// @flow
import * as React from 'react';
import classnames from 'classnames';

import Card from '@kwara/components/src/CardContainer';

import styles from './index.module.css';

type Props = {
  zIndexClass: string,
  appName: 'sacco' | 'member'
};

type DemoUserConfigItem = {
  label: string,
  description?: string,
  email: string,
  password: string
};

export class DemoUserSwitcher extends React.Component<Props, *> {
  state = {
    isSwitching: false
  };

  availableUsers: { [n: 'sacco' | 'member']: DemoUserConfigItem[] } = {
    sacco: [
      {
        label: 'Admin',
        description: 'Admin',
        email: 'demo.admin@kwara.com',
        password: 'demo.admin1'
      },
      {
        label: 'Secretary',
        description: `Can add members, can't approve members`,
        email: 'demo.secretary@kwara.com',
        password: 'demo.secretary1'
      },
      {
        label: 'Accountant',
        description: `Can create accounts, disburse loans, book repayments and deposits`,
        email: 'demo.accountant@kwara.com',
        password: 'demo.accountant1'
      },
      {
        label: 'Loan Officer',
        description: `Can add loan, can't approve loan`,
        email: 'demo.loan.officer@kwara.com',
        password: 'demo.loan.officer1'
      },
      {
        label: 'Operations Manager',
        description: `Can approve members, can't add members`,
        email: 'demo.operations@kwara.com',
        password: 'demo.operations1'
      },
      {
        label: 'Credit Manager',
        description: `Can approve loans, can't add loans`,
        email: 'demo.credit.manager@kwara.com',
        password: 'demo.credit.manager1'
      },
      {
        label: 'Credit Committee',
        description: `Can approve loans, can't add loans`,
        email: 'demo.credit.committee@kwara.com',
        password: 'demo.credit.committee1'
      },
      {
        label: 'Board Member',
        description: `View only`,
        email: 'demo.board.member@kwara.com',
        password: 'demo.board.member1'
      }
    ],
    member: [
      {
        label: 'Demo User',
        description: 'Basic user demo',
        email: 'demo.omondi@kwara.com',
        password: 'password123'
      }
    ]
  };

  switchUser = async ({ email, password }: DemoUserConfigItem) => {
    async function performUserSwitch() {
      const Auth = this.props.auth;

      if (Auth.isLoggedIn()) {
        await Auth.logOut();
      }

      await Auth.logIn({ email, password });

      window.location.reload();
    }

    this.setState(
      {
        isSwitching: true
      },
      performUserSwitch
    );
  };

  renderToggle = () => {
    const classes = [styles.toggle, 'tc pa2 kw-text-regular kw-weight-bold'];
    return <div className={classnames(classes)}>Kwara Demo - Switch Users</div>;
  };

  renderItem = (account: DemoUserConfigItem) => {
    const classes = [
      styles.account,
      'bt b--light-grey-400',
      'hover-bg-indigo-100'
    ];

    return (
      <li key={account.email} className={classnames(classes)}>
        <button onClick={() => this.switchUser(account)}>
          <h3 className={styles.title}>{account.label}</h3>
          <p className={classnames(styles.description, 'kw-weight-light')}>
            {account.description}
          </p>
        </button>
      </li>
    );
  };

  renderMenu = () => {
    return (
      <div className={styles.menu}>
        <div className="">
          <p className="mv0 mh3 kw-text-regular kw-weight-regular">
            Try out Kwara by logging in with the demo users below. Select a user
            to switch.
          </p>
        </div>

        <div className="relative">
          <ul className={classnames(styles.accounts, 'mb0')}>
            {this.availableUsers[this.props.appName].map(this.renderItem)}
          </ul>
          <p
            className={classnames([
              styles.switchingMessage,
              'mh0 mv4 tc flex items-center justify-center'
            ])}
          >
            <span>Switching user accounts</span>
          </p>
        </div>
      </div>
    );
  };

  render() {
    const classes = [
      styles.container,
      this.state.isSwitching ? styles.isSwitching : styles.isNotSwitching,
      'bg-light-grey-300',
      this.props.zIndexClass
    ];

    return (
      <Card className={classnames(classes)} full>
        <div>
          {this.renderToggle()}
          {this.renderMenu()}
        </div>
      </Card>
    );
  }
}

export default DemoUserSwitcher;
