//@flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type TranslationId = string;

type Props = {
  id: TranslationId,
  values?: { [key: string]: any }
};

const Text = (props: Props) => {
  const store = Text.appStore || {};
  const currency = store.currency;
  // orgCurrency will be always available in the translations
  const values = {
    orgCurrency: currency,
    ...props.values
  };
  return <FormattedMessage {...props} values={values} />;
};

export default Text;
