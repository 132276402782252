//@flow
import * as React from 'react';

import { type LoanProductType } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import { useMemberEligibility } from '@kwara/models/src/models/request/hooks';
import { useLoanProducts } from '../../../models/request';

import { type SubStepComponentProps } from '../../../components/Wizard';
import { LoanProductSelector } from '../../../components/ProductSelector';

type CustomProps = {
  productsPromise: Promise<LoanProductType[]>
};

export default ({
  addDataAndContinue,
  StackChild,
  data
}: SubStepComponentProps<CustomProps>) => {
  const { member } = data;

  const r1 = useMemberEligibility(member.id);
  const r2 = useLoanProducts();

  const onSelect = (product: LoanProductType) => {
    // Two fields (repaymentPeriodUnit and repaymentPeriod)
    // on the next Wizard step (Configure) need to be pre-filled
    // based on the selected loan product

    const {
      unit: repaymentPeriodUnit,
      value: repaymentPeriod
    } = product.toRepaymentFrequencyUI();

    const repaymentData =
      repaymentPeriodUnit || repaymentPeriod
        ? {
            repaymentPeriodUnit,
            repaymentPeriod
          }
        : {};

    addDataAndContinue({
      product,
      ...repaymentData
    });
  };

  return (
    <StackChild size="wide">
      <Loadable {...r1}>
        {eligibilities => (
          <Loadable {...r2}>
            {products => (
              <LoanProductSelector
                eligibilities={eligibilities}
                products={products}
                onSelect={onSelect}
              />
            )}
          </Loadable>
        )}
      </Loadable>
    </StackChild>
  );
};
