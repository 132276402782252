// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';

import Wizard from '../../components/Wizard';
import { savingPath } from '../../lib/urls';
import { useSaving } from '../../models/request';
import { steps } from './config';
import type { WizardPageProps } from '..';

type Props = WizardPageProps<{ savingId: string }>;

export const SavingClose = (props: Props) => {
  const { baseUrl, match, history } = props;
  const r = useSaving(props.match.params.savingId);

  const closeSavingAccount = async data => {
    const { saving, notes, reason } = data;

    const fullNotes = `Reason: ${reason}, ${notes}`;

    const didSave = await saving.close({ notes: fullNotes });

    if (!didSave) {
      throw saving.errors;
    }
  };

  return (
    <Loadable {...r}>
      {saving => {
        return (
          <Wizard
            baseUrl={baseUrl}
            history={history}
            initialData={{
              saving: saving,
              savingId: saving.id,
              firstName: saving.member.firstName
            }}
            cancelReturnsTo={savingPath()}
            currentStep={match.params.step}
            currentSubStep={
              match.params.subStep != null
                ? parseInt(match.params.subStep, 10)
                : null
            }
            onSubmit={closeSavingAccount}
            steps={steps}
            startId="confirm"
            titleId="SavingClose.shortTitle"
            type="approval"
          />
        );
      }}
    </Loadable>
  );
};
