import * as React from 'react';
import get from 'lodash/fp/get';

import { Loadable } from '@kwara/components/src/Loadable';
import { add } from '@kwara/lib/src/currency';

import { ProfileContext } from '../../models/Profile';
import Wizard from '../../components/Wizard';
import { loanPath } from '../../lib/urls';
import { useLoan } from '../../models/request';
import { steps } from './config';

async function payOffLoan({
  loan,
  method,
  notes,
  bankGlId,
  bankBranch,
  bankName,
  accountNumber,
  accountId,
  chequeNumber,
  drawer
}) {
  const params = {
    method,
    notes,
    bankGlId,
    bankName,
    bankBranch,
    accountNumber,
    chequeNumber,
    drawer,
    depositAccount: accountId
  };
  const didSave = await loan.payOff(params);

  if (!didSave) {
    throw loan.errors;
  }
}

export const LoanPayOff = props => {
  const store = React.useContext(ProfileContext);

  const { baseUrl, match, history } = props;
  const loanId = match.params.loanId;
  const r = useLoan(loanId);

  return (
    <Loadable {...r}>
      {loan => {
        return (
          <Wizard
            baseUrl={baseUrl}
            history={history}
            initialData={{
              firstName: get('member.firstName', loan),
              loanId: loan.id,
              loan,
              amount: add(
                loan.totalBalance,
                store.accruedInterestEnabled ? loan.accruedInterest : 0
              )
            }}
            cancelReturnsTo={loanPath({ id: loan.id })}
            currentStep={match.params.step}
            currentSubStep={
              match.params.subStep != null
                ? parseInt(match.params.subStep, 10)
                : null
            }
            onSubmit={payOffLoan}
            steps={steps}
            startId="confirm"
            titleId="LoanPayOff.shortTitle"
          />
        );
      }}
    </Loadable>
  );
};
