// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import find from 'lodash/find';

import { Loadable } from '@kwara/components/src/Loadable';
import {
  SavingsTransaction,
  PendingSavingsTransaction,
  type MemberType,
  type SavingType
} from '@kwara/models/src';

import Wizard, { type SubStepComponentProps } from '../../components/Wizard';
import { savingPath } from '../../lib/urls';
import { useSaving } from '../../models/request';
import { store } from '../../models/Store';
import { steps } from './config';
import type { WizardPageProps } from '..';

export const DEPOSIT = 'DEPOSIT';
export const LOAN = 'LOAN';
export const INTRA = 'intra';
export const INTER = 'inter';

type FormData = {
  amount: number,
  saving: SavingType,
  recipient: MemberType,
  sender: MemberType,
  linkedAccountId: string,
  linkedAccountType: typeof DEPOSIT | typeof LOAN,
  notes: string
};

export type SubstepProps = SubStepComponentProps<FormData>;

type Props = WizardPageProps<{ savingId: string }>;

export function getReceivingAccount(
  recipient: MemberType,
  toAccountId: string
) {
  const { savings, loans } = recipient;
  return find([...savings, ...loans], a => a.id === toAccountId);
}

export function linkedAccountType(account) {
  const resourceType = get('resourceIdentifier.type', account);

  if (resourceType === 'loans') {
    return LOAN;
  }

  if (resourceType === 'savings') {
    return DEPOSIT;
  }

  throw ('Make Transfer - Unsupported account type', account);
}

async function createTransfer(data: FormData) {
  const { amount, recipient, linkedAccountId, notes, saving } = data;

  const receivingAccount = getReceivingAccount(recipient, linkedAccountId);

  const Transaction = store.isMakerCheckerEnabled(amount)
    ? PendingSavingsTransaction
    : SavingsTransaction;

  const transfer = new Transaction({
    savingsId: saving.id,
    type: 'TRANSFER',
    amount,
    linkedAccountId,
    linkedAccountType: linkedAccountType(receivingAccount),
    notes
  });

  const didSave = await transfer.save();

  if (!didSave) {
    throw transfer.errors;
  }
}

export const MakeTransfer = (props: Props) => {
  const { baseUrl, match, history } = props;
  const savingId = match.params.savingId;
  const r = useSaving(savingId);

  return (
    <Loadable {...r}>
      {saving => {
        const { member } = saving;

        return (
          <Wizard
            baseUrl={baseUrl}
            history={history}
            initialData={{
              recipient: member,
              sender: member,
              saving,
              transferType: INTRA
            }}
            cancelReturnsTo={savingPath({ id: saving.id })}
            currentStep={match.params.step}
            currentSubStep={
              match.params.subStep != null
                ? parseInt(match.params.subStep, 10)
                : null
            }
            onSubmit={createTransfer}
            steps={steps}
            startId="recipient"
            titleId="MakeTransfer.shortTitle"
          />
        );
      }}
    </Loadable>
  );
};
