//@flow

import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { KinActions } from './Actions';

type Handler = () => void;

type Props = {
  onEdit: Handler,
  onRemove: Handler,
  position: number,
  text: string
};

export default ({ onEdit, onRemove, position, text }: Props) => (
  <div className="mb4">
    <h4 className="mt0 mb2 kw-text-small kw-weight-normal grey-400">
      <Text id="AddMember.Kin.position" values={{ position }} />
    </h4>

    <div className="pre mv3 pa3 br3 b--transparent bg-semi-white-400 kw-text-regular">
      {text}
    </div>

    <KinActions onEdit={onEdit} onRemove={onRemove} />
  </div>
);
