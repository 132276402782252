//@flow

import * as React from 'react';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';

import {
  type LoanProductType,
  type SavingProductType,
  type MemberEligibilityT
} from '@kwara/models/src';

import {
  SubscribedLoanProductCard,
  SubscribedSavingProductCard
} from './SubscribedProductCard';

import styles from './index.module.scss';

type SupportedProductTypes = SavingProductType | LoanProductType;
type SupportedProductsTypes = SavingProductType[] | LoanProductType[];
type SelectHandler = (product: SupportedProductTypes) => void;

type BaseProps = {
  products: SupportedProductsTypes,
  onSelect: SelectHandler,
  selectable?: boolean,
  eligibilities: MemberEligibilityT[]
};
type Props = BaseProps & {
  productCard: any // TO DO: Type this correctly
};

const ProductSelector = ({
  products,
  onSelect,
  selectable = false,
  productCard: ProductCard,
  eligibilities = []
}: Props) => {
  return (
    <div data-testid="ProductSelector" className={styles.Container}>
      {map(product => {
        const productEligibility = find(
          e => e.id === product.id,
          eligibilities
        );

        product.eligibility = productEligibility;

        return (
          <ProductCard
            key={product.id}
            product={product}
            onSelect={onSelect}
            isSelectable={selectable}
          />
        );
      }, products)}
    </div>
  );
};

export const LoanProductSelector = (props: BaseProps) => (
  <ProductSelector {...props} productCard={SubscribedLoanProductCard} />
);

export const SavingsProductSelector = (props: BaseProps) => (
  <ProductSelector {...props} productCard={SubscribedSavingProductCard} />
);
