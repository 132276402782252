// @flow

import * as React from 'react';
import map from 'lodash/fp/map';

import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { type MemberType } from '@kwara/models/src';

import { Grid } from '../../../components/Grid';
import {
  EditableSection,
  type EditableConfig
} from '../../../components/EditableSection';
import IdentityForm from '../../MemberAdd/components/Identity';

const base = {
  Component: IdentityForm,
  validate: IdentityForm.validateConfig.memberAdd
};

const memberDetail = base;
const loanAdd = {
  ...base,
  validate: IdentityForm.validateConfig.loanAdd
};

export const Identity = ({
  config = memberDetail,
  member,
  readOnly
}: EditableConfig<MemberType>) => (
  <EditableSection
    config={config}
    initialData={member}
    readOnly={readOnly}
    onSave={async data => data.save({ with: 'idDocuments' })}
  >
    <Grid columns={2} width="w-50">
      {map(
        document => (
          <Statistic
            key={`${document.documentId}${document.type}`}
            title={<Text id={`AddMember.Identity.Type.${document.type}`} />}
            value={document.documentId}
            size={'medium'}
          />
        ),
        member.idDocuments
      )}
      <Statistic
        title={<Text id={`AddMember.Identity.KRA.label`} />}
        value={member.kraPin}
        size={'medium'}
      />
    </Grid>
  </EditableSection>
);

Identity.Title = () => <Text id="MemberDetail.identification" />;
Identity.editConfig = { memberDetail, loanAdd };
