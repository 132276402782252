// @flow

import * as React from 'react';
import invoke from 'lodash/fp/invoke';
import get from 'lodash/fp/get';

import { Attribute } from '@kwara/components/src';

import MemberSearch from '../../../components/MemberSearch';

import { INTRA, INTER, type SubstepProps } from '..';

export const Recipient = ({
  StackChild,
  RadioGroup,
  Condition,
  data,
  addData,
  onChange
}: SubstepProps) => {
  const { recipient, sender } = data;

  const inputOnChange = e => {
    const type = e.target.value;

    if (type === INTRA) {
      addData({ recipient: sender });
      return onChange({ recipient: sender });
    }

    if (type === INTER) {
      addData({ recipient: null });
      return onChange({ recipient: null });
    }
  };

  return (
    <StackChild>
      <RadioGroup
        required
        name="transferType"
        labelId="MakeTransfer.Recipient.radio.label"
        items={[
          {
            labelId: 'MakeTransfer.Recipient.radio.intra',
            value: INTRA,
            inputOnChange
          },
          {
            labelId: 'MakeTransfer.Recipient.radio.inter',
            value: INTER,
            inputOnChange
          }
        ]}
      />
      {recipient ? (
        <Attribute
          className="mb2"
          value={invoke('fullName', recipient)}
          labelId="MakeTransfer.Recipient.label"
          values={{ id: get('id', recipient) }}
        />
      ) : null}
      <Condition when="transferType" is={INTER}>
        <MemberSearch
          resultType="nonFinancial"
          onSelect={async recipient => {
            addData({ recipient });
            await onChange({ recipient });
          }}
          data-cy="recipient-search"
        />
      </Condition>
    </StackChild>
  );
};

Recipient.validate = {
  recipient: {
    isRequired: () => true
  }
};
