import React from 'react';

import { ActionButton } from '@kwara/components/src/Button';

import TextField from './TextField';

const { useState } = React;

export const PasswordField = props => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleButton = (
    <ActionButton
      hideBorder
      col="grey300"
      type={showPassword ? 'view' : 'hidden'}
      onClick={() => setShowPassword(!showPassword)}
    />
  );

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      rightAction={toggleButton}
      {...props}
    />
  );
};
