// @flow

import * as React from 'react';

// import Button from '@kwara/components/src/Button';
import { Loadable } from '@kwara/components/src/Loadable';
// import { Text } from '@kwara/components/src/Intl';

// import { settingPath } from '../../../../lib/urls';
import { useLoanProducts } from '../../../../models/request';

import { LoanProductTable } from './LoanProductsTable';
import { Header } from '../Header';

export const LoanProducts = () => {
  const r = useLoanProducts();

  return (
    <>
      <Header
        titleId="Settings.Loans.titleId"
        subtitleId="Settings.Loans.subtitleId"
        // TO DO: Unhide the Create Loan button
        // when that feature is supported
        // action={
        //   <Button
        //     to={settingPath({ baseExtension: 'loans', action: 'new' })}
        //     type="primary"
        //   >
        //     <Text id="Settings.Loans.add" />
        //   </Button>
        // }
      />
      <Loadable {...r}>
        {products => <LoanProductTable products={products} />}
      </Loadable>
    </>
  );
};
