// @flow
import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

const Types = {
  good: 'good',
  bad: 'bad'
};

type Type = $Values<typeof Types>;

export type Props = {
  onConfirm: () => void,
  subtitle?: React.Node,
  type: Type,
  analyticsId: string
};

const Completion = ({ onConfirm, subtitle, type, analyticsId }: Props) => (
  <div
    id={`kwaralytics-completion-confirmation-${type}-${analyticsId}`}
    className={`${styles.Container} ${styles[type]} center`}
  >
    <div className={`${styles.Inner} flex flex-column items-center`}>
      <h3 className="kw-text-big kw-weight-bold pa0 ma0">
        <Text id={`Completion.Title.${type}`} />
      </h3>
      {subtitle ? <p className="kw-text-regular pt1 ph3">{subtitle}</p> : null}
      <div className="pt2 pb3">
        <Button onClick={onConfirm} type="primary" size="regular">
          <Text id={`Completion.Confirm.${type}`} />
        </Button>
      </div>
    </div>
  </div>
);

type AutoDismissableCompletionProps = Props & {
  autoconfirm: boolean,
  autoconfirmTimeoutSecs: number
};

export class AutoDismissableCompletion extends React.Component<AutoDismissableCompletionProps> {
  static Types = Types;
  static defaultProps = {
    autoconfirm: false,
    autoconfirmTimeoutSecs: 2
  };

  timeoutId: ?TimeoutID;

  complete = () => {
    this.props.onConfirm();
  };

  componentDidMount() {
    if (
      this.props.autoconfirm &&
      typeof this.props.autoconfirmTimeoutSecs === 'number'
    ) {
      this.timeoutId = setTimeout(
        this.complete,
        this.props.autoconfirmTimeoutSecs * 1000
      );
    }
  }

  componentWillUnmount() {
    if (this.timeoutId != null) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  render() {
    return <Completion {...this.props} />;
  }
}

AutoDismissableCompletion.Types = Types;
