import * as React from 'react';

import classnames from 'classnames';

import Asset from '@kwara/components/src/Asset';

type Props = {
  text: string,
  className?: string,
  state: string,
  onDismiss?: () => void,
  hidden?: boolean
};

const Banner = ({
  text,
  className,
  innerClassName,
  state = 'success',
  onDismiss,
  hidden
}: Props) => {
  if (hidden) {
    return null;
  }

  const classes = {
    error: 'bg-red-100 red-700',
    warning: 'bg-amber-100 amber-700',
    success: 'bg-teal-100 teal-600 '
  };

  const buttonCol = {
    error: Asset.Colours.red700,
    warning: Asset.Colours.amber700,
    success: Asset.Colours.teal700
  };

  const dismiss = onDismiss ? (
    <button onClick={onDismiss} className="bn bg-transparent p0 m0">
      <Asset id="Cross" col={buttonCol[state]} />
    </button>
  ) : null;

  return (
    <div className={classnames('br4', classes[state], className)}>
      <div className={classnames('ml-auto mr-auto pv3 ph3', innerClassName)}>
        {text}
        {dismiss}
      </div>
    </div>
  );
};

export default Banner;
