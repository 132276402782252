import * as React from 'react';

import { ActionButton } from '@kwara/components/src/Button';

import { SubscribedTextField } from '.';

const { useState } = React;

export const SubscribedPasswordField = props => {
  const [showPassword, setShowPassword] = useState(false);

  const fieldType = showPassword ? 'text' : 'password';
  const glyphType = showPassword ? 'view' : 'hidden';

  const toggleButton = (
    <ActionButton
      hideBorder
      col="grey300"
      type={glyphType}
      onClick={() => setShowPassword(!showPassword)}
    />
  );

  return (
    <SubscribedTextField
      {...props}
      type={fieldType}
      rightAction={toggleButton}
    />
  );
};
