//@flow

import * as React from 'react';
import { Field as FinalFormField } from 'react-final-form';

import { type TranslationId } from '@kwara/components/src/Intl';

import { Field as BasicField, DatePicker } from '.';

export const SubscribedDatePicker = ({
  compact,
  margin,
  name,
  required,
  titleId,
  labelId,
  errorBaseId,
  info,
  infoId,
  disabled,
  disabledDays
}: {
  name: string,
  required?: boolean,
  titleId?: TranslationId,
  labelId?: TranslationId,
  errorBaseId?: string,
  info?: React.Node,
  infoId?: TranslationId,
  disabled?: boolean,
  disabledDays?: { before?: Date, after?: Date }
}) => {
  return (
    <FinalFormField
      name={name}
      infoId={infoId}
      render={({ input, meta }) => {
        return (
          <BasicField
            compact={compact}
            margin={margin}
            name={input.name}
            labelId={labelId}
            titleId={titleId}
            error={meta.error && meta.touched}
            errorBaseId={errorBaseId}
            errorCodes={meta.error}
            info={info}
            infoId={infoId}
            required={required}
          >
            <DatePicker
              name={name}
              disabled={disabled}
              disabledDays={disabledDays}
              {...input}
            />
          </BasicField>
        );
      }}
    />
  );
};
