//@flow
import * as React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/fp/isEmpty';
import { injectIntl, type IntlShape } from 'react-intl';

import { Logger } from '@kwara/lib/src/logger';
import { type ModelErrors, type ModelError } from '@kwara/models/src';
import Banner from '@kwara/components/src/Banner';
import { getTranslation, Text } from '@kwara/components/src/Intl';

type Props = {
  errors: ModelErrors,
  errorBaseId?: string,
  errorValues?: { [key: string]: ?string | ?number }
};

const getMessageForStatus = (statusCode, intl) => {
  const id = `Errors.${statusCode}`;

  if (statusCode) {
    const maybeTranslated = getTranslation(intl, id);
    if (maybeTranslated !== id) {
      return maybeTranslated;
    }
  }

  return null;
};

export const ErrorItem = injectIntl(
  ({
    error,
    errorBaseId = '',
    errorValues,
    intl
  }: {
    error: ModelError,
    errorBaseId: string,
    intl: IntlShape,
    errorValues?: { [key: string]: ?string | ?number }
  }) => {
    const statusCode = get(error, 'code');
    let message = getMessageForStatus(statusCode, intl);
    const attribute = get(error, 'attribute', '');
    const title = get(error, 'title');
    const detail = get(error, 'detail');

    const rowIndex = get(error, 'rowIndex');

    if (!message && error.fullMessage && rowIndex) {
      Logger.error('Unsupported Error shape: ', JSON.stringify(error, null, 1));
      message = `Row ${rowIndex} - ${attribute} ${error.fullMessage}`;
    } else if (!message && error.fullMessage) {
      Logger.error('Unsupported Error shape: ', JSON.stringify(error, null, 1));
      message = `${attribute} ${error.fullMessage}`;
    } else if (!message && title) {
      message = title;
    } else if (!message && detail) {
      message = detail;
    }

    return (
      <li>
        {(() => {
          if (!message) {
            Logger.error(
              'Unsupported Error shape: ',
              JSON.stringify(error, null, 1)
            );
            // If a message cannot be found in the translations,
            // display the status code of the error, if possible.
            return statusCode || <Text id="Errors.Generic" />;
          }

          // For backwards compatibility we provide the translations path only if
          // errorBaseId is there. In all the other cases we assume no translation has been
          // set up for that particular message, so we fallback to the previous implementation
          // that was not translating the messages. This next block is the preferred one to be
          // matched, so try always writing the translation and passing an errorBaseId to
          // <ModelErrorBanner />
          if (errorBaseId) {
            return (
              <Text
                id={`${errorBaseId}.Errors.${attribute}`}
                values={errorValues}
              />
            );
          }

          return message;
        })()}
      </li>
    );
  }
);

export const ErrorList = ({
  errors,
  errorBaseId,
  errorValues
}: {
  errors: ModelErrors,
  errorBaseId: string,
  errorValues?: { [key: string]: ?string | ?number }
}) => {
  return (
    <ul className="ma0 pa1 list">
      {map(errors, (error: ModelError, attribute: string) => {
        return (
          <ErrorItem
            key={attribute}
            error={error}
            errorValues={errorValues}
            errorBaseId={errorBaseId}
          />
        );
      })}
    </ul>
  );
};

export const ModelErrorBanner = ({
  errors,
  errorBaseId = '',
  errorValues = {},
  state = 'error',
  ...props
}: Props) =>
  isEmpty(errors) ? null : (
    <Banner
      {...props}
      text={
        <ErrorList
          errors={errors}
          errorBaseId={errorBaseId}
          errorValues={errorValues}
        />
      }
      state={state}
    />
  );
