// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import isNull from 'lodash/isNull';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';

import { Checkbox } from '@kwara/components/src/Form';
import Button from '@kwara/components/src/Button';
import PageLayout from '@kwara/components/src/PageLayout';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src';
import { type MemberType, type UserT } from '@kwara/models/src';
import { Link } from '@kwara/components/src/Link';
import { ExpanderRow } from '@kwara/components/src';
import { useMember, isEMPTY } from '@kwara/models/src/models/request/hooks';
import { ScrollToTop } from '@kwara/components/src/ScrollIntoFocus';
import { MemberSummaryPDF } from '@kwara/components/src/MemberSummaryPDF';

import { AppPermissions, permission } from '../../models';
import { loanPath, memberPath, savingPath } from '../../lib/urls';
import { type MemberPageProps, type LinkContext } from '..';
import { Updater } from '../../components/EditableSection';
import { Activity } from './components/Activity';
import { ButtonMenu } from '../../components/ButtonMenu';
import { DetailSection } from '../../components/Detail/DetailSection';
import Head from '../../components/Head';
import { PrintPDFButton } from '../../components/PrintPDFButton';
import { Loans } from './components/Loans';
import Overview from './components/Overview';
import Savings from './components/Savings';
import Visible from '../../components/Visible';
import { SubsectionTitle } from '../../components/DetailSubsection';
import { Remittance } from './components/Remittance';
import {
  Bank,
  Documents,
  PersonalDetails,
  NextOfKin,
  Identity,
  Employment
} from './components';
import {
  AsNavigation,
  BackToTopNavigation
} from '../../components/DetailNavigation';
import { store } from '../../models/Store';

type MemberDetailProps = {
  member: MemberType,
  linkContext?: LinkContext,
  showCommentCreate: boolean,
  showMargins: boolean,
  showOverviewActions: boolean,
  statusOverride?: null | 'APPLICANT' | 'GUARANTOR',
  readOnly: boolean,
  refetch?: () => void,
  profile: UserT
};
type Props = MemberPageProps & MemberDetailProps;

const Menu = ({ member, hidden }: { member: MemberType, hidden?: boolean }) => {
  if (hidden) {
    return null;
  }

  // same user email means no transaction is allowed
  const sameUser = get('profile.email', store) === get('email', member);

  const items = [
    permission.to(AppPermissions.AddDeposits) ? (
      <ButtonMenu.Item
        key="deposit"
        disabled={
          size(member.savingsEligibleForTransactions()) === 0 || sameUser
        }
        to={memberPath({ id: member.id, action: 'deposit' })}
      >
        <Text id="MemberDetail.addDeposit" />
      </ButtonMenu.Item>
    ) : null,
    permission.to(AppPermissions.AddWithdrawal) ? (
      <ButtonMenu.Item
        key="withdrawal"
        disabled={
          size(member.savingsEligibleForTransactions()) === 0 ||
          sameUser ||
          Number(get('currentTill.currentAmount', store)) === 0
        }
        to={memberPath({ id: member.id, action: 'withdrawal' })}
      >
        <Text id="MemberDetail.addWithdrawal" />
      </ButtonMenu.Item>
    ) : null,
    permission.to(AppPermissions.AddRepayments) ? (
      <ButtonMenu.Item
        key="repayment"
        disabled={size(member.loansEligibleForRepayment()) === 0 || sameUser}
        to={memberPath({ id: member.id, action: 'repayment' })}
      >
        <Text id="MemberDetail.addRepayment" />
      </ButtonMenu.Item>
    ) : null,
    permission.to(AppPermissions.AddSavings) ? (
      <ButtonMenu.Item
        disabled={sameUser}
        key="savings"
        to={savingPath(
          { action: 'create', memberId: member.id },
          { asObject: true }
        )}
      >
        <Text id="MemberDetail.addSavings" />
      </ButtonMenu.Item>
    ) : null,
    permission.to(AppPermissions.AddLoans) ? (
      <ButtonMenu.Item
        disabled={sameUser}
        // This validation is removed for KBS because eligible amount is meaningless for KBS since their eligibility is on a per product basis.
        // For more details: https://kwara.slack.com/archives/CMSBN9MLL/p1582879558000300
        // disabled={!member.isEligibleForLoan()}
        key="loan"
        to={loanPath(
          { action: 'create/member/2', memberId: member.id },
          { asObject: true }
        )}
      >
        <Text id="MemberDetail.addLoan" />
      </ButtonMenu.Item>
    ) : null
  ];

  return items.every(isNull) ? null : (
    <ButtonMenu title="Add">{items}</ButtonMenu>
  );
};

export class MemberDetail extends React.Component<MemberDetailProps> {
  // MemberDetail is embedded within the
  // LoanApprove/Applicant Wizard step.
  // In this context, certain parts of the
  // page are hidden.
  static defaultProps = {
    showCommentCreate: true,
    showMargins: true,
    showOverviewActions: true,
    statusOverride: null
  };

  static includes = [
    { loans: ['product', 'transactions'] },
    { guaranteed_loans: ['product', 'guarantees.member'] },
    // { guaranteed_loans: ['product', 'guarantors.member'] },
    { savings: ['product', 'transactions'] },
    'id_documents',
    'attachments',
    'addresses',
    'next_of_kin'
  ];

  constructor(props) {
    super(props);
    this.state = {
      showClosedLoans: false,
      showClosedSavings: false
    };
  }

  onCheck = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  renderBody = ({
    history,
    linkContext,
    member,
    showCommentCreate
  }: MemberDetailProps & { member: MemberType }) => {
    const { readOnly } = this.props;
    return (
      <>
        <DetailSection
          id="savings"
          title={<Text id="MemberDetail.savings" />}
          subtitle={<BackToTopNavigation />}
          headerRight={
            <div className="pt5 h-100 flex items-center">
              <Checkbox
                compact
                name="showClosedSavings"
                labelId="MemberDetail.showInactiveSavings.checkbox"
                checked={this.state.showClosedSavings}
                onChange={this.onCheck}
              />
            </div>
          }
        >
          <Savings
            member={member}
            history={history}
            linkContext={linkContext}
            showClosedSavings={this.state.showClosedSavings}
          />
        </DetailSection>
        <DetailSection
          id="loans"
          title={<Text id="MemberDetail.loans" />}
          subtitle={<BackToTopNavigation />}
          headerRight={
            <div className="pt5 h-100 flex items-center">
              <Checkbox
                compact
                name="showClosedLoans"
                labelId="MemberDetail.showInactiveLoans.checkbox"
                checked={this.state.showClosedLoans}
                onChange={this.onCheck}
              />
            </div>
          }
        >
          <Loans
            member={member}
            history={history}
            linkContext={linkContext}
            showClosedLoans={this.state.showClosedLoans}
          />
        </DetailSection>

        <DetailSection
          id="remittance"
          title={<Text id="MemberDetail.remittance" />}
          subtitle={<BackToTopNavigation />}
        >
          <Remittance savings={member.savings} loans={member.loans} />
        </DetailSection>

        <DetailSection
          id="info"
          title={<Text id="MemberDetail.info" />}
          subtitle={
            <>
              <BackToTopNavigation />
              <Link to={memberPath({ id: member.id, action: 'edit' })}>
                Edit
              </Link>
            </>
          }
        >
          <SubsectionTitle>
            <PersonalDetails.Title />
          </SubsectionTitle>
          <ExpanderRow>
            <PersonalDetails member={member} readOnly={readOnly} />
          </ExpanderRow>
          <SubsectionTitle>
            <Identity.Title />
          </SubsectionTitle>
          <ExpanderRow>
            <Identity member={member} readOnly={readOnly} />
          </ExpanderRow>
          <SubsectionTitle>
            <NextOfKin.Title />
          </SubsectionTitle>
          <ExpanderRow>
            <NextOfKin member={member} readOnly={readOnly} />
          </ExpanderRow>
          <SubsectionTitle>
            <Employment.Title />
          </SubsectionTitle>
          <ExpanderRow>
            <Employment
              member={member}
              readOnly={readOnly}
              config={Employment.editConfig.extra}
            />
          </ExpanderRow>
          <SubsectionTitle>
            <Bank.Title />
          </SubsectionTitle>
          <ExpanderRow>
            <Bank member={member} readOnly={readOnly} />
          </ExpanderRow>
          <SubsectionTitle>
            <Documents.Title />
          </SubsectionTitle>
          <ExpanderRow>
            <Documents member={member} readOnly={readOnly} />
          </ExpanderRow>
        </DetailSection>

        <DetailSection
          id="activity"
          title={<Text id="MemberDetail.activity" />}
          subtitle={<BackToTopNavigation />}
        >
          <Activity
            showCommentCreate={showCommentCreate}
            memberId={member.id}
          />
        </DetailSection>
      </>
    );
  };

  render() {
    const {
      member,
      showMargins,
      showOverviewActions,
      statusOverride
    } = this.props;

    const body = this.renderBody({ ...this.props, member });
    const Navigation = AsNavigation(body);

    const hideButtonMenu = member.isExited() || !showOverviewActions;

    const canExit = member.canBeExited && member.canBeExited();
    const closeButton = (
      <Visible to={AppPermissions.ExitMembers}>
        <Button
          disabled={!canExit}
          size="medium"
          to={memberPath({ id: member.id, action: 'close' })}
        >
          <Text id="MemberDetail.close" />
        </Button>
      </Visible>
    );

    const printButton = (
      <PrintPDFButton
        renderPDF={props => <MemberSummaryPDF member={member} {...props} />}
        className="mr3"
      />
    );
    const buttonMenu = <Menu member={member} hidden={hideButtonMenu} />;

    return (
      <PageLayout
        className={showMargins ? 'pt5' : ''}
        overview={
          <Overview
            navigation={<Navigation />}
            member={member}
            actions={[closeButton, printButton, buttonMenu]}
            showBack={showOverviewActions}
            statusOverride={statusOverride}
          />
        }
      >
        <Head
          titleId="MemberDetail.title"
          values={{ fullName: member.fullName && member.fullName() }}
        />
        {body}
      </PageLayout>
    );
  }
}

export const MemberDetailRoute = (props: Props) => {
  const { memberId } = props.match.params;
  const result = useMember(memberId, MemberDetail.includes);
  return (
    <Loadable {...result} isPending={isEMPTY(result.data)}>
      {member => {
        if (member.isPendingApproval()) {
          return <Redirect to={`/members/${member.id}/approve`} />;
        }
        return (
          <ScrollToTop>
            <Updater value={{ onUpdate: result.refetch }}>
              <MemberDetail
                {...props}
                readOnly={false}
                member={member}
                // refetch={result.refetch}
              />
            </Updater>
          </ScrollToTop>
        );
      }}
    </Loadable>
  );
};
