//@flow
import * as React from 'react';

const addClassToButtons = (buttons, className) =>
  React.Children.map(
    buttons,
    button =>
      button &&
      React.cloneElement(button, {
        className: `${button.props.className || ''} ${className}`
      })
  );

type Props = {
  left: React.Node,
  right: React.Node,
  className?: string,
  innerClassName?: string
};

export const ButtonBar = ({
  left,
  right,
  className = '',
  innerClassName = ''
}: Props) => (
  <div className={`${className}`}>
    <div className={`flex ${innerClassName}`}>
      <div className="flex-auto flex" data-testid="ButtonBar-Left">
        {addClassToButtons(left, 'mr3')}
      </div>
      <div className="flex-auto flex justify-end" data-testid="ButtonBar-Right">
        {addClassToButtons(right, 'ml3')}
      </div>
    </div>
  </div>
);
