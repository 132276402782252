import React from 'react';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import { View } from '@react-pdf/renderer';
import { NATIONAL, PASSPORT } from '@kwara/models/src/models/IdDocument';
import { DefaultText as Text, SubtitleText as Subtitle } from '.';

const idDocuments = {
  [NATIONAL]: 'National ID',
  [PASSPORT]: 'Passport'
};

const summary = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
};

export const MemberDetails = ({ member }) => {
  const memberIdDocuments = get('idDocuments', member);
  const supportedIdDocuments = filter(({ type }) => {
    return type === NATIONAL || type === PASSPORT;
  }, memberIdDocuments);

  return (
    <View style={{ width: '200px' }}>
      <Subtitle id="MemberDetailsPDF.subtitle" />
      <View style={summary}>
        <Text id="MemberDetailsPDF.summary.name" style={{ width: '80px' }} />
        <Text style={{ width: '120px' }}>{member.fullName()}</Text>
      </View>

      <View style={summary}>
        <Text id="MemberDetailsPDF.summary.id" style={{ width: '80px' }} />
        <Text style={{ width: '120px' }}>{member.id}</Text>
      </View>

      <View style={summary}>
        {map(
          ({ type, documentId }) => (
            <React.Fragment key={documentId}>
              <Text style={{ width: '80px' }}>{idDocuments[type]}:</Text>
              <Text style={{ width: '120px' }}>{documentId}</Text>
            </React.Fragment>
          ),
          supportedIdDocuments
        )}
      </View>
      <View style={summary}>
        <Text id="MemberDetailsPDF.summary.mobile" style={{ width: '80px' }} />
        <Text style={{ width: '120px' }}>{member.phoneNumber}</Text>
      </View>

      <View style={summary}>
        <Text id="MemberDetailsPDF.summary.email" style={{ width: '80px' }} />
        <Text style={{ width: '120px' }}>{member.email}</Text>
      </View>
    </View>
  );
};
