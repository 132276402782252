//@flow
import * as React from 'react';
import { FormSpy } from 'react-final-form';

type NextOfKins = string[];

type RenderFunc = (kin: NextOfKins) => React.Node;

type SpyProps = {
  values?: { kin: NextOfKins }
};

export const KinFormSpy = ({ children }: { children: RenderFunc }) => (
  <FormSpy>
    {(props: SpyProps) => {
      const nextOfKin =
        props.values != null && props.values.kin != null
          ? props.values.kin
          : [];

      return children(nextOfKin);
    }}
  </FormSpy>
);
