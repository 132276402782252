//@flow
import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { Record, Section } from '../../../../components/Summary';
import { type SubStepComponentProps } from '../../../../components/Wizard';

import PersonalDetails from '../PersonalDetails';
import { Currency } from '../../../../components/Currency';

import { type WizardData } from '../..';

export const Summary = (props: SubStepComponentProps<WizardData>) => {
  const { data, StackChild, onChange } = props;
  const {
    note,
    savingsAccountDeposit,
    savingsProduct,
    shareCapitalAmount,
    member,
    method
  } = data;

  return (
    <StackChild>
      <div className="bb b--light-grey-400">
        <PersonalDetails member={member} onChange={onChange} />
      </div>

      <Section titleId="MemberApprove.Summary.ShareCapital.title">
        <Record
          labelId="MemberApprove.Summary.ShareCapital.amount"
          value={shareCapitalAmount}
        />
      </Section>

      <Section titleId="MemberApprove.Summary.Account.title">
        <Record
          labelId="MemberApprove.Summary.Account.name"
          value={savingsProduct ? savingsProduct.name : null}
        />
        <Record
          labelId="MemberApprove.Summary.Account.deposit"
          value={
            savingsAccountDeposit ? (
              <Currency value={savingsAccountDeposit} format="currency" />
            ) : null
          }
        />
        <Record
          labelId="MemberApprove.Summary.Account.remittanceMethod"
          value={method ? <Text id={`TransactionMethod.${method}`} /> : null}
        />
      </Section>

      <Section titleId="MemberApprove.Summary.Terms.title">
        <Record valueId="MemberApprove.Terms.Agree.label" />
      </Section>

      <Section titleId="MemberApprove.Summary.Notes.title">
        <Record value={note} />
      </Section>
    </StackChild>
  );
};
