// @flow

import * as React from 'react';
import filter from 'lodash/filter';
import get from 'lodash/fp/get';

import {
  Activity,
  Schedule,
  type LoanType,
  type ActivityType,
  type ScheduleType,
  type UserT
} from '@kwara/models/src';
import { Loadable, EMPTY } from '@kwara/components/src/Loadable';
import { LoanFetch } from '@kwara/models/src/models/Loan';

import { Updater } from '../../components/EditableSection';
import Wizard from '../../components/Wizard';
import { withProfile } from '../../models/Profile';
import { loanPath } from '../../lib/urls';
import { createSteps } from './config';
import type { WizardPageProps } from '..';

type Props = WizardPageProps<{ loanId: string }> & { profile: UserT };
const includes = [
  'member',
  'product',
  'guarantees.member',
  'collaterals',
  'loan_application.loan_application_approvals.user.role',
  'loan_application.pay_off_loans.product'
];

class LoanApprove extends React.Component<
  Props,
  {
    isPending: boolean,
    errors: { messages: [{ title: 'Something went wrong' }] } | {},
    loan: ?LoanType,
    schedule: ?ScheduleType,
    comments: ?(ActivityType[])
  }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isPending: true,
      errors: EMPTY,
      loan: null,
      schedule: null,
      comments: null
    };
  }

  componentDidMount = async () => {
    await this.refetchLoan();
  };

  refetchLoan = async () => {
    let errors = EMPTY;
    this.setState({ isPending: true });
    const [loan, schedule, activities] = await Promise.all([
      LoanFetch(this.props.match.params.loanId, includes),
      Schedule.find(this.props.match.params.loanId).then(r => r.data),
      Activity.loans(this.props.match.params.loanId)
        .all()
        .then(response => response.data)
    ]).catch(_ => {
      errors = { messages: [{ title: 'Something went wrong' }] };
      return [];
    });

    const comments = filter(activities, {
      activityType: 'LOAN_ACCOUNT_COMMENT_ADDED'
    });

    this.setState({
      isPending: false,
      errors,
      loan,
      schedule,
      comments
    });
  };

  approve = async ({ notes }: { notes: ?string }) => {
    const { loan } = this.state;
    const didApprove = await loan.approve({ notes });
    if (!didApprove) {
      throw loan.errors;
    }
  };

  reject = async ({
    comment,
    rejectType
  }: {
    comment: ?string,
    rejectType: 'SOFT' | 'HARD'
  }) => {
    const { loan } = this.state;
    let didReject = false;

    if (rejectType === 'SOFT') {
      didReject = await loan.softReject({ comment });
    } else if (rejectType === 'HARD') {
      didReject = await loan.reject({ comment });
    }

    if (!didReject) {
      throw loan.errors;
    }
  };

  render() {
    const { isPending, loan, comments, errors, schedule } = this.state;
    const { baseUrl, match, history, profile } = this.props;

    return (
      <Loadable
        isPending={isPending}
        error={errors}
        data={[loan, schedule, comments]}
      >
        {([loanData, scheduleData, commentsData]) => (
          <Updater value={{ onUpdate: this.refetchLoan }}>
            <Wizard
              analyticsId="LoanApprove"
              baseUrl={baseUrl}
              history={history}
              initialData={{
                loan: loanData,
                currentUser: profile,
                schedule: scheduleData,
                comments: commentsData,
                fullName: loanData.member.fullName(),
                firstName: loanData.member.firstName,
                identityValue: get(
                  'member.idDocuments[0].documentId',
                  loanData
                ),
                identityType: get('member.idDocuments[0].type', loanData),
                createdAt: new Date(loanData.createdAt) // TODO: Format as a relative time
              }}
              cancelReturnsTo={loanPath()}
              currentStep={match.params.step}
              currentSubStep={
                match.params.subStep != null
                  ? parseInt(match.params.subStep, 10)
                  : null
              }
              onReject={this.reject}
              onSubmit={this.approve}
              steps={createSteps(loanData, profile)}
              startId="overview"
              titleId="LoanApprove.shortTitle"
              type="approval"
            />
          </Updater>
        )}
      </Loadable>
    );
  }
}

export default withProfile(LoanApprove);
