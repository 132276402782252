// @flow

import { attr } from 'spraypaint';

import Base from './Base';

export const LoginActivity = Base.extend({
  static: {
    jsonapiType: 'login_activities',
    apiNamespace: '/auth'
  },
  attrs: {
    scope: attr(),
    strategy: attr(),
    identity: attr(),
    success: attr(),
    failure_reason: attr(),
    ip: attr(),
    userAgent: attr(),
    referrer: attr(),
    city: attr(),
    region: attr(),
    country: attr(),
    latitude: attr(),
    longitude: attr()
  }
});

export interface LoginActivityT {
  id: string;
  createdAt: string;
  scope: string;
  strategy: string;
  identity: string;
  success: boolean;
  failure_reason: string;
  ip: string;
  user_agent: string;
  referrer: string;
  city: string;
  region: string;
  country: string;
  latitude: number;
  longitude: number;
}
