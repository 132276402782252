// @flow

import { toDuration, parseDuration } from '@kwara/lib/src/dates';

import { type PeriodUnitsT } from '..';

export type UIDurationObjectT = {
  unit?: PeriodUnitsT,
  value?: number
};

type DurationObjectT = {
  months?: ?number,
  weeks?: ?number,
  days?: ?number,
  hours?: ?number,
  seconds?: ?number
};

// input: "P2W"
// output: {unit: "WEEKS", value: 2}
export const toDurationObjectUI = (duration: ?string): UIDurationObjectT => {
  const parsed = parseDuration(duration);
  if (parsed) {
    if (parsed.months) {
      return {
        unit: 'MONTHS',
        value: parsed.months
      };
    } else if (parsed.weeks) {
      return {
        unit: 'WEEKS',
        value: parsed.weeks
      };
    } else if (parsed.days) {
      return {
        unit: 'DAYS',
        value: parsed.days
      };
    }
  }

  return {};
};

// input: {unit: "MONTHS", value: 3}
// output: {months: 3}
export const toDurationObjectFromUI = (
  object: UIDurationObjectT
): ?DurationObjectT => {
  const { unit, value } = object;
  if (unit === 'MONTHS') {
    return {
      months: value
    };
  } else if (unit === 'WEEKS') {
    return {
      weeks: value
    };
  } else if (unit === 'DAYS') {
    return {
      days: value
    };
  }

  return null;
};

// input: (value = 3, unit = "MONTHS")
// output: "P3M"
export const toDurationFromUI = (value: number, unit: string) =>
  toDuration(toDurationObjectFromUI({ value, unit }));
