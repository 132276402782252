// @flow

import * as React from 'react';

import { saveAttachments } from '@kwara/models/src/models/Attachment';
import { DocumentList } from '@kwara/components/src/DocumentList';
import { type MemberType } from '@kwara/models/src';

import {
  EditableSection,
  type EditableConfig
} from '../../../components/EditableSection';
import { Documents as DocumentsForm } from '../../LoanAdd/components/Documents';

const config = { Component: DocumentsForm };

export const Documents = ({ member, readOnly }: EditableConfig<MemberType>) => (
  <EditableSection
    initialData={{ member }}
    readOnly={readOnly}
    config={config}
    onSave={data => {
      // this one should work except it doesn't
      // await data.save({ with: ['attachments'] });
      // so we use this hack
      return saveAttachments(member, data.attachments);
    }}
  >
    <DocumentList
      member={member}
      contexts={['loans']}
      attachments={member.attachments}
    />
  </EditableSection>
);
