//@flow
import * as React from 'react';
import isFunction from 'lodash/fp/isFunction';

import { Logger } from '@kwara/lib/src/logger';

import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';
import {
  hasErrors,
  type ErrorMsg,
  type BaseRequestProps
} from '@kwara/models/src/models/request';

import Loading from './Loading';

export { EMPTY } from '@kwara/models/src/models/request';
export { default as Loading } from './Loading';
export { default as LoadingFullScreen } from './LoadingFullScreen';

type ErrorProps = {
  error: ?Error
};

type LoadingProps = {};

type Props<Data> = {
  Error: React.ComponentType<ErrorProps>,
  Loading: React.ComponentType<LoadingProps>,
  loaded: (loadedData: Data) => React.Node,
  loader: ?Promise<Data>
};

type State<Data> = {
  result: ?Data,
  settled: boolean,
  error: ?Error
};

export class DeprecatedLoadable extends React.Component<Props<*>, State<*>> {
  static defaultProps = {
    Loading: Loading,
    Error: Loading
  };

  isUnmounted: boolean;

  state = {
    settled: false,
    result: null,
    error: null
  };

  componentDidMount() {
    this.waitForLoader();
    Logger.warn(
      `This component is deprecated. Switch the usage <DeprecatedLoadable /> to <Loadable /> or use
another component.`
    );
  }

  componentDidUpdate({ loader }: Props<*>) {
    if (loader !== this.props.loader) {
      this.waitForLoader();
    }
  }
  async waitForLoader() {
    try {
      const result = await this.props.loader;

      // Check whether the compoent is still mounted before updating the state. This avoids setting
      // state on an unmounted comp when a user changes page while the above promise is still
      // pending.
      // This is more of a quickfix as we should really cancel the this.props.loader promise
      // above. Hence, we will still log this error below so we are warned every time this happens
      // More info: https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
      if (!this.isUnmounted) {
        this.setState({
          settled: true,
          result
        });
      } else {
        // eslint-disable-next-line no-console
        console.error(
          `Trying to set state on an unmounted Component: (check the ${this.constructor.name} Component)`
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      this.setState({
        settled: true,
        error: error || { name: 'GENERAL_ERROR' }
      });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { Error, loaded, Loading, ...rest } = this.props;
    const { error, result, settled } = this.state;

    if (settled && error == null) {
      return loaded(result);
    }

    if (error != null) {
      return <Error {...rest} error={error} />;
    }

    return <Loading {...rest} error={error} />;
  }
}

export type LoadableBasePropsT<T = any> = BaseRequestProps<T>;

type LoadablePropsT<T> = LoadableBasePropsT<T> & {
  children?: React.Node | ((t: T) => React.Node),
  errorBanner?: (m: ErrorMsg[]) => React.Node
};

export const Error = () => <Loading error={{ name: 'GENERAL_ERROR' }} />;

const DefaultErrorBanner = errorMessages => (
  <div className="w-100 h-100 flex justify-center items-center">
    <ModelErrorBanner errors={errorMessages} />
  </div>
);

export function Loadable<T>({
  data,
  error,
  isPending,
  children,
  errorBanner = DefaultErrorBanner
}: LoadablePropsT<T>): React.Node {
  if (isPending) {
    return <Loading />;
  }

  if (hasErrors(error)) {
    return errorBanner(error.messages);
  }

  // This is pointless, but makes Flow a bit happier
  // so that   children?: React.Node   passes
  // so passing non-function children types correctly
  if (!children) {
    return null;
  }

  return isFunction(children) ? children(data) : children;
}
