// @flow

import { attr, belongsTo } from 'spraypaint';
import split from 'lodash/fp/split';
import get from 'lodash/fp/get';
import first from 'lodash/fp/first';

import Base, { BaseModel } from './Base';
import { User, type UserT } from './User';

const Names = Object.freeze({
  memberCreate: 'members.create',
  memberUpdate: 'members.update',
  loanCreate: 'loan.create',
  loanApprove: 'loan_state.approve',
  loanReject: 'loan_state.reject',
  loanSoftReject: 'loan_state.soft_reject',
  loanDisburse: 'loan_state.disburse',
  loanUpdate: 'loan.update',
  loanReschedule: 'loan.reschedule',
  loanRefinance: 'loan.refinance',
  loanTransactionCreate: 'loan_transactions.create',
  savingCreate: 'saving.create',
  savingUpdate: 'saving.update',
  savingTransactionCreate: 'savings_transactions.create',
  batchTransactionsImportApprove: 'import_review_state.approve',
  batchTransactionsImportReject: 'import_review_state.reject'
});

export type NamesT = $Values<typeof Names>;

export const Event = Base.extend({
  static: {
    jsonapiType: 'events'
  },
  attrs: {
    name: attr(),
    time: attr(),
    properties: attr(),
    user: belongsTo({ type: User })
  },
  methods: {
    entity() {
      return first(split('.', this.name));
    },
    action() {
      return get('1', split('.', this.name));
    }
  }
});

export interface EventT extends BaseModel<EventT> {
  name: NamesT;
  time: string;
  properties: {
    accountId?: string,
    newAccountId?: string
  };
  user: UserT;
  entity: () => string;
  action: () => string;
}
