// @flow

import { attr, hasMany } from 'spraypaint';
import filter from 'lodash/fp/filter';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';
import some from 'lodash/fp/some';
import isEmpty from 'lodash/fp/isEmpty';

import { sumBy } from '@kwara/lib/src/currency';

import Base, { type BaseModel } from './Base';
import { type UserT } from './User';

import {
  LoanApplicationApproval,
  type LoanApplicationApprovalT
} from './LoanApplicationApproval';
import Loan, { type LoanType } from './Loan';

const Actions = Object.freeze({
  REFINANCE: 'REFINANCE'
});

export type LoanApplictionActionsT = $Values<typeof Actions>;

export function totalBalance(loans: LoanType[]) {
  return sumBy('totalBalance', loans);
}

export const LoanApplication = Base.extend({
  static: {
    jsonapiType: 'loan_applications'
  },
  attrs: {
    loanId: attr(),
    amount: attr(),
    action: attr(),
    loanApplicationApprovals: hasMany({ type: LoanApplicationApproval }),
    payOffLoans: hasMany({ type: Loan })
  },
  methods: {
    role() {
      return get('[0].user.role.name', this.loanApplicationApprovals);
    },
    approversNeeded() {
      return size(this.loanApplicationApprovals);
    },
    approvals() {
      return size(
        filter(
          approval => get('state.current', approval) === 'approved',
          this.loanApplicationApprovals
        )
      );
    },
    canBeApprovedBy(user) {
      return (
        isEmpty(this.loanApplicationApprovals) ||
        some(
          approval => get('user.id', approval) === user.id,
          this.loanApplicationApprovals
        )
      );
    },
    alreadyApprovedBy(user) {
      return some(
        approval =>
          get('user.id', approval) === user.id &&
          get('state.current', approval) === 'approved',
        this.loanApplicationApprovals
      );
    },
    isFinalReview() {
      return this.approvals() === this.approversNeeded() - 1;
    },
    isRefinancing() {
      return this.action === Actions.REFINANCE;
    },
    totalPayOffLoans() {
      return totalBalance(this.payOffLoans);
    }
  }
});

export interface LoanApplicationT extends BaseModel<LoanApplicationT> {
  loanId: string;
  amount: string;
  action: ?LoanApplictionActionsT;
  loanApplicationApprovals: LoanApplicationApprovalT[];
  payOffLoans: LoanType[];
  role: () => string;
  approversNeeded: () => number;
  approvals: () => number;
  canBeApprovedBy: (u: UserT) => boolean;
  alreadyApprovedBy: (u: UserT) => boolean;
  isFinalReview: () => boolean;
  isRefinancing: () => boolean;
  totalPayOffLoans: () => string;
}
