// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import isArray from 'lodash/fp/isArray';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import Banner from '@kwara/components/src/Banner';
import { minus } from '@kwara/lib/src/currency';
import { getCurrentDate } from '@kwara/lib/src/dates';
import { Statistic } from '@kwara/components/src/Statistic';
import {
  SavingsTransaction,
  PendingSavingsTransaction,
  type SavingType,
  type BankGlAccountT,
  GlContexts,
  type MemberType
} from '@kwara/models/src';
import {
  allowedAttachments,
  UploadWidget,
  addAttachment,
  removeAttachment
} from '@kwara/components/src/UploadWidget';
import { Text } from '@kwara/components/src/Intl';
import {
  SubscribedPaymentSelectField,
  GlAccountSelect
} from '@kwara/components/src/Form';
import {
  TransactionChannels,
  getTransactionTypes,
  contexts
} from '@kwara/models/src/models/Transactions';

import { type SubStepComponentProps } from '../../../components/Wizard';
import MemberPanel from '../../../components/Payment/MemberPanel';
import NotesPanel from '../../../components/Payment/NotesPanel';
import { Grid } from '../../../components/Grid';
import { Panel } from '../../../components/ActionModal';
import { DatePicker, Field as BasicField } from '../../../components/Form';
import { Denominations } from '../../../components/Denominations';
import { store } from '../../../models/Store';

type ChequeDataT = {
  chequeNumber?: string,
  drawer?: string
};

type BankDataT = {
  bankName: string,
  bankBranch: string,
  accountNumber: string
};

type NotesT = {
  notes?: string
};

export type FormData = {
  member: MemberType,
  allGlAccounts: BankGlAccountT[],
  accounts: SavingType[],
  accountId: string,
  bankGlId?: string,
  saving?: SavingType,
  amount?: string,
  method?: string,
  reference?: string,
  glTransferId?: string
} & BankDataT &
  ChequeDataT &
  NotesT;

export const showChequeFieldsFor = [
  TransactionChannels.cheque,
  TransactionChannels.internalCheque
];
export const showBanksFieldsFor = [
  TransactionChannels.bankTransfer,
  TransactionChannels.cheque,
  TransactionChannels.internalCheque
];

export const depositConfig = {
  bank: 'bankName',
  methods: getTransactionTypes(contexts.Deposit).values,
  showChequeFieldsFor,
  showBanksFieldsFor
};

export function getMaxAmount(
  maxOpeningBalance: ?number,
  account?: SavingType
): ?number {
  if (maxOpeningBalance == null || account == null) {
    return null;
  }

  return Number(minus(maxOpeningBalance, account.balance));
}

function findMaximumAmount(account) {
  const maxOpeningBalance = get('product.maximumOpeningBalance', account);

  return getMaxAmount(maxOpeningBalance, account);
}

function getSelectedAccount({ accounts, accountId, saving }) {
  const selectedAccount = saving
    ? saving
    : find<SavingType>({ id: accountId }, accounts);

  return selectedAccount;
}

export const addDeposit = async (data: FormData) => {
  const {
    amount,
    accountId: savingsId,
    method: rawMethod,
    bankGlId,
    bankName,
    bankBranch,
    accountNumber,
    drawer,
    chequeNumber,
    notes,
    reference,
    glTransferId
  } = data;

  const Transaction = store.isMakerCheckerEnabled(amount)
    ? PendingSavingsTransaction
    : SavingsTransaction;

  const method = rawMethod === 'internal_cheque' ? 'cheque' : rawMethod;

  // TODO: This can perhaps be improved when ch14895 is actioned
  //
  const paymentMethod = method === 'glTransfer' ? glTransferId : method;

  const transaction = new Transaction({
    type: 'DEPOSIT',
    savingsId,
    bankGlId,
    amount,
    paymentMethod,
    bankName,
    bankBranch,
    accountNumber,
    drawer,
    chequeNumber,
    notes,
    reference
  });

  const didSave = await transaction.save();

  if (!didSave) {
    throw transaction.errors;
  } else {
    store.refetchTill();
  }
};

export const DepositForm = ({
  SelectField,
  StackChild,
  TextField,
  data,
  addData,
  formProps,
  onChange
}: SubStepComponentProps<FormData>) => {
  const Option = SelectField.Option;

  const { values } = formProps;
  const { method, accountId, amount } = values;
  const { member, accounts, saving } = data;

  const selectedAccount = getSelectedAccount({
    accounts,
    accountId,
    saving
  });

  const hasAccountSelection = isArray(accounts);

  const maximumAmount = findMaximumAmount(selectedAccount);

  const activateMakerChecker =
    amount && amount > store.stimaMakerCheckerMinAmount;

  const fieldNames = allowedAttachments.typesByContext(['depositForm']);

  return (
    <StackChild>
      <MemberPanel member={member} showFinancialInfo />
      <Panel>
        <div className="bb b--light-grey-400 mb4">
          {hasAccountSelection ? (
            <SelectField
              name="accountId"
              size="medium"
              labelId="PaymentForm.account"
            >
              {map<SavingType, React.Element<Option>>(
                account => (
                  <Option key={account.id} value={account.id}>
                    {account.id} &mdash; {account.product.name}
                  </Option>
                ),
                accounts
              )}
            </SelectField>
          ) : null}

          {!hasAccountSelection && selectedAccount ? (
            <Statistic
              size="medium"
              title={<Text id="PaymentForm.account" />}
              value={`${selectedAccount.id} — ${selectedAccount.product.name}`}
            />
          ) : null}
        </div>
        <Grid columns={2} width="w-50" border={false}>
          <TextField
            required
            name="amount"
            size="medium"
            leftGlyph="Currency.orgCurrency"
            labelId="PaymentForm.amount"
            errorBaseId="PaymentForm.amount"
            errorValues={{ max: get('tellerConfigs.tillMaximum', store) }}
            info={
              maximumAmount ? (
                <Text
                  id="SavingPayment.maximumAmountInfo"
                  values={{ maximumAmount }}
                />
              ) : null
            }
          />
          <BasicField
            required
            disabled
            name="date"
            size="medium"
            labelId="SavingPayment.dateLabel"
          >
            <DatePicker name="date" disabled value={getCurrentDate()} />
          </BasicField>
        </Grid>

        {activateMakerChecker ? (
          <Banner
            className="mb3"
            text="This transaction will be created in a pending state, and will require approval from the Branch Operations Manager"
            state="warning"
          />
        ) : null}

        <SubscribedPaymentSelectField
          name="method"
          config={depositConfig}
          required
          // see ch10266
          inputOnChange={() => addData({ bankGlId: '' })}
        />

        <GlAccountSelect
          method={method}
          addData={addData}
          context={GlContexts.DEPOSIT}
        />
        <SelectField
          name="sourceOfMoney"
          size="medium"
          disabled={!activateMakerChecker}
          labelId="Stima.SourceOfMoney"
          infoId="Stima.SourceOfMoneyInfo"
        >
          {map(
            reason => (
              <Option key={reason} value={reason}>
                {reason}
              </Option>
            ),
            ['Investment', 'Donation', 'Sell of goods', 'Other']
          )}
        </SelectField>
        {method ? (
          <TextField
            name="reference"
            size="medium"
            labelId="PaymentForm.reference"
          />
        ) : null}
      </Panel>
      {method === 'cash' ? (
        <Panel>
          <Denominations amount={amount} />
        </Panel>
      ) : null}

      {method === 'cheque' ? (
        <Panel>
          {fieldNames.map(f => (
            <UploadWidget
              key={f.name}
              onChange={v => onChange({ attachments: addAttachment(data, v) })}
              onRemove={fileName =>
                onChange({
                  attachments: removeAttachment(data, fileName)
                })
              }
              member={data}
              fileName={f.name}
              checked={data.attachments && data.attachments.length}
              textId={`DocumentUploads.${f.label}.label`}
            />
          ))}
        </Panel>
      ) : null}

      <NotesPanel />
    </StackChild>
  );
};

const ifCheque = {
  isRequired: (_, allData) => showChequeFieldsFor.includes(allData.method)
};

const ifChequeOrBank = {
  isRequired: (_, allData) => showBanksFieldsFor.includes(allData.method)
};

DepositForm.validate = ({ maxAllowedDeposit = store.maxAllowedDeposit }) => ({
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    max: maxAllowedDeposit,
    custom: (target, data) => {
      const { accounts, accountId, saving } = data;
      const selectedAccount = getSelectedAccount({
        accounts,
        accountId,
        saving
      });

      const maximumAmount = findMaximumAmount(selectedAccount);

      if (maximumAmount && Number(target) > maximumAmount) {
        return 'rangeOverflow';
      }

      return null;
    }
  },
  method: { isRequired: () => true },
  bankName: ifChequeOrBank,
  bankBranch: ifChequeOrBank,
  accountNumber: ifChequeOrBank,
  chequeNumber: ifCheque,
  drawer: ifCheque,
  sourceOfMoney: {
    isRequired: (_t, d) => {
      return Number(d.amount) > store.stimaMakerCheckerMinAmount;
    }
  },
  ...GlAccountSelect.validate
});
