//@flow

import * as React from 'react';

import { ProfilePhotoUpload } from '../../../components/ProfilePhotoUpload';
import { type SubStepComponentProps } from '../../../components/Wizard';
import * as recordValidations from '../../../lib/recordValidations';

import style from '../index.module.css';

export const NameFields = ({
  SelectField,
  TextField
}: SubStepComponentProps<>) => (
  <>
    <span className={style.TitleSelect}>
      <SelectField
        name="title"
        labelId="AddMember.Profile.MemberTitle.label"
        required={true}
      >
        <SelectField.Option translationId="" value="" />
        <SelectField.Option
          translationId="AddMember.Profile.MemberTitle.Mr"
          value="Mr"
        />
        <SelectField.Option
          translationId="AddMember.Profile.MemberTitle.Mrs"
          value="Mrs"
        />
        <SelectField.Option
          translationId="AddMember.Profile.MemberTitle.Ms"
          value="Ms"
        />
      </SelectField>
    </span>
    <TextField
      name="firstName"
      errorBaseId="AddMember.Profile.Name"
      labelId="AddMember.Profile.FirstName.label"
      required={true}
    />
    <TextField
      name="middleName"
      errorBaseId="AddMember.Profile.Name"
      labelId="AddMember.Profile.MiddleName.label"
    />
    <TextField
      name="lastName"
      errorBaseId="AddMember.Profile.Name"
      labelId="AddMember.Profile.LastName.label"
      required={true}
    />
  </>
);
NameFields.validate = {
  title: {
    isRequired: () => true
  },
  firstName: {
    isRequired: () => true,
    maxlength: 30,
    ...recordValidations.personName
  },
  middleName: { ...recordValidations.personName },
  lastName: {
    isRequired: () => true,
    maxlength: 30,
    ...recordValidations.personName
  }
};

export function Name(props: SubStepComponentProps<>) {
  const { StackChild, onChange, data } = props;
  return (
    <StackChild>
      <div className="flex">
        <ProfilePhotoUpload onChange={onChange} data={data} />

        <div className="flex-auto">
          <NameFields {...props} />
        </div>
      </div>
    </StackChild>
  );
}
Name.validate = { ...NameFields.validate };
