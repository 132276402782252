//@flow
import * as React from 'react';

import { type SavingProductType } from '@kwara/models/src';

import { DeprecatedLoadable } from '@kwara/components/src/Loadable';
import { SavingsProductSelector } from '../../../components/ProductSelector';

import { type SubStepComponentProps } from '../../../components/Wizard';

export default ({
  addDataAndContinue,
  customProps,
  StackChild
}: SubStepComponentProps<{
  savingProductsPromise: Promise<SavingProductType>
}>) => {
  const onSelect = (product: SavingProductType) => {
    addDataAndContinue({ savingProduct: product });
  };

  // TODO: Better error handling if product is not set
  //       e.g. when the page is reloaded here
  if (customProps == null) {
    return null;
  }

  return (
    <StackChild size="wide">
      <DeprecatedLoadable
        loader={customProps.savingProductsPromise}
        loaded={products => (
          <SavingsProductSelector products={products} onSelect={onSelect} />
        )}
      />
    </StackChild>
  );
};
