// @flow

import { attr, hasMany, belongsTo } from 'spraypaint';
import find from 'lodash/find';
import includes from 'lodash/includes';
import map from 'lodash/fp/map';
import split from 'lodash/fp/split';
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import values from 'lodash/fp/values';
import compact from 'lodash/fp/compact';
import join from 'lodash/fp/join';
import pipe from 'lodash/fp/pipe';
import _capitalize from 'lodash/fp/capitalize';
import size from 'lodash/fp/size';
import axios from 'axios';

import { Logger } from '@kwara/lib/src/logger';
import { formatHumanDate } from '@kwara/lib/src/dates';

import filterEmptyValues from '../lib/filterEmptyValues';
import { type ActivityType } from './Activity';
import { type LoanType } from './Loan';
import { type AttachmentT } from './Attachment';
import {
  createCancellablePromise,
  SearchCancellation,
  type SavingType,
  type SavingProductId,
  type IdDocumentType
} from '..';
import IdDocument from './IdDocument';
import MemberAddress from './MemberAddress';
import Base, { type BaseModel, type IncludesT } from './Base';
import { Branch, type BranchT } from './Branch';
import createModelErrors, {
  createErrorsFromApiResponse
} from './createModelErrors';

export const MemberStates = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  EXITED: 'EXITED',
  BLACKLISTED: 'BLACKLISTED'
};

export const MemberEvents = {
  APPROVE: 'approve',
  REJECT: 'reject',
  SOFT_REJECT: 'soft_reject',
  EXIT: 'exit'
};

export type MemberState = $Values<typeof MemberStates>;
export type MemberEvent = $Keys<typeof MemberEvents>;

// Freeze so Flow can type correctly https://stackoverflow.com/a/51166430/1446845
export const EmploymentStatuses = Object.freeze({
  EMPLOYED: 'employed',
  SELF_EMPLOYED: 'self_employed',
  OTHER: 'other'
});
export type EmployemntStatusT = $Values<typeof EmploymentStatuses>;

type ApproveParams = {
  product_id?: SavingProductId,
  amount?: number,
  share_capital_amount?: number,
  notes?: string,
  comment?: string
};

type RejectParams = {
  comment?: string
};

type ExitParams = {
  comment?: string
};

type TransitionParams = ApproveParams | ExitParams;

export const capitalize = (name: string) => {
  return pipe(
    split(' '),
    map(_capitalize),
    join(' ')
  )(name);
};

export const fullName = (arr: string[]) => {
  return pipe(
    compact,
    map(capitalize),
    join(' ')
  )(arr);
};

const Member = Base.extend({
  static: {
    jsonapiType: 'members',

    search({
      term,
      states = [MemberStates.ACTIVE, MemberStates.INACTIVE]
    }: {
      term: string,
      states: MemberState[]
    }) {
      const cancelSource = axios.CancelToken.source();

      const url = `${Member.fullBasePath()}/search?q=${escape(
        term
      )}&filter[state]=${states.join(',')}`;

      const options = {
        ...Member.fetchOptions(),
        cancelToken: cancelSource.token
      };

      const promise = axios(url, options).then(
        response => {
          if (response.status > 299) {
            throw new Error(`Response not OK`);
          }

          const jsonResult = response.data;

          // See: https://github.com/jsonapi-suite/jsorm/blob/master/src/scope.ts#L335
          return jsonResult.data.map(record => {
            return Member.fromJsonapi(record, jsonResult.data);
          });
        },
        err => {
          if (axios.isCancel(err)) {
            throw new SearchCancellation();
          }

          throw err;
        }
      );

      return createCancellablePromise(promise, cancelSource);
    }
  },
  attrs: {
    title: attr(),
    firstName: attr(),
    middleName: attr(),
    lastName: attr(),

    gender: attr(),
    dateOfBirth: attr(),
    maritalStatus: attr(),

    attachments: hasMany(),
    shareCapitalAmount: attr(),

    addresses: hasMany('member_addresses'),

    totalLoansBalance: attr(),
    totalLoans: attr(),
    totalSavings: attr(),
    totalGuaranteed: attr(),
    eligibleAmount: attr(),
    eligibilityMultiplier: attr(),
    standing: attr(),
    accruedInterest: attr(),

    mbankingStatus: attr(),

    guaranteedLoans: hasMany('loans'),

    // Write:
    phoneNumber: attr(),
    secondaryPhoneNumber: attr(),
    email: attr(),

    kraPin: attr(),

    idDocuments: hasMany('members_id_documents'),

    notes: attr(),

    state: attr({ persist: false }),

    activities: hasMany(), // Only available in Member API
    savings: hasMany(),
    loans: hasMany(),

    // Read
    nextOfKin: belongsTo(),

    // Write
    kin: attr(),

    employmentStatus: attr(),
    profession: attr(),
    employer: attr(),
    staffId: attr(),
    business: attr(),
    workEmail: attr(),
    employerEmail: attr(),
    employerPhoneNumber: attr(),

    grossIncome: attr(),
    netIncome: attr(),
    otherDeductibles: attr(),
    otherIncomeAmount: attr(),
    disposableIncomeAmount: attr(),
    incomeSource: attr(),
    termsOfService: attr(),
    govEmployee: attr(),
    isStaff: attr(),
    isDelegate: attr(),
    isDirector: attr(),
    isGroup: attr(),

    memberBankName: attr(),
    memberBankBranch: attr(),
    memberBankAccount: attr(),

    joiningFeeReference: attr(),
    branch: belongsTo({ type: Branch }) //Branch data only exists for Member App
  },
  methods: {
    // For some reason atm the models sent to the backend for saving a resource are slightly different
    // than the ones returned when querying for the same resource (WTF?).
    // For example when we request a member, his phone number and email are under:
    // { contact: phone_number: '+1234', email: 'a@b.c' }
    // But when we save it the backend expects all flattened fields, like:
    // { phone_number: '+1234', email: 'a@b.c' }
    // This means that we need to go back and forth from one shape to the other when receiving data,
    // using it prefill a form and then use the updated form to update the DB.
    // This is a hack to workaround this problem. Call this method after fetching and it will
    // prefill the form, so the shape expected in the frontend is rehydrated in the model.
    deserialize() {
      // Only one left.......

      this.kin = pipe(
        get('attributes'),
        values,
        compact
      )(this.nextOfKin);
      return this;
    },
    hasNextOfKin() {
      return this.deserialize().kin && this.kin.length > 0;
    },
    isEventPermitted(event: MemberEvent) {
      return this.state && includes(this.state.permitted_events, event);
    },
    fullName() {
      return fullName([this.firstName, this.middleName, this.lastName]);
    },
    nameWithTitle() {
      if (this.title) {
        return `${this.title}. ${this.fullName()}`;
      }
      return this.fullName();
    },
    formattedDateOfBirth() {
      return this.dateOfBirth ? formatHumanDate(this.dateOfBirth) : null;
    },
    isPendingApproval() {
      return this.state.current === MemberStates.PENDING_APPROVAL;
    },
    pendingLoans() {
      return filter(l => l.isPendingApproval(), this.loans);
    },
    numberPendingLoans() {
      return size(this.pendingLoans());
    },
    isMbankingBlacklisted() {
      return this.mbankingStatus === 'DISALLOWED';
    },
    isApproved() {
      return [MemberStates.INACTIVE, MemberStates.ACTIVE].includes(
        this.state.current
      );
    },
    isExited() {
      return this.state.current === MemberStates.EXITED;
    },
    canBeExited() {
      return this.isEventPermitted(MemberEvents.EXIT);
    },
    joinedAt() {
      return this.createdAt;
    },
    getIdDocument(type) {
      const document = find(this.idDocuments, { type });
      return document ? document.documentId : null;
    },
    nationalId() {
      return this.getIdDocument('NATIONAL');
    },
    historicalId() {
      return this.getIdDocument('Historical Member ID');
    },
    address(type: 'physical' | 'postal') {
      return this.addresses.length > 0
        ? this.addresses[0][`${type}Address`]
        : null;
    },
    savingsEligibleForTransactions() {
      return filter(saving => saving.transactionsPermitted(), this.savings);
    },
    loansEligibleForRepayment() {
      return filter(loan => loan.isApproved() && !loan.isPaidOff(), this.loans);
    },
    isEligibleForLoan() {
      return this.eligibleAmount > 0;
    },
    async approve(params: ApproveParams = {}): Promise<boolean> {
      if (this.isEventPermitted(MemberEvents.APPROVE)) {
        return await this.transition(MemberEvents.APPROVE, params);
      }

      this.errors = createModelErrors({
        base: 'APP_MEMBER_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async reject({ comment }: RejectParams = {}): Promise<boolean> {
      if (this.isEventPermitted(MemberEvents.REJECT)) {
        return await this.transition(MemberEvents.REJECT, { comment });
      }

      this.errors = createModelErrors({
        base: 'APP_MEMBER_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async softReject({ comment }: RejectParams = {}): Promise<boolean> {
      if (this.isEventPermitted(MemberEvents.SOFT_REJECT)) {
        return await this.transition(MemberEvents.SOFT_REJECT, { comment });
      }

      this.errors = createModelErrors({
        base: 'APP_MEMBER_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async exit({ comment }: ExitParams) {
      if (this.isEventPermitted(MemberEvents.EXIT)) {
        return await this.transition(MemberEvents.EXIT, { comment });
      }

      this.errors = createModelErrors({
        base: 'UI_APP_MEMBER_INVALID_STATE_TRANSITION_EXIT'
      });

      return false;
    },
    async transition(event: MemberEvent, params: ?TransitionParams = {}) {
      const url = `${Member.url(this.id)}/state`;
      const options = {
        ...Member.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({
          data: { attributes: filterEmptyValues({ event, ...params }) }
        })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();

          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (error) {
        Logger.error(
          'Error transitioning member states',
          JSON.stringify(error)
        );
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    }
  }
});

/**
 * Returns a member will all included scopes
 *
 */
Member.full = () =>
  Member.includes('loans')
    .includes('savings')
    .includes('addresses')
    .includes('next_of_kin')
    .includes('attachments') // can't make it work atm
    .includes('id_documents');
// TODO: Enable this when loans member is guaranteeing
//       should be displayed
//.includes({ guaranteed_loans: 'product' })

// This is like calling new Member(), but it also initialises
// the relationships the we always want to have in the basic member
Member.new = (props = {}) => {
  const { idDocuments = [{}] } = props; // the default here makes sure we always have at least an empty Document model
  const idDocumentsList = idDocuments.map(d => new IdDocument(d));
  const addresses = [new MemberAddress()];
  return new Member({ ...props, idDocuments: idDocumentsList, addresses });
};

type MemberStateT = {
  current: 'ACTIVE' | 'INACTIVE',
  permittedEvents: string[]
};

export interface MemberType extends BaseModel<MemberType> {
  activities: ActivityType[];
  id: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  totalSavings: number;
  totalLoansBalance: number;
  totalGuaranteed: number;
  eligibleAmount: number;
  dateOfBirth: string;
  contact: MemberContactT;
  idDocuments: IdDocumentType[];
  phoneNumber: string;
  secondaryPhoneNumber: string;
  email: string;
  notes: string;
  state: MemberStateT;
  kin: string[];
  createdAt: string;
  maritalStatus: ?string;
  employer: string;
  staffId: string;
  business: string;
  incomeSource: string;
  otherIncomeAmount: string;
  disposableIncomeAmount: string;
  gender: ?('MALE' | 'FEMALE');
  totalLoans: number;
  standing: number;
  shareCapitalAmount: number;
  accruedInterest: number;
  savings: SavingType[];
  nextOfKin: [];
  address: (t: string) => string;
  formattedDateOfBirth: () => string;
  profession: string;
  kraPin: string;
  documents: [
    {
      type: 'passport' | 'national',
      id: string
    }
  ];
  loans: LoanType[];
  guaranteedLoans: LoanType[];
  employmentStatus: ?EmployemntStatusT;
  joinedAt: () => string;
  fullName: () => string;
  nameWithTitle: () => string;
  canBeExited: () => boolean;
  loansEligibleForRepayment: () => LoanType[];
  savingsEligibleForTransactions: () => SavingType[];
  isEligibleForLoan: () => boolean;
  historicalId: () => string;
  hasNextOfKin: () => boolean;

  exit: ExitParams => Promise<boolean>;
  isExited: () => boolean;

  employerPhoneNumber: string;
  joiningFeeReference?: string;
  attachments: AttachmentT[];
  workEmail: string;
  employerEmail: string;

  grossIncome: string;
  netIncome: string;
  otherDeductibles: string;

  termsOfService: 'Contract' | 'Permanent';
  govEmployee: boolean;
  isStaff: boolean;
  isDelegate: boolean;
  isDirector: boolean;
  isGroup: boolean;

  memberBankName: string;
  memberBankBranch: string;
  memberBankAccount: string;

  createdAtDate: () => Date;
  includes: (p: IncludesT) => MemberType;
  find: (p: string) => Promise<{ data: MemberType }>;
  errors: string[];
  branch: BranchT; //Branch data only exists for Member App
}

export default (Member: typeof Member);
