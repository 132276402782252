import * as React from 'react';
import { withRouter } from 'react-router-dom';
import map from 'lodash/map';

import {
  LoanBaseStates,
  type LoanType,
  type LoanBaseState
} from '@kwara/models/src';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import OverviewList from '../../../../components/OverviewList';
import Visible from '../../../../components/Visible';
import LoanListTable from '../LoanListTable';
import { AppPermissions } from '../../../../models';
import { loanPath } from '../../../../lib/urls';

type Props = {
  filterBy: ?LoanBaseState,
  hasMore?: boolean,
  loans: LoanType[],
  loading?: boolean,
  errors: Object[],
  onFilterChange: (filterBy: LoanBaseState) => void,
  onLoadMoreData: () => void,
  totalNumResults: number
};

const LoanStateFilter = [
  { value: '', translationId: 'LoanList.noFilter' },
  ...map(LoanBaseStates, value => ({
    value,
    translationId: `StatusTag.${value}`
  }))
];

// TODO: What's the best way to handle application-level
//       routing?
const CreateLoanButton = withRouter(() => (
  <Button size="regular" type="primary" to={loanPath({ action: 'create' })}>
    <Text id="LoanList.create" />
  </Button>
));

const CreateLoanButtonWithPermissions = () => (
  <Visible to={AppPermissions.AddLoans}>
    <CreateLoanButton />
  </Visible>
);

export default withRouter(
  ({
    filterBy,
    hasMore = false,
    loading = false,
    loans = [],
    errors,
    onFilterChange,
    onLoadMoreData,
    totalNumResults
  }: Props) => (
    <OverviewList
      actions={[<CreateLoanButtonWithPermissions key="create-loan-button" />]}
      filterBy={filterBy}
      filters={LoanStateFilter}
      filterDisabled={loading}
      headerId="LoanList.heading"
      labelId="LoanList.filteredCount"
      items={loans}
      onFilterChange={onFilterChange}
      table={
        <LoanListTable
          hasMore={hasMore}
          groupBy={/*Groups.state*/ null}
          loading={loading}
          loans={loans}
          errors={errors}
          orderBy={/* Orders.updated */ null}
          onLoadMoreData={onLoadMoreData}
        />
      }
      totalNumResults={totalNumResults}
    />
  )
);
