// @flow

import React from 'react';

import { SavingProduct } from '@kwara/models/src';

import Wizard from '../../components/Wizard';
import { settingPath } from '../../lib/urls';
import { steps } from './config';

type Props = {
  baseUrl: string,
  match: {
    params: {
      step: string,
      subStep: string
    }
  }
};

export const SavingProductAdd = (props: Props) => {
  // TO DO: Waiting for API Support for this to work
  const createSavingProduct = async data => {
    const product = new SavingProduct(data);

    const didSave = await product.save();

    if (!didSave) {
      throw product.errors;
    }
  };

  return (
    <Wizard
      baseUrl={props.baseUrl}
      history={history}
      initialData={{}}
      cancelReturnsTo={settingPath({ action: 'savings' })}
      currentStep={props.match.params.step}
      currentSubStep={
        props.match.params.subStep != null
          ? parseInt(props.match.params.subStep, 10)
          : null
      }
      onSubmit={createSavingProduct}
      steps={steps}
      startId="product"
      titleId="SavingProductAdd.titleId"
      type="approval"
    />
  );
};
