//@flow

import * as React from 'react';

import { type SubStepComponentProps } from '../../../components/Wizard';

export default ({ StackChild, TextArea }: SubStepComponentProps<>) => (
  <StackChild>
    <TextArea name="notes" placeholderId="AddMember.Notes.placeholder" />
  </StackChild>
);
