// @flow

import * as React from 'react';

import { type LoanType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Statistic, PenaltyStatistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { Panel } from '../ActionModal';

type Props = {
  loan: LoanType
};

export default ({ loan }: Props) => (
  <Panel>
    <Statistic
      title={<Text id="Payment.loan" />}
      value={loan.id}
      size="large"
      padding={false}
    />
    <StatisticRow widthClass="w-50" border={true}>
      <Statistic
        title={<Text id="Payment.member" values={{ id: loan.member.id }} />}
        size="medium"
        value={loan.member.fullName()}
      />
      <PenaltyStatistic
        title={<Text id="Payment.feesBalance" />}
        size="medium"
        errorValue={loan.fees.balance}
      />
    </StatisticRow>
  </Panel>
);
