// @flow
import * as React from 'react';

import styles from './index.module.css';
import logo from './logo.svg';

const Logo = () => (
  <div className="flex items-center">
    <a href="/">
      <img alt="Kwara" className={styles.logo} src={logo} />
    </a>
  </div>
);

export default Logo;
