// @flow

import * as React from 'react';
import flatMap from 'lodash/flatMap';

import { Link } from '@kwara/components/src/Link';
import { memberPath } from '../../../lib/urls';

import type { GuaranteeType, LoanType, MemberType } from '@kwara/models/src';

const Guarantor = ({ member }: { member: MemberType }) => {
  return <Link to={memberPath({ id: member.id })}>{member.fullName()}</Link>;
};

const LoanGuarantors = ({ loan }: { loan: LoanType }) => {
  const guarantors = flatMap(
    loan.guarantors,
    (guarantee: GuaranteeType, index: number) => {
      if (guarantee != null && guarantee.member) {
        const node = (
          <Guarantor key={guarantee.member.id} member={guarantee.member} />
        );
        return index === 0 ? node : [', ', node];
      }
    }
  );

  return <>{guarantors.length > 0 ? guarantors : 'None'}</>;
};

export default LoanGuarantors;
