//@flow
import * as React from 'react';
import lodashGroupBy from 'lodash/groupBy';
import lodashMap from 'lodash/map';
import lodashOrderBy from 'lodash/orderBy';

import StatusTag from '@kwara/components/src/StatusTag';
import { type SavingType } from '@kwara/models/src';
import { Percent, DateTime } from '@kwara/components/src/Intl';

import { Currency } from '../../../../components/Currency';
import type { ISODateString, SavingState } from '../../../../lib/types';
import { savingPath } from '../../../../lib/urls';
import Table, * as table from '../../../../components/Table';

export const Groups = {
  state: 'state'
};

export const Orders = {
  updated: 'updated_at'
};

type Group = $Values<typeof Groups>;
type Order = $Values<typeof Orders>;

type FormattedSaving = {
  id: string,
  name: string,
  account: string,
  balance: number,
  interestRate: number,
  updated: ?ISODateString,
  state: SavingState
};

const formatter = (model: SavingType): FormattedSaving => ({
  id: model.id,
  name: model.member.fullName(),
  account: model.product.name,
  balance: model.balance,
  interestRate: model.interestObject().rate,
  updated: model.updatedAt,
  state: model.state.current
});

const Row = ({ item, pathTo }: { item: SavingType }) => {
  const f = formatter(item);

  return (
    <>
      <table.Row to={pathTo}>
        <table.Cell to={pathTo} className="grey-400 kw-numeric">
          {f.id}
        </table.Cell>
        <table.Cell to={pathTo} className="mw5">
          {f.name}
        </table.Cell>
        <table.Cell to={pathTo}>{f.account}</table.Cell>
        <table.Cell to={pathTo} align="right">
          <Currency value={f.balance} />
        </table.Cell>
        <table.Cell to={pathTo} align="right">
          {f.interestRate != null ? (
            <Percent value={f.interestRate / 100} />
          ) : (
            '-'
          )}
        </table.Cell>
        <table.Cell to={pathTo}>
          <DateTime value={f.updated} />
        </table.Cell>
        <table.Cell to={pathTo}>
          <StatusTag size="small" state={f.state} />
        </table.Cell>
      </table.Row>
    </>
  );
};

const createGroupWithSavings = ({
  key,
  columns,
  group,
  loans
}: {
  key: string,
  columns: number,
  group: Group,
  loans: SavingType[]
}) => (
  <React.Fragment key={key}>
    {group !== 'none' && (
      <table.GroupingRow
        cols={columns}
        translationId={`SavingListTableGroup.${group}`}
      />
    )}
    {loans.map(item => (
      <Row key={item.id} item={item} pathTo={savingPath({ id: item.id })} />
    ))}
  </React.Fragment>
);

type GroupedItems = { [id: Group]: SavingType[] };

const itemsGroupedByAttribute = (
  savings: SavingType[],
  attribute: Group
): GroupedItems =>
  lodashGroupBy(savings, ({ attributes }) => attributes[attribute]);

const orderByAttribute = (
  savings: SavingType[],
  attribute: Order
): SavingType[] =>
  // $FlowFixMe should be lodashOrderBy<SavingType>
  lodashOrderBy(savings, [({ attributes }) => attributes[attribute]], ['desc']);

export default ({
  hasMore = false,
  groupBy = Groups.state,
  loading,
  errors,
  savings,
  orderBy = Orders.updated,
  onLoadMoreData
}: {
  hasMore?: boolean,
  groupBy: Group,
  loading: boolean,
  errors: Object[],
  savings: SavingType[],
  orderBy: Order,
  onLoadMoreData: () => void
}) => {
  const groupedSavings = groupBy
    ? itemsGroupedByAttribute(savings, groupBy)
    : {
        none: savings
      };
  const columns = 7;

  return (
    <Table
      heading={
        <table.Row>
          <table.Heading translationId="SavingListTable.id" />
          <table.Heading translationId="SavingListTable.member" />
          <table.Heading translationId="SavingListTable.account" />
          <table.Heading
            align="right"
            translationId="SavingListTable.balance"
          />
          <table.Heading
            align="right"
            translationId="SavingListTable.interestRate"
          />
          <table.Heading translationId="SavingListTable.updated" />
          <table.Heading translationId="SavingListTable.status" />
        </table.Row>
      }
      footer={
        <table.Footer
          colSpan={7}
          isPending={loading}
          onNext={onLoadMoreData}
          hasMore={hasMore}
          errors={errors}
          items={savings}
          translationBaseId="SavingListTable"
        />
      }
    >
      {lodashMap(groupedSavings, (savings, group) =>
        createGroupWithSavings({
          key: group,
          columns,
          group,
          loans: orderByAttribute(savings, orderBy)
        })
      )}
    </Table>
  );
};
