// @flow

import * as React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import compact from 'lodash/fp/compact';

import { LoanTransactionsPDF } from '@kwara/components/src/LoanTransactionsPDF';
import { SchedulePDF } from '@kwara/components/src/SchedulePDF';
import Button from '@kwara/components/src/Button';
import { DeprecatedLoadable } from '@kwara/components/src/Loadable';
import PageLayout from '@kwara/components/src/PageLayout';
import { Text } from '@kwara/components/src/Intl';
import {
  type LoanType,
  LoanTransaction,
  Schedule as ScheduleModel,
  type ScheduleType,
  type LoanTransactionType
} from '@kwara/models/src';
import { LoanFetch } from '@kwara/models/src/models/Loan';
import { AppPermissions, permission } from '../../models';
import { loanPath } from '../../lib/urls';
import { Activity } from './components/Activity';
import {
  AsNavigation,
  BackToTopNavigation
} from '../../components/DetailNavigation';
import { ButtonMenu } from '../../components/ButtonMenu';
import { DetailSection } from '../../components/Detail/DetailSection';
import DetailSubsection from '../../components/DetailSubsection';
import { Updater } from '../../components/EditableSection';
import { PrintPDFButton } from '../../components/PrintPDFButton';

import { Disbursement } from './components/Disbursement';
import FeesAndPenalties from './components/FeesAndPenalties';
import Head from '../../components/Head';
import { Overview } from './components/Overview';
import { Repayment } from './components/Repayment';
import { Documents } from './components/Documents';
import { Securities } from './components/Securities';
import { Schedule } from './components/Schedule';
import { Transactions } from './components/Transactions';

import Visible from '../../components/Visible';

import { type DetailPageProps } from '..';

type Props = DetailPageProps<{ loanId: string }> & {
  hiddenSections: string[],
  showCommentCreate: boolean,
  showMargins: boolean,
  showOverviewActions: boolean,
  readOnly?: boolean
};

const Hidden = ({ when, within, children }) =>
  within.includes(when) ? null : children;

const primaryButtonForLoan = (loan: LoanType) => {
  if (loan.canDisburse() && permission.to(AppPermissions.DisburseLoans)) {
    // Disbursal case 1
    // where the loan has been refinanced
    if (
      loan.isRefinancing() &&
      permission.to(AppPermissions.DisburseRefinancedLoan)
    ) {
      return {
        title: 'LoanDetail.refinanceDisburse',
        url: loanPath({ id: loan.id, action: 'disburse' }),
        disabled: false
      };
    }

    // Disbursal case 2
    // a normal disbursal
    if (!loan.isRefinancing() && permission.to(AppPermissions.DisburseLoans)) {
      return {
        title: 'LoanDetail.disburse',
        url: loanPath({ id: loan.id, action: 'disburse' }),
        disabled: false
      };
    }
  }

  if (loan.canMakeRepayment() && permission.to(AppPermissions.AddRepayments)) {
    return {
      title: 'LoanDetail.add',
      url: loanPath({ id: loan.id, action: 'repayment' }),
      disable: false
    };
  }

  return {};
};

class LoanDetail extends React.Component<
  Props,
  {
    schedule: ?ScheduleType,
    transactions: ?(LoanTransactionType[]),
    loanPromise: Promise<LoanType>
  }
> {
  static defaultProps = {
    hiddenSections: [],
    showCommentCreate: true,
    showMargins: true,
    showOverviewActions: true
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      schedule: null,
      transactions: null,
      loanPromise: LoanFetch(this.props.match.params.loanId)
    };
  }

  fetchLoanPromise = () => {
    this.setState({
      loanPromise: LoanFetch(this.props.match.params.loanId)
    });
  };

  componentDidMount() {
    ScheduleModel.find(this.props.match.params.loanId).then(({ data }) =>
      this.setState({ schedule: data })
    );
    LoanTransaction.where({ loan_id: this.props.match.params.loanId })
      .all()
      .then(({ data }) => this.setState({ transactions: data }));
  }

  historyState = () => this.props.history.location.state || {};

  back = () => {
    if (this.historyState().useBrowserBack) {
      this.props.history.goBack();
    } else {
      this.props.history.push(loanPath());
    }
  };

  renderBody = ({
    hiddenSections,
    loan,
    showCommentCreate
  }: Props & { loan: LoanType }) => {
    const { member } = loan;

    const { transactions } = this.state;

    return (
      <Updater value={{ onUpdate: this.fetchLoanPromise }}>
        <>
          <Head titleId="LoanDetail.title" values={{ id: loan.id }} />

          <DetailSection
            id="details"
            title={<Text id="LoanDetail.details" />}
            subtitle={<BackToTopNavigation />}
          >
            <DetailSubsection
              title="Schedule"
              headerRight={
                <PrintPDFButton
                  renderPDF={props => (
                    <SchedulePDF
                      loan={loan}
                      member={member}
                      schedule={this.state.schedule}
                      {...props}
                    />
                  )}
                />
              }
            >
              <Schedule schedule={this.state.schedule} />
            </DetailSubsection>

            <Hidden when="transactions" within={hiddenSections}>
              <DetailSubsection
                title="Transactions"
                headerRight={
                  transactions ? (
                    <PrintPDFButton
                      ariaLabel="PrintTransactions"
                      renderPDF={props => (
                        <LoanTransactionsPDF
                          loan={loan}
                          member={member}
                          transactions={transactions}
                          {...props}
                        />
                      )}
                    />
                  ) : null
                }
              >
                <Transactions transactions={transactions} />
              </DetailSubsection>
            </Hidden>

            <Hidden when="feesAndPenalties" within={hiddenSections}>
              <DetailSubsection
                title={<Text id="LoanDetail.feesAndPenalties" />}
                classNames="hide-on-print"
              >
                <FeesAndPenalties loan={loan} />
                {loan.canAddFee() ? (
                  <Visible to={AppPermissions.AddPenalties}>
                    <Button to={loanPath({ id: loan.id, action: 'penalty' })}>
                      <Text id="LoanDetail.FeesAndPenalties.addPenaltyFee" />
                    </Button>
                  </Visible>
                ) : null}
              </DetailSubsection>
            </Hidden>
          </DetailSection>

          <DetailSection
            id="security"
            title={<Text id="LoanDetail.security" />}
            subtitle={<BackToTopNavigation />}
            classNames="hide-on-print"
          >
            <Securities loan={loan} />
            <div className="cf" />
          </DetailSection>

          <DetailSection
            id="documents"
            title={<Text id="LoanDetail.documents" />}
            subtitle={<BackToTopNavigation />}
            classNames="hide-on-print"
          >
            <Documents member={loan.member} />
          </DetailSection>

          <DetailSection
            id="repayment"
            title={<Text id="LoanDetail.repayment" />}
            subtitle={<BackToTopNavigation />}
            classNames="hide-on-print"
          >
            <Repayment loan={loan} refetch={this.fetchLoanPromise} />
          </DetailSection>

          <DetailSection
            id="disbursement"
            title={<Text id="LoanDetail.disbursement" />}
            subtitle={<BackToTopNavigation />}
            classNames="hide-on-print"
          >
            <Disbursement loan={loan} />
          </DetailSection>

          <DetailSection
            id="activity"
            title={<Text id="LoanDetail.activity" />}
            subtitle={<BackToTopNavigation />}
            classNames="hide-on-print"
          >
            <Activity loanId={loan.id} showCommentCreate={showCommentCreate} />
          </DetailSection>
        </>
      </Updater>
    );
  };

  renderContent = (loan: LoanType) => {
    const { title, url, disabled } = primaryButtonForLoan(loan);
    const { showMargins, showOverviewActions, readOnly = true } = this.props;
    const { schedule } = this.state;

    const disableWriteOff = !loan.canWriteOff();
    const disableReschedule = !loan.canReschedule();
    const disableRefinance = !loan.canRefinance();
    const disablePayOff = !loan.canMakeRepayment();

    const menuItems = [
      permission.to(AppPermissions.WriteOffLoans) ? (
        <ButtonMenu.Item
          key="loanWriteOff"
          disabled={disableWriteOff}
          to={loanPath({ id: loan.id, action: 'writeoff' })}
        >
          <Text id="LoanDetail.more.WriteOff" />
        </ButtonMenu.Item>
      ) : null,
      permission.to(AppPermissions.RescheduleLoans) ? (
        <ButtonMenu.Item
          key="loanReschedule"
          disabled={disableReschedule}
          to={loanPath({ id: loan.id, action: 'reschedule' })}
        >
          <Text id="LoanDetail.more.Reschedule" />
        </ButtonMenu.Item>
      ) : null,
      permission.to(AppPermissions.RefinanceLoans) ? (
        <ButtonMenu.Item
          key="loanRefinance"
          disabled={disableRefinance}
          to={loanPath({ id: loan.id, action: 'refinance' })}
        >
          <Text id="LoanDetail.more.Refinance" />
        </ButtonMenu.Item>
      ) : null,
      permission.to(AppPermissions.PayOffLoan) ? (
        <ButtonMenu.Item
          key="payOff"
          disabled={disablePayOff}
          to={loanPath({ id: loan.id, action: 'payOff' })}
        >
          <Text id="LoanDetail.more.PayOff" />
        </ButtonMenu.Item>
      ) : null
    ];

    const disableButtonMenu =
      (disableWriteOff &&
        disableReschedule &&
        disableRefinance &&
        disablePayOff) ||
      isEmpty(compact(menuItems));

    const buttonMenu = (
      <ButtonMenu
        disabled={disableButtonMenu}
        titleId="LoanDetail.more"
        type="secondary"
      >
        {menuItems}
      </ButtonMenu>
    );

    const primaryButton = url ? (
      <Button type="primary" to={url} disabled={disabled} size="medium">
        <Text id={title} />
      </Button>
    ) : null;

    const body = this.renderBody({ ...this.props, loan });
    const Navigation = AsNavigation(body.props.children);

    return (
      <PageLayout
        className={showMargins ? 'pt5' : ''}
        overview={
          <Overview
            navigation={<Navigation />}
            loan={loan}
            schedule={schedule}
            showBack={showOverviewActions}
            printOverview={false}
            actions={showOverviewActions ? [buttonMenu, primaryButton] : null}
            linkToMember={showOverviewActions}
            readOnly={readOnly}
          />
        }
      >
        {body}
      </PageLayout>
    );
  };

  render() {
    return (
      <DeprecatedLoadable
        loader={this.state.loanPromise}
        loaded={this.renderContent}
      />
    );
  }
}

export default LoanDetail;
