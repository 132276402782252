//@flow
import * as React from 'react';

import { Date, Time, Text } from '@kwara/components/src/Intl';
import { BackButton } from '@kwara/components/src/Button';
import { ButtonBar } from '@kwara/components/src/ButtonBar';

import { type Props } from './Overview';

export const OverviewMetadata = ({ actions, showBack, transaction }: Props) => {
  const back = showBack && (
    <BackButton type="secondary" to={{ url: '/till', routeName: 'Till' }} />
  );

  const { member } = transaction;

  const noButtons = actions == null && back == null;

  return noButtons ? null : (
    <React.Fragment>
      <ButtonBar className="mb4 pb3" left={[back]} right={actions} />
      <div className="mb2 grey-400">
        <Text id="TransactionDetail.Overview.createdAt" />{' '}
        <Date value={transaction.createdAt} />{' '}
        <Time value={transaction.createdAt} />
        {member ? (
          <>
            {' '}
            &middot;{' '}
            <Text
              id="TransactionDetail.Overview.memberId"
              values={{ id: transaction.member.id }}
            />
          </>
        ) : null}
      </div>
    </React.Fragment>
  );
};
