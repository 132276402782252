// @flow

import * as React from 'react';
import size from 'lodash/fp/size';

import { removeAt } from '@kwara/lib/src/lodash';
import { type CollateralT } from '@kwara/models/src';

const { useState } = React;

type Shapes = CollateralT;

// Extracts the functionality to deal with adding/removing/editing items within an array
// that is part of a form model.
const VIEW = 'view';
const EDIT = 'edit';
export function useListEditor(products: Shapes, basePath: string) {
  const itemsLen = size(products);
  const [state, setState] = useState(VIEW);
  const [index, setIndex] = useState<number>(itemsLen);
  const editItem = (idx: number) => {
    setIndex(idx);
    setState(EDIT);
  };
  const onRemove = (idx: number) => removeAt(idx, products);
  const addItem = () => editItem(itemsLen);
  const setEdit = () => setState(EDIT);
  const setView = () => setState(VIEW);
  return {
    isEditing: state === EDIT,
    state,
    setEdit,
    setView,
    index,
    setIndex,
    addItem,
    editItem,
    itemsLen,
    onRemove,
    path: `${basePath}[${index}]`
  };
}

const MINUTE = 1000 * 60;

// See: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: () => void, delay: ?number = MINUTE) {
  const savedCallback = React.useRef();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// See: https://dev.to/darthknoppix/harnessing-the-page-visibility-api-with-react-bi5
export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
}

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}

export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    window.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      window.removeEventListener(visibilityChange, onVisibilityChange);
    };
  }, []);

  return isVisible;
}
