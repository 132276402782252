// @flow

import { type StepConfig } from '../../components/Wizard';

import { Preview } from './components/Preview';

export const steps: StepConfig = {
  preview: {
    titleId: 'LoanSchedulePreview.Preview.title',
    subtitleId: 'LoanSchedulePreview.Preview.subtitle',
    Component: Preview,
    validate: Preview.validate,
    actions: []
  }
};
