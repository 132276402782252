// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';
import orderBy from 'lodash/fp/orderBy';

import { type LoanType, type SavingType } from '@kwara/models/src';
import { ExpanderRow } from '@kwara/components/src';
import StatusTag from '@kwara/components/src/StatusTag';
import { Text } from '@kwara/components/src/Intl';

import { SubsectionTitle } from '../../../components/DetailSubsection';
import { Remittance as RemittanceForm } from '../../SavingAdd/components/Remittance';
import { Repayment } from '../../LoanAdd/components/Repayment';
import { EditableSection } from '../../../components/EditableSection';
import Table, * as table from '../../../components/Table';
import { Content as SavingRemittanceView } from '../../SavingDetail/components/Remittance';
import { Content as LoanRepaymentView } from '../../LoanDetail/components/Repayment';

function sortItems(items) {
  return orderBy(['createdAt', 'id'], ['desc'], items);
}

const configs = {
  loan: {
    Component: Repayment,
    validate: Repayment.validateConfig.loanEdit
  },
  saving: {
    Component: RemittanceForm,
    validate: RemittanceForm.validateConfig.savingEdit
  }
};

const Accounts = ({
  item,
  accountType
}: {
  item: LoanTpe | SavingType,
  accountType: 'loan' | 'saving'
}) => {
  return (
    <table.Collapsible key={item.id}>
      {/* $FlowFixMe: @TODO: Collapsible props should be fixed */}
      <table.Row>
        <table.Cell>{item.id}</table.Cell>
        <table.Cell>{get('product.name', item)}</table.Cell>
        <table.Cell>
          <Text
            id={
              // This is so ugly it hurts...
              accountType === 'loan'
                ? `TransactionMethod.${get(
                    'repaymentDetails.repayment_mode',
                    item
                  )}`
                : `TransactionMethod.${get(
                    'remittanceOptions.remittance_method',
                    item
                  )}`
            }
          />
        </table.Cell>
        <table.Cell>
          <StatusTag state={get('state.current', item)} />
        </table.Cell>
      </table.Row>
      {/* $FlowFixMe: @TODO: Collapsible props should be fixed */}
      <table.Row className="bg-light-grey-300">
        <table.Cell colSpan={5}>
          <EditableSection initialData={item} config={configs[accountType]}>
            {accountType === 'saving' ? (
              <SavingRemittanceView className="pl4 pr4" saving={item} />
            ) : (
              <LoanRepaymentView className="pl4 pr4" loan={item} />
            )}
          </EditableSection>
        </table.Cell>
      </table.Row>
    </table.Collapsible>
  );
};

export function Remittance({
  savings: unsortedSavings,
  loans: unsortedLoans
}: {
  savings: SavingType[],
  loans: LoanType[]
}) {
  // Editing these fields is possible when loan is:
  // ACTIVE, ACTIVE_IN_ARREARS
  const editableLoans = filter(loan => loan.isApproved(), unsortedLoans);

  const savings = sortItems(unsortedSavings);
  const loans = sortItems(editableLoans);

  const [selectedId, setId] = React.useState(null);

  const onSelectAccount = id => setId(id);

  const accounts = [...savings, ...loans];
  const findAccount = id => find({ id }, accounts) || {};
  const selectedAccount = findAccount(selectedId) || accounts[0];

  return (
    <>
      <h2 className="kw-text-large kw-weight-bold mb4">
        <Text id="MemberDetail.Remittance.allAccounts" />
      </h2>
      <SubsectionTitle>
        <Text id="MemberDetail.loans" />
      </SubsectionTitle>
      <ExpanderRow>
        {isEmpty(loans) ? (
          <div className="mb4">
            <Text id="MemberDetail.Remittance.noAccount" />
          </div>
        ) : (
          <Table
            heading={
              <table.Row>
                <table.Heading translationId="MemberDetail.Remittance.accountId" />
                <table.Heading translationId="MemberDetail.Remittance.accountName" />
                <table.Heading translationId="MemberDetail.Remittance.method" />
                <table.Heading translationId="MemberDetail.Remittance.status" />
                <table.Heading width="75px" />
              </table.Row>
            }
            footer={<></>}
          >
            {map(
              l => (
                <Accounts
                  selectedAccount={selectedAccount}
                  onSelectAccount={onSelectAccount}
                  key={l.id}
                  item={l}
                  accountType="loan"
                />
              ),
              loans
            )}
          </Table>
        )}
      </ExpanderRow>
      <SubsectionTitle>
        <Text id="MemberDetail.savings" />
      </SubsectionTitle>
      <ExpanderRow>
        {isEmpty(savings) ? (
          <div className="mb4">
            <Text id="MemberDetail.Remittance.noAccount" />
          </div>
        ) : (
          <Table
            heading={
              <table.Row>
                <table.Heading translationId="BatchTransactionsList.table.id" />
                <table.Heading translationId="BatchTransactionsList.table.name" />
                <table.Heading translationId="MemberDetail.Remittance.method" />
                <table.Heading translationId="BatchTransactionsList.table.status" />
                <table.Heading width="75px" />
              </table.Row>
            }
            footer={<></>}
          >
            {map(
              l => (
                <Accounts
                  selectedAccount={selectedAccount}
                  onSelectAccount={onSelectAccount}
                  key={l.id}
                  item={l}
                  accountType="saving"
                />
              ),
              savings
            )}
          </Table>
        )}
      </ExpanderRow>
    </>
  );
}
