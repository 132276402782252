// @flow
import compact from 'lodash/fp/compact'
import get from 'lodash/fp/get'

import { Collateral } from '../LoanAdd/components/Security/Collateral';
import { Guarantor } from '../LoanAdd/components/Security/Guarantor';
import { Repayment } from '../LoanAdd/components/Repayment';
import { Disbursement } from '../LoanAdd/components/Disbursement';

import { Product } from './components/Product';
import { Configure } from './components/Configure';
import { RepaymentDate } from './components/RepaymentDate';
import { Review } from './components/Review';
import { ConfirmReschedule } from './components/ConfirmReschedule';

const disbursementChild = {
  titleId: 'LoanReschedule.Disbursement.title',
  subtitleId: 'LoanReschedule.Disbursement.subtitle',
  Component: Disbursement,
  validate: Disbursement.validate
}

const repaymentChild = {
  titleId: 'LoanReschedule.Configure.RepaymentMode.step.title',
  Component: Repayment,
  validate: Repayment.validate
}

export const steps = (loan) => ({
  review: {
    titleId: 'LoanReschedule.Review.title',
    Component: Review,
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'product' }]
  },
  product: {
    titleId: 'LoanReschedule.Product.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'configure'
      }
    ],
    children: [
      {
        titleId: 'LoanReschedule.Product.title',
        subtitleId: 'LoanReschedule.Product.subtitle',
        Component: Product
      }
    ]
  },
  configure: {
    titleId: 'LoanReschedule.Configure.shortTitle',
    actions: [
      { appearsAs: 'next', behavesAs: 'next', destination: 'security' }
    ],
    children: compact([
      {
        titleId: 'LoanReschedule.Configure.title',
        validate: Configure.validate,
        Component: Configure
      }, get('disbursementMode', loan) ? null : disbursementChild
    ])
  },
  security: {
    titleId: 'LoanReschedule.Security.shortTitle',
    actions: [
      { appearsAs: 'next', behavesAs: 'next', destination: 'repayment' }
    ],
    children: [
      { titleId: 'LoanReschedule.Guarantee.title', Component: Guarantor },
      { titleId: 'LoanReschedule.Collateral.title', Component: Collateral }
    ]
  },
  repayment: {
    titleId: 'LoanReschedule.Repayment.shortTitle',
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'confirm' }],
    children: compact([
      {
        titleId: 'LoanReschedule.Repayment.title',
        subtitleId: 'LoanReschedule.Repayment.subtitle',
        validate: RepaymentDate.validate,
        Component: RepaymentDate
      }, get('repaymentDetails.repayment_mode', loan) ? null : repaymentChild
    ])
  },
  confirm: {
    titleId: 'LoanReschedule.Confirm.title',
    subtitleId: 'LoanReschedule.Confirm.subtitle',
    Component: ConfirmReschedule,
    actions: [{ appearsAs: 'submit', behavesAs: 'complete' }]
  }
});
