import * as React from 'react';
import { Form } from 'react-final-form';

import Button from '@kwara/components/src/Button';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import Modal from '@kwara/components/src/Modal';
import { SubscribedTextField } from '@kwara/components/src/Form';
import { Panel } from '../../../../components/ActionModal';

import { store } from '../../../../models/Store';

export const TellerModal = ({
  isOpen,
  onClose = () => {},
  onSubmit = amount => {
    store.setReorderLevel(amount);
  }
}) => {
  const submitAndClose = ({ amount }) => {
    onSubmit(amount);
    onClose();
  };

  return (
    <Modal titleId="Confirm" isOpen={isOpen} onCancel={onClose}>
      <Form
        onSubmit={submitAndClose}
        initialValues={{ amount: store.tellerConfigs.tillMinimum }}
        render={({ form }) => (
          <div className="w-100 h-100 flex justify-center items-center">
            <div>
              <Panel>
                <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
                  Set reorder limit
                </h2>
                <p className="kw-weight-light mb3">
                  This value is the minimum amount of cash tellers should retain
                  in their till
                </p>
                <SubscribedTextField name="amount" labelId="Reorder limit" />
                <ButtonBar
                  className="mt4"
                  left={<Button onClick={onClose}>Cancel</Button>}
                  right={
                    <Button
                      classNames="mr3"
                      type={'primary'}
                      onClick={form.submit}
                    >
                      Confirm
                    </Button>
                  }
                />
              </Panel>
            </div>
          </div>
        )}
      />
    </Modal>
  );
};

// <VaultModal isOpen={isVaultModalOpen} onClose={onVaultModalClose} /
export const VaultModal = ({
  isOpen,
  onClose = () => {},
  onSubmit = amount => {
    store.setVaultLevels(amount);
  }
}) => {
  const submitAndClose = ({ minAmount, maxAmount }) => {
    onSubmit({ minAmount, maxAmount });
    onClose();
  };

  return (
    <Modal titleId="Confirm" isOpen={isOpen} onCancel={onClose}>
      <Form
        onSubmit={submitAndClose}
        initialValues={{
          minAmount: store.vaultConfigs.reorderMinimum,
          maxAmount: store.vaultConfigs.reorderMaximum
        }}
        render={({ form }) => (
          <div className="w-100 h-100 flex justify-center items-center">
            <div>
              <Panel>
                <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
                  Set vault reorder limit
                </h2>
                <p className="kw-weight-light mb3">
                  Set the minimum and maximum amount of reoder for the Vault.
                  The maximum is Insurance limit.
                </p>
                <SubscribedTextField name="minAmount" labelId="Minimum" />

                <SubscribedTextField name="maxAmount" labelId="Maximum" />
                <ButtonBar
                  className="mt4"
                  left={<Button onClick={onClose}>Cancel</Button>}
                  right={
                    <Button
                      classNames="mr3"
                      type={'primary'}
                      onClick={form.submit}
                    >
                      Confirm
                    </Button>
                  }
                />
              </Panel>
            </div>
          </div>
        )}
      />
    </Modal>
  );
};
