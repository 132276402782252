//@flow

import * as React from 'react';

import Asset from '@kwara/components/src/Asset';
import { PhoneNumberVerification } from '@kwara/models/src';
import { type ValidationRules } from '@kwara/lib/src/validator';
import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';

import ModelErrorBanner from '../../../components/ModelErrorBanner';
import { type SubStepComponentProps } from '../../../components/Wizard';
import permission, { AppPermissions } from '../../../models/Permission';

import { store } from '../../../models/Store';

export const AddNumberPlaceholderLink = ({
  onClick
}: {
  onClick: () => void
}) => (
  <span>
    <Text id="AddMember.Contact.Mobile.info" />
    <br />
    <Link
      onClick={evt => {
        evt.preventDefault();
        onClick();
      }}
    >
      <Text id="AddMember.Contact.Mobile.addSecondary" />
    </Link>
  </span>
);

export const AddEmailPlaceholderLink = ({
  onClick
}: {
  onClick: () => void
}) => (
  <span>
    <Link
      onClick={evt => {
        evt.preventDefault();
        onClick();
      }}
    >
      <Text id="AddMember.Contact.SecEmail.addSecondary" />
    </Link>
  </span>
);

export class Contact extends React.Component<SubStepComponentProps<>, *> {
  static validateConfig: { [k: 'loanAdd' | 'memberAdd']: ValidationRules };
  constructor(props: SubStepComponentProps<>) {
    super(props);

    this.state = {
      showSecondaryNumber:
        props.data && props.data.secondaryPhoneNumber != null,
      errors: null,
      success: false
    };
  }

  // TODO: The method below pings the Safaricom endpoint
  // to validate the phone number is real.
  // ATM it conflicts with the FE validation, so this will have
  // to be resolved before this feature is truly live.
  verifyPhoneNumber = async ({ phoneNumber }) => {
    if (
      !permission.to(AppPermissions.SafaricomVerify) ||
      !phoneNumber ||
      !store.isSafaricomPhoneNumberValidationLive
    ) {
      return;
    }

    this.setState({ errors: null });
    const verification = new PhoneNumberVerification({ phoneNumber });

    const didSave = await verification.save();

    if (didSave) {
      this.setState({ success: true });
    } else {
      this.setState({ errors: verification.errors, success: false });
    }
  };

  showEmail = () => {
    this.setState({
      showEmail: true
    });
  };

  showSecondaryNumber = () => {
    this.setState({
      showSecondaryNumber: true
    });
  };

  render() {
    const { StackChild, TextField, formProps } = this.props;
    const { showSecondaryNumber, showEmail, errors, success } = this.state;

    const { values } = formProps;

    return (
      <StackChild>
        {errors ? (
          <div className="mb3">
            <ModelErrorBanner state="warning" errors={errors} />
          </div>
        ) : null}
        <TextField
          name="phoneNumber"
          labelId="AddMember.Contact.Mobile.label"
          required={true}
          placeholderId="AddMember.Contact.Mobile.placeholder"
          inputOnBlur={() => this.verifyPhoneNumber(values)}
          rightGlyph={success ? Asset.Glyphs.Check : null}
          info={
            showSecondaryNumber ? null : (
              <AddNumberPlaceholderLink onClick={this.showSecondaryNumber} />
            )
          }
        />

        {showSecondaryNumber ? (
          <TextField
            name="secondaryPhoneNumber"
            labelId="AddMember.Contact.SecondaryNumber.label"
            placeholderId="AddMember.Contact.SecondaryNumber.placeholder"
          />
        ) : null}

        <TextField
          name="email"
          labelId="AddMember.Contact.Email.label"
          required={true}
          placeholderId="AddMember.Contact.Email.placeholder"
          info={
            showEmail ? null : (
              <AddEmailPlaceholderLink onClick={this.showEmail} />
            )
          }
        />

        {showEmail ? (
          <TextField
            name="secondaryEmail"
            labelId="AddMember.Contact.SecEmail.label"
            placeholderId="AddMember.Contact.SecEmail.placeholder"
          />
        ) : null}
      </StackChild>
    );
  }
}

const base: ValidationRules = {
  phoneNumber: {
    isRequired: () => true
  },
  secondaryPhoneNumber: {
    phoneNumber: true
  },
  email: { isRequired: () => true, email: true },
  secondaryEmail: { email: true }
};

export const validateConfig = {
  memberAdd: base,
  loanAdd: base
};

Contact.validateConfig = validateConfig;
