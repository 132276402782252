import React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';

import Wizard from '../../components/Wizard';
import { settingPath } from '../../lib/urls';
import { steps } from './config';

import { useLoanProduct } from '../../models/request';

export const LoanProductEdit = props => {
  const r = useLoanProduct(props.match.params.productId);

  const onSubmit = async product => await product.save();

  return (
    <Loadable {...r}>
      {product => {
        return (
          <Wizard
            baseUrl={props.baseUrl}
            history={history}
            initialData={product}
            cancelReturnsTo={settingPath({ action: 'loans' })}
            currentStep={props.match.params.step}
            currentSubStep={
              props.match.params.subStep != null
                ? parseInt(props.match.params.subStep, 10)
                : null
            }
            onSubmit={onSubmit}
            steps={steps}
            startId="product"
            titleId="LoanProductEdit.titleId"
            type="approval"
          />
        );
      }}
    </Loadable>
  );
};
