// @flow

import { attr } from 'spraypaint';
import get from 'lodash/fp/get';

import Base, { type BaseModel } from './Base';

const AuthorizationHoldStates = Object.freeze({
  pending: 'pending',
  settled: 'settled',
  reversed: 'reversed',
  expired: 'expired'
});

type AuthorizationHoldStatesT = $Values<typeof AuthorizationHoldStates>;

export const AuthorizationHold = Base.extend({
  static: {
    jsonapiType: 'authorization_holds'
  },
  attrs: {
    holdBalance: attr(),
    availableBalance: attr(),
    expiresAt: attr(),
    idempotencyKey: attr(),
    transactionSource: attr(),
    transitionReason: attr(),
    userTransactionTime: attr(),
    state: attr()
  },
  methods: {
    isPending() {
      return get('state.current', this) === AuthorizationHoldStates.pending;
    }
  }
});

export const LatestPendingAuthorizationHold = AuthorizationHold.extend({
  static: {
    jsonapiType: 'latest_pending_authorization_hold'
  }
});

export interface AuthorizationHoldT extends BaseModel<AuthorizationHoldT> {
  holdBalance: string;
  availableBalance: string;
  expiresAt: string;
  idempotencyKey: string;
  transactionSource: string;
  transitionReason: string;
  userTransactionTime: string;
  state: {
    current: AuthorizationHoldStatesT,
    permitted_events: string[]
  };
  isPending: () => boolean;
}
