//@flow

import get from 'lodash/get';
import defaultsDeep from 'lodash/defaultsDeep';
import cameliseObjectKeys from '../lib/cameliseObjectKeys';

import { UserTypes } from '@kwara/models/src';
import type { UserType } from '@kwara/models/src';

type Token = string;

type saccoUserDetails = {
  firstName: string,
  lastName: string,
  password: string
};

type memberUserDetails = {
  firstName: string,
  lastName: string,
  password: string,
  passwordConfirm: string,
  email: string,
  phone: string
};

export type UserDetails = saccoUserDetails | memberUserDetails;

type saccoRedeemPayload = {
  first_name: string,
  last_name: string,
  password: string,
  token: string
};

type userRedeemPayload = {
  first_name: string,
  last_name: string,
  password: string,
  password_confirmation: string,
  email: string,
  phone: string
};

type redeemPayload = {
  data: {
    attributes: saccoRedeemPayload | userRedeemPayload
  }
};

function toRedeemPayload(
  userType: UserType,
  token: Token,
  userDetails: UserDetails
): redeemPayload {
  const { firstName, lastName, password, passwordConfirm, email, phone } =
    userDetails || {};

  const payload = {
    data: {
      attributes: {
        first_name: firstName,
        last_name: lastName,
        password: password,
        token
      }
    }
  };

  if (userType === UserTypes.USER) {
    return payload;
  }

  return defaultsDeep(payload, {
    data: {
      attributes: {
        password_confirmation: passwordConfirm,
        email,
        phone
      }
    }
  });
}

function redeem(config: any, userType: UserType, payload: any) {
  if (userType === UserTypes.USER) {
    return fetch(`${config.API_ROOT}/invitation/redeem`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  return fetch(`${config.API_ROOT}/users`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

async function readResponse(
  res
): Promise<{ data: any, errors: Array<{ code: string }> }> {
  try {
    const { data, errors } = await res.json();
    return {
      data: cameliseObjectKeys(data),
      errors
    };
  } catch (e) {
    return {
      data: {},
      errors: []
    };
  }
}

export default class Invitation {
  config: any;
  type: UserType;

  constructor({ config, userType }: { config: any, userType: UserType }) {
    this.config = config;
    this.type = userType;
  }

  async isValid(id: ?string) {
    try {
      if (id == null) {
        throw new Error('Token is null or undefined');
      }

      const response = await fetch(
        `${this.config.API_ROOT}/invitation/validate?t=${id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      const { data, errors } = await readResponse(response);
      if (response.ok && response.status === 200) {
        return {
          isValid: true,
          error: { code: null },
          data
        };
      }

      return {
        isValid: false,
        error: {
          code: get(errors, '[0].detail')
        }
      };
    } catch (error) {
      return { isValid: false, error };
    }
  }

  async redeem(token: Token, userDetails: UserDetails) {
    const payload = toRedeemPayload(this.type, token, userDetails);

    try {
      const response = await redeem(this.config, this.type, payload);

      if (response.ok) {
        return { isCreated: true, error: null };
      }

      const errorPayload = await response.json();

      return {
        isCreated: false,
        error: {
          code: get(errorPayload, 'errors[0].code')
        }
      };
    } catch (error) {
      return { isCreated: false, error };
    }
  }
}
