// @flow

import { type StepConfig } from '../../components/Wizard';

import { Confirm } from './components/Confirm';

export const steps: StepConfig = {
  confirm: {
    titleId: 'LoanWriteOff.Confirm.title',
    subtitleId: 'LoanWriteOff.Confirm.subtitle',
    Component: Confirm,
    validate: Confirm.validate,
    actions: [{ appearsAs: 'close', behavesAs: 'complete' }]
  }
};
