import React from 'react';
import { WithNotification } from '@kwara/components/src/Notification';

import { store } from '../../models/Store';

const showMaximumMessage = (current, notification, onClose) =>
  notification.displayWarning(
    `You have reached ${current} % of the allowed Till maximum`,
    onClose
  );

const showMaximumError = (current, notification, onClose) =>
  notification.displayError(
    `You have reached ${current} % of the allowed Till maximum`,
    onClose
  );

const showMinimumMessage = (_, notification, onClose) =>
  notification.displayWarning(`Till urgently requiries topup. `, onClose);

const showMinimumError = (current, notification, onClose) =>
  notification.displayError(
    `You are ${current} below the required Till minimum. You urgently must top up your till. `,
    onClose
  );

const dismiss = notification => notification.dismiss();

export const TellerNotificationBannerMinimum = WithNotification(
  ({ notification }) => {
    const minValue = store.warnIfTellerCloseToMinimum;
    const [showingMessage, setShowingMessage] = React.useState(false);
    const [hasBeenDismissed, setHasBeenDismissed] = React.useState(false);

    const onClose = () => {
      setShowingMessage(false);
      setHasBeenDismissed(true);
    };

    React.useEffect(() => {
      if (minValue) {
        setShowingMessage(false);
        setHasBeenDismissed(false);
      }
    }, [minValue]);

    // Minimum logic
    React.useEffect(() => {
      if (!hasBeenDismissed) {
        if (!showingMessage && minValue) {
          if (Math.abs(minValue) <= 5000) {
            showMinimumMessage(5000 + minValue, notification, onClose);
          } else {
            showMinimumError(5000 + minValue, notification, onClose);
          }
          setShowingMessage(true);
        }

        if (showingMessage && !minValue) {
          dismiss(notification);
          setShowingMessage(false);
        }
      }
    }, [minValue, notification, showingMessage, hasBeenDismissed]);

    return null;
  }
);

export const TellerNotificationBannerMaximum = WithNotification(
  ({ notification }) => {
    const maxValue = store.warnTellerIfCloseToMaximum;

    const [showingMessage, setShowingMessage] = React.useState(false);
    const [hasBeenDismissed, setHasBeenDismissed] = React.useState(false);

    const onClose = () => {
      setShowingMessage(false);
      setHasBeenDismissed(true);
    };

    React.useEffect(() => {
      if (maxValue) {
        setShowingMessage(false);
        setHasBeenDismissed(false);
      }
    }, [maxValue]);

    // Maximum logic
    React.useEffect(() => {
      if (!hasBeenDismissed) {
        if (!showingMessage && maxValue) {
          if (maxValue < 90) {
            showMaximumMessage(maxValue, notification, onClose);
          } else {
            showMaximumError(maxValue, notification, onClose);
          }
          setShowingMessage(true);
        }

        if (showingMessage && !maxValue) {
          dismiss(notification);
          setShowingMessage(false);
        }
      }
    }, [maxValue, notification, showingMessage, hasBeenDismissed]);

    return null;
  }
);
