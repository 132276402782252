import * as React from 'react';

import Empty from '@kwara/components/src/Empty';
import { Grid } from '../../../components/Grid';
import { Text } from '@kwara/components/src/Intl';

type Props = {
  accounts: React.Node,
  renderAccount: (account: React.Node) => React.Node
};

export const AccountList = ({
  accounts,
  columns = 2,
  width = 'w-50',
  renderAccount
}: Props) => {
  return accounts.length > 0 ? (
    <Grid columns={columns} width={width} border={false}>
      {accounts.map(account => renderAccount(account))}
    </Grid>
  ) : (
    <Empty>
      <Text id="AccountList.empty" />
    </Empty>
  );
};
