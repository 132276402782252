// @flow

import { attr, belongsTo } from 'spraypaint';
import axios from 'axios';
import getOr from 'lodash/fp/getOr';
import pipe from 'lodash/fp/pipe';
import dropRight from 'lodash/fp/dropRight';
import split from 'lodash/fp/split';
import join from 'lodash/fp/join';
import keys from 'lodash/fp/keys';
import values from 'lodash/fp/values';

import { Logger } from '@kwara/lib/src/logger';
import { toBase64 } from '@kwara/components/src/UploadWidget';

import Base from './Base';
import { type UserT } from '..';
import { createErrorsFromApiResponse } from './createModelErrors';

// see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
  // 'text/csv': 'csv' // currently disabled see ch9363
});
const SupportedMimeTypes = Object.freeze(keys(mimeTypesMaps));
const SupportedExtensions = Object.freeze(values(mimeTypesMaps));
const UNKNOWN = 'UNKNOWN';
const mimeTypeToExtension = mimeType => getOr(UNKNOWN, mimeType, mimeTypesMaps);

function blobToFileDownload(blob) {
  const fileUrl = global.URL.createObjectURL(blob);

  // This line:
  // `window.location.href = fileUrl;`
  // should be enough in most cases but the download seems to happen without the file extension
  // on Linux on ci. To make sure we can set both file extension and file name we generate an
  // anchor, inject it in the DOM, click on it and remove it.
  const element = document.createElement('a');
  element.setAttribute('href', fileUrl);
  element.setAttribute('download', 'kwara-template.xlsx');
  element.style.display = 'none';
  // Flow wants a null check here or to redeclare document to guarantee the is a body in document
  // Unfortunately doing that increases the check time considerably, so we ignore.
  // See also https://github.com/facebook/flow/issues/4783
  // $FlowFixMe
  document.body.appendChild(element);
  element.click();
  // $FlowFixMe
  document.body.removeChild(element);
}

// To download the template we take the binary returned from the api, we instantiate a Blob
// and point the browser to a new URL created from the blob object.
// Referencess:
// - https://github.com/getkwara/backend/pull/332
// - https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/
// - http://keyangxiang.com/2017/09/01/HTML5-XHR-download-binary-content-as-Blob/
function binaryToBlob() {
  return new Promise(resolve => {
    const { result } = this;
    const fileLength = result.length;
    const chunks = new Uint8Array(fileLength);
    for (let i = 0; i < fileLength; i++) {
      chunks[i] = result.charCodeAt(i);
    }
    const blob = new Blob([chunks], {
      type: join(', ', SupportedMimeTypes)
    });

    return resolve(blob);
  })
    .then(blobToFileDownload)
    .catch(err => {
      Logger.error('Error storing template file ', JSON.stringify(err));
      return err;
    });
}

export const BatchUpload = Base.extend({
  static: {
    jsonapiType: 'batch_uploads',
    getTemplate() {
      const opts = BatchUpload.fetchOptions();
      const options = {
        ...opts,
        responseType: 'blob',
        headers: {
          ...opts.headers,
          accept: '*/*'
        }
      };
      const url = `${BatchUpload.fullBasePath()}/batch_uploads_template`;
      return axios(url, options).then(res => {
        try {
          const frb = new FileReader();
          frb.onload = binaryToBlob;
          frb.readAsBinaryString(res.data);
        } catch (e) {
          Logger.log(
            'Error reading batch upload template response ',
            JSON.stringify(e)
          );
          return Promise.reject(e);
        }
        // The main result of this call is a side effect (the file download kicking off in the
        // client) so we're not interested in returning any result apart from the success/failure
        // of the operation.
        return Promise.resolve();
      });
    },
    async uploadTemplate(file: File): Promise<string> {
      const { name: rawName, type: mimeType } = file;
      const type = mimeTypeToExtension(mimeType);
      // make sure the extension is dropped from the filename
      const name = pipe(
        split('.'),
        dropRight(1),
        join('')
      )(rawName);
      if (type === UNKNOWN) {
        return Promise.reject([
          {
            code: 'UI_UNSUPPORTED_EXTENSION'
          }
        ]);
      }
      const content = await toBase64(file);
      const opts = BatchUpload.fetchOptions();
      const data = {
        attributes: {
          name,
          type,
          content
        }
      };
      const url = `${BatchUpload.fullBasePath()}/batch_uploads`;
      return axios.post(url, { data }, opts).catch(({ response }) => {
        throw createErrorsFromApiResponse(response.data);
      });
    }
  },
  attrs: {
    name: attr(),
    byteSize: attr(),
    content_type: attr(),
    state: attr({ persist: false }),
    user: belongsTo()
  },
  methods: {}
});
BatchUpload.SupportedMimeTypes = SupportedMimeTypes;
BatchUpload.SupportedExtensions = SupportedExtensions;

export type BatchUploadT = {
  name: string,
  byteSize: string,
  contentType: string,
  state: {
    current: string
  },
  user: UserT
};
