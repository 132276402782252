// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { Loadable } from '@kwara/components/src/Loadable';

import { loanPath } from '../../lib/urls';
import { useLoan } from '../../models/request';
import Wizard from '../../components/Wizard';

import { makeRepayment } from '../MemberRepayment/components/RepaymentForm';
import { steps } from './config';
import { type LoanPageProps } from '..';

type Props = LoanPageProps;

const includes = ['guarantors', 'member', 'product'];

export const LoanPayment = (props: Props) => {
  const r = useLoan(props.match.params.loanId, includes);

  return (
    <Loadable {...r}>
      {loan => {
        const { member } = loan;
        const returnTo = loanPath({ id: loan.id });
        return loan.canMakeRepayment() ? (
          <Wizard
            analyticsId="LoanRepayment"
            baseUrl={returnTo}
            history={history}
            initialData={{
              member,
              accountId: loan.id,
              loan
            }}
            cancelReturnsTo={returnTo}
            currentStep="repayment"
            onSubmit={makeRepayment}
            steps={steps}
            startId="repayment"
            titleId="LoanRepayment.title"
          />
        ) : (
          <Redirect to={returnTo} />
        );
      }}
    </Loadable>
  );
};
