//@flow
import * as React from 'react';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import toNumber from 'lodash/fp/toNumber';

import { Duration, Number, Percent } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { type MetricType } from '@kwara/models/src';
import { isDev, isSandbox } from '@kwara/lib/src/utils';

import { Currency } from '../Currency';

import styles from './index.module.css';

type Props = {
  title: React.Node,
  metric: MetricType,
  infoId?: string,
  values?: { [id: string]: any }
};

function extractValue(val: { type: string, value: number | string }) {
  const value = getOr(0, 'value', val);
  const currencyVal = toNumber(value);
  const isNonProdEnv = isDev || isSandbox;
  // if higher than Max safe integer reduce the value
  if (isNonProdEnv && currencyVal > 9007199254740991) {
    return currencyVal / 20000000000000;
  }

  return val.value;
}

export default class extends React.Component<Props> {
  renderUnknown = (value: number | string) => {
    return <span>{value}</span>;
  };

  renderPercentage = (value: number | string) => {
    return (
      <span className={styles.Numeric}>
        <Percent value={value} />
      </span>
    );
  };

  renderDuration = (value: number | string) => {
    if (value === 'PT0S') {
      return <span>{'< 1 day'}</span>;
    }

    return <Duration value={value} />;
  };

  renderValue = (value: { type: string, value: string | number }) => {
    switch (value.type) {
      case 'currency': {
        const currency = extractValue(value);
        return <Currency value={currency} />;
      }
      case 'number':
        return <Number value={value.value} />;
      case 'duration':
        return this.renderDuration(value.value);
      case 'percent':
        return this.renderPercentage(value.value);
      default:
        return this.renderUnknown(value.value);
    }
  };

  renderInfo = (info: { type: string, value: string | number }) => {
    if (info) {
      return (
        <span className="pl2 kw-text-extra-large grey-400 kw-weight-light">
          {this.renderValue(info)}
        </span>
      );
    }
  };

  render() {
    const { infoId, values, metric, title, size = 'extraBig' } = this.props;
    return (
      <Statistic
        title={title}
        info={this.renderInfo(get('context', metric))}
        infoId={infoId}
        values={values}
        size={size}
        value={this.renderValue(get('value', metric))}
      />
    );
  }
}
