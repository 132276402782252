// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

import { GlContexts } from '@kwara/models/src';
import { getCurrentDate } from '@kwara/lib/src/dates';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import {
  SubscribedPaymentSelectField,
  GlAccountSelect
} from '@kwara/components/src/Form';
import {
  TransactionChannels,
  getTransactionTypes,
  contexts
} from '@kwara/models/src/models/Transactions';

import { ProfileContext } from '../../../models/Profile';
import { Currency } from '../../../components/Currency';
import MemberPanel from '../../../components/Payment/MemberPanel';
import { Panel } from '../../../components/ActionModal';
import { Grid } from '../../../components/Grid';
import { DatePicker, Field as BasicField } from '../../../components/Form';

const showAccountSelectionFor = [TransactionChannels.fosa];
const showChequeFieldsFor = [TransactionChannels.cheque];
const showBanksFieldsFor = [
  TransactionChannels.bankTransfer,
  TransactionChannels.cheque
];

const payOffConfig = {
  bank: 'bankName',
  methods: getTransactionTypes(contexts.LoanPayOff).values,
  showAccountSelectionFor,
  showChequeFieldsFor,
  showBanksFieldsFor
};

export const PayOffForm = ({
  StackChild,
  Condition,
  TextField,
  TextArea,
  data,
  addData
}) => {
  const store = React.useContext(ProfileContext);

  const { loan } = data;
  const product = get('product', loan);

  const memberId = get('member.id', loan);

  const disableAccountIf = s =>
    !s.isWithdrawable() || s.balance < loan.totalBalance;

  return (
    <StackChild>
      <MemberPanel member={loan.member} />
      <Panel>
        <Statistic
          size="medium"
          containerClassName="mb2"
          title={<Text id="LoanPayOff.Confirm.Account" />}
          value={`${loan.id} - ${product.name}`}
        />
        <Grid columns={2} width="w-50" border={false}>
          <Statistic
            title={<Text id="LoanPayOff.Confirm.PrincipalBalance" />}
            value={<Currency value={loan.principal.balance} />}
            compact
          />
          <Statistic
            title={<Text id="LoanPayOff.Confirm.InterestBalance" />}
            value={<Currency value={loan.interest.balance} />}
            compact
          />
          <Statistic
            title={<Text id="LoanPayOff.Confirm.FeesBalance" />}
            value={<Currency value={loan.fees.balance} />}
            compact
          />
          <Statistic
            title={<Text id="LoanPayOff.Confirm.PenaltiesBalance" />}
            value={<Currency value={loan.penalties.balance} />}
            compact
          />
          {store.accruedInterestEnabled ? (
            <Statistic
              title={<Text id="LoanPayOff.Confirm.AccruedInterest" />}
              value={<Currency value={loan.accruedInterest} />}
              compact
            />
          ) : null}
        </Grid>
      </Panel>
      <Panel>
        <Grid columns={2} width="w-50" border={false}>
          <TextField
            name="amount"
            disabled
            labelId="LoanPayOff.Confirm.amount.label"
          />
          <BasicField
            required
            disabled
            name="date"
            size="medium"
            labelId="LoanPayOff.Confirm.date.label"
          >
            <DatePicker name="date" disabled value={getCurrentDate()} />
          </BasicField>
        </Grid>
        <SubscribedPaymentSelectField
          required
          name="method"
          config={{ memberId, disableAccountIf, ...payOffConfig }}
          inputOnChange={() => addData({ bankGlId: '' })}
        />
        <Condition when="method" not="">
          {({ input }) => {
            const { value: method } = input;
            return (
              <GlAccountSelect
                method={method}
                addData={addData}
                context={GlContexts.REPAYMENT}
              />
            );
          }}
        </Condition>
      </Panel>
      <Panel>
        <TextArea name="notes" labelId="LoanPayOff.Confirm.notes.label" />
      </Panel>
    </StackChild>
  );
};

PayOffForm.validate = {
  method: {
    isRequired: () => true
  },
  accountId: {
    isRequired: (_, allData) => {
      const { method } = allData;
      return includes(method, showAccountSelectionFor);
    }
  },
  ...GlAccountSelect.validate
};
