//@flow
import * as React from 'react';
import zipObject from 'lodash/zipObject';
import raw from 'raw.macro';

import styles from './index.module.scss';

// Changing these files might not reload
// them in the dev environment since the
// raw.macro seems to aggressively cache
// them.
//
// Run: yarn clear-babel-cache and restart
// the packager to fix this
//
const assets = {
  Add: raw('./Glyph/Plus Circle.svg'),
  ArrowLeft: raw('./Glyph/Arrow Left.svg'),
  ArrowRight: raw('./Glyph/Arrow Right.svg'),
  ArrowDown: raw('./Glyph/Arrow Down.svg'),
  Check: raw('./Glyph/Check.svg'),
  ChevronDown: raw('./Glyph/Chevron Down.svg'),
  ChevronUp: raw('./Glyph/Chevron Up.svg'),
  Contact: raw('./Glyph/Contact.svg'),
  Cross: raw('./Glyph/Cross.svg'),
  DoubleArrow: raw('./Glyph/Double Arrow.svg'),
  Dropdown: raw('./Glyph/Dropdown.svg'),
  EllipsisVertical: raw('./Glyph/Ellipsis Vertical.svg'),
  Eye: raw('./Glyph/Eye.svg'),
  EyeClosed: raw('./Glyph/Eye Closed.svg'),
  Pencil: raw('./Glyph/Pencil.svg'),
  Printer: raw('./Glyph/Printer.svg'),
  Search: raw('./Glyph/Search.svg'),
  Spinner: raw('./Glyph/Refresh.svg'),
  TrashBin: raw('./Glyph/Trash Bin.svg')
};

const assetIds = Object.keys(assets);
const Glyphs = Object.freeze(zipObject(assetIds, assetIds));

const Colours = {
  white: 'white',
  grey300: 'grey300',
  amber500: 'amber500',
  amber700: 'amber700',
  red500: 'red500',
  red700: 'red700',
  teal500: 'teal500',
  teal700: 'teal700',
  indigo500: 'indigo500',
  black: 'black'
};

export type Glyph = $Values<typeof Glyphs>;
export type GlyphCols = $Values<typeof Colours>;

type Props = {
  className?: string,
  id: Glyph,
  col?: $Values<typeof Colours>
};

const getRawAssetById = id => assets[id];

export const getAssetDataUrlById = (id: Glyph) =>
  `data:image/svg+xml,${escape(getRawAssetById(id))}`;

const Asset = ({
  size = 'medium',
  className = '',
  id,
  col = Colours.white
}: Props) => {
  const assetStyle = size === 'small' ? styles.AssetSmall : styles.Asset;
  const classes = `dib ${className} ${assetStyle} ${styles[col]} ${styles[id]}`;

  return (
    <span
      className={classes}
      dangerouslySetInnerHTML={{ __html: getRawAssetById(id) }}
    />
  );
};

Asset.Colours = Colours;
Asset.Glyphs = Glyphs;

export default Asset;
