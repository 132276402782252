// @flow

import { attr, belongsTo } from 'spraypaint';

import Transaction, { type TransactionType } from './Transaction';
import { type LoanProductType } from './LoanProduct';

import createModelErrors, {
  createErrorsFromApiResponse
} from './createModelErrors';
import { LoanTransactionReviewSet } from './TransactionReviewSet';

export const LoanTransactionTypes = Object.freeze({
  DISBURSEMENT: 'DISBURSEMENT',
  INTEREST_APPLIED: 'INTEREST_APPLIED',
  REPAYMENT: 'REPAYMENT',
  FEE: 'FEE'
});

export type LoanTransactionTypesT = $Values<typeof LoanTransactionTypes>;

const LoanTransaction = Transaction.extend({
  static: {
    jsonapiType: 'loan_transactions'
  },
  attrs: {
    amount: attr(),
    balance: attr(),
    type: attr(),
    notes: attr(),
    createdAt: attr(),
    enteredAt: attr(),
    paymentMethod: attr(),
    bankName: attr(),
    bankBranch: attr(),
    accountNumber: attr(),
    account: belongsTo('loan_products'),
    chequeNumber: attr(),
    drawer: attr(),
    // Write
    bankGlId: attr(),
    loanId: attr(),
    reference: attr(),

    memberName: attr(),
    memberId: attr(),
    loanTransactionReviewSet: belongsTo({ type: LoanTransactionReviewSet })
  },
  methods: {}
});

export const PendingLoanTransaction = LoanTransaction.extend({
  static: {
    jsonapiType: 'pending_loan_transactions'
  },
  methods: {
    async transition(params: {
      event: 'approve' | 'reject',
      raw_loan_transaction_ids: string[],
      notes?: string
    }) {
      const url = PendingLoanTransaction.url();
      const attributes = params;

      const options = {
        ...PendingLoanTransaction.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({ data: { attributes } })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();

          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (errors) {
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    }
  }
});

PendingLoanTransaction.transactionsKey = 'raw_loan_transaction_ids';

export interface LoanTransactionType extends TransactionType {
  loanId: string;
  account: LoanProductType;
}

export default LoanTransaction;
