import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { type LoanType } from '@kwara/models/src';

import OverviewList from '../../../../components/OverviewList';
import SubmitListTable from '../SubmitListTable';

type DaysInArrearsFilter = 0 | 30 | 60 | 90;

type Props = {
  loading?: boolean,
  loans: LoanType[],
  totalNumResults: number,
  filterBy?: DaysInArrearsFilter,
  isSelectAllCheckboxActive: boolean,
  searchValue: string,
  onFilterChange: (filterBy: DaysInArrearsFilter) => void,
  onSearchChange: (term: string) => void,
  toggleAllCheckboxes: () => void
};

// const ArrearsStateFilter = [
//   { value: 0, translationId: 'CreditSubmitList.noFilter' },
//   ...map([30, 60, 90], value => ({
//     value,
//     translationId: `LoanDaysInArrears.${value}`
//   }))
// ];

export default withRouter(
  ({
    loading = false,
    loans = [],
    totalNumResults,
    // filterBy,
    isSelectAllCheckboxActive,
    searchValue,
    hasMore,
    onFilterChange,
    onSearchChange,
    onLoadMoreData,
    toggleAllCheckboxes
  }: Props) => (
    <OverviewList
      // filterLabelId="OverviewList.filterByArrearsLabel"
      // filterBy={filterBy}
      // filters={ArrearsStateFilter}
      headerId="CreditSubmitList.heading"
      labelId="CreditSubmitList.filteredCount"
      searchPlaceholderId="CreditSubmitList.searchPlaceholder"
      onSearchChange={onSearchChange}
      searchValue={searchValue}
      items={loans}
      onFilterChange={onFilterChange}
      totalNumResults={totalNumResults}
      table={
        <SubmitListTable
          hasMore={hasMore}
          groupBy={null}
          loading={loading}
          loans={loans}
          orderBy={null}
          isSelectAllCheckboxActive={isSelectAllCheckboxActive}
          toggleAllCheckboxes={toggleAllCheckboxes}
          onLoadMoreData={onLoadMoreData}
        />
      }
    />
  )
);
