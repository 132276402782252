//@flow
import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

type Handler = () => void;

export const KinActions = ({
  onEdit,
  onRemove
}: {
  onEdit: Handler,
  onRemove: () => Promise<any>
}) => (
  <div className="">
    <Link size="regular" type="secondary" onClick={onEdit}>
      <Text id="AddMember.Kin.edit" />
    </Link>{' '}
    &middot;{' '}
    <Link size="regular" type="destructive" onClick={onRemove}>
      <Text id="AddMember.Kin.remove" />
    </Link>
  </div>
);

export const EditActions = ({
  disabled,
  onDone
}: {
  disabled?: boolean,
  onDone: () => Promise<any>
}) => (
  <div className="">
    <Link disabled={disabled} size="regular" type="secondary" onClick={onDone}>
      <Text id="AddMember.Kin.done" />
    </Link>
  </div>
);
