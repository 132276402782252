// @flow

import * as React from 'react';
import { Redirect, type RouterHistory } from 'react-router-dom';
import { Form } from 'react-final-form';

import {
  SavingsTransaction,
  PendingSavingsTransaction,
  type ModelErrors
} from '@kwara/models/src';
import createValidator from '@kwara/lib/src/validator';
import { Loadable } from '@kwara/components/src/Loadable';

import { savingPath } from '../../lib/urls';
import { useSaving } from '../../models/request';
import Payment from '../../components/Payment';
import { store } from '../../models/Store';

import {
  PaymentForm,
  type PaymentData,
  validateConfig
} from './components/PaymentForm';

type Props = {
  savingId?: string,
  baseUrl: string,
  history: RouterHistory,
  match: {
    params: {
      savingId: string
    }
  },
  onCancel: () => void,
  onConfirm: () => void
};

type FormData = PaymentData & {
  notes?: string
};

type States = 'inProgress' | 'success' | 'done' | 'error';

type MaybeErrors = ?ModelErrors;

export const SavingPenalty = (props: Props) => {
  const { savingId = props.match.params.savingId } = props;
  const result = useSaving(savingId);
  const { data: saving } = result;

  const [state, setState] = React.useState<States>('inProgress');
  const [isProcessing, setProcessing] = React.useState(false);
  const [errors, setErrors] = React.useState<MaybeErrors>();

  const createTransaction = async (data: FormData) => {
    setProcessing(true);
    const { amount, notes } = data;

    const Transaction = store.isMakerCheckerEnabled(amount)
      ? PendingSavingsTransaction
      : SavingsTransaction;

    const transaction = await Transaction.create({
      type: 'FEE',
      savingsId: saving.id,
      amount,
      notes
    });

    try {
      setProcessing(false);
      const didSave = await transaction.save();
      if (didSave) {
        setState('success');
      } else {
        throw new Error();
      }
    } catch {
      setState('error');
      setErrors(transaction.errors);
    }
  };

  const onClose = () => setState('done');

  if (state === 'done') {
    return <Redirect to={savingPath({ id: saving.id })} />;
  }

  return (
    <Loadable {...result}>
      <Form
        onSubmit={createTransaction}
        validate={createValidator(validateConfig)}
        render={(formProps: any) => {
          const { invalid, values, handleSubmit } = formProps;
          return (
            <Payment
              titleId="SavingPenalty.title"
              invalid={invalid}
              isProcessing={isProcessing}
              errors={errors}
              saving={saving}
              onCancel={onClose}
              onConfirm={state !== 'success' ? handleSubmit : onClose}
              success={state === 'success'}
              type={Payment.Type.penalty}
            >
              <PaymentForm formValues={values} />
            </Payment>
          );
        }}
      />
    </Loadable>
  );
};
