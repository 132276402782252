//@flow
import * as React from 'react';
import noop from 'lodash/fp/noop';

import { Text, type TranslationId } from '@kwara/components/src/Intl';

import styles from './Radio.module.scss';

type Props = {
  disabled?: boolean,
  value: string,
  labelId: TranslationId,
  name: string,
  size: 'regular' | 'medium',
  onChange: (evt: { target: { value: string } }) => void,
  inputOnChange: (evt: { target: { value: string } }) => void,
  titleId?: TranslationId
};

export const Radio = ({
  disabled,
  name,
  size,
  labelId,
  titleId,
  onChange,
  inputOnChange = noop,
  value,
  ...rest
}: Props) => {
  const id = `${name}-${value}`;
  const labelling = (
    <div
      className={`pb2 dib flex kw-text-${
        size === 'regular' ? 'small' : 'regular'
      } ${styles.labelling}`}
    >
      <div className="flex flex-column">
        <div className="black">
          <Text id={titleId ? titleId : labelId} />
        </div>
        {titleId && (
          <div className="grey-400 pt1">
            <Text id={labelId} />
          </div>
        )}
      </div>
    </div>
  );

  const input = (
    <input
      className={styles.input}
      disabled={disabled}
      type="radio"
      id={id}
      name={name}
      value={value}
      onChange={e => {
        onChange(e);
        inputOnChange(e);
      }}
      {...rest}
    />
  );

  return (
    <label className={styles.container} htmlFor={id}>
      {input}
      {labelling}
    </label>
  );
};

export default Radio;
