import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import { ActionButton } from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import {
  groupPermissions,
  groups,
  sortByVerb
} from '@kwara/models/src/models/AppPermission';

import { settingPath } from '../../../../lib/urls';

export const RoleCard = ({ appRole, deleteAppRole }) => {
  const { appPermissions, users } = appRole;

  const grouped = groupPermissions(appPermissions);

  return (
    <div className="mb4 pa3 bb b--light-grey-400">
      <div className="kw-text-medium kw-weight-bold mb2 flex justify-between">
        {appRole.name}
        <div className="flex">
          <ActionButton
            disabled={!isEmpty(users)}
            onClick={deleteAppRole}
            className="red-500 mr1"
            col="red500"
            type="delete"
          />
          <ActionButton
            className="bg-white ml1"
            type="edit"
            to={settingPath({
              baseExtension: 'roles',
              action: 'edit',
              id: appRole.id
            })}
          />
        </div>
      </div>
      {appRole.description ? (
        <div className="grey-300 mb2">{appRole.description}</div>
      ) : null}
      <div className="flex flex-wrap">
        {map(group => {
          const grouping = get(group, grouped);
          return (
            <Statistic
              key={group}
              containerClassName={cx([
                'w-25',
                isEmpty(grouping) ? 'o-50' : null
              ])}
              size="small"
              title={
                <Text
                  id={`Settings.AppRoles.RoleCard.permissionGroup.${group}`}
                />
              }
              value={
                grouping ? (
                  <ul className="list pl0">
                    {map(
                      p => (
                        <li key={p.id}>
                          <Text
                            id={`Settings.AppRoles.RoleCard.permissions.${p.name}`}
                          />
                        </li>
                      ),
                      sortBy(sortByVerb, grouping)
                    )}
                  </ul>
                ) : null
              }
            />
          );
        }, groups)}
      </div>
    </div>
  );
};
