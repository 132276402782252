import { Email } from './components/Email';

export const steps = {
  email: {
    titleId: Email.title,
    subtitleId: Email.subtitle,
    Component: Email,
    validate: Email.validate,
    actions: [{ appearsAs: 'submit', behavesAs: 'complete' }]
  }
};
