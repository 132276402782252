//@flow
import mitt from 'mitt';

import {
  SearchCancellation,
  type LoanType,
  type MemberType
} from '@kwara/models/src';

type Results = LoanType[] | MemberType[];
type ResultHandler = (results: Results) => void;
type SearchStateChangeHandler = ({ isSearchPending: boolean }) => void;

/*
  Only allows one search request to be active at a time
*/
export default class SearchManager {
  constructor(model) {
    this._emitter = mitt();
    this.model = model;
  }

  _currentSearchRequestPromise = null;
  _emitter: mitt;

  results = (handler: ResultHandler) => {
    this._emitter.on('results', res => handler(res));
  };

  stateChange = (handler: SearchStateChangeHandler) => {
    this._emitter.on('stateChange', handler);
  };

  _addHandlerToPromise = (promise: Promise<Results>) => {
    this._emitter.emit('stateChange', { isSearchPending: true });

    promise.then(
      (results: Results) => {
        if (promise !== this._currentSearchRequestPromise) {
          return;
        }

        this._currentSearchRequestPromise = null;
        this._emitter.emit('results', results);
        this._emitter.emit('stateChange', { isSearchPending: false });
      },
      error => {
        if (!(error instanceof SearchCancellation)) {
          this._emitter.emit('error', error);
        }
      }
    );
  };

  cancelPendingSearches = () => {
    if (this._currentSearchRequestPromise) {
      this._currentSearchRequestPromise.cancel();
    }
    this._currentSearchRequestPromise = null;
    this._emitter.emit('stateChange', { isSearchPending: false });
  };

  search(query: { term: string }) {
    this.cancelPendingSearches();

    this._currentSearchRequestPromise = this.model.search(query);

    this._addHandlerToPromise(this._currentSearchRequestPromise);
  }

  destroy() {
    this.cancelPendingSearches();
    this._emitter.off('*');
  }
}
