// @flow

import * as React from 'react';
import map from 'lodash/fp/map';

import { type MemberReportT } from '@kwara/models/src';
import { Percent, DateTime, Text } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';

import Table, {
  Cell,
  Row,
  Heading,
  Footer
} from '../../../../components/Table';

type Props = {
  reports: MemberReportT[],
  onNext: () => void,
  hasMore: boolean,
  isPending: boolean,
  errors: Object[]
};

export const ReportsTable = ({
  reports,
  onNext,
  hasMore,
  errors,
  isPending
}: Props) => (
  <Table
    heading={
      <Row>
        <Heading>
          <Text id="Settings.Data.Table.id" />
        </Heading>
        <Heading>
          <Text id="Settings.Data.Table.email" />
        </Heading>
        <Heading>
          <Text id="Settings.Data.Table.date" />
        </Heading>
        <Heading>
          <Text id="Settings.Data.Table.complete" />
        </Heading>
        <Heading>
          <Text id="Settings.Data.Table.status" />
        </Heading>
      </Row>
    }
    footer={
      <Footer
        colSpan={5}
        isPending={isPending}
        onNext={onNext}
        hasMore={hasMore}
        errors={errors}
        items={reports}
      />
    }
  >
    {map(
      r => (
        <Row key={r.id}>
          <Cell>{r.id}</Cell>
          <Cell>{r.email}</Cell>
          <Cell>
            <DateTime value={r.updatedAt} />
          </Cell>
          <Cell>
            <Percent value={r.completed / 100} />
          </Cell>
          <Cell>
            <StatusTag state={r.currentState()} />
          </Cell>
        </Row>
      ),
      reports
    )}
  </Table>
);
