// @flow
import * as React from 'react';
import noop from 'lodash/fp/noop';

import { NamedRoute } from '@kwara/components/src/Route';
import { type AppPermissionValue } from '@kwara/models/src';
import { PermissionDenied } from '@kwara/components/src/PermissionDenied';

import Visible from '../Visible';

type Base = {
  permission: AppPermissionValue,
  path?: string,
  exact?: boolean,
  strict?: boolean,
  sensitive?: boolean
};
type P1 = Base & {
  component: React.ComponentType<*>
};
type P2 = Base & {
  render: (props: any) => React.ComponentType<*>
};
type Props = P1 | P2;

const PermittedRoute = ({
  permission,
  component: Component,
  render = noop,
  ...rest
}: Props) => (
  <NamedRoute
    {...rest}
    render={props => (
      <Visible to={permission} fallback={<PermissionDenied />}>
        {Component ? <Component {...props} /> : render(props)}
      </Visible>
    )}
  />
);

export default PermittedRoute;
