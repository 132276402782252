//@flow

import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import size from 'lodash/size';

import Actionable from '@kwara/components/src/Actionable';
import { Text } from '@kwara/components/src/Intl';
import { IdDocument } from '@kwara/models/src';
import { mapIndexed } from '@kwara/lib/src/lodash';

import { type SubStepComponentProps } from '../../../components/Wizard';
import * as recordValidations from '../../../lib/recordValidations';

import styles from '../index.module.css';

const { useState } = React;

const documentTypes = [
  { value: 'PASSPORT' },
  { value: 'NATIONAL' },
  { value: 'ALIEN_ID' },
  { value: 'DRIVER_LICENSE' }
];

const AddDocumentPlaceholderLink = ({
  onAdd,
  onRemove,
  isOpen
}: {
  onAdd: () => void,
  onRemove: () => void,
  isOpen: boolean
}) => {
  const action = isOpen ? onRemove : onAdd;

  const buttonStyle = [
    'link  bw0 underline-hover pointer',
    isOpen ? 'red-600' : 'indigo-500'
  ];

  return (
    <Actionable className={cx(buttonStyle)} onClick={action}>
      <Text
        id={`AddMember.Identity.Type.Document.alternative.${
          isOpen ? 'remove' : 'add'
        }`}
      />
    </Actionable>
  );
};

// TODO: this is a duplicate, should be removed if ch6907 happens
// if not, consider extracting this and its duplicate(s) and moving
// where appropriate
function isRequired(fieldName, config, formProps) {
  const fieldValidate = get(`validate.${fieldName}`, config);
  if (fieldValidate && fieldValidate.isRequired) {
    return fieldValidate.isRequired(
      formProps.values[fieldName],
      formProps.values
    );
  }

  return false;
}

const addSecondaryIdDocument = data => {
  const idDocument = new IdDocument();
  idDocument.id = 'temp-id';
  if (!data.idDocuments) {
    data.idDocuments = [];
  }
  data.idDocuments.push(idDocument);
  return data;
};

const removeSecondaryIdDocument = data => {
  data.idDocuments = [data.idDocuments[0]];
  return data;
};

export default function Identity({
  onChange,
  SelectField,
  TextField,
  StackChild,
  data = {},
  config,
  formProps
}: SubStepComponentProps<>) {
  const showSecondaryId = size(get(`idDocuments`, data)) > 1;

  const [isOpen, setOpenState] = useState(showSecondaryId);

  return (
    <StackChild>
      {mapIndexed((_field, idx) => {
        const name = `idDocuments[${idx}]`;
        const idType = get(`values.idDocuments.[${idx}].type`, formProps);
        const isPrimary = idx === 0;
        const showLink = (isPrimary && !isOpen) || (!isPrimary && isOpen);

        return (
          <div className="bb b--light-gray pv3" key={idx}>
            <span className={styles.IdTypeSelect}>
              <SelectField
                name={`${name}.type`}
                required={isPrimary}
                labelId={`AddMember.Identity.Type.Document.${
                  isPrimary ? 'primary' : 'alternative'
                }.label`}
              >
                <SelectField.Option
                  translationId="AddMember.Identity.Type.NONE"
                  value=""
                />
                {map(
                  o => (
                    <SelectField.Option
                      translationId={`AddMember.Identity.Type.${o.value}`}
                      value={o.value}
                      key={o.value}
                    />
                  ),
                  documentTypes
                )}
              </SelectField>
            </span>

            {idType ? (
              <TextField
                name={`idDocuments[${idx}].documentId`}
                required={isPrimary}
                labelId={`AddMember.Identity.Value.${idType}.label`}
                placeholderId={`AddMember.Identity.Value.${idType}.placeholder`}
              />
            ) : null}

            <div>
              {showLink ? (
                <AddDocumentPlaceholderLink
                  isOpen={isOpen}
                  onAdd={() => {
                    onChange(addSecondaryIdDocument(data));
                    setOpenState(true);
                  }}
                  onRemove={() => {
                    onChange(removeSecondaryIdDocument(data));
                    setOpenState(false);
                  }}
                />
              ) : null}
            </div>
          </div>
        );
      }, data.idDocuments)}
      <div className="pv3">
        <TextField
          name="kraPin"
          labelId="AddMember.Identity.KRA.label"
          placeholderId="AddMember.Identity.KRA.placeholder"
          required={isRequired('kraPin', config, formProps)}
        />
      </div>
    </StackChild>
  );
}

const base = {
  ['idDocuments[0].type']: { isRequired: () => true },
  ['idDocuments[0].documentId']: {
    isRequired: () => true,
    ...recordValidations.identityDocumentValue
  },
  ['idDocuments[1].documentId']: {
    ...recordValidations.identityDocumentValue
  }
};

const loanAdd = {
  ...base,
  kraPin: {
    isRequired: () => true
  }
};

Identity.validateConfig = {
  memberAdd: base,
  memberDetail: base,
  loanAdd
};
