// @flow

import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import { Text } from '@kwara/components/src/Intl';
import Modal from '@kwara/components/src/Modal';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';
import { SubscribedTextArea } from '@kwara/components/src/Wizard/FormFields';

import { Panel } from '../../../components/ActionModal';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => ?Promise<?Object>,
  numberSelected: number,
  event: 'approve' | 'reject' | null,
  error: any,
  applyToAll: boolean
};

export const Confirm = ({
  isOpen,
  onClose,
  onSubmit,
  numberSelected,
  event,
  error,
  applyToAll
}: Props) => {
  // Even with a simultaneous state change, the Text components below
  // are still briefly rendered with `null` value before the modal closes
  // and react throws a red missing translations error.
  //
  if (!event) {
    return null;
  }

  const isReject = event === 'reject';

  return (
    <Modal titleId="Confirm" isOpen={isOpen} onCancel={onClose}>
      <div className="w-100 h-100 flex justify-center items-center">
        <div>
          <div className="mb3">
            <ModelErrorBanner errors={error} />
          </div>
          <Panel>
            <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
              {applyToAll ? (
                <Text id={`BatchTransactionsApproval.title.all.${event}`} />
              ) : (
                <Text
                  id={`BatchTransactionsApproval.title.${event}`}
                  values={{ val: numberSelected }}
                />
              )}
            </h2>
            <p className="kw-weight-light mb3">
              <Text
                id={`BatchTransactionsApproval.subtitle.${event}`}
                values={{ val: numberSelected, br: <br /> }}
              />
            </p>
            <SubscribedTextArea
              name="notes"
              labelId="BatchTransactionsApproval.notes.label"
              placeholderId="BatchTransactionsApproval.notes.placeholder"
            />
            <ButtonBar
              className="mt4"
              left={
                <Button onClick={onClose}>
                  <Text id="BatchTransactionsApproval.cancel" />
                </Button>
              }
              right={
                <Button
                  classNames="mr3"
                  type={isReject ? 'destructive' : 'primary'}
                  onClick={onSubmit}
                >
                  <Text id={`BatchTransactionsApproval.confirm.${event}`} />
                </Button>
              }
            />
          </Panel>
        </div>
      </div>
    </Modal>
  );
};
