//@flow

import * as React from 'react';

import { TRIAL_BANNER_CLOSED } from '@kwara/models/src/lib/Auth';
import {
  WithNotification,
  type notification
} from '@kwara/components/src/Notification';

import { TrialBanner } from './TrialBanner';

type Props = {
  notification: notification
};

class TrialChecker extends React.Component<Props, *> {
  constructor({ storage = window.localStorage }) {
    super();
    this.storage = storage;
  }

  componentDidMount() {
    this.trigger();
  }

  trigger = () => {
    //const isTrial = this.storage.getItem(TRIAL_STATUS) === 'true';
    const hasBannerBeenClosed =
      this.storage.getItem(TRIAL_BANNER_CLOSED) === 'true';

    const shouldBannerDisplay = this.props.force || !hasBannerBeenClosed;

    const onBannerClose = () => {
      this.storage.setItem(TRIAL_BANNER_CLOSED, true);
    };

    if (shouldBannerDisplay) {
      this.props.notification.displayWarning(<TrialBanner />, onBannerClose);
    }
  };

  render() {
    return null;
  }
}

export const TrialNotificationChecker = WithNotification(TrialChecker);
