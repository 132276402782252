// @flow

import { decorate, observable, computed } from 'mobx';
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';

import {
  type UserT,
  type TransactionChannelT,
  type BankT
} from '@kwara/models/src';
import { Logger } from '@kwara/lib/src/logger';
import { minus } from '@kwara/lib/src/currency';

const BLOCKLIST_KEY = 'blocklist_key';

const BLOCK_ALL_USERS_ACCESS_KEY = 'block_all_users_access';

const ALLOWED_DAYS_KEY = 'allowed_days';
const ACCESS_TIME_FROM = 'access_time_from';
const ACCESS_TIME_TO = 'ACCESS_TIME_TO';

const TILL_MINIMUM_KEY = 'till_minimum';
const TILL_MAXIMUM_KEY = 'till_maximum';
const DEFAULT_TILL_MINIMUM = 500000;
const DEFAULT_TILL_MAXIMUM = 5000000;

const VAULT_MINIMUM_KEY = 'vault_minimum';
const VAULT_MAXIMUM_KEY = 'vault_maximum';
const DEFAULT_VAULT_MINIMUM = 1000000;
const DEFAULT_VAULT_MAXIMUM = 2000000;

export class Store {
  profile = {};
  banks = [];
  refetch = () => {};
  tellerConfigs = {};
  accessDays = [];
  accessTimes = {};
  blockAllUsersAccess = false;
  blockList = [];

  stimaBalanceMinimums = {
    alpha: 5000,
    fixed: 10000,
    children: 20000,
    msingi: 5000,
    sc: 10000,
    shares: 10000,
    twiiga: 15000
  };

  vaultConfigs = {
    reorderMinimum: DEFAULT_VAULT_MINIMUM,
    reorderMaximum: DEFAULT_VAULT_MAXIMUM
  };

  initializeBlocklist() {
    const cached = window.localStorage.getItem(BLOCKLIST_KEY);
    this.blockList = cached ? JSON.parse(cached) : [];
  }

  initializeTellerConfigs() {
    const cachedMinimum = window.localStorage.getItem(TILL_MINIMUM_KEY);
    const cachedMaximum = window.localStorage.getItem(TILL_MAXIMUM_KEY);

    this.tellerConfigs.tillMinimum = cachedMinimum
      ? Number(cachedMinimum)
      : DEFAULT_TILL_MINIMUM;

    this.tellerConfigs.tillMaximum = cachedMaximum
      ? Number(cachedMaximum)
      : DEFAULT_TILL_MAXIMUM;

    const cachedVault = window.localStorage.getItem(VAULT_MINIMUM_KEY);
    this.vaultConfigs.reorderMinimum = cachedVault
      ? Number(cachedVault)
      : DEFAULT_VAULT_MINIMUM;
  }

  initializeAllowedDays() {
    const cached = window.localStorage.getItem(ALLOWED_DAYS_KEY);
    this.accessDays = cached
      ? JSON.parse(cached)
      : [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday'
        ];
  }

  initializeAccessTimes() {
    const cachedFrom = window.localStorage.getItem(ACCESS_TIME_FROM);
    const cachedTo = window.localStorage.getItem(ACCESS_TIME_TO);

    this.accessTimes.fromTime = cachedFrom;
    this.accessTimes.toTime = cachedTo;
  }

  initializeBlockAllUsersAccess() {
    const cached = window.localStorage.getItem(BLOCK_ALL_USERS_ACCESS_KEY);
    this.blockAllUsersAccess = cached ? cached === 'true' : false;
  }

  setBlocklist(list = []) {
    this.blockList = list;
    window.localStorage.setItem(BLOCKLIST_KEY, JSON.stringify(list));
  }

  setAccessDays(days: string[]) {
    this.accessDays = days;
    window.localStorage.setItem(ALLOWED_DAYS_KEY, JSON.stringify(days));
  }

  setAccessTimes(times = {}) {
    this.accessTimes = times;
    window.localStorage.setItem(ACCESS_TIME_FROM, times.fromTime);
    window.localStorage.setItem(ACCESS_TIME_TO, times.toTime);
  }

  setBlockAllUsersAccess(blockAllUsersAccess: boolean) {
    this.blockAllUsersAccess = blockAllUsersAccess;
    window.localStorage.setItem(
      BLOCK_ALL_USERS_ACCESS_KEY,
      String(blockAllUsersAccess)
    );
  }

  updateProfile(p: UserT) {
    this.profile = p;
  }

  setRefetch(r: () => void) {
    this.refetch = r;
  }

  setRefetchTill(r: () => void) {
    this.refetchTill = r;
  }

  setCurrentTill(till: TillT) {
    this.currentTill = till;
  }

  setBanks(banks: BankT[]) {
    this.banks = banks;
  }

  setReorderLevel(amount) {
    this.tellerConfigs.tillMinimum = amount;
    window.localStorage.setItem(TILL_MINIMUM_KEY, amount);
  }

  setVaultLevels({ minAmount, maxAmount }) {
    this.vaultConfigs.reorderMinimum = minAmount;
    this.vaultConfigs.reorderMaximum = maxAmount;
    window.localStorage.setItem(VAULT_MINIMUM_KEY, minAmount);
    window.localStorage.setItem(VAULT_MAXIMUM_KEY, maxAmount);
  }

  get vaultId() {
    if (this.isKBS) {
      return '16199';
    }

    // I know makes no sense
    return '16199';
  }

  get maxAllowedDeposit() {
    return minus(
      get('tellerConfigs.tillMaximum', this),
      get('currentTill.currentAmount', this)
    );
  }

  get isKBS() {
    return get('profile.branch.id', this) === 'KENYA_BANKERS_SACCO_MAIN';
  }

  get isKwaraKenya() {
    return get('profile.branch.id', this) === 'arena_sacco';
  }

  isBridgingLoanProduct(id: string) {
    return get('profile.bridgingLoanProductId', this) === id;
  }

  isBridgingDepositProduct(id: string) {
    return get('profile.bridgingDepositProductId', this) === id;
  }

  get refinancingIsLive() {
    return (
      get('profile.bridgingDepositProductId', this) &&
      get('profile.bridgingLoanProductId', this)
    );
  }

  get branchName() {
    return get('profile.branch.name', this);
  }

  get organisation() {
    return get('profile.apiInstance', this);
  }

  get glTransactionChannels() {
    const allChannels = get('profile.branch.transactionChannels', this);
    const filtered = filter(c => c.isGlChannel, allChannels);
    return sortBy<TransactionChannelT>(c => c.id, filtered);
  }

  get currency() {
    const curr = get('profile.organisation.currency', this);
    // We only want to do this check when the user is logged in, so we first
    // check if org is defined
    if (this.organisation != null && curr == null) {
      Logger.error(`Currency not set for ${this.organisation}`);
    }
    // we fallback to KES in case existing orgs currency haven't been set yet
    // https://github.com/getkwara/webapp/pull/1232#discussion_r484820629
    return curr || 'KES';
  }

  get accruedInterestEnabled() {
    return get(
      'profile.organisation.configSettings.accrued_interest_enabled',
      this
    );
  }

  stimaMakerCheckerMinAmount = 1000000;

  isMakerCheckerEnabled(amount, type) {
    if (type === 'WITHDRAWAL') {
      return Number(amount) > 400000;
    }

    return Number(amount) > this.stimaMakerCheckerMinAmount;
  }

  get isSafaricomPhoneNumberValidationLive() {
    return true;
  }

  get warnIfTellerCloseToMinimum() {
    if (!this.currentTill) {
      return 0;
    }

    const threshold = 5000;
    const currentAmount = Number(this.currentTill.currentAmount);
    const tillMinimum = this.tellerConfigs.tillMinimum;

    const diff = currentAmount - tillMinimum;

    if (diff > threshold) {
      return 0;
    }

    return diff - threshold;
  }

  get warnTellerIfCloseToMaximum() {
    if (!this.currentTill) {
      return 0;
    }

    const threshold = 70;
    const currentAmount = Number(this.currentTill.currentAmount);
    const tillMax = this.tellerConfigs.tillMaximum;

    if (currentAmount < tillMax * (threshold / 100)) {
      return 0;
    }

    return 100 * (currentAmount / tillMax);
  }
}

decorate(Store, {
  profile: observable,
  isKBS: computed
});

export const store = new Store();
