import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';

import { pdfTranslationStore as pdf } from './translation';

function formatCurrency(value = 0) {
  return Number(value).toFixed(2);
}

export const styles = StyleSheet.create({
  defaultText: { color: 'grey', fontSize: 10, marginBottom: 3 },
  subtitleText: { color: 'black', fontSize: 12, marginBottom: 5 },
  titleText: { color: 'black', fontSize: 20, marginBottom: 8 }
});

export const DefaultText = ({ children, style, id, values }) => (
  <Text style={{ ...styles.defaultText, ...style }}>
    {id
      ? pdf.translate(id, { orgCurrency: pdf.currency, ...values })
      : children}
  </Text>
);

export const SubtitleText = ({ children, style, id, values }) => (
  <Text style={{ ...styles.subtitleText, ...style }}>
    {id
      ? pdf.translate(id, { orgCurrency: pdf.currency, ...values })
      : children}
  </Text>
);

export const TitleText = ({ children, style, id, values }) => (
  <Text style={{ ...styles.titleText, ...style }}>
    {id
      ? pdf.translate(id, { orgCurrency: pdf.currency, ...values })
      : children}
  </Text>
);

export const Currency = ({ children, style }) => (
  <DefaultText style={{ ...styles.defaultText, ...style }}>
    {formatCurrency(children)}
  </DefaultText>
);
