// @flow
import { attr } from 'spraypaint';
import get from 'lodash/fp/get';

import Base, { type BaseModel } from './Base';

const MemberReportState = Object.freeze({
  pending: 'pending',
  sent: 'sent',
  failed: 'failed'
});

type MemberReportStatesT = $Values<typeof MemberReportState>;

export const MemberReport = Base.extend({
  static: {
    jsonapiType: 'member_reports'
  },
  attrs: {
    id: attr(),
    state: attr(),
    completed: attr(),
    email: attr()
  },
  methods: {
    currentState() {
      return get('state.current', this);
    }
  }
});

export interface MemberReportT extends BaseModel<MemberReportT> {
  id: string;
  state: {
    current: MemberReportStatesT
  };
  completed: number;
  currentState: () => MemberReportStatesT;
  email: string;
}
