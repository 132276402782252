import Name from './components/Name';
import Personal from './components/Personal';
import Address from './components/Address';
import Identity from './components/Identity';
import Employment from './components/Employment';
import Kin from './components/Kin';
import Contact from './components/Contact';
import Documents from './components/Documents';
import Notes from './components/Notes';
import Summary from './components/Summary';

import * as recordValidations from '../../lib/recordValidations';

export const steps = {
  about: {
    titleId: 'SelfService.Step.about',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'contact'
      }
    ],
    children: [
      {
        titleId: 'SelfService.Profile.title',
        Component: Name,
        validate: {
          firstName: {
            isRequired: () => true,
            maxlength: 30,
            ...recordValidations.personName
          },
          middleName: { ...recordValidations.personName },
          lastName: {
            isRequired: () => true,
            maxlength: 30,
            ...recordValidations.personName
          }
        }
      },
      {
        titleId: 'SelfService.Personal.title',
        Component: Personal,
        validate: {
          dateOfBirth: {
            isRequired: () => true,
            ...recordValidations.dateOfBirth
          }
        }
      },
      {
        titleId: 'SelfService.Address.title',
        Component: Address
      },
      {
        titleId: 'SelfService.Identity.title',
        Component: Identity,
        validate: {
          ['idDocuments[0].type']: { isRequired: () => true },
          ['idDocuments[0].documentId']: {
            isRequired: () => true,
            ...recordValidations.identityDocumentValue
          }
        }
      },
      {
        titleId: 'SelfService.Employment.title',
        Component: Employment,
        validate: Employment.validate
      },
      {
        titleId: 'SelfService.Kin.title',
        Component: Kin
      }
    ]
  },
  contact: {
    titleId: 'SelfService.Step.contact',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'documents'
      }
    ],
    children: [
      {
        titleId: 'SelfService.Contact.title',
        Component: Contact,
        validate: {
          phoneNumber: {
            phoneNumber: true
          },
          secondaryPhoneNumber: {
            phoneNumber: true
          },
          email: { email: true }
        }
      }
    ]
  },
  documents: {
    titleId: 'SelfService.Step.documents',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'notes'
      }
    ],
    children: [
      {
        titleId: 'SelfService.Documents.title',
        subtitleId: 'SelfService.Documents.subtitle',
        Component: Documents
      }
    ]
  },
  notes: {
    titleId: 'SelfService.Step.notes',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'summary'
      }
    ],
    children: [
      {
        titleId: 'SelfService.Notes.title',
        subtitleId: 'SelfService.Notes.subtitle',
        Component: Notes
      }
    ]
  },
  summary: {
    titleId: 'SelfService.Step.summary',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'SelfService.Summary.title',
        subtitleId: 'SelfService.Summary.subtitle',
        Component: Summary,
        progress: false
      }
    ]
  }
};
