//@flow
import * as React from 'react';
import { FormSpy } from 'react-final-form';

import { type SubStepComponentProps } from '../../../../components/Wizard';

import { SavingProduct, type SavingProductType } from '@kwara/models/src';

import { Text } from '@kwara/components/src/Intl';
import { DeprecatedLoadable } from '@kwara/components/src/Loadable';
import { SavingsProductSelector } from '../../../../components/ProductSelector';

import { type WizardData } from '../..';

export default class Accounts extends React.Component<
  SubStepComponentProps<WizardData>,
  *
> {
  state = {
    products: SavingProduct.all().then(response => response.data)
  };

  selectProduct = (product: SavingProductType, shareCapital: ?boolean) => {
    const savingsProduct = product;

    this.props.addData({
      savingsProduct,
      shareCapital
    });
  };

  renderContent = (products: SavingProductType[]) => (
    <FormSpy>
      {(props: { values?: { shareCapital: ?boolean } }) =>
        products.length > 0 ? (
          <SavingsProductSelector
            products={products}
            onSelect={(product: SavingProductType) =>
              this.selectProduct(
                product,
                props.values ? props.values.shareCapital : undefined
              )
            }
            selectable
          />
        ) : (
          <div className="tc">
            <Text id="MemberApprove.Accounts.noProducts" />
          </div>
        )
      }
    </FormSpy>
  );

  render() {
    const { Checkbox, StackChild } = this.props;
    return (
      <React.Fragment>
        <StackChild>
          <Checkbox
            name="shareCapital"
            labelId="MemberApprove.Accounts.shareCapital"
            infoId="MemberApprove.Accounts.shareCapitalInfo"
          />

          <h3 className="kw-text-small grey-400">
            <Text id="MemberApprove.Accounts.savings" />
          </h3>
        </StackChild>

        <StackChild size="wide">
          <DeprecatedLoadable
            loader={this.state.products}
            loaded={this.renderContent}
          />
        </StackChild>
      </React.Fragment>
    );
  }
}
