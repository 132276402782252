// @flow

import * as React from 'react';

import {
  TopupRequestStatus,
  type TopupRequestT
} from '@kwara/models/src/models/TopupRequest';
import { Loadable } from '@kwara/components/src/Loadable';

import Wizard from '../../components/Wizard';
import { tellerPath } from '../../lib/urls';
import { useTopupRequest } from '../../models/request';
import { steps } from './config';
import type { WizardPageProps } from '..';

type Props = WizardPageProps<{ topupId: string, initialData: TopupRequestT }>;

export const TopupReview = (props: Props) => {
  const { baseUrl, match, history } = props;
  const response = useTopupRequest(props.match.params.topupId);

  const approve = async topup => {
    topup.status = TopupRequestStatus.APPROVED;
    const didSave = await topup.save();
    if (!didSave) {
      throw topup.errors;
    }
  };

  const reject = async topup => {
    topup.status = TopupRequestStatus.DECLINED;
    const didSave = await topup.save();
    if (!didSave) {
      throw topup.errors;
    }
  };

  return (
    <Loadable {...response}>
      {topupRequest => {
        return (
          <Wizard
            analyticsId="TopupReview"
            baseUrl={baseUrl}
            history={history}
            initialData={topupRequest}
            cancelReturnsTo={tellerPath()}
            currentStep={match.params.step}
            currentSubStep={
              match.params.subStep != null
                ? parseInt(match.params.subStep, 10)
                : null
            }
            onSubmit={approve}
            onReject={reject}
            steps={steps}
            startId="review"
            titleId="TopupReview.shortTitle"
            type="approval"
          />
        );
      }}
    </Loadable>
  );
};
