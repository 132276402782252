// @flow
import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/map';

import { Text, Date, FileSize } from '@kwara/components/src/Intl';
import { ActionButton } from '@kwara/components/src/Button';
import { type AttachmentT, type MemberType } from '@kwara/models/src';
import { type AttachmentContext } from '@kwara/models/src/models/Attachment';
import { appName } from '@kwara/lib/src/utils';
import {
  allowedAttachments,
  WithViewer
} from '@kwara/components/src/UploadWidget';

import styles from './index.module.css';

interface Props {
  size?: 'tiny' | 'default';
}
type MetaDataProps = Props & {
  attachment: AttachmentT
};
const MetaData = (props: MetaDataProps) => {
  const { size, attachment } = props;
  const { isMember } = appName;
  const textClasses = isMember ? 'kw-text-small' : 'kw-text-regular';

  if (size === 'tiny') {
    return null;
  }

  if (attachment.createdAt == null) {
    return (
      <div className={cx('grey-400', textClasses)}>
        <Text id="DocumentUploads.JustUploaded" />
      </div>
    );
  }

  return (
    <div className={cx('grey-400', textClasses)}>
      <FileSize value={attachment.fileSize} /> &middot;{' '}
      {!isMember ? <Text id="DocumentUploads.UploadedOn" /> : null}{' '}
      <Date value={attachment.createdAt} />
    </div>
  );
};

interface DocumentListProps extends Props {
  member: MemberType;
  contexts: AttachmentContext[];
  attachments: AttachmentT[];
}
export const DocumentList = ({
  member,
  contexts,
  attachments = [],
  size = 'default'
}: DocumentListProps) => {
  const filteredAttachments = contexts
    ? allowedAttachments.filterByContext(contexts, attachments)
    : attachments;

  const { isMember } = appName;

  return (
    <WithViewer member={member}>
      {({ setShownAttachment }) => {
        return map(filteredAttachments, attachment => (
          <div
            key={attachment.id}
            className={cx(styles.Wrapper, 'mb3', {
              'bg-light-grey-200 br3 pa3': isMember
            })}
          >
            <div
              className={cx('black kw-weight-regular', {
                'kw-text-medium': size === 'default' && !isMember
              })}
            >
              <MetaData size={size} attachment={attachment} />
              <Text id={`DocumentUploads.${attachment.name}.label`} />
            </div>
            <div>
              <ActionButton
                onClick={() => {
                  setShownAttachment(attachment);
                }}
                type="view"
                size={size}
              />
            </div>
          </div>
        ));
      }}
    </WithViewer>
  );
};
