import React from 'react';

import { Text } from '@kwara/components/src/Intl';

export const ComingSoon = () => {
  return (
    <div>
      <Text id="Settings.ComingSoon" />
    </div>
  );
};
