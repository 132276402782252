//@flow
import * as React from 'react';

import { type SubStepComponentProps } from '../../../../components/Wizard';

import { Text } from '@kwara/components/src/Intl';
import { positiveNumber } from '../../../../lib/recordValidations';

import { type WizardData } from '../..';

const Deposits = ({
  StackChild,
  TextField,
  data
}: SubStepComponentProps<WizardData>) => {
  const product = data.savingsProduct;

  return (
    <StackChild>
      {product == null ? (
        <p>
          <Text id="MemberApprove.Deposits.noProduct" />
        </p>
      ) : (
        <>
          <h3 className="kw-super-large kw-weight-bold">{product.name}</h3>
          <TextField
            errorBaseId="MemberApprove.Deposits.Amount"
            leftGlyph="Currency.orgCurrency"
            name="savingsAccountDeposit"
            labelId="MemberApprove.Deposits.amountLabel"
            placeholderId="MemberApprove.Deposits.amountPlaceholder"
          />
          <TextField
            name="savingsAccountDepositReference"
            labelId="MemberApprove.Deposits.referenceLabel"
          />
        </>
      )}
    </StackChild>
  );
};

Deposits.validate = {
  savingsAccountDeposit: {
    ...positiveNumber
  }
};

export default Deposits;
