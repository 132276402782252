// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { type SavingType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Statistic, PenaltyStatistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { Panel } from '../ActionModal';

type Props = {
  saving: SavingType
};

export const SavingPanel = ({ saving }: Props) => {
  const fees = get('fees.total', saving);

  return (
    <Panel>
      <Statistic
        title={<Text id="Payment.saving" values={{ id: saving.id }} />}
        value={saving.name}
        size="large"
        padding={false}
      />
      <StatisticRow widthClass="w-50" border={true}>
        <Statistic
          title={<Text id="Payment.member" values={{ id: saving.member.id }} />}
          size="medium"
          value={saving.member.fullName()}
        />
        <PenaltyStatistic
          title={<Text id="Payment.feesBalance" />}
          size="medium"
          errorValue={fees}
        />
      </StatisticRow>
    </Panel>
  );
};
