// @flow

import * as React from 'react';
import cx from 'classnames';

import { UserTypes } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import logotype from '@kwara/components/src/LogIn/logotype.svg';
import { Footer } from '@kwara/components/src/Footer';
import type { UserType } from '@kwara/models/src';

import { type Invitation } from './components/Redeemer';
import Redeemer from './components/Redeemer';
import styles from './index.module.scss';

type Props = {
  invitation: Invitation,
  userType: UserType
};

const inviteToken = (
  searchParams: typeof window.location.search = window.location.search
) => {
  const urlParams = new URLSearchParams(searchParams);
  return urlParams.get('t');
};

export const InvitationRedeem = ({ invitation, userType }: Props) => (
  <div
    className={cx(styles.Container, {
      [styles.Container__Member]: userType === UserTypes.MEMBER,
      'bg-white': userType === UserTypes.MEMBER
    })}
  >
    <div className={cx(styles.Image, 'h-100')}>
      <img alt="Kwara" className={styles.Logotype} src={logotype} />
    </div>

    <div
      className={cx(styles.Content, 'pa4', {
        [styles.Content__Member]: userType === UserTypes.MEMBER,
        'bg-white': userType === UserTypes.MEMBER
      })}
    >
      <div className={'flex flex-column'}>
        <h1 className="kw-text-super-large kw-weight-bold">
          <Text id="InvitationRedeem.RedeemerUI.title" />
        </h1>
        <Redeemer
          userType={userType}
          invitation={invitation}
          inviteToken={inviteToken()}
        />
      </div>
      <Footer />
    </div>
  </div>
);
