// @flow

import * as React from 'react';
import { Form, Field as FinalFormField } from 'react-final-form';

import pipe from 'lodash/fp/pipe';
import toLower from 'lodash/fp/toLower';
import trim from 'lodash/fp/trim';
import classnames from 'classnames';

import createValidator from '@kwara/lib/src/validator';
import Banner from '@kwara/components/src/Banner';
import Button from '@kwara/components/src/Button';
import { Field } from '@kwara/components/src/Form';
import { Select, Option } from '../../components/Form';
import {
  SubscribedTextField,
  SubscribedPasswordField,
  Condition
} from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';
import { snakeCaseObjectKeys } from '@kwara/models/src';
import logotype from './logotype.svg';
import styles from './index.module.css';

const processEmail = pipe(
  trim,
  toLower
);

type Props = {
  auth: Object,
  history: {
    push: (path: string, ctx?: ?LinkContext) => void
  }
};

type FormData = {
  password: string,
  email: string,
  firstName: string,
  lastName: string,
  passwordConfirmation: string,
  saccoName: string,
  phoneNumber?: string,
  profile?: 'staff' | 'member' | 'neither',
  role?: string
};

export default class SignUp extends React.Component<
  Props,
  {
    error: ?string
  }
> {
  static validator = createValidator({
    firstName: {
      isRequired: () => true
    },
    lastName: {
      isRequired: () => true
    },
    email: {
      isRequired: () => true
    },
    saccoName: {
      isRequired: () => true
    },
    password: {
      isRequired: () => true,
      minlength: 10,
      containsAlphaNum: true
    },
    passwordConfirmation: {
      isRequired: () => true,
      custom: function(passwordConfirmation, allData) {
        return passwordConfirmation === allData.password;
      }
    }
  });

  state = {
    error: null
  };

  static validate(values: FormData) {
    return SignUp.validator(values);
  }

  displayError = (errorMessage: string) => {
    this.setState({
      error: errorMessage
    });
    setTimeout(() => {
      this.setState({
        error: null
      });
    }, 3000);
  };

  handleSubmit = (data: FormData) => {
    const { auth, history } = this.props;
    auth
      .signUp({ ...snakeCaseObjectKeys(data), email: processEmail(data.email) })
      .then(() => {
        history.push('/success');
      })
      .catch(() => {
        this.displayError('Something went wrong.');
      });
  };

  render() {
    const { error } = this.state;
    return (
      <div className={classnames(styles.Container, 'fl h-100 w-100 bg-white')}>
        <div className={classnames(styles.Image, 'h-100')}>
          <img alt="Kwara" className={styles.Logotype} src={logotype} />
        </div>
        <div className={classnames(styles.FormWrap, 'h-100 pl4 fl flex')}>
          <div className={classnames(styles.Form)}>
            <h1 className="kw-text-extra-big kw-weight-bold">
              <Text id="SignUp.title" />
            </h1>
            <Form
              validate={SignUp.validate}
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, invalid }) => {
                return (
                  <form className="w-80 mb4" onSubmit={handleSubmit}>
                    <p className="grey-400 kw-text-regular">
                      <Text id="SignUp.intro" />
                    </p>

                    <SubscribedTextField
                      name="firstName"
                      labelId="SignUp.firstName"
                      errorBaseId="SignUp.name"
                    />
                    <SubscribedTextField
                      name="lastName"
                      labelId="SignUp.lastName"
                      errorBaseId="SignUp.name"
                    />
                    <SubscribedTextField
                      name="email"
                      labelId="SignUp.email"
                      errorBaseId="SignUp.email"
                    />

                    <SubscribedPasswordField
                      name="password"
                      labelId="SignUp.password"
                      errorBaseId="SignUp.password"
                      infoId="SignUp.password.info"
                    />
                    <SubscribedPasswordField
                      name="passwordConfirmation"
                      labelId="SignUp.passwordConfirm"
                      errorBaseId="SignUp.passwordConfirm"
                    />
                    <SubscribedTextField
                      name="saccoName"
                      labelId="SignUp.nameSacco"
                      errorBaseId="SignUp.sacco"
                    />
                    <SubscribedTextField
                      name="phoneNumber"
                      labelId="SignUp.phoneNumber"
                    />

                    <FinalFormField
                      name="profile"
                      render={({ input }) => (
                        <Field labelId="SignUp.profile" name={input.name}>
                          <Select size="regular" {...input}>
                            <Option
                              value=""
                              translationId="SignUp.profile.default"
                            />
                            <Option
                              value="staff"
                              translationId="SignUp.profile.staff"
                            />
                            <Option
                              value="member"
                              translationId="SignUp.profile.member"
                            />
                            <Option
                              value="neither"
                              translationId="SignUp.profile.neither"
                            />
                          </Select>
                        </Field>
                      )}
                    />
                    <Condition when="profile" is="staff">
                      <SubscribedTextField
                        name="role"
                        labelId="SignUp.roleSacco"
                      />
                    </Condition>

                    <Button
                      isSubmit
                      type="primary"
                      size="medium"
                      disabled={invalid}
                    >
                      <Text id="SignUp.signUp" />
                    </Button>
                    {error && (
                      <Banner className="mt4" state="error" text={error} />
                    )}
                  </form>
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
