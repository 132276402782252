// @flow

import * as React from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import head from 'lodash/fp/head';
import size from 'lodash/fp/size';

import { ExpanderRow, Loadable } from '@kwara/components/src';
import { Text } from '@kwara/components/src/Intl';
import Banner from '@kwara/components/src/Banner';
import { type MemberType } from '@kwara/models/src';
import { mapIndexed } from '@kwara/lib/src/lodash';
import { useMember } from '@kwara/models/src/models/request/hooks';

import { validateMember, getMissingAttachments } from './utils';
import {
  PersonalDetails,
  Identity,
  NextOfKin,
  Employment,
  Bank,
  Documents
} from '../../../MemberDetail/components';
import { Updater } from '../../../../components/EditableSection';
import { type LoanSubstepProps } from '../..';

import style from './index.module.scss';

const Warn = ({ when }: { when: ?string }) =>
  when ? (
    <span className={cx('red-500', style.ErrorMsg)}>
      <Text id="LoanAdd.MemberReview.SectionIncomplete" />
    </span>
  ) : null;

const Header = (props: { children: React.Node }) => (
  <header className="flex">{props.children}</header>
);

const JoinWithSeparator = ({
  sep = 'or',
  items
}: {
  sep?: 'or' | 'and',
  items: React.Node[]
}) => {
  const length = size(items);
  return mapIndexed((item, idx) => {
    if (idx + 1 < length) {
      return (
        <span key={idx}>
          {item} <Text id={`General.Separators.${sep}`} />{' '}
        </span>
      );
    }

    return <span key={idx}>{item}</span>;
  }, items);
};

const MissingDocumentsWarning = (props: { member: MemberType }) => {
  const missingAttachments = getMissingAttachments(props && props.member);
  if (isEmpty(missingAttachments)) {
    return null;
  }

  return (
    <div className={cx('red-500 mt3', style.ErrorMsg)}>
      <Text id="LoanAdd.MemberReview.MissingDocuments.warning" />
      <ul>
        {map(
          entry => (
            <li key={head(entry)}>
              <JoinWithSeparator
                items={map(
                  fileName => (
                    <Text id={`DocumentUploads.${fileName}.label`} />
                  ),
                  entry
                )}
              />
            </li>
          ),
          missingAttachments
        )}
      </ul>
    </div>
  );
};

const includes = [
  'loans.product',
  'savings',
  'addresses',
  'next_of_kin',
  'attachments',
  'id_documents'
];

export function MemberReview({
  data,
  onChange,
  StackChild,
  formProps
}: LoanSubstepProps) {
  const { errors = {} } = formProps;
  const { member = {} } = data;
  const valid = isEmpty(errors);
  const { isPending, data: memberRes, error } = useMember(member.id, includes);
  const onUpdate = editedMember =>
    onChange({ member: editedMember, loans: editedMember.loans });
  React.useEffect(() => {
    onUpdate(memberRes);
    // eslint-disable-next-line
  }, [memberRes]);

  return (
    <Loadable isPending={isPending} error={error}>
      <Updater value={{ onUpdate }}>
        <StackChild size="wide">
          <Banner
            className="mb5"
            state={valid ? 'success' : 'error'}
            text={
              <Text
                id={`LoanAdd.MemberReview.Banner.${valid ? 'ok' : 'warn'}`}
                values={{
                  firstName: member.firstName
                }}
              />
            }
          />
          <Header>
            <PersonalDetails.Title />
            <Warn when={errors.personalDetails} />
          </Header>
          <ExpanderRow>
            <PersonalDetails
              member={member}
              config={PersonalDetails.editConfig.loanAdd}
            />
          </ExpanderRow>

          <Header>
            <Identity.Title />
            <Warn when={errors.identity} />
          </Header>
          <ExpanderRow>
            <Identity member={member} config={Identity.editConfig.loanAdd} />
          </ExpanderRow>

          <Header>
            <NextOfKin.Title />
            <Warn when={errors.nextOfKin} />
          </Header>
          <ExpanderRow>
            <NextOfKin member={member} />
          </ExpanderRow>

          <Header>
            <Employment.Title />
            <Warn when={errors.employment} />
          </Header>
          <ExpanderRow>
            <Employment
              member={member}
              config={Employment.editConfig.loanAdd}
            />
          </ExpanderRow>

          <Header>
            <Bank.Title />
            <Warn when={errors.bank} />
          </Header>
          <ExpanderRow>
            <Bank member={member} />
          </ExpanderRow>

          <Header>
            <Documents.Title />
            <Warn when={errors.documents} />
          </Header>
          <ExpanderRow>
            <MissingDocumentsWarning member={member} />
            <Documents member={member} />
          </ExpanderRow>
        </StackChild>
      </Updater>
    </Loadable>
  );
}

MemberReview.validate = ({ member, loans }) => {
  // User should not proceed until the members' loans
  // are fetched and added to the data model.
  if (!loans) {
    return { loans: { isRequired: () => true } };
  }

  return validateMember(member);
};
