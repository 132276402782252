import * as React from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import { mapIndexed } from '@kwara/lib/src/lodash';
import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';

import Table, {
  Cell,
  Collapsible,
  Heading,
  Row
} from '../../../components/Table';
import { ActionableHeading } from '../../../components/OverviewList';
import { ActionModal, Panel }from '../../../components/ActionModal';
import { TableActions } from './shared';
import { useProduct } from './utils';

function Modal({ idx, setModalState, TextField, SelectField, formProps }) {
  const baseField = `loanProducts[${idx}]`;
  return (
    <ActionModal
      actions
      disabled={formProps.invalid}
      titleId="Onboarding.LoanProducts.ModalAdd.Title"
      onConfirm={() => {
        formProps.form.submit();
        formProps.form.change(formProps.form.getState());
        setModalState(false);
      }}
      onCancel={() => {
        formProps.form.reset();
        setModalState(false);
      }}
    >
      <Panel>
        <h2 className="mt1 mb3 kw-text-large grey-400">
          <Text id="Onboarding.LoanProducts.LoanName.title" />
        </h2>
        <TextField
          name={`${baseField}.name`}
          errorBaseId="Onboarding.LoanProducts.LoanName"
          labelId="Onboarding.LoanProducts.LoanName.label"
        />
      </Panel>

      <Panel>
        <h2 className="mt1 mb3 kw-text-large grey-400">
          <Text id="Onboarding.LoanProducts.defaults.title" />
        </h2>
        <div className="flex">
          <TextField
            className="w-50"
            name={`${baseField}.defaultInterestRate`}
            type="number"
            errorBaseId="Onboarding.LoanProducts.InterestRate"
            placeholderId="Onboarding.LoanProducts.InterestRate.placeholder"
            labelId="Onboarding.LoanProducts.InterestRate.label"
            rightGlyph="%"
          />
        </div>
        <div className="flex">
          <TextField
            className="w-50"
            name={`${baseField}.defaultRepaymentInstallments`}
            type="number"
            errorBaseId="Onboarding.LoanProducts.defaultRepaymentInstallments"
            placeholderId="Onboarding.LoanProducts.defaultRepaymentInstallments.placeholder"
            labelId="Onboarding.LoanProducts.defaultRepaymentInstallments.label"
            rightGlyph=""
          />
        </div>
      </Panel>

      <Panel>
        <h2 className="mt1 mb3 kw-text-large grey-400">
          <Text id="Onboarding.LoanProducts.MinMaxAmount.title" />
        </h2>
        <div className="flex justify-between">
          <div className="w-50 pr2">
            <TextField
              className="w-50"
              name={`${baseField}.mimumPrincipalAmount`}
              min={0}
              type="number"
              errorBaseId="Onboarding.LoanProducts.MinMaxAmount"
              labelId="Onboarding.LoanProducts.MinAmount.label"
              placeholderId="Onboarding.LoanProducts.MinMaxAmount.placeholder"
              info={<Text id="Onboarding.LoanProducts.MinAmount.help" />}
            />
          </div>
          <div className="w-50 pl2">
            <TextField
              className="w-50"
              name={`${baseField}.maximumPrincipalAmount`}
              min={0}
              type="number"
              errorBaseId="Onboarding.LoanProducts.MinMaxAmount"
              labelId="Onboarding.LoanProducts.MaxAmount.label"
              placeholderId="Onboarding.LoanProducts.MinMaxAmount.placeholder"
              info={<Text id="Onboarding.LoanProducts.MaxAmount.help" />}
            />
          </div>
        </div>
      </Panel>
      <Panel>
        <h2 className="mt1 mb3 kw-text-large grey-400">
          <Text id="Onboarding.LoanProducts.RepaymentPeriodCount.title" />
        </h2>
        <div className="flex justify-between">
          <div className="w-50 ml2">
            <TextField
              className="w-50"
              name={`${baseField}.repaymentPeriodCount`}
              type="number"
              errorBaseId="Onboarding.LoanProducts.MemberRestrictions.memberDuration"
              placeholderId="Onboarding.LoanProducts.MemberRestrictions.memberDuration.placeholder"
              labelId="Onboarding.LoanProducts.MemberRestrictions.memberDuration.label"
            />
          </div>
          <div className="w-50 ml2 mt2">
            <SelectField
              name={`${baseField}.repaymentPeriodUnit`}
              errorBaseId="Onboarding.LoanProducts.RepaymentPeriodUnit"
            >
              <SelectField.Option
                translationId="Onboarding.LoanProducts.RepaymentPeriodUnit.weeks"
                value="WEEKS"
              />
              <SelectField.Option
                translationId="Onboarding.LoanProducts.RepaymentPeriodUnit.months"
                value="MONTHS"
              />
            </SelectField>
          </div>
        </div>
      </Panel>

      <Panel>
        <h2 className="mt1 mb3 kw-text-large grey-400">
          <Text id="Onboarding.LoanProducts.maximumRepaymentInstallments.title" />
        </h2>
        <div className="flex">
          <TextField
            className="w-50"
            name={`${baseField}.maximumRepaymentInstallments`}
            type="number"
            min={1}
            errorBaseId="Onboarding.LoanProducts.MaxRepayment"
            labelId="Onboarding.LoanProducts.MaxRepayment.label"
          />
        </div>
      </Panel>

      <Panel>
        <h2 className="mt1 mb3 kw-text-large grey-400">
          <Text id="Onboarding.LoanProducts.interestCalculationMethod.title" />
        </h2>
        <div className="flex">
          <SelectField
            className="w-50 pl2"
            name={`${baseField}.interestCalculationMethod`}
            errorBaseId="Onboarding.LoanProducts.interestCalculationMethod"
          >
            <SelectField.Option
              translationId="Onboarding.LoanProducts.InterestRateRepeat.flat"
              value="FLAT"
            />
            <SelectField.Option
              translationId="Onboarding.LoanProducts.InterestRateRepeat.decliningBalance"
              value="DECLINING_RATE"
            />
          </SelectField>
        </div>
      </Panel>
    </ActionModal>
  );
}

export function LoanProducts({
  StackChild,
  TextField,
  Checkbox,
  SelectField,
  RadioGroup,
  Condition,
  data,
  addData,
  formProps,
  onChange
}) {
  const {
    isOpen,
    setModalState,
    index,
    addProduct,
    editProduct,
    removeProduct,
    id
  } = useProduct(data.loanProducts);

  return (
    <>
      <StackChild>
        {isOpen ? (
          <Modal
            idx={index}
            data={data}
            addData={addData}
            id={id}
            {...{
              TextField,
              setModalState,
              SelectField,
              Checkbox,
              Condition,
              RadioGroup,
              formProps
            }}
          />
        ) : null}
        {isEmpty(data.loanProducts) ? (
          <Empty img="triangle">
            <div className="flex flex-column">
              <p>
                <Text id="Onboarding.LoanProducts.NoProductsMessage" />
              </p>
              <div className="mb2">
                <Button onClick={addProduct} type="primary">
                  <Text id="Onboarding.LoanProducts.AddProductsMessage" />
                </Button>
              </div>
            </div>
          </Empty>
        ) : null}
      </StackChild>
      {isEmpty(data.loanProducts) ? null : (
        <StackChild size="medium">
          <ActionableHeading
            headerId="Onboarding.Step.LoanProductsMain.title"
            actions={[
              <Button key="add" onClick={addProduct}>
                <Text id="Onboarding.LoanProducts.AddProductsMessage" />
              </Button>
            ]}
          />
          <Table
            className="mb5"
            heading={
              <Row>
                <Heading>
                  <Text id="Onboarding.LoanProducts.Table.Heading.Name" />
                </Heading>
                <Heading>
                  <Text id="Onboarding.LoanProducts.Table.Heading.InterestRate" />
                </Heading>
                <Heading>
                  <Text id="Onboarding.LoanProducts.Table.Heading.Principal" />
                </Heading>
                <Heading>
                  <Text id="Onboarding.LoanProducts.Table.Heading.Repayment" />
                </Heading>
              </Row>
            }
          >
            {mapIndexed(
              (product = {}, idx) => (
                <Collapsible key={product.name} addIcon={false}>
                  <Row>
                    <Cell>{product.name}</Cell>
                    <Cell>
                      {product.defaultInterestRate != null
                        ? `${product.defaultInterestRate}%`
                        : '-'}
                    </Cell>
                    <Cell>
                      {product.minAmount || 1}
                      {product.maxAmount
                        ? ` / ${product.maxAmount}`
                        : ' to unlimited'}
                    </Cell>
                    <Cell className="relative">
                      {product.repaymentPeriodCount
                        ? `${
                            product.repaymentPeriodCount
                          } ${product.repaymentPeriodUnit || 'months'}
                      `
                        : null}
                      <TableActions
                        onEdit={() => editProduct(idx)}
                        onRemove={() =>
                          onChange({ loanProducts: removeProduct(idx) })
                        }
                      />
                    </Cell>
                  </Row>
                </Collapsible>
              ),
              data.loanProducts || []
            )}
          </Table>
        </StackChild>
      )}
    </>
  );
}
