// @flow

import React from 'react';
import get from 'lodash/fp/get';

import ScrollIntoFocus from '@kwara/components/src/ScrollIntoFocus';
import { tomorrow, formatIsoDate } from '@kwara/lib/src/dates';
import { type LoanProductType } from '@kwara/models/src';
import { calculateNumberInstallments } from '@kwara/models/src/models/Loan';
import { SchedulesPreview } from '@kwara/models/src/models/SchedulePreview';
import Button from '@kwara/components/src/Button';
import { Loadable } from '@kwara/components/src/Loadable';
import { Text, Percent } from '@kwara/components/src/Intl';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Statistic } from '@kwara/components/src/Statistic';
import { ButtonBar } from '@kwara/components/src/ButtonBar';

import { SubscribedDatePicker as DatePicker } from '../../../components/Form';
import { Grid } from '../../../components/Grid';
import { Schedule as ScheduleTable } from '../../LoanDetail/components/Schedule';
import { getMinMaxLoanProductAmount } from '../../LoanAdd/components/Configure/PrincipalAmountField';
import {
  LoanTermsFields,
  DurationField,
  RepaymentPeriodFields
} from '../../LoanAdd/components/Configure/LoanTermsFields';
import { EMPTY, hasErrors } from '../../../models/request';

import { type SubStepComponentProps } from '../../../components/Wizard';
type PreviewT = SubStepComponentProps<{
  product: LoanProductType,
  principalMax: number,
  principalMin: number
}>;

const { useState } = React;

export const Preview = (props: PreviewT) => {
  const { StackChild, TextField, formProps } = props;
  const { invalid, values: data } = formProps;
  const { product } = data;
  const {
    principalMax: maximum,
    principalMin: minimum
  } = getMinMaxLoanProductAmount(data);

  const [isPending, setIsPending] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [error, setError] = useState(EMPTY);

  const clear = () => {
    setSchedule(null);
    setError(EMPTY);
  };

  const onSubmit = async () => {
    clear();
    setIsPending(true);

    const {
      amount,
      firstRepaymentDate,
      loanDuration,
      repaymentPeriod: period,
      repaymentPeriodUnit: unit
    } = data;

    const repaymentInstallments = calculateNumberInstallments({
      loanDuration: Number(loanDuration),
      period: Number(period)
    });

    const params = {
      amount,
      firstRepaymentDate: formatIsoDate(firstRepaymentDate),
      repaymentInstallments,
      repaymentPeriod: period,
      repaymentPeriodUnit: unit,
      interestRate: product.interestRate.percentage
    };

    try {
      const schedule = await SchedulesPreview.generate(product.id, params);
      setSchedule(schedule);
    } catch (err) {
      setError(err);
    } finally {
      setIsPending(false);
    }
  };

  const amountInfo = maximum ? (
    <Text
      id="LoanSchedulePreview.Preview.amount.info"
      values={{ maximum, minimum }}
    />
  ) : null;

  return (
    <StackChild size="widest">
      <StatisticRow widthClass="w-third">
        <Statistic
          size="medium"
          title={<Text id="LoanSchedulePreview.Preview.productId" />}
          value={product.id}
        />
        <Statistic
          size="medium"
          title={<Text id="LoanSchedulePreview.Preview.productName" />}
          value={product.name}
        />
        <Statistic
          size="medium"
          title={<Text id="LoanSchedulePreview.Preview.interestRate" />}
          value={
            <Percent value={get('interestRate.percentage', product) / 100} />
          }
        />
      </StatisticRow>

      <Grid columns={2} width="w-50" border={false}>
        <TextField
          name="amount"
          titleId="LoanSchedulePreview.Preview.amount.titleId"
          info={amountInfo}
          required
        />
        <RepaymentPeriodFields {...props} />
        <DatePicker
          name="firstRepaymentDate"
          titleId="LoanSchedulePreview.Preview.firstRepaymentDate.titleId"
          disabledDays={{ before: tomorrow() }}
        />
        <DurationField {...props} />
      </Grid>

      <ButtonBar
        className="mb4"
        left={[]}
        right={[
          schedule ? (
            <Button disabled={isPending} type="secondary" onClick={clear}>
              <Text id="LoanSchedulePreview.Preview.button.clearSchedule" />
            </Button>
          ) : null,
          <Button
            disabled={isPending || invalid}
            type="primary"
            onClick={onSubmit}
          >
            <Text id="LoanSchedulePreview.Preview.button.previewSchedule" />
          </Button>
        ]}
      />

      {schedule || isPending || hasErrors(error) ? (
        <div className="mt3 mb3">
          {schedule ? <Text id="LoanSchedulePreview.Table.title" /> : null}
          <Loadable data={schedule} error={error} isPending={isPending}>
            {scheduleData => (
              <ScrollIntoFocus>
                <ScheduleTable schedule={scheduleData} initiallyOpen />
              </ScrollIntoFocus>
            )}
          </Loadable>
        </div>
      ) : null}
    </StackChild>
  );
};

Preview.validate = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom: (target, allData) => {
      const {
        principalMax: maximum,
        principalMin: minimum
      } = getMinMaxLoanProductAmount(allData);

      const amount = Number(target);

      if (minimum != null && amount < minimum) {
        return 'rangeUnderflow';
      }

      if (maximum != null && amount > maximum) {
        return 'rangeOverflow';
      }

      return null;
    }
  },
  ...LoanTermsFields.validate
};
