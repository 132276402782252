// @flow

import { attr } from 'spraypaint';
import axios from 'axios';

import Base, { type BaseModel } from './Base';

export const UserAppRole = Base.extend({
  static: {
    jsonapiType: 'user_app_roles',
    endpoint: '/auth/user_app_roles',
    remove(userId, appRoleId) {
      const data = {
        data: {
          attributes: {
            user_id: userId,
            app_role_id: appRoleId
          }
        }
      };
      return axios({
        method: 'DELETE',
        url: UserAppRole.url(),
        data,
        headers: { ...UserAppRole.fetchOptions().headers }
      });
    },
    add(userId, appRoleId) {
      const data = {
        data: {
          attributes: {
            user_id: userId,
            app_role_id: appRoleId
          }
        }
      };
      return axios({
        method: 'POST',
        url: UserAppRole.url(),
        data,
        headers: { ...UserAppRole.fetchOptions().headers }
      });
    }
  },

  attrs: {
    user_id: attr(),
    role_id: attr()
  }
});

export interface AppRoleT extends BaseModel<AppRoleT> {
  user_id: string;
  role_id: string;
}
