// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import PageLayout from '@kwara/components/src/PageLayout';

import { useBatchTransactionImport } from '../../models/request';
import { Overview } from './components/Overview';
import { BatchTransactionsForm } from './components/BatchTransactionsForm';

type Props = {
  batchId: string
};

export const ImportDetails = ({ batchId }: Props) => {
  const r = useBatchTransactionImport(batchId);

  return (
    <Loadable {...r}>
      {batchImport => (
        <PageLayout
          className="pt5"
          overview={<Overview batchImport={batchImport} />}
        >
          <BatchTransactionsForm batchImport={batchImport} {...r} />
        </PageLayout>
      )}
    </Loadable>
  );
};
