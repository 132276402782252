//@flow
import * as React from 'react';
import pick from 'lodash/fp/pick';

import {
  Loan,
  Member as MemberModel,
  type MemberType,
  type LoanType,
  type CollateralT,
  type PeriodUnitsT,
  type LoanProductType
} from '@kwara/models/src';
import {
  DeprecatedLoadable,
  LoadingFullScreen
} from '@kwara/components/src/Loadable';
import { saveAttachments } from '@kwara/models/src/models/Attachment';
import { type TransactionChannelT } from '@kwara/models/src/models/Transactions';

import { loanPath } from '../../lib/urls';
import { steps } from './config';
import Wizard, {
  getSubstepFromMatchParams,
  type SubStepComponentProps
} from '../../components/Wizard';
import { type WizardPageProps } from '..';

type Props = WizardPageProps;

export type GuaranteeType = {
  member: MemberType,
  amount: ?string // TODO: enforce numbers
};

export type LoanFormData = {
  guarantors: GuaranteeType[],
  collaterals: CollateralT[],
  member: MemberType,
  product: LoanProductType,
  amount: string,
  loanDuration: string,
  repaymentPeriod: string,
  repaymentPeriodUnit: PeriodUnitsT,
  purpose: string,
  specification: string,
  repaymentDetails: {
    directDebitBankBranch?: string,
    directDebitBank?: string,
    directDebitBankAccount?: string,
    repaymentMode: TransactionChannelT
  },
  loans: LoanType[],
  payOffLoans?: string[]
};
export type LoanSubstepProps = SubStepComponentProps<LoanFormData>;

const createLoan = async (data: LoanFormData): Promise<?boolean> => {
  const {
    amount,
    loanDuration,
    repaymentPeriod,
    repaymentPeriodUnit,
    guarantors,
    payOffLoans
  } = data;

  const loan = new Loan({
    accountHolderId: data.member.id,
    productId: data.product.id,
    amount,
    loanDuration,
    repaymentPeriod,
    repaymentPeriodUnit,
    guarantors,
    payOffLoans,
    ...pick(
      [
        'purpose',
        'specification',
        'disbursementMode',
        'disbursementBankDetails',
        'repaymentDetails',
        'collaterals'
      ],
      data
    )
  });
  const success = await loan.save({
    with: ['collaterals', 'guarantors']
  });

  if (!success) {
    throw loan.errors;
  }

  const { member } = data;

  try {
    const memberSuccess = await member.save();
    if (!memberSuccess) {
      // disabled for now: see ch7429
      // once done remove the try/catch and reinstate the error
      //throw member.errors;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error saving member, see ch7429');
  }

  const uploaded = await saveAttachments(member, member.attachments);
  if (!uploaded) {
    throw member.errors;
  }
};

function getInitialData(member: ?MemberType) {
  const base = {
    repaymentPeriodUnit: 'MONTHS',
    repaymentPeriod: 1,
    collaterals: []
  };

  if (member) {
    return {
      ...base,
      member,
      // unfortunately we need this in order to make translation work, see ch6556
      fullName: member.fullName(),
      ...pick(['firstName', 'standing'], member)
    };
  }

  return base;
}

export default class extends React.Component<Props, *> {
  constructor(props: Props) {
    super(props);

    const params = new URLSearchParams(props.location.search);

    const memberId = params.get('memberId');
    let memberPromise;

    if (typeof memberId === 'string') {
      memberPromise = MemberModel.full()
        .find(memberId)
        .then(response => response.data);
    } else {
      memberPromise = Promise.resolve(null);
    }

    this.state = {
      memberPromise
    };
  }

  renderWizard = (member: ?MemberType) => {
    const { baseUrl, match, history } = this.props;

    return (
      <Wizard
        analyticsId="LoanAdd"
        baseUrl={baseUrl}
        history={history}
        cancelReturnsTo={loanPath()}
        currentStep={match.params.step}
        currentSubStep={getSubstepFromMatchParams(match.params)}
        initialData={getInitialData(member)}
        onSubmit={createLoan}
        steps={steps(this.state)}
        startId="member"
        titleId="LoanAdd.title"
      />
    );
  };

  render() {
    return (
      <DeprecatedLoadable
        Loading={LoadingFullScreen}
        loader={this.state.memberPromise}
        loaded={this.renderWizard}
      />
    );
  }
}
