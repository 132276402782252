// @flow

import * as React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import getOr from 'lodash/fp/getOr';
import pipe from 'lodash/fp/pipe';
import toLower from 'lodash/fp/toLower';
import trim from 'lodash/fp/trim';
import map from 'lodash/fp/map';

import Auth from '@kwara/models/src/lib/Auth';
import Button from '@kwara/components/src/Button';
import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import Banner from '@kwara/components/src/Banner';
import { appName } from '@kwara/lib/src/utils';

import { Field, TextField, PasswordField } from '../Form';

import styles from './index.module.scss';
import logotype from './logotype.svg';

export { Unlock } from './components/Unlock';

const processEmail = pipe(
  trim,
  toLower
);

type Props = {
  auth: Auth
};

export default class LogIn extends React.Component<
  Props,
  {
    error: ?string,
    email: string,
    password: string,
    loading: boolean,
    unlockSent: boolean
  }
> {
  state = {
    error: null,
    email: '',
    password: '',
    loading: false,
    unlockSent: false
  };

  handleEmailChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const email = get(event, 'target.value', '');
    this.setState({ email });
  };

  handlePasswordChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const password = get(event, 'target.value', '');
    this.setState({ password });
  };

  logIn = async (evt: SyntheticEvent<HTMLFormElement>) => {
    this.setState({ loading: true });
    const { auth } = this.props;
    evt.preventDefault();
    const { email, password } = this.state;
    try {
      await auth.logIn({
        email: processEmail(email),
        password
      });

      window.location.reload();
    } catch (error) {
      this.setState({
        error,
        loading: false
      });
    }
  };

  resendUnlockLink = () => {
    const { auth } = this.props;
    const { email } = this.state;
    auth
      .resendUnlockLink(email)
      .then(_ => {
        this.setState({
          error: null,
          unlockSent: true
        });
        setTimeout(() => {
          this.setState({
            unlockSent: false
          });
        }, 4000);
      })
      .catch(error => {
        this.setState({
          error
        });
      });
  };

  render() {
    const { email, password, loading, error } = this.state;
    const isMember = appName.isMember;

    return (
      <div
        className={classnames(styles.Container, 'fl w-100 bg-white', {
          [styles.Container__Member]: isMember
        })}
      >
        <div className={classnames(styles.Image, 'h-100')}>
          <img alt="Kwara" className={styles.Logotype} src={logotype} />
        </div>
        <div
          className={classnames(styles.FormWrap, 'fl h-100 flex items-center')}
        >
          <div className={classnames(styles.Form)}>
            <h1 className={'kw-text-super-large kw-weight-bold'}>
              <Text id="LogIn.title" />
            </h1>
            <Banner
              state="error"
              className="mb3"
              text={
                <>
                  {map(
                    err => (
                      <React.Fragment key={err.code}>
                        <Text
                          id={`Errors.${err.code}`}
                          values={{
                            br: <br />,
                            clickHere: (
                              <Link onClick={this.resendUnlockLink}>
                                <Text id="General.clickHere" />
                              </Link>
                            )
                          }}
                        />
                      </React.Fragment>
                    ),
                    getOr([], 'errors', error)
                  )}
                </>
              }
              hidden={!error}
            />
            <Banner
              hidden={!this.state.unlockSent}
              className="mb3"
              text={<Text id="LogIn.unlockSent" />}
            />
            <form className="mb3" onSubmit={this.logIn}>
              <Field name="email" labelId="LogIn.email" size="medium">
                <TextField
                  autoFocus
                  required
                  name="email"
                  autoComplete="email"
                  size="regular"
                  value={email}
                  onChange={this.handleEmailChange}
                />
              </Field>
              <Field name="password" labelId="LogIn.password" size="medium">
                <PasswordField
                  name="password"
                  size="regular"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={this.handlePasswordChange}
                />
              </Field>
              <Button
                isSubmit
                type="primary"
                size="medium"
                className="fr"
                glyphRightId={loading ? Button.Glyphs.Spinner : null}
                disabled={loading}
              >
                <Text id="LogIn.login" />
              </Button>
              {/*Disabled until Signup fully configured with onboarding Wizard:
              https://kwara.slack.com/archives/C8EPTLL7K/p1573042638016500
              {isMember ? null : (
                <Button
                  to="/signup"
                  type="secondary"
                  size="medium"
                  className="fr mr3"
                >
                  <Text id="LogIn.signup" />
                </Button>
              )} */}
              <div className="cf" />
            </form>
            <Link className="fr" to="/password/forgot">
              <Text id="Password.Forget.title" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
