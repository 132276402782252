//@flow
import * as React from 'react';

import { type TillT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { Currency } from '../../../components/Currency';
import { DetailOverviewSection } from '../../../components/Detail/DetailOverviewSection';
import { OverviewMetadata } from './OverviewMetadata';

export type Props = {
  actions: React.Node,
  navigation: React.Node,
  showBack: boolean,
  till: TillT
};

export const Overview = (props: Props) => {
  const { till, navigation } = props;

  console.log('TILL', till);

  return (
    <DetailOverviewSection
      metadata={<OverviewMetadata till={till} {...props} />}
      secondary={navigation}
      title={
        <Text id="TillDetail.title" values={{ tellerName: till.tellerName }} />
      }
    >
      <section className="mb5">
        <StatisticRow widthClass="w-50">
          <Statistic
            title={<Text id="TillDetail.Overview.currentBalance" />}
            value={<Currency value={till.currentAmount} />}
            size={'huge'}
          />
          <Statistic
            title={<Text id="Closing balance" />}
            value={<Currency value={till.closingAmount} />}
            size={'huge'}
          />
        </StatisticRow>
        <StatisticRow border widthClass="w-50">
          <Statistic
            title={<Text id="TillDetail.Overview.deposits" />}
            value={<Currency value={till.deposits} />}
            size={'huge'}
          />
          <Statistic
            title={<Text id="TillDetail.Overview.withdrawals" />}
            value={<Currency value={till.withdrawals} />}
            size={'huge'}
          />
        </StatisticRow>
      </section>
    </DetailOverviewSection>
  );
};
