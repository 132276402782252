// @flow
import includes from 'lodash/fp/includes';
import some from 'lodash/fp/some';

import { parse } from '../dates';

// It should pass all truthy values and 0
export const required = function(record: ?mixed) {
  return record ? true : record === 0;
};

export const pattern = function(validationValue: RegExp, record: string) {
  return validationValue instanceof RegExp && validationValue.test(record);
};

// See: https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation
const EMAIL_MATCHER = /^[\w.!#$%&'*+-/=?`{}|~]+@([\w-]+\.)+(\w+)$/;

// See: https://stackoverflow.com/questions/9052441/regular-expression-to-match-only-letters-numbers-and-spaces
const LETTERS_NUMBERS_SPACES = /^[\w ]+$/;

export const email = function(record: string) {
  return EMAIL_MATCHER.test(record);
};

export const noSpecialCharacters = function(record: string) {
  return LETTERS_NUMBERS_SPACES.test(record);
};

export const minlength = function(
  validationValue: number,
  record: string = ''
) {
  return record != null && record.length >= validationValue;
};

export const isSpecialCharacter = (char: string) => {
  const special = [
    '~',
    '!',
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    '_',
    '-',
    '+',
    '='
  ];
  return includes(char, special);
};

export const hasSpecialCharacter = (word: string) => {
  return some(c => isSpecialCharacter(c), word);
};

export const isLower = (char: string) => {
  return char.toLowerCase() === char && char !== char.toUpperCase();
};

export const hasLowerCase = (word: string) => {
  return some(c => isLower(c), word);
};

export const isUpper = (char: string) => {
  return char.toUpperCase() === char && char !== char.toLowerCase();
};

export const hasUpperCase = (word: string) => {
  return some(c => isUpper(c), word);
};

export const maxlength = function(validationValue: number, record: string) {
  return record != null && record.length <= validationValue;
};

export const containsComma = (record: string | number) => includes(',', record);
export const containsPlusSign = (record: string | number) =>
  includes('+', record);

export const date = function(record: string) {
  const [date, month, year] = record.replace(/[./]/g, '-').split('-');
  const maybeDate = `${year}-${month}-${date}`;
  return record != null && parse(maybeDate) != null;
};

export const containsNumbers = (record: string) => /[0-9]+/.test(record);
export const containsLetters = (record: string) => /[a-zA-Z]+/.test(record);

export const containsAlphaNum = (record: string) =>
  containsLetters(record) && containsNumbers(record);
