//@flow
import every from 'lodash/fp/every';

import auth from '../../lib/auth';

import { ApiPermissions, type ApiPermission } from './ApiPermissions';
import { KwaraPermissions, type KwaraPermission } from './KwaraPermissions';

export const AppPermissions = {
  // Comments
  AddComments: [ApiPermissions.CREATE_COMMENTS],

  // Members
  AddMembers: [ApiPermissions.CREATE_COMMENTS, ApiPermissions.CREATE_CLIENT],
  ApproveMembers: [
    ApiPermissions.APPROVE_CLIENT,
    ApiPermissions.CREATE_COMMENTS
  ],
  RejectMembers: [ApiPermissions.REJECT_CLIENT, ApiPermissions.CREATE_COMMENTS],
  ExitMembers: [ApiPermissions.EXIT_CLIENT, ApiPermissions.CREATE_COMMENTS],

  // Savings
  AddSavings: [ApiPermissions.CREATE_SAVINGS_ACCOUNT],
  AddDeposits: [
    ApiPermissions.MAKE_DEPOSIT,
    ApiPermissions.LOG_JOURNAL_ENTRIES
  ],
  AddWithdrawal: [
    ApiPermissions.MAKE_WITHDRAWAL,
    ApiPermissions.LOG_JOURNAL_ENTRIES,
    ApiPermissions.APPLY_SAVINGS_FEES
  ],
  CloseSavings: [
    ApiPermissions.CLOSE_SAVINGS_ACCOUNTS,
    ApiPermissions.CREATE_COMMENTS
  ],

  // Loans
  ViewLoan: [],
  AddLoans: [
    ApiPermissions.CREATE_LOAN_ACCOUNT,
    ApiPermissions.CREATE_COMMENTS
  ],
  ApproveLoans: [ApiPermissions.CREATE_COMMENTS, ApiPermissions.APPROVE_LOANS],
  RejectLoans: [ApiPermissions.CREATE_COMMENTS, ApiPermissions.REJECT_LOANS],
  DisburseLoans: [
    ApiPermissions.APPLY_LOAN_FEES,
    ApiPermissions.DIBURSE_LOANS,
    ApiPermissions.LOG_JOURNAL_ENTRIES,
    ApiPermissions.SET_DISBURSEMENT_CONDITIONS,
    ApiPermissions.MAKE_DEPOSIT,
    ApiPermissions.MAKE_TRANSFER
  ],
  DisburseRefinancedLoan: [
    ApiPermissions.APPLY_LOAN_FEES,
    ApiPermissions.DIBURSE_LOANS,
    ApiPermissions.LOG_JOURNAL_ENTRIES,
    ApiPermissions.SET_DISBURSEMENT_CONDITIONS,
    ApiPermissions.MAKE_DEPOSIT,
    ApiPermissions.MAKE_TRANSFER,
    ApiPermissions.MAKE_WITHDRAWAL,
    ApiPermissions.CLOSE_LOAN_ACCOUNTS,
    ApiPermissions.ENTER_REPAYMENT,
    ApiPermissions.APPLY_LOAN_ADJUSTMENTS,
    ApiPermissions.PAY_OFF_LOAN
  ],
  AddPenalties: [ApiPermissions.EDIT_PENALTY_RATE],
  AddRepayments: [
    ApiPermissions.ENTER_REPAYMENT,
    ApiPermissions.LOG_JOURNAL_ENTRIES
  ],
  PayOffLoan: [
    ApiPermissions.PAY_OFF_LOAN,
    ApiPermissions.MAKE_WITHDRAWAL,
    ApiPermissions.LOG_JOURNAL_ENTRIES
  ],
  RescheduleLoans: [
    ApiPermissions.RESCHEDULE_LOAN_ACCOUNT,
    ApiPermissions.CREATE_COMMENTS
  ],
  RefinanceLoans: [
    ApiPermissions.REFINANCE_LOAN_ACCOUNT,
    ApiPermissions.CREATE_COMMENTS,
    ApiPermissions.VIEW_LOAN_PRODUCT_DETAILS,
    ApiPermissions.CREATE_LOAN_ACCOUNT,
    ApiPermissions.APPROVE_LOANS
  ],
  WriteOffLoans: [
    ApiPermissions.WRITE_OFF_LOAN_ACCOUNTS,
    ApiPermissions.CREATE_COMMENTS
  ],

  // Batch Upload
  BatchUpload: [
    ApiPermissions.ENTER_REPAYMENT,
    ApiPermissions.MAKE_DEPOSIT,
    ApiPermissions.MAKE_WITHDRAWAL
  ],

  // Submit to CRB
  CreditSubmit: [
    ApiPermissions.ENTER_REPAYMENT,
    ApiPermissions.MAKE_DEPOSIT,
    ApiPermissions.MAKE_WITHDRAWAL
  ],

  // Make transfer
  MakeTransfer: [
    ApiPermissions.MAKE_INTER_CLIENTS_TRANSFERS,
    ApiPermissions.MAKE_TRANSFER
  ],

  ViewOrgActivityFeed: [KwaraPermissions.VIEW_SACCO_EVENTS],

  // Batch Transaction Imports
  ViewBatchTransactionImports: [
    KwaraPermissions.VIEW_BATCH_TRANSACTION_IMPORTS
  ],
  CreateBatchTransactionImports: [
    KwaraPermissions.VIEW_BATCH_TRANSACTION_IMPORTS,
    KwaraPermissions.CREATE_BATCH_TRANSACTION_IMPORTS
  ],
  ReviewBatchTransactionImports: [
    KwaraPermissions.VIEW_BATCH_TRANSACTION_IMPORTS,
    KwaraPermissions.REVIEW_BATCH_TRANSACTION_IMPORTS
  ],

  // Invitations
  ViewInvitations: [KwaraPermissions.VIEW_INVITATIONS],
  InviteUser: [
    KwaraPermissions.VIEW_INVITATIONS,
    KwaraPermissions.CREATE_INVITATIONS
  ],

  // Journal Entries
  ViewJournalEntries: [KwaraPermissions.VIEW_JOURNAL_ENTRIES],
  CreateJournalEntries: [
    KwaraPermissions.VIEW_JOURNAL_ENTRIES,
    KwaraPermissions.CREATE_JOURNAL_ENTRIES
  ],

  // View Till Transactions
  ViewTillTransactions: [KwaraPermissions.VIEW_TILL_TRANSACTIONS],

  // View Tills
  ViewTills: [KwaraPermissions.VIEW_TILLS],

  // Use Till
  UseTill: [
    KwaraPermissions.OPEN_TILL,
    KwaraPermissions.CLOSE_TILL,
    KwaraPermissions.VIEW_TILL_TRANSACTIONS
  ],

  // Topup Requests
  CreateTopupRequests: [KwaraPermissions.CREATE_TOPUP_REQUESTS],

  // Utility Transactions
  CreateUtilityTransactions: [
    ApiPermissions.LOG_JOURNAL_ENTRIES,
    KwaraPermissions.CREATE_UTILITY_TRANSACTIONS
  ],

  // Topup Review
  ReviewTopupRequest: [
    KwaraPermissions.VIEW_ALL_TOPUP_REQUESTS,
    KwaraPermissions.APPROVE_TOPUP_REQUESTS,
    KwaraPermissions.REJECT_TOPUP_REQUESTS
  ],

  // Teller Supervisor
  UseTellerSupervisorPage: [
    KwaraPermissions.VIEW_ALL_TILLS,
    KwaraPermissions.VIEW_ALL_TOPUP_REQUESTS,
    KwaraPermissions.APPROVE_TOPUP_REQUESTS,
    KwaraPermissions.REJECT_TOPUP_REQUESTS,
    KwaraPermissions.VIEW_TILL_TRANSACTIONS,
    KwaraPermissions.VIEW_SUPERVISOR_METRICS
  ],

  // Maker checker on Pending Transactions
  UseMakerCheckerPage: [
    KwaraPermissions.VIEW_SAVINGS_TRANSACTIONS,
    KwaraPermissions.APPROVE_SAVINGS_TRANSACTIONS,
    KwaraPermissions.REJECT_SAVINGS_TRANSACTIONS,
    KwaraPermissions.VIEW_LOAN_TRANSACTIONS,
    KwaraPermissions.APPROVE_LOAN_TRANSACTIONS,
    KwaraPermissions.REJECT_LOAN_TRANSACTIONS
  ],

  SafaricomVerify: [KwaraPermissions.SAFARICOM_IMSI_VERIFY_PHONE]
};
export type AppPermission = $Keys<typeof AppPermissions>;
export type AppPermissionValue = $Values<typeof AppPermissions>;

type GetPermissions = () => (ApiPermission | KwaraPermission)[];

export class Permission {
  static isValid(permission: ApiPermission | KwaraPermission) {
    return Object.keys({ ...ApiPermissions, ...KwaraPermissions }).includes(
      permission
    );
  }

  _getPermissions: GetPermissions;

  constructor(getPermissions: GetPermissions) {
    this._getPermissions = getPermissions;
  }

  to = (
    param: ApiPermission | KwaraPermission | (ApiPermission | KwaraPermission)[]
  ) => {
    const permissions = Array.isArray(param) ? param : [param];

    permissions.forEach(p => {
      if (!Permission.isValid(p)) {
        throw new Error(`Invalid permission "${p}"`);
      }
    });

    const userPermissions = this._getPermissions();

    if (!Array.isArray(userPermissions)) {
      throw new Error(`Couldn't fetch permissions`);
    }

    return every(p => userPermissions.includes(p), permissions);
  };
}

export default new Permission(auth.getPermissions);
