//@flow

import * as React from 'react';

import StatisticRow from '@kwara/components/src/StatisticRow';
import { Text } from '@kwara/components/src/Intl';
import { Metric, type MetricType } from '@kwara/models/src';

import Dashboard from '../../../../components/Dashboard';
import DashboardMetric from '../../../../components/DashboardMetric';

import { store } from '../../../../models/Store';

type Props = {
  isTillOpen: boolean,
  actions: React.Node[],
  metrics: MetricType[]
};

export class TillDashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { actions, metrics: data, isTillOpen } = this.props;

    const placeholderMetric = { value: { value: '-', type: '-' } };

    const metrics = isTillOpen ? data : [];

    return (
      <Dashboard actions={actions}>
        <StatisticRow childClasses="pa0 mr4" compact={true} widthClass="w-50">
          <DashboardMetric
            title={<Text id="Till.Dashboard.currentBalance" />}
            metric={
              Metric.findFromArray(metrics, 'current_balance') ||
              placeholderMetric
            }
            infoId={'Till.Dashboard.currentBalance.info'}
            values={{
              tillMax: store.tellerConfigs.tillMaximum,
              tillMin: store.tellerConfigs.tillMinimum
            }}
          />
          <DashboardMetric
            title={<Text id="Till.Dashboard.utilities" />}
            metric={
              Metric.findFromArray(metrics, 'utility_payments') ||
              placeholderMetric
            }
          />
        </StatisticRow>
        <StatisticRow childClasses="pa0 mr4" compact={true} widthClass="w-50">
          <DashboardMetric
            title={<Text id="Till.Dashboard.deposits" />}
            metric={
              Metric.findFromArray(metrics, 'cash_deposits') ||
              placeholderMetric
            }
          />
          <DashboardMetric
            title={<Text id="Till.Dashboard.withdrawals" />}
            metric={
              Metric.findFromArray(metrics, 'cash_withdrawals') ||
              placeholderMetric
            }
          />
        </StatisticRow>
      </Dashboard>
    );
  }
}
