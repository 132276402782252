// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import min from 'lodash/fp/min';

import { type SavingType, type LoanType } from '@kwara/models/src';
import StatusTag from '@kwara/components/src/StatusTag';
import { Currency } from '@kwara/components/src/Intl';

import { Grid } from '../../../components/Grid';

import {
  getReceivingAccount,
  type SubstepProps,
  linkedAccountType,
  LOAN
} from '..';

function getMaximumAmount(data) {
  const { saving, recipient, linkedAccountId } = data;

  const receivingAccount = getReceivingAccount(recipient, linkedAccountId);
  const isLoan = linkedAccountType(receivingAccount) === LOAN;

  if (isLoan) {
    return min([saving.balance, receivingAccount.totalBalance]);
  }

  return saving.balance;
}

const AccountPreview = ({ account }: { account: SavingType | LoanType }) => {
  const isLoan = get('resourceIdentifier.type', account) === 'loans';

  const amount = isLoan
    ? get('totalBalance', account)
    : get('balance', account);

  return (
    <div className="pb2 kw-text-small">
      <div className="nowrap tl pv2 grey-400">
        <StatusTag size="small" state={get('state.current', account)} />{' '}
        &middot; {account.id}
      </div>
      <div className="tl pb2 kw-text-medium">
        {get('product.name', account)}
      </div>
      <div className={isLoan ? 'red-500' : 'grey-400'}>
        <Currency format="currency" value={amount} />
      </div>
    </div>
  );
};

export const Amount = ({
  StackChild,
  TextArea,
  TextField,
  data
}: SubstepProps) => {
  const { linkedAccountId, recipient } = data;

  const receivingAccount = getReceivingAccount(recipient, linkedAccountId);

  return (
    <StackChild size="wide">
      <Grid border={false} columns={3} width="w-33">
        <AccountPreview border={false} account={receivingAccount} />
        <TextField
          labelId="MakeTransfer.Amount.amount.label"
          infoId="MakeTransfer.Amount.amount.info"
          values={{ val: getMaximumAmount(data) }}
          name="amount"
          required
        />
        <TextArea
          name="notes"
          labelId="MakeTransfer.Amount.notes.label"
          placeholderId="MakeTransfer.Amount.notes.placeholder"
        />
      </Grid>
    </StackChild>
  );
};

Amount.validate = {
  amount: {
    isRequired: () => true,
    currency: true,
    custom: (target, allData) => {
      const { saving, recipient, linkedAccountId } = allData;

      const receivingAccount = getReceivingAccount(recipient, linkedAccountId);
      const isLoan = linkedAccountType(receivingAccount) === LOAN;

      // From account must have enough money
      if (Number(target) > Number(saving.balance)) {
        return 'insufficientFunds';
      }

      // Disallow overpaying receiving loan account
      if (isLoan && Number(target) > Number(receivingAccount.totalBalance)) {
        return 'rangeOverflow';
      }

      return null;
    }
  }
};
