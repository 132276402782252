// @flow

import { attr, belongsTo, hasMany, hasOne } from 'spraypaint';
import some from 'lodash/fp/some';
import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import pipe from 'lodash/fp/pipe';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import isFinite from 'lodash/fp/isFinite';
import _result from 'lodash/fp/result';
import includes from 'lodash/fp/includes';
import axios from 'axios';

import { sumBy, add } from '@kwara/lib/src/currency';
import { Logger } from '@kwara/lib/src/logger';

import Schedule from '../Schedule';
import Base, { type BaseModel, type IncludesT } from '../Base';
import {
  snakeCaseObjectKeys,
  createCancellablePromise,
  SearchCancellation,
  Guarantor,
  type GuaranteeType,
  type CollateralT,
  type TransactionChannelT,
  type PeriodUnitsT
} from '../..';
import createModelErrors, {
  createErrorsFromApiResponse
} from '../createModelErrors';
import filterEmptyValues from '../../lib/filterEmptyValues';
import { LoanApplication, type LoanApplicationT } from '../LoanApplication';
import Member, { type MemberType } from '../Member';
import { toDurationObjectUI } from '../util';
import { type LoanProductType } from '../LoanProduct';

import { store } from '../../../../webapp-sacco/src/models/Store';

// These are the native Loan states Mambu accepts,
// thus the ones used to populate the state
//  filter on the Loans list page, see ch11336
export const LoanBaseStates = {
  ACTIVE: 'ACTIVE',
  ACTIVE_IN_ARREARS: 'ACTIVE_IN_ARREARS',
  APPROVED: 'APPROVED',
  CLOSED: 'CLOSED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PARTIAL_APPLICATION: 'PARTIAL_APPLICATION'
};

// These are the loan states used on the Kwara platform
export const LoanStates = {
  ...LoanBaseStates,
  CLOSED_REJECTED: 'CLOSED_REJECTED',
  CLOSED_WRITTEN_OFF: 'CLOSED_WRITTEN_OFF',
  CLOSED_RESCHEDULED: 'CLOSED_RESCHEDULED',
  CLOSED_WITHDRAWN: 'CLOSED_WITHDRAWN',
  CLOSED_REPAID: 'CLOSED_REPAID'
};

export const LoanEvents = Object.freeze({
  APPROVE: 'approve',
  DISBURSE: 'disburse',
  REJECT: 'reject',
  SOFT_REJECT: 'soft_reject',
  WRITE_OFF: 'write_off'
});

export type LoanBaseState = $Values<typeof LoanBaseStates>;
export type LoanState = $Values<typeof LoanStates>;
export type LoanEvent = $Values<typeof LoanEvents>;

type UiRepaymentFrequency = {
  loanDuration: number,
  period: number
};

type ApiRepaymentPeriodT = {
  unit: PeriodUnitsT,
  period: number,
  installments: number
};

export type RepaymentDetailsT = {
  direct_debit_bank?: string,
  direct_debit_bank_account?: string,
  direct_debit_branch?: string,
  direct_debit_collecting_bank?: string,
  repayment_amount?: string,
  repayment_collection_date?: string,
  repayment_collection_frequency?: string,
  repayment_fee_amount?: string,
  repayment_mode: string
};

export type DisbursementBankDetailsT = {
  bank?: string,
  bank_branch?: string,
  account_number?: string
};

type Note = {
  notes: ?string
};

type DisbursalInfo = {
  amount: number,
  date: Date,
  payment_method: TransactionChannelT,
  reference?: ?string,
  notes: ?string
};

export const calculateNumberInstallments = ({
  loanDuration,
  period
}: UiRepaymentFrequency): number => {
  return Math.floor(loanDuration / period);
};

export const fields = Object.freeze({
  repayment: {
    mode: 'repaymentDetails.repayment_mode',
    bank: 'repaymentDetails.direct_debit_bank',
    branch: 'repaymentDetails.direct_debit_branch',
    account: 'repaymentDetails.direct_debit_bank_account',
    collectingBank: 'repaymentDetails.direct_debit_collecting_bank',
    amount: 'repaymentDetails.repayment_amount',
    feeAmount: 'repaymentDetails.repayment_fee_amount',
    frequency: 'repaymentDetails.repayment_collection_frequency',
    collectionDate: 'repaymentDetails.repayment_collection_date',
    startDate: 'repaymentDetails.repayment_start_date'
  },
  disbursement: {
    mode: 'disbursementMode',
    bank: 'disbursementBankDetails.bank',
    branch: 'disbursementBankDetails.bank_branch',
    account: 'disbursementBankDetails.account_number'
  }
});

const defaultLoanFetchIncludes = [
  'member',
  'product',
  'guarantees.member',
  'collaterals',
  'loan_application'
];
export const LoanFetch = (
  id: string,
  includes: IncludesT = defaultLoanFetchIncludes
) => {
  return Loan.includes(includes)
    .find(id)
    .then(response => response.data.deserialize())
    .then(res => {
      // This is a "temporary" (I hope) solution to display fields such as staff ID and other
      // employment related stuff that is not part of the loan>member model.
      // see ch7843 for related tech debt ticket and full info
      return Member.includes([
        'attachments',
        'id_documents',
        'savings.product',
        'loans.product'
      ])
        .find(res.member.id)
        .then(r => r.data)
        .then(member => {
          res.member = member;
          return res;
        });
    });
};

const Loan: LoanType = Base.extend({
  static: {
    jsonapiType: 'loans',

    reschedule(id: string) {
      return Loan.extend({
        static: {
          endpoint: `/loans/${id}/reschedule`
        },
        attrs: {
          restructureDetails: attr()
        }
      });
    },

    refinance(id: string) {
      return Loan.extend({
        static: {
          endpoint: `/loans/${id}/refinance`
        },
        attrs: {}
      });
    },

    search({ term = '' }: { term: string }) {
      const cancelSource = axios.CancelToken.source();

      if (term === '') {
        const promise = Loan.where({ state: 'ACTIVE_IN_ARREARS' })
          .includes(['member', 'product'])
          .all()
          .then(r => r.data);

        return createCancellablePromise(promise, cancelSource);
      }

      const url = `${Loan.fullBasePath()}/search?q=${escape(
        term
      )}&filter[state]=ACTIVE_IN_ARREARS&type=loan`;

      //TO DO: Activate search url below once endpoint is created

      // const urlWithArrearsFilter = `${Loan.fullBasePath()}/search?q=${escape(
      //   term
      // )}&filter[state]=ACTIVE_IN_ARREARS&filter[days_in_arrears]=${daysInArrears}type=loan`;

      const options = {
        ...Loan.fetchOptions(),
        cancelToken: cancelSource.token
      };

      const promise = axios(url, options).then(
        response => {
          if (response.status > 299) {
            throw new Error(`Response not OK`);
          }

          const jsonResult = response.data;

          return Promise.all(
            jsonResult.data.map(record => {
              return Loan.includes(['member', 'product'])
                .find(record.id)
                .then(r => r.data);
            })
          );
        },
        err => {
          if (axios.isCancel(err)) {
            throw new SearchCancellation();
          }

          throw err;
        }
      );

      return createCancellablePromise(promise, cancelSource);
    }
  },
  attrs: {
    amount: attr(),
    interestRate: attr(),
    interest: attr(),
    principal: attr(),

    name: attr(),

    firstRepaymentDate: attr(),

    // Read Only Fields
    fees: attr(),
    penalties: attr(),
    state: attr(),
    // Represents the duration of the loan in
    // string format i.e. P10M
    duration: attr(),
    purpose: attr(),
    specification: attr(),
    repaymentFrequency: attr(),

    totalBalance: attr(),
    totalDue: attr(),
    totalPaid: attr(),
    accruedInterest: attr(),

    willPayOffOtherLoans: attr(),
    repaymentDistribution: attr(),

    disbursementDate: attr(),
    firstPaymentDate: attr(),

    // UI Field representing the numerical value of the loan duration
    loanDuration: attr({ persist: false }),

    member: belongsTo(),
    product: belongsTo('loan_products'),
    collaterals: hasMany(),
    transactions: hasMany(),

    loanApplication: hasOne({ type: LoanApplication }),

    // Create
    accountHolderId: attr(),
    productId: attr(),
    repaymentInstallments: attr(),
    repaymentPeriod: attr(),
    repaymentPeriodUnit: attr(),
    payOffLoans: attr(),

    // write
    guarantors: hasMany(),
    // read
    guarantees: hasMany(),

    disbursementMode: attr(),
    disbursementBankDetails: attr(),
    repaymentDetails: attr()
  },
  methods: {
    deserialize() {
      // This field is stored as a string in Mambu, but needs
      // to be a date for the DatePicker to understand it
      const startDate = get(fields.repayment.startDate, this);
      if (startDate) {
        this.repaymentDetails.repayment_start_date = new Date(startDate);
      }

      // The order of these assignments is important! We need access to
      // the repaymentPeriod object the api returns to define
      // repaymentPeriodUnit before we then reassign repaymentPeriod
      // to fit the shape the UI is expecting.
      this.repaymentPeriodUnit = get('repaymentPeriod.unit', this);
      this.repaymentPeriod = get('repaymentPeriod.count', this);
      this.loanDuration = get('value', toDurationObjectUI(this.duration));

      // wtf ch8924
      this.guarantors = map(g => {
        const i = new Guarantor(g);
        i.isPersisted = true;
        return i;
      }, this.guarantees);
      return this;
    },
    hasNoSecurities() {
      return isEmpty(this.collaterals) && isEmpty(this.guarantors);
    },
    sumOfGuarantees() {
      return sumBy('amount', this.guarantees);
    },
    sumOfCollaterals() {
      return sumBy('amount', this.collaterals);
    },
    sumOfSecurities() {
      return Number(add(this.sumOfGuarantees(), this.sumOfCollaterals()));
    },
    isPaidOff() {
      return Number(this.totalBalance) <= 0;
    },
    isBridgingProduct() {
      return store.isBridgingLoanProduct(get('product.id', this));
    },
    interestRatePercent() {
      return isFinite(get('interestRate.percentage', this))
        ? this.interestRate.percentage / 100
        : null;
    },
    canDisburse() {
      return (
        this.isEventPermitted(LoanEvents.DISBURSE) && !this.isBridgingProduct()
      );
    },
    canWriteOff() {
      return (
        this.isEventPermitted(LoanEvents.WRITE_OFF) && !this.isBridgingProduct()
      );
    },
    isUnreschedulableLoan() {
      // Hack Alert: Loans with frequency and duration equal
      // to 1 day are not able to be rescheduled
      // Link on Slack:
      // https://kwara.slack.com/archives/C8EPTLL7K/p1582889413012000
      return this.repaymentFrequency === 'P1D' && this.duration === 'P1D';
    },
    canReschedule() {
      return (
        [LoanStates.ACTIVE, LoanStates.ACTIVE_IN_ARREARS].includes(
          this.state.current
        ) &&
        !this.isUnreschedulableLoan() &&
        !this.isBridgingProduct()
      );
    },
    canRefinance() {
      return (
        [LoanStates.ACTIVE, LoanStates.ACTIVE_IN_ARREARS].includes(
          this.state.current
        ) &&
        !this.isBridgingProduct() &&
        store.refinancingIsLive
      );
    },
    isRefinancing() {
      return !!_result('loanApplication.isRefinancing', this);
    },
    canMakeRepayment() {
      return (
        // if the loan has been paid off we disallow adding repayments [ch7874]
        this.isApproved() && !this.isPaidOff() && !this.isBridgingProduct()
      );
    },
    isApproved() {
      return [LoanStates.ACTIVE, LoanStates.ACTIVE_IN_ARREARS].includes(
        this.state.current
      );
    },
    isPendingApproval() {
      return this.state.current === LoanStates.PENDING_APPROVAL;
    },
    isClosed() {
      return includes('CLOSED', this.state.current);
    },
    canAddFee() {
      return (
        this.principal.balance > 0 &&
        (this.state.current === LoanStates.ACTIVE ||
          this.state.current === LoanStates.ACTIVE_IN_ARREARS) &&
        !this.isBridgingProduct()
      );
    },
    isEventPermitted(event: LoanEvent) {
      return some({ name: event }, this.state.permitted_events);
    },
    async getOutstandingBalance() {
      try {
        const { data = {} } = await Schedule.find(this.id);
        // Consider the 1st non-paid repayment as the one determining the outstanding amount [ch2984]
        // https://app.clubhouse.io/getkwara/story/2984/display-member-loan-repayment-amount-field-in-the-input-placeholder
        const repayment = find(o => o.state !== 'PAID', data.repayments);
        return repayment ? repayment.outstanding() : 0;
      } catch (e) {
        Logger.error(
          'Error calculating outstanding balance',
          JSON.stringify(e)
        );
      }
    },
    async approve({ notes }: { notes: ?string }): Promise<boolean> {
      if (this.isEventPermitted(LoanEvents.APPROVE)) {
        return await this.transition(LoanEvents.APPROVE, { notes });
      }

      this.errors = createModelErrors({
        base: 'APP_LOAN_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async reject({ comment }: { comment: ?string } = {}): Promise<boolean> {
      if (this.isEventPermitted(LoanEvents.REJECT)) {
        return await this.transition(LoanEvents.REJECT, { comment });
      }

      this.errors = createModelErrors({
        base: 'APP_LOAN_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async softReject({ comment }: { comment: ?string } = {}): Promise<boolean> {
      if (this.isEventPermitted(LoanEvents.SOFT_REJECT)) {
        return await this.transition(LoanEvents.SOFT_REJECT, { comment });
      }

      this.errors = createModelErrors({
        base: 'APP_LOAN_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async disburse(info: DisbursalInfo): Promise<boolean> {
      if (this.isEventPermitted(LoanEvents.DISBURSE)) {
        return await this.transition(LoanEvents.DISBURSE, info);
      }

      this.errors = createModelErrors({
        base: 'APP_DISBURSED'
      });

      return false;
    },
    async writeOff(notes: ?Note) {
      if (this.isEventPermitted(LoanEvents.WRITE_OFF)) {
        return await this.transition(LoanEvents.WRITE_OFF, notes);
      }

      this.errors = createModelErrors({
        base: 'UI_APP_LOAN_WRITE_OFF_INVALID_STATE'
      });

      return false;
    },
    async payOff(params) {
      const url = `${Loan.url(this.id)}/payoff`;
      const attributes = pipe(
        filterEmptyValues,
        snakeCaseObjectKeys
      )(params);

      const options = {
        ...Loan.fetchOptions(),
        method: 'POST',
        body: JSON.stringify({ data: { attributes } })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();
          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (errors) {
        Logger.error('Error paying off loan account', JSON.stringify(errors));
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    },
    async transition(event: LoanEvent, params: ?Note | ?DisbursalInfo) {
      const url = `${Loan.url(this.id)}/state`;
      const attributes = pipe(
        filterEmptyValues,
        snakeCaseObjectKeys
      )({
        event,
        ...params
      });

      const options = {
        ...Loan.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({ data: { attributes } })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();
          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (errors) {
        Logger.error('Error transitioning loan state', JSON.stringify(errors));
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    }
  }
});

Loan.prototype._originalLoanSave = Loan.prototype.save;

Loan.prototype.save = async function(...args) {
  const repaymentInstallments = calculateNumberInstallments({
    loanDuration: Number(this.loanDuration),
    period: Number(this.repaymentPeriod)
  });

  // The 2 fields for which we must force the change
  this.repaymentPeriod = Number(this.repaymentPeriod);
  this.repaymentInstallments = repaymentInstallments;

  try {
    return await this._originalLoanSave(...args);
  } catch (saveError) {
    try {
      const body = await saveError.response.clone().json();
      this.errors = createErrorsFromApiResponse(body);
    } catch (parseError) {
      throw saveError;
    }
  }
};

export type TransactionT = {
  account: string
};

export type LoanId = string;

type PenaltiesT = {
  balance: number,
  due: number,
  paid: number
};

type FeesT = {
  balance: number,
  due: number,
  paid: number
};

type InterestT = {
  balance: number,
  due: number,
  paid: number
};

type PrincipalT = {
  balance: number,
  due: number,
  paid: number
};

export interface LoanType extends BaseModel<LoanType> {
  id: string;
  product: LoanProductType;
  totalBalance: number;
  accruedInterest: number;
  amount: number;
  duration: string;
  loanDuration: string;
  // guarantees: GuaranteeType[];
  guarantors: GuaranteeType[];
  loanApplication: LoanApplicationT;
  interest: InterestT;
  fees: FeesT;
  penalties: PenaltiesT;
  principal: PrincipalT;
  repaymentPeriod: number | ApiRepaymentPeriodT;
  repaymentPeriodUnit: PeriodUnitsT;
  repaymentFrequency: string;
  collaterals: CollateralT[];
  payOffLoans?: string[];
  state: {
    current: LoanState
  };
  repaymentDetails: RepaymentDetailsT;
  disbursementMode: string;
  disbursementBankDetails: DisbursementBankDetailsT;
  transactions: TransactionT[];
  purpose: string;
  specification: string;
  getOutstandingBalance: () => number;
  includes: (p: IncludesT) => LoanType;
  find: (p: string) => Promise<{ data: LoanType }>;
  disburse: (info: DisbursalInfo) => Promise<boolean>;
  writeOff: (notes: ?Note) => Promise<boolean>;
  canDisburse: () => boolean;
  canWriteOff: () => boolean;
  canReschedule: () => boolean;
  canRefinance: () => boolean;
  canMakeRepayment: () => boolean;
  member?: MemberType;
  hasNoSecurities: () => boolean;
  interestRatePercent: () => number;
  isPaidOff: () => boolean;
  isApproved: () => boolean;
  isClosed: () => boolean;
  isBridgingProduct: () => boolean;
  isRefinancing: () => boolean;
  isPendingApproval: () => boolean;
  canAddFee: () => boolean;
  totalPaid: string;
}

export default Loan;
