//@flow

import config from '@kwara/lib/src/config';

const api = {
  local: '//127.0.0.1:3000',
  sandbox: 'https://cfi-api.sandbox.kwara.com',
  production: 'https://cfi-api.kwara.com'
};

export default config({ api, appName: 'sacco' });
