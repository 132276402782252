// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';
import isEqual from 'lodash/fp/isEqual';
import some from 'lodash/fp/some';

import { Text } from '@kwara/components/src/Intl';
import { type MemberType } from '@kwara/models/src';
import { Link } from '@kwara/components/src/Link';

import typeof { Child } from '../../../../../components/Stack';
import MemberSearch from '../../../../../components/MemberSearch';

type Props = {
  onSelect: (member: MemberType) => Promise<void>,
  onCancel: () => void,
  StackChild: Child,
  data: any
};

function alreadyAdded(data, chosenMember) {
  const sameId = pipe(
    get('member.id'),
    isEqual(chosenMember.id)
  );
  return pipe(
    get('guarantors'),
    some(sameId)
  )(data);
}

export const GuarantorSearch = ({
  onCancel,
  onSelect,
  StackChild,
  data = {}
}: Props) => (
  <>
    <StackChild>
      <div className="pb2 flex justify-between items-center">
        <p className="ma0 kw-text-regular grey-400">
          <Text id="LoanAdd.Security.Guarantor.searchTitle" />
        </p>
        <Link className="mla" type="secondary" onClick={onCancel}>
          <Text id="LoanAdd.Security.Guarantor.cancel" />
        </Link>
      </div>
    </StackChild>
    <StackChild size="widest">
      <MemberSearch
        resultType="nonFinancial"
        onSelect={onSelect}
        data-cy="guarantor-search"
        isItemDisabled={member => alreadyAdded(data, member)}
        returnFullMember
      />
    </StackChild>
  </>
);
