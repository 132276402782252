// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import { Logger } from '@kwara/lib/src/logger';

import { SubscribedSelectField as SelectField } from '..';

// TO DO: Fix this cross import
// Once the Member app also fetches the banks, there could be a shared config object
// that can be responsibel for setting the right list?
import { store } from '../../../../webapp-sacco/src/models/Store';

export const BankSelect = ({
  name,
  banks = store.banks,
  labelId = 'Forms.TransactionForm.Bank.label',
  titleId
}: {
  name: string,
  banks?: any,
  labelId?: ?string,
  titleId?: string
}) => {
  return (
    <SelectField name={name} titleId={titleId} labelId={labelId} required>
      <SelectField.Option
        translationId={`Forms.TransactionForm.Bank.default`}
        value=""
      />
      {map(
        o => (
          <SelectField.Option value={o.id} key={o.id}>
            {o.name}
          </SelectField.Option>
        ),
        banks
      )}
    </SelectField>
  );
};

export const BranchSelect = ({
  name = 'bankBranch',
  bankId,
  banks = store.banks,
  required = true
}: {
  name: string,
  bankId: string,
  banks?: any,
  required?: boolean
}) => {
  const bank = find(b => Number(b.id) === Number(bankId), banks);

  if (!bank) {
    Logger.warn(`Missing bank details for bankId: ${bankId}`);
  }

  const branches = get('bankBranches', bank);

  return (
    <SelectField
      required={required}
      name={name}
      labelId="Forms.TransactionForm.Branch.label"
    >
      <SelectField.Option value="" />
      {map(
        branch => (
          <SelectField.Option value={branch.id} key={branch.id}>
            {branch.name}
          </SelectField.Option>
        ),
        branches
      )}
    </SelectField>
  );
};
