//@flow
import * as React from 'react';
import { Route } from 'react-router-dom';

import { CurrentRouteNameProvider } from '.';

type Props = {
  name: string,
  component: React.ComponentType<*>,
  path?: string,
  exact?: boolean,
  strict?: boolean,
  sensitive?: boolean
};

const NamedRoute = ({ name, ...rest }: Props) => (
  <CurrentRouteNameProvider value={name}>
    <Route {...rest} />
  </CurrentRouteNameProvider>
);

export default NamedRoute;
