//@flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { Loadable } from '@kwara/components/src/Loadable';
import { ScrollToTop } from '@kwara/components/src/ScrollIntoFocus';

import { useLoan } from '../../models/request';
import LoanDetail from '../LoanDetail';
import { type LoanPageProps } from '..';

type Props = LoanPageProps;

const includes = [];

export const LoanRouteDecider = (props: Props) => {
  const res = useLoan(props.match.params.loanId, includes);
  return (
    <Loadable {...res}>
      {loan =>
        loan.isPendingApproval() ? (
          <Redirect to={`/loans/${loan.id}/approve`} />
        ) : (
          <ScrollToTop>
            <LoanDetail {...props} loan={loan} />
          </ScrollToTop>
        )
      }
    </Loadable>
  );
};
