// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

export const ProductTypes = Object.freeze({
  CURRENT_ACCOUNT: 'CURRENT_ACCOUNT',
  FIXED_DEPOSIT: 'FIXED_DEPOSIT',
  SAVINGS_ACCOUNT: 'SAVINGS_ACCOUNT'
});
type ProductTypesT = $Values<typeof ProductTypes>;

export const InterestTerms = Object.freeze({
  FIXED: 'FIXED'
});
type InterestTermsT = $Values<typeof InterestTerms>;

export const InterestSource = Object.freeze({
  FIXED_INTEREST_RATE: 'FIXED_INTEREST_RATE'
});
type InterestSourceT = $Values<typeof InterestSource>;

export const ChargeFrequency = Object.freeze({
  ANNUALIZED: 'ANNUALIZED',
  EVERY_MONTH: 'EVERY_MONTH',
  EVERY_FOUR_WEEKS: 'EVERY_FOUR_WEEKS',
  EVERY_WEEK: 'EVERY_WEEK',
  EVERY_DAY: 'EVERY_DAY'
});
type ChargeFrequencyT = $Values<typeof ChargeFrequency>;

type InterestRateSettingsT = {
  default: ?number,
  terms: ?InterestTermsT,
  source: ?InterestSourceT,
  chargeFrequency?: ?ChargeFrequencyT
};

const SavingProduct = Base.extend({
  static: {
    jsonapiType: 'savings_products'
  },
  attrs: {
    name: attr(),
    type: attr(),
    accountingMethod: attr(),
    interestPaidIntoAccount: attr(),
    // TO DO: Propose to change `interestRate` to `interestRateSettings`
    interestRate: attr(),
    productType: attr(),
    minimumOpeningBalance: attr(),
    maximumOpeningBalance: attr()
  },
  methods: {
    deserialize() {
      // This is here temporarily until the backend
      // serves back this boolean attribute
      this.interestPaidIntoAccount = !!this.interestRate.default;
      return this;
    }
  }
});

export interface SavingProductType extends BaseModel<SavingProductType> {
  id: string;
  name: string;
  type: string;
  accountingMethod: string;
  productType: ProductTypesT;
  interestPaidIntoAccount: boolean;
  interestRate: InterestRateSettingsT;
  minimumOpeningBalance: ?string;
  maximumOpeningBalance: ?string;
}

export type SavingProductId = string;

export default (SavingProduct: typeof SavingProduct);
