// @flow
import pipe from 'lodash/fp/pipe';
import values from 'lodash/fp/values';
import map from 'lodash/fp/map';
import getOr from 'lodash/fp/getOr';

export const contexts = Object.freeze({
  SavingsRemittance: 'SavingsRemittance',
  LoanRemittance: 'LoanRemittance',
  LoanDisbursement: 'LoanDisbursement',
  LoanRepayment: 'LoanRepayment',
  Deposit: 'Deposit',
  Withdrawal: 'Withdrawal',
  Utility: 'Utility',
  LoanPayOff: 'LoanPayOff'
});
type ContextT = $Values<typeof contexts>;

export const TransactionChannels = Object.freeze({
  bankDeposit: 'bank_deposit',
  bankTransfer: 'bank_transfer_eft',
  cash: 'cash',
  cheque: 'cheque',
  internalCheque: 'internal_cheque',
  directDebit: 'direct_debit',
  fosa: 'fosa',
  mobile: 'mobile_money',
  payrollDeduction: 'payroll_deduction',
  standingOrder: 'standing_order',
  glTransfer: 'glTransfer',
  bankersCheque: 'bankers_cheque'
});
export type TransactionChannelT = $Values<typeof TransactionChannels>;

const asValues = pickedChannels =>
  pipe(
    values,
    map<TransactionChannelT, { value: TransactionChannelT }>(value => ({
      value
    }))
  )(pickedChannels);

// This object drives the options available for each type of transaction.
// See the reference table at https://kwara-eng-docs.netlify.com/mambu/transaction_channels/
const validTransactionTypesByContext = {
  [contexts.SavingsRemittance]: {
    values: asValues([
      TransactionChannels.bankDeposit,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.directDebit,
      TransactionChannels.fosa,
      TransactionChannels.mobile,
      TransactionChannels.payrollDeduction,
      TransactionChannels.standingOrder
    ])
  },
  // Used for the "Repayment" step of LoanAdd
  [contexts.LoanRemittance]: {
    values: asValues([
      TransactionChannels.bankDeposit,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.directDebit,
      // FOSA deposits are not supported yet
      // TransactionChannels.fosa,
      TransactionChannels.mobile,
      TransactionChannels.payrollDeduction,
      TransactionChannels.standingOrder
    ])
  },
  [contexts.LoanDisbursement]: {
    values: asValues([
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.fosa,
      TransactionChannels.mobile
    ])
  },
  // Used for Repayment form
  [contexts.LoanRepayment]: {
    values: asValues([
      TransactionChannels.bankDeposit,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.directDebit,
      // FOSA deposits are not supported yet
      // TransactionChannels.fosa,
      TransactionChannels.mobile,
      TransactionChannels.payrollDeduction,
      TransactionChannels.standingOrder,
      TransactionChannels.glTransfer
    ])
  },
  [contexts.LoanPayOff]: {
    values: asValues([
      TransactionChannels.bankDeposit,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.directDebit,
      TransactionChannels.fosa,
      TransactionChannels.mobile,
      TransactionChannels.payrollDeduction,
      TransactionChannels.standingOrder
    ])
  },
  [contexts.Deposit]: {
    values: asValues([
      TransactionChannels.bankDeposit,
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      // FOSA deposits are not supported yet
      // TransactionChannels.fosa,
      TransactionChannels.mobile,
      TransactionChannels.glTransfer,
      TransactionChannels.internalCheque
    ])
  },
  [contexts.Withdrawal]: {
    values: asValues([
      TransactionChannels.bankTransfer,
      TransactionChannels.cash,
      TransactionChannels.cheque,
      TransactionChannels.bankersCheque,
      // FOSA deposits are not supported yet
      // TransactionChannels.fosa,
      TransactionChannels.mobile,
      TransactionChannels.glTransfer
    ])
  },
  [contexts.Utility]: {
    values: asValues([TransactionChannels.cash])
  }
};
export const getTransactionTypes = (context: ContextT) => {
  return getOr(
    () =>
      // eslint-disable-next-line
      console.error(
        'Pass a valid context to get a list of transaction channels',
        `Can't find a list for ${context}`
      ),
    context,
    validTransactionTypesByContext
  );
};
