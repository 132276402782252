// @flow
import * as React from 'react';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import isEmpty from 'lodash/fp/isEmpty';
import compact from 'lodash/fp/compact';
import cx from 'classnames';

import Banner from '@kwara/components/src/Banner';
import ScrollIntoFocus from '@kwara/components/src/ScrollIntoFocus';
import {
  Condition,
  SubscribedTextField,
  SubscribedSelect,
  SubscribedSelectField,
  SubscribedCheckbox
} from '@kwara/components/src/Form';
import createValidator from '@kwara/lib/src/validator';
import { Stack } from '@kwara/components/src/Stack';
import WizardActions from '@kwara/components/src/WizardActions';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';

import { SubscribedRadioGroup, SubscribedTextArea } from './FormFields';
import Action from './Action';
import Header from './Header';
import { type ActionEvent, type RenderableStep, patchValues } from '.';

import styles from './index.module.css';

export { patchValues };

type Props<Data> = {
  currentState: Data,
  error?: any,
  history: { goBack: () => void, push: (path: string) => void },
  isProcessing?: boolean,
  onAction: (action: ActionEvent<Data>) => void,
  onChange: (data: Data) => Promise<Data>,
  subStep: RenderableStep,
  className?: string,
  fullPage?: boolean,
  bottomMargin?: boolean,
  overflowScroll?: boolean,
  canCancel?: boolean,
  parentUrl: string
};

export type ComponentProps<Data> = {
  data: Data,
  // error: any,
  addData: (updated: Data) => void,
  addDataAndContinue: (updated: Data) => void,
  Checkbox: typeof SubscribedCheckbox,
  Condition: typeof Condition,
  RadioGroup: typeof SubscribedRadioGroup,
  Select: typeof SubscribedSelect,
  SelectField: typeof SubscribedSelectField,
  TextArea: typeof SubscribedTextArea,
  TextField: typeof SubscribedTextField,
  StackChild: typeof Stack.Child,
  onChange: (data: Data) => Promise<Data>
};

export const SubStepContainer = ({
  className = '',
  currentState,
  error,
  history,
  isProcessing,
  onAction,
  onChange,
  parentUrl,
  subStep,
  fullPage,
  overflowScroll = true,
  bottomMargin = true,
  canCancel = true
}: Props<*>) => {
  let validate = undefined;

  if (typeof subStep.validate === 'function') {
    validate = createValidator(subStep.validate(currentState));
  } else if (subStep.validate) {
    validate = createValidator(subStep.validate);
  }

  /*
    When the form is submitted, and the validations
    pass, we call onChange to update the state of
    the Wizard with new data.
  */
  const dispatchChangeEvent = async patch => {
    await onChange(patch);
  };

  /*
    Calls onAction with the action provided
    and a data payload supplied at call-time
  */
  const createActionHandler = action => {
    return async payload => {
      onAction({ payload, action });
    };
  };

  let errorBanner = null;

  if (!isEmpty(error) && (error instanceof Error || error.message)) {
    errorBanner = (
      <ScrollIntoFocus>
        <Banner className="mt4" text={error.message} state="error" />
      </ScrollIntoFocus>
    );
  } else if (!isEmpty(error)) {
    errorBanner = (
      <ScrollIntoFocus>
        <ModelErrorBanner className="mt4" errors={error} state="error" />
      </ScrollIntoFocus>
    );
  }

  return (
    <Form
      initialValues={currentState}
      onSubmit={dispatchChangeEvent}
      validate={validate}
      mutators={arrayMutators}
      render={formProps => {
        const { handleSubmit, invalid, form, values } = formProps;
        const canProgress = !invalid;

        /*
          When resetValidation is called, the validation ruleset object
          is recalculated based on the form's live values.
          Warning: There must be a condition to prevent the form
          from re-rendering in an infite loop
        */
        const resetValidation = () => {
          if (typeof subStep.validate === 'function') {
            form.setConfig(
              'validate',
              createValidator(subStep.validate(values))
            );
          }
        };

        /*
          When addDataAndContinue is called to programmatically
          move to the next step, we find an action in the array
          that behavesAs 'next' or 'approve'.
        */
        const handleNext = createActionHandler(
          find(subStep.actions, { behavesAs: 'next' }) ||
            find(subStep.actions, { behavesAs: 'approve' })
        );

        /*
          Sets this data on the Wizard programmatically
          without requiring a form input. This is useful
          for more complex components such as product selectors
          or member search.
        */
        const addData = async data => {
          form.batch(function() {
            forEach(data, function(value, name) {
              form.change(name, value);
            });
          });
        };

        /*
          Adds data programatically and then proceeds immedietely
          to the next Wizard step.
        */
        const addDataAndContinue = async data => {
          await addData(data);
          await form.submit();

          if (typeof handleNext === 'function') {
            handleNext();
          } else {
            throw new Error('addDataAndContinue called but handleNext is null');
          }
        };

        const header =
          subStep.titleId || subStep.subtitleId || errorBanner ? (
            <Header
              titleId={subStep.titleId}
              subtitleId={subStep.subtitleId}
              values={currentState}
            >
              {errorBanner}
            </Header>
          ) : null;

        const cancelAction = canCancel
          ? new Action({
              appearsAs: 'cancel',
              behavesAs: 'cancel'
            })
          : null;

        const hideActions = subStep.hideActions === true;

        return (
          <form
            data-testid="wizard-form-visible"
            onSubmit={handleSubmit}
            className={cx(
              styles.SubStep,
              overflowScroll ? styles.OverflowScroll : null,
              bottomMargin ? styles.SubStepBottomMargin : null,
              hideActions ? styles.actionsHidden : null,
              className
            )}
          >
            <div id="top" className={styles.SubStepInner}>
              <Stack>
                {header}
                <subStep.Component
                  data={currentState}
                  addData={addData}
                  parentUrl={parentUrl}
                  addDataAndContinue={addDataAndContinue}
                  customProps={subStep.customProps}
                  config={subStep}
                  history={history}
                  Checkbox={SubscribedCheckbox}
                  Condition={Condition}
                  RadioGroup={SubscribedRadioGroup}
                  Select={SubscribedSelect}
                  SelectField={SubscribedSelectField}
                  TextArea={SubscribedTextArea}
                  TextField={SubscribedTextField}
                  StackChild={Stack.Child}
                  formProps={formProps}
                  onChange={onChange}
                  resetValidation={resetValidation}
                />
                {hideActions ? null : (
                  <WizardActions
                    className={cx(styles.WizardActions, {
                      [styles.WizardActionsFixed]: fullPage
                    })}
                    innerClassName={styles.WizardActionsInner}
                    actions={compact([...subStep.actions, cancelAction])}
                    onAction={async action => {
                      if (action.behavesAs !== 'cancel') {
                        // We do not dispatchChangeEvent on cancel, in case the
                        // userdoes not confirm the cancelation and returns the
                        // form, the data will be as is (and not marked invalid)
                        // see ch10634
                        await form.submit();
                      }
                      onAction({ action, payload: null });
                    }}
                    nextDisabled={!canProgress}
                    isProcessing={isProcessing}
                  />
                )}
              </Stack>
            </div>
          </form>
        );
      }}
    />
  );
};

export default withRouter(SubStepContainer);
