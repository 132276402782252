// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { getCurrentDate, tomorrow } from '@kwara/lib/src/dates';
import {
  type LoanType,
  type BankGlAccountT,
  GlContexts
} from '@kwara/models/src';
import {
  getTransactionTypes,
  contexts,
  TransactionChannels,
  type TransactionChannelT
} from '@kwara/models/src/models/Transactions';
import { Text } from '@kwara/components/src/Intl';
import {
  SubscribedTextField as TextField,
  SubscribedPaymentSelectField,
  SubscribedCheckbox as Checkbox,
  GlAccountSelect
} from '@kwara/components/src/Form';

import { Panel } from '../../../components/ActionModal';
import {
  DatePicker,
  Field,
  SubscribedDatePicker
} from '../../../components/Form';

export type PaymentData = {
  amount?: ?number,
  date?: ?Date,
  firstRepaymentDate?: ?Date,
  method?: ?TransactionChannelT,
  bankName?: string,
  bankBranch?: string,
  accountNumber?: string,
  reference?: ?string,
  accountId?: string,
  loan: LoanType,
  allGlAccounts: BankGlAccountT[]
};

const showBanksFieldsFor = [
  TransactionChannels.bankTransfer,
  TransactionChannels.cheque
];

const showChequeFieldsFor = [TransactionChannels.cheque];

const ifCheque = {
  isRequired: (_: string, allData: PaymentData) =>
    showChequeFieldsFor.includes(allData.method)
};

const ifChequeOrBank = {
  isRequired: (_: string, allData: PaymentData) =>
    showBanksFieldsFor.includes(allData.method)
};

function shouldSignRepaymentForm(values: PaymentData) {
  const repaymentMethod = get('loan.repaymentDetails.repayment_mode', values);
  return [TransactionChannels.directDebit].includes(repaymentMethod);
}

export const validateConfig = {
  method: { isRequired: () => true },
  bankName: ifChequeOrBank,
  bankBranch: ifChequeOrBank,
  accountNumber: ifChequeOrBank,
  chequeNumber: ifCheque,
  drawer: ifCheque,
  signedOfferLetter: {
    isRequired: () => true
  },
  signedRepaymentForm: {
    isRequired: (_: string, allData: PaymentData) =>
      shouldSignRepaymentForm(allData)
  },
  ...GlAccountSelect.validate
};

type Props = {
  formValues: PaymentData,
  addData: ({ bankGlId: string }) => void
};

export const PaymentForm = (props: Props) => {
  const { formValues, addData } = props;

  const { method } = formValues;

  const memberId = get('loan.member.id', formValues);

  return (
    <Panel>
      <Field
        required
        name="date"
        size="medium"
        labelId="LoanDisburse.dateLabel"
      >
        <DatePicker name="date" disabled value={getCurrentDate()} />
      </Field>
      <SubscribedDatePicker
        name="firstRepaymentDate"
        size="medium"
        labelId="LoanDisburse.firstRepaymentDate.label"
        disabledDays={{ before: tomorrow() }}
      />
      <SubscribedPaymentSelectField
        name="method"
        labelId="LoanDisburse.method.label"
        inputOnChange={() => addData({ bankGlId: '' })}
        config={{
          bank: 'bankName',
          showBanksFieldsFor,
          methods: getTransactionTypes(contexts.LoanDisbursement).values,
          showAccountSelectionFor: [TransactionChannels.fosa],
          showChequeFieldsFor,
          memberId
        }}
        required
      />

      <GlAccountSelect
        method={method}
        addData={addData}
        context={GlContexts.DISBURSAL}
      />

      <p className="ma0 grey-400 kw-text-regular mb2">
        <Text id="LoanDisburse.signedDocumentsHeading" />
      </p>
      <Checkbox
        required
        name="signedOfferLetter"
        labelId="LoanDisburse.signedLetterOfOffer.label"
      />
      <Checkbox
        required
        name="signedRepaymentForm"
        labelId="LoanDisburse.signedRepaymentForm.label"
        hidden={!shouldSignRepaymentForm(formValues)}
      />
      <TextField
        hidden={formValues.method == null}
        name="reference"
        size="medium"
        labelId="PaymentForm.reference"
      />
    </Panel>
  );
};
