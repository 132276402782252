// @flow

import * as React from 'react';
import cx from 'classnames';
import pipe from 'lodash/fp/pipe';
import last from 'lodash/fp/last';
import split from 'lodash/fp/split';
import map from 'lodash/fp/map';
import toUpper from 'lodash/fp/toUpper';
import toLower from 'lodash/fp/toLower';
import getOr from 'lodash/fp/getOr';
import join from 'lodash/fp/join';
import find from 'lodash/fp/find';

import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import { getCurrentDate } from '@kwara/lib/src/dates';
import {
  mimeTypesMaps,
  Attachments,
  type AttachmentT,
  type MemberType
} from '@kwara/models/src';
import {
  allowedExtensions,
  type AttachmentMeta
} from '@kwara/models/src/models/Attachment';
import { Logger } from '@kwara/lib/src/logger';

import { Checkbox } from '../Form';
import { WithViewer } from './Viewer';

import style from './index.module.css';

export {
  addAttachment,
  allowedAttachments,
  removeAttachment,
  isDirty,
  findProfilePicture
} from './utils';
export { WithViewer } from './Viewer';

// Trims the data URI scheme from the base64 encoded image
// in: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAAC=="
// out: "iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAAC=="
export const trimScheme = (fileName: string) =>
  pipe(
    split('base64,'),
    last
  )(fileName);

export function toBase64(file: File) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(trimScheme(reader.result));
    reader.onerror = reject;
  });
}

export function toBase64Img(base64: string, type: string) {
  return `data:image/${toLower(type)};base64,${base64}`;
}

type fileNameT = $ElementType<AttachmentMeta, 'name'> | 'profile';

const mimeTypeToExtension = mimeType =>
  getOr('UNKNOWN', mimeType, mimeTypesMaps);

export async function convert(
  evt: SyntheticInputEvent<HTMLInputElement>,
  fileName: fileNameT
): Promise<AttachmentT | {}> {
  const [file] = evt.target.files;
  const { type } = file;

  try {
    const content = await toBase64(file);
    return new Attachments({
      content,
      name: fileName,
      // filename is unique per user so we can use it as a temp ID. Having a temp ID makes it easier to handle viewing usaved docs with <Viewer />
      // This temp id will be discarded when saving as the server will use its own ID logic to assign it.
      id: `temp-id-${fileName}-${getCurrentDate().toISOString()}`,
      type: pipe(
        mimeTypeToExtension,
        toUpper
      )(type)
    });
  } catch (e) {
    Logger.error('Error at UploadWidget > convert', JSON.stringify(e));
    return {};
  }
}

type Props = {
  textId: string,
  infoId?: string,
  fileName: fileNameT,
  onChange: (p: AttachmentT | {}) => void,
  onRemove: (p: string) => void,
  checked: boolean,
  member: MemberType
};

// Accept files for only ".png,.jpg,etc"
export const accept = pipe(
  map(ext => `.${toLower(ext)}`),
  join(',')
)(allowedExtensions);

export const UploadWidget = ({
  textId,
  fileName,
  onChange,
  onRemove,
  checked,
  member = {}
}: Props) => (
  <WithViewer member={member}>
    {({ setShownAttachment }) => (
      <div className="relative b--dashed br3 bw1 b--light-gray h3 dim pa3 mb3 flex justify-between">
        <div className="dib v-mid mt1">
          <Checkbox
            checked={checked}
            labelId={textId}
            name={fileName}
            readOnly
          />
        </div>
        {checked ? (
          <div />
        ) : (
          <Link
            className={cx(style.LinkContainer)}
            onClick={() => {}}
            size="regular"
            type="primary"
            underline
            active
          >
            <span className={style.InputText}>Upload</span>
          </Link>
        )}
        {
          // If input is dirty we allow removing the file
          // If not we allow uploading a new file
        }
        {checked ? (
          <div>
            <span
              className="pointer kw-text-regular indigo-500 underline"
              onClick={() => onRemove(fileName)}
            >
              <Text id="DocumentUploads.Remove.label" />
            </span>{' '}
            <span
              className="pointer kw-text-regular indigo-500 underline"
              onClick={() =>
                setShownAttachment(
                  find(o => o.name === fileName, member.attachments)
                )
              }
            >
              <Text id="DocumentUploads.View.label" />
            </span>
          </div>
        ) : (
          <input
            type="file"
            className={style.InputFile}
            accept={accept}
            onChange={e => convert(e, fileName).then(onChange)}
          />
        )}
      </div>
    )}
  </WithViewer>
);
