// @flow

import * as React from 'react';
import map from 'lodash/fp/map';

import { type UserT } from '@kwara/models/src';
import { ActionButton } from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import { settingPath } from '../../../../lib/urls';
import Table, { Cell, Row, Heading } from '../../../../components/Table';
import { ButtonMenu } from '../../../../components/ButtonMenu';

const IconDropDown = ({
  user,
  col = 'grey300'
}: {
  user: UserT,
  col?: string
}) => (
  <ButtonMenu
    Button={props => (
      <ActionButton {...props} hideBorder col={col} type="more" />
    )}
  >
    <ButtonMenu.Item
      to={settingPath({
        baseExtension: 'team',
        action: 'edit',
        id: user.id
      })}
    >
      <Text id="Settings.Team.Table.IconMenu.edit" />
    </ButtonMenu.Item>
  </ButtonMenu>
);

export const UserTable = ({ users }: { users: UserT[] }) => {
  const heading = (
    <Row className="grey-400">
      <Heading width="75px" translationId="Settings.Team.Table.id" />
      <Heading translationId="Settings.Team.Table.name" />
      <Heading translationId="Settings.Team.Table.email" />
      <Heading translationId="Settings.Team.Table.roles" />
      <Heading iconSpacer />
    </Row>
  );

  return (
    <Table className="mt5" heading={heading}>
      {map(user => {
        return (
          <Row key={user.id} secondaryIcon={<IconDropDown user={user} />}>
            <Cell>{user.id}</Cell>
            <Cell>{user.fullName()}</Cell>
            <Cell>{user.email}</Cell>
            <Cell>
              <ul className="list pl0">
                {map(
                  appRole => (
                    <li key={appRole.id}>{appRole.name}</li>
                  ),
                  user.appRoles
                )}
              </ul>
            </Cell>
          </Row>
        );
      }, users)}
    </Table>
  );
};
