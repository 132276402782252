//@flow
import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text, type TranslationId } from '@kwara/components/src/Intl';
import { TextField } from '../Form';
import Visible from '../Visible';

import Filter, {
  type FilterValue,
  type Filters,
  type FilterChangeHandler
} from './Filter';

import styles from './index.module.css';

const Heading = ({
  translationId,
  ...props
}: {
  translationId: TranslationId,
  values: { [id: string]: string | number | null }
}) => (
  <h1 className="kw-text-extra-large kw-weight-bold mb0">
    <Text id={translationId} {...props} />
  </h1>
);

export const ActionableHeading = ({
  headerId,
  headerValues,
  actions,
  subheading,
  backButton
}: {
  headerId?: string,
  headerValues?: { [id: string]: any },
  actions: (React.Element<typeof Button> | React.Element<typeof Visible>)[],
  subheading?: React.Node,
  backButton?: React.Element<typeof Button>
}) => (
  <div className="flex items-center mb3">
    <div>
      {backButton ? backButton : null}
      {headerId ? (
        <Heading translationId={headerId} values={headerValues} />
      ) : null}
      {subheading ? subheading : null}
    </div>
    {actions ? (
      <div className={`flex ml-auto ${styles.ActionsBar}`}>{actions}</div>
    ) : null}
  </div>
);

const Label = ({
  translationId,
  visible,
  total
}: {
  translationId: TranslationId,
  visible: number,
  total: number
}) => (
  <p className="dib kw-text-regular grey-400 ma0 pa0">
    <Text id={translationId} values={{ visible, total }} />
  </p>
);

type SearchChangeHandler = (term: string) => void;

type Props = {
  backButton?: React.Element<typeof Button>,
  actions?: (React.Element<typeof Button> | React.Element<typeof Visible>)[],
  headerId?: TranslationId,
  headerValues?: { [k: string]: string },
  filterLabelId?: string,
  filterBy?: FilterValue,
  filters?: Filters,
  filterDisabled?: boolean,
  onFilterChange?: FilterChangeHandler,
  labelId: TranslationId,
  onSearchChange?: SearchChangeHandler,
  searchPlaceholderId?: TranslationId,
  searchValue?: string,
  table: React.Element<*>,
  totalNumResults: number,
  items: Array<*>
};

export default ({
  backButton,
  filterLabelId,
  actions,
  filterBy,
  filters,
  filterDisabled,
  searchPlaceholderId,
  onFilterChange,
  onSearchChange,
  searchValue,
  headerId,
  headerValues,
  labelId,
  table,
  totalNumResults,
  items
}: Props) => {
  return (
    <div>
      <ActionableHeading
        headerId={headerId}
        headerValues={headerValues}
        actions={actions}
        backButton={backButton}
      />
      <div className="flex items-center mt4 mb3">
        <Label
          translationId={labelId}
          visible={items.length}
          total={totalNumResults}
        />
        <div className="flex justify-center items-center ml-auto">
          {filters && (
            <Filter
              filterLabelId={filterLabelId}
              filterBy={filterBy}
              filters={filters}
              onChange={onFilterChange}
              disabled={filterDisabled}
            />
          )}
          {onSearchChange && (
            <div className="ml3">
              <TextField
                border={false}
                leftGlyph="Search"
                name="search"
                onChange={evt => {
                  onSearchChange(evt.target.value);
                }}
                placeholderId={searchPlaceholderId}
                size="regular"
                value={searchValue || ''}
              />
            </div>
          )}
        </div>
      </div>
      <div>{table}</div>
    </div>
  );
};
