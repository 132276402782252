// @flow

import { attr } from 'spraypaint';

import Base from './Base';

const Repayment = Base.extend({
  static: {
    jsonapiType: 'repayments'
  },
  attrs: {
    dueAt: attr(),
    startingBalance: attr(),
    endingBalance: attr(),
    totalDue: attr(),
    totalPaid: attr(),
    principal: attr(),
    interest: attr(),
    fees: attr(),
    penalty: attr(),
    state: attr()
  },
  methods: {
    outstanding() {
      return this.totalDue - this.totalPaid;
    }
  }
});

export type RepaymentType = Repayment;

export default (Repayment: typeof Repayment);
