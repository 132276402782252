// @flow

import { attr, belongsTo } from 'spraypaint';
import get from 'lodash/fp/get';

import Base, { type BaseModel } from './Base';
import { type BankBranchT } from './BankBranch';

export const BankAccount = Base.extend({
  static: {
    jsonapiType: 'bank_accounts'
  },
  attrs: {
    bankId: attr(),
    accountNumber: attr(),
    bankBranch: belongsTo(),

    // for POST
    bankBranchId: attr(),
    mambuId: attr()
  },
  methods: {
    deserialize() {
      this.bankId = get('bankBranch.bank.id', this);
      return this;
    }
  }
});

export interface BankAccountT extends BaseModel<BankAccountT> {
  accountNumber: string;
  bankBranch: BankBranchT;
  bankBranchId: string;
  mambuId: string;
}
