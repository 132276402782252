// @flow

import { attr } from 'spraypaint';
import filter from 'lodash/fp/filter';
import includes from 'lodash/fp/includes';

import Base, { type BaseModel } from './Base';
import { TransactionChannels, type TransactionChannelT } from './Transactions';

const glTypes = Object.freeze({
  mobileMoney: 'mobile_money',
  bankAccounts: 'bank_accounts',
  cheques: 'cheques',
  cash: 'cash',
  directDebit: 'direct_debit',
  payrollDeduction: 'payroll_deduction'
});

type GlTypes = $Values<typeof glTypes>;

export const GlContexts = Object.freeze({
  DEPOSIT: 'DEPOSIT',
  REPAYMENT: 'REPAYMENT',
  WITHDRAWAL: 'WITHDRAWAL',
  DISBURSAL: 'DISBURSAL'
});

export type GlContextsT = $Values<typeof GlContexts>;

export const AccountRelationships = {
  [glTypes.mobileMoney]: [TransactionChannels.mobile],
  [glTypes.bankAccounts]: [
    TransactionChannels.bankDeposit,
    TransactionChannels.bankTransfer,
    TransactionChannels.standingOrder
  ],
  [glTypes.cheques]: [TransactionChannels.cheque],
  [glTypes.cash]: [TransactionChannels.cash],
  [glTypes.directDebit]: [TransactionChannels.directDebit],
  [glTypes.payrollDeduction]: [TransactionChannels.payrollDeduction]
};

export function filterGlAccounts(
  allAccounts: BankGlAccountT[],
  method: TransactionChannelT,
  context: GlContextsT
) {
  return filter<BankGlAccountT>(
    gl =>
      includes(method, AccountRelationships[gl.accountType]) &&
      includes(context, gl.contexts),
    allAccounts
  );
}

export const BankGlAccount = Base.extend({
  static: {
    jsonapiType: 'bank_gl_accounts'
  },
  attrs: {
    accountName: attr(),
    accountType: attr(),
    contexts: attr()
  },
  methods: {}
});

export interface BankGlAccountT extends BaseModel<BankGlAccountT> {
  accountName: string;
  accountType: GlTypes;
  contexts: GlContextsT[];
}
