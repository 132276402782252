// @flow

import React from 'react';
import { FormattedNumber as IntlFormattedNumber } from 'react-intl';

import { Logger } from '@kwara/lib/src/logger';

type Props = {
  value: number,
  format?: string,
  style?: string
};

export const FormattedNumber = (
  props: Props
): React.Component<IntlFormattedNumber> => {
  // Warn in case something like ch10017 happens again
  if (isNaN(props.value)) {
    Logger.error('[ERROR] NaN is being rendered where a number is expected');
  }

  return <IntlFormattedNumber {...props} />;
};
