import React from 'react';

import { formatHumanDate, formatHumanTime } from '@kwara/lib/src/dates';

import { DefaultText as Text } from '.';

export const Date = ({ children, style }) => (
  <Text style={style}>{formatHumanDate(children)}</Text>
);

export const Time = ({ children, style }) => (
  <Text style={style}>{formatHumanTime(children)}</Text>
);
