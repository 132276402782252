// @flow

import * as React from 'react';
import map from 'lodash/map';
import getOr from 'lodash/fp/getOr';
import size from 'lodash/fp/size';

import { Text } from '@kwara/components/src/Intl';
import { DeprecatedLoadable, Loading } from '@kwara/components/src/Loadable';
import { Statistic } from '@kwara/components/src/Statistic';
import Banner from '@kwara/components/src/Banner';

import ModelErrorBanner from '../../../components/ModelErrorBanner';
import CreditReport from './CreditReport';
import { type IDCheckResponse } from '../../../models';
import { type SubStepComponentProps } from '../../../components/Wizard';

const View = {
  confirm: 'confirm',
  loading: 'loading',
  report: 'report'
};

type ViewType = $Keys<typeof View>;

type State = {
  view: ViewType,
  idText: string
};

export default class CreditCheck extends React.Component<
  SubStepComponentProps<>,
  State
> {
  static validate() {
    return {};
  }

  render() {
    const { StackChild, data } = this.props;

    return (
      <StackChild>
        <div className="mb3">
          <h2 className="ma0 mb2">
            <Text id="LoanApprove.CreditCheck.memberDetails" />
          </h2>
          <p className="ma0 grey-400">
            <Text id="LoanApprove.CreditCheck.checkDetails" />
          </p>
        </div>

        <div>
          <Statistic
            title={<Text id="LoanApprove.CreditCheck.fullName" />}
            value={data.fullName() || '-'}
            size="medium"
            compact={true}
          />

          <Statistic
            title={<Text id="AddMember.Identity.Type.Document.primary.label" />}
            value={
              <Text
                id={`AddMember.Identity.Type.${data.idDocuments[0].type}`}
              />
            }
            size="medium"
            compact={true}
          />

          <Statistic
            title={<Text id="Stima.Number" />}
            value={data.idDocuments[0].documentId}
            size="medium"
            compact={true}
          />
        </div>
      </StackChild>
    );
  }
}

function Result({
  request
}: {
  request: Promise<IDCheckResponse>,
  onClick: () => void
}) {
  return (
    <DeprecatedLoadable
      loader={request}
      loaded={result => (
        <div>
          {(() => {
            if (!result) {
              return 'Checking the details you entered...';
            }

            if (size(result.errors)) {
              return (
                <div className="mb4">
                  <ModelErrorBanner errors={result.errors} />
                </div>
              );
            }

            const shouldThrow = getOr(' ', 'firstName', result)[0] === 'x';
            const fake = {
              ...result,
              gender: 'MALE',
              firstName: 'John',
              lastName: 'Kahura'
            };
            return (
              <>
                <Banner
                  text={
                    shouldThrow
                      ? "The details don't match"
                      : 'The details match'
                  }
                  state={shouldThrow ? 'error' : 'success'}
                />
                <h2>The details you entered</h2>

                {map(exposedFields, field => (
                  <Statistic
                    key={field}
                    title={<Text id={`Info.${field}`} />}
                    value={getOr('-', field, result)}
                    size="small"
                    compact={true}
                  />
                ))}

                <h2>MCRB ID verification results</h2>
                {map(exposedFields, field => (
                  <Statistic
                    key={field}
                    title={<Text id={`Info.${field}`} />}
                    value={getOr('-', field, shouldThrow ? fake : result)}
                    size="small"
                    compact={true}
                  />
                ))}
              </>
            );
          })()}
        </div>
      )}
    />
  );
}

const exposedFields = ['firstName', 'lastName', 'gender', 'dateOfBirth'];

export class IdentityCheckResult extends React.Component<
  *,
  {
    View: string,
    request?: ?Promise<IDCheckResponse>,
    errors: ErrorDetails,
    error: string
  }
> {
  request = '';
  state = {
    View: '',
    errors: [],
    error: '',
    request: null
  };

  componentDidMount() {
    const { data = {} } = this.props;
    const request = new Promise((res, _rej) => {
      setTimeout(() => {
        res(data);
      }, 3000);
    });
    this.setState({
      request
    });
  }

  render() {
    const { StackChild, data } = this.props;
    const identityType = data.idDocuments[0].type;
    const identityValue = data.idDocuments[0].documentId;

    if (this.state.View === 'creditReport') {
      return <CreditReport {...this.props} />;
    }

    if (this.state.View === 'loading') {
      return <Loading />;
    }

    return (
      <StackChild>
        {identityType && identityValue ? (
          <Result
            request={this.state.request}
            onClick={() => {
              this.setState({ View: 'loading' });
              setTimeout(() => {
                this.setState({ View: 'creditReport' });
              }, 4000);
            }}
          />
        ) : (
          <p>No details entered.</p>
        )}
      </StackChild>
    );
  }
}
