import { Review } from './components/Review';
import { Approve } from './components/Approve';
import { Reject } from './components/Reject';

export const steps = {
  review: {
    Component: Review,
    actions: [
      { appearsAs: 'approve', behavesAs: 'next', destination: 'approve' },
      { appearsAs: 'reject', behavesAs: 'next', destination: 'reject' }
    ]
  },
  approve: {
    Component: Approve,
    actions: [{ appearsAs: 'approve', behavesAs: 'complete' }]
  },
  reject: {
    Component: Reject,
    actions: [{ appearsAs: 'reject', behavesAs: 'reject' }]
  }
};
