//@flow

import { type IntlShape } from 'react-intl';

import { type TranslationId } from './Text.js';

export default function(
  intl: IntlShape,
  id: TranslationId,
  values: { [id: string]: any }
) {
  return intl.formatMessage({ id }, values);
}
