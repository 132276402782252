// @flow

import { attr, belongsTo } from 'spraypaint';

import { type MemberType } from '..';

import Base from './Base';

export const TillTransaction = Base.extend({
  static: {
    jsonapiType: 'till_transactions'
  },
  attrs: {
    createdAt: attr(),
    updatedAt: attr(),
    member: belongsTo(),
    type: attr(),
    amount: attr(),
    balance: attr(),
    paymentMethod: attr(),
    accountNumber: attr(),
    bankName: attr(),
    bankBranch: attr(),
    reference: attr(),
    notes: attr(),
    enteredAt: attr(),
    beneficiary: attr(),
    invoiceNumber: attr()
  },
  methods: {
    isUtility() {
      return this.type === 'UTILITY';
    }
  }
});

export type TillTransactionType = {
  id: string,
  createdAt: string,
  updatedAt: string,
  member: MemberType,
  type: string,
  amount: number,
  balance: number,
  paymentMethod: string,
  accountNumber: ?string,
  bankName: ?string,
  bankBranch: ?string,
  reference: string,
  notes: string,
  enteredAt: string,
  beneficiary?: string,
  invoiceNumber: string,
  isUtility: () => boolean
};
