//@flow
import React, { Component } from 'react';

import { Text } from '@kwara/components/src/Intl';
import Dashboard from '../../../../components/Dashboard';
import DashboardMetric from '../../../../components/DashboardMetric';
import { DeprecatedLoadable } from '@kwara/components/src/Loadable';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { Metric, LoanMetric } from '@kwara/models/src';

type Props = {};

export default class extends Component<Props, *> {
  constructor(props: Props) {
    super(props);

    this.state = {
      metricsPromise: LoanMetric.all().then(response => response.data)
    };
  }

  render() {
    return (
      <DeprecatedLoadable
        loader={this.state.metricsPromise}
        loaded={metrics => (
          <Dashboard>
            <StatisticRow
              childClasses="pa0 mr4"
              compact={true}
              widthClass="w-50"
            >
              <DashboardMetric
                title={<Text id="Loans.Dashboard.grossPortfolio" />}
                metric={Metric.findFromArray(metrics, 'total_loans')}
              />
              <DashboardMetric
                title={<Text id="Loans.Dashboard.portfolioAtRisk" />}
                metric={Metric.findFromArray(metrics, 'portfolio_at_risk')}
              />
            </StatisticRow>
            <StatisticRow
              childClasses="pa0 mr4"
              compact={true}
              widthClass="w-50"
            >
              <DashboardMetric
                title={<Text id="Loans.Dashboard.outstandingAmount" />}
                metric={Metric.findFromArray(metrics, 'outstanding_amount')}
              />
              <DashboardMetric
                title={<Text id="Loans.Dashboard.lateRepayments" />}
                metric={Metric.findFromArray(metrics, 'late_repayments')}
              />
            </StatisticRow>
          </Dashboard>
        )}
      />
    );
  }
}
