// @flow

import Base, { BaseModel } from './Base';

export const TillMetric = Base.extend({
  static: {
    jsonapiType: 'till_metrics'
  },
  attrs: {},
  methods: {}
});

type Metric = {};

export interface TillMetricT extends BaseModel<TillMetric>, Array<Metric> {}
