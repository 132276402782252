// @flow

import { attr, belongsTo } from 'spraypaint';

import Base, { type BaseModel } from './Base';

import { type TransactionChannelT } from './Transactions';

const Transaction = Base.extend({
  static: {
    jsonapiType: 'transactions'
  },
  attrs: {
    amount: attr(),
    balance: attr(),
    type: attr(),
    notes: attr(),
    reference: attr(),
    paymentMethod: attr(),
    glCode: attr(),
    glName: attr(),
    isGlChannel: attr(),
    createdAt: attr(),
    enteredAt: attr(),
    createdBy: attr(),

    // NOTE: this is really used only by pending transactions
    // but needs to be defined here, since when extending models
    // it seems Spraypaint loses track of the relationship definitions
    // (attr() work, while belognsTo() gets deleted)
    user: belongsTo(),
    state: attr(),

    // NB: Account is not returned by the API, but sometimes we need to
    // annotate the transaction with the account it belongs to
    account: attr()
  },
  methods: {
    // TODO: This is temporary until ch14895 has been actioned
    //
    glType() {
      let direction;
      switch (this.type) {
        case 'DEPOSIT':
          direction = 'from';
          break;
        case 'REPAYMENT':
          direction = 'from';
          break;
        case 'WITHDRAWAL':
          direction = 'to';
          break;
      }

      if (!direction) {
        return;
      }

      return `Transfer ${direction} GL ${this.glCode}`;
    }
  }
});

export interface TransactionType extends BaseModel<TransactionType> {
  enteredAt: Date;
  amount: number;
  balance: number;
  type: string;
  notes: string;
  reference: string;
  paymentMethod: TransactionChannelT;
  glCode: string;
  glName: string;
  isGlChannel: boolean;
  glType: () => ?string;
}

export default (Transaction: typeof Transaction);
