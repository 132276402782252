//@flow
import * as React from 'react';
import classnames from 'classnames';

type Props = {
  border?: boolean,
  children: React.Node,
  right?: boolean,
  flex?: ?number,
  width?: ?number
};

const Column = ({ border, children, right, flex }: Props) => {
  const classes = [
    `flex items-center ph3`,
    right ? 'justify-end' : null,
    border ? 'b br b--light-grey-400' : null
  ];

  return (
    <div style={{ flex }} className={classnames(classes)}>
      {children}
    </div>
  );
};

Column.defaultProps = {
  border: true,
  right: false,
  flex: 1
};

export default Column;
