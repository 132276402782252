// @flow
import * as React from 'react';
import map from 'lodash/fp/map';

import { type LoanType } from '@kwara/models/src';

import { memberPath } from '../../../../lib/urls';
import { Grid } from '../../../../components/Grid';
import GuaranteeCard from './GuaranteeCard';
import { EditableSection } from '../../../../components/EditableSection';
import { Guarantor } from '../../../LoanAdd/components/Security/Guarantor';

type Props = {
  loan: LoanType
};

const config = {
  Component: Guarantor
};

export const GuaranteeList = ({ loan }: Props) => {
  return (
    <EditableSection
      readOnly
      initialData={loan}
      config={config}
      ariaLabel="Edit Guarantors"
      onSave={editedLoan =>
        editedLoan.save({
          with: 'guarantors.member'
        })
      }
    >
      <Grid columns={3}>
        {map(
          guarantee => (
            <GuaranteeCard
              loan={loan}
              key={guarantee.id}
              guarantee={guarantee}
              actionTo={memberPath({ id: guarantee.member.id })}
            />
          ),
          loan.guarantors
        )}
      </Grid>
    </EditableSection>
  );
};
