// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { type MemberType } from '@kwara/models/src';
import { useMember } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';
import { memberPath } from '../../lib/urls';
import { steps } from './config';
import type { WizardPageProps } from '..';

type Props = WizardPageProps<{ memberId: string }>;

export const MemberClose = (props: Props) => {
  const { baseUrl, match, history } = props;
  const response = useMember(props.match.params.memberId);

  const exitMember = async ({
    member,
    comment
  }: {
    member: MemberType,
    comment?: string
  }) => {
    const didSave = await member.exit({ comment });

    if (!didSave) {
      throw member.errors;
    }
  };

  return (
    <Loadable {...response}>
      {member => {
        return (
          <Wizard
            baseUrl={baseUrl}
            history={history}
            initialData={{
              member: member,
              memberId: member.id,
              firstName: member.firstName
            }}
            cancelReturnsTo={memberPath()}
            currentStep={match.params.step}
            currentSubStep={
              match.params.subStep != null
                ? parseInt(match.params.subStep, 10)
                : null
            }
            onSubmit={exitMember}
            steps={steps}
            startId="confirm"
            titleId="MemberClose.shortTitle"
            type="approval"
          />
        );
      }}
    </Loadable>
  );
};
