// @flow

import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';

import { ERRORS as err } from '@kwara/lib/src/validator/';

import {
  getCurrentDate,
  getYear,
  isAfter,
  subYears,
  isFuture
} from '@kwara/lib/src/dates';
import { type IdentityType } from './types';

export const dateOfBirth = {
  custom(value?: string) {
    if (!value) {
      return err.valueMissing;
    }

    if (isFuture(value)) {
      return err.future;
    }

    const year = getYear(value);

    if (isAfter(value, subYears(getCurrentDate(), 18))) {
      return err.under18;
    }

    if (year == null || isNaN(year) || year < 1900) {
      return err.invalidYear;
    }

    return null;
  }
};

export const identityDocumentValue = {
  custom: function(id: string, allData: { identityType: IdentityType }) {
    const { idDocuments } = allData;

    const identityType = pipe(
      find(d => d.documentId === id),
      get('type')
    )(idDocuments);

    if (identityType === 'PASSPORT') {
      return /^\w+$/.test(id) ? null : err.passportFormat;
    } else if (identityType === 'NATIONAL') {
      return /^\d+$/.test(id) ? null : err.nationalFormat;
    }
  },
  minlength: 5,
  maxlength: 15
};

export const personName = {
  pattern: /^[ a-zA-Z\u00C0-\u017F'-]+$/i
};

export const positiveNumber = {
  pattern: /[0-9]/,
  min: 0
};
