// @flow

import * as React from 'react';
import { Form } from 'react-final-form';
import includes from 'lodash/fp/includes';
import toLower from 'lodash/fp/toLower';
import trim from 'lodash/fp/trim';

import { PasswordHelper } from '@kwara/components/src/PasswordHelper';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { UserTypes, type UserType } from '@kwara/models/src';
import {
  SubscribedTextField,
  SubscribedPasswordField
} from '@kwara/components/src/Form';

import createValidator from '@kwara/lib/src/validator';
import { passwordBaseRules, passwordConfirmBaseRules } from '@kwara/lib/src/validator/ruleSets'

export type InitialDetails = {
  firstName: string,
  lastName: string,
  email: string,
  phone: string
};

export type FormData = {
  firstName: string,
  lastName: string,
  password: string,
  passwordConfirm: string
};

type Props = {
  initialDetails: InitialDetails,
  onSubmit: (data: FormData) => void,
  userType?: UserType
};

const rules = {
  firstName: {
    isRequired: () => true
  },
  lastName: {
    isRequired: () => true
  },
  password: {
    ...passwordBaseRules,
    custom: (password: string, data: FormData) => {
      const { firstName, lastName } = data;
      if (!firstName || !lastName || !password) {
        return null;
      }
      const included =
        includes(toLower(trim(firstName)), toLower(trim(password))) ||
        includes(toLower(trim(lastName)), toLower(trim(password)));

      if (included) {
        return 'passwordContainsName';
      }

      return null;
    }
  },
  passwordConfirm: passwordConfirmBaseRules
};
export default class RegistrationForm extends React.Component<Props, *> {
  static validator = createValidator(rules);

  static validate(values: FormData) {
    return RegistrationForm.validator(values);
  }

  handleSubmit = (data: FormData) => {
    const { firstName, lastName, password, passwordConfirm } = data;

    this.props.onSubmit({
      firstName,
      lastName,
      password,
      passwordConfirm
    });
  };

  render() {
    const { userType, initialDetails = {} } = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={initialDetails}
        validate={RegistrationForm.validate}
        render={({ handleSubmit, invalid, errors }) => (
          <form onSubmit={handleSubmit}>
            <p className="grey-400 kw-text-medium">
              {userType === UserTypes.MEMBER ? (
                <Text
                  id="InvitationRedeem.RegistrationForm.memberSubtitle"
                  values={{ firstName: initialDetails.firstName }}
                />
              ) : (
                  <Text id="InvitationRedeem.RegistrationForm.subtitle" />
                )}
            </p>
            {userType === UserTypes.MEMBER ? (
              <div className="pb2 pt2">
                <p className="grey-400 mb2 kw-text-small">
                  <Text id="InvitationRedeem.RegistrationForm.email" />
                </p>
                <p className="black kw-text-regular kw-weight-regular">
                  {initialDetails.email}
                </p>

                <p className="grey-400 mb2 kw-text-small">
                  <Text id="InvitationRedeem.RegistrationForm.phone" />
                </p>
                <p className="black kw-text-regular kw-weight-regular">
                  {initialDetails.phone}
                </p>
              </div>
            ) : (
                <>
                  <SubscribedTextField
                    name="firstName"
                    labelId="InvitationRedeem.RegistrationForm.firstName"
                    errorBaseId="InvitationRedeem.RegistrationForm.name"
                    autoComplete="given-name"
                  />

                  <SubscribedTextField
                    name="lastName"
                    labelId="InvitationRedeem.RegistrationForm.lastName"
                    errorBaseId="InvitationRedeem.RegistrationForm.name"
                    autoComplete="family-name"
                  />
                </>
              )}

            <SubscribedPasswordField
              margin={false}
              name="password"
              labelId="InvitationRedeem.RegistrationForm.password"
              errorBaseId="InvitationRedeem.RegistrationForm.password"
              type="password"
              autoComplete="new-password"
            />

            <PasswordHelper
              rules={rules.password}
              errors={errors}
              customTranslationId="passwordContainsName"
            />

            <SubscribedPasswordField
              name="passwordConfirm"
              labelId="InvitationRedeem.RegistrationForm.passwordConfirm"
              type="password"
              autoComplete="new-password"
            />

            <Button isSubmit disabled={invalid} type="primary" size="medium">
              <Text id="InvitationRedeem.RegistrationForm.createAccount" />
            </Button>
          </form>
        )}
      />
    );
  }
}
