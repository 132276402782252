//@flow

import * as React from 'react';
import map from 'lodash/map';
// import Asset from '@kwara/components/src/Asset';

import { type SubStepComponentProps } from '../../../components/Wizard';

export default ({
  Condition,
  RadioGroup,
  TextField,
  StackChild,
  data = {}
}: SubStepComponentProps<>) => {
  return (
    <StackChild>
      {map(data.idDocuments, (field, idx) => {
        const name = `idDocuments[${idx}]`;

        return (
          <React.Fragment key={field.id + idx}>
            <RadioGroup
              name={`${name}.type`}
              items={[
                {
                  labelId: 'AddMember.Identity.Type.NATIONAL',
                  value: 'NATIONAL'
                },
                {
                  labelId: 'AddMember.Identity.Type.PASSPORT',
                  value: 'PASSPORT'
                }
              ]}
            />
            <Condition when={`${name}.type`} is="NATIONAL">
              <div>
                <TextField
                  name={`idDocuments[${idx}].documentId`}
                  placeholderId="AddMember.Identity.Value.NATIONAL.placeholder"
                />
                {/* <Asset col="amber700" id="Check" />
                <Asset id={Asset.Glyphs.Spinner} col={Asset.Colours.grey300} /> */}
              </div>
            </Condition>

            <Condition when={`${name}.type`} is="PASSPORT">
              <TextField
                name={`idDocuments[${idx}].documentId`}
                placeholderId="AddMember.Identity.Value.PASSPORT.placeholder"
              />
            </Condition>
          </React.Fragment>
        );
      })}
    </StackChild>
  );
};
