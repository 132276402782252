import * as React from 'react';

import pipe from 'lodash/fp/pipe';
import last from 'lodash/fp/last';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import toLower from 'lodash/fp/toLower';
import initial from 'lodash/fp/initial';
import toPairs from 'lodash/fp/toPairs';
import filter from 'lodash/fp/filter';
import join from 'lodash/fp/join';
import first from 'lodash/fp/first';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { Text } from '@kwara/components/src/Intl';
import Button, { ActionButton } from '@kwara/components/src/Button';
import { Statistic } from '@kwara/components/src/Statistic';

import { ActionableHeading } from '../../../components/OverviewList';
import { ActionModal, Panel }from '../../../components/ActionModal';

import { useProduct } from './utils';

const toHumanReadablePerm = (permObj = {}) => {
  const permsArr = pipe(
    toPairs,
    filter(([, value]) => value),
    map(first)
  )(permObj);
  const length = permsArr.length;

  if (!length) {
    return '-';
  }

  if (length === 1) {
    return `can only ${toLower(permsArr[0])}`;
  }

  const commaSeparated = pipe(
    initial,
    map(toLower),
    join(', ')
  )(permsArr);

  return `can ${commaSeparated} and ${toLower(last(permsArr))}`;
};

const permsListByName = (name, perms) =>
  pipe(
    get(name),
    toHumanReadablePerm
  )(perms);

const Role = ({ role, onEdit, onRemove, idx }) => (
  <>
    <header className="flex justify-between mt1">
      <h3>{role.name}</h3>
      <div className="flex">
        <ActionButton
          className="mr2"
          type="delete"
          col="red500"
          onClick={onRemove}
        />
        <ActionButton onClick={() => onEdit(idx)} type="edit" col="black" />
      </div>
    </header>
    <div className="flex justify-between">
      <Statistic
        containerClassName="w-50"
        title={<Text id="Onboarding.Roles.Perm.Members" />}
        value={permsListByName('members', role.permissions)}
      />
      <Statistic
        containerClassName="w-50"
        title={<Text id="Onboarding.Roles.Perm.Savings" />}
        value={permsListByName('savings', role.permissions)}
      />
    </div>
    <div className="flex justify-between bb b--light-gray mb4">
      <Statistic
        containerClassName="w-50"
        title={<Text id="Onboarding.Roles.Perm.Loans" />}
        value={permsListByName('loans', role.permissions)}
      />
      <Statistic
        containerClassName="w-50"
        title={<Text id="Onboarding.Roles.Perm.Sacco" />}
        value={permsListByName('sacco', role.permissions)}
      />
    </div>
  </>
);

function Modal({ formProps, idx, setModalState, TextField, Checkbox }) {
  const role = `roles[${idx}]`;
  return (
    <ActionModal
      actions
      titleId="Onboarding.Roles.ModalAdd.Title"
      onConfirm={() => {
        formProps.form.submit();
        formProps.form.change(formProps.form.getState());
        setModalState(false);
      }}
      onCancel={() => {
        formProps.form.reset();
        setModalState(false);
      }}
    >
      >
      <Panel>
        <h2 className="mt1 mb3 kw-text-large grey-400">
          <Text id="Onboarding.Roles.ModalAdd.Title" />
        </h2>
        <TextField
          name={`${role}.name`}
          errorBaseId="Onboarding.Roles.name"
          labelId="Onboarding.Roles.name.label"
        />
      </Panel>
      {['members', 'savings', 'loans'].map(entity => (
        <Panel key={entity}>
          <h2 className="mt1 mb3 kw-text-large grey-400">
            <Text id={`Onboarding.Roles.${entity}.title`} />
          </h2>
          <Checkbox
            name={`${role}.permissions.${entity}.add`}
            labelId="Onboarding.Roles.Permissions.add.label"
          />
          <Checkbox
            name={`${role}.permissions.${entity}.edit`}
            labelId="Onboarding.Roles.Permissions.edit.label"
          />
          <Checkbox
            name={`${role}.permissions.${entity}.review`}
            labelId="Onboarding.Roles.Permissions.review.label"
          />
        </Panel>
      ))}
      <Panel>
        <h2 className="mb3 mb0 kw-text-large grey-400">
          <Text id={`Onboarding.Roles.sacco.title`} />
        </h2>
        <Checkbox
          name={`${role}.permissions.sacco.editOrganisation`}
          labelId="Onboarding.Roles.Permissions.editOrganasition.label"
        />
        <Checkbox
          name={`${role}.permissions.sacco.addNewUsers`}
          labelId="Onboarding.Roles.Permissions.addNewUsers.label"
        />
        <Checkbox
          name={`${role}.permissions.sacco.addNewSavings`}
          labelId="Onboarding.Roles.Permissions.addNewSavings.label"
        />
        <Checkbox
          name={`${role}.permissions.sacco.addNewLoans`}
          labelId="Onboarding.Roles.Permissions.addNewLoans.label"
        />
      </Panel>
    </ActionModal>
  );
}

export function Roles({
  StackChild,
  data,
  addData,
  onChange,
  TextField,
  Checkbox,
  formProps
}) {
  const {
    isOpen,
    setModalState,
    index,
    addProduct,
    editProduct,
    removeProduct
  } = useProduct(data.roles);

  return (
    <>
      <StackChild>
        {isOpen ? (
          <Modal
            idx={index}
            data={data}
            addData={addData}
            {...{
              TextField,
              setModalState,
              Checkbox,
              formProps
            }}
          />
        ) : null}
      </StackChild>
      <StackChild size="regular">
        <ActionableHeading
          headerId="Onboarding.Roles.ActionsTitle"
          subheading={
            <>
              <span className="kw-text-regular grey-400">
                <Text id="Onboarding.Roles.ActionsSubtitle" />
              </span>
            </>
          }
          actions={[
            <Button key="add" onClick={addProduct}>
              <span className="nowrap">
                <Text id="Onboarding.Roles.AddRolesMessage" />
              </span>
            </Button>
          ]}
        />
        {mapIndexed(
          (role, idx) => (
            // Note that this assumes that some validation around uniqueness of names will be enforced
            // which is not the case atm
            <Role
              key={role.name}
              role={role}
              idx={idx}
              onRemove={() =>
                onChange({
                  roles: removeProduct(idx)
                })
              }
              onEdit={editProduct}
            />
          ),
          data.roles
        )}
      </StackChild>
    </>
  );
}
