// @flow

import * as React from 'react';
import cx from 'classnames';

import zIndices from '@kwara/lib/src/zIndices';

import styles from './index.module.scss';

type Props = {
  children: React.Node
};

export const InfoPanel = ({ children }: Props) => {
  if (!children) {
    return null;
  }

  return (
    <span className={styles.Wrapper}>
      <span>
        <span className={cx('ba br-100', styles.Icon)}>i</span>
      </span>
      <div
        className={cx('white br3 pa2', styles.InfoPanel, zIndices.OverlayMenus)}
      >
        {children}
      </div>
    </span>
  );
};
