import * as React from 'react';
import cx from 'classnames';
import map from 'lodash/fp/map';

import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

import styles from './index.module.scss';

export const SideNav = ({
  active,
  modules,
  pathFn,
  translationBase
}: {
  active: string,
  modules: string[],
  pathFn: Function,
  translationBase: string
}) => {
  return (
    <nav className={styles.Nav}>
      <ul className="list pl0">
        {map(
          m => (
            <li key={m} className="mb3 ">
              <Link
                to={pathFn({ action: m })}
                className={cx({
                  'kw-weight-bold': m === active,
                  'grey-400': m !== active,
                  'no-underline': true
                })}
              >
                <Text id={`${translationBase}.${m}`} />
              </Link>
            </li>
          ),
          modules
        )}
      </ul>
    </nav>
  );
};
