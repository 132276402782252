// @flow

import * as React from 'react';
import getOr from 'lodash/fp/getOr';
import reject from 'lodash/fp/reject';
import split from 'lodash/fp/split';

import { isEmpty } from '@kwara/lib/src/lodash';
import { Date, Text, Time } from '@kwara/components/src/Intl';
import { type SavingsTransactionType } from '@kwara/models/src';
import Banner from '@kwara/components/src/Banner';

import { savingPath } from '../../../lib/urls';
import { type MemberPageProps } from '../..';
import { AccountList } from './AccountList';
import { SavingsAccountCard } from './SavingsAccountCard';
import DetailSubsection from '../../../components/DetailSubsection';
import { Cell, Heading, Row } from '../../../components/Table';
import TransactionsTable from '../../../components/TransactionsTable';
import { Currency } from '../../../components/Currency';

import styles from './Savings.module.css';

type Props = MemberPageProps & {};

function by(transaction) {
  const { createdBy } = transaction;
  const left = split('(', createdBy)[1];
  return split(')', left)[0];
}

export default class Savings extends React.Component<Props, *> {
  renderRow(transaction: SavingsTransactionType) {
    // TODO: This is temporary until ch14895 is actioned
    //
    const standard = (
      <>
        <span>{transaction.paymentMethod}</span>{' '}
        <Text id={`TransactionType.${transaction.type}`} />
        <div className="grey-300">
          {transaction.createdBy ? ` by ${by(transaction)}` : ''}
        </div>
      </>
    );

    const gl = <span>{transaction.glType()}</span>;

    return (
      <Row key={transaction.id}>
        <Cell>
          <div className="grey-300">{transaction.id}</div>
          <div>
            <Date value={transaction.enteredAt} />
          </div>
          <div>
            <Time value={transaction.enteredAt} />
          </div>
        </Cell>
        <Cell>
          <div className="grey-300">{transaction.account.id}</div>
          <div>{transaction.account.product.name}</div>
        </Cell>
        <Cell>
          <div className="grey-300">{transaction.reference}</div>
          {transaction.isGlChannel ? gl : standard}
          {transaction.notes && (
            <div className="grey-300">{transaction.notes}</div>
          )}
        </Cell>
        <Cell align="right">
          <Currency value={transaction.amount} />
        </Cell>
        <Cell align="right">
          <Currency value={transaction.balance} />
        </Cell>
        <Cell />
      </Row>
    );
  }

  render() {
    const { member, showClosedSavings } = this.props;

    const accounts = showClosedSavings
      ? member.savings
      : reject(saving => saving.isClosed(), member.savings);

    const transactions = accounts
      .map(saving =>
        saving.transactions.map(transaction => {
          transaction.account = saving;
          return transaction;
        })
      )
      .flatten();

    const heading = (
      <Row>
        <Heading translationId="TransactionsTable.date" />
        <Heading
          className={styles.AccountColumn}
          translationId="TransactionsTable.account"
        />
        <Heading className="w-25" translationId="TransactionsTable.type" />
        <Heading align="right" translationId="TransactionsTable.amount" />
        <Heading align="right" translationId="TransactionsTable.balance" />
        <Heading iconSpacer />
      </Row>
    );

    return (
      <>
        <Banner
          text={getOr('', 'firstName', member) + ' saves frequently.'}
          hidden={isEmpty(accounts)}
        />
        <DetailSubsection title={<Text id="MemberDetail.accounts" />}>
          <AccountList
            accounts={accounts}
            columns={3}
            width="w-33"
            renderAccount={account => (
              <SavingsAccountCard
                key={account.id}
                account={account}
                to={savingPath({ id: account.id })}
              />
            )}
          />
        </DetailSubsection>
        <DetailSubsection title={<Text id="MemberDetail.transactions" />}>
          <TransactionsTable
            heading={heading}
            renderRow={this.renderRow}
            transactions={transactions}
            maximumEntries={100}
          />
        </DetailSubsection>
      </>
    );
  }
}
