// @flow

import Base, { BaseModel } from './Base';

export const SupervisorMetric = Base.extend({
  static: {
    jsonapiType: 'supervisor_metrics'
  },
  attrs: {},
  methods: {}
});

type Metric = {};

export interface SupervisorMetricT
  extends BaseModel<SupervisorMetricT>,
    Array<Metric> {}
