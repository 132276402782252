//@flow
import * as React from 'react';
import concat from 'lodash/concat';

import { Text } from '@kwara/components/src/Intl';
import {
  type MemberType,
  Guarantor as GuarantorModel
} from '@kwara/models/src';

import { Header } from '../Header';
import { GuarantorList } from './GuarantorList';
import { GuarantorSearch } from './GuarantorSearch';
import { type LoanSubstepProps } from '../../..';
import { useListEditor } from '../../../../../lib/hooks';

export const Guarantor = ({
  formProps,
  data,
  StackChild,
  onChange
}: LoanSubstepProps) => {
  const { product } = data;

  const basePath = 'guarantors';
  const { isEditing, setEdit, addItem, setView } = useListEditor(
    data.guarantors,
    basePath
  );

  const onAddMember = async (member: MemberType) => {
    const inst = new GuarantorModel({ member, memberId: member.id });
    await onChange({
      guarantors: concat(data.guarantors || [], inst)
    });
    setView();
  };
  if (!product.hasGuarantorsEnabled()) {
    return (
      <StackChild>
        <div className="pb4 flex justify-between items-center">
          <p className="ma0">
            <Text id="LoanAdd.Security.Guarantor.disabled" />
          </p>
        </div>
      </StackChild>
    );
  }

  return (
    <>
      <Header
        titleId="LoanAdd.Security.Guarantor.title"
        subtitleId="LoanAdd.Security.Guarantor.subtitle"
        buttonTextId="LoanAdd.Security.Guarantor.add"
        enabled={true}
        StackChild={StackChild}
        isSearching={isEditing}
        onAdd={() => {
          addItem();
          setEdit();
        }}
        memberName={data.member.fullName()}
        data={data}
      />
      {isEditing ? (
        <GuarantorSearch
          onSelect={onAddMember}
          StackChild={StackChild}
          formProps={formProps}
          data={data}
          onCancel={setView}
        />
      ) : (
        <GuarantorList
          StackChild={StackChild}
          guarantors={data.guarantors}
          formProps={formProps}
        />
      )}
    </>
  );
};
