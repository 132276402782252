// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { InfoPanel } from '@kwara/components/src/InfoPanel';
import { type LoanProductType, type LoanType } from '@kwara/models/src';
import { Panel } from '../../../components/ActionModal';
import { Percent, Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { Currency } from '../../../components/Currency';
import { LoansPanel } from '../../../components/LoansPanel';

type Props = {
  product: LoanProductType,
  loan: LoanType
};

export default ({ product, loan }: Props) => {
  const { loanApplication } = loan;
  const payOffLoans = get('payOffLoans', loanApplication);

  return (
    <Panel>
      <StatisticRow widthClass="w-50" border={false}>
        <Statistic
          title={<Text id="LoanProductPanel.loan" />}
          value={product.name}
          size="large"
          padding={false}
        />
        <Statistic
          title={<Text id="LoanProductPanel.interestRate" />}
          size="medium"
          value={<Percent value={loan.interestRatePercent()} />}
          padding={false}
        />
      </StatisticRow>

      <StatisticRow widthClass="w-50" border={true}>
        <Statistic
          title={<Text id="LoanProductPanel.principal" />}
          size="medium"
          value={<Currency value={loan.amount} />}
        />
        <Statistic
          title={
            <>
              <Text id="LoanProductPanel.payOffLoans" />{' '}
              <InfoPanel>
                <LoansPanel
                  loans={payOffLoans}
                  noLoans={<Text id="LoanProductPanel.noLoans" />}
                />
              </InfoPanel>
            </>
          }
          size="medium"
          value={
            loanApplication ? (
              <Currency value={loanApplication.totalPayOffLoans()} />
            ) : null
          }
        />
      </StatisticRow>
    </Panel>
  );
};
