import { Recipient } from './components/Recipient';
import { Account } from './components/Account';
import { Amount } from './components/Amount';
import { Summary } from './components/Summary';

export const steps = {
  recipient: {
    titleId: 'MakeTransfer.Recipient.title',
    Component: Recipient,
    validate: Recipient.validate,
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'account' }]
  },
  account: {
    titleId: 'MakeTransfer.Account.title',
    Component: Account,
    validate: Account.validate,
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'amount' }]
  },
  amount: {
    titleId: 'MakeTransfer.Amount.title',
    Component: Amount,
    validate: Amount.validate,
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'summary' }]
  },
  summary: {
    titleId: 'MakeTransfer.Summary.title',
    Component: Summary,
    actions: [{ appearsAs: 'submit', behavesAs: 'complete' }]
  }
};
