// @flow

import * as React from 'react';
import { observer } from 'mobx-react';

import { type UserT } from '@kwara/models/src';
import { PermissionDenied } from '@kwara/components/src/PermissionDenied';

import { settingPath } from '../../lib/urls';
import { Activity } from './components/Activity';
import { ComingSoon } from './components/ComingSoon';
import { Data } from './components/Data';
import { Events } from './components/Events';
import { Savings } from './components/SavingProducts';
import { LoanProducts } from './components/LoanProducts';
import { LoanApproval } from './components/LoanApproval';
import { Personal } from './components/Personal';
import { Team } from './components/Team';
import { Workspace } from './components/Workspace';
import { AppRoles } from './components/AppRoles';
import { Access } from './components/Access';
import { Billing } from './components/Billing';

import permission, { AppPermissions } from '../../models/Permission';
import { SideNavContainer } from '../../components/SideNavContainer';
import { SideNav } from '../../components/SideNav';
import { ProfileContext } from '../../models/Profile';

type Props = {
  match: {
    params: {
      module: string
    }
  }
};

function getSettings(user: UserT) {
  return [
    'general',
    // 'data',
    'loans',
    'loanApproval',
    ...(permission.to(AppPermissions.ViewInvitations) ? ['workspace'] : []),
    'activity',
    ...(user.isAdmin ? ['team'] : []),
    ...(permission.to(AppPermissions.ViewOrgActivityFeed) ? ['events'] : []),
    // 'savings',
    // 'billing',
    ...(user.isAdmin ? ['access'] : []),
    ...(user.isAdmin ? ['rolesPermissions'] : []),
    ...(user.isAdmin ? ['billing'] : [])
  ];
}

function componentSwitch(activeModule: string, user: UserT) {
  switch (activeModule) {
    case 'activity':
      return <Activity />;
    case 'data':
      return <Data />;
    case 'events':
      return permission.to(AppPermissions.ViewOrgActivityFeed) ? (
        <Events />
      ) : (
        <PermissionDenied />
      );
    case 'savings':
      return <Savings />;
    case 'loans':
      return <LoanProducts />;
    case 'loanApproval':
      return <LoanApproval />;
    case 'general':
      return <Personal />;
    case 'billing':
      return <Billing />;
    case 'team':
      return user.isAdmin ? <Team /> : <PermissionDenied />;
    case 'workspace':
      return permission.to(AppPermissions.ViewInvitations) ? (
        <Workspace />
      ) : (
        <PermissionDenied />
      );
    case 'rolesPermissions':
      return user.isAdmin ? <AppRoles /> : <PermissionDenied />;
    case 'access':
      return user.isAdmin ? <Access /> : <PermissionDenied />;
    default:
      return <ComingSoon />;
  }
}

const SideMenu = ({ active, user }: { active: string, user: UserT }) => {
  return (
    <SideNav
      active={active}
      modules={getSettings(user)}
      pathFn={settingPath}
      translationBase="Settings"
    />
  );
};

export const Settings = observer((props: Props) => {
  const { profile } = React.useContext(ProfileContext);
  const active = props.match.params.module || 'general';

  return (
    <SideNavContainer
      sideMenu={<SideMenu active={active} user={profile} />}
      activeModule={componentSwitch(active, profile)}
    />
  );
});
