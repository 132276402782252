// @flow

import get from 'lodash/fp/get';
import lt from 'lodash/fp/lt';
import compact from 'lodash/fp/compact';
import pipe from 'lodash/fp/pipe';
import size from 'lodash/fp/size';
import map from 'lodash/fp/map';
import reject from 'lodash/fp/reject';
import includes from 'lodash/fp/includes';
import some from 'lodash/fp/some';

import { EmploymentStatuses, type MemberType } from '@kwara/models/src';
import {
  attachmentNames,
  type AttachmentNameT
} from '@kwara/models/src/models/Attachment';

import { store } from '../../../../models/Store';
import { type ValidateMemberT } from './types';

export function hasMissingValues(member: MemberType, fields: string[]) {
  return pipe(
    map(path => get(path, member)),
    compact,
    size,
    s => lt(s, size(fields))
  )(fields);
}

function hasMissingEmploymentValues(member: MemberType) {
  if (member.employmentStatus === EmploymentStatuses.SELF_EMPLOYED) {
    return hasMissingValues(member, [
      'workEmail',
      'grossIncome',
      'netIncome',
      'otherDeductibles'
    ]);
  }

  if (member.employmentStatus === EmploymentStatuses.EMPLOYED) {
    return hasMissingValues(member, [
      'employerEmail',
      'employerPhoneNumber',
      'workEmail',
      'grossIncome',
      'netIncome',
      'otherDeductibles',
      'termsOfService'
    ]);
  }

  if (member.employmentStatus === EmploymentStatuses.OTHER) {
    return hasMissingValues(member, [
      'grossIncome',
      'netIncome',
      'otherDeductibles',
      'incomeSource'
    ]);
  }

  return true;
}

// Every first level item is required.
// If an item contains more than one document name only one of them is considered required
// For ex. either Id Document or Passport must be there.
// If none of them is present this will be marked as missing.
export const requiredDocs: AttachmentNameT[][] = [
  [attachmentNames.memberSignature],
  [attachmentNames.salarySlip],
  [attachmentNames.idDocument, attachmentNames.passport]
];
export function getMissingAttachments(member: MemberType): AttachmentNameT[][] {
  const memberAttachmentNames = pipe(
    get('attachments'),
    map('name')
  )(member);

  return pipe(
    reject(entry =>
      some(e => {
        return includes(e, memberAttachmentNames);
      }, entry)
    ),
    compact
  )(requiredDocs);
}

export function validateMember(member: MemberType): ValidateMemberT {
  if (!member) {
    return {};
  }

  // TODO: Disabled due to this ticket
  // https://app.clubhouse.io/getkwara/story/12087/disable-the-checks-for-profile-completion-on-the-loan-application-process-kbs-prod-instance
  const shouldDisableValidation = store.isKBS;
  if (shouldDisableValidation) {
    return {};
  }

  return {
    personalDetails: {
      isRequired: () =>
        hasMissingValues(member, ['firstName', 'lastName', 'email'])
    },
    identity: {
      isRequired: () => hasMissingValues(member, ['idDocuments[0]', 'kraPin'])
    },
    nextOfKin: {
      isRequired: () => hasMissingValues(member, ['kin[0]'])
    },
    employment: {
      isRequired: () => hasMissingEmploymentValues(member)
    },
    bank: {},
    documents: {
      isRequired: () => size(getMissingAttachments(member)) > 0
    }
  };
}
