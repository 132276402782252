// @flow

import { attr } from 'spraypaint';

import Base from './Base';

export const BranchAddress = Base.extend({
  static: {
    jsonapiType: 'branch_addresses'
  },
  attrs: {
    line1: attr(),
    line2: attr(),
    postalAddress: attr(),
    physicalAddress: attr(),
    city: attr(),
    postcode: attr(),
    region: attr(),
    country: attr()
  },
  methods: {}
});

export type BranchAddressT = {
  line1: string,
  line2: string,
  postalAddress: string,
  physicalAddress: string,
  city: string,
  postcode: string,
  region: string,
  country: string
};
