//@flow
import * as React from 'react';
import classnames from 'classnames';
import { injectIntl, type IntlShape } from 'react-intl';
import noop from 'lodash/noop';

import Asset, { getAssetDataUrlById } from '@kwara/components/src/Asset';
import { getTranslation, type TranslationId } from '@kwara/components/src/Intl';

import styles from './Select.module.scss';

type BaseOptionProps = {|
  intl: IntlShape,
  value: string,
  disabled?: boolean,
  values?: {
    [k: string]: string
  }
|};

type OptionWithChildrenProps = {|
  ...BaseOptionProps,
  children?: React.ChildrenArray<string>
|};

type OptionWithTranslationIdProps = {|
  ...BaseOptionProps,
  translationId: TranslationId
|};

type OptionProps = OptionWithChildrenProps | OptionWithTranslationIdProps;

const InnerOption = ({ disabled = false, ...props }: OptionProps) => {
  if (props.translationId) {
    return (
      <option disabled={disabled} value={props.value}>
        {getTranslation(props.intl, props.translationId, props.values)}
      </option>
    );
  }

  // The duplicate return is required to make flow's disjoint union
  // work correctly
  if (props.children) {
    return (
      <option disabled={disabled} value={props.value}>
        {props.children}
      </option>
    );
  }

  return (
    <option disabled={disabled} value={props.value}>
      {props.children}
    </option>
  );
};

export const Option = injectIntl(InnerOption);

type Props = {
  disabled?: boolean,
  error?: boolean,
  inline?: boolean,
  value: string,
  name: string,
  size: 'regular' | 'medium',
  children: React.ChildrenArray<React.Element<typeof Option>>,
  onChange: (evt: { target: { value: string } }) => void,
  inputOnChange?: (event: SyntheticEvent<HTMLSelectElement>) => void,
  inputOnBlur?: (event: SyntheticEvent<HTMLSelectElement>) => void,
  onBlur?: (evt: { target: { value: string } }) => void
};

export const Select = ({
  inline = false,
  disabled,
  error,
  name,
  size,
  value,
  onBlur = () => {},
  onChange,
  inputOnChange = noop,
  inputOnBlur = noop,
  children
}: Props) => {
  const classes = [
    styles.Select,
    styles[size],
    `dib kw-text-${size}`,
    disabled ? styles.isDisabled : '',
    error ? styles.isError : '',
    inline ? '' : 'w-100',
    inline ? styles.isInline : styles.isNotInline
  ];

  const icon = inline
    ? `url(${getAssetDataUrlById(Asset.Glyphs.Dropdown)})`
    : `url(${getAssetDataUrlById(Asset.Glyphs.DoubleArrow)})`;

  return (
    <select
      className={classnames(classes)}
      style={{ backgroundImage: icon }}
      disabled={disabled}
      id={name}
      name={name}
      value={value}
      // Allows a custom onChange event in addition to the
      // "built in" one from react-final-form
      // https://jetrockets.pro/blog/0q1tcsfda0-custom-onchange-in-react-final-form
      onChange={e => {
        onChange(e);
        inputOnChange(e);
      }}
      onBlur={e => {
        onBlur(e);
        inputOnBlur(e);
      }}
    >
      {children}
    </select>
  );
};

Select.Option = Option;
