import { Submit } from './components/Submit';

export const steps = {
  submit: {
    titleId: 'CreditSubmit.Submit.title',
    subtitleId: 'CreditSubmit.Submit.subtitle',
    Component: Submit,
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ]
  }
};
