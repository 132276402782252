// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { Loadable } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';
import { memberPath } from '../../lib/urls';

import { steps } from './steps';

import { type MemberPageProps } from '..';
import { addDeposit } from './components/DepositForm';

type Props = MemberPageProps;

const includes = [{ savings: 'product' }];

export const MemberDeposit = (props: Props) => {
  const { history } = props;
  const r = useMember(props.match.params.memberId, includes);

  return (
    <Loadable {...r}>
      {member => {
        return member.savingsEligibleForTransactions().length > 0 ? (
          <Wizard
            analyticsID="SavingPayment"
            baseUrl={memberPath({ id: member.id })}
            history={history}
            initialData={{
              member,
              accounts: member.savingsEligibleForTransactions(),
              accountId: member.savingsEligibleForTransactions()[0].id
            }}
            cancelReturnsTo={memberPath({ id: member.id })}
            currentStep="deposit"
            onSubmit={addDeposit}
            steps={steps}
            startId="deposit"
            titleId="SavingPayment.title"
          />
        ) : (
          <Redirect to={memberPath({ id: member.id })} />
        );
      }}
    </Loadable>
  );
};
