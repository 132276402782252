//@flow
import * as React from 'react';

import { type SubStepComponentProps } from '../../../components/Wizard';

import { type WizardData } from '..';

const Reject = ({
  RadioGroup,
  StackChild,
  TextArea
}: SubStepComponentProps<WizardData>) => {
  return (
    <StackChild>
      <RadioGroup
        flex={false}
        name="rejectType"
        items={[
          {
            labelId: 'MemberApprove.Reject.Type.soft.label',
            titleId: 'MemberApprove.Reject.Type.soft.title',
            value: 'SOFT'
          },
          {
            labelId: 'MemberApprove.Reject.Type.hard.label',
            titleId: 'MemberApprove.Reject.Type.hard.title',
            value: 'HARD'
          }
        ]}
      />

      <TextArea
        name="comment"
        labelId="MemberApprove.Reject.Note.label"
        infoId="MemberApprove.Reject.Note.info"
      />
    </StackChild>
  );
};

Reject.validate = {
  rejectType: {
    isRequired: () => true
  }
};

export default Reject;
