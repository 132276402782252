export const passwordBaseRules = {
  isRequired: () => true,
  minlength: 10,
  containsAlphaNum: true,
  hasLowerCase: true,
  hasUpperCase: true,
  requiresSpecialCharacter: true
};

export const passwordConfirmBaseRules = {
  isRequired: () => true,
  custom: function(passwordConfirm, allData) {
    if (passwordConfirm !== allData.password) {
      return 'passwordMismatch';
    }
    return null;
  }
};
