import * as React from 'react';
import map from 'lodash/map';
import isEmpty from 'lodash/fp/isEmpty';

import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';

import { TableActions } from './shared';

import Table, {
  Cell,
  Collapsible,
  Heading,
  Row
} from '../../../components/Table';
import { ActionableHeading } from '../../../components/OverviewList';
import { ActionModal, Panel }from '../../../components/ActionModal';
import { useProduct } from './utils';

function Modal({ idx, setModalState, TextField, SelectField, formProps }) {
  const product = `savingsProducts[${idx}]`;
  return (
    <ActionModal
      actions
      titleId="Onboarding.SavingsProducts.ModalAdd.Title"
      onConfirm={() => {
        formProps.form.submit();
        formProps.form.change(formProps.form.getState());
        setModalState(false);
      }}
      onCancel={() => {
        formProps.form.reset();
        setModalState(false);
      }}
    >
      <Panel>
        <h2 className="mt1 mb3 kw-text-large grey-400">
          <Text id="Onboarding.SavingsProducts.Name.Title" />
        </h2>
        <TextField
          name={`${product}.name`}
          errorBaseId="Onboarding.SavingsProducts.Name"
          labelId="Onboarding.SavingsProducts.Name.label"
        />

        <div className="flex justify-between">
          <div className="w-50 pr2">
            <TextField
              className="w-50"
              name={`${product}.minimumOpeningBalance`}
              min={0}
              type="number"
              labelId="Onboarding.SavingsProducts.MinBalance.label"
              placeholderId="Onboarding.SavingsProducts.MinBalance.placeholder"
            />
          </div>
          <div className="w-50 pl2">
            <TextField
              className="w-50"
              name={`${product}.defaultInterestRate`}
              min={0}
              type="number"
              labelId="Onboarding.SavingsProducts.InterestRate.label"
              placeholderId="Onboarding.SavingsProducts.InterestRate.placeholder"
            />
          </div>
        </div>
      </Panel>

      <Panel>
        <h2 className="mt1 mb3 kw-text-large grey-400">
          <Text id="Onboarding.SavingsProducts.DefaultSettings.Title" />
        </h2>
        <div className="flex">
          <TextField
            className="w-50"
            name={`${product}.defaultInterestChargeFrequencyCount`}
            type="number"
            labelId="Onboarding.SavingsProducts.defaultInterestChargeFrequencyCount.label"
          />
          <div className="ml3 pt3 mt3">
            <SelectField
              className="w-50 pl2"
              name={`${product}.defaultInterestChargeFrequency`}
            >
              <SelectField.Option translationId="-" value="" />
              <SelectField.Option
                translationId="Onboarding.SavingsProducts.defaultInterestChargeFrequency.month"
                value="EVERY_MONTH"
              />
              <SelectField.Option
                translationId="Onboarding.SavingsProducts.defaultInterestChargeFrequency.year"
                value="ANNUALIZED"
              />
            </SelectField>
          </div>
        </div>

        <div className="flex">
          <TextField
            className="w-50"
            name={`${product}.defaultMaturityPeriodCount`}
            type="number"
            labelId="Onboarding.SavingsProducts.defaultMaturityPeriodCount.label"
          />
          <div className="ml3 pt3 mt3">
            <SelectField
              className="w-50 pl2"
              name={`${product}.defaultMaturityPeriodUnit`}
            >
              <SelectField.Option translationId="-" value="" />
              <SelectField.Option
                translationId="Onboarding.SavingsProducts.defaultMaturityPeriodUnit.month"
                value="WEEKS"
              />
              <SelectField.Option
                translationId="Onboarding.SavingsProducts.defaultMaturityPeriodUnit.year"
                value="MONTHS"
              />
            </SelectField>
          </div>
        </div>
      </Panel>
    </ActionModal>
  );
}

export function SavingsProducts({
  StackChild,
  TextField,
  Checkbox,
  SelectField,
  RadioGroup,
  formProps,
  data,
  onChange
}) {
  const {
    isOpen,
    setModalState,
    index,
    addProduct,
    editProduct,
    removeProduct
  } = useProduct(data.savingsProducts);

  return (
    <>
      <StackChild>
        {isOpen ? (
          <Modal
            idx={index}
            {...{
              TextField,
              setModalState,
              SelectField,
              Checkbox,
              RadioGroup,
              formProps
            }}
          />
        ) : null}

        {isEmpty(data.savingsProducts) ? (
          <Empty img="triangle">
            <div className="flex flex-column">
              <p>
                <Text id="Onboarding.SavingsProducts.NoProductsMessage" />
              </p>
              <div className="mb2">
                <Button onClick={addProduct} type="primary">
                  <Text id="Onboarding.SavingsProducts.AddProductsMessage" />
                </Button>
              </div>
            </div>
          </Empty>
        ) : null}
      </StackChild>
      {isEmpty(data.savingsProducts) ? null : (
        <StackChild size="medium">
          <ActionableHeading
            headerId="Onboarding.Step.SavingsProducts.title"
            actions={[
              <Button key="0" onClick={addProduct}>
                <Text id="Onboarding.SavingsProducts.AddProductsMessage" />
              </Button>
            ]}
          />
          <Table
            className="mb5"
            heading={
              <Row>
                <Heading>
                  <Text id="Onboarding.SavingsProducts.Table.Heading.Name" />
                </Heading>
                <Heading>
                  <Text id="Onboarding.SavingsProducts.Table.Heading.InterestRate" />
                </Heading>
                <Heading>
                  <Text id="Onboarding.SavingsProducts.Table.Heading.MinimumOpeningBalance" />
                </Heading>
              </Row>
            }
          >
            {map(data.savingsProducts, (product, idx) => (
              <Collapsible key={product.name} addIcon={false}>
                <Row>
                  <Cell>{product.name}</Cell>
                  <Cell>{product.interestRate}</Cell>
                  <Cell className="relative">
                    <TableActions
                      onEdit={() => editProduct(idx)}
                      onRemove={() =>
                        onChange({ savingsProducts: removeProduct(idx) })
                      }
                    />
                  </Cell>
                </Row>
              </Collapsible>
            ))}
          </Table>
        </StackChild>
      )}
    </>
  );
}
