//@flow
import * as React from 'react';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/fp/isEmpty';
import invoke from 'lodash/fp/invoke';
import get from 'lodash/fp/get';

import { DocumentList } from '@kwara/components/src/DocumentList';
import { InfoPanel } from '@kwara/components/src/InfoPanel';
import Banner from '@kwara/components/src/Banner';
import { Duration, Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Bank as BankName } from '@kwara/components/src/Bank';

import { Card } from '../../../components/Card';
import { Grid } from '../../../components/Grid';
import { Note } from '../../../components/Note';
import { Currency } from '../../../components/Currency';
import { type SubStepComponentProps } from '../../../components/Wizard';
import { LoanEligibilityPanel } from '../../../components/LoanEligibilityPanel';
import { SavingsPanel } from '../../../components/SavingsPanel';
import { LoansPanel } from '../../../components/LoansPanel';

const CollateralPanel = ({ collateral }) => {
  const {
    assetBankAccount,
    assetBankBranch,
    assetBankName,
    collateralNarration,
    insuranceCompany,
    insurancePolicyNumber,
    landRegistration,
    nseStockCds
  } = collateral;

  return (
    <Card className="nowrap tl">
      {landRegistration ? (
        <Statistic
          compact
          size="tiny"
          title={
            <Text id="LoanApprove.Overview.security.CollateralPanel.landRegistration" />
          }
          value={landRegistration}
        />
      ) : null}

      {nseStockCds ? (
        <Statistic
          compact
          size="tiny"
          title={
            <Text id="LoanApprove.Overview.security.CollateralPanel.nseStocksCds" />
          }
          value={nseStockCds}
        />
      ) : null}

      {assetBankName ? (
        <Statistic
          compact
          size="tiny"
          title={
            <Text id="LoanApprove.Overview.security.CollateralPanel.bankName" />
          }
          value={<BankName bankCode={assetBankName} />}
        />
      ) : null}

      {assetBankBranch ? (
        <Statistic
          compact
          size="tiny"
          title={
            <Text id="LoanApprove.Overview.security.CollateralPanel.bankBranch" />
          }
          value={assetBankBranch}
        />
      ) : null}

      {assetBankAccount ? (
        <Statistic
          compact
          size="tiny"
          title={
            <Text id="LoanApprove.Overview.security.CollateralPanel.accountNumber" />
          }
          value={assetBankAccount}
        />
      ) : null}

      {insuranceCompany ? (
        <Statistic
          compact
          size="tiny"
          title={
            <Text id="LoanApprove.Overview.security.CollateralPanel.insuranceName" />
          }
          value={insuranceCompany}
        />
      ) : null}

      {insurancePolicyNumber ? (
        <Statistic
          compact
          size="tiny"
          title={
            <Text id="LoanApprove.Overview.security.CollateralPanel.insurancePolicy" />
          }
          value={insurancePolicyNumber}
        />
      ) : null}

      {collateralNarration ? (
        <Statistic
          compact
          size="tiny"
          title={
            <Text id="LoanApprove.Overview.security.CollateralPanel.narration" />
          }
          value={collateralNarration}
        />
      ) : null}
    </Card>
  );
};

const GuarantorPanel = ({ guarantor }) => {
  const { member } = guarantor;

  return (
    <Card className="nowrap tl">
      <Statistic
        compact
        size="tiny"
        title={
          <Text id="LoanApprove.Overview.security.GuarantorPanel.deposits" />
        }
        value={<Currency value={member.totalSavings} />}
      />
      <Statistic
        compact
        size="tiny"
        title={<Text id="LoanApprove.Overview.security.GuarantorPanel.loans" />}
        value={<Currency value={member.totalLoansBalance} />}
      />
      <Statistic
        compact
        size="tiny"
        title={
          <Text id="LoanApprove.Overview.security.GuarantorPanel.standing" />
        }
        color={Number(member.standing) < 0 ? 'red-600' : undefined}
        value={<Currency value={member.standing} />}
      />
    </Card>
  );
};

export default ({ StackChild, data }: SubStepComponentProps<{}>) => {
  const { loan, currentUser } = data;
  const { loanApplication } = loan;
  const payOffLoans = get('payOffLoans', loanApplication);

  const { member } = loan;
  const { attachments } = member;

  return (
    <>
      <StackChild>
        {loanApplication &&
        !isEmpty(loanApplication.loanApplicationApprovals) ? (
          <>
            {loanApplication.alreadyApprovedBy(currentUser) ? (
              <Banner
                className="mb3"
                state="warning"
                text={
                  <Text id="LoanApprove.Overview.warning.alreadyApproved" />
                }
              />
            ) : null}

            {!loanApplication.canBeApprovedBy(currentUser) ? (
              <Banner
                className="mb3"
                state="warning"
                text={<Text id="LoanApprove.Overview.warning.mayNotApprove" />}
              />
            ) : null}

            <StatisticRow widthClass="w-50">
              <Statistic
                title={<Text id="LoanApprove.Overview.approverRole" />}
                value={loanApplication.role()}
              />
              <Statistic
                containerClassName="mb3"
                title={<Text id="LoanApprove.Overview.approvalsSoFar" />}
                value={`${loanApplication.approvals()} of ${loanApplication.approversNeeded()}`}
              />
            </StatisticRow>
          </>
        ) : null}
      </StackChild>

      {data.comments && (
        <StackChild>
          <Note items={data.comments} />
        </StackChild>
      )}

      <StackChild size="widest">
        <Grid columns={4} width="w-25">
          <Card border={false} header={<Text id="LoanApprove.Overview.loan" />}>
            <Statistic
              title={<Text id="LoanApprove.Overview.id" />}
              value={data.loan.id}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.productName" />}
              value={data.loan.product.name}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.principal" />}
              value={<Currency value={data.loan.amount} />}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.frequency" />}
              value={<Duration value={data.loan.repaymentFrequency} />}
              size="small"
              compact
            />
            <Statistic
              title={
                <Text id="LoanApprove.Overview.individualInstallmentAmount" />
              }
              value={
                <Currency value={data.schedule.firstInstallmentAmount()} />
              }
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.duration" />}
              value={<Duration value={data.loan.duration} />}
              size="small"
              compact={true}
            />
            <Statistic
              compact
              size="small"
              title={
                <>
                  <Text id="LoanApprove.Overview.payOffLoans" />{' '}
                  <InfoPanel>
                    <LoansPanel loans={payOffLoans} />
                  </InfoPanel>
                </>
              }
              value={
                <Currency value={invoke('totalPayOffLoans', loanApplication)} />
              }
            />
          </Card>
          <Card
            border={false}
            header={<Text id="LoanApprove.Overview.applicant" />}
          >
            <Statistic
              title={<Text id="LoanApprove.Overview.memberId" />}
              value={data.loan.member.id}
              size="small"
              compact={true}
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.memberName" />}
              value={data.loan.member.fullName()}
              size="small"
              compact={true}
            />
            <Statistic
              title={
                <>
                  <Text id="LoanApprove.Overview.deposits" />{' '}
                  <InfoPanel>
                    <SavingsPanel savings={member.savings} />
                  </InfoPanel>
                </>
              }
              value={<Currency value={data.loan.member.totalSavings} />}
              size="small"
              compact
            />
            <Statistic
              title={
                <>
                  <Text id="LoanApprove.Overview.loans" />{' '}
                  <InfoPanel>
                    <LoansPanel loans={member.loans} />
                  </InfoPanel>
                </>
              }
              value={<Currency value={data.loan.member.totalLoansBalance} />}
              size="small"
              compact
            />
            <Statistic
              title={
                <>
                  <Text id="LoanApprove.Overview.eligible" />{' '}
                  <InfoPanel>
                    <LoanEligibilityPanel member={member} />
                  </InfoPanel>
                </>
              }
              value={<Currency value={data.loan.member.eligibleAmount} />}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.grossIncome" />}
              value={<Currency value={data.loan.member.grossIncome} />}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.netIncome" />}
              value={<Currency value={data.loan.member.netIncome} />}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="LoanApprove.Overview.otherDeductibles" />}
              value={<Currency value={data.loan.member.otherDeductibles} />}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="AddMember.Employment.OtherIncomeAmount.label" />}
              value={<Currency value={data.loan.member.otherIncomeAmount} />}
              size="small"
              compact
            />
            <Statistic
              title={<Text id="AddMember.Employment.IncomeSource.label" />}
              value={member.incomeSource}
              size="small"
              compact
            />
            <Statistic
              title={
                <Text id="AddMember.Employment.DisposableIncomeAmount.label" />
              }
              value={
                <Currency value={data.loan.member.disposableIncomeAmount} />
              }
              size="small"
              compact
            />
          </Card>
          <Card
            border={false}
            header={<Text id="LoanApprove.Overview.security" />}
          >
            <Statistic
              title={<Text id="LoanApprove.Overview.security.totalAmount" />}
              value={
                <Currency
                  format="currency"
                  value={data.loan.sumOfSecurities()}
                />
              }
              size="small"
              compact
            />
            {map(
              guarantee => (
                <Statistic
                  key={guarantee.id}
                  title={
                    <>
                      {guarantee.member.fullName()}{' '}
                      <InfoPanel>
                        <GuarantorPanel guarantor={guarantee} />
                      </InfoPanel>
                    </>
                  }
                  value={
                    <Currency format="currency" value={guarantee.amount} />
                  }
                  size="small"
                  compact
                />
              ),
              data.loan.guarantees
            )}

            {map(
              collateral => (
                <Statistic
                  key={collateral.id}
                  title={
                    <>
                      <Text
                        id={`LoanAdd.Security.Collateral.Type.${collateral.assetName}`}
                      />{' '}
                      <InfoPanel>
                        <CollateralPanel collateral={collateral} />
                      </InfoPanel>
                    </>
                  }
                  value={
                    <Currency format="currency" value={collateral.amount} />
                  }
                  size="small"
                  compact
                />
              ),
              data.loan.collaterals
            )}
            {data.loan.hasNoSecurities() ? (
              <div className="mt3">
                <Text id="LoanApprove.Overview.noSecurity" />
              </div>
            ) : null}
          </Card>
          <Card
            border={false}
            header={<Text id="LoanApprove.Overview.documents" />}
          >
            <DocumentList
              size="tiny"
              contexts={['members', 'loans']}
              member={member}
              attachments={attachments}
            />
          </Card>
        </Grid>
      </StackChild>
    </>
  );
};
