// @flow
import includes from 'lodash/fp/includes';
import curry from 'lodash/fp/curry';
import get from 'lodash/fp/get';

import {
  type LoanType,
  LoanFields,
  TransactionChannels
} from '@kwara/models/src';

const { repayment, disbursement } = LoanFields;
const repaymentFields = {
  [TransactionChannels.directDebit]: [
    repayment.bank,
    repayment.branch,
    repayment.account,
    repayment.collectingBank
  ],
  [TransactionChannels.payrollDeduction]: ['member.employer', 'member.staffId']
};

export const excludedRepaymentFields = curry<LoanType, string, boolean>(
  (loan: LoanType, fieldName: string) => {
    const repaymentMode = get(LoanFields.repayment.mode, loan);
    return !includes(fieldName, repaymentFields[repaymentMode]);
  }
);

const disbursementFields = {
  [TransactionChannels.bankTransfer]: [
    disbursement.bank,
    disbursement.branch,
    disbursement.account
  ]
};
export const excludedDisbursementFields = curry<LoanType, string, boolean>(
  (loan: LoanType, fieldName: string) => {
    const disbursementMode = get(LoanFields.disbursement.mode, loan);
    return !includes(fieldName, disbursementFields[disbursementMode]);
  }
);
