// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

export const LoanApprovalSetting = Base.extend({
  static: {
    jsonapiType: 'loan_approval_settings'
  },
  attrs: {
    tiers: attr()
  }
});

export interface LoanApprovalSettingT extends BaseModel<LoanApprovalSettingT> {
  tiers: {
    max: number,
    min: number,
    roles: number[]
  };
}
