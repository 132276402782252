// @flow

import * as React from 'react';

import { type EventT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { PageHeading, SupportEmailLink } from '@kwara/components/src/text';

import { EventTimeline } from '../../../../components/EventTimeline';
import Pagination from '../../../../components/Pagination';
import { useEvents } from '../../../../models/request';
import { usePagination } from '../../../../models/pagination';

const includes = ['user'];
export const Events = () => {
  const { allData, onNext, hasMore, isPending, ...r } = usePagination<EventT>(
    useEvents,
    page => [includes, page]
  );

  return (
    <>
      <PageHeading translationId="Settings.events" />
      <p className="grey-400 kw-text-medium mb1">
        <Text id="Settings.Events.text1" />
      </p>
      <p className="grey-400 kw-text-medium mt1">
        <Text id="Settings.Events.text2" />
        <SupportEmailLink />.
      </p>
      <Loadable {...r} isPending={false} data={allData}>
        {events => (
          <section className="mt4">
            <EventTimeline events={events} />
          </section>
        )}
      </Loadable>
      <Pagination onNext={onNext} loading={isPending} hasMore={hasMore} />
    </>
  );
};
