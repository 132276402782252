import * as React from 'react';
import cx from 'classnames';
import get from 'lodash/get';

import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import {
  convert,
  accept,
  findProfilePicture,
  addAttachment,
  removeAttachment
} from '@kwara/components/src/UploadWidget';
import { useAttachment } from '@kwara/components/src/UploadWidget/Viewer';

import style from './index.module.css';

export const ProfilePhotoUpload = ({ data, onChange }) => {
  const profilePicture = findProfilePicture(data.attachments);
  const [attachment] = useAttachment(
    profilePicture.id,
    data.id,
    data.attachments
  );
  const onRemoveAttachment = () => onChange(removeAttachment(data, 'profile'));

  return (
    <div className="flex-none mr4">
      <div className="mb2 kw-text-regular grey-400">
        <Text id="AddMember.Profile.Photo" />
      </div>
      <label htmlFor="profilePhoto" className={style.ProfilePhotoLabel}>
        <div className={style.ProfilePhotoWrapper}>
          <ProfilePhoto
            memberId={data.id}
            attachments={data.attachments}
            size="medium"
          />
        </div>
        <div className={style.OverlayText}>
          <Text id="AddMember.Profile.Photo.Upload" />
        </div>
      </label>
      <input
        className={style.ProfilePhotoInput}
        key={get(attachment, 'id', 0)}
        id="profilePhoto"
        type="file"
        accept={accept}
        onChange={e => {
          onRemoveAttachment();
          return convert(e, 'profile').then(v =>
            onChange(addAttachment(data, v))
          );
        }}
      />
      {attachment.content ? (
        <Link
          className={cx(style.LinkContainer)}
          onClick={onRemoveAttachment}
          size="small"
          type="destructive"
          underline={false}
        >
          <Text id="AddMember.Profile.Photo.Remove" />
        </Link>
      ) : null}
      <small className="grey-400 mt1">
        <Text id="AddMember.Profile.Photo.UploadHelpText" />
      </small>
    </div>
  );
};
