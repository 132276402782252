// @flow

import * as React from 'react';

import { type UserT } from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import PageLayout from '@kwara/components/src/PageLayout';

import { PrintPDFButton } from '../../components/PrintPDFButton';
import { useTillTransaction } from '../../models/request';
import Head from '../../components/Head';
import Overview from './components/Overview';
import { TransactionPDF } from './components/TransactionPDF';

type Props = {
  profile: UserT,
  showMargins?: boolean,
  showOverviewActions?: boolean,
  match: {
    params: {
      transactionId: string
    }
  }
};

const includes = ['member', 'member.attachments'];

export const TransactionDetail = (props: Props) => {
  const { showMargins = true, showOverviewActions = true } = props;

  const r = useTillTransaction(props.match.params.transactionId, includes);

  return (
    <Loadable {...r}>
      {transaction => {
        const printButton = (
          <PrintPDFButton
            renderPDF={props => (
              <TransactionPDF
                member={transaction.member}
                transaction={transaction}
                {...props}
              />
            )}
          />
        );

        return (
          <PageLayout
            className={showMargins ? 'pt5' : ''}
            overview={
              <Overview
                transaction={transaction}
                showBack={showOverviewActions}
                actions={[printButton]}
              />
            }
          >
            <Head
              titleId="TransactionDetail.pageTitle"
              values={{ id: transaction.id }}
            />
          </PageLayout>
        );
      }}
    </Loadable>
  );
};
