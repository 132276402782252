// @flow

import * as React from 'react';
import classnames from 'classnames';
import type { LocationShape } from 'react-router-dom';
import includes from 'lodash/fp/includes';

import { ActionButton } from '@kwara/components/src/Button';
import { Link } from '@kwara/components/src/Link';
import { Currency, type TranslationId } from '@kwara/components/src/Intl';
import Tag from '@kwara/components/src/Tag';

import styles from './index.module.css';

type Props = {
  breakWord?: boolean,
  color?: string,
  compact?: boolean,
  containerClassName?: string,
  'data-testid'?: string,
  hidden?: boolean,
  linkTo?: string | LocationShape,
  info?: React.Node,
  infoId?: TranslationId,
  onAction?: () => void,
  padding?: boolean,
  size?: string,
  title?: React.Node,
  value?: React.Node,
  values?: { [string]: mixed }
};

export const valueClasses = {
  huge: 'kw-text-huge kw-weight-light',
  extraBig: 'kw-text-extra-big kw-weight-light',
  big: 'kw-text-big kw-weight-light',
  superLarge: 'kw-text-super-large kw-weight-light',
  large: 'kw-text-large kw-weight-regular',
  medium: 'kw-text-medium kw-weight-regular',
  regular: 'kw-text-regular kw-weight-regular',
  small: 'kw-text-regular kw-weight-regular',
  tiny: 'kw-text-small kw-weight-regular'
};

export const Statistic = ({
  breakWord = false,
  color = 'black',
  compact = false,
  containerClassName = '',
  'data-testid': dataTestid,
  hidden,
  info,
  infoId,
  linkTo,
  onAction,
  padding = true,
  size = 'regular',
  title,
  value,
  values
}: Props) => {
  let containerClasses = `flex justify-between ph0 ${containerClassName}`;
  let titleClasses = 'mb2';
  const titleTextClass = includes(size, ['small', 'tiny'])
    ? 'kw-text-small'
    : 'kw-text-regular';
  const valueClass = breakWord ? styles.BreakWord : '';
  const infoElement = infoId ? (
    <Tag status="neutral" id={infoId} values={values} />
  ) : null;

  if (compact) {
    containerClasses += ' pv2';
    titleClasses = 'mb1';
  } else if (padding === false) {
    containerClasses += ' pv0';
  } else {
    containerClasses += ' pv3';
  }

  if (value == null || value === '') {
    color = 'grey-300';
  }

  const content = (
    <>
      <div data-testid={dataTestid}>
        <div className={classnames('grey-400', titleClasses, titleTextClass)}>
          {title}
        </div>
        <div className={valueClass}>
          <span className={classnames(color, valueClasses[size])}>
            {value == null ? '–' : value}
          </span>
          <span>{info}</span>
        </div>

        {infoElement}
      </div>
      {(onAction || linkTo) && (
        <ActionButton className="ml3" onClick={onAction} />
      )}
    </>
  );

  if (hidden) {
    return null;
  }

  if (onAction != null) {
    return (
      <button
        onClick={onAction}
        className={classnames(
          containerClasses,
          'b--transparent bg-transparent tl w-100 pointer dim'
        )}
      >
        {content}
      </button>
    );
  } else if (linkTo != null) {
    return (
      <Link
        className={classnames(containerClasses, 'dim')}
        to={linkTo}
        underline={false}
      >
        {content}
      </Link>
    );
  } else {
    return <div className={classnames(containerClasses)}>{content}</div>;
  }
};

type PenaltyStatisticT = Props & { errorValue: number };

export const PenaltyStatistic = (props: PenaltyStatisticT) => {
  const { errorValue } = props;
  return (
    <Statistic
      value={<Currency value={errorValue ? errorValue : 0} />}
      color={errorValue > 0 ? 'red-600' : undefined}
      {...props}
    />
  );
};
