// @flow

import * as React from 'react';
import type { RouterHistory, LocationShape } from 'react-router-dom';

import get from 'lodash/fp/get';
import orderBy from 'lodash/fp/orderBy';
import _result from 'lodash/fp/result';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { Text, DateTime, FileSize } from '@kwara/components/src/Intl';
import { PageHeading } from '@kwara/components/src/text';
import StatusTag from '@kwara/components/src/StatusTag';
import Button from '@kwara/components/src/Button';
import { Link, PrintButton } from '@kwara/components/src';
import { type BatchTransactionImportT } from '@kwara/models/src';

import { batchTransactionImportsPath } from '../../lib/urls';
import OverviewList from '../../components/OverviewList';
import Visible from '../../components/Visible';
import Table, * as table from '../../components/Table';
import { ImportDetails } from '../ImportDetails';

import { useBatchTransactionImports } from '../../models/request';
import { AppPermissions } from '../../models/Permission';
import { usePagination } from '../../models/pagination';
import { type SubStepComponentProps } from '../../components/Wizard';

const includes = ['user'];

export const BatchTransactionImportsList = () => {
  const {
    allData,
    onNext,
    hasMore,
    isPending,
    totalResults,
    ...r
  } = usePagination<BatchTransactionImportT>(
    useBatchTransactionImports,
    currentPage => [includes, currentPage]
  );

  const sortedItems = orderBy(['createdAt'], ['desc'], allData);

  return (
    <>
      <div className="flex items-center flex-column mt2 mb5">
        <PageHeading translationId="BatchTransactionImports.title" />
      </div>
      <OverviewList
        headerId="BatchTransactionImports.heading"
        labelId="BatchTransactionImports.entity"
        totalNumResults={totalResults}
        items={sortedItems}
        actions={[
          <Button key="download" disabled>
            <Text id="BatchTransactionImports.downloadTemplate" />
          </Button>,
          <PrintButton size="regular" key="print" />,
          <Visible to={AppPermissions.CreateBatchTransactionImports}>
            <Button
              data-testid="add-new-upload"
              type="primary"
              key="upload"
              to="batch_transaction_imports/add"
            >
              <Text id="BatchTransactionImports.uploadCTA" />
            </Button>
          </Visible>
        ]}
        table={
          <Table
            heading={
              <table.Row>
                <table.Heading width="50px" />
                <table.Heading
                  textSize="regular"
                  translationId="BatchTransactionImports.heading.id"
                  width="160px"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchTransactionImports.heading.name"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchTransactionImports.heading.uploaderName"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchTransactionImports.heading.uploaderId"
                  width="125px"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchTransactionImports.heading.date"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchTransactionImports.heading.fileSize"
                  width="125px"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchTransactionImports.heading.actions"
                  width="125px"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchTransactionImports.heading.status"
                  width="125px"
                />
              </table.Row>
            }
            footer={
              <table.Footer
                colSpan={9}
                hasMore={hasMore}
                isPending={isPending}
                onNext={onNext}
                errors={get('error.messages', r)}
                items={allData}
              />
            }
          >
            {mapIndexed((entry, i) => {
              const pathTo = batchTransactionImportsPath({ id: entry.id });
              return (
                <table.Row key={entry.id} to={pathTo}>
                  <table.Cell to={pathTo} className="grey-400 kw-numeric" />
                  <table.Cell to={pathTo} className="grey-400 kw-numeric">
                    {entry.id}
                  </table.Cell>
                  <table.Cell to={pathTo} className="mw5">
                    {entry.name}
                  </table.Cell>
                  <table.Cell to={pathTo}>
                    {_result('user.fullName', entry)}
                  </table.Cell>
                  <table.Cell to={pathTo}>{get('user.id', entry)}</table.Cell>
                  <table.Cell to={pathTo}>
                    <DateTime value={entry.createdAt} />
                  </table.Cell>
                  <table.Cell to={pathTo}>
                    <FileSize value={entry.byteSize} />
                  </table.Cell>
                  <table.Cell to={pathTo}>
                    <Link
                      size="regular"
                      data-testid={`download-as-csv-${i}`}
                      type="secondary"
                      onClick={() => {}}
                      disabled
                    >
                      <Text id="BatchTransactionImports.row.download.CSV" />
                    </Link>
                  </table.Cell>
                  <table.Cell to={pathTo}>
                    <StatusTag state={get('state.current', entry)} />
                  </table.Cell>
                </table.Row>
              );
            }, sortedItems)}
          </Table>
        }
      />
    </>
  );
};

export type SubstepProps = SubStepComponentProps<{}>;

type BatchTransactionImportsProps = {
  match: {
    params: {
      id: ?string
    }
  },
  history: RouterHistory,
  location: LocationShape
};

export const BatchTransactionImports = (
  props: BatchTransactionImportsProps
) => {
  if (props.match.params.id) {
    const batchId = props.match.params.id;
    return <ImportDetails batchId={batchId} />;
  }

  return <BatchTransactionImportsList {...props} />;
};
