// @flow

import * as React from 'react';

import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Percent, Text } from '@kwara/components/src/Intl';
import { type SavingType } from '@kwara/models/src';

import { Currency } from '../../../components/Currency';
import { memberPath } from '../../../lib/urls';
import { DetailOverviewSection } from '../../../components/Detail/DetailOverviewSection';
import { OverviewMetadata } from './OverviewMetadata';

export type Props = {
  actions: React.Node,
  backLabel?: string,
  navigation: React.Node,
  onBack: () => void,
  saving: SavingType
};

export default (props: Props) => {
  const { navigation, saving } = props;

  return (
    <DetailOverviewSection
      secondary={navigation}
      title={
        <React.Fragment>
          <Text id="SavingDetail.title" values={{ id: saving.id }} />
        </React.Fragment>
      }
      metadata={<OverviewMetadata {...props} />}
    >
      <section className="mb5">
        <StatisticRow widthClass="w-50">
          <Statistic
            title={<Text id="SavingDetail.Overview.balance" />}
            value={<Currency value={saving.balance} />}
            border={true}
            color={'teal-600'}
            size={'huge'}
          />
          <Statistic
            title={<Text id="SavingDetail.Overview.accruedInterest" />}
            value={<Currency value={saving.accruedInterest} />}
            border={true}
            size={'huge'}
          />
        </StatisticRow>
        <StatisticRow widthClass="w-25" border={true}>
          <Statistic
            title={<Text id="SavingDetail.Overview.name" />}
            value={saving.name || 'Unknown'}
            border={true}
            size={'large'}
          />
          <Statistic
            title={<Text id="SavingDetail.Overview.interestRate" />}
            value={<Percent value={saving.interestObject().rate / 100} />}
            border={true}
            size={'large'}
          />
          <Statistic
            title={<Text id="SavingDetail.Overview.minimumDeposit" />}
            value={'Unknown'}
            border={true}
            size={'large'}
          />
          <Statistic
            title={<Text id="SavingDetail.Overview.minimumBalance" />}
            value={
              <Currency value={saving.product.minimumOpeningBalance || 0} />
            }
            border={true}
            size={'large'}
          />
        </StatisticRow>
        <StatisticRow widthClass="w-50" border={true}>
          <Statistic
            title={<Text id="SavingDetail.Overview.memberId" />}
            value={saving.member.id}
            size={'large'}
            linkTo={memberPath({ id: saving.member.id })}
          />
          <Statistic
            title={<Text id="SavingDetail.Overview.member" />}
            value={saving.member.fullName()}
            border={true}
            size={'large'}
            linkTo={memberPath({ id: saving.member.id })}
          />
        </StatisticRow>
      </section>
    </DetailOverviewSection>
  );
};
