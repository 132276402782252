// @flow

import React from 'react';

import { ProductForm } from '../../SavingProductAdd/components/ProductForm';

export const ProductEdit = props => {
  return <ProductForm {...props} translationBaseId="SavingProductEdit" />;
};

ProductEdit.validate = ProductForm.validate;
