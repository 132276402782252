// @flow

import React from 'react';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

import { fields as loanFields } from '@kwara/models/src/models/Loan';
import {
  SubscribedPaymentSelectField,
  BankSelect
} from '@kwara/components/src/Form';
import {
  getTransactionTypes,
  contexts,
  TransactionChannels
} from '@kwara/models/src/models/Transactions';

import { Grid } from '../../../../components/Grid';
import {
  SubscribedFrequencySelectFields,
  SubscribedDatePicker
} from '../../../../components/Form';
import { type LoanSubstepProps, type LoanFormData } from '../..';

const fields = loanFields.repayment;

type Props = LoanSubstepProps;

// Payroll Deduction fields are only shown
// during LoanAdd > Repayment step, not
// in the Repayment Editable Sections.
// We likely won't need this once ch16865
// is  actioned.
//
function showPayrollFields(fieldName, config) {
  return !!get(fieldName, config.validate);
}

const showBanksFieldsFor = [
  TransactionChannels.directDebit,
  TransactionChannels.bankTransfer
];

export function Repayment({
  StackChild,
  Condition,
  TextField,
  addData,
  config
}: Props) {
  return (
    <StackChild>
      <SubscribedPaymentSelectField
        name={fields.mode}
        labelId="LoanAdd.Configure.RepaymentMode.label"
        required
        config={{
          bank: fields.bank,
          bankLabelId: 'LoanAdd.Configure.DirectDebitBank.label',
          bankBranch: fields.branch,
          accountNumber: fields.account,
          methods: getTransactionTypes(contexts.LoanRemittance).values,
          showBanksFieldsFor
        }}
      />

      <Condition when={fields.mode} is={TransactionChannels.directDebit}>
        <BankSelect
          name={fields.collectingBank}
          titleId="LoanAdd.Repayment.CollectingBank.title"
          labelId={null}
        />
        <SubscribedDatePicker
          required
          name={fields.startDate}
          labelId="LoanAdd.Repayment.RemittanceStartDate.label"
        />
      </Condition>

      <Condition when={fields.mode} is={TransactionChannels.payrollDeduction}>
        {showPayrollFields('member.employer', config) ? (
          <TextField
            name="member.employer"
            labelId="LoanAdd.Configure.RepaymentMode.Employer.title"
            required
          />
        ) : null}
        {showPayrollFields('member.staffId', config) ? (
          <TextField
            name="member.staffId"
            labelId="LoanAdd.Configure.RepaymentMode.StaffId.title"
            required
          />
        ) : null}
      </Condition>

      <Grid columns={2} width="w-50" border={false}>
        <TextField
          name={fields.amount}
          labelId="LoanAdd.Repayment.Amount.label"
        />
        <TextField
          name={fields.feeAmount}
          labelId="LoanAdd.Repayment.FeeAmount.label"
        />
      </Grid>

      <SubscribedFrequencySelectFields
        addData={addData}
        frequency={fields.frequency}
        day={fields.collectionDate}
      />
    </StackChild>
  );
}

const requiredForBankFields = {
  isRequired: (_, allData: LoanFormData): boolean =>
    includes(get(fields.mode, allData), showBanksFieldsFor)
};

const requiredForDirectDebit = {
  isRequired: (_, allData: LoanFormData): boolean =>
    get(fields.mode, allData) === TransactionChannels.directDebit
};

const requiredForPayrollDeduction = {
  isRequired: (_, allData: LoanFormData): boolean =>
    get(fields.mode, allData) === TransactionChannels.payrollDeduction
};

const base = {
  [fields.mode]: { isRequired: () => true },
  [fields.bank]: requiredForBankFields,
  [fields.branch]: requiredForBankFields,
  [fields.account]: requiredForBankFields,
  [fields.collectingBank]: requiredForDirectDebit,
  [fields.startDate]: requiredForDirectDebit,
  [fields.amount]: {
    currency: true
  },
  [fields.feeAmount]: {
    currency: true
  },
  ...SubscribedFrequencySelectFields.validate(
    fields.frequency,
    fields.collectionDate
  )
};

const loanAdd = {
  ...base,
  'member.employer': requiredForPayrollDeduction,
  'member.staffId': requiredForPayrollDeduction
};

Repayment.validateConfig = {
  loanAdd,
  loanEdit: base
};
