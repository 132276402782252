//@flow
import React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import { Till, type TopupRequestT } from '@kwara/models/src';
import { setFavicon } from '@kwara/models/src/lib/favicon';
import PageLayout from '@kwara/components/src/PageLayout';
import { Loadable } from '@kwara/components/src/Loadable';

import { useInterval, usePageVisibility } from '../../lib/hooks';
import { topupReviewPath } from '../../lib/urls';
import { useTopupRequests, isEMPTY } from '../../models/request';
import { DataViewWrapper } from '../../components/DataViewWrapper';
import { TellerDashboard } from './components/Dashboard';
import { TellerModal, VaultModal } from './components/Modal';
import Head from '../../components/Head';
import TellerList from './components/TellerList';

const TEN_SECONDS = 10 * 1000;
const TWO_MINUTES = 2 * 60 * 1000;

function useThrottledCallback(
  callback: () => void,
  visibleInterval: number = TEN_SECONDS,
  invisibleInterval: number = TWO_MINUTES
) {
  const isVisible = usePageVisibility();
  const [intervalLength, setIntervalLength] = React.useState(visibleInterval);

  React.useEffect(() => {
    if (isVisible) {
      setIntervalLength(visibleInterval);
    } else {
      setIntervalLength(invisibleInterval);
    }
  }, [isVisible, visibleInterval, invisibleInterval]);

  useInterval(callback, intervalLength);
}

function formattedMessages(topupRequests: TopupRequestT[]) {
  return map(r => {
    return {
      type: 'topup',
      amount: r.amount,
      updated: r.updatedAt,
      tellerName: r.till.tellerName,
      tillId: get('till.id', r),
      topupRequest: r
    };
  }, topupRequests);
}

const Content = props => {
  const {
    hasMore,
    loading,
    errors,
    data: tellers,
    onLoadMoreData,
    totalNumberResults,
    history
  } = props;

  const { refetch, ...r } = useTopupRequests();

  // teller modal
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const onOpenTellerModal = () => setIsOpen(true);

  // vault modal
  const [isVaultModalOpen, setVaultModalIsOpen] = React.useState(false);
  const onVaultModalClose = () => setVaultModalIsOpen(false);
  const onOpenVaultModal = () => setVaultModalIsOpen(true);

  useThrottledCallback(refetch);

  if (!r.isPending) {
    setFavicon(!isEmpty(r.data) ? 'notification' : undefined);
  }

  return (
    <PageLayout
      overview={
        <Loadable {...r} isPending={r.isPending && isEMPTY(r.data)}>
          {requests => (
            <TellerDashboard
              messages={formattedMessages(requests)}
              inboxAction={message => {
                const id = get('topupRequest.id', message);
                history.push(topupReviewPath({ id }));
              }}
            />
          )}
        </Loadable>
      }
    >
      <Head titleId="TellerList.title" />
      <TellerModal isOpen={isOpen} onClose={onClose} />
      <VaultModal isOpen={isVaultModalOpen} onClose={onVaultModalClose} />
      <TellerList
        onOpenTellerModal={onOpenTellerModal}
        onOpenVaultModal={onOpenVaultModal}
        tellers={tellers}
        onLoadMoreData={onLoadMoreData}
        hasMore={hasMore}
        loading={loading}
        errors={errors}
        history={history}
        totalNumResults={totalNumberResults}
      />
    </PageLayout>
  );
};

export const Tellers = ({ location, ...rest }) => {
  return (
    <DataViewWrapper
      component={props => <Content {...rest} {...props} />}
      scope={Till.includes()}
      pathname="/tellers"
      location={location}
    />
  );
};
