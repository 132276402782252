// @flow

import { attr } from 'spraypaint';

import Base from './Base';

export const Lead = Base.extend({
  static: {
    jsonapiType: 'leads'
  },
  attrs: {
    sacco: attr(),
    loanProducts: attr(),
    savingsProducts: attr(),
    roles: attr(),
    users: attr()
  }
});
