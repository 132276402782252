// @flow

import * as React from 'react';
import { Redirect, type RouterHistory } from 'react-router-dom';
import { Form } from 'react-final-form';

import createValidator from '@kwara/lib/src/validator';
import { DeprecatedLoadable } from '@kwara/components/src/Loadable';

import { Panel } from '../../components/ActionModal';
import Payment from '../../components/Payment';
import PaymentForm, { defaultConfig } from '../../components/PaymentForm';

import {
  Loan,
  LoanTransaction,
  PendingLoanTransaction,
  type LoanType,
  type ModelErrors
} from '@kwara/models/src';

import { loanPath } from '../../lib/urls';
import { store } from '../../models/Store';

type Props = {
  baseUrl: string,
  history: RouterHistory,
  match: {
    params: {
      loanId: string
    }
  },
  onCancel: () => void,
  onConfirm: () => void
};

type State = {
  errors: ?ModelErrors,
  state: 'inProgress' | 'success' | 'done',
  isProcessing: boolean,
  loanPromise: Promise<LoanType>
};

type FormData = {
  amount: ?number,
  date?: ?Date,
  notes?: string
};

export default class extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errors: null,
      state: 'inProgress',
      isProcessing: false,
      loanPromise: Loan.includes(['guarantees', 'member', 'product'])
        .find(props.match.params.loanId)
        .then(response => response.data)
    };
  }

  makeTransaction = async (data: FormData) => {
    this.setState({ isProcessing: true });

    const loan = await this.state.loanPromise;
    const { amount, notes } = data;

    const Transaction = store.isMakerCheckerEnabled(amount)
      ? PendingLoanTransaction
      : LoanTransaction;

    const transaction = Transaction.create({
      type: 'FEE',
      loanId: loan.id,
      amount,
      notes
    });

    try {
      const didSave = await transaction.save();
      if (didSave) {
        this.setState({ state: 'success', errors: null });
      } else {
        throw new Error('Could not save');
      }
    } catch (error) {
      this.setState({ state: 'inProgress', errors: transaction.errors });
    } finally {
      this.setState({ isProcessing: false });
    }
  };

  close = () => {
    this.setState({ state: 'done' });
  };

  renderContent = (loan: LoanType) => {
    const { errors, state, isProcessing } = this.state;
    const returnTo = <Redirect to={loanPath({ id: loan.id })} />;

    if (state === 'done') {
      return returnTo;
    }

    const config: any = {
      ...defaultConfig,
      method: { required: false },
      bankName: { required: false },
      bankBranch: { required: false },
      accountNumber: { required: false }
    };

    const validate = createValidator(config);

    return (
      <Form
        onSubmit={this.makeTransaction}
        validate={validate}
        render={(formProps: any) => {
          const { invalid, handleSubmit } = formProps;

          return (
            <Payment
              errors={errors}
              isProcessing={isProcessing}
              invalid={invalid}
              loan={loan}
              onCancel={() =>
                this.props.history.push(loanPath({ id: loan.id }))
              }
              onConfirm={state !== 'success' ? handleSubmit : this.close}
              success={state === 'success'}
              titleId="LoanPenalty.title"
              type={Payment.Type.repayment}
            >
              <Panel>
                <PaymentForm
                  showAmount={true}
                  errors={errors}
                  type={Payment.Type.repayment}
                  dateLabelId="LoanPenalty.dateLabel"
                  showMethod={false}
                />
              </Panel>
            </Payment>
          );
        }}
      />
    );
  };

  render() {
    return (
      <DeprecatedLoadable
        loader={this.state.loanPromise}
        loaded={loan => this.renderContent(loan)}
      />
    );
  }
}
