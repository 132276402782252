import assignAll from 'lodash/fp/assignAll';

import { mapIndexed } from '@kwara/lib/src/lodash';

import {
  ImportTemplate,
  Users,
  Roles,
  LoanProducts,
  SaccoDetails,
  SavingsProducts,
  Summary
} from './components';

// eslint-disable-next-line
const importSteps = {
  template: {
    titleId: 'Onboarding.Step.TemplateMain.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'saccoImport'
      }
    ],
    children: [
      {
        titleId: 'Onboarding.Step.Template.title',
        subtitleId: 'Onboarding.Step.Template.subtitle',
        Component: ImportTemplate
      }
    ]
  },
  saccoImport: {
    titleId: 'Onboarding.Step.SaccoDetails.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'loanProductsImport'
      }
    ],
    children: [
      {
        titleId: 'Onboarding.ImportSaccoDetails.title',
        subtitleId: 'Onboarding.ImportSaccoDetails.subtitle',
        Component: SaccoDetails
      }
    ]
  },
  loanProductsImport: {
    titleId: 'Onboarding.Step.LoanProductsMain.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'summary'
      }
    ],
    children: [
      {
        titleId: 'Onboarding.Step.ImportLoanProducts.title',
        Component: LoanProducts
      }
    ]
  }
};

export const steps = {
  sacco: {
    titleId: 'Onboarding.Step.SaccoDetails.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'loanProducts'
      }
    ],
    children: [
      {
        titleId: 'Onboarding.SaccoDetails.title',
        subtitleId: 'Onboarding.SaccoDetails.subtitle',
        Component: SaccoDetails,
        validate: {
          'sacco.name': {
            isRequired: () => true
          },
          'sacco.physicalAddress': {
            isRequired: () => true
          }
        }
      }
    ]
  },
  loanProducts: {
    titleId: 'Onboarding.Step.LoanProductsMain.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'savingsProducts'
      }
    ],
    children: [
      {
        titleId: 'Onboarding.Step.LoanProducts.title',
        subtitleId: 'Onboarding.Step.LoanProducts.subtitle',
        Component: LoanProducts,
        validate(v) {
          return assignAll(
            mapIndexed((_, i) => {
              return {
                [`loanProducts[${i}].name`]: {
                  isRequired: () => true
                }
              };
            }, v.loanProducts)
          );
        }
      }
    ]
  },
  savingsProducts: {
    titleId: 'Onboarding.Step.SavingsProductsMain.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'roles'
      }
    ],
    children: [
      {
        titleId: 'Onboarding.Step.SavingsProducts.title',
        subtitleId: 'Onboarding.Step.SavingsProducts.subtitle',
        Component: SavingsProducts,
        validate(v) {
          return assignAll(
            mapIndexed((_, i) => {
              return {
                [`savingsProducts[${i}].name`]: {
                  isRequired: () => true
                }
              };
            }, v.savingsProducts)
          );
        }
      }
    ]
  },
  roles: {
    titleId: 'Onboarding.Step.RolesMain.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'users'
      }
    ],
    children: [
      {
        titleId: 'Onboarding.Step.Roles.title',
        subtitleId: 'Onboarding.Step.Roles.subtitle',
        Component: Roles
      }
    ]
  },
  users: {
    titleId: 'Onboarding.Step.UsersMain.title',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'summary'
      }
    ],
    children: [
      {
        titleId: 'Onboarding.Step.Users.title',
        subtitleId: 'Onboarding.Step.Users.subtitle',
        Component: Users,
        validate(v) {
          return assignAll(
            mapIndexed((_, i) => {
              return {
                [`users[${i}].name`]: {
                  isRequired: () => true
                }
              };
            }, v.users)
          );
        }
      }
    ]
  },
  //...importSteps, // disabled for now
  summary: {
    titleId: 'Onboarding.Step.Summary.title',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'Onboarding.Step.Summary.title',
        subtitleId: 'Onboarding.Step.Summary.subtitle',
        Component: Summary
      }
    ]
  }
};

// We preload some default content for the form
export const initialData = {
  roles: [
    {
      name: 'Secretary',
      permissions: {
        members: {
          add: true,
          edit: false
        },
        savings: {
          add: true,
          edit: true
        }
      }
    }
  ]
};
