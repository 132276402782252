//@flow
import * as React from 'react';

import Context, { type ContextType } from './Context';
export { default as Banner } from './Banner';
export { default as Provider } from './Provider';
export type notification = ContextType;

type Props = {
  children: (context: ContextType) => React.Node
};

/*
  Allows anything in the app to display global
  notifications in the site header.

  Look at the stories to see how this should be used.
*/
export const AddNotification = ({ children }: Props) => (
  <Context.Consumer>
    {({ displayError, displayMessage, displayWarning, dismiss }: ContextType) =>
      children({ displayError, displayMessage, displayWarning, dismiss })
    }
  </Context.Consumer>
);

export function WithNotification<P: {}>(
  Component: React.ComponentType<P>
): React.ComponentType<$Diff<P, { notification: ContextType }>> {
  return function WrapperComponent(props: P) {
    return (
      <Context.Consumer>
        {(methods: ContextType) => (
          <Component {...props} notification={methods} />
        )}
      </Context.Consumer>
    );
  };
}

type NotificationComponentProps = {
  type?: 'error' | 'message',
  children: React.Node,
  notification: ContextType
};

class NotificationComponent extends React.Component<
  NotificationComponentProps,
  *
> {
  componentDidMount() {
    const method =
      this.props.type === 'error'
        ? this.props.notification.displayError
        : this.props.notification.displayMessage;

    method(this.props.children);
  }

  render() {
    return null;
  }
}

export const Notification = WithNotification(NotificationComponent);
