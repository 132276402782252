//@flow

import pipe from 'lodash/fp/pipe';
import pick from 'lodash/fp/pick';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import size from 'lodash/fp/size';
import toInteger from 'lodash/fp/toInteger';
import fpMap from 'lodash/fp/map';
import values from 'lodash/fp/values';
import sum from 'lodash/fp/sum';

import apiConfig from '../config';
import Auth from '../lib/auth';
import { type IdentityType } from '../lib/types';

import { cameliseObjectKeys, createModelErrors } from '@kwara/models/src';

type CreditReportParams = {
  identityType: IdentityType,
  identityValue: string,
  loanAmount: number,
  reportReason?: number
};

const sumAccounts = (key: string, instance): number =>
  pipe(
    get('lenderSector'),
    values,
    fpMap(get(key)),
    sum
  )(instance);

class CreditReport {
  accounts = [];
  lenderSector = {};
  constructor(data) {
    Object.assign(this, data);
  }

  get hasReportedFraud() {
    return this.hasFraud ? 'Yes' : 'No';
  }
  get formattedCreditScore() {
    const { creditScore } = this;

    if (creditScore == null) {
      return '-';
    }

    return toInteger(creditScore);
  }
  get nonPerformingAccounts() {
    return sumAccounts('accountNpa', this);
  }
  get performingAccountsWithDefault() {
    return sumAccounts('accountPerforming', this);
  }
  get performingAccountsWithoutDefault() {
    return sumAccounts('accountPerformingNpaHistory', this);
  }
  get totalAccounts() {
    return size(this.accounts);
  }
  get totalOutstandingBalance() {
    return pipe(
      fpMap(v => parseInt(getOr(0, 'currentBalance', v))),
      sum
    )(this.accounts);
  }
}

async function getReport(params: CreditReportParams) {
  const {
    identityType: identity_type = 'national',
    identityValue: identity_value,
    loanAmount: loan_amount
  } = params;

  try {
    const qparams = [
      // For example loan_amount=10000&report_reason=1&identity_type=NATIONAL&identity_value=880000088
      `loan_amount=${loan_amount}`,
      `identity_type=${identity_type}`,
      `identity_value=${identity_value}`,
      'report_reason=1' // yes, we hardcode this one
    ];
    const res = await fetch(
      `${apiConfig.API_ROOT}/credit_info?${qparams.join('&')}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getRawToken()}`
        },
        method: 'GET'
      }
    ).then(r => r.json());

    const result = pipe(
      pick(['data', 'errors']),
      r => ({ ...r, data: get('data.attributes', r) }),
      cameliseObjectKeys,
      r => ({ ...r, data: new CreditReport(r.data) })
    )(res);

    return result;
  } catch (e) {
    return { errors: [createModelErrors(e.response)] };
  }
}

export default { get: getReport };
