//@flow
import * as React from 'react';

import { LoanDetail as LoanDetailBase } from '../..';
import { type SubStepComponentProps } from '../../../components/Wizard';

type CustomProps = {
  loanId: string
};

export const LoanDetail = ({
  customProps,
  history,
  StackChild
}: SubStepComponentProps<CustomProps>) => {
  return (
    <StackChild size="stretch">
      <LoanDetailBase
        hiddenSections={['feesAndPenalties', 'transactions']}
        history={history}
        match={{ params: { loanId: customProps ? customProps.loanId : null } }}
        linkContext={{
          backLabel: 'Back to loan application',
          useBrowserBack: true
        }}
        showCommentCreate={false}
        showMargins={false}
        showOverviewActions={false}
        readOnly={false}
      />
    </StackChild>
  );
};
