//@flow
import * as React from 'react';
import classnames from 'classnames';
import { type IntlShape, injectIntl } from 'react-intl';

import Asset, { type Glyph } from '@kwara/components/src/Asset';
import {
  type TranslationId,
  getTranslation,
  Text
} from '@kwara/components/src/Intl';

import styles from './TextField.module.scss';

type PublicProps = {
  'data-testid'?: string,
  border?: boolean,
  className?: string,
  clearable?: boolean,
  disabled?: boolean,
  error?: boolean,
  leftGlyph?: ?Glyph,
  max?: ?number,
  min?: ?number,
  name: string,
  inputOnBlur?: (evt: SyntheticInputEvent<HTMLInputElement>) => void,
  onBlur?: (evt: SyntheticInputEvent<HTMLInputElement>) => void,
  onChange: (evt: SyntheticInputEvent<HTMLInputElement>) => void,
  placeholder?: string,
  placeholderId?: TranslationId,
  rightGlyph?: ?Glyph,
  rightAction?: React.Node,
  size: 'regular' | 'medium',
  type?: 'text' | 'password' | 'number',
  value: string,
  values?: { [string]: mixed }
};

type Props = { intl: IntlShape } & PublicProps;

type TextGlyphProps = {
  className?: string,
  children: React.Node
};

// TODO: This isn't a great approach
//       But we need a way to re-use the Glyph mechanism for text
//
const TextGlyph = ({ className, children }: TextGlyphProps) => (
  <span className={`${styles.TextGlyph} grey-300 ${className || ''}`}>
    {children}
  </span>
);

const getGlyph = (id, classes) => {
  if (id != null && Asset.Glyphs.hasOwnProperty(id)) {
    return <Asset className={classes} id={id} col={Asset.Colours.grey300} />;
  } else if (typeof id === 'string') {
    return <TextGlyph className={classes}>{<Text id={id} />}</TextGlyph>;
  }

  return null;
};

const ClearButton = ({ onClear }) => (
  <span
    className={`flex-none ${styles.rightGlyph} ${styles.ClearButton}`}
    onClick={onClear}
  >
    {getGlyph(Asset.Glyphs.Cross)}
  </span>
);

class TextField extends React.Component<Props, *> {
  inputRef = React.createRef();

  focus = () => {
    if (
      this.inputRef.current != null &&
      typeof this.inputRef.current.focus === 'function'
    ) {
      this.inputRef.current.focus();
    }
  };

  clear = () => {
    // $FlowFixMe Should somehow create a real event?
    this.props.onChange({ target: { value: '' } });
  };

  render() {
    const {
      border = true,
      className,
      clearable,
      disabled = false,
      error = false,
      intl,
      inputOnBlur = e => e,
      leftGlyph,
      name,
      onBlur = e => e,
      placeholder,
      placeholderId,
      rightAction,
      rightGlyph,
      size,
      type = 'text',
      value,
      values,
      ...rest
    } = this.props;

    // Do not pass to input
    delete rest['data-testid'];

    const shouldOfferClear = clearable && value && value.length > 0;

    const containerClasses = [
      styles.Container,
      `w-100 dib kw-text-${size}`,
      border ? 'ba' : '',
      `flex items-center`,
      error ? styles.error : '',
      { [styles.hasGlyph]: !!leftGlyph || !!rightGlyph },
      styles[size],
      className
    ];

    const inputClasses = [styles.Input, 'flex-auto'];

    let placeholderContent = null;

    if (placeholderId) {
      placeholderContent = getTranslation(intl, placeholderId, values);
    } else if (placeholder) {
      placeholderContent = placeholder;
    }

    const left = getGlyph(leftGlyph, `flex-none ${styles.leftGlyph}`);
    let right = shouldOfferClear ? (
      <ClearButton onClear={this.clear} />
    ) : (
      rightAction
    );
    right = rightGlyph
      ? getGlyph(rightGlyph, `flex-none ${styles.rightGlyph}`)
      : right;

    return (
      <div
        className={classnames(containerClasses)}
        data-testid={this.props['data-testid']}
        onClick={this.focus}
      >
        {left}
        <input
          ref={this.inputRef}
          disabled={disabled}
          placeholder={placeholderContent}
          className={classnames(inputClasses)}
          id={name}
          type={type}
          value={value}
          onBlur={e => {
            inputOnBlur(e);
            onBlur(e);
          }}
          {...rest}
        />
        {right}
      </div>
    );
  }
}

const Wrapped = (injectIntl(TextField): React.ComponentType<PublicProps>);

// TODO: DatePicker relies on being able to imperitively
//       focus() but the wrapped HoC doesn't allow this
//$FlowFixMe
Wrapped.prototype.focus = () => {};

export default Wrapped;
