// @flow

import React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';

import Wizard from '../../components/Wizard';
import { settingPath } from '../../lib/urls';
import { steps } from './config';
import { useSavingProduct } from '../../models/request';

type Props = {
  baseUrl: string,
  match: {
    params: {
      step: string,
      subStep: string,
      productId: string
    }
  }
};

export const SavingProductEdit = (props: Props) => {
  const r = useSavingProduct(props.match.params.productId);
  // TO DO: Waiting for API Support to create new SavingsProduct
  const onSubmit = async product => await product.save();

  return (
    <Loadable {...r}>
      {product => {
        return (
          <Wizard
            baseUrl={props.baseUrl}
            history={history}
            initialData={product}
            cancelReturnsTo={settingPath({ action: 'savings' })}
            currentStep={props.match.params.step}
            currentSubStep={
              props.match.params.subStep != null
                ? parseInt(props.match.params.subStep, 10)
                : null
            }
            onSubmit={onSubmit}
            steps={steps}
            startId="product"
            titleId="SavingProductEdit.titleId"
            type="approval"
          />
        );
      }}
    </Loadable>
  );
};
