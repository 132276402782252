// @flow
import * as React from 'react';
import classnames from 'classnames';
import { type IntlShape, injectIntl } from 'react-intl';

import { type TranslationId, getTranslation } from '@kwara/components/src/Intl';

import styles from './TextArea.module.css';

type PublicProps = {
  border?: boolean,
  compact?: boolean,
  disabled?: boolean,
  error?: boolean,
  name: string,
  onChange: (evt: { target: { value: string } }) => void,
  placeholderId?: TranslationId,
  size: 'regular' | 'medium',
  value: string
};

type Props = { intl: IntlShape } & PublicProps;

const borderCol = (disabled, error) => {
  if (error) {
    return 'b--red-500';
  }

  return disabled ? 'b--light-grey-300' : 'b--light-grey-500';
};

const TextField = ({
  compact = false,
  border = true,
  disabled = false,
  error = false,
  intl,
  placeholderId,
  size,
  value,
  name,
  ...rest
}: Props) => {
  const inputClasses = [
    `w-100 dib ba bf--indigo-500 kw-text-${size}`,
    styles.base,
    styles[size],
    compact ? styles.isCompact : styles.isNotCompact,
    border ? borderCol(disabled, error) : 'b--transparent'
  ];

  const placeholder = placeholderId
    ? getTranslation(intl, placeholderId)
    : null;

  return (
    <div className="">
      <textarea
        disabled={disabled}
        id={name}
        placeholder={placeholder}
        className={classnames(inputClasses)}
        value={value}
        {...rest}
      />
    </div>
  );
};

export default (injectIntl(TextField): React.ComponentType<PublicProps>);
