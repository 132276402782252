// @flow

import React from 'react';
import getOr from 'lodash/fp/getOr';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { View, StyleSheet } from '@react-pdf/renderer';
import {
  type LoanType,
  type TransactionT,
  type MemberType,
  type UserT,
  type ScheduleType,
  type RepaymentType
} from '@kwara/models/src';

import {
  Date,
  pdfMemberDetails as MemberDetails,
  DefaultText as Text,
  Cell,
  Row,
  Section,
  Template
} from '@kwara/components/src/PDF';

import { LoanAccountSummary } from '@kwara/components/src/LoanTransactionsPDF';

const styles = StyleSheet.create({
  table: {
    flexDirection: 'column'
  },
  tableCell: { width: '17%', textAlign: 'center' },
  tableLabel: { textAlign: 'center', color: 'black' },
  tableDateLabel: { textAlign: 'center', color: 'lightgrey' },
  border: { borderBottomColor: 'black' }
});

const ScheduleTableHeader = () => (
  <Row>
    {[
      'Date due',
      'Opening balance',
      'Principal due',
      'Interest due',
      'Outstanding due',
      'State'
    ].map(title => (
      <Cell key={title} style={styles.tableCell}>
        <Text>{title}</Text>
      </Cell>
    ))}
  </Row>
);

const RepaymentTableRow = ({ repayment }: { repayment: TransactionT }) => {
  const {
    dueAt,
    startingBalance,
    principal,
    interest,
    totalDue,
    state
  } = repayment;
  return (
    <Row>
      <Cell style={styles.tableCell}>
        <Date style={styles.tableDateLabel}>{dueAt}</Date>
      </Cell>
      <Cell style={styles.tableCell}>
        <Text style={styles.tableLabel}>{startingBalance}</Text>
      </Cell>
      <Cell style={styles.tableCell}>
        <Text style={styles.tableLabel}>{get('due', principal)}</Text>
      </Cell>
      <Cell style={styles.tableCell}>
        <Text style={styles.tableLabel}>{get('due', interest)}</Text>
      </Cell>
      <Cell style={styles.tableCell}>
        <Text style={styles.tableLabel}>{totalDue}</Text>
      </Cell>
      <Cell style={styles.tableCell}>
        <Text style={styles.tableLabel}>{state}</Text>
      </Cell>
    </Row>
  );
};

const RepaymentsTable = ({ repayments }): { repayment: RepaymentType } => (
  <View>
    {map(
      repayment => (
        <RepaymentTableRow key={repayment.id} repayment={repayment} />
      ),
      repayments
    )}
  </View>
);

type Props = {
  loan: LoanType,
  member: MemberType,
  profile: UserT,
  schedule: ScheduleType
};

export const SchedulePDF = ({ loan, member, schedule, profile }: Props) => (
  <Template
    title={`Loan Transaction Statement ${loan.id}`}
    sacco={getOr('', 'branch', profile)}
    headerText="Loan Schedule"
  >
    <Section fixed>
      <MemberDetails member={member} />
      <LoanAccountSummary account={loan} />
    </Section>
    <View style={styles.table}>
      <ScheduleTableHeader />
      <RepaymentsTable repayments={get('repayments', schedule)} />
    </View>
  </Template>
);
