// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import { type LoanType } from '@kwara/models/src';

import Wizard from '../../components/Wizard';
import { loanPath } from '../../lib/urls';
import { useLoan } from '../../models/request';
import { steps } from './config';
import type { WizardPageProps } from '..';

type Props = WizardPageProps<{ loanId: string }>;

export const LoanWriteOff = (props: Props) => {
  const { baseUrl, match, history } = props;
  const response = useLoan(props.match.params.loanId);

  const writeOffLoan = async ({
    loan,
    notes
  }: {
    loan: LoanType,
    notes: string
  }) => {
    const didSave = await loan.writeOff({ notes });

    if (!didSave) {
      throw loan.errors;
    }
  };

  return (
    <Loadable {...response}>
      {loan => {
        return (
          <Wizard
            baseUrl={baseUrl}
            history={history}
            initialData={{
              loan: loan,
              loanId: loan.id,
              firstName: loan.member.firstName
            }}
            cancelReturnsTo={loanPath()}
            currentStep={match.params.step}
            currentSubStep={
              match.params.subStep != null
                ? parseInt(match.params.subStep, 10)
                : null
            }
            onSubmit={writeOffLoan}
            steps={steps}
            startId="confirm"
            titleId="LoanWriteOff.shortTitle"
            type="approval"
          />
        );
      }}
    </Loadable>
  );
};
