//@flow
import * as React from 'react';
import { Text } from '@kwara/components/src/Intl';

// $FlowFixMe Disjoint union
const Loading = ({ error }: ErrorProps | LoadingProps) => (
  <div className="w-100 h-100 flex justify-center items-center">
    <span className="">
      <Text id={error == null ? 'Loading.loading' : 'Loading.error'} />
    </span>
  </div>
);

export default Loading;
