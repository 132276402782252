// @flow

import * as React from 'react';
import { injectIntl, type IntlShape } from 'react-intl';
import isEqual from 'lodash/fp/isEqual';

import type { TranslationId } from '@kwara/components/src/Intl';

type PropsWithoutIntl = {
  titleId?: TranslationId,
  values?: { [string]: mixed }
};

type Props = {
  intl: IntlShape
} & PropsWithoutIntl;

class Head extends React.PureComponent<Props> {
  componentDidMount() {
    this.setTitle();
  }

  componentDidUpdate(prevProps) {
    const titleHasChanged = this.props.titleId !== prevProps.titleId;
    const valuesHaveChanged = !isEqual(this.props.values, prevProps.values);

    if (titleHasChanged || valuesHaveChanged) {
      this.setTitle();
    }
  }

  setTitle = () => {
    const { titleId, intl, values } = this.props;

    let title = 'Kwara';
    if (titleId) {
      title += ` - ${intl.formatMessage({ id: titleId }, values)}`;
    }

    document.title = title;
  };

  render() {
    return null;
  }
}

const PublicHead: React.ComponentType<PropsWithoutIntl> = injectIntl(Head);
export default PublicHead;
