// @flow
import * as React from 'react';
import get from 'lodash/fp/get';
import cx from 'classnames';

import { Attribute } from '@kwara/components/src';
import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import { type CollateralT, CollateralFields } from '@kwara/models/src';

type Props = {
  index: number,
  asset: CollateralT,
  onRemove: (index: number) => void,
  onUndo: (index: number) => void,
  onEdit: (index: number) => void
};

export const CollateralEntry = ({
  asset,
  onRemove,
  onEdit,
  onUndo,
  index
}: Props) => (
  <div
    className={cx('br3 t-1a', {
      'bg-red-100 red-700': asset.isMarkedForDestruction,
      'bg-semi-white-400': !asset.isMarkedForDestruction
    })}
  >
    <div className="flex justify-stretch items-center">
      <Attribute
        labelId="LoanAdd.Security.Collateral.Type"
        className="pl3 w-30 flex-auto b--light-grey-400"
        size="medium"
        value={
          <Text
            id={`LoanAdd.Security.Collateral.Type.${get(
              CollateralFields.type,
              asset
            )}`}
          />
        }
      />
      <Attribute
        labelId="LoanAdd.Security.Collateral.Amount.label"
        className="pl3 w-20 flex-auto br b--light-grey-400"
        size="medium"
        value={get(CollateralFields.amount, asset)}
      />
    </div>

    <div hidden className="flex justify-stretch items-center">
      <Attribute
        labelId="LoanAdd.Security.Collateral.RealValue.label"
        className="pl3 w-20 flex-auto br b--light-grey-400"
        size="medium"
        value={get(CollateralFields.realValue, asset)}
      />
      <Attribute
        labelId="LoanAdd.Security.Collateral.ForcedSellValue.label"
        className="pl3 w-30 flex-auto b--light-grey-400"
        size="medium"
        value={get(CollateralFields.forcedSellValue, asset)}
      />
    </div>

    <Attribute
      labelId="LoanAdd.Security.Collateral.Narration"
      className="pl3 w-30 flex-auto b--light-grey-400"
      size="medium"
      value={get(CollateralFields.narration, asset)}
    />
    <div className="bt b--light-grey-400 flex justify-stretch items-center">
      <div className="pa3 b--light-grey-400">
        {asset.isMarkedForDestruction ? (
          <>
            <Text id="LoanAdd.Security.Guarantor.undoWarning" />
            <Link size="regular" type="primary" onClick={() => onUndo(index)}>
              <Text id="LoanAdd.Security.Guarantor.undo" />
            </Link>
          </>
        ) : (
          <>
            <Link
              size="regular"
              type="destructive"
              onClick={() => onRemove(index)}
            >
              <Text id="LoanAdd.Security.Guarantor.remove" />
            </Link>
            <span className="dib ml3">
              <Link size="regular" type="secondary" onClick={onEdit}>
                <Text id="LoanAdd.Security.Collateral.edit" />
              </Link>
            </span>
          </>
        )}
      </div>
    </div>
  </div>
);
