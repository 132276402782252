// @flow

import { attr, belongsTo } from 'spraypaint';

import cameliseObjectKeys from '../lib/cameliseObjectKeys';
import { type MemberType } from './Member';

import Base, { type BaseModel } from './Base';

export const Guarantor = Base.extend({
  static: {
    jsonapiType: 'guarantors'
  },
  attrs: {
    member: belongsTo('members'),
    memberId: attr(),
    amount: attr()
  },
  methods: {
    memberObject() {
      return cameliseObjectKeys(this.member);
    },
    fullName() {
      this.member.fullName();
    }
  }
});

export const Guarantee = Base.extend({
  static: {
    jsonapiType: 'guarantees'
  },
  attrs: {
    member: belongsTo('members'),
    memberId: attr(),
    amount: attr()
  },
  methods: {
    memberObject() {
      return cameliseObjectKeys(this.member);
    },
    fullName() {
      this.member.fullName();
    }
  }
});

export interface GuaranteeType extends BaseModel<GuaranteeType> {
  member: MemberType;
  memberObject: () => MemberType;
  fullName: () => string;
  amount: number;
  memberId: string;
}

export default (Guarantee: typeof Guarantee);
