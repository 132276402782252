import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { PageHeading } from '@kwara/components/src/text';
import { Loadable } from '@kwara/components/src/Loadable';

import { useUsers } from '../../../../models/request';
import { UserTable } from './UserTable';

export const Team = () => {
  const r = useUsers();

  return (
    <>
      <PageHeading translationId="Settings.Team.titleId" />
      <p className="grey-400 kw-text-medium mb1">
        <Text id="Settings.Team.subtitle" />
      </p>
      <Loadable {...r}>{users => <UserTable users={users} />}</Loadable>
    </>
  );
};
