import camelCase from 'lodash/camelCase';
import isObject from 'lodash/isObject';
import pipe from 'lodash/fp/pipe';
import map from 'lodash/fp/map';
import fromPairs from 'lodash/fp/fromPairs';
import toPairs from 'lodash/fp/toPairs';

// Deeply traverse an object and camelCase every key
export const camelise = object =>
  pipe(
    toPairs,
    map(([key, value]) => {
      if (isObject(value)) {
        return [camelCase(key), camelise(value)];
      }

      return [camelCase(key), value];
    }),
    fromPairs
  )(object);

export default camelise;
