// @flow

import React from 'react';

import { tomorrow } from '@kwara/lib/src/dates';

import { SubscribedDatePicker as DatePicker } from '../../../components/Form';

import { type ComponentPropsLoanReschedule } from '..';

export const RepaymentDate = (props: ComponentPropsLoanReschedule) => {
  const { StackChild } = props;
  return (
    <StackChild>
      <DatePicker
        name="firstRepaymentDate"
        labelId="LoanReschedule.Repayment.firstRepaymentDate.label"
        required
        disabledDays={{ before: tomorrow() }}
      />
    </StackChild>
  );
};

RepaymentDate.validate = {
  firstRepaymentDate: {
    isRequired: () => true
  }
};
