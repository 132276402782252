// @flow
import * as React from 'react';
import { type ContextRouter } from 'react-router-dom';

import Button from '@kwara/components/src/Button';
import { appName } from '@kwara/lib/src/utils';
import {
  WithNotification,
  type notification
} from '@kwara/components/src/Notification';
import { Text } from '@kwara/components/src/Intl';

const UPDATE_CHECK_INTERVAL_MS = 1000 * 60;
const EXPIRY_WINDOW_MS = 1000 * 60 * 5;
const ACTIVITY_WINDOW_MS = 1000 * 60 * 5;

type Props = ContextRouter & {
  getLastActivityTime: () => number,
  notification: notification
};

/**
 * AuthExpiryChecker
 *
 * This component renders no UI. It polls the local auth service
 * and triggers a page reload when it expires. This will then
 * show the login page.
 */
export class AuthExpiryChecker extends React.Component<Props, *> {
  state = {
    inactivityWarningDisplayed: false
  };

  componentDidMount() {
    this.checkExpiration();
  }

  componentWillUnmount() {
    this.cancelPendingTimer();
  }

  updateIntervalMs = UPDATE_CHECK_INTERVAL_MS;
  timerId: ?number;

  cancelPendingTimer = () => {
    if (this.timerId != null) {
      window.clearTimeout(this.timerId);
      this.timerId = null;
    }
  };

  scheduleCheck = () => {
    this.cancelPendingTimer();

    this.timerId = window.setTimeout(
      this.checkExpiration,
      this.updateIntervalMs
    );
  };

  checkExpiration = () => {
    // Already expired
    const { auth, logOut } = this.props;
    if (!auth.isLoggedIn()) {
      this.props.notification.displayWarning(
        <Text id="Notifications.signedOut" />
      );
      this.props.history.push('/');
      logOut();
      return;
    }

    const now = Date.now();
    const expiryDate = auth.getExpiryDate();
    const lastActivityTime = this.props.getLastActivityTime();

    const expiringSoonWindow =
      expiryDate && expiryDate.valueOf() - EXPIRY_WINDOW_MS;
    const isExpiringSoon = now > expiringSoonWindow;

    const recentActivityWindow = now - ACTIVITY_WINDOW_MS;
    const isActive = lastActivityTime > recentActivityWindow;

    if (isExpiringSoon && isActive) {
      this.refreshToken();
    } else if (isExpiringSoon && !isActive) {
      // For the member app we are not enabling user to refresh token if they haven't been active
      // for some time and member is logged out automatically
      if (!appName.isMember) {
        this.displayLogOutWarning();
      }
    }

    this.scheduleCheck();
  };

  refreshToken = () => {
    // refresh token
    const { auth } = this.props;
    auth.refreshToken();

    if (this.state.inactivityWarningDisplayed) {
      this.props.notification.dismiss();
      this.setState({
        inactivityWarningDisplayed: false
      });
    }
  };

  displayLogOutWarning = () => {
    this.props.notification.displayWarning(
      <>
        <Text id="Notifications.signOutWarn" />
        <Button
          className="ml-auto mr3"
          size="small"
          type="secondary"
          onClick={async () => {
            this.setState({
              inactivityWarningDisplayed: false
            });

            // Clicking the button is counted as recent activity
            // which will automatically refresh the token.
            //
            // We display a message so that the user sees that
            // their click has been registered
            this.props.notification.displayMessage(
              <Text id="Notifications.keepSignedIn" />
            );
          }}
        >
          Stay logged in
        </Button>
      </>
    );

    this.setState({
      inactivityWarningDisplayed: true
    });
  };

  render() {
    return null;
  }
}

export default WithNotification(AuthExpiryChecker);
