// @flow

import React from 'react';

import { Image } from '@kwara/components/src/ProfilePhoto';

import { DetailWrapper, Title } from '..';

export const Avatar = () => {
  return (
    <DetailWrapper>
      <div className="flex items-center">
        <Image size="regular" />
        <Title titleId="Settings.Personal.Avatar.title" classNames="ml3" />
      </div>
    </DetailWrapper>
  );
};
