// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';
import pipe from 'lodash/fp/pipe';

import { type SavingType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';

import { Currency } from '../Currency';
import { AccountThumbnail, NoAccounts } from '../AccountThumbnail';

type Props = {
  savings: SavingType[]
};

function filterAccounts(savings: SavingType[]) {
  return pipe(
    filter(s => Number(s.balance)),
    sortBy(s => -Number(s.balance))
  )(savings);
}

export const SavingsPanel = ({ savings }: Props) => {
  const accounts = filterAccounts(savings);

  if (isEmpty(accounts)) {
    return (
      <NoAccounts>
        <Text id="MemberDetail.Overview.deposits.empty" />
      </NoAccounts>
    );
  }

  return (
    <div className="ph3">
      {map(
        saving => (
          <AccountThumbnail
            key={saving.id}
            account={saving}
            amount={<Currency format="currency" value={saving.balance} />}
          />
        ),
        accounts
      )}
    </div>
  );
};
