// @flow

/**
 * Maps z-indices for different parts of the app
 * This avoid havings z-indices hard-coded everywhere
 * making changing the levels difficult
 *
 * Currently the order of layers, from top to bottom
 *
 * Modal
 * Global Navigation
 * Everything else
 */

export default {
  Modal: 'z-999',
  Banner: 'z-5',
  Navigation: 'z-4',
  DebugSwitchUser: 'z-3',
  OverlayMenus: 'z-2',
  Base: ''
};
