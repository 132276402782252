import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import { Small } from '@kwara/components/src/text';
import { ActionButton } from '@kwara/components/src/Button';
import style from './index.module.scss';

export function ImportTemplate({ StackChild }) {
  return (
    <StackChild>
      <div
        className={cx(
          style.button,
          style.downloadButton,
          'relative ba br3 bw1 b--light-gray h3 dim pa3 mb3 flex justify-between'
        )}
      >
        <div className="dib v-mid mt1">
          <Small translationId="0.05kb" />
          <br />
          <Text id="Onboarding.ImportTemplate.downloadButton.label" />
        </div>
        <div>
          <ActionButton
            style={{ transform: 'rotate(90deg)' }}
            onClick={() => 'Clicked'}
          />
        </div>
      </div>

      <div
        className={cx(
          style.uploadButton,
          'relative ba br3 bw1 b--light-gray h3 dim pa3 mb3 flex justify-between b--dashed'
        )}
      >
        <p class={cx(style.uploadText, 'ma0 grey-300 kw-text-regular')}>
          <Text id="Onboarding.ImportTemplate.uploadButton.label" />
        </p>
      </div>
    </StackChild>
  );
}
