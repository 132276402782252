//@flow

import * as React from 'react';

import { type SubStepComponentProps } from '../../../components/Wizard';

export default ({ StackChild, TextField }: SubStepComponentProps<>) => (
  <StackChild>
    <TextField
      name="postalAddress"
      errorBaseId="AddMember.Personal.DateOfBirth"
      labelId="AddMember.Address.Postal.label"
      placeholderId="AddMember.Address.Postal.placeholder"
    />
    <TextField
      name="physicalAddress"
      errorBaseId="AddMember.Personal.DateOfBirth"
      labelId="AddMember.Address.Physical.label"
      placeholderId="AddMember.Address.Physical.placeholder"
      infoId="AddMember.Address.Physical.info"
    />
  </StackChild>
);
