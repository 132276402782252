// @flow
import * as React from 'react';

import type { LoadableBasePropsT } from '@kwara/components/src/Loadable';
import {
  Bank,
  BankAccount,
  BankGlAccount,
  MemberEligibility,
  Member,
  Loan,
  Saving,
  Schedule,
  type BankT,
  type BankAccountT,
  type IncludesT,
  type MemberType,
  type MemberEligibilityT,
  type BankGlAccountT,
  type LoanType,
  type SavingType,
  type ScheduleType
} from '../..';
import { useModelRequest, useModelsRequest, isEMPTY } from '.';

export { isEMPTY };

const defaultMemberIncludes = [
  'loans',
  'savings',
  'addresses',
  'next_of_kin',
  'attachments',
  'id_documents'
];
export const useMember = (
  id: string,
  includes: IncludesT = defaultMemberIncludes
) => useModelRequest<MemberType>(Member, id, includes);

const defaultBankGlAccountsIncludes = [];
export const useBankGlAccounts = (
  includes: IncludesT = defaultBankGlAccountsIncludes
) => useModelsRequest<BankGlAccountT>(BankGlAccount, includes);

const defaultLoanIncludes = ['member', 'product', 'guarantees.member'];
export const useLoan = (
  id: string,
  includes: IncludesT = defaultLoanIncludes
): LoadableBasePropsT<LoanType> =>
  useModelRequest<LoanType>(Loan, id, includes);

export const useLoans = (
  includes: IncludesT = defaultLoanIncludes
): LoadableBasePropsT<LoanType> => useModelsRequest<LoanType>(Loan, includes);

export const defaultSavingIncludes = ['member', 'product'];
export const useSaving = (
  id: string,
  includes: IncludesT = defaultSavingIncludes
): LoadableBasePropsT<SavingType> =>
  useModelRequest<SavingType>(Saving, id, includes);

export const defaultUseMemberEligibilityIncludes = ['product'];
export const useMemberEligibility = (
  memberId: string,
  includes: IncludesT = defaultUseMemberEligibilityIncludes
) => {
  const scope = React.useMemo(() => MemberEligibility.id(memberId), [memberId]);
  return useModelsRequest<MemberEligibilityT>(scope, includes);
};
export const defaultScheduleIncludes = [];
export const useSchedule = (
  id: string,
  includes: IncludesT = defaultScheduleIncludes
) => useModelRequest<ScheduleType>(Schedule, id, includes);

const defaultBankIncludes = [];
export const useBanks = (includes: IncludesT = defaultBankIncludes) =>
  useModelsRequest<BankT>(Bank, includes);

const defaultBankAccountIncludes = [];
export const useBankAccounts = (
  memberId: string,
  includes: IncludesT = defaultBankAccountIncludes
) => {
  const where = React.useMemo(() => ({ mambu_id: memberId }), [memberId]);
  return useModelsRequest<BankAccountT>(BankAccount, includes, where);
};
