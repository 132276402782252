import * as React from 'react';

export function SaccoDetails({ StackChild, TextField, Checkbox }) {
  return (
    <StackChild>
      <TextField
        name="sacco.name"
        errorBaseId="Onboarding.SaccoDetails.Name"
        labelId="Onboarding.SaccoDetails.Name.label"
      />
      <TextField
        name="sacco.physicalAddress"
        errorBaseId="Onboarding.SaccoDetails.PhysicalAddress"
        labelId="Onboarding.SaccoDetails.PhysicalAddress.label"
      />
      <TextField
        name="sacco.kraPin"
        errorBaseId="Onboarding.SaccoDetails.TaxPin"
        labelId="Onboarding.SaccoDetails.TaxPin.label"
      />
      <Checkbox
        name="sacco.approvedInfoSharing"
        labelId="Onboarding.SaccoDetails.ApprovedInfoSharing.label"
      />
      <Checkbox
        name="sacco.sasraRegulated"
        labelId="Onboarding.SaccoDetails.SasraRegulated.label"
      />
    </StackChild>
  );
}
