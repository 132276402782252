// @flow
import * as React from 'react';
import get from 'lodash/fp/get';
import cx from 'classnames';

import { type EventT } from '@kwara/models/src';
import { DateTime, Text } from '@kwara/components/src/Intl';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';

import { AccountLink } from './AccountLink';
import styles from './index.module.scss';

type Props = {
  event: EventT
};

export const EventItem = ({ event }: Props) => {
  const { user, time, name } = event;
  const entity = event.entity();

  const accountId = get('properties.account_id', event);

  return (
    <div className={cx('flex justify-start items-start', styles.EventItem)}>
      <div className={cx('flex flex-column', styles.Indicator)} />

      <div className="flex-m flex-l">
        <time
          className={cx(
            'nowrap flex-none kw-text-small grey-300',
            styles.Timestamp
          )}
          dateTime={time}
          title={time}
        >
          <DateTime value={time} />
        </time>

        <div className="mr3 flex-none dn db-m db-l">
          <ProfilePhoto size="small" />
        </div>

        <div className={styles.Info}>
          <p className="mt2 kw-text-regular">
            <span className="b">{user.fullName()}</span>{' '}
            <Text id={`EventItem.event.${name}`} />{' '}
            {accountId ? (
              <AccountLink entity={entity} accountId={accountId} />
            ) : null}
          </p>
        </div>
      </div>
    </div>
  );
};
