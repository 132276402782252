// @flow

import * as React from 'react';
import some from 'lodash/fp/some';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';

import {
  type IncludesT,
  type WhereT,
  type SupervisorMetricT,
  type TillMetricT,
  type LoanProductType,
  type MemberType,
  type LoanType
} from '@kwara/models/src';

export type ErrorMsg = {
  message: string,
  status?: number
};
export type ErrorObject = Response & {
  messages: ErrorMsg[]
};

type CombinedRequestProps<T> = {
  errors: (ErrorObject | {})[],
  isPending: boolean,
  data: T | {}
};

export type BaseRequestProps<T> = {
  error: ErrorObject | {},
  isPending: boolean,
  data: T | {}
};

export type RequestProps<T> = BaseRequestProps<T> & {
  refetch: () => void
};
export type requestParamsT = string | (WhereT | IncludesT | string | number)[];

export const EMPTY = {};

export function combineRequests(...reqs: Array<CombinedRequestProps<any>>) {
  return {
    isPending: some(get('isPending'), reqs),
    errors: map(get('error'), reqs),
    data: map<any, any>(get('data'), reqs)
  };
}

export function isEMPTY(data: any) {
  return data === EMPTY;
}

export function hasErrors(error: ?ErrorMsg) {
  if (error == null) {
    return false;
  }
  return error !== EMPTY;
}

export function getFirstError(errors: ErrorMsg[]) {
  return find(hasErrors, errors) || EMPTY;
}

export async function formatErrorObject(e: Error) {
  try {
    const errorBody = await e.response.clone().json();
    const res = e.response;
    res.messages = errorBody.errors;
    return res;
  } catch (_) {
    const status = get('response.status', e);
    return { status, messages: [{ title: 'Unknown error' }] };
  }
}

type SupportedEntitiesAllT = SupervisorMetricT | TillMetricT | LoanProductType;
export function useModelsRequest<T>(
  entity: SupportedEntitiesAllT,
  includes?: IncludesT,
  where?: WhereT = null,
  per: ?number = null,
  page: ?number = null
): RequestProps<T> {
  const [isPending, setPending] = React.useState(true);
  const [error, setError] = React.useState(EMPTY);
  const [result, setResult] = React.useState<T | {}>(EMPTY);
  const [meta, setMeta] = React.useState<T | {}>(EMPTY);
  const [reqId, setReqId] = React.useState(1);
  const refetch = React.useCallback(() => {
    setReqId(n => n + 1);
  }, []);
  React.useEffect(() => {
    let unmounted = false;
    const req = async () => {
      try {
        setPending(true);
        const { data, meta: resMeta } = await entity
          .stats({ total: ['count', 'pages'] })
          .where(where)
          .includes(includes)
          .per(per)
          .page(page)
          .all();

        if (!unmounted) {
          setResult(data);
          setMeta(resMeta);
        }
      } catch (e) {
        setError(await formatErrorObject(e));
      } finally {
        setPending(false);
      }
    };

    req();

    return () => {
      unmounted = true;
    };
  }, [entity, includes, where, reqId, per, page]);
  return {
    data: result,
    error,
    isPending,
    refetch,
    meta
  };
}

type SupportedEntitiesByIdT = MemberType | LoanType;
export function useModelRequest<T>(
  entity: SupportedEntitiesByIdT,
  id: string,
  includes: IncludesT
): RequestProps<T> {
  const [isPending, setPending] = React.useState(true);
  const [error, setError] = React.useState(EMPTY);
  const [result, setResult] = React.useState<T | {}>(EMPTY);
  const [reqId, setReqId] = React.useState(1);
  const refetch = React.useCallback(() => {
    setReqId(n => n + 1);
  }, []);
  React.useEffect(() => {
    let unmounted = false;
    const req = async () => {
      try {
        setPending(true);
        const { data } = await entity.includes(includes).find(id);
        if (!unmounted) {
          const res = data.deserialize();
          setResult(res);
        }
      } catch (e) {
        setError(await formatErrorObject(e));
      } finally {
        setPending(false);
      }
    };

    if (id) {
      req();
    }

    return () => {
      unmounted = true;
    };
  }, [entity, id, includes, reqId]);
  return {
    data: result,
    error,
    isPending,
    refetch
  };
}
