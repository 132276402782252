import * as React from 'react';
import filter from 'lodash/fp/filter';
import includes from 'lodash/fp/includes';
import forEach from 'lodash/fp/forEach';
import keys from 'lodash/fp/keys';

import { Loadable, EMPTY } from '@kwara/components/src/Loadable';
import { AppRole } from '@kwara/models/src';

import Wizard from '../../components/Wizard';
import { useAppPermissions, useAppRole } from '../../models/request';
import { steps } from './config';

// The following two functions are a hack because
// our Wizard / forms do not play well with arrays,
// particularly in the "Edit" scenario.
function formatInitialPermissions(appRole) {
  const perms = {};
  forEach(p => {
    perms[p.name] = true;
  }, appRole.appPermissions);
  return perms;
}

// We have to convert the data into an object with booleans,
// i.e. { ADD_MEMBER: true, APPROVE_MEMBER: false } for the duration
// of the form, and upon data submission "prepare" this object
// to then pick the correct spraypaint Permission objects
function prepare(permissionsObject) {
  return filter(p => permissionsObject[p], keys(permissionsObject));
}

function useInitialRole(roleId) {
  const r = useAppRole(roleId);
  if (!roleId) {
    return { data: new AppRole(), isPending: false, error: EMPTY };
  }
  return r;
}

async function createOrUpdate(data) {
  const { allAppPermissions, permissions, appRole } = data;

  const relevantPermissionNames = prepare(permissions);

  const appPermissions = filter(
    p => includes(p.name, relevantPermissionNames),
    allAppPermissions
  );

  // If editing, the new permissions will
  // competely override the previous permissions
  appRole.appPermissions = appPermissions;

  const didSave = await appRole.save({ with: 'appPermissions.id' });

  if (!didSave) {
    throw appRole.errors;
  }
}

export const AppRoleWizard = ({ baseUrl, history, match }) => {
  const r1 = useAppPermissions();
  const r2 = useInitialRole(match.params.roleId);

  return (
    <Loadable {...r1}>
      {allAppPermissions => (
        <Loadable {...r2}>
          {appRole => (
            <Wizard
              baseUrl={baseUrl}
              history={history}
              initialData={{
                allAppPermissions,
                appRole,
                permissions: formatInitialPermissions(appRole),
                // Hack: This extra key is used only by the validator.
                // We initialize with the dummy value ("REQUIRED", but it can be anything)
                // We require this field by the validator, so it is not skipped, and
                // within the rule, we check that at least one other checkbox is checked
                atLeastOneChecked: 'REQUIRED'
              }}
              currentStep={match.params.step}
              currentSubStep={
                match.params.subStep != null
                  ? parseInt(match.params.subStep, 10)
                  : null
              }
              onSubmit={createOrUpdate}
              steps={steps}
              startId="config"
              titleId="AppRoleWizard.shortTitle"
            />
          )}
        </Loadable>
      )}
    </Loadable>
  );
};
