// @flow

import * as React from 'react';
import Downshift from 'downshift';
import throttle from 'lodash/throttle';
import classnames from 'classnames';

import { Member, type MemberType } from '@kwara/models/src';
import { Stack } from '@kwara/components/src/Stack';
import zIndices from '@kwara/lib/src/zIndices';

import ResultList, { type Props as ResultListProps } from './ResultList';
import ResultLabel from './ResultLabel';
import SearchTextField from './SearchTextField';
import SearchManager from '../SearchManager';

import styles from './index.module.scss';

type SearchTextFieldProps = {
  value: string,
  searching: boolean
};

type ChangeHandler = (evt: SyntheticInputEvent<>) => void;
type GetInputProps = () => { onChange: ChangeHandler, value: string };

type ControlledProps = SearchTextFieldProps &
  ResultListProps & {
    floatResults: boolean,
    getInputProps: GetInputProps,
    resultsVisible: boolean,
    showResultCount: boolean
  };

export const Controlled = ({
  collapsed,
  floatResults,
  getInputProps,
  getItemProps,
  isItemDisabled,
  results,
  resultsVisible,
  resultType,
  highlightedIndex,
  searching,
  showResultCount,
  value,
  ...rest
}: ControlledProps) => (
  <div
    className={classnames(
      styles.MemberSearch,
      showResultCount ? styles.withResultCount : styles.withoutResultCount,
      floatResults ? styles.floatResults : null
    )}
    data-testid="MemberSearch"
  >
    <Stack align={floatResults ? 'start' : 'center'}>
      <Stack.Child size="regular">
        <SearchTextField
          {
            ...getInputProps(/*{
            onKeyDown: event => {
              if (event.key === 'Escape') {
                // Prevent Downshift's default 'Enter' behavior.
                // event.nativeEvent.preventDownshiftDefault = true

                // your handler code
              }
            },
          }*/)
          }
          data-cy={rest['data-cy']}
          name="member-search-input"
          searching={searching}
        />
      </Stack.Child>

      {resultsVisible && showResultCount && (
        <Stack.Child>
          <ResultLabel numResults={results.length} searchTerm={value} />
        </Stack.Child>
      )}

      <div className={`${styles.ResultContainer} ${zIndices.Navigation}`}>
        <Stack.Child size="wide">
          {resultsVisible && (
            <ResultList
              collapsed={collapsed}
              floatResults={floatResults}
              highlightedIndex={highlightedIndex}
              getItemProps={getItemProps}
              results={results}
              resultType={resultType}
              searching={searching}
              showResultCount={showResultCount}
              value={value}
              isItemDisabled={isItemDisabled}
            />
          )}
        </Stack.Child>
      </div>
    </Stack>
  </div>
);

type Props = {
  collapsed: boolean,
  onSelect: (member: MemberType) => void | Promise<void>,
  isItemDisabled?: (member: MemberType) => boolean,
  resultType: 'financial' | 'nonFinancial',
  showResultCount: boolean,
  floatResults: boolean,
  'data-cy'?: string
};

type DownshiftState = {
  inputValue?: string,
  isOpen?: boolean,
  selectedItem?: ?MemberType
};

type DownshiftRenderProps = {
  getInputProps: () => { [string]: any },
  getItemProps: () => { [string]: any },
  inputValue: string,
  isOpen: boolean,
  selectedItem: ?MemberType,
  highlightedIndex: number
};

export default class MemberSearch extends React.Component<Props, *> {
  static defaultProps = {
    collapsed: false,
    floatResults: false,
    resultType: 'nonFinancial',
    showResultCount: true
  };

  static inputThrottleMs = 500;

  constructor(props: Props) {
    super(props);

    this._searchManager = new SearchManager(Member);
    this._searchManager.results(this.receiveResults);
    this._searchManager.stateChange(this.searchStateChange);
  }

  _searchManager: SearchManager;

  state = {
    isSearchPending: false,
    items: []
  };

  receiveResults = (items: MemberType[]) => {
    this.setState({ items });
  };

  searchStateChange = ({ isSearchPending }: { isSearchPending: boolean }) => {
    this.setState({
      isSearchPending
    });
  };

  reset = () => {
    this._searchManager.cancelPendingSearches();
    this.setState({ items: [] });
  };

  _search = async (inputValue: string) => {
    if (inputValue == null || inputValue === '') {
      this.reset();
      return;
    }

    this._searchManager.search({ term: inputValue });
  };

  search = throttle(this._search, MemberSearch.inputThrottleMs, {
    leading: true,
    trailing: true
  });

  select = (
    member: MemberType,
    { clearSelection }: { clearSelection: () => void }
  ) => {
    if (member == null) {
      return;
    }

    if (this.props.returnFullMember) {
      return Member.includes(['savings.product', 'loans.product'])
        .find(member.id)
        .then(r => r.data)
        .then(fullMember => {
          this.props.onSelect(fullMember);
        })
        .finally(() => {
          this.reset();
          clearSelection();
        });
    }

    this.props.onSelect(member);
    this.reset();
    clearSelection();
  };

  getMemberName = (member: MemberType) => {
    return member ? member.fullName() : null;
  };

  stateChange = (changes: DownshiftState) => {
    if (changes.hasOwnProperty('inputValue')) {
      this.search(changes.inputValue);
    }
  };

  stateReducer = (state: DownshiftState, changes: DownshiftState) => {
    // Clear input when an item is selected
    if (changes.hasOwnProperty('selectedItem')) {
      return {
        ...changes,
        inputValue: ''
      };
      // Clears input when is closes (for example, Escape key is pressed)
    } else if (changes.hasOwnProperty('isOpen') && changes.isOpen === false) {
      return {
        ...changes,
        inputValue: '',
        isOpen: state.isOpen
      };
    }

    return changes;
  };

  renderSearch = ({
    getInputProps,
    getItemProps,
    inputValue,
    isOpen,
    highlightedIndex
  }: DownshiftRenderProps) => (
    <div>
      <Controlled
        collapsed={this.props.collapsed}
        floatResults={this.props.floatResults}
        getInputProps={getInputProps}
        highlightedIndex={highlightedIndex}
        resultType={this.props.resultType}
        results={this.state.items}
        resultsVisible={isOpen}
        value={inputValue}
        getItemProps={getItemProps}
        searching={this.state.isSearchPending}
        showResultCount={this.props.showResultCount}
        isItemDisabled={this.props.isItemDisabled}
        data-cy={this.props['data-cy']}
      />
    </div>
  );

  render() {
    return (
      <Downshift
        itemToString={this.getMemberName}
        onChange={this.select}
        onStateChange={this.stateChange}
        stateReducer={this.stateReducer}
      >
        {this.renderSearch}
      </Downshift>
    );
  }
}
