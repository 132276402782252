// @flow

import * as React from 'react';
import Menu, { MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import classnames from 'classnames';

import Actionable from '@kwara/components/src/Actionable';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import zIndices from '@kwara/lib/src/zIndices';

import styles from './index.module.scss';

type Props = {
  children: React.Node,
  className?: string,
  title?: string,
  titleId?: string,
  onClick?: () => void,
  disabled?: boolean,
  type?: 'primary' | 'secondary' | 'destructive'
};

type ButtonItemProps = {
  children: React.Node,
  className?: string,
  disabled?: boolean
};

type State = {
  isOpen: boolean
};

const DefaultButton = ({
  onClick,
  title,
  titleId,
  disabled,
  type = 'primary'
}: Props) => (
  <Button
    size="medium"
    type={type}
    glyphRightId={Button.Glyphs.ChevronDown}
    onClick={onClick}
    disabled={disabled}
  >
    {titleId ? <Text id={titleId} /> : title}
  </Button>
);

const noOp = () => {};

const Item = ({
  className,
  to,
  children,
  disabled,
  ...rest
}: ButtonItemProps) => (
  <MenuItem
    className={classnames(className, styles.menuItem)}
    disabled={disabled}
    onItemHover={noOp}
    onClick={noOp}
    {...rest}
  >
    {to ? (
      <Actionable disabled={disabled} to={to}>
        {children}
      </Actionable>
    ) : (
      <span>{children}</span>
    )}
  </MenuItem>
);

export class ButtonMenu extends React.Component<Props, State> {
  static defaultProps = {
    Button: DefaultButton
  };
  static Item = Item;

  state = { isOpen: false };

  toggle = () => this.setState({ isOpen: !this.state.isOpen });
  open = () => this.setState({ isOpen: true });
  close = () => this.setState({ isOpen: false });

  componentDidUpdate(_prevProps: Props, prevState: State) {
    const menuDidOpen =
      prevState.isOpen === false && this.state.isOpen === true;
    const menuDidClose =
      prevState.isOpen === true && this.state.isOpen === false;

    if (menuDidOpen) {
      this.addOutsideHandler();
      this.adjustMenuPosition();
    } else if (menuDidClose) {
      this.removeOutsideHandler();
    }
  }

  componentWillUnmount() {
    this.removeOutsideHandler();
  }

  adjustMenuPosition = () => {
    const menu = this.menuRef.current;
    const button = this.ref.current;

    if (menu == null || button == null) {
      return;
    }

    const menuPosition = menu.getBoundingClientRect();
    const buttonPosition = button.getBoundingClientRect();

    const menuDoesOverlapY = menuPosition.top < buttonPosition.bottom;
    const menuIsOffscreenRight = window.innerWidth < menuPosition.right;
    const menuIsOffscreenLeft = menuPosition.left < 0;

    const transforms = [];

    if (menuDoesOverlapY) {
      transforms.push(
        `translateY(${buttonPosition.bottom - menuPosition.top}px)`
      );
    }

    if (menuIsOffscreenRight) {
      transforms.push(
        `translateX(${window.innerWidth - menuPosition.right}px)`
      );
    } else if (menuIsOffscreenLeft) {
      transforms.push(`translateX(-${menuPosition.left}px)`);
    }

    if (transforms.length > 0) {
      menu.style.transform = transforms.join(' ');
    }
  };

  outsideClick = (evt: MouseEvent) => {
    if (this.ref.current == null) {
      return;
    }

    const isOutside = !this.ref.current.contains(evt.target);

    if (isOutside) {
      this.close();
    }
  };

  keyPress = (evt: KeyboardEvent) => {
    if (evt.key === 'Escape') {
      this.close();
    }
  };

  menuRef = React.createRef();
  ref = React.createRef();

  addOutsideHandler = () => {
    const body = document.body;
    if (body) {
      body.addEventListener('click', this.outsideClick, true);
      body.addEventListener('keyup', this.keyPress, true);
    }
  };

  removeOutsideHandler = () => {
    const body = document.body;
    if (body) {
      body.removeEventListener('click', this.outsideClick, true);
      body.removeEventListener('keyup', this.keyPress, true);
    }
  };

  getPopupContainer = () => this.ref.current;

  render() {
    const {
      Button,
      className,
      children,
      disabled,
      title,
      titleId,
      type
    } = this.props;

    return (
      <div className={styles.container} ref={this.ref}>
        <Button
          disabled={disabled}
          type={type}
          title={title}
          titleId={titleId}
          onClick={this.toggle}
        />
        <div
          className={classnames(styles.menuContainer, zIndices.OverlayMenus)}
          hidden={!this.state.isOpen}
          ref={this.menuRef}
        >
          <Menu
            className={classnames(className, styles.menu)}
            getPopupContainer={this.getPopupContainer}
          >
            {children}
          </Menu>
        </div>
      </div>
    );
  }
}
