// @flow

import { attr } from 'spraypaint';

import Base, { BaseModel } from './Base';
import { type LoanState } from './Loan';

export const LoanAccountCrbSubmissions = Base.extend({
  static: {
    jsonapiType: 'loan_account_crb_submissions'
  },
  attrs: {
    loanId: attr(),
    state: attr()
  },
  methods: {}
});

export interface LoanAccountCrbSubmissionsT
  extends BaseModel<LoanAccountCrbSubmissionsT> {
  loanId: string;
  state: {
    current: LoanState,
    permitted_events: string[]
  };
}
