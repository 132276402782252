// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import isArray from 'lodash/fp/isArray';
import find from 'lodash/fp/find';
import min from 'lodash/fp/min';

import Banner from '@kwara/components/src/Banner';
import { GlContexts, type SavingType } from '@kwara/models/src';
import { Text, Currency } from '@kwara/components/src/Intl';
import { getCurrentDate } from '@kwara/lib/src/dates';
import { Statistic } from '@kwara/components/src/Statistic';
import {
  SubscribedPaymentSelectField,
  GlAccountSelect
} from '@kwara/components/src/Form';
import {
  TransactionChannels,
  getTransactionTypes,
  contexts
} from '@kwara/models/src/models/Transactions';
import { ERRORS } from '@kwara/lib/src/validator';

import { type SubStepComponentProps } from '../../../components/Wizard';
import MemberPanel from '../../../components/Payment/MemberPanel';
import NotesPanel from '../../../components/Payment/NotesPanel';
import { Grid } from '../../../components/Grid';
import { Panel } from '../../../components/ActionModal';
import { DatePicker, Field as BasicField } from '../../../components/Form';

import { store } from '../../../models/Store';

export const showBanksFieldsFor = [TransactionChannels.bankTransfer];

export const withdrawalConfig = {
  bank: 'bankName',
  methods: getTransactionTypes(contexts.Withdrawal).values,
  showBanksFieldsFor
};

function getSelectedAccount({ accounts, accountId, saving }) {
  const selectedAccount = saving
    ? saving
    : find<SavingType>({ id: accountId }, accounts);

  return selectedAccount;
}

function getMaxWithdrawalAmounts(
  account: SavingType
): { actual: number, available: number | string } {
  return {
    actual: account.balance,
    available: account.availableBalance()
  };
}

export const PaymentForm = ({
  SelectField,
  StackChild,
  TextField,
  data,
  addData,
  formProps
}: SubStepComponentProps<FormData>) => {
  const Option = SelectField.Option;

  const { values } = formProps;
  const { method, accountId, amount } = values;
  const { member, accounts, saving } = data;

  const activateMakerChecker = amount && amount > 400000;

  const selectedAccount = getSelectedAccount({
    accounts,
    accountId,
    saving
  });

  const { actual, available } = getMaxWithdrawalAmounts(selectedAccount);

  const hasAccountSelection = isArray(accounts);

  return (
    <StackChild>
      <MemberPanel member={member} showFinancialInfo />
      <Panel>
        <div className="bb b--light-grey-400 mb4">
          {hasAccountSelection ? (
            <SelectField
              name="accountId"
              size="medium"
              labelId="PaymentForm.account"
            >
              {map<SavingType, React.Element<Option>>(
                account => (
                  <Option key={account.id} value={account.id}>
                    {account.id} &mdash; {account.product.name}
                  </Option>
                ),
                accounts
              )}
            </SelectField>
          ) : null}

          {!hasAccountSelection && selectedAccount ? (
            <Statistic
              size="medium"
              title={<Text id="PaymentForm.account" />}
              value={`${selectedAccount.id} — ${selectedAccount.product.name}`}
            />
          ) : null}
        </div>
        <Grid columns={2} width="w-50" border={false}>
          <TextField
            required
            name="amount"
            errorBaseId="WithdrawalForm.amount"
            size="medium"
            leftGlyph="Currency.orgCurrency"
            labelId="PaymentForm.amount"
            info={
              <div className="kw-text-small">
                <div className="flex justify-between">
                  <Text
                    id="MemberWithdrawal.actualBalance"
                    values={{ actual }}
                  />
                  <Currency value={actual} />
                </div>
                <div className="flex justify-between">
                  <Text id="MemberWithdrawal.availableBalance" />
                  <Currency value={available} />
                </div>
              </div>
            }
          />
          <BasicField
            required
            disabled
            name="date"
            size="medium"
            labelId="SavingPayment.dateLabel"
          >
            <DatePicker name="date" disabled value={getCurrentDate()} />
          </BasicField>
        </Grid>

        {activateMakerChecker ? (
          <Banner
            className="mb3"
            text="This transaction will be created in a pending state, and will require approval from the Branch Operations Manager"
            state="warning"
          />
        ) : null}

        <SubscribedPaymentSelectField
          name="method"
          config={withdrawalConfig}
          required
          // see ch10266
          inputOnChange={() => addData({ bankGlId: '' })}
        />

        <GlAccountSelect
          method={method}
          addData={addData}
          context={GlContexts.WITHDRAWAL}
        />

        {method ? (
          <TextField
            name="reference"
            size="medium"
            labelId="PaymentForm.reference"
          />
        ) : null}
      </Panel>
      <NotesPanel />
    </StackChild>
  );
};

const ifBank = {
  isRequired: (_, allData) => showBanksFieldsFor.includes(allData.method)
};

PaymentForm.validate = ({
  currentTillAmount = store.currentTill.currentAmount,
  stimaBalanceMinimums = store.stimaBalanceMinimums
}) => ({
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom: (target, data) => {
      const { accountId, accounts, saving, method } = data;

      const selectedAccount = getSelectedAccount({
        accounts,
        accountId,
        saving
      });

      const { actual, available } = getMaxWithdrawalAmounts(selectedAccount);
      const amount = Number(target);

      // Sometimes the available balance returned from the api is higher than the actual
      // See comments to this CH story https://app.clubhouse.io/getkwara/story/12420/authorization-holds-improvements#activity-12548
      // The FE should validate the with the less value as the maximum withdrawable amount.
      if (amount > min([Number(available), Number(actual)])) {
        return ERRORS.rangeOverflow;
      }

      if (amount > Number(currentTillAmount) && method === 'cash') {
        return 'tillOverflow';
      }

      const productId = selectedAccount.product.id;
      const productMinimum = stimaBalanceMinimums[productId];

      if (Number(selectedAccount.balance) - amount < productMinimum) {
        return 'accountMinimum';
      }

      return null;
    }
  },
  method: { isRequired: () => true },
  bankName: ifBank,
  bankBranch: ifBank,
  accountNumber: ifBank,
  ...GlAccountSelect.validate
});
