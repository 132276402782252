// @flow

import * as React from 'react';

import { InvitationModel } from '@kwara/models/src';

import Wizard from '../../components/Wizard';
import { type WizardPageProps } from '..';

import { steps } from './steps';

export type FormData = {
  email: string,
  roleId: string
};

const createInvitation = async (data: FormData) => {
  const invitation = new InvitationModel(data);

  const didSave = await invitation.save();

  if (!didSave) {
    throw invitation.errors;
  }
};

type Params = { step?: string };

export const InviteUser = (props: WizardPageProps<Params>) => {
  const { baseUrl, match, history } = props;
  return (
    <Wizard
      analyticsId="InviteUser"
      baseUrl={baseUrl}
      history={history}
      cancelReturnsTo={baseUrl}
      currentStep={match.params.step}
      initialData={{}}
      onSubmit={createInvitation}
      steps={steps}
      startId="user"
      titleId="InviteUser.titleId"
    />
  );
};
