// @flow
import * as React from 'react';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';

import { Text } from '@kwara/components/src/Intl';
import { type ActivityType } from '@kwara/models/src';
import { userFriendlyName } from '@kwara/models/src/models/Activity';

import ActivityItem from './ActivityItem';
import styles from './index.module.scss';

type Props = {
  activities: ActivityType[]
};

const titleForActivity = (activity: ActivityType) => (
  <Text
    id={`ActivityTimeline.${activity.activityType}.title`}
    values={activity}
  />
);

const bodyForActivity = (activity: ActivityType) => (
  <>
    {activity.notes ? (
      activity.notes
    ) : (
      <Text
        id={`ActivityTimeline.${activity.activityType}.body`}
        values={activity}
      />
    )}
    {isEmpty(activity.fieldChanges) ? null : (
      <div>
        <span className="kw-weight-bold">
          <Text id="ActivityTimeline.fieldChanges" />
        </span>
        <ul>
          {map(change => {
            const original = change.original_value;
            const newVal = change.new_value;
            return (
              <li key={newVal}>
                {userFriendlyName(change.field_change_name)} (
                {original && newVal ? (
                  <Text
                    id="ActivityTimeline.fieldChanges.values"
                    values={{
                      original,
                      new: newVal
                    }}
                  />
                ) : (
                  <Text
                    id="ActivityTimeline.fieldChanges.values.newOnly"
                    values={{
                      new: newVal
                    }}
                  />
                )}
                )
              </li>
            );
          }, activity.fieldChanges)}
        </ul>
      </div>
    )}
  </>
);

const metaForActivity = (activity: ActivityType) => (
  <>
    {activity.objectId && activity.type ? (
      <Text
        id={`ActivityTimeline.meta.${activity.type}`}
        values={{ id: activity.objectId }}
      />
    ) : null}
  </>
);

export default ({ activities }: Props) => (
  <ul className={`list ma0 pa0 ${styles.Timeline}`}>
    {orderBy<ActivityType>(activities, ['timestamp'], ['desc']).map(
      (activity: ActivityType) => (
        <li key={activity.id} className={styles.ActivityItemWrapper}>
          <ActivityItem
            date={activity.timestamp}
            body={bodyForActivity(activity)}
            title={titleForActivity(activity)}
            meta={metaForActivity(activity)}
          />
        </li>
      )
    )}
  </ul>
);
