// @flow

import { attr, hasOne } from 'spraypaint';

import Base, { type BaseModel } from './Base';
import LoanProduct, { type LoanProductType } from './LoanProduct';

export const MemberEligibility = Base.extend({
  static: {
    jsonapiType: 'loan_eligibility',

    id(id) {
      return MemberEligibility.extend({
        static: { endpoint: `/members/${id}/loan_eligibility` }
      });
    }
  },
  attrs: {
    eligibleAmount: attr(),
    multiplier: attr(),
    product: hasOne({ type: LoanProduct })
  }
});

export interface MemberEligibilityT extends BaseModel<MemberEligibilityT> {
  eligibleAmount: number;
  multiplier: number;
  product: LoanProductType;
}
