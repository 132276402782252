//@flow

import React from 'react';

import { getCurrentDate } from '@kwara/lib/src/dates';
import {
  SubscribedTextField as TextField,
  SubscribedPaymentSelectField
} from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';
import {
  getTransactionTypes,
  contexts
} from '@kwara/models/src/models/Transactions';
import { SubscribedTextArea as TextArea } from '@kwara/components/src/Wizard/FormFields';

import {
  AllGeneralLedgerAccountSelectField,
  Field as BasicField,
  DatePicker
} from '../../../../../components/Form';
import { FormActionModal } from '../../../../../components/FormActionModal';
import { Grid } from '../../../../../components/Grid';

import { ModalAction } from '../../..';

type Props = {
  updateModal: ModalAction,
  submitUtilityPayment: () => void
};

const config = {
  beneficiary: {
    isRequired: () => true
  },
  invoiceNumber: {
    isRequired: () => true
  },
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true
  },
  paymentMethod: {
    isRequired: () => true
  },
  glCode: {
    isRequired: () => true
  }
};

export const utilitiesConfig = {
  methods: getTransactionTypes(contexts.Utility).values
};

const Panel = FormActionModal.Panel;

export const Utilities = ({ submitUtilityPayment, updateModal }: Props) => (
  <FormActionModal
    config={config}
    initialValues={{ paymentMethod: 'cash' }}
    onSubmit={data => submitUtilityPayment(data)}
    onCancel={() => updateModal({ activeModal: null })}
    titleId="Teller transfer"
    confirmId="Till.Utilities.Modal.confirm"
  >
    <Panel>
      <div className="mb3">
        <span className="kw-text-large kw-weight-bold ">
          <Text id="Teller transfer" />
        </span>
      </div>
      <TextField
        name="beneficiary"
        labelId="Till.Utilities.Modal.Beneficiary.label"
        errorBaseId="Till.Utilities.Modal.Beneficiary"
        required
      />
      <TextField
        name="invoiceNumber"
        labelId="Till.Utilities.Modal.Invoice.label"
        errorBaseId="Till.Utilities.Modal.Invoice"
        required
      />
      <Grid columns={2} width="w-50" border={false}>
        <TextField
          name="amount"
          labelId="Till.Utilities.Modal.Amount.label"
          errorBaseId="Till.Utilities.Modal.Amount"
          leftGlyph="Currency.orgCurrency"
          required
        />
        <BasicField
          required
          disabled
          name="date"
          size="medium"
          labelId="Till.Utilities.Modal.Date.label"
        >
          <DatePicker name="date" disabled value={getCurrentDate()} />
        </BasicField>
      </Grid>

      <AllGeneralLedgerAccountSelectField
        required
        name="glCode"
        labelId="Till.Utilities.Modal.GlSelect.label"
      />

      <SubscribedPaymentSelectField
        required
        name="paymentMethod"
        labelId="Till.Utilities.Modal.Method.label"
        config={utilitiesConfig}
      />

      <TextField
        name="reference"
        labelId="Till.Utilities.Modal.Reference.label"
      />
    </Panel>
    <Panel>
      <TextArea
        name="notes"
        labelId="Till.Utilities.Modal.Narration.label"
        placeholderId="Till.Utilities.Modal.Narration.placeholder"
        errorBaseId="Till.Utilities.Modal.Narration"
      />
    </Panel>
  </FormActionModal>
);
