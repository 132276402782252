//@flow
import * as React from 'react';

import MemberSearch from '../../../components/MemberSearch';
import { type LoanSubstepProps } from '..';

export default ({ addDataAndContinue, StackChild }: LoanSubstepProps) => (
  <StackChild size="wide">
    <MemberSearch
      onSelect={member => {
        addDataAndContinue({
          member,
          fullName: member.fullName(),
          firstName: member.firstName,
          standing: member.standing
        });
      }}
    />
  </StackChild>
);
