import { attr } from 'spraypaint';
import axios from 'axios';

import { createErrorsFromApiResponse } from './createModelErrors';

import Base, { BaseModel } from './Base';

export const Comment = Base.extend({
  static: {
    jsonapiType: 'comments',

    member(memberId) {
      return Comment.extend({
        static: {
          endpoint: `/members/${memberId}/comments`
        }
      });
    },

    loan(loanId) {
      return Comment.extend({
        static: {
          endpoint: `/loans/${loanId}/comments`
        }
      });
    },

    saving(savingsId) {
      return Comment.extend({
        static: {
          endpoint: `/savings/${savingsId}/comments`
        }
      });
    }
  },
  attrs: {
    comment: attr()
  },
  methods: {
    post({ url, opts }) {
      const data = { attributes: this.attributes };

      return axios
        .post(url, { data }, opts)
        .then(res => {
          return res.status === 201;
        })
        .catch(async ({ response }) => {
          const body = response.data;
          this.errors = createErrorsFromApiResponse(body);
        });
    }
  }
});

export interface CommentT extends BaseModel<CommentT> {
  comment: string;
}
