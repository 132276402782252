// @flow

import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import { Text } from '@kwara/components/src/Intl';
import Modal from '@kwara/components/src/Modal';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';

import { Panel } from '../../../../components/ActionModal';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: () => ?Promise<?Object>,
  errors: any
};

// TO DO: De-dupe this with other popup confirmation Modals?

export const ConfirmDelete = ({ isOpen, onClose, onSubmit, errors }: Props) => {
  return (
    <Modal titleId="Confirm" isOpen={isOpen} onCancel={onClose}>
      <div className="w-100 h-100 flex justify-center items-center">
        <div>
          <div className="mb3">
            <ModelErrorBanner errors={errors} />
          </div>
          <Panel>
            <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
              <Text id="Settings.AppRoles.Confirm.delete" />
            </h2>

            <ButtonBar
              className="mt4"
              left={
                <Button onClick={onClose}>
                  <Text id="Settings.AppRoles.Confirm.cancel" />
                </Button>
              }
              right={
                <Button
                  classNames="mr3"
                  type={'destructive'}
                  onClick={onSubmit}
                >
                  <Text id="Settings.AppRoles.Confirm.confirm" />
                </Button>
              }
            />
          </Panel>
        </div>
      </div>
    </Modal>
  );
};
