//@flow
import * as React from 'react';
import { Form } from 'react-final-form';

import Asset from '@kwara/components/src/Asset';
import { SubscribedTextField } from '@kwara/components/src/Form';

export type CommentHandler = (comment: string) => Promise<void>;

type Props = {
  disabled: boolean,
  onComment: CommentHandler
};

export const ActivityCommenter = ({
  disabled,
  onComment = () => {}
}: Props) => {
  return (
    <Form
      onSubmit={onComment}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <SubscribedTextField
              name="comment"
              rightGlyph={submitting ? Asset.Glyphs.Spinner : null}
              disabled={disabled || submitting}
              infoId="ActivityCommenter.info"
              placeholderId="ActivityCommenter.placeholder"
              size="medium"
            />
          </form>
        );
      }}
    />
  );
};
