// @flow

import { hasOne } from 'spraypaint';

import Base, { type BaseModel } from './Base';

import { Role, type RoleT } from './Role';

export const LoanApprovalTierSet = Base.extend({
  static: {
    jsonapiType: 'loan_approval_tier_sets'
  },
  attrs: {
    role: hasOne({ type: Role })
  }
});

export interface LoanApprovalTierSetT extends BaseModel<LoanApprovalTierSetT> {
  role: RoleT;
}
