// @flow

import Big from 'big.js';
import get from 'lodash/fp/get';
import curry from 'lodash/fp/curry';
import reduce from 'lodash/fp/reduce';

export const INVALID = 'INVALID';

export function add(a: number | string, b: number | string): string {
  try {
    const x = Big(a);
    const y = Big(b);
    return x.add(y).toString();
  } catch {
    return INVALID;
  }
}

export function addMany(numbers: (number | string)[]) {
  try {
    return numbers.reduce((total, n) => add(total, n), Big(0)).toString();
  } catch {
    return INVALID;
  }
}

export function minus(a: number | string, b: number | string): string {
  try {
    const x = Big(a);
    const y = Big(b);
    return x.minus(y).toString();
  } catch {
    return INVALID;
  }
}

export function equals(a: number | string, b: number | string): boolean {
  try {
    const x = Big(a);
    const y = Big(b);
    return x.eq(y);
  } catch {
    return false;
  }
}

export const sumBy = curry<string, any[], string>(
  (path: string, arr: any[]) => {
    try {
      return reduce(
        (total, item) => add(total, get(path, item)),
        Big(0),
        arr
      ).toString();
    } catch {
      return INVALID;
    }
  }
);
