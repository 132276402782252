// @flow
import * as React from 'react';
import invoke from 'lodash/fp/invoke';
import _includes from 'lodash/fp/includes';
import split from 'lodash/fp/split';

import { Logger } from '@kwara/lib/src/logger';
import { hasErrors } from '@kwara/models/src/models/request';
import { useBanks } from '@kwara/models/src/models/request/hooks';

import { store, type Store } from './Store';
import { useProfile, useCurrentTill, EMPTY } from './request';
import { GoogleTagManager } from '../lib/GoogleTagManager';

const { createContext } = React;

export const ProfileContext = createContext<Store | {}>({});

const mapping = {
  '0': 'Sunday',
  '1': 'Monday',
  '2': 'Tuesday',
  '3': 'Wednesday',
  '4': 'Thursday',
  '5': 'Friday',
  '6': 'Saturday'
};

function preventAllUsersAccess(user) {
  if (user.isAdmin) {
    return false;
  }

  return store.blockAllUsersAccess;
}

function preventLoginOnLockoutDays(user) {
  if (user.isAdmin) {
    return false;
  }

  const today = new Date();
  const day = today.getDay();
  return !_includes(mapping[String(day)], store.accessDays);
}

function isBeforeToTime() {
  const [toHour, toMinutes] = split(':', store.accessTimes.toTime);

  const now = new Date();
  const hour = now.getHours();
  const minutes = now.getMinutes();

  if (hour < Number(toHour)) {
    return true;
  }
  if (hour > Number(toHour)) {
    return false;
  }

  if (hour === Number(toHour)) {
    return minutes < Number(toMinutes);
  }
}

function isAfterFromTime() {
  const [fromHour, fromMinutes] = split(':', store.accessTimes.fromTime);

  const now = new Date();
  const hour = now.getHours();
  const minutes = now.getMinutes();

  if (hour > Number(fromHour)) {
    return true;
  }
  if (hour < Number(fromHour)) {
    return false;
  }

  if (hour === Number(fromHour)) {
    return minutes > Number(fromMinutes);
  }
}

function preventLoginOnAccessTimes(user) {
  if (!store.accessTimes.fromTime || !store.accessTimes.toTime) {
    return false;
  }

  if (user.isAdmin) {
    return false;
  }

  if (isAfterFromTime() && isBeforeToTime()) {
    return false;
  }

  return true;
}

function preventIfBlocklisted(user) {
  if (user.isAdmin) {
    return false;
  }

  return _includes(user.id, store.blockList);
}

export const ProfileProvider = ({ auth, children }) => {
  if (!auth.isLoggedIn()) {
    return children;
  }

  return <AuthorizedProfileProvider auth={auth} children={children} />;
};

const includes = ['bank_branches'];
const AuthorizedProfileProvider = ({ auth, children }) => {
  const { data, error, refetch } = useProfile();
  const r1 = useBanks(includes);
  const { refetch: refetchTill, ...r2 } = useCurrentTill();
  store.initializeBlocklist();
  store.initializeAllowedDays();
  store.initializeTellerConfigs();
  store.initializeAccessTimes();
  store.initializeBlockAllUsersAccess();

  if (hasErrors(error)) {
    Logger.error('[ERROR] Error fetching /profile', JSON.stringify(error));
    auth.logOut().then(() => (window.location.href = '/'));
    return null;
  }

  if (data === EMPTY) {
    return null;
  }

  const [user] = data;

  if (
    preventAllUsersAccess(user) ||
    preventLoginOnLockoutDays(user) ||
    preventLoginOnAccessTimes(user) ||
    preventIfBlocklisted(user)
  ) {
    auth.logOut().then(() => (window.location.href = '/'));
    return null;
  }

  // Update the store with valid user data
  store.updateProfile(user);
  // Update the store with the refetch user function
  store.setRefetch(refetch);
  // Update the store with the till refetch function
  store.setRefetchTill(refetchTill);

  if (!r1.isPending) {
    store.setBanks(r1.data);
  }

  if (!r2.isPending && r2.error === EMPTY) {
    store.setCurrentTill(r2.data);
  }

  GoogleTagManager.addDataLayer();

  // Store the user's permissions in the Auth object
  auth.setPermissions(invoke('getPermissions', user));

  return (
    <ProfileContext.Provider value={store}>{children}</ProfileContext.Provider>
  );
};

export const withProfile = Component => props => (
  <ProfileContext.Consumer>
    {profileData => <Component {...profileData} {...props} />}
  </ProfileContext.Consumer>
);
