// @flow
import * as React from 'react';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { mapIndexed } from '@kwara/lib/src/lodash';
import Banner from '@kwara/components/src/Banner';
import { Text } from '@kwara/components/src/Intl';
import { fields } from '@kwara/models/src/models/Loan';
import { totalBalance } from '@kwara/models/src/models/LoanApplication';
import { CollateralFields } from '@kwara/models/src';
import { DocumentList } from '@kwara/components/src/DocumentList';

import { Currency } from '../../../components/Currency';
import { Record, Section } from '../../../components/Summary';
import { type LoanSubstepProps } from '..';
import { getLoansfromLoanIds } from './Loans';

const currency = value =>
  value == null ? null : <Currency format="currency" value={value} />;

const GuarantorsBanner = () => (
  <>
    <h4 className="mb1 mt0">
      <Text id="LoanAdd.Summary.GuarantorsBanner.title" />
    </h4>
    <p className="bl bw1 mv0 pl3 kw-text-regular">
      <Text id="LoanAdd.Summary.GuarantorsBanner.text" />
    </p>
  </>
);

export default ({ data, StackChild, parentUrl }: LoanSubstepProps) => {
  const {
    guarantors,
    collaterals,
    member,
    loans,
    purpose,
    specification,
    product,
    amount,
    repaymentPeriodUnit,
    showInstallments,
    payOffLoans: payOffLoanIds
  } = data;

  const repaymentValue = data.repaymentPeriod;
  const installments = data.loanInstallments;
  const duration = data.loanDuration;
  const repaymentMode = get(fields.repayment.mode, data);
  const disbursementMode = get(fields.disbursement.mode, data);
  const disbursementBank = get(fields.disbursement.bank, data);
  const disbursementBranch = get(fields.disbursement.branch, data);
  const disbursementAccount = get(fields.repayment.account, data);
  const editUrl = path => `${parentUrl}/${path}`;

  const payOffLoans = getLoansfromLoanIds(payOffLoanIds, loans);

  const payOffLoanRecord = loan => (
    <div>
      {get('product.name', loan)} -{' '}
      <Currency format="currency" value={loan.totalBalance} />
    </div>
  );

  return (
    <StackChild>
      {size(guarantors) > 0 ? (
        <Banner text={<GuarantorsBanner />} state="warning" />
      ) : null}
      <Section titleId="LoanAdd.Summary.Member.title">
        <Record labelId="LoanAdd.Summary.Member.id" value={member.id} />
        <Record value={member.fullName()} />
        <Record
          labelId="LoanAdd.Summary.Member.deposits"
          value={currency(member.totalSavings)}
        />
        <Record
          labelId="LoanAdd.Summary.Member.loans"
          value={currency(member.totalLoansBalance)}
        />
        <Record
          labelId="LoanAdd.Summary.Member.eligible"
          value={currency(member.eligibleAmount)}
        />
      </Section>

      <Section
        titleId="LoanAdd.Summary.Loan.title"
        editUrl={editUrl('configure/1')}
      >
        <Record labelId="LoanAdd.Summary.Loan.name" value={product.name} />
        <Record
          labelId="LoanAdd.Summary.Loan.principal"
          value={currency(amount)}
        />
        <Record labelId="LoanAdd.Summary.Loan.purpose" value={purpose} />
        <Record
          labelId="LoanAdd.Summary.Loan.specification"
          value={specification}
        />

        <Record
          labelId="LoanAdd.Summary.Loan.frequency"
          value={
            <Text
              id={`LoanAdd.Summary.Loan.frequency.${repaymentPeriodUnit}`}
              values={{ value: repaymentValue, unit: repaymentPeriodUnit }}
            />
          }
        />
        {showInstallments ? (
          <Record
            labelId="LoanAdd.Summary.Loan.installments"
            value={installments}
          />
        ) : (
          <Record
            labelId="LoanAdd.Summary.Loan.duration"
            valueId={`LoanAdd.Summary.Loan.duration.${repaymentPeriodUnit}`}
            values={{ duration }}
          />
        )}
      </Section>

      <Section
        titleId="LoanAdd.Summary.LoanPayOff"
        editUrl={editUrl('configure/2')}
      >
        {map(
          loan => (
            <Record key={loan.id} value={payOffLoanRecord(loan)} />
          ),
          payOffLoans
        )}
        <Record
          labelId="LoanAdd.Summary.LoanPayOff.total"
          value={
            <Currency format="currency" value={totalBalance(payOffLoans)} />
          }
        />
      </Section>

      <Section
        titleId="LoanAdd.Summary.Repayment"
        editUrl={editUrl('configure/3')}
      >
        <Record
          labelId="LoanAdd.Summary.Repayment.method"
          value={
            repaymentMode ? (
              <Text id={`TransactionMethod.${repaymentMode}`} />
            ) : null
          }
        />
      </Section>

      <Section
        titleId="LoanAdd.Summary.Disbursement.title"
        editUrl={editUrl('configure/4')}
      >
        <Record
          labelId="LoanAdd.Summary.Disbursement.method"
          value={<Text id={`TransactionMethod.${disbursementMode}`} />}
        />
        <Record
          labelId="LoanAdd.Summary.Disbursement.bank"
          value={
            disbursementBank ? <Text id={`Bank.${disbursementBank}`} /> : null
          }
        />
        <Record
          labelId="LoanAdd.Summary.Disbursement.bankBranch"
          value={disbursementBranch}
        />
        <Record
          labelId="LoanAdd.Summary.Disbursement.accountNumber"
          value={disbursementAccount}
        />
      </Section>

      <Section
        titleId="LoanAdd.Summary.Security.title"
        editUrl={editUrl('configure/5')}
      >
        {mapIndexed(
          ({ member, amount: memberAmount }, index) => (
            <Record
              key={`${member.id}.${index}.heading`}
              labelId="LoanAdd.Summary.SecurityAmount.guarantor"
              values={{ name: member.fullName(), id: member.id }}
              value={currency(memberAmount)}
            />
          ),
          guarantors
        )}
        {mapIndexed(
          (collateral, i) => (
            <Record
              key={`${collateral.amount}-${i}`}
              labelId={`LoanAdd.Security.Collateral.Type.${
                collateral[CollateralFields.type]
              }`}
              valueId="LoanAdd.Summary.Security.Collateral.value"
              values={{
                amount: collateral.amount,
                br: <br />
              }}
            />
          ),
          collaterals
        )}
      </Section>

      <Section
        titleId="LoanAdd.Summary.Documents.title"
        editUrl={editUrl('documents/1')}
      >
        <Record
          value={
            <DocumentList
              size="tiny"
              member={member}
              contexts={['loans']}
              attachments={member.attachments}
            />
          }
        />
      </Section>
    </StackChild>
  );
};
