// @flow

import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import typeof { history } from 'react-router-dom';
import { observer } from 'mobx-react';

import Logo from '@kwara/components/src/Logo';
import { Text } from '@kwara/components/src/Intl';
import { Link as LinkComponent } from '@kwara/components/src';
import zIndices from '@kwara/lib/src/zIndices';

import MemberSearch from '../MemberSearch';
import { UserMenu } from '../UserMenu';
import { memberPath, currentSection } from '../../lib/urls';
import permission, { AppPermissions } from '../../models/Permission';

import styles from './style.module.css';
import { ProfileContext } from '../../models/Profile';

const linkStyles = 'b grey-400 no-underline';

const Link = ({ active = false, to, ...rest }) => (
  <LinkComponent
    Component={NavLink}
    to={to}
    className={`dib pr3 kw-weight-bold ${linkStyles} ${
      active ? styles.isActive : ''
    } ${styles.link}`}
    {...rest}
  />
);

type Section =
  | 'members'
  | 'savings'
  | 'loans'
  | 'settings'
  | 'till'
  | 'tellers';

type Props = {
  history: history,
  enabled: Section[],
  isLoggedIn: boolean,
  onLogOut?: () => void
};

const Navigation = observer(
  ({ history, isLoggedIn, onLogOut = () => {} }: Props) => {
    const current = currentSection();
    const store = React.useContext(ProfileContext);

    const enabled = [
      ...(permission.to(AppPermissions.UseTill) ? ['till'] : []),
      ...(permission.to(AppPermissions.UseTellerSupervisorPage)
        ? ['tellers']
        : []),
      'members',
      'savings',
      'loans'
    ];

    return (
      <div className="pa3 h3 flex items-center hide-on-print">
        <div className="flex flex-auto ma0 pa0">
          <Logo />
          <div className="ml-auto mr-auto flex items-center">
            {isLoggedIn && (
              <MemberSearch
                collapsed
                onSelect={member => history.push(memberPath({ id: member.id }))}
                resultType="nonFinancial"
                floatResults
                showResultCount={false}
                data-cy="member-search-navbar"
              />
            )}
          </div>
          <div className="dib w4 flex-auto" />
          <div className="ml-auto flex justify-center items-center grey-400">
            {enabled.map(path => (
              <Link
                key={path}
                to={`/${path !== 'members' ? path : ''}`}
                active={current === path}
              >
                <Text id={`navigation.${path}`} />
              </Link>
            ))}
          </div>
          {isLoggedIn && (
            <div
              className={`flex-none flex items-center ml3 mr3 ${zIndices.Navigation}`}
            >
              <UserMenu
                onLogOut={onLogOut}
                userName={store.profile.email}
                saccoName={store.profile.branch.name}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default withRouter(Navigation);
