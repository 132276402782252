// @flow

import * as React from 'react';
import useInterval from '@use-it/interval';

import { PendingLoanTransaction } from '@kwara/models/src';

import { Header } from '../../../../components/SideNavContainer';
import { usePendingLoanTransactions } from '../../../../models/request';
import { TransactionsForm } from '../shared/TransactionsForm';
import { usePagination } from '../../../../models/pagination';
import { useFilter } from '../../../../models/filter';

const params = [null, ['user', 'loan_transaction_review_set.user']];
export const PendingLoanTransactions = () => {
  const { filterValue, onFilterChange } = useFilter(
    filter => ({
      state: filter
    }),
    'pending_approval'
  );
  const {
    allData,
    onNext,
    hasMore,
    isPending,
    totalResults,
    refetch,
    ...r
  } = usePagination(
    usePendingLoanTransactions,
    currentPage => [...params, filterValue, currentPage],
    filterValue
  );
  useInterval(refetch, 10000);
  return (
    <>
      <Header
        titleId="Pending transactions"
        subtitleId="Review transactions occurring on the platform"
      />
      <TransactionsForm
        transactions={allData}
        filterBy={filterValue.state}
        onRefetch={r.refetch}
        onNext={onNext}
        isPending={isPending}
        hasMore={hasMore}
        totalResults={totalResults}
        onFilterChange={onFilterChange}
        model={PendingLoanTransaction}
      />
    </>
  );
};
