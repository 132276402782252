// @flow
import * as React from 'react';
import orderBy from 'lodash/orderBy';
import map from 'lodash/fp/map';
import cx from 'classnames';

import { type EventT } from '@kwara/models/src';

import { EventItem } from './EventItem';
import styles from './index.module.scss';

type Props = {
  events: EventT[]
};

export const EventTimeline = ({ events }: Props) => (
  <ul className={cx('list ma0 pa0', styles.Timeline)}>
    {map(
      event => (
        <li key={event.id} className={styles.EventItemWrapper}>
          <EventItem event={event} />
        </li>
      ),
      orderBy<EventT>(events, ['timestamp'], ['desc'])
    )}
  </ul>
);
