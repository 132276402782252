import { type StepConfig } from '../../components/Wizard';

import { PayOffForm } from './components/PayOffForm';

export const steps: StepConfig = {
  confirm: {
    Component: PayOffForm,
    validate: PayOffForm.validate,
    actions: [{ appearsAs: 'submit', behavesAs: 'complete' }]
  }
};
