import snakeCase from 'lodash/snakeCase';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import pipe from 'lodash/fp/pipe';
import map from 'lodash/fp/map';
import fromPairs from 'lodash/fp/fromPairs';
import toPairs from 'lodash/fp/toPairs';

// Deeply traverse an object and recursively convert to snake_case every key
export const snakeCaseObjectKeys = object =>
  pipe(
    toPairs,
    map(([key, value]) => {
      if (isArray(value)) {
        return [snakeCase(key), map(snakeCaseObjectKeys, value)];
      }

      if (isObject(value)) {
        return [snakeCase(key), snakeCaseObjectKeys(value)];
      }

      return [snakeCase(key), value];
    }),
    fromPairs
  )(object);
