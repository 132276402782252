import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { type LoanType } from '@kwara/models/src';
import { PenaltyStatistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

export default ({ loan }: { loan: LoanType }) => (
  <StatisticRow widthClass="w-50">
    <PenaltyStatistic
      title={<Text id="LoanDetail.FeesAndPenalties.feesBalance" />}
      errorValue={loan.fees.balance}
      size={'large'}
    />
    <PenaltyStatistic
      title={<Text id="LoanDetail.FeesAndPenalties.penaltiesBalance" />}
      errorValue={loan.penalties.balance}
      size={'large'}
    />
  </StatisticRow>
);
