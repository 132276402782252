// @flow

import Base from './Base';

const MemberMetric = Base.extend({
  static: {
    jsonapiType: 'member_metrics'
  },
  attrs: {},
  methods: {}
});

export default (MemberMetric: typeof MemberMetric);
