import * as React from 'react';
import cx from 'classnames';

import styles from '../index.module.css';

type Props = {
  children: React.Node,
  id: string,
  title: React.Node,
  subTitle: React.Node,
  classNames?: string,
  headerRight?: React.Node,
  secondary?: React.Node
};

export const DetailSection = ({
  children,
  id,
  title,
  subtitle,
  classNames,
  headerRight,
  secondary
}: Props) => (
  <section id={id} className={cx(classNames, styles.Section)}>
    <div>
      <header className={styles.Header}>
        <div className={cx('mt0 mb1')}>
          <div>
            <h2 className="bt b--light-grey-400 mb1 kw-text-big kw-weight-bold">
              <span className={cx('dib bt pt4', styles.Title)}>{title}</span>
            </h2>
          </div>
          {subtitle}
        </div>
        <div className={styles.HeaderRight}>{headerRight}</div>
      </header>
    </div>
    <div className="flex items-start">
      <div className={cx(styles.Secondary, 'flex-none')}>{secondary}</div>
      <div className="flex-auto">{children}</div>
    </div>
  </section>
);
