// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';

import { type GeneralLedgerAccountT } from '@kwara/models/src';
import { SubscribedSelectField as SelectField } from '@kwara/components/src/Form';
import { Loadable } from '@kwara/components/src/Loadable';

import { useGlAccounts } from '../../models/request';

export const AllGeneralLedgerAccountSelectField = ({
  name,
  labelId,
  required
}: {
  name: string,
  labelId: string,
  required: boolean
}) => {
  const r = useGlAccounts();

  return (
    <Loadable {...r}>
      {accounts => (
        <GeneralLedgerSelectField
          required={required}
          name={name}
          labelId={labelId}
          accounts={accounts}
        />
      )}
    </Loadable>
  );
};

export const GeneralLedgerSelectField = ({
  name,
  labelId,
  accounts,
  compact,
  margin
}: {
  name: string,
  labelId?: string,
  accounts: GeneralLedgerAccountT[],
  compact?: boolean,
  margin?: boolean
}) => {
  const glAccounts = sortBy(account => account.id, accounts);

  return (
    <SelectField
      name={name}
      labelId={labelId}
      compact={compact}
      margin={margin}
    >
      <SelectField.Option key="empty" value={null}>
        -
      </SelectField.Option>
      {map(
        account => (
          <SelectField.Option key={account.id} value={account.id}>
            {account.id} - {account.name}
          </SelectField.Option>
        ),
        glAccounts
      )}
    </SelectField>
  );
};
