// @flow

import { attr } from 'spraypaint';

import Base from './Base';

const CustomField = Base.extend({
  static: {
    jsonapiType: 'custom_fields'
  },
  attrs: {
    label: attr(),
    value: attr()
  },
  methods: {}
});

export type CustomFieldType = CustomField;

export default (CustomField: typeof CustomField);
