// @flow

import some from 'lodash/fp/some';
import filter from 'lodash/fp/filter';
import concat from 'lodash/fp/concat';
import getOr from 'lodash/fp/getOr';
import keyBy from 'lodash/fp/keyBy';
import size from 'lodash/fp/size';
import intersection from 'lodash/fp/intersection';
import find from 'lodash/fp/find';
import remove from 'lodash/remove';

import {
  Attachments,
  type AttachmentT,
  type AttachmentContext,
  type AttachmentMeta,
  attachmentsMeta
} from '@kwara/models/src/models/Attachment';

// Return a list of document types that match the visibility specified in "contexts"
// You can pass either a string or an array of strings
// AND logic
const typesByContext = (contexts: AttachmentContext | AttachmentContext[]) => {
  const contextsList = concat([], contexts);
  return filter<AttachmentMeta>(
    (o: AttachmentMeta) => size(intersection(o.contexts, contextsList)) > 0,
    attachmentsMeta
  );
};

// Filter a list of attachments based on its context(s)
const fileNamesDictionary = keyBy('name', attachmentsMeta);
const filterByContext = (
  contexts: AttachmentContext | AttachmentContext[],
  attachments: AttachmentT[]
) => {
  const contextsList = concat([], contexts);
  return filter<AttachmentT>((o: AttachmentT) => {
    const meta = getOr({}, o.name, fileNamesDictionary);
    return size(intersection(meta.contexts, contextsList)) > 0;
  }, attachments);
};

export const allowedAttachments = {
  list: attachmentsMeta,
  typesByContext,
  filterByContext
};

// Form helpers to add/remove/check attachments on forms
export function addAttachment(
  data: { [k: string]: any, attachments: AttachmentT[] },
  value: AttachmentT
) {
  // We need to mutate the instance here to trigger Spraypaint change detection :(
  const att = new Attachments(value);
  if (!data.attachments) {
    data.attachments = [];
  }
  data.attachments.push(att);
  return data;
}

export function removeAttachment(
  data: { [k: string]: any, attachments: AttachmentT[] },
  fileName: string
) {
  // We need to mutate array here to trigger Spraypaint change detection :(
  remove(data.attachments, e => e.name === fileName);
  return data;
}

export function isDirty(attachments: AttachmentT[] = [], fileName: string) {
  return some(att => att.name === fileName, attachments);
}

const fallback = {};
export const findProfilePicture = (attachments: AttachmentT[]) =>
  find(attachment => attachment.name === 'profile', attachments) || fallback;
