import React from 'react';
import {
  Page as ReactPDFPage,
  Document,
  StyleSheet
} from '@react-pdf/renderer';

import { pdfHeader as Header, pdfFooter as Footer } from '.';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    paddingTop: 20,
    paddingHorizontal: 25,
    paddingBottom: 50
  }
});

export const Template = ({
  title,
  headerText,
  sacco,
  children,
  size = 'A4'
}) => {
  return (
    <Document title={title}>
      <ReactPDFPage size={size} style={styles.page}>
        <Header sacco={sacco} titleText={headerText} />
        {children}
        <Footer />
      </ReactPDFPage>
    </Document>
  );
};

export const Page = ({ children, size = 'A4', sacco = {} }) => {
  return (
    <ReactPDFPage size={size} style={styles.page}>
      <Header sacco={sacco} />
      {children}
      <Footer />
    </ReactPDFPage>
  );
};
