// @flow

import * as React from 'react';
import map from 'lodash/map';

import { type MemberType } from '@kwara/models/src';
import { Grid } from '../../../components/Grid';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import {
  EditableSection,
  type EditableConfig
} from '../../../components/EditableSection';
import { NextOfKin as Kin } from '../../MemberAdd/components/Kin';

const config = { Component: Kin };

export const NextOfKin = (props: EditableConfig<MemberType>) => (
  <EditableSection
    config={config}
    initialData={props.member}
    readOnly={props.readOnly}
  >
    <Grid columns={2} width="w-50">
      {map(props.member.kin, (nextOfKin, index) => (
        <Statistic
          key={index}
          title={<Text id="MemberDetail.NextOfKin.fullName" />}
          value={nextOfKin}
          size={'medium'}
        />
      ))}
    </Grid>
  </EditableSection>
);

NextOfKin.Title = () => <Text id="MemberDetail.nextOfKin" />;
