import groupBy from 'lodash/fp/groupBy';
import get from 'lodash/fp/get';
import indexOf from 'lodash/fp/indexOf';

// Permission groups
//
const BATCH_TRANSACTIONS = 'batchTransactionImport';
const SAVING_TRANSACTIONS = 'savingTransaction';
const LOAN_TRANSACTIONS = 'loanTransaction';
const TILL = 'till';
const TOPUP = 'topup';
const JOURNAL_ENTRIES = 'journalEntries';
const INVITATION = 'invitation';
const OTHER = 'other';

export const groups = [
  SAVING_TRANSACTIONS,
  LOAN_TRANSACTIONS,
  TILL,
  TOPUP,
  BATCH_TRANSACTIONS,
  JOURNAL_ENTRIES,
  INVITATION,
  OTHER
];

// Permission actions
//
const VERIFY = 'verify';
const VIEW = 'view';
const VIEW_ALL = 'view_all';
const CREATE = 'create';
const APPROVE = 'approve';
const REJECT = 'reject';
const REVIEW = 'review';
const OPEN = 'open';
const CLOSE = 'close';

// Permissions are sorted by these "verbs", lowest index first
// i.e VIEW permission will always be first, and so on.
//
const verbs = [
  VERIFY,
  VIEW,
  VIEW_ALL,
  CREATE,
  APPROVE,
  REJECT,
  REVIEW,
  OPEN,
  CLOSE
];

export const sortByVerb = permission => indexOf(permission.verb(), verbs);

function determineGroup(permission) {
  return get([permission.name, 'group'], KwaraPermissionsConfig) || OTHER;
}

export function groupPermissions(permissions = []) {
  return groupBy(determineGroup, permissions);
}

export const KwaraPermissionsConfig = Object.freeze({
  VIEW_BATCH_TRANSACTION_IMPORTS: {
    id: 'VIEW_BATCH_TRANSACTION_IMPORTS',
    group: BATCH_TRANSACTIONS,
    verb: VIEW
  },
  CREATE_BATCH_TRANSACTION_IMPORTS: {
    id: 'CREATE_BATCH_TRANSACTION_IMPORTS',
    group: BATCH_TRANSACTIONS,
    verb: CREATE
  },
  REVIEW_BATCH_TRANSACTION_IMPORTS: {
    id: 'REVIEW_BATCH_TRANSACTION_IMPORTS',
    group: BATCH_TRANSACTIONS,
    verb: REVIEW
  },

  VIEW_SACCO_EVENTS: { id: 'VIEW_SACCO_EVENTS', group: OTHER, verb: VIEW },

  VIEW_INVITATIONS: {
    id: 'VIEW_INVITATIONS',
    group: INVITATION,
    verb: VIEW
  },
  CREATE_INVITATIONS: {
    id: 'CREATE_INVITATIONS',
    group: INVITATION,
    verb: CREATE
  },

  VIEW_JOURNAL_ENTRIES: {
    id: 'VIEW_JOURNAL_ENTRIES',
    group: JOURNAL_ENTRIES,
    verb: VIEW
  },
  CREATE_JOURNAL_ENTRIES: {
    id: 'CREATE_JOURNAL_ENTRIES',
    group: JOURNAL_ENTRIES,
    verb: CREATE
  },

  VIEW_TILLS: { id: 'VIEW_TILLS', group: TILL, verb: VIEW },
  VIEW_ALL_TILLS: { id: 'VIEW_ALL_TILLS', group: TILL, verb: VIEW_ALL },
  OPEN_TILL: { id: 'OPEN_TILL', group: TILL, verb: OPEN },
  CLOSE_TILL: { id: 'CLOSE_TILL', group: TILL, verb: CLOSE },

  VIEW_TOPUP_REQUESTS: {
    id: 'VIEW_TOPUP_REQUESTS',
    group: TOPUP,
    verb: VIEW
  },
  VIEW_ALL_TOPUP_REQUESTS: {
    id: 'VIEW_ALL_TOPUP_REQUESTS',
    group: TOPUP,
    verb: VIEW_ALL
  },
  CREATE_TOPUP_REQUESTS: {
    id: 'CREATE_TOPUP_REQUESTS',
    group: TOPUP,
    verb: CREATE
  },
  APPROVE_TOPUP_REQUESTS: {
    id: 'APPROVE_TOPUP_REQUESTS',
    group: TOPUP,
    verb: APPROVE
  },
  REJECT_TOPUP_REQUESTS: {
    id: 'REJECT_TOPUP_REQUESTS',
    group: TOPUP,
    verb: REJECT
  },
  VIEW_TILL_TRANSACTIONS: {
    id: 'VIEW_TILL_TRANSACTIONS',
    group: TILL,
    verb: VIEW
  },
  CREATE_UTILITY_TRANSACTIONS: {
    id: 'CREATE_UTILITY_TRANSACTIONS',
    group: TILL,
    verb: CREATE
  },

  VIEW_SUPERVISOR_METRICS: {
    group: TILL,
    id: 'VIEW_SUPERVISOR_METRICS',
    verb: VIEW
  },

  CREATE_SAVINGS_TRANSACTIONS: {
    group: SAVING_TRANSACTIONS,
    verb: CREATE,
    id: 'CREATE_SAVINGS_TRANSACTIONS'
  },
  VIEW_SAVINGS_TRANSACTIONS: {
    group: SAVING_TRANSACTIONS,
    verb: VIEW,
    id: 'VIEW_SAVINGS_TRANSACTIONS'
  },
  APPROVE_SAVINGS_TRANSACTIONS: {
    group: SAVING_TRANSACTIONS,
    verb: APPROVE,
    id: 'APPROVE_SAVINGS_TRANSACTIONS'
  },
  REJECT_SAVINGS_TRANSACTIONS: {
    group: SAVING_TRANSACTIONS,
    verb: REJECT,
    id: 'REJECT_SAVINGS_TRANSACTIONS'
  },

  CREATE_LOAN_TRANSACTIONS: {
    group: LOAN_TRANSACTIONS,
    verb: CREATE,
    id: 'CREATE_LOAN_TRANSACTIONS'
  },
  VIEW_LOAN_TRANSACTIONS: {
    group: LOAN_TRANSACTIONS,
    verb: VIEW,
    id: 'VIEW_LOAN_TRANSACTIONS'
  },
  APPROVE_LOAN_TRANSACTIONS: {
    group: LOAN_TRANSACTIONS,
    verb: APPROVE,
    id: 'APPROVE_LOAN_TRANSACTIONS'
  },
  REJECT_LOAN_TRANSACTIONS: {
    group: LOAN_TRANSACTIONS,
    verb: REJECT,
    id: 'REJECT_LOAN_TRANSACTIONS'
  },
  SAFARICOM_IMSI_VERIFY_PHONE: {
    id: 'SAFARICOM_IMSI_VERIFY_PHONE',
    verb: VERIFY,
    group: OTHER
  }
});
