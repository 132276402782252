import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import sortBy from 'lodash/fp/sortBy';
import get from 'lodash/fp/get';
import pipe from 'lodash/fp/pipe';
import flatMap from 'lodash/fp/flatMap';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { type TransactionT, type MemberType } from '@kwara/models/src';
import {
  Date,
  Time,
  DefaultText as Text,
  Cell,
  Row,
  Currency
} from '@kwara/components/src/PDF';

const styles = StyleSheet.create({
  table: {
    flexDirection: 'column'
  }
});

const LoanTransactionTableHeader = () => {
  return (
    <Row>
      <Cell style={{ width: '15%', textAlign: 'left' }}>
        <Text id="MemberSummaryPDF.LoanTransactions.Header.date" />
      </Cell>
      <Cell style={{ width: '20%', textAlign: 'left' }}>
        <Text id="MemberSummaryPDF.LoanTransactions.Header.id" />
      </Cell>
      <Cell style={{ width: '25%', textAlign: 'left' }}>
        <Text id="MemberSummaryPDF.LoanTransactions.Header.description" />
      </Cell>
      <Cell style={{ width: '20%', textAlign: 'right' }}>
        <Text id="MemberSummaryPDF.LoanTransactions.Header.amount" />
      </Cell>
      <Cell style={{ width: '20%', textAlign: 'right' }}>
        <Text id="MemberSummaryPDF.LoanTransactions.Header.balance" />
      </Cell>
    </Row>
  );
};

const LoanTableRow = ({ transaction }: { transaction: TransactionT }) => {
  const {
    id,
    enteredAt,
    account,
    type,
    amount,
    balance,
    reference,
    notes
  } = transaction;

  const accountID = get('id', account);
  return (
    <Row>
      <Cell style={{ width: '15%', textAlign: 'left' }}>
        <Text style={{ color: 'grey' }}>{id}</Text>
        <Date style={{ color: 'black' }}>{enteredAt}</Date>
        <Time style={{ color: 'black' }}>{enteredAt}</Time>
      </Cell>
      <Cell style={{ width: '20%', textAlign: 'left' }}>
        <Text style={{ color: 'black' }}>{accountID}</Text>
      </Cell>
      <Cell style={{ width: '25%' }}>
        <Text style={{ color: 'grey' }}>{reference}</Text>
        <Text style={{ color: 'black' }} id={`TransactionType.${type}`} />
        <Text style={{ color: 'grey' }}>{notes}</Text>
      </Cell>
      <Cell style={{ width: '20%' }}>
        <Currency style={{ textAlign: 'right', color: 'black' }}>
          {amount}
        </Currency>
      </Cell>
      <Cell style={{ width: '20%' }}>
        <Currency style={{ textAlign: 'right', color: 'black' }}>
          {balance}
        </Currency>
      </Cell>
    </Row>
  );
};

const LoanTransactionTable = ({
  transactions
}: {
  transactions: TransactionT[]
}) => {
  const sortedTransactions = sortBy(t => Number(t.id), transactions);

  return (
    <View>
      {mapIndexed(
        (transaction, i) => (
          <LoanTableRow key={i} transaction={transaction} />
        ),
        sortedTransactions
      )}
    </View>
  );
};

export const LoanTable = ({ member }: { member: MemberType }) => {
  const loanTransactions = pipe(
    get('loans'),
    flatMap(loan => get('transactions', loan))
  )(member);

  return (
    <View style={styles.table}>
      <LoanTransactionTableHeader />
      <LoanTransactionTable transactions={loanTransactions} />
    </View>
  );
};
