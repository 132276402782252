//@flow
import * as React from 'react';
import classnames from 'classnames';
import lt from 'lodash/fp/lt';
import noop from 'lodash/fp/noop';

import { Attribute, ActionButton } from '@kwara/components/src';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import { type MemberType } from '@kwara/models/src';

import { Currency } from '../Currency';
import Column from './Column';

import styles from './index.module.scss';

type Props = {
  highlighted: boolean,
  member: MemberType,
  disabled?: boolean,
  onClick?: (evt: SyntheticInputEvent<HTMLDivElement>) => void
};

export default ({
  member,
  highlighted = false,
  disabled,
  onClick = noop,
  ...props
}: Props) => (
  <div
    className={classnames(styles.Result, 'flex', {
      [styles.isHighlighted]: highlighted,
      [styles.isDisabled]: disabled
    })}
    data-testid="Result"
    onClick={disabled ? noop : onClick}
    {...props}
  >
    <Column flex={null}>
      <div className={classnames(styles.noFlex, 'mr3')}>
        <ProfilePhoto size="small" />
      </div>
      <div className={classnames(styles.ResultMember, 'mr3')}>
        <Attribute
          labelId="MemberSearch.Result.member"
          labelSize="small"
          values={{ id: member.id, joinedAt: new Date(member.joinedAt()) }}
          value={member.fullName()}
          valueClassName="truncate"
        />
      </div>
    </Column>
    <Column>
      <Attribute
        labelId="MemberSearch.Result.deposits"
        labelSize="small"
        valueClassName="teal-600"
        value={
          member.totalSavings != null ? (
            <Currency value={member.totalSavings} />
          ) : null
        }
      />
    </Column>
    <Column>
      <Attribute
        labelId="MemberSearch.Result.loans"
        labelSize="small"
        valueClassName="red-600"
        value={
          member.totalLoansBalance != null ? (
            <Currency value={member.totalLoansBalance} />
          ) : null
        }
      />
    </Column>
    <Column border={false}>
      <Attribute
        labelId="MemberSearch.Result.eligible"
        labelSize="small"
        valueClassName={lt(member.eligibleAmount, 0) ? 'red-600' : ''}
        value={
          member.eligibleAmount != null ? (
            <Currency value={member.eligibleAmount} />
          ) : null
        }
      />
    </Column>
    <Column flex={0.5} border={false} right>
      <ActionButton />
    </Column>
  </div>
);
