// @flow

import { attr, hasMany } from 'spraypaint';
import reject from 'lodash/fp/reject';
import { createErrorsFromApiResponse } from './createModelErrors';
import { Logger } from '@kwara/lib/src/logger';
import { appName } from '@kwara/lib/src/utils';

import Base, { type BaseModel } from './Base';

export const Attachment = Base.extend({
  static: {
    jsonapiType: 'attachment'
  },
  attrs: {
    type: attr(),
    name: attr(),
    content: attr()
  }
});

// see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
export const mimeTypesMaps = Object.freeze({
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'pptx',
  'application/pdf': 'pdf',
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'text/csv': 'csv',
  'text/plain': 'txt'
});

export const allowedExtensions = [
  'CSV',
  'DOC',
  'DOCX',
  'JPEG',
  'JPG',
  'PDF',
  'PNG',
  'PPT',
  'PPTX',
  'TXT',
  'XLS',
  'XLSX'
];
export const attachmentNames = Object.freeze({
  profile: 'profile',
  idDocument: 'idDocument',
  passport: 'passport',
  application: 'application',
  kraDocument: 'kraDocument',
  proofOfLandOwnership: 'proofOfLandOwnership',
  proofOfNseShareStock: 'proofOfNseShareStock',
  proofOfLifeCover: 'proofOfLifeCover',
  proofOfBankSecurity: 'proofOfBankSecurity',
  salarySlip: 'salarySlip',
  memberSignature: 'memberSignature',
  stimaReceipt: 'stimaReceipt',
  otherDocument1: 'otherDocument1',
  otherDocument2: 'otherDocument2',
  otherDocument3: 'otherDocument3',
  otherDocument4: 'otherDocument4',
  otherDocument5: 'otherDocument5',
  otherDocument6: 'otherDocument6'
});
export type AttachmentNameT = $Values<typeof attachmentNames>;
export type AttachmentContext = 'members' | 'loans' | 'profile' | 'depositForm';

// Flow can't derive values from array to form an Enum, as $Values currently works only with objects
// https://github.com/facebook/flow/issues/961
// so we need to duplicate the above to correctly type
export interface AttachmentT extends BaseModel<AttachmentT> {
  type:
    | 'CSV'
    | 'DOC'
    | 'DOCX'
    | 'JPEG'
    | 'JPG'
    | 'PDF'
    | 'PNG'
    | 'PPT'
    | 'PPTX'
    | 'TXT'
    | 'XLS'
    | 'XLSX';
  content: string;
  name: AttachmentNameT;
  id?: string;
  fileSize: number;
}

export type AttachmentMeta = {
  name: AttachmentNameT,
  label: string,
  contexts: AttachmentContext[]
};
// List of all allowed document uploads.
// The "contexts" array determines where in the app the upload is relevant and will be shown
export const attachmentsMeta: AttachmentMeta[] = Object.freeze([
  {
    name: attachmentNames.profile,
    label: 'UploadProfile',
    contexts: ['profile']
  },
  {
    name: attachmentNames.idDocument,
    label: 'UploadID',
    contexts: ['members']
  },
  {
    name: attachmentNames.passport,
    label: 'UploadPassport',
    contexts: ['members']
  },
  {
    name: attachmentNames.application,
    label: 'UploadApplication',
    contexts: ['members']
  },
  {
    name: attachmentNames.kraDocument,
    label: 'UploadKraDocument',
    contexts: ['members']
  },
  {
    name: attachmentNames.proofOfLandOwnership,
    label: 'UploadProofOfLandOwnership',
    contexts: ['loans']
  },
  {
    name: attachmentNames.proofOfNseShareStock,
    label: 'UploadProofOfNseShareStock',
    contexts: ['loans']
  },
  {
    name: attachmentNames.proofOfLifeCover,
    label: 'UploadProofOfLifeCover',
    contexts: ['loans']
  },
  {
    name: attachmentNames.proofOfBankSecurity,
    label: 'UploadProofOfBankSecurity',
    contexts: ['loans']
  },
  {
    name: attachmentNames.memberSignature,
    label: 'UploadMemberSignature',
    contexts: ['members']
  },
  {
    name: attachmentNames.salarySlip,
    label: 'UploadSalarySlip',
    contexts: ['members']
  },
  {
    name: attachmentNames.stimaReceipt,
    label: 'StimaReceipt',
    contexts: ['depositForm']
  },
  {
    name: attachmentNames.otherDocument1,
    label: 'OtherDocument',
    contexts: ['members']
  },
  {
    name: attachmentNames.otherDocument2,
    label: 'OtherDocument',
    contexts: ['members']
  },
  {
    name: attachmentNames.otherDocument3,
    label: 'OtherDocument',
    contexts: ['members']
  },
  {
    name: attachmentNames.otherDocument4,
    label: 'OtherDocument',
    contexts: ['loans']
  },
  {
    name: attachmentNames.otherDocument5,
    label: 'OtherDocument',
    contexts: ['loans']
  },
  {
    name: attachmentNames.otherDocument6,
    label: 'OtherDocument',
    contexts: ['loans']
  }
]);
const fetchUrl = (id, memberId) => {
  const memberUrl = `${Attachments.fullBasePath()}/attachments/${id}`;
  const saccoUrl = `${Attachments.fullBasePath()}/members/${memberId}/attachments/${id}`;

  return appName.isMember ? memberUrl : saccoUrl;
};
export const Attachments = Base.extend({
  static: {
    jsonapiType: 'attachments'
  },
  attrs: {
    attachments: hasMany({ type: Attachment }),
    memberId: attr({ persist: false }),
    type: attr(),
    name: attr(),
    content: attr(),
    fileSize: attr()
  },
  methods: {
    findOne({ id }: { id: string }) {
      const url = fetchUrl(id, this.memberId);
      return window
        .fetch(url, {
          ...Attachments.fetchOptions()
        })
        .then(r => r.json())
        .then(r => ({ ...r, id }));
    },
    async save() {
      const url = `${Attachments.fullBasePath()}/members/${
        this.memberId
      }/attachments`;
      const attachments = this.attachments.map(att => att.typedAttributes);

      const options = {
        ...Attachments.fetchOptions(),
        method: 'POST',
        body: JSON.stringify({
          data: {
            id: this.memberId,
            type: 'members',
            attributes: {
              attachments
            }
          }
        })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();

          this.errors = createErrorsFromApiResponse(body);

          return body;
        }

        return true;
      } catch (error) {
        Logger.error('Error saving attachment', JSON.stringify(error));
      }
    },
    add(attachments: AttachmentT[] = []) {
      this.attachments = attachments.map(att => new Attachment(att));
      return this;
    }
  }
});

export function saveAttachments(member, attachments) {
  if (!attachments) {
    return Promise.resolve();
  }

  const attachment = new Attachments();
  attachment.memberId = member.id;
  attachment.attachments = [];
  // we don't send uploads that where already persisted, so just reject them here
  attachment.add(reject(o => !o.content, attachments));
  return attachment.save();
}
