// @flow

import * as React from 'react';
import type { RouterHistory, LocationShape } from 'react-router-dom';

import get from 'lodash/fp/get';
import orderBy from 'lodash/fp/orderBy';
import _result from 'lodash/fp/result';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { Text, DateTime, FileSize } from '@kwara/components/src/Intl';
import { PageHeading } from '@kwara/components/src/text';
import StatusTag from '@kwara/components/src/StatusTag';
import Button from '@kwara/components/src/Button';
import { Link, PrintButton } from '@kwara/components/src';
import { type BatchUploadT } from '@kwara/models/src';

import OverviewList from '../../components/OverviewList';
import Table, * as table from '../../components/Table';
import { UploadAdd } from './components/UploadAdd';
import { useHistoricalBatchUploads } from '../../models/request';
import { usePagination } from '../../models/pagination';
import { type SubStepComponentProps } from '../../components/Wizard';

const includes = ['user'];

export const BatchUploadsList = () => {
  const {
    allData,
    onNext,
    hasMore,
    isPending,
    totalResults,
    ...r
  } = usePagination<BatchUploadT>(useHistoricalBatchUploads, currentPage => [
    includes,
    currentPage
  ]);

  const sortedItems = orderBy(['createdAt'], ['desc'], allData);

  return (
    <>
      <div className="flex items-center flex-column mt2 mb5">
        <PageHeading translationId="BatchUploads.title" />
      </div>
      <OverviewList
        headerId="BatchUploads.heading"
        labelId="BatchUploads.entity"
        totalNumResults={totalResults}
        items={sortedItems}
        actions={[
          <Button key="download" disabled>
            <Text id="BatchUploads.downloadTemplate" />
          </Button>,
          <PrintButton size="regular" key="print" />,
          <Button
            data-testid="add-new-upload"
            type="primary"
            key="upload"
            to="uploads/add"
          >
            <Text id="BatchUploads.uploadCTA" />
          </Button>
        ]}
        table={
          <Table
            heading={
              <table.Row>
                <table.Heading width="50px" />
                <table.Heading
                  textSize="regular"
                  translationId="BatchUploads.heading.id"
                  width="160px"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchUploads.heading.name"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchUploads.heading.uploaderName"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchUploads.heading.uploaderId"
                  width="125px"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchUploads.heading.date"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchUploads.heading.fileSize"
                  width="125px"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchUploads.heading.actions"
                  width="125px"
                />
                <table.Heading
                  textSize="regular"
                  translationId="BatchUploads.heading.status"
                  width="125px"
                />
              </table.Row>
            }
            footer={
              <table.Footer
                colSpan={9}
                hasMore={hasMore}
                isPending={isPending}
                onNext={onNext}
                errors={get('error.messages', r)}
                items={allData}
              />
            }
          >
            {mapIndexed(
              (entry, i) => (
                <table.Row key={entry.id}>
                  <table.Cell className="grey-400 kw-numeric" />
                  <table.Cell className="grey-400 kw-numeric">
                    {entry.id}
                  </table.Cell>
                  <table.Cell className="mw5">{entry.name}</table.Cell>
                  <table.Cell>{_result('user.fullName', entry)}</table.Cell>
                  <table.Cell>{get('user.id', entry)}</table.Cell>
                  <table.Cell>
                    <DateTime value={entry.createdAt} />
                  </table.Cell>
                  <table.Cell>
                    <FileSize value={entry.byteSize} />
                  </table.Cell>
                  <table.Cell>
                    <Link
                      size="regular"
                      data-testid={`download-as-csv-${i}`}
                      type="secondary"
                      onClick={() => {}}
                      disabled
                    >
                      <Text id="BatchUploads.row.download.CSV" />
                    </Link>
                  </table.Cell>
                  <table.Cell>
                    <StatusTag state={get('state.current', entry)} />
                  </table.Cell>
                </table.Row>
              ),
              sortedItems
            )}
          </Table>
        }
      />
    </>
  );
};

export type SubstepProps = SubStepComponentProps<{}>;

type BatchUploadsProps = {
  match: {
    params: {
      action: ?'add'
    }
  },
  history: RouterHistory,
  location: LocationShape
};

export const BatchUploads = (props: BatchUploadsProps) => {
  if (props.match.params.action === 'add') {
    return <UploadAdd history={props.history} location={props.location} />;
  }

  return <BatchUploadsList />;
};
