// @flow

import { Address } from './components/Address';
import { Contact } from './components/Contact';
import { Documents } from './components/Documents';
import { Employment } from './components/Employment';
import { JoiningFeePayment } from './components/JoiningFeePayment';
import { Name } from './components/Name';
import { NextOfKin } from './components/Kin';
import { Personal } from './components/Personal';
import Identity from './components/Identity';
import Notes from './components/Notes';
import Summary from './components/Summary';
import MemberCreditCheck, {
  IdentityCheckResult
} from '../LoanApprove/components/MemberCreditCheck';

export const steps = {
  about: {
    titleId: 'AddMember.Step.about',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'creditCheck'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Profile.title',
        Component: Name,
        validate: Name.validate
      },
      {
        titleId: 'AddMember.Personal.title',
        Component: Personal,
        validate: Personal.validate
      },
      {
        titleId: 'AddMember.Address.title',
        Component: Address
      },
      {
        titleId: 'AddMember.Identity.title',
        Component: Identity,
        validate: Identity.validateConfig.memberAdd
      },
      {
        titleId: 'AddMember.Employment.title',
        Component: Employment,
        validate: Employment.validateConfig.memberAdd
      },
      {
        titleId: 'AddMember.Kin.title',
        Component: NextOfKin
      }
    ]
  },
  creditCheck: {
    titleId: 'Stima.CreditCheck.shortTitle',
    children: [
      {
        titleId: 'Stima.CreditCheck.title',
        subtitleId: 'Stima.CreditCheck.subtitle',
        Component: MemberCreditCheck,
        validate: MemberCreditCheck.validate,
        actions: [
          {
            appearsAs: 'skip',
            behavesAs: 'skip',
            destination: 'contact'
          }
        ]
      },
      {
        titleId: 'Stima.CreditCheck.title',
        subtitleId: 'Stima.CreditCheck.subtitle',
        Component: IdentityCheckResult
      }
    ],
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'contact'
      }
    ]
  },
  contact: {
    titleId: 'AddMember.Step.contact',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'documents'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Contact.title',
        Component: Contact,
        validate: Contact.validateConfig.memberAdd
      }
    ]
  },
  documents: {
    titleId: 'AddMember.Step.documents',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'joiningFeePayment'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Documents.title',
        subtitleId: 'AddMember.Documents.subtitle',
        Component: Documents
      }
    ]
  },
  joiningFeePayment: {
    titleId: 'AddMember.Step.paymentFee',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'notes'
      }
    ],
    children: [
      {
        titleId: 'AddMember.PaymentFee.title',
        subtitleId: 'AddMember.PaymentFee.subtitle',
        Component: JoiningFeePayment,
        validate: JoiningFeePayment.validate
      }
    ]
  },
  notes: {
    titleId: 'AddMember.Step.notes',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'summary'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Notes.title',
        subtitleId: 'AddMember.Notes.subtitle',
        Component: Notes
      }
    ]
  },
  summary: {
    titleId: 'AddMember.Step.summary',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'AddMember.Summary.title',
        subtitleId: 'AddMember.Summary.subtitle',
        Component: Summary,
        progress: false
      }
    ]
  }
};
