import * as React from 'react';
import { Field as FinalFormField } from 'react-final-form';
import noop from 'lodash/fp/noop';

import {
  SavingAccountCard,
  LoanAccountCard
} from '../../../../components/AccountCard';

const SubscribedCard = ({
  name,
  account,
  onSelect = noop,
  isSelectable = false,
  card: Card
}) => {
  return (
    <FinalFormField
      key={account.id}
      name={name}
      type="radio"
      value={account.id}
      render={({ input }) => {
        return (
          <>
            <label htmlFor={account.id}>
              <Card
                account={account}
                isSelectable={isSelectable}
                isSelected={!!input.checked}
              />
            </label>
            <input
              hidden
              {...input}
              onClick={e => {
                onSelect(account);
                input.onChange(e);
              }}
              type="radio"
              id={account.id}
              name={name}
            />
          </>
        );
      }}
    />
  );
};

export const SubscribedSavingAccountCard = ({ account }) => {
  return (
    <SubscribedCard
      name="linkedAccountId"
      account={account}
      card={SavingAccountCard}
    />
  );
};

export const SubscribedLoanAccountCard = ({ account }) => {
  return (
    <SubscribedCard
      name="linkedAccountId"
      account={account}
      card={LoanAccountCard}
    />
  );
};
