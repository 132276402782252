// @flow

import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import Banner from '@kwara/components/src/Banner';

import { extractLoanValues } from './LoanSummary';
import { type ComponentPropsLoanReschedule } from '..';

export const WarningBanner = ({
  className,
  ...props
}: ComponentPropsLoanReschedule & { className?: string }) => {
  const { data } = props;
  const { loan } = data;
  const { interest, fees, penalties } = extractLoanValues(loan);

  if (interest || fees || penalties) {
    return (
      <Banner
        state="warning"
        className={className}
        text={
          <>
            <p className="b">
              <Text id="LoanReschedule.Banner.Warning.General" />
            </p>
            <ul>
              {interest ? (
                <li>
                  <Text
                    id="LoanReschedule.Banner.Warning.Interest"
                    values={{ interest }}
                  />
                </li>
              ) : null}
              {fees ? (
                <li>
                  <Text
                    id="LoanReschedule.Banner.Warning.Fees"
                    values={{ fees }}
                  />
                </li>
              ) : null}
              {penalties ? (
                <li>
                  <Text
                    id="LoanReschedule.Banner.Warning.Penalties"
                    values={{ penalties }}
                  />
                </li>
              ) : null}
            </ul>
          </>
        }
      />
    );
  }

  return null;
};
