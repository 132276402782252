//@flow
import * as React from 'react';

import { type LoanTransactionType } from '@kwara/models/src';

import { Date, Text, Time } from '@kwara/components/src/Intl';
import { Cell, Heading, Row } from '../../../components/Table';
import TransactionsTable from '../../../components/TransactionsTable';

import { Currency } from '../../../components/Currency';

type Props = {
  transactions: ?(LoanTransactionType[])
};

function renderRow(transaction: LoanTransactionType) {
  // TODO: This is temporary until ch14895 is actioned
  //
  const standard = (
    <>
      <span>{transaction.paymentMethod}</span>{' '}
      <Text id={`TransactionType.${transaction.type}`} />
    </>
  );

  const gl = <span>{transaction.glType()}</span>;

  return (
    <Row key={transaction.id}>
      <Cell>
        <div className="grey-300">{transaction.id}</div>
        <div>
          <Date value={transaction.enteredAt} />
        </div>
        <div>
          <Time value={transaction.enteredAt} />
        </div>
      </Cell>
      <Cell>
        <div className="grey-300">{transaction.reference}</div>
        {transaction.isGlChannel ? gl : standard}
        {transaction.notes && (
          <div className="grey-300">{transaction.notes}</div>
        )}
      </Cell>
      <Cell align="right">
        <Currency value={transaction.amount} />
      </Cell>
      <Cell align="right">
        <Currency value={transaction.balance} />
      </Cell>
      <Cell />
    </Row>
  );
}

const heading = (
  <Row>
    <Heading translationId="TransactionsTable.date" />
    <Heading className="w-33" translationId="TransactionsTable.type" />
    <Heading align="right" translationId="TransactionsTable.amount" />
    <Heading align="right" translationId="TransactionsTable.balance" />
    <Heading iconSpacer />
  </Row>
);

export const Transactions = ({ transactions }: Props) => {
  if (!transactions) {
    return null;
  }

  return (
    <TransactionsTable
      heading={heading}
      renderRow={renderRow}
      transactions={transactions}
    />
  );
};
