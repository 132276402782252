//@flow
import * as React from 'react';

import Banner from '@kwara/components/src/Banner';
import Context from './Context';

import styles from './Banner.module.css';

const NotificationBanner = () => (
  <Context.Consumer>
    {({ current, dismiss }) =>
      current != null ? (
        <Banner
          className={styles.Banner}
          innerClassName={`${styles.Inner} flex justify-between items-center`}
          text={current.message}
          state={current.level}
          onDismiss={dismiss}
        />
      ) : null
    }
  </Context.Consumer>
);

export default NotificationBanner;
