// @flow

const favicons = {
  default: 'favicon.png',
  notification: 'notification.png'
};

export function setFavicon(icon?: string = 'default') {
  const favicon = document.getElementById('favicon');
  if (favicon) {
    favicon.setAttribute('href', favicons[icon]);
  }
}
