//@flow

import * as React from 'react';

import CardContainer from '@kwara/components/src/CardContainer';
import { Link } from '@kwara/components/src/Link';

import { ActionModal } from '../ActionModal';
import { Card } from '../Card';

const { useState } = React;

export const TrialBanner = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {modalOpen ? (
        <ActionModal
          titleId="Your membership"
          header={true}
          actions={false}
          confirmId="Contact Us"
          onCancel={() => setModalOpen(false)}
        >
          <CardContainer highlighted centered full>
            <Card
              header="0757597340"
              metadata="Contact us to extend your Kwara subscription"
            />
          </CardContainer>
        </ActionModal>
      ) : null}

      <span>
        Your subscription is about to expire.{' '}
        <Link onClick={() => setModalOpen(true)}>
          {' '}
          <strong>Contact us</strong>
        </Link>{' '}
        to extend it.
      </span>
    </>
  );
};
