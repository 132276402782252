// @flow

import React from 'react';
import isNull from 'lodash/fp/isNull';
import negate from 'lodash/fp/negate';
import every from 'lodash/fp/every';
import { isEqual } from 'date-fns';

import { Text } from '@kwara/components/src/Intl';

import { Currency } from '../../../components/Currency';

const isNotNull = negate(isNull);

export const CurrencyRange = ({
  min,
  max
}: {
  max?: ?(number | string),
  min?: ?(number | string)
}) => {
  if (every(isNotNull, [min, max]) && isEqual(max, min)) {
    return (
      <>
        <Text id="Table.fixed" />: <Currency value={min} />
      </>
    );
  }
  if (every(isNotNull, [min, max])) {
    return (
      <>
        <Currency value={min} /> - <Currency value={max} />
      </>
    );
  }
  if (isNotNull(min)) {
    return (
      <>
        <Text id="Table.min" />: <Currency value={min} />
      </>
    );
  }
  if (isNotNull(max)) {
    return (
      <>
        <Text id="Table.max" />: <Currency value={max} />
      </>
    );
  }

  return '-';
};
