// @flow

import * as React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/fp/get';

import Button from '@kwara/components/src/Button';
import { Loadable } from '@kwara/components/src/Loadable';
import PageLayout from '@kwara/components/src/PageLayout';
import { type SavingType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { SavingTransactionsPDF } from '@kwara/components/src/SavingTransactionsPDF';
import { ScrollToTop } from '@kwara/components/src/ScrollIntoFocus';

import { useSaving, useSavingTransactions } from '../../models/request';
import { memberPath, savingPath } from '../../lib/urls';
import { AppPermissions } from '../../models';
import {
  AsNavigation,
  BackToTopNavigation
} from '../../components/DetailNavigation';
import { ButtonMenu } from '../../components/ButtonMenu';
import { DetailSection } from '../../components/Detail/DetailSection';
import DetailSubsection from '../../components/DetailSubsection';
import Head from '../../components/Head';
import Overview from './components/Overview';
import { PrintPDFButton } from '../../components/PrintPDFButton';
import Visible from '../../components/Visible';

import { FeesAndPenalties } from './components/FeesAndPenalties';

import MemberCard from './components/MemberCard';
import { Transactions } from './components/Transactions';

import { Activity } from './components/Activity';
import { Remittance } from './components/Remittance';

import { type SavingPageProps } from '..';
import { useMember } from '@kwara/models/src/models/request/hooks';

type Props = SavingPageProps;

const PrimaryButtonForSaving = (saving: SavingType) => {
  if (saving.transactionsPermitted()) {
    return (
      <Visible to={AppPermissions.AddDeposits}>
        <Button
          type="primary"
          to={savingPath({ id: saving.id, action: 'deposit' })}
          size="medium"
          // The ButtonBar component SHOULD handle applying the margin left className
          // but because this is wrapped in `<Visible />` it doesn't work
          // TO DO: Make it work programatically via the addClassToButtons
          // function in /ButtonBar
          className="ml3"
        >
          <Text id="SavingDetail.add" />
        </Button>
      </Visible>
    );
  }

  return null;
};

// At the moment we keep this pattern,
// because `AsNavigation` seems to require
// it to generate the side navbar items
//
const renderBody = ({ transactionResponse, saving, member, refetch }) => (
  <>
    {console.log(member)}
    <DetailSection
      id="transactions"
      title={<Text id="SavingDetail.transactions" />}
      headerRight={
        <Loadable {...transactionResponse}>
          {transactions => (
            <PrintPDFButton
              renderPDF={props => (
                <SavingTransactionsPDF
                  saving={saving}
                  transactions={transactions}
                  member={member}
                  {...props}
                />
              )}
            />
          )}
        </Loadable>
      }
      subtitle={<BackToTopNavigation />}
    >
      <DetailSubsection title="Transactions">
        <Loadable {...transactionResponse}>
          {transactions => <Transactions transactions={transactions} />}
        </Loadable>
      </DetailSubsection>

      <DetailSubsection
        title={<Text id="SavingDetail.feesAndPenalties" />}
        classNames="hide-on-print"
      >
        <FeesAndPenalties saving={saving} />
        {saving.canAddPenalty() ? (
          <Visible to={AppPermissions.AddPenalties}>
            <Button to={savingPath({ id: saving.id, action: 'penalty' })}>
              <Text id="SavingDetail.FeesAndPenalties.addPenaltyFee" />
            </Button>
          </Visible>
        ) : null}
      </DetailSubsection>
    </DetailSection>

    <DetailSection
      id="remittance"
      title={<Text id="SavingDetail.Remittance.title" />}
      subtitle={<BackToTopNavigation />}
    >
      <Remittance saving={saving} refetch={refetch} />
    </DetailSection>

    <DetailSection
      id="member"
      title={<Text id="SavingDetail.member" />}
      classNames="hide-on-print"
      subtitle={<BackToTopNavigation />}
    >
      <div className="w-third">
        <MemberCard
          member={saving.member}
          actionTo={memberPath({ id: saving.member.id })}
        />
      </div>
    </DetailSection>

    <DetailSection
      id="activity"
      title={<Text id="SavingDetail.activity" />}
      classNames="hide-on-print"
      subtitle={<BackToTopNavigation />}
    >
      <Activity savingsId={saving.id} />
    </DetailSection>
  </>
);

const includes = ['member', 'product'];
const transactionIncludes = [];
const SavingDetail = (props: Props) => {
  const { refetch, ...r } = useSaving(props.match.params.savingId, includes);
  const transactionResponse = useSavingTransactions(
    props.match.params.savingId,
    transactionIncludes
  );
  const memberReq = useMember(get(`data.member.id`, r));
  const isStaff = get('data.isStaff', memberReq);

  const historyState = () => props.history.location.state || {};
  const back = () => {
    if (historyState().useBrowserBack) {
      props.history.goBack();
    } else {
      props.history.push(savingPath());
    }
  };

  return (
    <Loadable {...r}>
      {saving => {
        const { member } = saving;
        const primaryButton = PrimaryButtonForSaving(saving);

        const minAmount = 250000;
        const prodId = get('product.id', saving);
        const insufficientFunds = saving.balance < minAmount;
        const disableCloseButton = !saving.canBeClosed();
        // stima: "6011" is share capital
        const isShareCapital = prodId === '6011';
        // const isStaff = true;
        const lowShareCap = isShareCapital && insufficientFunds;
        const disableTranserButton = lowShareCap || isStaff;
        //const disableMenuButton = disableCloseButton && disableTranserButton;

        const menuItems = [
          <Visible key="transfer" to={AppPermissions.MakeTransfer}>
            <ButtonMenu.Item
              key="transfer"
              disabled={disableTranserButton}
              to={savingPath({ id: saving.id, action: 'transfer' })}
            >
              <Text id="SavingDetail.more.Transfer" />
              {!isStaff && lowShareCap && ' (Balance too low)'}
              {isStaff && " (Staff members can't transfer shares)"}
            </ButtonMenu.Item>
          </Visible>,
          <Visible key="close" to={AppPermissions.CloseSavings}>
            <ButtonMenu.Item
              key="close"
              disabled={disableCloseButton}
              to={savingPath({ id: saving.id, action: 'close' })}
            >
              <Text id="SavingDetail.more.Close" />
            </ButtonMenu.Item>
          </Visible>
        ];

        const buttonMenu = !isEmpty(menuItems) ? (
          <ButtonMenu
            titleId="SavingDetail.more"
            type="secondary"
            //disabled={disableMenuButton}
          >
            {menuItems}
          </ButtonMenu>
        ) : null;

        const body = renderBody({
          ...props,
          saving,
          member,
          transactionResponse,
          refetch
        });
        const Navigation = AsNavigation(body);

        return (
          <ScrollToTop>
            <PageLayout
              className="pt5"
              printOverview={false}
              overview={
                <Overview
                  navigation={<Navigation />}
                  saving={saving}
                  backLabel={historyState().backLabel}
                  onBack={back}
                  actions={[buttonMenu, primaryButton]}
                />
              }
            >
              <Head titleId="SavingDetail.title" values={{ id: saving.id }} />
              {body}
            </PageLayout>
          </ScrollToTop>
        );
      }}
    </Loadable>
  );
};

export default SavingDetail;
