// @flow
import * as React from 'react';
import classnames from 'classnames';
import chunk from 'lodash/fp/chunk';
import get from 'lodash/fp/get';

import styles from './index.module.scss';

type Props = {
  border?: boolean,
  children: React.Node,
  columns?: number,
  width?: string
};

export const Grid = ({
  border = true,
  columns = 3,
  width = 'w-third',
  children
}: Props) => {
  const rows = chunk(
    columns,
    // The filter makes sure that hidden elements don't throw off the layout.
    // Without it a hidden elememnt would still be calculated as a row item
    // rendering an empty box
    React.Children.toArray(children).filter(
      child => !get('props.hidden', child)
    )
  );

  const containerClasses = [
    styles.GridContainer,
    border ? styles.hasBorder : styles.noBorder
  ];
  const rowClasses = [styles.GridRow, 'w-100 flex'];
  const cellClasses = [styles.GridCell, width, 'fl'];

  return (
    <div className={classnames(containerClasses)}>
      {rows.map((items, rowIndex) => (
        <div key={rowIndex} className={classnames(rowClasses)}>
          {items.map((item, index) => (
            <div key={item.key || index} className={classnames(cellClasses)}>
              {item}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
