import React from 'react';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { Text } from '@kwara/components/src/Intl';
import { calculateNumberInstallments } from '@kwara/models/src/models/Loan';
import { PeriodUnits } from '@kwara/models/src';
import { Logger } from '@kwara/lib/src/logger';

import { Field } from '../../../../components/Form';
import styles from './index.module.css';

export function getRepaymentTerms(data) {
  const product = get('product', data);

  if (!product) {
    return {};
  }

  const {
    unit: maxDurationUnit,
    value: maxDurationValue
  } = product.maxDurationUI();

  const fixedRepayment = product.hasFixedRepaymentFrequency();

  const repaymentData = {
    maxDurationUnit,
    maxDurationValue,
    fixedRepayment
  };

  return repaymentData;
}

// *** Loan Term Fields ***
// repaymentPeriod = 2
// &&
// loanRepaymentUnit = "Month"
// =>
// "Repayment every 2 months"

// loanDuration = 12
// =>
// Installments would calculate to
// 12 Months / 2 Months = 6 Installments

export const RepaymentPeriodFields = props => {
  const { TextField, SelectField, data } = props;
  const { fixedRepayment } = getRepaymentTerms(data);

  return (
    <Field
      margin={false}
      name="repaymentPeriod"
      size="medium"
      required={true}
      disabled={fixedRepayment}
      labelId="LoanAdd.Configure.Repayment.label"
    >
      <div className={`${styles.textSelect} flex`}>
        <TextField
          disabled={fixedRepayment}
          size="medium"
          name="repaymentPeriod"
        />
        <SelectField
          disabled={fixedRepayment}
          name="repaymentPeriodUnit"
          size="medium"
        >
          {map(
            unit => (
              <SelectField.Option
                key={unit}
                translationId={`LoanAdd.Configure.Repayment.${unit}`}
                value={unit}
              />
            ),
            PeriodUnits
          )}
        </SelectField>
      </div>
    </Field>
  );
};

export const DurationField = props => {
  const { TextField, Condition, data, formProps } = props;
  const { values } = formProps;
  const { repaymentPeriodUnit } = values;
  const { maxDurationUnit, maxDurationValue } = getRepaymentTerms(data);

  // If the api returns a maxDurationUnit and a repaymentPeriodUnit,
  // they  should always be the same. If not, we have a problem.
  if (
    repaymentPeriodUnit &&
    maxDurationUnit &&
    repaymentPeriodUnit !== maxDurationUnit
  ) {
    Logger.error('Repayment Unit does not equal to Maximum duration Unit');
  }

  const installmentsInfo = (
    <>
      {maxDurationValue ? (
        <>
          <Text
            id={`LoanAdd.Configure.maxDuration.${maxDurationUnit}`}
            values={{
              value: maxDurationValue,
              unit: maxDurationUnit
            }}
          />
          <br />
        </>
      ) : null}
      <Condition when="repaymentPeriod" not="">
        {({ input: { value: repaymentPeriod } }) => (
          <Condition when="repaymentPeriodUnit" not="">
            <Condition when="loanDuration" not="">
              {({ input: { value: loanDuration } }) => (
                <Text
                  id="LoanAdd.Configure.installments"
                  values={{
                    num: calculateNumberInstallments({
                      period: repaymentPeriod,
                      loanDuration
                    })
                  }}
                />
              )}
            </Condition>
          </Condition>
        )}
      </Condition>
    </>
  );

  return (
    <TextField
      name="loanDuration"
      required={true}
      errorBaseId="LoanAdd.Configure.LoanDuration"
      titleId="LoanAdd.Configure.LoanDuration.title"
      rightGlyph={`LoanAdd.Configure.${repaymentPeriodUnit}`}
      info={installmentsInfo}
    />
  );
};

export const LoanTermsFields = props => {
  return (
    <div className={`pt4 bt b--light-grey-400`}>
      <h2>Terms</h2>
      <RepaymentPeriodFields {...props} />

      <div className={`${styles.row} pb4`}>
        <DurationField {...props} />
      </div>
    </div>
  );
};

LoanTermsFields.validate = {
  repaymentPeriod: {
    isRequired: () => true,
    positiveInteger: true
  },
  loanDuration: {
    isRequired: () => true,
    custom: (target, allData) => {
      const { repaymentPeriod } = allData;

      const { maxDurationValue } = getRepaymentTerms(allData);

      if (Number(target) > maxDurationValue) {
        return 'MaxDurationExceeded';
      }

      // Prevents "NaN Installments" from displaying if other
      // field ('repaymentPeriod') has non-positive input
      if (!(Number(repaymentPeriod) > 0)) {
        return 'NaNError';
      }

      if (Number(target) < Number(repaymentPeriod)) {
        return 'extendLoanDuration';
      }

      return null;
    },
    positiveInteger: true
  }
};
