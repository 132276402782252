// @flow

import { User } from './components/User';

export const steps = {
  user: {
    titleId: 'InviteUser.User.title',
    subtitleId: 'InviteUser.User.subtitle',
    Component: User,
    validate: User.validate,
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ]
  }
};
