//@flow
import * as React from 'react';
import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

import {
  getTransactionTypes,
  contexts,
  TransactionChannels
} from '@kwara/models/src/models/Transactions';
import { fields as savingFields } from '@kwara/models/src/models/Saving';
import {
  BankSelect,
  SubscribedPaymentSelectField
} from '@kwara/components/src/Form';

import {
  SubscribedFrequencySelectFields,
  SubscribedDatePicker
} from '../../../components/Form';
import { Grid } from '../../../components/Grid';
import { type SavingsSubStepProps } from '..';

const fields = savingFields.remittance;

type Props = SavingsSubStepProps;

const showBanksFieldsFor = [
  TransactionChannels.directDebit,
  TransactionChannels.bankTransfer
];

// Payroll Deduction fields are only shown
// during SavingAdd > Remittance step, not
// in the Remittance Editable Sections.
// We likely won't need this once ch16865
// is  actioned.
//
function showPayrollFields(fieldName, config) {
  return !!get(fieldName, config.validate);
}

export const Remittance = ({
  StackChild,
  Condition,
  TextField,
  addData,
  config
}: Props) => {
  return (
    <StackChild>
      <SubscribedPaymentSelectField
        name={fields.method}
        labelId="SavingAdd.Remittance.label"
        config={{
          bank: fields.bank,
          bankBranch: fields.branch,
          accountNumber: fields.account,
          methods: getTransactionTypes(contexts.SavingsRemittance).values,
          placeholderId: 'SavingAdd.Remittance.placeholder',
          showBanksFieldsFor
        }}
      />

      <Condition when={fields.method} is={TransactionChannels.directDebit}>
        <BankSelect
          name={fields.collectingBank}
          titleId="SavingAdd.RemittanceCollectionBank.title"
          labelId={null}
        />
        <SubscribedDatePicker
          required
          name={fields.startDate}
          labelId="SavingAdd.RemittanceStartDate.label"
        />
      </Condition>

      <Condition when={fields.method} is={TransactionChannels.payrollDeduction}>
        {showPayrollFields('member.employer', config) ? (
          <TextField
            name="member.employer"
            labelId="LoanAdd.Configure.RepaymentMode.Employer.title"
            required
          />
        ) : null}
        {showPayrollFields('member.staffId', config) ? (
          <TextField
            name="member.staffId"
            labelId="LoanAdd.Configure.RepaymentMode.StaffId.title"
            required
          />
        ) : null}
      </Condition>

      <Grid columns={2} width="w-50" border={false}>
        <TextField
          name="monthlyRemittanceAmount"
          labelId="SavingAdd.RemittanceAmount.label"
        />
        <TextField
          name={fields.feeAmount}
          labelId="SavingAdd.RemittanceFee.label"
        />
      </Grid>

      <SubscribedFrequencySelectFields
        addData={addData}
        frequency={fields.frequency}
        day={fields.collectionDate}
      />
    </StackChild>
  );
};

const requiredForBankChannels = {
  isRequired: (_, allData) =>
    includes(get(fields.method, allData), showBanksFieldsFor)
};

const requiredForDirectDebit = {
  isRequired: (_, allData) =>
    get(fields.method, allData) === TransactionChannels.directDebit
};

const requiredForPayroll = {
  isRequired: (_, allData) =>
    get(fields.method, allData) === TransactionChannels.payrollDeduction
};

const base = {
  monthlyRemittanceAmount: {
    currency: true
  },
  [fields.bank]: requiredForBankChannels,
  [fields.branch]: requiredForBankChannels,
  [fields.account]: requiredForBankChannels,
  [fields.collectingBank]: requiredForDirectDebit,
  [fields.startDate]: requiredForDirectDebit,
  [fields.feeAmount]: {
    currency: true
  },
  ...SubscribedFrequencySelectFields.validate(
    fields.frequency,
    fields.collectionDate
  )
};

const savingAdd = {
  ...base,
  'member.employer': requiredForPayroll,
  'member.staffId': requiredForPayroll
};

Remittance.validateConfig = {
  savingAdd,
  savingEdit: base
};
