// @flow

import React from 'react';
import map from 'lodash/fp/map';

import { Loadable } from '@kwara/components/src/Loadable';
import { type LoanProductType } from '@kwara/models/src';

import { LoanProductSelector } from '../../../components/ProductSelector';
import { useLoanProducts } from '../../../models/request';

import { type ComponentPropsLoanReschedule } from '..';

function markAllForDestruction(list) {
  return map(item => {
    item.isMarkedForDestruction = true;
    return item;
  }, list);
}

function markAllForNonDestruction(list) {
  return map(item => {
    item.isMarkedForDestruction = false;
    return item;
  }, list);
}

export const Product = ({ addData, data }: ComponentPropsLoanReschedule) => {
  const res = useLoanProducts();

  const onSelect = async (product: LoanProductType) => {
    const {
      unit: repaymentPeriodUnit,
      value: repaymentPeriod
    } = product.toRepaymentFrequencyUI();

    const repaymentData =
      repaymentPeriodUnit || repaymentPeriod
        ? {
            repaymentPeriodUnit,
            repaymentPeriod
          }
        : {};

    const collaterals = product.hasCollateralsEnabled()
      ? markAllForNonDestruction(data.loan.collaterals)
      : markAllForDestruction(data.collaterals);

    const guarantors = product.hasGuarantorsEnabled()
      ? markAllForNonDestruction(data.loan.guarantors)
      : markAllForDestruction(data.guarantors);

    return addData({ product, guarantors, collaterals, ...repaymentData });
  };

  return (
    <Loadable {...res}>
      {products => (
        <LoanProductSelector
          products={products}
          onSelect={onSelect}
          selectable={true}
        />
      )}
    </Loadable>
  );
};
