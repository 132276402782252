import React from 'react';
import { View, Image } from '@react-pdf/renderer';
import trim from 'lodash/fp/trim';

import { getCurrentDate } from '@kwara/lib/src/dates';

import { Date, Time } from './Date';

import { DefaultText as Text, TitleText as Title, Section } from '.';

import logo from './stima_logo.jpg';

const Logo = () => {
  return (
    <View style={{ width: 150, flexGrow: 1 }}>
      <Image style={{ width: 90, height: 90 }} src={logo} />
    </View>
  );
};

const MainTitleInfo = ({ text = 'Customer Statement of Account' }) => {
  return (
    <View
      style={{
        flexDirection: 'column',
        textAlign: 'center',
        width: 200
      }}
    >
      <Title>{text}</Title>
      <Text>
        Generated on <Date>{getCurrentDate()}</Date> at{' '}
        <Time>{getCurrentDate()}</Time>
      </Text>
    </View>
  );
};

const SaccoContactInfo = ({ sacco }) => {
  const { name, phoneNumber, email, address = {} } = sacco;
  const { line1, line2, city, region, postcode, country } = address;

  const regionLine = city || region ? trim(`${city} ${region}`) : null;
  const countryLine =
    postcode || country ? trim(`${postcode} ${country}`) : null;

  return (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'right',
        flexGrow: 1,
        width: 150
      }}
    >
      <Text>{name}</Text>
      {line1 ? <Text>{line1}</Text> : null}
      {line2 ? <Text>{line2}</Text> : null}
      {regionLine ? <Text>{regionLine}</Text> : null}
      {countryLine ? <Text>{countryLine}</Text> : null}
      {email ? <Text>{email}</Text> : null}
      {phoneNumber ? <Text>{phoneNumber}</Text> : null}
    </View>
  );
};

export const Header = ({ sacco, titleText }) => {
  return (
    <Section fixed>
      <Logo />
      <MainTitleInfo text={titleText} />
      <SaccoContactInfo sacco={sacco} />
    </Section>
  );
};
