//@flow

import * as React from 'react';
import size from 'lodash/fp/size';
import pullAt from 'lodash/pullAt';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { Text } from '@kwara/components/src/Intl';

import { type SubStepComponentProps } from '../../../../components/Wizard';
import Header from './Header';
import { EditActions, KinActions } from './Actions';

import styles from './index.module.css';

const maxKin = 4;

export const NextOfKin = (props: SubStepComponentProps<>) => {
  const { onChange, formProps, TextArea, StackChild } = props;
  const [isEditing, setEditing] = React.useState<boolean>(false);
  const [index, setIndex] = React.useState<number>(0);
  const onEdit = idx => {
    setIndex(idx);
    setEditing(true);
  };
  const { kin = [] } = props.data;

  return (
    <StackChild>
      <Header
        disabled={size(kin) === maxKin || isEditing}
        onAdd={() => onEdit(kin.length)}
      />
      {isEditing ? (
        <div className={styles.Edit}>
          <TextArea
            margin={false}
            name={`kin[${index}`}
            labelId="AddMember.Kin.position.edit"
            placeholderId="AddMember.Kin.placeholder"
            values={{ position: index + 1, br: <br /> }}
          />
          <EditActions
            onDone={() =>
              onChange({ kin: formProps.values.kin }).then(() =>
                setEditing(false)
              )
            }
          />
        </div>
      ) : (
        mapIndexed(
          (k, idx) => (
            <div key={`${k}${idx}`} className="mb4">
              <h4 className="mt0 mb2 kw-text-small kw-weight-normal grey-400">
                <Text
                  id="AddMember.Kin.position"
                  values={{ position: idx + 1 }}
                />
              </h4>

              <div className="pre mv3 pa3 br3 b--transparent bg-semi-white-400 kw-text-regular">
                {k}
              </div>

              <KinActions
                onEdit={() => onEdit(idx)}
                onRemove={() => {
                  pullAt(props.data.kin, [idx]);
                  return onChange(props.data);
                }}
              />
            </div>
          ),
          props.data.kin
        )
      )}
    </StackChild>
  );
};
