// @flow

import * as React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

import zIndices from '@kwara/lib/src/zIndices';
import { isDev, isSandbox } from '@kwara/lib/src/utils';

import styles from './index.module.scss';

const envs: { name: string, color: string } = (() => {
  if (isSandbox) {
    return {
      name: 'Sandbox',
      color: 'bg-amber-100 amber-700'
    };
  }

  if (isDev) {
    return {
      name: 'Development',
      color: 'bg-teal-100 teal-700'
    };
  }

  return {
    name: '',
    color: ''
  };
})();

export const EnvironmentBanner = observer(({ ctx }) => {
  const store = React.useContext(ctx);
  const { profile = {} } = store || {};

  if (!envs.name) {
    return null;
  }

  return (
    <div className={cx(envs.color, styles.Banner, zIndices.Banner)}>
      {envs.name} {profile.apiInstance ? `- ${profile.apiInstance}` : null}
    </div>
  );
});
