import React from 'react';
import { View, Text } from '@react-pdf/renderer';

const styles = {
  row: {
    flexDirection: 'row',
    marginBottom: 5,
    alignItems: 'stretch',
    width: '100%',
    borderBottom: 1,
    borderBottomColor: 'grey'
  }
};

export const Row = ({ style, children }) => {
  return (
    <View wrap={false} style={{ ...styles.row, ...style }}>
      {children}
    </View>
  );
};

export const EndOfStatement = () => {
  return (
    <View style={{ padding: '10px', borderBottom: true }}>
      <Text
        id="FooterPDF.endOfStatement"
        style={{ fontSize: 12, textAlign: 'center', color: 'black' }}
      />
    </View>
  );
};
