// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import pipe from 'lodash/fp/pipe';
import split from 'lodash/fp/split';
import filter from 'lodash/fp/filter';
import getOr from 'lodash/fp/getOr';

export const Body = ({ pathname }: { pathname: string }) => {
  /*
    Sets the body background color to a particular class
    This is required so that when scrolling, a white
    background does not appear above the navigation.
  */
  const colours = {
    loans: 'bg-red-50',
    members: 'bg-indigo-50',
    savings: 'bg-teal-50'
  };

  const page = pipe(
    split('/'),
    filter(Boolean)
  )(pathname);

  // The key below is to prevent a maximum call stack exceeded error
  // that would happen in some e2e tests. See relevant github thread:
  // https://github.com/nfl/react-helmet/issues/373
  return (
    <Helmet key={window.location.href}>
      <body className={getOr(colours.members, page, colours)} />
    </Helmet>
  );
};
