//@flow

import React from 'react';
import { Form } from 'react-final-form';

import createValidator from '@kwara/lib/src/validator';

import { ActionModal, Panel }from '../ActionModal';

export const FormActionModal = ({
  config = {},
  onSubmit = () => {},
  onCancel = () => {},
  titleId,
  confirmId,
  children,
  isOpen = true,
  initialValues = {}
}) => {
  const validate = createValidator(config);

  if (!isOpen) {
    return null;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      render={formProps => {
        const { handleSubmit, invalid, form } = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <ActionModal
              titleId={titleId}
              onCancel={onCancel}
              disabled={invalid}
              confirmId={confirmId}
              onConfirm={form.submit}
            >
              {children}
            </ActionModal>
          </form>
        );
      }}
    />
  );
};

FormActionModal.Panel = Panel;
