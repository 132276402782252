// @flow

import React from 'react';
import getOr from 'lodash/fp/getOr';

import { type MemberType, type UserT } from '@kwara/models/src';
import {
  pdfMemberDetails as MemberDetails,
  Document,
  EndOfStatement,
  Page,
  Section,
  SubtitleText as Subtitle,
  pdfTranslationStore as pdf
} from '@kwara/components/src/PDF';

import { SavingsTable } from './SavingsTable';
import { SavingsAccounts } from './SavingsAccounts';
import { LoanAccounts } from './LoanAccounts';
import { LoanTable } from './LoanTable';

type Props = {
  member: MemberType,
  profile: UserT
};

// Create Document Component
export const MemberSummaryPDF = ({ member, profile }: Props) => {
  const branch = getOr('', 'branch', profile);
  return (
    <Document
      title={pdf.translate('MemberSummaryPDF.title', {
        memberId: member.id
      })}
    >
      <Page sacco={branch}>
        <Section>
          <MemberDetails member={member} />
        </Section>
        <Section style={{ textAlign: 'center' }}>
          <Subtitle id="MemberSummaryPDF.subtitle.SavingAccounts" />
        </Section>
        <SavingsAccounts member={member} />
      </Page>
      <Page sacco={branch}>
        <Section style={{ textAlign: 'center' }}>
          <Subtitle id="MemberSummaryPDF.subtitle.SavingTransactions" />
        </Section>
        <SavingsTable member={member} />
      </Page>
      <Page sacco={branch}>
        <Section style={{ textAlign: 'center' }}>
          <Subtitle id="MemberSummaryPDF.subtitle.LoanAccounts" />
        </Section>
        <LoanAccounts member={member} />
      </Page>
      <Page sacco={branch}>
        <Section style={{ textAlign: 'center' }}>
          <Subtitle id="MemberSummaryPDF.subtitle.LoanTransactions" />
        </Section>
        <LoanTable member={member} />
        <EndOfStatement />
      </Page>
    </Document>
  );
};
