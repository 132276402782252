// @flow

import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import style from '../../index.module.css';

export const Success = () => {
  return (
    <div className={style.Content}>
      <h1>
        <Text id="SignUp.Success" />
      </h1>
      <p>
        <Text id="SignUp.Success.message" values={{ br: <br /> }} />
      </p>

      <Button className="mt2" to="/" primary>
        <Text id="SignUp.Success.button" />
      </Button>
    </div>
  );
};
