//@flow

import * as React from 'react';

import pipe from 'lodash/fp/pipe';
import concat from 'lodash/fp/concat';
import reject from 'lodash/fp/reject';
import some from 'lodash/fp/some';

import { UploadWidget } from '@kwara/components/src/UploadWidget';

import { type SubStepComponentProps } from '../../../components/Wizard';

const fieldNames = [
  { name: 'idDocument', label: 'UploadID' },
  { name: 'application', label: 'UploadApplication' }
];

function addAttachment(attachments = [], value) {
  return {
    attachments: pipe(
      reject(e => e.name === value.fileName),
      concat(value)
    )(attachments)
  };
}

function removeAttachment(attachments = [], fileName) {
  return {
    attachments: reject(e => e.name === fileName, attachments)
  };
}

function isDirty(attachments = [], fileName) {
  return some(att => att.name === fileName, attachments);
}

export default ({ StackChild, addData, data }: SubStepComponentProps<>) => (
  <StackChild>
    {fieldNames.map(f => (
      <UploadWidget
        key={f.name}
        onChange={v => addData(addAttachment(data.attachments, v))}
        onRemove={fileName =>
          addData(removeAttachment(data.attachments, fileName))
        }
        member={data}
        fileName={f.name}
        checked={isDirty(data.attachments, f.name)}
        textId={`AddMember.Documents.${f.label}.label`}
      />
    ))}
  </StackChild>
);
