// @flow
import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { Notification } from '@kwara/components/src/Notification';
import { Logger } from '@kwara/lib/src/logger';

const runningAppId = function() {
  const meta = document.querySelector('meta[name="x-app-id"]');
  if (meta) {
    return meta.getAttribute('content');
  }

  return '';
};

const updateCheck = async (currentUrl = window.location) => {
  try {
    const releaseUrl = new URL(currentUrl);
    releaseUrl.pathname = '/release.json';

    const response = await fetch(releaseUrl, { credentials: 'same-origin' });
    const bundle = await response.json();

    return bundle.appId;
  } catch (error) {
    return null;
  }
};

// const TEN_SECONDS_MS = 1000 * 10;
const HOURLY_MS = 1000 * 60 * 60;
const UPDATE_CHECK_INTERVAL_MS = HOURLY_MS;

export class AppUpdateChecker extends React.Component<*, *> {
  constructor() {
    super();

    this.runningAppId = runningAppId();

    Logger.log('AppUpdateChecker runningAppId:', this.runningAppId);
  }

  state = {
    hasUpdate: false
  };

  componentDidMount() {
    // I know, but it's useful
    window.checkForAppUpdates = this.checkForUpdates;

    if (this.runningAppId) {
      this.scheduleAppUpdateCheck();
    } else {
      Logger.warn('Cannot find runningApId so will not poll for updates');
    }
  }

  componentDidCatch() {}

  updateIntervalMs = UPDATE_CHECK_INTERVAL_MS;
  runningAppId: ?string;
  timerId: ?number;

  scheduleAppUpdateCheck = () => {
    if (this.timerId != null) {
      window.clearTimeout(this.timerId);
    }

    this.timerId = window.setTimeout(
      this.checkForUpdates,
      this.updateIntervalMs
    );
  };

  checkForUpdates = async () => {
    const latestAppId = await updateCheck();
    if (latestAppId != null && this.runningAppId !== latestAppId) {
      this.setState({
        hasUpdate: true
      });
    } else {
      this.scheduleAppUpdateCheck();
    }
  };

  reloadPage = () => window.location.reload();

  render() {
    return this.state.hasUpdate ? (
      <Notification>
        <Text id="AppUpdateChecker.newRelease" />
        <Button
          className="ml-auto mr3"
          size="small"
          type="secondary"
          onClick={this.reloadPage}
        >
          <Text id="AppUpdateChecker.refresh" />
        </Button>
      </Notification>
    ) : null;
  }
}
