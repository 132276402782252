//@flow

import React from 'react';
import typeof { history } from 'react-router-dom';

import {
  ConfirmationClose,
  ATMModal,
  ChequeModal
} from './components/ConfirmationClose';
import { SearchModal } from './components/SearchModal';
import { Success } from './components/Success';
import { Utilities } from './components/Utilities';

import { type ModalState, type ModalAction } from '../..';

type Props = {
  history: history,
  isTillOpen: boolean,
  activeModal: ModalState,
  updateModal: ModalAction,
  openTill: () => void,
  closeTill: () => void,
  submitUtilityPayment: () => void
};

export const TillModal = ({
  isTillOpen,
  history,
  activeModal,
  updateModal,
  closeTill,
  submitUtilityPayment
}: Props) => {
  return (
    <>
      {activeModal === 'confirmClose' ? (
        <ConfirmationClose updateModal={updateModal} closeTill={closeTill} />
      ) : null}

      {activeModal === 'giveMeCard' ? (
        <ATMModal updateModal={updateModal} />
      ) : (
        ''
      )}

      {activeModal === 'giveMeCheque' ? (
        <ChequeModal updateModal={updateModal} />
      ) : (
        ''
      )}

      {activeModal === 'success' ? (
        <Success
          onCancel={() => updateModal({ activeModal: null })}
          isTillOpen={isTillOpen}
        />
      ) : null}

      {activeModal === 'utilities' ? (
        <Utilities
          submitUtilityPayment={submitUtilityPayment}
          updateModal={updateModal}
        />
      ) : null}

      {activeModal === 'withdrawal' ||
      activeModal === 'deposit' ||
      activeModal === 'repayment' ? (
        <SearchModal
          updateModal={updateModal}
          history={history}
          titleId={`Till.Search.Modal.title.${activeModal}`}
          headerId={`Till.Search.Modal.header.${activeModal}`}
        />
      ) : null}
    </>
  );
};
