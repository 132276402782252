//@flow

const pathWithBase = (base, isIndex) => (
  {
    id,
    action,
    baseExtension,
    ...rest
  }: {
    id?: string,
    action?: string,
    baseExtension?: string
  } = {},
  { asObject = false }: { asObject: boolean } = {}
): string => {
  const restKeys = Object.keys(rest);

  const pathname = [
    '/',
    id == null && action == null && isIndex ? '' : base,
    baseExtension ? '/' + baseExtension : '',
    id ? '/' + id : '',
    action ? '/' + action : ''
  ].join('');

  const search =
    restKeys.length > 0
      ? '?' + restKeys.map(key => `${key}=${rest[key]}`).join('&')
      : '';

  return asObject === true ? { pathname, search } : `${pathname}${search}`;
};

export const loanPath = pathWithBase('loans');

export const memberPath = pathWithBase('members', true);

export const profilePath = pathWithBase('profile');

export const savingPath = pathWithBase('savings');

export const settingPath = pathWithBase('settings');

export const financePath = pathWithBase('finance');

export const transactionPath = pathWithBase('transactions');

export const tellerPath = pathWithBase('tellers');

export const tillPath = pathWithBase('till');

export const topupReviewPath = pathWithBase('topup');

export const topupRequestPath = pathWithBase('topup/new');

export const batchTransactionImportsPath = pathWithBase(
  'batch_transaction_imports'
);

export const creditPath = pathWithBase('credit');

export const batchUplaodsPath = '/uploads';

export const currentSection = (path: string = window.location.pathname) => {
  if (path === '/') {
    return 'members';
  }

  try {
    return path.split('/')[1];
  } catch (error) {
    return null;
  }
};
