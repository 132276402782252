// @flow

import * as React from 'react';

import { Member } from '@kwara/models/src';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import { Text } from '@kwara/components/src/Intl';
import { InfoPanel } from '@kwara/components/src/InfoPanel';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { OverviewMetadata } from './OverviewMetadata';

import { DetailOverviewSection } from '../../../components/Detail/DetailOverviewSection';
import { LoanEligibilityPanel } from '../../../components/LoanEligibilityPanel';
import { LoansPanel } from '../../../components/LoansPanel';
import { SavingsPanel } from '../../../components/SavingsPanel';
import { Currency } from '../../../components/Currency';

type Props = {
  member: typeof Member,
  actions: React.Node,
  navigation: React.Node,
  showBack: boolean,
  statusOverride: void | 'APPLICANT' | 'GUARANTOR' | null
};

export default ({
  member,
  navigation,
  actions,
  showBack,
  statusOverride
}: Props) => {
  const isEligibleForLoan = member.isEligibleForLoan();

  return (
    <DetailOverviewSection
      metadata={
        <OverviewMetadata
          member={member}
          actions={actions}
          showBack={showBack}
          className="hide-on-print"
          statusOverride={statusOverride}
        />
      }
      secondary={navigation}
      title={
        <Text
          id="MemberDetail.title"
          values={{ id: member.id, fullName: member.fullName() }}
        />
      }
      headerRight={
        <ProfilePhoto
          memberId={member.id}
          attachments={member.attachments}
          size="regular"
          isExpandable
        />
      }
    >
      <section className="mb5">
        <StatisticRow widthClass="w-50">
          <Statistic
            title={
              <>
                <Text id="MemberDetail.Overview.deposits" />{' '}
                <InfoPanel>
                  <SavingsPanel savings={member.savings} />
                </InfoPanel>
              </>
            }
            value={<Currency value={member.totalSavings || 0} />}
            color={'teal-600'}
            size={'huge'}
          />
          <Statistic
            title={
              <>
                <Text id="MemberDetail.Overview.loans" />{' '}
                <InfoPanel>
                  <LoansPanel loans={member.loans} />
                </InfoPanel>
              </>
            }
            value={<Currency value={member.totalLoansBalance || 0} />}
            border={true}
            color={'red-600'}
            size={'huge'}
          />
        </StatisticRow>
        <StatisticRow widthClass="w-50" border={true}>
          <Statistic
            title={
              <>
                <Text id="MemberDetail.Overview.eligibleAmount" />{' '}
                <InfoPanel>
                  <LoanEligibilityPanel member={member} />
                </InfoPanel>
              </>
            }
            value={
              isEligibleForLoan ? (
                <Currency value={member.eligibleAmount} />
              ) : (
                '-'
              )
            }
            border={true}
            size={'huge'}
          />
          <Statistic
            data-testid="member.shareCapitalAmount"
            title={<Text id="MemberDetail.Overview.shareCapitalAmount" />}
            value={
              member.shareCapitalAmount ? (
                <Currency value={member.shareCapitalAmount} />
              ) : null
            }
            border={true}
            size={'huge'}
          />
        </StatisticRow>
        <StatisticRow widthClass="w-25" border>
          <Statistic
            title={<Text id="MemberDetail.Overview.netValue" />}
            value={<Currency value={member.standing} />}
            border={true}
            size={'large'}
            color={member.standing < 0 ? 'red-600' : ''}
          />
          <Statistic
            title={<Text id="MemberDetail.Overview.pending" />}
            value={<Currency value={member.numberPendingLoans()} />}
            border={true}
            size={'large'}
          />
          <Statistic
            title={<Text id="MemberDetail.Overview.guaranteedLoans" />}
            value={<Currency value={member.totalGuaranteed} />}
            border={true}
            size={'large'}
          />
          <Statistic
            title={<Text id="MemberDetail.Overview.mobileBanking" />}
            value={
              member.mbankingStatus ? (
                <Text
                  id={`MemberDetail.Overview.mobileBanking.${member.mbankingStatus}`}
                />
              ) : null
            }
            border={true}
            size={'large'}
          />
        </StatisticRow>
        <StatisticRow
          widthClass={member.historicalId() ? 'w-25' : 'w-third'}
          border
        >
          <Statistic
            title={<Text id="MemberDetail.Overview.email" />}
            value={member.email}
            border={true}
            size={'large'}
            breakWord
          />
          <Statistic
            title={<Text id="MemberDetail.Overview.nationalId" />}
            value={member.nationalId()}
            border={true}
            size={'large'}
          />
          <Statistic
            title={<Text id="MemberDetail.Overview.mobile" />}
            value={member.phoneNumber}
            border={true}
            size={'large'}
          />
          {member.historicalId() && (
            <Statistic
              title={<Text id="MemberDetail.Overview.historicalId" />}
              value={member.historicalId()}
              border={true}
              size={'large'}
            />
          )}
        </StatisticRow>
      </section>
    </DetailOverviewSection>
  );
};
