//@flow

import React from 'react';
import typeof { history } from 'react-router-dom';

import { Text } from '@kwara/components/src/Intl';

import { ActionModal } from '../../../../../components/ActionModal';
import MemberSearch from '../../../../../components/MemberSearch';

import { memberPath } from '../../../../../lib/urls';

import { type ModalAction } from '../../..';

type Props = {
  history: history,
  updateModal: ModalAction,
  headerId: string,
  titleId: string
};

export const SearchModal = ({
  updateModal,
  history,
  headerId,
  titleId
}: Props) => {
  return (
    <ActionModal
      titleId={titleId}
      hideConfirm={true}
      onCancel={() => updateModal({ activeModal: null })}
    >
      <div className="mt6 mb5">
        <span className="kw-text-extra-big kw-weight-bold">
          <Text id={headerId} />
        </span>
      </div>
      <MemberSearch
        onSelect={member => {
          history.push(memberPath({ id: member.id }));
        }}
      />
    </ActionModal>
  );
};
