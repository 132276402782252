// @flow

import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Loadable } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';

import { settingPath } from '../../../../lib/urls';
import { useSavingProducts } from '../../../../models/request';

import { ProductTable } from './SavingProductsTable';
import { Header } from '../Header';

export const Savings = () => {
  const r = useSavingProducts();

  return (
    <>
      <Header
        titleId="Settings.Savings.titleId"
        subtitleId="Settings.Savings.subtitleId"
        action={
          <Button
            to={settingPath({ baseExtension: 'saving', action: 'new' })}
            type="primary"
          >
            <Text id="Settings.Savings.add" />
          </Button>
        }
      />
      <Loadable {...r}>
        {products => <ProductTable products={products} />}
      </Loadable>
    </>
  );
};
