//@flow

import * as React from 'react';

import { type TopupRequestT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';

import { Panel } from '../../../components/ActionModal';
import { type SubStepComponentProps } from '../../../components/Wizard';

export type SubmitFormProps = SubStepComponentProps<TopupRequestT> & {
  isApproved: boolean
};

export const Submit = ({
  data,
  StackChild,
  TextArea,
  isApproved
}: SubmitFormProps) => {
  const { till } = data;
  const { tellerName } = till;

  return (
    <StackChild>
      <Panel>
        <h3 className="kw-text-large kw-weight-bold">
          <Text
            values={{ tellerName }}
            id={`TopupReview.${isApproved ? 'approve' : 'reject'}.main`}
          />
        </h3>
        <p className="kw-text-regular mb5">
          <Text
            values={{ tellerName }}
            id={`TopupReview.${isApproved ? 'approve' : 'reject'}.subtext`}
          />
        </p>
        <TextArea
          name="comment"
          placeholderId="TopupReview.notes.placeholder"
          infoId="TopupReview.notes.info"
        />
      </Panel>
    </StackChild>
  );
};
