import 'babel-polyfill';
import 'raf/polyfill'; // React and old IE support
import React from 'react';
import ReactDOM from 'react-dom';
import focusWithin from 'ally.js/esm/style/focus-within';
import 'url-search-params-polyfill';

import App from './App';

focusWithin(); // Polyfills the :focus-within CSS selector on IE/Edge

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
