// @flow

import * as React from 'react';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';

import { Currency } from '../../../components/Currency';
import { Record, Section } from '../../../components/Summary';
import { getReceivingAccount, type SubstepProps } from '..';

export const Summary = ({ StackChild, data }: SubstepProps) => {
  const { sender, recipient, saving, linkedAccountId, amount } = data;

  const receivingAccount = getReceivingAccount(recipient, linkedAccountId);

  return (
    <StackChild>
      <Section titleId="MakeTransfer.Summary.Sender.title">
        <Record value={invoke('fullName', sender)} />
        <Record value={get('product.name', saving)} />
        <Record value={get('id', saving)} />
      </Section>
      <Section titleId="MakeTransfer.Summary.Recipient.title">
        <Record value={invoke('fullName', recipient)} />
        <Record value={get('product.name', receivingAccount)} />
        <Record value={get('id', receivingAccount)} />
      </Section>
      <Section titleId="MakeTransfer.Summary.Amount.title">
        <Record value={<Currency format="currency" value={amount} />} />
      </Section>
    </StackChild>
  );
};
