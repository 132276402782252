// @flow

import * as React from 'react';

import { type MemberType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import type { ValidationRules } from '@kwara/lib/src/validator';
import { excludedEmploymentField } from '@kwara/lib/src/modelUtils/member';

import { Grid } from '../../../components/Grid';
import {
  EditableSection,
  type EditableConfig
} from '../../../components/EditableSection';
import {
  Employment as EmploymentBaseForm,
  EmploymentExtras,
  validateConfig
} from '../../MemberAdd/components/Employment';

// Editable forms configs
const base = {
  Component: props => (
    <>
      <EmploymentBaseForm {...props} />
      <EmploymentExtras {...props} />
    </>
  )
};
const memberDetail = {
  ...base,
  validate: validateConfig.memberDetail
};
const loanAdd = {
  ...base,
  validate: validateConfig.loanAdd
};

interface EditableSectionT {
  (): React.Node;
  Title: () => React.Element<Text>;
  editConfig?: { [k: 'memberDetail' | 'loanAdd']: ValidationRules };
}

export const Employment: EditableSectionT = ({
  config = memberDetail,
  member,
  readOnly
}: EditableConfig<MemberType>) => {
  const shouldHide = excludedEmploymentField(member);
  return (
    <EditableSection config={config} initialData={member} readOnly={readOnly}>
      <Grid columns={2} width="w-50">
        <Statistic
          title={<Text id="AddMember.Employment.Profession.label" />}
          value={member.profession}
          size="medium"
          hidden={shouldHide('profession')}
        />
        <Statistic
          title={<Text id="AddMember.Employment.Type.label" />}
          value={
            member.employmentStatus ? (
              <Text
                id={`AddMember.Employment.EmploymentStatus.${member.employmentStatus}`}
              />
            ) : null
          }
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.Employer.label" />}
          value={member.employer}
          size="medium"
          hidden={shouldHide('employer')}
        />
        <Statistic
          title={<Text id="AddMember.Employment.StaffId.label" />}
          value={member.staffId}
          size="medium"
          hidden={shouldHide('staffId')}
        />
        <Statistic
          title={<Text id="AddMember.Employment.Business.label" />}
          value={member.business}
          size="medium"
          hidden={shouldHide('business')}
        />
        <Statistic
          title={<Text id="AddMember.Employment.EmployerEmail.label" />}
          value={member.employerEmail}
          size="medium"
          hidden={shouldHide('employerEmail')}
        />
        <Statistic
          title={<Text id="AddMember.Employment.EmployerPhoneNumber.label" />}
          value={member.employerPhoneNumber}
          size="medium"
          hidden={shouldHide('employerPhoneNumber')}
        />
        <Statistic
          title={<Text id="AddMember.Employment.MemberWorkEmail.label" />}
          value={member.workEmail}
          size="medium"
          hidden={shouldHide('workEmail')}
        />
        <Statistic
          title={<Text id="AddMember.Employment.GrossIncome.label" />}
          value={member.grossIncome}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.NetIncome.label" />}
          value={member.netIncome}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.OtherDeductibles.label" />}
          value={member.otherDeductibles}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.OtherIncomeAmount.label" />}
          value={member.otherIncomeAmount}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.IncomeSource.label" />}
          value={member.incomeSource}
          size="medium"
        />
        <Statistic
          title={
            <Text id="AddMember.Employment.DisposableIncomeAmount.label" />
          }
          value={member.disposableIncomeAmount}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.tos.label" />}
          hidden={shouldHide('termsOfService')}
          value={
            member.termsOfService ? (
              <Text id={`AddMember.Employment.tos.${member.termsOfService}`} />
            ) : null
          }
          size="medium"
        />
      </Grid>
      <Grid columns={2} width="w-50">
        <Statistic
          title={<Text id="AddMember.Employment.Group.label" />}
          value={<Text id={`AddMember.Employment.Group.${member.isGroup}`} />}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.Staff.label" />}
          value={<Text id={`AddMember.Employment.Staff.${member.isStaff}`} />}
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.Director.label" />}
          value={
            <Text id={`AddMember.Employment.Director.${member.isDirector}`} />
          }
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.Delegate.label" />}
          value={
            <Text id={`AddMember.Employment.Delegate.${member.isDelegate}`} />
          }
          size="medium"
        />
        <Statistic
          title={<Text id="AddMember.Employment.GovEmployee.label" />}
          value={
            <Text
              id={`AddMember.Employment.GovEmployee.${member.govEmployee}`}
            />
          }
          size="medium"
        />
      </Grid>
    </EditableSection>
  );
};

Employment.Title = () => <Text id="MemberDetail.employment" />;
Employment.editConfig = { memberDetail, loanAdd };
