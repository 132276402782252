// @flow

import * as React from 'react';

import { LoanType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';

import { GuaranteeList } from './GuaranteeList';
import { CollateralList } from './CollateralList';

type Props = { loan: LoanType };

export const Securities = (props: Props) => {
  const { loan } = props;
  if (!loan) {
    return null;
  }

  if (loan.hasNoSecurities()) {
    return (
      <div className="mb3">
        <Text id="LoanDetail.Security.none" />
      </div>
    );
  }

  return (
    <>
      <GuaranteeList loan={loan} />
      <CollateralList loan={loan} />
    </>
  );
};
