// @flow

import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';

type SmallProps = {
  children: React.Node,
  className?: string,
  translationId: string
};

export const Small = ({ children, className, translationId }: SmallProps) => (
  <small className={cx('grey-400 kw-text-small', className)}>
    {translationId ? <Text id={translationId} /> : children}
  </small>
);

export const PageHeading = ({
  translationId,
  values,
  children
}: {
  translationId: string,
  children?: React.Node,
  values?: { [string]: mixed }
}) => (
  <h1 className="mt4 mb0 kw-text-extra-big kw-weight-bold">
    {translationId ? <Text id={translationId} values={values} /> : children}
  </h1>
);

export const supportEmail = 'support@kwara.com';
export const SupportEmailLink = () => (
  <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
);
