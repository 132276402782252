// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import { onlyTruthyValues, isEmpty } from '@kwara/lib/src/lodash';
import { formatIsoDate } from '@kwara/lib/src/dates';
import { Loadable } from '@kwara/components/src/Loadable';
import { type PeriodUnitsT } from '@kwara/models/src';

import {
  Loan,
  Collateral,
  type LoanType,
  type MemberType,
  type GuaranteeType,
  type CollateralT,
  type LoanProductType
} from '@kwara/models/src';
import {
  type RepaymentDetailsT,
  type DisbursementBankDetailsT
} from '@kwara/models/src/models/Loan';

import { type SubStepComponentProps } from '../../components/Wizard';
import Wizard from '../../components/Wizard';
import { loanPath } from '../../lib/urls';
import { useLoan } from '../../models/request';
import { steps } from './config';
import type { WizardPageProps } from '..';

type Props = WizardPageProps<{ loanId: string }>;

export type FormDataLoanReschedule = {
  loan: LoanType,
  productId: string,
  product: LoanProductType,
  member: MemberType,
  amount: string,
  loanDuration: string,
  loanInstallments?: string,
  guarantors: GuaranteeType[],
  collaterals: CollateralT[],
  repaymentPeriodUnit: PeriodUnitsT,
  disbursementMode: string,
  repaymentDetails: RepaymentDetailsT,
  disbursementBankDetails: DisbursementBankDetailsT,
  repaymentPeriod: string,
  firstRepaymentDate: Date,
  writeOffAmount?: string | number
};

export type ComponentPropsLoanReschedule = SubStepComponentProps<FormDataLoanReschedule>;

const includes = [
  'member',
  'product',
  'guarantees.member',
  // 'guarantors.member',
  'member.attachments',
  'collaterals'
];

export function filterDeletedSecurities(
  securities: (GuaranteeType | CollateralT)[]
) {
  return filter(security => !security.isMarkedForDestruction, securities);
}

function hasUpdatedInfo(details) {
  return !isEmpty(onlyTruthyValues(details));
}

const rescheduleLoan = async (data: FormDataLoanReschedule) => {
  const {
    loan,
    productId,
    loanDuration,
    loanInstallments,
    guarantors,
    collaterals: persistedCollaterals,
    repaymentPeriodUnit,
    repaymentPeriod,
    firstRepaymentDate,
    disbursementMode: disbursementModeUpdated,
    disbursementBankDetails: disbursementBankDetailsUpdated,
    repaymentDetails: repaymentDetailsUpdated,
    amount,
    writeOffAmount
  } = data;

  // Access the original loan's disbursement and repayment details
  const {
    repaymentDetails: repaymentDetailsOriginal,
    disbursementMode: disbursementModeOriginal,
    disbursementBankDetails: disbursementBankDetailsOriginal
  } = loan;

  // Compare with the current form's values, prioritizing the current form's values.
  const disbursementMode = disbursementModeUpdated || disbursementModeOriginal;
  const disbursementBankDetails = hasUpdatedInfo(disbursementBankDetailsUpdated)
    ? disbursementBankDetailsUpdated
    : disbursementBankDetailsOriginal;
  const repaymentDetails = hasUpdatedInfo(repaymentDetailsUpdated)
    ? repaymentDetailsUpdated
    : repaymentDetailsOriginal;

  const collaterals = map(c => {
    const collateral = onlyTruthyValues(c);
    return new Collateral({ ...collateral, isPersisted: false });
  }, filterDeletedSecurities(persistedCollaterals));

  // Mambu does not want 0 values so we filter
  // here for the non-zero data prior to sending
  const restructureDetails = onlyTruthyValues({
    principal_write_off: Number(writeOffAmount)
  });

  const rescheduleData = {
    firstRepaymentDate: formatIsoDate(firstRepaymentDate),
    productId,
    guarantors,
    collaterals,
    loanDuration,
    loanInstallments,
    repaymentPeriod,
    repaymentPeriodUnit,
    amount,
    repaymentDetails,
    disbursementMode,
    disbursementBankDetails,
    restructureDetails
  };

  const RescheduledLoan = Loan.reschedule(loan.id);
  const rescheduledLoan = new RescheduledLoan(rescheduleData);

  const didSave = await rescheduledLoan.save({ with: ['collaterals'] });

  if (!didSave) {
    throw rescheduledLoan.errors;
  }
};

export const LoanReschedule = (props: Props) => {
  const { baseUrl, match, history } = props;

  const response = useLoan(props.match.params.loanId, includes);

  return (
    <Loadable {...response}>
      {loan =>
        loan.canReschedule() ? (
          <Wizard
            analyticsId="LoanReschedule"
            baseUrl={baseUrl}
            history={history}
            initialData={{
              loan,
              loanId: loan.id,
              member: loan.member,
              guarantors: loan.guarantors,
              collaterals: loan.collaterals,
              firstName: loan.member.firstName,
              amount: loan.principal.balance,
              loanDuration: loan.loanDuration,
              product: loan.product,
              productId: loan.product.id,
              repaymentPeriod: loan.repaymentPeriod,
              repaymentPeriodUnit: loan.repaymentPeriodUnit,
              fullName: loan.member.fullName(),
              disbursementMode: loan.disbursementMode,
              repaymentDetails: {
                repayment_mode: loan.repaymentDetails.repayment_mode
              }
            }}
            cancelReturnsTo={loanPath({ id: loan.id })}
            currentStep={match.params.step}
            currentSubStep={
              match.params.subStep != null
                ? parseInt(match.params.subStep, 10)
                : null
            }
            onSubmit={rescheduleLoan}
            steps={steps(loan)}
            startId="review"
            titleId="LoanReschedule.shortTitle"
            type="approval"
          />
        ) : (
          <Redirect to={loanPath({ id: loan.id })} />
        )
      }
    </Loadable>
  );
};
