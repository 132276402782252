import * as React from 'react';

import styles from './index.module.scss';

export { Header } from './components/Header';

export const SideNavContainer = ({ sideMenu, activeModule }) => {
  return (
    <div className={styles.Container}>
      {sideMenu}
      <div className={styles.ModuleContainer}>{activeModule}</div>
    </div>
  );
};
