import * as React from 'react';
import { withRouter } from 'react-router-dom';

import type { TillTransactionType } from '@kwara/models/src';

import OverviewList from '../../../../components/OverviewList';
import { ButtonMenu } from '../../../../components/ButtonMenu';

import { TellerListTable } from '../TellerListTable';

const TellerMenu = ({ onOpenTellerModal, onOpenVaultModal }) => {
  return (
    <ButtonMenu title={'Actions'}>
      <ButtonMenu.Item onClick={onOpenTellerModal}>
        <div>
          Set reorder level
          <br />
          (till minimum)
        </div>
      </ButtonMenu.Item>
      <ButtonMenu.Item onClick={onOpenVaultModal}>
        <div>Set vault minimum</div>
      </ButtonMenu.Item>
    </ButtonMenu>
  );
};

type Props = {
  hasMore?: boolean,
  loading?: boolean,
  tellers: TillTransactionType[], //TO DO Update Prop for Teller
  errors: Object[],
  onLoadMoreData: () => void,
  totalNumResults: ?number
};

export default withRouter(
  ({
    hasMore = false,
    loading = false,
    tellers = [],
    errors,
    onLoadMoreData,
    totalNumResults,
    onOpenTellerModal,
    onOpenVaultModal
  }: Props) => (
    <OverviewList
      actions={[
        <TellerMenu
          key="teller-menu"
          onOpenTellerModal={onOpenTellerModal}
          onOpenVaultModal={onOpenVaultModal}
        />
      ]}
      headerId="TellerList.heading"
      labelId={
        totalNumResults
          ? 'TellerList.filteredCount'
          : 'TellerList.filteredCountWithoutTotal'
      }
      items={tellers}
      table={
        <TellerListTable
          hasMore={hasMore}
          tellers={tellers}
          onLoadMoreData={onLoadMoreData}
          loading={loading}
          errors={errors}
        />
      }
      totalNumResults={totalNumResults}
    />
  )
);
