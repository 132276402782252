// @flow

import compact from 'lodash/compact';
import mapValues from 'lodash/mapValues';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';

import Action from './Action';
import type {
  DirectStep,
  SequentialStep,
  SequentialSubStep,
  RenderableStepConfig,
  StepConfig,
  StepId,
  StepPath
} from '.';

const FirstSubStepSymbol = Symbol('FirstSubStep');

const nextSubStep = (
  index: number,
  steps: SequentialSubStep[]
): { index: number, subStep: SequentialSubStep } =>
  index + 1 < steps.length
    ? { index: index + 1, subStep: steps[index + 1] }
    : null;

const pathForSequentialSubStep = (id: StepId, index: number): StepPath =>
  `${id}/${index + 1}`;

// Find a step by ID and work out if it's sequential or direct
const stepType = (graph: StepConfig, id: StepId): 'sequential' | 'direct' => {
  if (graph[id] == null) {
    throw new Error(`Step "${id}" not found`);
  }

  return graph[id].children ? 'sequential' : 'direct';
};

// TODO: Rename
//      This function takes an Action and works out the
//      correct destintation paths
const directAction = (
  graph: StepConfig,
  action: Action,
  currentPath: StepPath,
  meta
): Action => {
  if (!(action instanceof Action)) {
    throw new Error(`action must be an instance of Action`);
  }

  if (Action.isFinalStep(action)) {
    return action;
  }

  const nextAction = graph[action.destination];

  if (stepType(graph, action.destination) === 'sequential') {
    const nextPath = pathForSequentialSubStep(action.destination, 0);

    meta[nextPath] = { prevPath: currentPath };
    action.destinationPath = nextPath;

    if (typeof nextAction.isPermitted === 'boolean') {
      action.isPermitted = nextAction.isPermitted;
    }

    return action;
  } else {
    const nextPath = action.destination;

    meta[nextPath] = { prevPath: currentPath };
    action.destinationPath = nextPath;

    if (typeof nextAction.isPermitted === 'boolean') {
      action.isPermitted = nextAction.isPermitted;
    }

    return action;
  }
};

type StepReducer = { graph: RenderableStepConfig, meta: { [string]: mixed } };

const stepReducer = (
  { graph, meta, ordered }: StepReducer,
  step: Step,
  id: StepId,
  original: StepConfig
): StepReducer => {
  const ordering = [];
  if (step.children) {
    step.children.forEach((subStep, index, steps) => {
      const next = nextSubStep(index, steps);
      const path = pathForSequentialSubStep(id, index);
      const actions = [];

      // Process any actions within the child
      // if they have a destination
      if (Array.isArray(subStep.actions)) {
        subStep.actions.forEach(action => {
          if (typeof action.destination === 'string') {
            actions.push(
              directAction(original, new Action(action), path, meta)
            );
          }
        });
      }

      const isHiddenNext =
        subStep.hideActions && typeof subStep.hideActions.next === 'boolean'
          ? subStep.hideActions.next
          : false;

      // Handle case where next substep is within seqential step
      if (next != null) {
        const nextPath = pathForSequentialSubStep(id, next.index);

        actions.push(
          new Action({
            appearsAs: 'next',
            behavesAs: 'next',
            isPermitted: true,
            isHidden: isHiddenNext,
            destination: nextPath,
            destinationPath: nextPath
          })
        );

        meta[nextPath] = { prevPath: path };

        // Handle case where existing sequential substep
      } else {
        step.actions.forEach(action => {
          if (action.behavesAs === 'next' && isHiddenNext != null) {
            action.isHidden = isHiddenNext;
          }

          const direct = directAction(original, action, path, meta);
          actions.push(direct);

          if (!Action.isFinalStep(action)) {
            meta[direct.destinationPath] = { prevPath: path };
          }
        });
      }

      if (!meta[FirstSubStepSymbol]) {
        meta[FirstSubStepSymbol] = path;
      }

      ordering.push(path);

      if (meta[path] != null && meta[path].prevPath) {
        actions.push(
          new Action({
            appearsAs: 'back',
            behavesAs: 'back',
            isPermitted: true,
            isHidden: false,
            destination: meta[path].prevPath,
            destinationPath: meta[path].prevPath
          })
        );
      }

      const parent = { ...step, id };
      delete parent.children;

      graph[path] = {
        ...subStep,
        isPermitted: step.isPermitted,
        actions,
        parent
      };
    });
  } else {
    const path = id;

    const actions = step.actions.map(action =>
      directAction(original, action, path, meta)
    );

    if (!meta[FirstSubStepSymbol]) {
      meta[FirstSubStepSymbol] = path;
    }

    // We explicitly ignore direct steps
    // ordering.push(path);

    if (meta[path] != null && meta[path].prevPath) {
      actions.push(
        new Action({
          appearsAs: 'back',
          behavesAs: 'back',
          isPermitted: true,
          isHidden: false,
          destination: meta[path].prevPath,
          destinationPath: meta[path].prevPath
        })
      );
    }

    graph[path] = { ...step, actions };
  }

  if (ordering.length > 0) {
    ordered.push(ordering);
  }

  return { graph, meta, ordered };
};

export const stepsToVisitInOrder = (
  graph: StepConfig,
  stepId: StepId,
  ids: StepId[] = []
): StepId[] => {
  const step = graph[stepId];

  if (step == null) {
    return [];
  } else if (step.actions.length === 0) {
    return [stepId];
  } else {
    return uniq([
      stepId,
      ...flatMap(step.actions, action =>
        stepsToVisitInOrder(graph, action.destination, ids)
      )
    ]);
  }
};

const parseActions = (step: DirectStep | SequentialStep, id: StepId) => {
  if (step && step.actions && Array.isArray(step.actions)) {
    return {
      ...step,
      actions: step.actions.map(obj => new Action(obj))
    };
  } else {
    const error = new Error(`step.actions must be an Array as step ID: ${id}`);
    error.step = step;
    throw error;
  }
};

export const prepare = (steps: StepConfig): StepConfig =>
  mapValues(steps, parseActions);

export default ({
  steps,
  startId
}: {
  steps: StepConfig,
  startId: StepId
}): RenderableStepConfig => {
  // We expand the raw actions array into Action instances
  const stepsWithParsedActions = prepare(steps);

  const orderedStepIds = compact(
    stepsToVisitInOrder(stepsWithParsedActions, startId)
  );

  const { graph, ordered, meta } = orderedStepIds.reduce(
    (state, id) =>
      stepReducer(
        state,
        stepsWithParsedActions[id],
        id,
        stepsWithParsedActions
      ),
    { graph: {}, meta: {}, ordered: [] }
  );

  return {
    subSteps: graph,
    ordered,
    firstSubStepId: meta[FirstSubStepSymbol]
  };
};
