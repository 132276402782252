import * as React from 'react';
import map from 'lodash/fp/map';
import forEach from 'lodash/fp/forEach';
import sum from 'lodash/fp/sum';

import { Form } from 'react-final-form';

import { SubscribedTextField } from '@kwara/components/src/Form';

const denominations = [1000, 500, 100, 50, 20, 10, 5, 1];

function getInitialBreakdown(amount) {
  const values = {};
  let total = amount;

  forEach(d => {
    const qty = Math.floor(total / d);
    values[`qty-${d}`] = qty;
    total -= d * qty;
  }, denominations);

  return values;
}

function getRowTotals(obj) {
  const values = {};

  forEach(d => {
    values[`total-${d}`] = d * obj[`qty-${d}`];
  }, denominations);

  return values;
}

function getTotal(totals) {
  return sum(Object.values(totals));
}

export const Denominations = ({ amount }) => {
  return (
    <Form
      onSubmit={() => {}}
      initialValues={getInitialBreakdown(Number(amount))}
      render={({ values }) => {
        const totals = getRowTotals(values);
        const total = getTotal(totals);

        return (
          <section>
            <div className="w-100 flex">
              <div className="w-33 pa2 flex justify-center items-center">
                Denomination
              </div>
              <div className="w-33 pa2 flex justify-center items-center">
                Qty
              </div>
              <div className="w-33 pa2 flex justify-center items-center">
                Total
              </div>
            </div>
            {map(d => {
              return (
                <div key={d} className="w-100 flex">
                  <div className="w-33 pa2 flex justify-center items-center">
                    {d}
                  </div>
                  <div className="w-33 pa2 flex justify-center items-center">
                    <SubscribedTextField
                      compact
                      margin={false}
                      name={`qty-${d}`}
                    />
                  </div>
                  <div className="w-33 pa2 flex justify-center items-center">
                    <SubscribedTextField
                      value={totals[`total-${d}`]}
                      compact
                      margin={false}
                      name={`total-${d}`}
                    />
                  </div>
                </div>
              );
            }, denominations)}
            <div className="w-100 flex">
              <div className="w-33 pa2 flex justify-center items-center">
                Total
              </div>
              <div className="w-33 pa2 flex justify-center items-center"></div>
              <div className="w-33 pa2 flex justify-center items-center">
                <SubscribedTextField
                  value={total}
                  name="total"
                  compact
                  margin={false}
                />
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};
