// @flow

import React from 'react';
import { Form } from 'react-final-form';
import get from 'lodash/fp/get';
import reject from 'lodash/fp/reject';
import _includes from 'lodash/fp/includes';

import Banner from '@kwara/components/src/Banner';
import Button from '@kwara/components/src/Button';
import { SubscribedCheckbox } from '@kwara/components/src/Form';

import { store } from '../../../../../models/Store';
import { Detail, DetailWrapper, type UserT } from '..';

const DeactiveUserForm = ({ user }) => {
  const [showSuccess, setShowSuccess] = React.useState(false);
  const onSubmit = data => {
    const currentBlockList = [...store.blockList];
    const shouldDeactivate = get('disableUser', data);

    const newBlockList = shouldDeactivate
      ? [user.id, ...currentBlockList]
      : reject(id => id === user.id, currentBlockList);

    store.setBlocklist(newBlockList);
    setShowSuccess(true);
  };

  return (
    <>
      {showSuccess ? (
        <Banner
          className="mb3"
          text={'Change submitted successfully'}
          state="success"
        />
      ) : null}
      <Form
        initialValues={{ disableUser: _includes(user.id, store.blockList) }}
        onSubmit={onSubmit}
        render={props => {
          return (
            <form>
              <SubscribedCheckbox name="disableUser" labelId="Disable user?" />
              <Button onClick={props.form.submit}>Submit</Button>
            </form>
          );
        }}
      />
    </>
  );
};

export const Deactivate = ({ user }: { user: UserT }) => {
  return (
    <DetailWrapper>
      <Detail
        titleId="Deactivate user"
        value={<DeactiveUserForm user={user} />}
      />
    </DetailWrapper>
  );
};
