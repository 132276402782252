//@flow
import * as React from 'react';
import { Field as FinalFormField } from 'react-final-form';
import cx from 'classnames';

import { Field, RequiredAsterisk, type InputProps, type FieldProps } from '.';

import { Text, type TranslationId } from '@kwara/components/src/Intl';

import styles from './Checkbox.module.scss';

type Props = {
  compact?: boolean,
  checked?: boolean,
  disabled?: boolean,
  hidden?: boolean,
  labelId?: TranslationId,
  labelText?: string,
  name: string,
  onChange?: (evt: { target: { value: string } }) => void,
  size?: 'regular' | 'medium',
  required?: boolean,
  value?: string
};

export const Checkbox = ({
  disabled,
  name,
  size,
  hidden,
  labelId,
  labelText,
  value,
  onChange,
  required,
  compact,
  ...rest
}: Props) => {
  const id = `${name}-${value || ''}`;
  const label = (
    <label
      htmlFor={id}
      className={cx(
        `pb2 dib black flex kw-text-${size === 'regular' ? 'small' : 'regular'}`
      )}
    >
      {labelText}
      {labelId && <Text id={labelId} />} <RequiredAsterisk hidden={!required} />
    </label>
  );

  const input = (
    <input
      disabled={disabled}
      type="checkbox"
      id={id}
      name={name}
      value={value}
      hidden={hidden}
      onChange={onChange}
      {...rest}
    />
  );

  return (
    <div
      hidden={hidden}
      className={cx(
        styles.checkbox,
        compact ? styles.compact : styles.default,
        styles[size]
      )}
    >
      {input}
      {label}
    </div>
  );
};

type SubscribedCheckboxProps = InputProps & FieldProps;

export const SubscribedCheckbox = ({
  disabled,
  errorBaseId,
  hidden,
  info,
  infoId,
  labelId,
  name,
  required,
  titleId,
  values,
  margin,
  value,
  showInfo
}: SubscribedCheckboxProps) => (
  <FinalFormField
    name={name}
    type="checkbox"
    value={value}
    labelId={labelId}
    render={({ input, meta }) => (
      <Field
        error={meta.error && meta.touched}
        name={input.name}
        size="medium"
        titleId={titleId}
        infoId={infoId}
        errorBaseId={errorBaseId}
        errorCodes={meta.error}
        values={values}
        info={info}
        required={required}
        margin={margin}
        showInfo={showInfo}
      >
        <Checkbox
          disabled={disabled}
          required={required}
          {...input}
          size="medium"
          labelId={labelId}
          hidden={hidden}
        />
      </Field>
    )}
  />
);
