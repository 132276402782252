// @flow

import * as React from 'react';
import queryString from 'query-string';

import Button from '@kwara/components/src/Button';
import Banner from '@kwara/components/src/Banner';
import { Text } from '@kwara/components/src/Intl';

import style from '../../index.module.css';

type Props = {
  auth: Object,
  location: {
    search: string
  }
};

type State = {
  loading: boolean,
  success: boolean,
  error: boolean
};

export class ConfirmEmail extends React.Component<Props, State> {
  state = {
    loading: true,
    success: false,
    error: false
  };

  componentDidMount() {
    const { location, auth } = this.props;
    const { token } = queryString.parse(location.search);

    auth
      .confirmEmail(token)
      .then(_ => {
        this.setState({ success: true, loading: false });
      })
      .catch(_ => {
        this.setState({ error: true, loading: false });
      });
  }

  render() {
    const { loading, error, success } = this.state;
    return (
      <div className={style.Content}>
        {loading && (
          <h2>
            <Text id="SignUp.Confirm.loading" />
          </h2>
        )}

        {success && (
          <>
            <h1>
              <Text id="SignUp.Confirm.success.title" />
            </h1>
            <p>
              <Text id="SignUp.Confirm.success.message" />
            </p>

            <Button to="/" primary>
              <Text id="SignUp.Confirm.success.button" />
            </Button>
          </>
        )}

        {error && <Banner state="error" text="Something went wrong" />}
      </div>
    );
  }
}
