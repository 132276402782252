// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

type GlType = 'ASSET' | 'LIABILITY' | 'EQUITY' | 'INCOME' | 'EXPENSE';
type UsageT = 'HEADER' | 'DETAIL';

export const GeneralLedgerAccount = Base.extend({
  static: {
    jsonapiType: 'gl_accounts',
    endpoint: '/general_ledger_accounts'
  },
  attrs: {
    name: attr(),
    description: attr(),
    type: attr(),
    usage: attr(),
    allowManualJournalEntries: attr()
  },
  methods: {}
});

export const GeneralLedgerAccountApi = GeneralLedgerAccount.extend({
  static: {
    jsonapiType: 'general_ledger_accounts'
  },
  attrs: {
    balance: attr()
  }
});

export interface GeneralLedgerAccountT
  extends BaseModel<GeneralLedgerAccountT> {
  name: string;
  description: string;
  type: GlType;
  usage: UsageT;
  allowManualJournalEntries: boolean;
}
