import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 15,
    borderBottom: 1,
    borderBottomColor: 'lightgrey'
  }
});

export const Section = ({ children, style, fixed = false }) => (
  <View fixed={fixed} style={{ ...styles.section, ...style }}>
    {children}
  </View>
);
