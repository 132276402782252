//  @flow

import * as React from 'react';
import noop from 'lodash/fp/noop';
import { Field as FinalFormField } from 'react-final-form';

import { type LoanType, type SavingType } from '@kwara/models/src';

import LoanAccountCard from '../../../MemberDetail/components/LoanAccountCard';

export const SubscribedCard = ({
  name,
  account,
  onSelect = noop,
  isSelectable = false,
  card: Card
}: {
  name: string,
  account: LoanType | SavingType,
  onSelect?: (account: ?LoanType | ?SavingType) => void,
  isSelectable?: boolean,
  card?: typeof LoanAccountCard
}) => {
  return (
    <FinalFormField
      name={name}
      type="checkbox"
      value={account.id}
      render={({ input }) => {
        return (
          <>
            <label htmlFor={account.id}>
              <Card
                account={account}
                isSelectable={isSelectable}
                isSelected={!!input.checked}
              />
            </label>
            <input
              hidden
              {...input}
              onChange={e => {
                onSelect(account);
                input.onChange(e);
              }}
              type="checkbox"
              id={account.id}
              name={name}
            />
          </>
        );
      }}
    />
  );
};

export const SubscribedLoanAccountCard = ({
  account
}: {
  account: LoanType
}) => {
  return (
    <SubscribedCard
      name="payOffLoans"
      account={account}
      card={LoanAccountCard}
    />
  );
};
