import * as React from 'react';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import filter from 'lodash/fp/filter';
import merge from 'lodash/merge';
import set from 'lodash/set';
import toString from 'lodash/toString';
import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import map from 'lodash/fp/map';

import { Loadable } from '@kwara/components/src/Loadable';
import { add, equals } from '@kwara/lib/src/currency';
import { mapIndexed } from '@kwara/lib/src/lodash';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import Table, { Row, Cell, Heading } from '../../../../components/Table';
import { useRoles } from '../../../../models/request';

const CURRENCY_INCREMENT = 0.01;

function commitChanges(tiers = [], index: number, formTiers) {
  const idx = toString(index);
  const original = getOr({}, idx, tiers);
  const changed = get(index, formTiers);
  /* eslint-disable-next-line prototype-pollution-security-rules/detect-merge-objects */
  const updated = merge(original, changed);
  set(tiers, idx, updated);
  return tiers;
}

function toggleItem(shouldDestroy, tiers, idx: number) {
  const clone = getOr({}, idx, tiers);
  // Reactivate next line when we are dealing with spraypaint models
  // const clone = get(idx, tiers).dup();
  clone.isMarkedForDestruction = shouldDestroy;
  // replace the original instance with a clone that is marked for destruction
  return {
    tiers: mapIndexed((c, i) => {
      if (i === idx) {
        return clone;
      }
      return c;
    }, tiers)
  };
}

export function indexNext(indexCurrent, tiers) {
  if (indexCurrent + 1 >= tiers.length) {
    return -1;
  }
  return findIndex(
    tiers,
    tier => !tier.isMarkedForDestruction,
    indexCurrent + 1
  );
}

export function indexPrevious(indexCurrent, tiers) {
  if (indexCurrent - 1 < 0) {
    return -1;
  }
  return findLastIndex(
    tiers,
    tier => !tier.isMarkedForDestruction,
    indexCurrent - 1
  );
}

function editTierAtIndex(tiers, index, changed) {
  const idx = toString(index);
  const original = getOr({}, idx, tiers);
  /* eslint-disable-next-line prototype-pollution-security-rules/detect-merge-objects */
  const updated = merge(original, changed);
  set(tiers, idx, updated);
  return tiers;
}

function addTier(tiers) {
  const i = tiers.length;
  const indexPrev = indexPrevious(i, tiers);
  const prevMax = tiers[indexPrev].max;
  const newTier = {
    min: add(prevMax, CURRENCY_INCREMENT),
    isMarkedForDestruction: false
  };
  return editTierAtIndex(tiers, i, newTier);
}

function deleteTier(tiers, index) {
  return toggleItem(true, tiers, index);
}

export const LoanApprovalForm = ({
  StackChild,
  TextField,
  SelectField,
  data,
  formProps,
  onChange
}) => {
  const r = useRoles();

  const { tiers } = data;
  const { invalid } = formProps;
  const formTiers = get('values.tiers', formProps);

  const hasErrorAt = index => get(`errors.[${index}]`, formProps);

  const saveCurrentValues = (tiers, i) => {
    const res = commitChanges(tiers, i, formTiers);
    onChange(res);
  };

  const removeTier = (tiers, i) => {
    const res = deleteTier(tiers, i);
    onChange(res);
  };

  const appendTier = tiers => {
    const updated = addTier(tiers);
    onChange(updated);
  };

  const updateNextTier = (i, tiers) => {
    const nextActive = indexNext(i, tiers);
    if (nextActive > -1) {
      const currentMax = tiers[i].max;
      const updated = editTierAtIndex(tiers, nextActive, {
        min: add(currentMax, CURRENCY_INCREMENT)
      });
      onChange({ tiers: updated });
    }
  };

  const updateAfterRemoval = (i, tiers) => {
    const prevActive = indexPrevious(i, tiers);
    const nextActive = indexNext(i, tiers);
    if (nextActive > -1) {
      const previousMax = tiers[prevActive].max;
      const updated = editTierAtIndex(tiers, nextActive, {
        min: add(previousMax, CURRENCY_INCREMENT)
      });
      onChange({ tiers: updated });
    }
  };

  const onAdd = tiers => {
    const index = tiers.length - 1;
    saveCurrentValues(tiers, index);
    appendTier(tiers);
  };

  const onBlur = (tiers, i) => {
    saveCurrentValues(tiers, i);
    updateNextTier(i, tiers);
  };

  const onRemove = (tiers, i) => {
    removeTier(tiers, i);
    updateAfterRemoval(i, tiers);
  };

  return (
    <StackChild size="wide">
      <Table
        heading={
          <Row>
            <Heading
              translationId="Settings.LoanApprove.TiersTable.Heading.minimum"
              width="200px"
            />
            <Heading
              translationId="Settings.LoanApprove.TiersTable.Heading.maximum"
              width="200px"
            />
            <Heading width="50px"></Heading>
            <Heading translationId="Settings.LoanApprove.TiersTable.Heading.roles" />
            <Heading width="100px" />
          </Row>
        }
      >
        <Row>
          <Cell className="v-btm" align="center" colSpan={2}>
            <span className="kw-text-small kw-weight-light grey-400">
              <Text id="Settings.LoanApprove.TiersTable.Helper.range" />
            </span>
          </Cell>
          <Cell />
          <Cell className="v-btm" colSpan={2}>
            <span className="kw-text-small kw-weight-light grey-400">
              <Text id="Settings.LoanApprove.TiersTable.Helper.roles" />
            </span>
          </Cell>
        </Row>

        {mapIndexed((tier, i) => {
          if (tier.isMarkedForDestruction) {
            return null;
          }

          return (
            <Row key={i}>
              <Cell>
                <TextField
                  name={`tiers[${i}].min`}
                  placeholderId="Settings.LoanApprove.Form.min.placeholderId"
                  leftGlyph="Currency.orgCurrency"
                  initialValue={tier.min}
                  disabled
                />
              </Cell>
              <Cell>
                <TextField
                  name={`tiers[${i}].max`}
                  placeholderId="Settings.LoanApprove.Form.max.placeholderId"
                  leftGlyph="Currency.orgCurrency"
                  inputOnBlur={() => {
                    if (!hasErrorAt(i)) {
                      onBlur(data.tiers, i);
                    }
                  }}
                />
              </Cell>
              <Cell />
              <Cell>
                <Loadable {...r}>
                  {roles => (
                    <SelectField
                      name={`tiers[${i}].roles[0]`}
                      inputOnBlur={() => {
                        onBlur(data.tiers, i);
                      }}
                    >
                      <SelectField.Option
                        value=""
                        translationId="Settings.LoanApprove.TiersTable.Role.default"
                      />
                      {map(
                        role => (
                          <SelectField.Option key={role.id} value={role.id}>
                            {role.name}
                          </SelectField.Option>
                        ),
                        filter(role => role.hasLoanApprovePermission(), roles)
                      )}
                    </SelectField>
                  )}
                </Loadable>
              </Cell>
              <Cell>
                {i !== 0 ? (
                  <Button
                    data-testid="delete"
                    type="destructive"
                    size="small"
                    onClick={() => onRemove(data.tiers, i)}
                  >
                    -
                  </Button>
                ) : null}
              </Cell>
            </Row>
          );
        }, tiers)}
      </Table>
      <Button
        disabled={invalid}
        className="mb4"
        size="small"
        type="primary"
        onClick={() => onAdd(data.tiers)}
      >
        <Text id="Settings.LoanApprove.Form.button.addTier" />
      </Button>
    </StackChild>
  );
};

const tierKey = (index, field) => `tiers[${index}].${field}`;

LoanApprovalForm.validate = ({ tiers }) => {
  if (tiers != null) {
    const validations = {};

    tiers.forEach((tier, index) => {
      if (!tier.isMarkedForDestruction) {
        validations[tierKey(index, 'max')] = {
          isRequired: () => true,
          min: add(tier.min, CURRENCY_INCREMENT),
          currency: true
        };

        validations[tierKey(index, 'min')] = {
          isRequired: () => true,
          currency: true,
          custom: target => {
            if (index === 0) {
              return equals(target, 0) ? null : 'invalidMin';
            }
            const prevIndex = indexPrevious(index, tiers);
            if (
              !equals(target, add(tiers[prevIndex].max, CURRENCY_INCREMENT))
            ) {
              return 'invalidGapInTiers';
            }

            return null;
          }
        };

        validations[tierKey(index, 'roles[0]')] = {
          isRequired: () => true
        };
      }
    });

    return validations;
  }

  return {};
};
