// @flow

import React from 'react';
import map from 'lodash/fp/map';
import negate from 'lodash/fp/negate';
import every from 'lodash/fp/every';

import { isEmpty } from '@kwara/lib/src/lodash';
import {
  ChargeFrequencies,
  InterestApplicationMethods,
  InterestCalculationMethods,
  PeriodUnits
} from '@kwara/models/src/models/LoanProduct';
import { Text } from '@kwara/components/src/Intl';
import { Field } from '@kwara/components/src/Form';

import { Panel as PanelWithoutHeader } from '../../../components/ActionModal';
import { Grid } from '../../../components/Grid';

const Panel = ({ children, headerId }) => {
  return (
    <PanelWithoutHeader>
      <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
        <Text id={headerId} />
      </h2>
      {children}
    </PanelWithoutHeader>
  );
};

// WIP
export const ProductForm = ({
  StackChild,
  TextField,
  SelectField,
  Checkbox,
  translationBaseId = 'LoanProductAdd'
}) => {
  return (
    <StackChild>
      <Panel headerId={`${translationBaseId}.Product.id.fullText`}>
        <TextField
          required
          name="id"
          labelId={`${translationBaseId}.Product.id.labelId`}
        />
      </Panel>

      <Panel headerId={`${translationBaseId}.Product.name.fullText`}>
        <TextField
          required
          name="name"
          labelId={`${translationBaseId}.Product.name.labelId`}
        />
      </Panel>

      <Panel headerId={`${translationBaseId}.Product.minMax.fullText`}>
        <Grid columns={2} width="w-50" border={false}>
          <TextField
            name="amount.minimum"
            labelId={`${translationBaseId}.Product.min.labelId`}
            infoId={`${translationBaseId}.Product.min.infoId`}
            errorBaseId={`${translationBaseId}.Product`}
            rightGlyph="Currency.orgCurrency"
          />
          <TextField
            name="amount.maximum"
            labelId={`${translationBaseId}.Product.max.labelId`}
            errorBaseId={`${translationBaseId}.Product`}
            rightGlyph="Currency.orgCurrency"
          />
        </Grid>
      </Panel>

      {/* <Panel headerId={`${translationBaseId}.Product.type.fullText`}>
        <SelectField
          required
          name="type"
          labelId={`${translationBaseId}.Product.type.labelId`}
        >
          {map(productType => {
            return (
              <SelectField.Option
                key={productType}
                value={productType}
                translationId={`${translationBaseId}.Product.type.${productType}`}
              />
            );
          }, ProductTypes)}
        </SelectField>
      </Panel> */}

      <Panel
        headerId={`${translationBaseId}.Product.interestCalculation.fullText`}
      >
        <SelectField
          required
          name="interestRate.calculation_method"
          labelId={`${translationBaseId}.Product.interestCalculation.labelId`}
        >
          {map(m => {
            return (
              <SelectField.Option
                key={m}
                value={m}
                translationId={`${translationBaseId}.Product.interestCalculation.${m}`}
              />
            );
          }, InterestCalculationMethods)}
        </SelectField>
      </Panel>

      <Panel headerId={`${translationBaseId}.Product.interest.fullText`}>
        <Field labelId={`${translationBaseId}.Product.interestRate.labelId`}>
          <Grid columns={2} width="w-50" border={false}>
            <TextField
              name="interestRate.default_interest_rate"
              rightGlyph="%"
            />
            <SelectField name="interestRate.interest_charge_frequency">
              <SelectField.Option
                key="NONE"
                translationId={`${translationBaseId}.Product.interestChargeFrequency.NONE`}
                value=""
              />
              {map(
                o => (
                  <SelectField.Option
                    key={o}
                    translationId={`${translationBaseId}.Product.interestChargeFrequency.${o}`}
                    value={o}
                  />
                ),
                ChargeFrequencies
              )}
            </SelectField>
          </Grid>
        </Field>
      </Panel>

      <Panel headerId={`${translationBaseId}.Product.applyInterest.fullText`}>
        <SelectField
          required
          name="interestRate.application_method"
          labelId={`${translationBaseId}.Product.applyInterest.labelId`}
        >
          {map(m => {
            return (
              <SelectField.Option
                key={m}
                value={m}
                translationId={`${translationBaseId}.Product.applyInterest.${m}`}
              />
            );
          }, InterestApplicationMethods)}
        </SelectField>
      </Panel>

      <Panel headerId={`${translationBaseId}.Product.maxInstallments.fullText`}>
        <TextField
          name="maximumRepaymentInstallments"
          labelId={`${translationBaseId}.Product.maxInstallments.labelId`}
          infoId={`${translationBaseId}.Product.maxInstallments.infoId`}
        />
      </Panel>

      <Panel
        headerId={`${translationBaseId}.Product.repaymentDetails.fullText`}
      >
        <Field
          labelId={`${translationBaseId}.Product.repaymentDetails.labelId`}
          infoId={`${translationBaseId}.Product.repaymentDetails.infoId`}
        >
          <Grid columns={2} width="w-50" border={false}>
            <TextField name="defaultRepaymentPeriod" />
            <SelectField name="repaymentPeriodUnit">
              <SelectField.Option key="NONE" value="" />
              {map(
                o => (
                  <SelectField.Option
                    key={o}
                    translationId={`${translationBaseId}.Product.repaymentPeriodUnit.${o}`}
                    value={o}
                  />
                ),
                PeriodUnits
              )}
            </SelectField>
          </Grid>
        </Field>
      </Panel>

      <Panel headerId="LoanProductAdd.Product.securities.fullText">
        <Checkbox
          name="security.guarantors_enabled"
          labelId={`${translationBaseId}.Product.guarantorsEnabled.labelId`}
        />
        <Checkbox
          name="security.collaterals_enabled"
          labelId={`${translationBaseId}.Product.collateralsEnabled.labelId`}
        />
      </Panel>

      {/* This is not yet supported
      <Panel headerId={`${translationBaseId}.Product.restrictions.fullText`}>
        <Checkbox
          name="multipleAccountsAllowed"
          labelId={`${translationBaseId}.Product.multipleAccountsAllowed.labelId`}
        />
      </Panel> */}

      <Panel headerId={`${translationBaseId}.Product.fees.fullText`}>
        <Checkbox
          name="feesSettings.allow_arbitrary_fees"
          labelId={`${translationBaseId}.Product.allowFees.labelId`}
        />
      </Panel>
    </StackChild>
  );
};

export function allOrNone(...values) {
  const isFilled = negate(isEmpty);
  return every(isEmpty, values) || every(isFilled, values);
}

ProductForm.validate = {
  id: {
    isRequired: () => true
  },
  name: {
    isRequired: () => true
  },
  'interestRate.default_interest_rate': {
    isRequired: () => true,
    nonZero: true,
    max: 100
  },
  'interestRate.interest_charge_frequency': {
    isRequired: () => true
  },
  'interestRate.calculation_method': {
    isRequired: () => true
  },
  'interestRate.application_method': {
    isRequired: () => true
  },
  'amount.minimum': {
    currency: true
  },
  'amount.maximum': {
    currency: true,
    custom: (target, allData) => {
      const { amount } = allData;
      const { minimum } = amount;
      if (!isEmpty(minimum) && Number(minimum) > Number(target)) {
        return 'maxExceedsMin';
      }
      return null;
    }
  },
  defaultRepaymentPeriod: {
    positiveInteger: true,
    isRequired: (defaultRepaymentPeriod, allData) => {
      const { repaymentPeriodUnit } = allData;
      return !allOrNone(repaymentPeriodUnit, defaultRepaymentPeriod);
    }
  },
  repaymentPeriodUnit: {
    isRequired: (repaymentPeriodUnit, allData) => {
      const { defaultRepaymentPeriod } = allData;
      return !allOrNone(defaultRepaymentPeriod, repaymentPeriodUnit);
    }
  },
  maximumRepaymentInstallments: {
    positiveInteger: true
  }
};
