// @flow
import * as React from 'react';

import pipe from 'lodash/fp/pipe';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import sum from 'lodash/fp/sum';
import toNumber from 'lodash/fp/toNumber';

import { Loan } from '@kwara/models/src';
import { Duration, Percent, Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { type ScheduleType } from '@kwara/models/src/models/Schedule';

import { memberPath } from '../../../lib/urls';

import { PrincipalAmountField } from '../../LoanAdd/components/Configure/PrincipalAmountField';
import { LoanTermsFields } from '../../LoanAdd/components/Configure/LoanTermsFields';
import { EditableSection } from '../../../components/EditableSection';
import { DetailOverviewSection } from '../../../components/Detail/DetailOverviewSection';
import { OverviewMetadata } from './OverviewMetadata';
import { Currency } from '../../../components/Currency';

export type Props = {
  actions: React.Node,
  loan: typeof Loan,
  navigation: React.Node,
  showBack: boolean,
  linkToMember: boolean,
  schedule: ?ScheduleType,
  readOnly?: boolean
};

// see ch3744 and https://github.com/getkwara/webapp/pull/280
function getInterestAmount(schedule) {
  if (schedule == null) {
    return 0;
  }

  const getInterestsDueAsNumber = map(r => toNumber(get('interest.due', r)));

  return pipe(
    get('repayments'),
    getInterestsDueAsNumber,
    sum
  )(schedule);
}

const config = {
  Component: props => (
    <div>
      <PrincipalAmountField {...props} />
      <LoanTermsFields {...props} />
    </div>
  ),
  validate: { ...PrincipalAmountField.validate, ...LoanTermsFields.validate }
};

export const Overview = (props: Props) => {
  const { loan, navigation, linkToMember, schedule, readOnly } = props;

  return (
    <DetailOverviewSection
      metadata={<OverviewMetadata {...props} />}
      secondary={navigation}
      title={<Text id="LoanDetail.title" values={{ id: loan.id }} />}
    >
      <section className="mb5">
        <EditableSection config={config} initialData={loan} readOnly={readOnly}>
          <StatisticRow widthClass="w-50">
            <Statistic
              title={<Text id="LoanDetail.Overview.principalAmount" />}
              value={<Currency value={loan.amount} />}
              color={'red-600'}
              size={'huge'}
            />

            <Statistic
              title={<Text id="LoanDetail.Overview.principalBalance" />}
              value={<Currency value={loan.principal.balance} />}
              size={'huge'}
            />
          </StatisticRow>
          <StatisticRow border={true} widthClass="w-50">
            <Statistic
              data-testid="LoanDetail.interestAmount"
              title={<Text id="LoanDetail.Overview.interestAmount" />}
              value={<Currency value={getInterestAmount(schedule)} />}
              size={'huge'}
            />
            <Statistic
              title={<Text id="LoanDetail.Overview.interestBalance" />}
              value={<Currency value={loan.interest.balance} />}
              size={'huge'}
            />
          </StatisticRow>
          <StatisticRow border={true} widthClass="w-25">
            <Statistic
              title={<Text id="LoanDetail.Overview.loanProduct" />}
              value={loan.product.name}
              size={'large'}
            />
            <Statistic
              data-testid="LoanDetail.interestRate"
              title={<Text id="LoanDetail.Overview.interestRate" />}
              value={<Percent value={loan.interestRatePercent()} />}
              size={'large'}
            />
            <Statistic
              title={<Text id="LoanDetail.Overview.duration" />}
              value={<Duration value={loan.duration} />}
              size={'large'}
            />
            <Statistic
              title={<Text id="LoanDetail.Overview.frequency" />}
              value={<Duration value={loan.repaymentFrequency} />}
              size="large"
            />
          </StatisticRow>
          <StatisticRow border={true} widthClass="w-50">
            <Statistic
              title={<Text id="LoanDetail.Overview.borrowerId" />}
              value={loan.member.id}
              size="large"
              linkTo={
                linkToMember ? memberPath({ id: loan.member.id }) : undefined
              }
            />
            <Statistic
              title={<Text id="LoanDetail.Overview.borrower" />}
              value={loan.member.fullName()}
              size="large"
              linkTo={
                linkToMember ? memberPath({ id: loan.member.id }) : undefined
              }
            />
          </StatisticRow>
        </EditableSection>
      </section>
    </DetailOverviewSection>
  );
};
