// @flow

import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';

import { MemberType, EmploymentStatuses } from '@kwara/models/src';

export const viewFields = {
  [EmploymentStatuses.EMPLOYED]: [
    'profession',
    'employer',
    'staffId',
    'employerEmail',
    'employerPhoneNumber',
    'workEmail',
    'termsOfService'
  ],
  [EmploymentStatuses.SELF_EMPLOYED]: ['profession', 'business', 'workEmail'],
  [EmploymentStatuses.OTHER]: []
};

export const excludedEmploymentField = (member: MemberType) => (
  fieldName: string
) => !includes(fieldName, viewFields[get('employmentStatus', member)]);
