//@flow

import * as React from 'react';
import classnames from 'classnames';

import { type NavigationItemType } from './NavigationItem';

type Props = {
  children: React.ChildrenArray<React.Element<NavigationItemType>>,
  padding?: boolean
};

export default ({ children, padding = true }: Props) => {
  const classes = ['list ma0 pa0', padding ? 'mt4 pt2' : 'mt0 pt0'];

  return <ul className={classnames(classes)}>{children}</ul>;
};
