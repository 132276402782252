//@flow

import mitt from 'mitt';
import get from 'lodash/fp/get';

import { EMPTY, formatErrorObject } from '@kwara/models/src/models/request';

type Handler = (evt: any) => void;

export default class DataView<ModelType, Filter> {
  constructor({ Model }: { Model: ModelType }) {
    if (Model == null) {
      throw new Error(`Model must be supplied`);
    }

    this._Model = Model;
    this._emitter = mitt();
    this._handlers = [];
    this._data = [];
    this._filterState = null;
    this._hasMore = true;
  }

  // $FlowFixMe `Cannot call this._Model.per because property per is missing in ModelType`
  _Model: ModelType;
  _data: ModelType[];
  _emitter: mitt;
  _handlers: Array<{ name: string, handler: Handler }>;
  _hasMore: boolean;
  _filterState: ?Filter;

  get data(): ModelType[] {
    return this._data.slice(0);
  }

  get hasMore(): boolean {
    return this._hasMore;
  }

  get state(): ?Filter {
    return this._filterState;
  }

  set state(value: ?Filter) {
    this._filterState = value;
    this._emitter.emit('filterChange');
    this.reset();
  }

  _fetch(): Promise<void> {
    return new Promise(resolve => {
      this._emitter.emit('change', { loading: true, error: EMPTY });
      let scope = this._Model.per(this.pageSize).page(this.currentPage);

      if (this.state != null) {
        scope = scope.where({ state: this.state });
      }

      scope
        .stats({ total: ['count', 'pages'] })
        .all()
        .then(response => {
          this._emitter.emit('responseReceived', response);
          this.totalPages = get('meta.stats.total.pages', response);
          this.totalResults = get('meta.stats.total.count', response);
          // If pagination is not supported,
          // hasMore should be false
          this._hasMore =
            this.currentPage !== this.totalPages &&
            this.totalPages !== undefined;
          this._data = [...this._data, ...response.data];
          this._emitter.emit('change', { loading: false, error: EMPTY });
          resolve();
        })
        .catch(async err => {
          const formatted = await formatErrorObject(err);
          this._emitter.emit('change', {
            loading: false,
            error: formatted
          });
        });
    });
  }

  next() {
    this.currentPage =
      this.currentPage == null ? this.firstPage : this.currentPage + 1;
    return this._fetch();
  }

  reset() {
    this.currentPage = null;
    this.totalPages = null;
    this.totalResults = null;

    this._data = [];
    this._emitter.emit('change', { loading: false, error: EMPTY });
    this._hasMore = true;
  }

  on(name: string, handler: Handler) {
    this._handlers.push({ name, handler });
    this._emitter.on(name, handler);
  }

  destroy() {
    this._handlers.map(({ name, handler }) => this._emitter.off(name, handler));
    this.reset();
  }

  firstPage = 1;
  pageSize = 10;
  currentPage = null;
  totalPages = null;
  totalResults = null;
}
