//@flow
import * as React from 'react';
import map from 'lodash/fp/map';

import { type SubStepComponentProps } from '../../../components/Wizard';

const reasons = [
  'transfer_funds_to_another_account',
  'withdraw_from_sacco',
  'other'
];

export const Confirm = ({
  StackChild,
  TextArea,
  SelectField
}: SubStepComponentProps<{}>) => {
  return (
    <StackChild>
      <SelectField
        required
        name="reason"
        labelId="SavingClose.Confirm.Reason.label"
      >
        <SelectField.Option value="" />
        {map(
          reason => (
            <SelectField.Option
              value={reason}
              translationId={`SavingClose.Confirm.Reason.${reason}`}
            />
          ),
          reasons
        )}
      </SelectField>
      <TextArea
        name="notes"
        required
        labelId="SavingClose.Confirm.Note.label"
        infoId="SavingClose.Confirm.Note.info"
      />
    </StackChild>
  );
};

Confirm.validate = {
  reason: {
    isRequired: () => true
  },
  notes: {
    isRequired: () => true
  }
};
