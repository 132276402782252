import map from 'lodash/fp/map';
import isNumber from 'lodash/fp/isNumber';
import _isEmpty from 'lodash/fp/isEmpty';
import pickBy from 'lodash/fp/pickBy';
import identity from 'lodash/fp/identity';
import negate from 'lodash/fp/negate';

export const mapIndexed = map.convert({ cap: false });

// Removes item from array by index
// Does not mutate array
export function removeAt(idx, arr = []) {
  return [...arr].filter((_e, i) => i !== idx);
}

// lodash _isEmpty considers a number as
//  "empty", but our validation should not
export const isEmpty = n => !isNumber(n) && _isEmpty(n);

export const onlyTruthyValues = collection => pickBy(identity, collection);

export const isFilled = negate(isEmpty);
