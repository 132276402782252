// @flow
import * as React from 'react';
import classnames from 'classnames';

import CardContainer from '@kwara/components/src/CardContainer';

import styles from './index.module.scss';

type Props = {
  children: any,
  overview: React.Node,
  className?: string,
  printOverview?: boolean
};

const PageLayout = ({
  children,
  overview,
  className,
  printOverview = false
}: Props) => {
  const classes = ['min-vh-100 flex flex-column content-stretch'];

  return (
    <div className={classnames(classes, className)}>
      <div
        className={classnames([
          styles.Overview,
          printOverview ? null : 'hide-on-print'
        ])}
      >
        {overview}
      </div>
      <CardContainer>
        <div className={styles.Content}>{children}</div>
      </CardContainer>
    </div>
  );
};

export default PageLayout;
