// @flow

import * as React from 'react';
import { typeof SubscribedTextArea } from '@kwara/components/src/Wizard/FormFields';

import { EditActions } from './Actions';

import styles from './index.module.css';

type Handler = () => void;

type Props = {
  index: number,
  onDone: Handler,
  position: number,
  TextArea: SubscribedTextArea
};

export default ({ TextArea, onDone, position, index }: Props) => (
  <div className={styles.Edit}>
    <TextArea
      margin={false}
      name={`kin[${index}`}
      labelId="AddMember.Kin.position.edit"
      placeholderId="AddMember.Kin.placeholder"
      values={{ position, br: <br /> }}
    />

    <EditActions disabled={false} onDone={onDone} />
  </div>
);
