//@flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { type Location } from '@kwara/components/src/Actionable';
import { type LoanType, type GuaranteeType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import Tag, { Status } from '@kwara/components/src/Tag';

import { Currency } from '../../../../components/Currency';
import { Card } from '../../../../components/Card';

type Props = {
  loan: LoanType,
  guarantee: GuaranteeType,
  actionTo: Location
};

export default ({ actionTo, loan, guarantee }: Props) => {
  const isBorrower = get('member.id', loan) === guarantee.member.id;
  const metadataTextId = isBorrower ? 'borrower' : 'guarantor';

  return (
    <Card
      className="mb4"
      border={false}
      header={guarantee.member.fullName()}
      actionLabelId="GuaranteeCard.action"
      actionTo={actionTo}
      metadata={
        <Tag id={`GuaranteeCard.${metadataTextId}`} status={Status.Neutral} />
      }
    >
      <Statistic
        title={<Text id="GuaranteeCard.amount" />}
        value={<Currency value={guarantee.amount} />}
        compact
      />
      <Statistic
        title={<Text id="GuaranteeCard.deposits" />}
        value={<Currency value={guarantee.member.totalSavings} />}
        compact
      />
      <Statistic
        title={<Text id="GuaranteeCard.loans" />}
        value={<Currency value={guarantee.member.totalLoansBalance} />}
        compact
      />
      <Statistic
        title={<Text id="GuaranteeCard.standing" />}
        value={<Currency value={guarantee.member.standing} />}
        compact
      />
    </Card>
  );
};
