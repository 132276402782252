// @flow

import includes from 'lodash/fp/includes';

import {
  type CollateralT,
  CollateralTypes,
  CollateralFields
} from '@kwara/models/src';

const {
  account,
  bank,
  branch,
  insuranceNumber,
  insuranceCompany,
  narration,
  type,
  landRegistration
} = CollateralFields;

export const viewFields = {
  [CollateralTypes.LAND]: [type, landRegistration, narration],
  [CollateralTypes.STOCK]: [type, narration],
  [CollateralTypes.BANK_ACCOUNT]: [type, bank, branch, account, narration],
  [CollateralTypes.LIFE_INSURANCE]: [type, insuranceNumber, insuranceCompany, narration]
};

export const excludedFields = (collateral: CollateralT) => (
  fieldName: string
) => !includes(fieldName, viewFields[collateral.assetName]);
