// @flow

import * as React from 'react';

import { permission, type ApiPermission } from '../../models';

type Props = {
  to: ApiPermission[],
  checkPermission: (permission: ApiPermission[]) => boolean,
  children: React.Node,
  fallback?: React.Node
};

const Visible = ({ checkPermission, to, fallback, children }: Props) => {
  if (checkPermission(to)) {
    return children;
  } else if (fallback) {
    return fallback;
  } else {
    return null;
  }
};

Visible.defaultProps = {
  checkPermission: permission.to
};

export default Visible;
