class TranslationStore {
  constructor() {
    this.translate = () => {};
  }
  updateTranslateFunction(f) {
    this.translate = f;
  }

  setCurrency(currency) {
    this.currency = currency;
  }
}

export const pdfTranslationStore = new TranslationStore();
