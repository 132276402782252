// @flow

import { attr, belongsTo, hasMany, hasOne } from 'spraypaint';
import pipe from 'lodash/fp/pipe';
import some from 'lodash/fp/some';
import get from 'lodash/fp/get';

import { Logger } from '@kwara/lib/src/logger';

import { type MemberType } from '..';
import filterEmptyValues from '../lib/filterEmptyValues';
import Base, { type BaseModel } from './Base';
import {
  type AuthorizationHoldT,
  LatestPendingAuthorizationHold
} from './AuthorizationHold';
import cameliseObjectKeys from '../lib/cameliseObjectKeys';
import { snakeCaseObjectKeys } from '../lib/snakeCaseObjectKeys';
import { ProductTypes } from './SavingProduct';
import createModelErrors, {
  createErrorsFromApiResponse
} from './createModelErrors';

import { store } from '../../../webapp-sacco/src/models/Store';

export const SavingStates = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  ACTIVE: 'ACTIVE',
  ACTIVE_IN_ARREARS: 'ACTIVE_IN_ARREARS',
  MATURED: 'MATURED',
  LOCKED: 'LOCKED',
  DORMANT: 'DORMANT',
  CLOSED: 'CLOSED',
  CLOSED_WRITTEN_OFF: 'CLOSED_WRITTEN_OFF',
  WITHDRAWN: 'WITHDRAWN',
  CLOSED_REJECTED: 'CLOSED_REJECTED'
};

export const SavingEvents = Object.freeze({
  CLOSE: 'close'
});

export type SavingState = $Values<typeof SavingStates>;
export type SavingEvent = $Values<typeof SavingEvents>;

type Note = {
  notes: ?string
};

export const fields = Object.freeze({
  remittance: {
    method: 'remittanceOptions.remittance_method',
    bank: 'remittanceOptions.direct_debit_details.remittance_direct_debit_bank',
    branch:
      'remittanceOptions.direct_debit_details.remittance_direct_debit_branch',
    account:
      'remittanceOptions.direct_debit_details.remittance_direct_debit_account',
    collectingBank:
      'remittanceOptions.direct_debit_details.remittance_collection_bank',
    startDate: 'remittanceOptions.remittance_start_date',
    feeAmount: 'remittanceOptions.remittance_fee_amount',
    frequency: 'remittanceOptions.remittance_frequency',
    collectionDate: 'remittanceOptions.remittance_collection_date'
  }
});

const Saving = Base.extend({
  static: {
    jsonapiType: 'savings'
  },
  attrs: {
    name: attr(),
    accruedInterest: attr(),
    balance: attr(),
    interest: attr(),
    type: attr(),
    state: attr({ persist: false }),
    fees: attr(),
    transactions: hasMany(),
    latestPendingAuthorizationHold: hasOne({
      type: LatestPendingAuthorizationHold
    }),

    // Create
    accountHolderId: attr(),
    productId: attr(),
    amount: attr(),

    member: belongsTo(),
    product: belongsTo('savings_products'),

    monthlyRemittanceAmount: attr(),

    // only for POST
    remittanceOptions: attr(),

    // only for GET
    // this is duplicating the shape inside remittanceOptions
    remittanceMethod: attr(),
    payrollDeductionDetails: attr()
  },
  methods: {
    deserialize() {
      // This field is stored as a string in Mambu, but needs
      // to be a date for the DatePicker to understand it

      const startDate = get(fields.remittance.startDate, this);

      if (startDate) {
        this.remittanceOptions.remittance_start_date = new Date(startDate);
      }

      return this;
    },
    isBridgingProduct() {
      return store.isBridgingDepositProduct(get('product.id', this));
    },
    availableBalance() {
      return (
        get('availableBalance', this.latestPendingAuthorizationHold) ||
        this.balance
      );
    },
    isPendingApproval() {
      return this.state.current === SavingStates.PENDING_APPROVAL;
    },
    isActive() {
      return this.state.current === SavingStates.ACTIVE;
    },
    isApproved() {
      return [SavingStates.ACTIVE, SavingStates.APPROVED].includes(
        this.state.current
      );
    },
    isClosed() {
      return [SavingStates.CLOSED].includes(this.state.current);
    },
    isWithdrawable() {
      return (
        this.product.productType === ProductTypes.CURRENT_ACCOUNT &&
        !this.isBridgingProduct()
      );
    },
    canBeClosed() {
      return (
        this.isEventPermitted(SavingEvents.CLOSE) &&
        [SavingStates.ACTIVE, SavingStates.DORMANT].includes(
          this.state.current
        ) &&
        this.balance === 0 &&
        !this.isBridgingProduct()
      );
    },
    canAddPenalty() {
      return (
        !this.isBridgingProduct() && this.state.current === SavingStates.ACTIVE
      );
    },
    transactionsPermitted() {
      return !this.isClosed() && this.isApproved() && !this.isBridgingProduct();
    },
    // TODO: Find better way of doing this
    interestObject() {
      return cameliseObjectKeys(this.interest);
    },
    async close({ notes }: { notes: ?string }): Promise<boolean> {
      if (this.isEventPermitted(SavingEvents.CLOSE)) {
        return await this.transition(SavingEvents.CLOSE, { notes });
      }

      this.errors = createModelErrors({
        base: 'APP_SAVING_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    isEventPermitted(event: SavingEvent) {
      return some({ name: event }, get('permitted_events', this.state));
    },
    async transition(event: SavingEvent, params: ?Note) {
      const url = `${Saving.url(this.id)}/state`;
      const attributes = pipe(
        filterEmptyValues,
        snakeCaseObjectKeys
      )({
        event,
        ...params
      });

      const options = {
        ...Saving.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({ data: { attributes } })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();
          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (errors) {
        Logger.error(
          'Error transitioning saving states',
          JSON.stringify(errors)
        );
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    }
  }
});

interface SavingsRemittanceT {
  remittance_method: string;
  remittance_frequency: string;
  remittance_collection_date: string;
  remittance_fee_amount: string;
  direct_debit_details: {
    remittance_direct_debit_bank: string,
    remittance_direct_debit_branch: string,
    remittance_direct_debit_account: string
  };
  payroll_deduction_details: {
    payroll_deduction_employer_name: string,
    payroll_deduction_staff_id: string
  };
}

export interface SavingType extends BaseModel<SavingType> {
  id: string;
  amount: number;
  member: MemberType;
  balance: number;
  accruedInterest?: number;
  name: string;
  updatedAt?: string;
  product: {
    name: string,
    minimumOpeningBalance: number
  };
  latestPendingAuthorizationHold: AuthorizationHoldT;
  availableBalance: () => number;
  isApproved: () => boolean;
  isClosed: () => boolean;
  canBeClosed: () => boolean;
  isBridgingProduct: () => boolean;
  transactionsPermitted: () => boolean;
  isWithdrawable: () => boolean;
  interestObject: () => {
    rate: number
  };
  // This looks horrible but that's the way the model is now, when posting the remittance options
  // are nested under this key, when getting they are flattened to the top level.
  remittanceOptions?: SavingsRemittanceT;
  remittanceMethod: string;
  monthlyRemittanceAmount: string;
  directDebitDetails: {
    remittance_direct_debit_bank: string,
    remittance_direct_debit_branch: string,
    remittance_direct_debit_account: string
  };
  payrollDeductionDetails: {
    payroll_deduction_employer_name: string,
    payroll_deduction_staff_id: string
  };

  state: {
    current: string,
    permitted_events: { name: SavingEvent }[]
  };
}

export default Saving;
