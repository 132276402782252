import { attr, belongsTo } from 'spraypaint';

import Base, { type BaseModel } from './Base';
import { User, type UserT } from './User';

type ReviewEventT = 'reject' | 'approve';

export const ImportReviewSet = Base.extend({
  static: {
    jsonapiType: 'import_review_sets'
  },
  attrs: {
    notes: attr(),
    event: attr(),
    user: belongsTo({ type: User })
  }
});

export interface ImportReviewSetT extends BaseModel<ImportReviewSetT> {
  notes: string;
  event: ReviewEventT;
  user: UserT;
}
