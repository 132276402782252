// @flow
import * as React from 'react';

import { useMember } from '@kwara/models/src/models/request/hooks';

import MemberSearch from '../../../components/MemberSearch';
import { type SavingsSubStepProps } from '..';
import { EMPTY } from '../../../models/request';
import Loading from '@kwara/components/src/Loadable/Loading';
import { Error } from '@kwara/components/src/Loadable';
import { hasErrors } from '@kwara/models/src/models/request';

interface SearchProps extends SavingsSubStepProps {
  onSelect: ({ member: any }) => void;
}
const Search = ({ StackChild, onSelect }: SearchProps) => (
  <StackChild size="wide">
    <MemberSearch onSelect={onSelect} />
  </StackChild>
);

const includes = [];
export function Member(props: SavingsSubStepProps) {
  const { addDataAndContinue } = props;
  const [memberId, setMemberId] = React.useState();
  const { data: member, isPending, error } = useMember(memberId, includes);
  const onSelect = member => setMemberId(member.id);
  React.useEffect(() => {
    if (member !== EMPTY) {
      addDataAndContinue({ member });
    }
    // Atm addDataAndContinue is part of the wizard and should be wrapped in a useCallback to
    // memoize it and be able to pass it as a dependency here below.
    // A refactoring of the wizard is not going to happen in this PR.
    // eslint-disable-next-line
  }, [member]);

  if (memberId && isPending) {
    return <Loading />;
  }

  if (hasErrors(error)) {
    return <Error />;
  }

  return <Search {...props} onSelect={onSelect} />;
}
