import React from 'react';
import cx from 'classnames';

import Actionable from '@kwara/components/src/Actionable';
import Asset from '@kwara/components/src/Asset';

import style from './index.module.scss';

export function TableActions({ onEdit, onRemove }) {
  return (
    <div className={style.actionIconWrapper}>
      <Actionable
        className={cx(style.actionIcon, style.pencilIcon)}
        onClick={e => {
          e.stopPropagation();
          onEdit();
        }}
      >
        <Asset id="Pencil" col="grey300" />
      </Actionable>
      <Actionable
        className={cx(style.actionIcon, style.trashBinIcon)}
        onClick={e => {
          e.stopPropagation();
          onRemove();
        }}
      >
        <Asset id="TrashBin" col="red500" />
      </Actionable>
    </div>
  );
}
