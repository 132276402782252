// @flow
import * as React from 'react';
import get from 'lodash/fp/get';

import { type ScheduleType } from '@kwara/models/src';

import RepaymentsTable from './RepaymentsTable';

export const Schedule = ({
  schedule,
  initiallyOpen = false
}: {
  schedule: ?ScheduleType,
  initiallyOpen?: boolean
}) => (
  <RepaymentsTable
    repayments={get('repayments', schedule)}
    initiallyOpen={initiallyOpen}
  />
);
