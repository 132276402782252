// @flow
import * as React from 'react';

import type { LoadableBasePropsT } from '@kwara/components/src/Loadable';

import {
  Activity,
  AppPermission,
  AppRole,
  BatchTransaction,
  BatchTransactionImport,
  BatchUpload,
  CrbDataSubmission,
  CurrentTill,
  Event,
  GeneralLedgerAccount,
  GeneralLedgerAccountApi,
  InvitationModel,
  LoanApprovalSetting,
  LoanProduct,
  LoginActivity,
  MemberReport,
  Organisation,
  PendingSavingsTransaction,
  PendingLoanTransaction,
  Profile,
  Role,
  Saving,
  SavingProduct,
  SavingsTransaction,
  SupervisorMetric,
  Till,
  TillMetric,
  TillTransaction,
  TopupRequest,
  User,
  type ActivityType,
  type AppPermissionT,
  type AppRoleT,
  type BatchTransactionT,
  type BatchTransactionImportT,
  type BatchUploadT,
  type CrbDataSubmissionT,
  type EventT,
  type GeneralLedgerAccountT,
  type RoleT,
  type SavingType,
  type SavingProductType,
  type InvitationModelT,
  type LoanApprovalSettingT,
  type LoanProductType,
  type LoginActivityT,
  type MemberReportT,
  type OrganisationT,
  type TillMetricT,
  type SavingsTransactionType,
  type LoanTransactionType,
  type SupervisorMetricT,
  type TillT,
  type TillTransactionType,
  type UserT,
  type IncludesT,
  type WhereT,
  type TopupRequestT
} from '@kwara/models/src';

import {
  useModelsRequest,
  useModelRequest
} from '@kwara/models/src/models/request';

import { TopupRequestStatus } from '@kwara/models/src/models/TopupRequest';
import { defaultSavingIncludes } from '@kwara/models/src/models/request/hooks';

export {
  hasErrors,
  EMPTY,
  isEMPTY,
  combineRequests,
  getFirstError
} from '@kwara/models/src/models/request';

export { useLoan, useSaving } from '@kwara/models/src/models/request/hooks';

export const useSavings = (
  includes: IncludesT = defaultSavingIncludes,
  where: WhereT = null,
  page: number = 1
): LoadableBasePropsT<SavingType> =>
  useModelsRequest<SavingType>(Saving, includes, where, 10, page);

const defaultSupervisorMetricsIncludes = [];
export const useSupervisorMetrics = (
  includes: IncludesT = defaultSupervisorMetricsIncludes
) => useModelsRequest<SupervisorMetricT>(SupervisorMetric, includes);

const defaultTillTransactionIncludes = ['member'];
export const useTillTransactions = (
  till_id: string,
  includes: IncludesT = defaultTillTransactionIncludes
) => {
  const [where] = React.useState({ till_id });
  return useModelsRequest<TillTransactionType>(
    TillTransaction,
    includes,
    where
  );
};

export const useTillTransaction = (
  id: string,
  includes: IncludesT = defaultTillTransactionIncludes
) => {
  return useModelRequest<TillTransactionType>(TillTransaction, id, includes);
};

const defaultTillIncludes = [];

export const useCurrentTill = (includes = defaultTillIncludes) =>
  useModelRequest<TillT>(CurrentTill, includes);
export const useTill = (
  id: string,
  includes: IncludesT = defaultTillIncludes
) => useModelRequest<TillT>(Till, id, includes);

const defaultTillMetricsIncludes = [];
export const useTillMetrics = (
  till_id: string,
  includes: IncludesT = defaultTillMetricsIncludes
) => {
  const [where] = React.useState({ till_id });
  return useModelsRequest<TillMetricT>(TillMetric, includes, where);
};

const defaultBatchUploadIncludes = ['user'];
export const useHistoricalBatchUploads = (
  includes: IncludesT = defaultBatchUploadIncludes,
  currentPage: ?number = null
) =>
  useModelsRequest<BatchUploadT>(BatchUpload, includes, null, 10, currentPage);

const defaultBatchTransactionImportsIncludes = ['user'];
export const useBatchTransactionImports = (
  includes: IncludesT = defaultBatchTransactionImportsIncludes,
  currentPage: ?number = null
) =>
  useModelsRequest<BatchTransactionImportT>(
    BatchTransactionImport,
    includes,
    null,
    10,
    currentPage
  );

const defaultBatchTransactionImportIncludes = ['batch_transactions', 'user'];
export const useBatchTransactionImport = (
  id: string,
  includes: IncludesT = defaultBatchTransactionImportIncludes
) =>
  useModelRequest<BatchTransactionImportT>(
    BatchTransactionImport,
    id,
    includes
  );

const defaultBatchTransactionsIncludes = [];
export const useBatchTransactions = (
  batchId: string,
  includes: IncludesT = defaultBatchTransactionsIncludes,
  where: WhereT,
  currentPage: ?number = null
) => {
  const scope = React.useMemo(() => BatchTransaction.from(batchId), [batchId]);
  return useModelsRequest<BatchTransactionT>(
    scope,
    includes,
    where,
    10,
    currentPage
  );
};

const defaultProfileIncludes = [
  'role',
  'branch.address',
  'branch.transaction_channels',
  'app_roles.app_permissions',
  'organisation'
];
export const useProfile = (includes: IncludesT = defaultProfileIncludes) => {
  return useModelsRequest<UserT>(Profile, includes);
};

const defaultUserIncludes = ['app_roles'];
export const useUser = (
  userId: string,
  includes: IncludesT = defaultUserIncludes
) => useModelRequest<UserT>(User, userId, includes);

export const useUsers = (includes: IncludesT = defaultUserIncludes) =>
  useModelsRequest<UserT>(User, includes);

const defaultOrganisationIncludes = ['users.app_roles'];
export const useOrganisation = (
  includes: IncludesT = defaultOrganisationIncludes
) => {
  return useModelsRequest<OrganisationT>(Organisation, includes);
};

type SupportedEntity = 'loans' | 'savings' | 'member';
const defaultActivityIncludes = [];
export const useActivity = (
  id: string,
  includes: IncludesT = defaultActivityIncludes,
  entity: SupportedEntity = 'loans'
) => {
  // TO DO: A better way to do this, without useState
  // https://github.com/getkwara/webapp/pull/735#discussion_r330087984
  const [scope] = React.useState(() => Activity[entity](id));
  return useModelsRequest<ActivityType>(scope, includes);
};

const defaultSavingProductsIncludes = [];
export const useSavingProducts = (
  includes: IncludesT = defaultSavingProductsIncludes
) => {
  return useModelsRequest<SavingProductType>(SavingProduct, includes);
};

export const useSavingProduct = (
  id: string,
  includes: IncludesT = defaultSavingProductsIncludes
) => useModelRequest<SavingProductType>(SavingProduct, id, includes);

const defaultLoanProductsIncludes = [];
export const useLoanProducts = (
  includes: IncludesT = defaultLoanProductsIncludes
) => {
  // TO DO: Ideally we would not need to specify the result per page to 1000.
  // Because of the way Mambu is set up we have to filter products by branch availability because
  // Mambu returns all loan products for a mambu instance (we expect it to return by branch).
  // See ch: https://app.clubhouse.io/getkwara/story/8553/product-settings-page-loan-products
  return useModelsRequest<LoanProductType>(LoanProduct, includes, null, 1000);
};

export const useLoanProduct = (
  id: string,
  includes: IncludesT = defaultLoanProductsIncludes
) => useModelRequest<LoanProductType>(LoanProduct, id, includes);

const defaultTopupRequestsIncludes = ['till'];
const defaultWhere = { status: TopupRequestStatus.PENDING };
export const useTopupRequests = (
  includes: IncludesT = defaultTopupRequestsIncludes,
  where: WhereT = defaultWhere
) => useModelsRequest<TopupRequestT>(TopupRequest, includes, where);

export const useTopupRequest = (
  id: string,
  includes: IncludesT = defaultTopupRequestsIncludes
) => useModelRequest<TopupRequestT>(TopupRequest, id, includes);

export type { LoginActivityT };
const defaultLoginIncludes = [];
export const useLoginActivities = (
  currentPage: ?number = null
): LoadableBasePropsT<LoginActivityT[]> =>
  useModelsRequest<LoginActivityT[]>(
    LoginActivity,
    defaultLoginIncludes,
    null,
    10,
    currentPage
  );

const defaultHistoricalCreditSubmissionsIncludes = ['user'];
export const useHistoricalCreditSubmissions = (
  includes: IncludesT = defaultHistoricalCreditSubmissionsIncludes,
  currentPage: ?number = null
) =>
  useModelsRequest<CrbDataSubmissionT>(
    CrbDataSubmission,
    includes,
    null,
    10,
    currentPage
  );

export const useHistoricalCreditSubmission = (
  id: string,
  includes: IncludesT = defaultHistoricalCreditSubmissionsIncludes
) => useModelRequest<CrbDataSubmissionT>(CrbDataSubmission, id, includes);

export const useLoanApprovalSetting = () =>
  useModelsRequest<LoanApprovalSettingT>(LoanApprovalSetting);

export const useRoles = () => useModelsRequest<RoleT>(Role);

const useAppRolesIncludes = [];
export const useAppRoles = (includes: IncludesT = useAppRolesIncludes) =>
  useModelsRequest<AppRoleT>(AppRole, includes);

const defaultUseAppRoleIncludes = ['app_permissions'];
export const useAppRole = (
  roleId,
  includes: IncludesT = defaultUseAppRoleIncludes
) => useModelRequest<AppRoleT>(AppRole, roleId, includes);

const defaultInvitationsIncludes = ['role'];
export const useInvitations = (
  includes: IncludesT = defaultInvitationsIncludes,
  currentPage: ?number = null
) =>
  useModelsRequest<InvitationModelT>(
    InvitationModel,
    includes,
    null,
    10,
    currentPage
  );

const defaultMemberReportsIncludes = [];
export const useMemberReports = (
  includes: IncludesT = defaultMemberReportsIncludes,
  currentPage: ?number = null
) =>
  useModelsRequest<MemberReportT>(
    MemberReport,
    includes,
    null,
    10,
    currentPage
  );

const defaultEventIncludes = ['user'];
export const useEvents = (
  includes: IncludesT = defaultEventIncludes,
  page: ?number
) => useModelsRequest<EventT>(Event, includes, null, 10, page);

const defaultSavingTransactionIncludes = [];
export const useSavingTransactions = (
  savingId: string,
  includes: IncludesT = defaultSavingTransactionIncludes
) => {
  const where = React.useMemo<{ savingId: string }>(
    () => ({ savings_id: savingId }),
    [savingId]
  );
  return useModelsRequest<SavingsTransactionType>(
    SavingsTransaction,
    includes,
    where
  );
};

const defaultPendingSavingTransactionIncludes = [];
export const usePendingSavingTransactions = (
  savingId: string,
  includes: IncludesT = defaultPendingSavingTransactionIncludes,
  where_: { state: 'pending_approval' | 'rejected' | 'approved' } = {},
  page
) => {
  const where = React.useMemo<{ savingId: string }>(() => {
    return { savings_id: savingId, state: where_.state };
  }, [savingId, where_.state]);
  return useModelsRequest<SavingsTransactionType>(
    PendingSavingsTransaction,
    includes,
    where,
    100,
    page
  );
};

const defaultPendingLoanTransactionIncludes = [];
export const usePendingLoanTransactions = (
  loanId: string,
  includes: IncludesT = defaultPendingLoanTransactionIncludes,
  where_: { state: 'pending_approval' | 'rejected' | 'approved' } = {},
  page
) => {
  const where = React.useMemo<{ loanId: string }>(() => {
    return { loan_id: loanId, state: where_.state };
  }, [loanId, where_.state]);
  return useModelsRequest<LoanTransactionType>(
    PendingLoanTransaction,
    includes,
    where,
    100,
    page
  );
};

const defaultGlAccountIncludes = [];
const defaultGlAccountWhere = { allow_manual_journal_entries: 'true' };

export const useGlAccount = (
  id: string,
  includes: IncludesT = defaultGlAccountIncludes
) =>
  useModelRequest<GeneralLedgerAccountT>(GeneralLedgerAccountApi, id, includes);

export const useGlAccounts = (
  includes: IncludesT = defaultGlAccountIncludes,
  where: WhereT = defaultGlAccountWhere
) =>
  useModelsRequest<GeneralLedgerAccountT>(
    GeneralLedgerAccount,
    includes,
    where
  );

export const useAppPermissions = () =>
  useModelsRequest<AppPermissionT>(AppPermission);
