// @flow
import * as React from 'react';
import get from 'lodash/fp/get';

import { BankSelect, BranchSelect } from '@kwara/components/src/Form';

import { type SubStepComponentProps } from '../../../components/Wizard';

export const Bank = ({
  StackChild,
  TextField,
  formProps
}: SubStepComponentProps<{}>) => {
  const { values } = formProps;

  const bankId = get('bankId', values);

  return (
    <StackChild>
      <BankSelect required name="bankId" labelId="AddMember.Bank.name.label" />
      <BranchSelect
        required
        name="bankBranchId"
        bankId={bankId}
        labelId="AddMember.Bank.branch.label"
      />
      <TextField
        required
        name="accountNumber"
        labelId="AddMember.Bank.account.label"
      />
    </StackChild>
  );
};

Bank.validate = {
  bankId: { isRequired: () => true },
  bankBranchId: { isRequired: () => true },
  accountNumber: { isRequired: () => true }
};
