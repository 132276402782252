//@flow
import * as React from 'react';

import { type TillT } from '@kwara/models/src';
import { BackButton } from '@kwara/components/src/Button';
import StatusTag from '@kwara/components/src/StatusTag';
import { Text, DateTime } from '@kwara/components/src/Intl';
import { ButtonBar } from '@kwara/components/src/ButtonBar';

import { type Props as OverviewProps } from './Overview';

type Props = OverviewProps & { till: TillT };

export const OverviewMetadata = ({ actions, showBack, till }: Props) => {
  const back = showBack ? (
    <BackButton
      type="secondary"
      to={{ url: '/tellers', routeName: 'Tellers' }}
    />
  ) : null;

  const noButtons = actions == null && back == null;

  return noButtons ? null : (
    <React.Fragment>
      <ButtonBar className="mb4 pb3" left={[back]} right={[]} />
      <div className="mb2 grey-400">
        <StatusTag state={till.status} /> &middot;{' '}
        <Text
          id="TillDetail.updated"
          values={{ updated: <DateTime value={till.updatedAt} /> }}
        />{' '}
        &middot; <Text id="TillDetail.tillId" values={{ id: till.id }} />
      </div>
    </React.Fragment>
  );
};
