//@flow
import * as React from 'react';
import lodashGroupBy from 'lodash/groupBy';
import lodashMap from 'lodash/map';
import lodashOrderBy from 'lodash/orderBy';
import memoize from 'lodash/memoize';
import identity from 'lodash/identity';
import getOr from 'lodash/fp/getOr';

import { type MemberType, type MemberState, Member } from '@kwara/models/src';
import StatusTag from '@kwara/components/src/StatusTag';
import { DateTime } from '@kwara/components/src/Intl';

import { memberPath } from '../../../../lib/urls';
import Table, * as table from '../../../../components/Table';

export const Groups = {
  state: 'state'
};

export const Orders = {
  updated: 'updated_at'
};

type Group = $Values<typeof Groups>;
type Order = $Values<typeof Orders>;

type FormattedMember = {
  id: string,
  name: string,
  phone: string,
  updated: string,
  state: MemberState
};

function fetchMember(id: string): MemberType {
  return (
    Member.full()
      .includes({ loans: ['product', 'transactions'] })
      .includes({ guaranteed_loans: ['product', 'guarantees.member'] })
      // .includes({ guaranteed_loans: ['product', 'guarantors.member'] })
      .includes({ savings: ['product', 'transactions'] })
      .includes('id_documents')
      .find(id)
  );
}

// This prefetches the member details when hovering over a name in order
// to prime the cache and make the detail page appear faster.
// The call is cached by ID so we avoid slashing the server with a great number of calls
// when a user hovers over a member has already been requested
const preFetchMember = memoize(fetchMember, identity);

const formatter = (member: MemberType): FormattedMember => ({
  id: member.id,
  name: member.fullName(),
  phone: member.phoneNumber,
  updated: member.updatedAt,
  state: getOr('PENDING', 'state.current', member)
});

const MemberRow = ({
  member,
  pathTo
}: {
  member: MemberType,
  pathTo: string
}) => {
  const m = formatter(member);

  return (
    <table.Row to={pathTo} onMouseEnter={() => preFetchMember(m.id)}>
      <table.Cell to={pathTo} className="grey-400 kw-numeric">
        {m.id}
      </table.Cell>
      <table.Cell to={pathTo} className="mw5">
        {m.name}
      </table.Cell>
      <table.Cell to={pathTo}>{m.phone}</table.Cell>
      <table.Cell to={pathTo}>
        <DateTime value={m.updated} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <StatusTag size="small" state={m.state} />
      </table.Cell>
    </table.Row>
  );
};

const createGroupWithMembers = ({
  key,
  columns,
  group,
  members
}: {
  key: string,
  columns: number,
  group: Group,
  members: MemberType[]
}) => (
  <React.Fragment key={key}>
    {group !== 'none' && (
      <table.GroupingRow
        cols={columns}
        translationId={`MemberListTableGroup.${group}`}
      />
    )}
    {members.map(member => (
      <MemberRow
        key={member.id}
        member={member}
        pathTo={memberPath({ id: member.id })}
      />
    ))}
  </React.Fragment>
);

type GroupedMembers = { [id: Group]: MemberType[] };

const membersGroupedByAttribute = (
  members: MemberType[],
  attribute: Group
): GroupedMembers => lodashGroupBy(members, member => member[attribute]);

const orderMembersByAttribute = (
  members: MemberType[],
  attribute: ?Order
): MemberType[] =>
  //$FlowFixMe should be lodashOrderBy<MemberType>(...)
  lodashOrderBy(members, [member => [member[attribute]]], ['desc']);

const MemberListTable = ({
  hasMore,
  groupBy,
  errors,
  loading,
  members,
  orderBy,
  onLoadMoreData
}: {
  hasMore: boolean,
  groupBy: ?Group,
  errors: Object[],
  loading: boolean,
  members: MemberType[],
  orderBy: ?Order,
  onLoadMoreData: () => void
}) => {
  const groupedMembers = groupBy
    ? membersGroupedByAttribute(members, groupBy)
    : {
        none: members
      };

  return (
    <Table
      heading={
        <table.Row>
          <table.Heading translationId="MemberListTable.id" />
          <table.Heading translationId="MemberListTable.name" />
          <table.Heading translationId="MemberListTable.phone" />
          <table.Heading translationId="MemberListTable.lastUpdated" />
          <table.Heading translationId="MemberListTable.status" />
        </table.Row>
      }
      footer={
        <table.Footer
          colSpan={5}
          isPending={loading}
          onNext={onLoadMoreData}
          hasMore={hasMore}
          errors={errors}
          items={members}
          translationBaseId="MemberListTable"
        />
      }
    >
      {lodashMap(groupedMembers, (members, group) =>
        createGroupWithMembers({
          key: group,
          columns: 5,
          group: group,
          members: orderMembersByAttribute(members, orderBy)
        })
      )}
    </Table>
  );
};

MemberListTable.defaultProps = {
  hasMore: false,
  groupBy: Groups.state,
  orderBy: Orders.updated
};

export default MemberListTable;
