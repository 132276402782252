//@flow
import * as React from 'react';

import noop from 'lodash/noop';

import Context from './Context';

export const Levels = {
  error: 'error',
  success: 'success',
  warning: 'warning'
};

type Level = $Values<typeof Levels>;
export type NotificationType = {
  level: Level,
  message: React.Node,
  onDismiss?: () => void,
  customOnDismiss?: () => void
};

type State = {
  current: ?NotificationType
};

/*
  Provider stores the current notification
  and provides functions to display errors and messages
  and to dismiss a notification.

  It should exist at the top-level of the app.
*/
export default class Provider extends React.Component<*, State> {
  state = {
    current: null
  };

  addNotification = (notification: NotificationType) => {
    this.setState({ current: notification });
  };

  displayError = (message: React.Node) => {
    this.addNotification({ level: Levels.error, message });
  };

  displayMessage = (message: React.Node) => {
    this.addNotification({ level: Levels.success, message });
  };

  displayWarning = (
    message: React.Node,
    customOnDismiss: () => void = noop
  ) => {
    this.addNotification({ level: Levels.warning, message, customOnDismiss });
  };

  dismiss = () => {
    const { customOnDismiss = noop } = this.state.current || {};
    this.setState({ current: null }, customOnDismiss);
  };

  render() {
    return (
      <Context.Provider
        value={{
          current: this.state.current,
          displayError: this.displayError,
          displayMessage: this.displayMessage,
          displayWarning: this.displayWarning,
          dismiss: this.dismiss
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
