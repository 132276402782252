// @flow

import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import Visible from '../../../../components/Visible';
import { settingPath } from '../../../../lib/urls';
import { AppPermissions } from '../../../../models/Permission';
import { Header } from '../Header';
import { Invites } from './components/Invites';

export const Workspace = () => {
  return (
    <>
      <Header
        titleId="Settings.Workspace.title"
        subtitleId="Settings.Workspace.subtitle"
        action={
          <Visible to={AppPermissions.InviteUser}>
            <Button
              to={settingPath({ baseExtension: 'workspace', action: 'invite' })}
              type="primary"
            >
              <Text id="Settings.Workspace.invite" />
            </Button>
          </Visible>
        }
      />
      <Invites />
    </>
  );
};
