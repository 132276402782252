//@flow

import { type MemberType } from '@kwara/models/src';

import pick from 'lodash/fp/pick';
import get from 'lodash/fp/get';
import toLower from 'lodash/fp/toLower';
import {
  cameliseObjectKeys,
  type ErrorDetails,
  createErrorsFromApiResponse
} from '@kwara/models/src';
import type { ApiIdentityType } from '../lib/types';
import { type IdentityType } from '../lib/types';
import Auth from '../lib/auth';

import { type ConfigType } from '.';

function toIdentityType(identityType: IdentityType): ApiIdentityType {
  return toLower(identityType);
}

export type IDCheckResponse = {
  data: any,
  errors: ErrorDetails
};

// This should really be temporary, as at the moment Metropol result shapes are not consistent.
// We transform "dob" into date_of_birth (note date_of_birth is actually returned ATM from the API
// itself, but it appears to be empty every time)
function mapKeys(r) {
  return {
    ...r,
    data: {
      ...r.data,
      date_of_birth: get('data.dob', r)
    }
  };
}

export type CheckPayload = {
  identityType: IdentityType,
  identityValue: string,
  member: MemberType
};
export default class IDVerification {
  config: ConfigType;

  constructor({ config }: { config: ConfigType }) {
    this.config = config;
  }

  async check({
    identityType,
    identityValue: identity_value
  }: CheckPayload): Promise<IDCheckResponse> {
    try {
      const res = await fetch(`${this.config.API_ROOT}/identity_verification`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth.getRawToken()}`
        },
        method: 'POST',
        body: JSON.stringify({
          data: {
            attributes: {
              identity_type: toIdentityType(identityType),
              identity_number: identity_value
            }
          }
        })
      });

      const result = await res
        .json()
        .then(pick(['data', 'errors']))
        .then(r => ({
          ...r,
          errors: createErrorsFromApiResponse(r),
          data: get('data.attributes', r)
        }))
        .then(mapKeys)
        .then(cameliseObjectKeys);
      return { ...result };
    } catch (error) {
      return {
        data: {},
        errors: createErrorsFromApiResponse({ errors: [error] })
      };
    }
  }
}
