// @flow
import * as React from 'react';
import get from 'lodash/fp/get';

import { saveAttachments } from '@kwara/models/src/models/Attachment';
import { Member, type MemberType } from '@kwara/models/src';
import { Loadable, EMPTY } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';
import { memberPath } from '../../lib/urls';
import { steps } from './config';
import { type WizardPageProps } from '..';

export interface WizardData extends MemberType {}

const saveMember = async (member: WizardData) => {
  const success = await member.save({ with: ['addresses', 'idDocuments'] });
  if (!success) {
    throw member.errors;
  }
  const uploaded = await saveAttachments(member, member.attachments);
  if (!uploaded) {
    throw member.errors;
  }
};

const includes = ['addresses', 'next_of_kin', 'attachments', 'id_documents'];
function useMemberFormModel(id) {
  const result = useMember(id, includes);
  if (!id) {
    return { data: Member.new(), error: EMPTY, isPending: false };
  }
  return result;
}

export const MemberAdd = ({
  match,
  history,
  baseUrl
}: WizardPageProps<{ memberId: string }>) => {
  const result = useMemberFormModel(match.params.memberId);
  return (
    <Loadable {...result}>
      {member => (
        <Wizard
          analyticsId="AddMember"
          baseUrl={baseUrl}
          history={history}
          currentStep={match.params.step}
          initialData={member}
          currentSubStep={
            match.params.subStep != null
              ? parseInt(match.params.subStep, 10)
              : null
          }
          cancelReturnsTo={memberPath({ id: get('id', member) })}
          onSubmit={saveMember}
          steps={steps}
          startId="about"
          titleId="AddMember.title"
        />
      )}
    </Loadable>
  );
};
