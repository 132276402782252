import * as React from 'react';
import { withRouter } from 'react-router-dom';
import map from 'lodash/map';
import size from 'lodash/size';

import { MemberStates, type MemberState } from '@kwara/models/src';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import OverviewList from '../../../../components/OverviewList';
import MemberListTable from '../MemberListTable';
import Visible from '../../../../components/Visible';

import { AppPermissions } from '../../../../models';
import { memberPath } from '../../../../lib/urls';
import type { Member } from '../../../../lib/types';

type Props = {
  filterBy: ?MemberState,
  errors: Object[],
  hasMore?: boolean,
  loading?: boolean,
  onFilterChange: (filterBy: MemberState) => void,
  onLoadMoreData: () => void,
  items: Member[],
  totalNumResults: ?number
};

const MemberStateFilters = [
  { value: '', translationId: 'MemberList.noFilter' },
  ...map(MemberStates, value => ({
    value,
    translationId: `MemberStatus.${value}`
  }))
];

const labelId = (searchValue, loading, members = []) => {
  if (searchValue || loading) {
    return size(members) === 1
      ? 'MemberList.searchedCountSingular'
      : 'MemberList.searchedCount';
  }

  return 'MemberList.filteredCount';
};

// TODO: What's the best way to handle application-level
//       routing?
const AddMemberButton = () => (
  <Visible to={AppPermissions.AddMembers}>
    <Button size="regular" type="primary" to={memberPath({ action: 'create' })}>
      <Text id="MemberList.addMember" />
    </Button>
  </Visible>
);

export default withRouter(
  ({
    hasMore = false,
    loading = false,
    members = [],
    errors,
    filterBy,
    onLoadMoreData,
    onFilterChange,
    onSearchChange,
    searchValue,
    totalNumResults
  }: Props) => (
    <OverviewList
      actions={[<AddMemberButton key="add-member-button" />]}
      headerId="MemberList.heading"
      labelId={labelId(searchValue, loading, members)}
      filters={MemberStateFilters}
      filterBy={filterBy}
      filterDisabled={loading}
      onFilterChange={onFilterChange}
      onSearchChange={onSearchChange}
      searchPlaceholderId="MemberList.searchPlaceholder"
      searchValue={searchValue}
      items={members}
      table={
        <MemberListTable
          groupBy={/*Groups.state*/ null}
          hasMore={hasMore}
          members={members}
          orderBy={/*Orders.updated*/ null}
          onLoadMoreData={onLoadMoreData}
          loading={loading}
          errors={errors}
        />
      }
      totalNumResults={totalNumResults}
    />
  )
);
