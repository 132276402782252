//@flow
import * as React from 'react';
import classnames from 'classnames';

import { Text } from '@kwara/components/src/Intl';

import { Inbox } from '../Inbox';

import styles from './index.module.css';

type Props = {
  children: React.Node
};

const Overview = ({ children, actions }) => (
  <div className={classnames(styles.Overview, 'pv5 fl flex-auto')}>
    <div className="flex items-center mb3">
      <h2 className="mt0 dib kw-text-large kw-weight-bold">
        <Text id="Dashboard.overview" />
      </h2>
      {actions ? (
        <div className={`flex ml-auto ${styles.ActionsBar}`}>{actions}</div>
      ) : null}
    </div>
    {children}
  </div>
);

export default ({ children, actions, messages = [], inboxAction }: Props) => {
  return (
    <section className="flex">
      <Inbox messages={messages} action={inboxAction} />
      <Overview actions={actions}>{children}</Overview>
    </section>
  );
};
