//@flow

import * as React from 'react';

import { type SubStepComponentProps } from '../../../components/Wizard';
import { DatePickerInputs } from '@kwara/components/src/Form';

export default ({
  Condition,
  RadioGroup,
  StackChild,
  TextField,
  data
}: SubStepComponentProps<>) => (
  <StackChild>
    <RadioGroup
      name="gender"
      labelId="SelfService.Personal.Gender.label"
      items={[
        { labelId: 'SelfService.Personal.Gender.FEMALE', value: 'FEMALE' },
        { labelId: 'SelfService.Personal.Gender.MALE', value: 'MALE' }
      ]}
    />

    <DatePickerInputs
      name="dateOfBirth"
      placeholderId="SelfService.Personal.DateOfBirth.placeholder"
      labelId="SelfService.Personal.DateOfBirth.label"
      errorBaseId="SelfService.Personal.DateOfBirth"
      initial={data.dateOfBirth}
    />

    <RadioGroup
      name="maritalStatus"
      labelId="SelfService.Personal.MaritalStatus.label"
      items={[
        {
          labelId: 'SelfService.Personal.MaritalStatus.SINGLE',
          value: 'SINGLE'
        },
        {
          labelId: 'SelfService.Personal.MaritalStatus.MARRIED',
          value: 'MARRIED'
        },
        { labelId: 'SelfService.Personal.MaritalStatus.OTHER', value: 'OTHER' }
      ]}
    />

    <Condition when="maritalStatus" is="OTHER">
      <TextField
        name="maritalStatusOther"
        placeholderId="SelfService.Personal.MaritalStatus.otherText.placeholder"
      />
    </Condition>
  </StackChild>
);
