import React from 'react';
import { Text, Image, View } from '@react-pdf/renderer';

import { DefaultText } from './Text';

import kwara from './kwara.png';

export const Footer = () => {
  return (
    <View
      fixed
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        fontSize: 8,
        paddingHorizontal: '15px',
        color: 'grey'
      }}
    >
      <View
        style={{
          lineHeight: '1.2rem',
          paddingBottom: '5px',
          borderBottom: true,
          borderBottomColor: 'black'
        }}
      >
        <DefaultText style={{ fontSize: 8 }} id="FooterPDF.disclaimer" />
      </View>
      <View
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          paddingVertical: 10
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end'
          }}
        >
          <DefaultText
            id="FooterPDF.poweredBy"
            style={{ fontSize: 8, marginBottom: 0 }}
          />
          <View style={{ width: 30, height: 10, marginHorizontal: '1px' }}>
            <Image src={kwara} />
          </View>
          <Text> </Text>
          <DefaultText
            id="FooterPDF.leadingSacco"
            style={{ fontSize: 8, marginBottom: 0 }}
          />
        </View>

        <Text
          style={{ fontSize: 12 }}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </View>
    </View>
  );
};
