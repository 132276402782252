// @flow

const headers = { 'Content-Type': 'application/json; charset=utf-8' };
export default class Api {
  constructor({ apiRoot }: { apiRoot: string } = {}) {
    if (typeof apiRoot !== 'string') {
      throw new Error('apiRoot must be provided in config object');
    }

    this.apiRoot = apiRoot;
  }

  apiRoot: string;

  async userToken({
    email,
    password
  }: {
    email: string,
    password: string
  } = {}): Promise<{ jwt: string }> {
    if (typeof email !== 'string') {
      throw Error('email must be provided');
    }

    if (typeof password !== 'string') {
      throw Error('password must be provided');
    }

    const url = `${this.apiRoot}/auth/user_token.json`;
    const body = {
      auth: {
        email,
        password
      }
    };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    });

    const res = await response.json();
    if (!response.ok) {
      throw res;
    }

    return res;
  }

  async deleteUserToken(token: string) {
    if (typeof token !== 'string') {
      throw new Error('token must be provided');
    }

    const url = `${this.apiRoot}/auth/user_token`;

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (response.ok) {
      return;
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  }

  async passwordForget(data) {
    const url = `${this.apiRoot}/password/forgot`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers
    });

    //TO DO: backend should return response.ok whether password reset email is sent or not
    // https://support.logmeininc.com/gotomypc/help/why-didnt-i-get-my-reset-password-email-1153288921

    if (response.status >= 500) {
      throw new Error(`API Error: ${response.statusText}`);
    }

    return;
  }

  async passwordReset(data) {
    const url = `${this.apiRoot}/password/reset`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers
    });

    if (!response.ok) {
      throw new Error(`API Error: ${response.statusText}`);
    }

    return await response.json();
  }

  async refreshUserToken(token: string) {
    if (typeof token !== 'string') {
      throw new Error('token must be provided');
    }

    const url = `${this.apiRoot}/auth/token_refresh`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  }

  async resendUnlockLink(email: string) {
    const url = `${this.apiRoot}/auth/resend_unlock_email`;
    const body = {
      data: {
        attributes: {
          email
        }
      }
    };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      // 2xx responses from this endpoint have no body,
      // so we just parse the body in case of error
      const err = await response.json();
      throw err;
    }

    return response;
  }

  async unlockAccount(token: string) {
    const url = `${this.apiRoot}/auth/unlock`;
    const body = {
      data: {
        attributes: {
          token
        }
      }
    };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      // 2xx responses from this endpoint have no body,
      // so we just parse the body in case of error
      const err = await response.json();
      throw err;
    }

    return response;
  }

  async confirmEmail(token: string) {
    if (typeof token !== 'string') {
      throw new Error('token must be provided');
    }
    const url = `${this.apiRoot}/confirm?token=${token}`;

    const response = await fetch(url, {
      method: 'GET',
      headers
    });

    if (response.ok) {
      return await response.json();
    }

    throw new Error(`API ERROR: ${response.statusText}`);
  }

  //Member app route
  async createNewMember(payload) {
    const url = `${this.apiRoot}/auth/signup`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response.json();
  }

  // Member app route
  async resendInvitation(email: string) {
    const url = `${this.apiRoot}/auth/signup/resend`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        data: {
          attributes: {
            email
          }
        }
      }),
      headers
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response.json();
  }

  async createNewUser(payload) {
    const url = `${this.apiRoot}/signup`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      throw new Error(`API ERROR: ${response.statusText}`);
    }

    return await response.json();
  }

  async tillToken(token: string) {
    const url = `${this.apiRoot}/tills`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (response.ok) {
      return true;
    }

    throw new Error(`API ERROR: ${response.statusText}`);
  }

  async removeTillToken(token: string) {
    const url = `${this.apiRoot}/tills`;

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (response.ok) {
      return true;
    }

    throw new Error(`API ERROR: ${response.statusText}`);
  }

  async confirmOrganisation(token: string) {
    const url = `${this.apiRoot}/join?t=${token}`;

    const response = await fetch(url, {
      method: 'GET',
      headers
    });

    if (response.ok) {
      return await response.json();
    }

    throw new Error(`API ERROR: ${response.statusText}`);
  }
}
