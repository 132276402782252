// @flow

import { attr } from 'spraypaint';
import find from 'lodash/fp/find';

import Base from './Base';

const Metric = Base.extend({
  static: {
    jsonapiType: 'metrics',
    findFromArray: function(all, id) {
      return find({ id }, all);
    }
  },
  attrs: {
    name: attr(),
    value: attr(),
    context: attr()
  },
  methods: {}
});

export type MetricType = Metric;

export default (Metric: typeof Metric);
