// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { type SavingType } from '@kwara/models/src';
import { fields as savingFields } from '@kwara/models/src/models/Saving';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import {
  Bank as BankName,
  BankBranch as BankBranchName
} from '@kwara/components/src/Bank';

import { Remittance as RemittanceForm } from '../../SavingAdd/components/Remittance';
import { EditableSection, Updater } from '../../../components/EditableSection';
import { excludedRemittanceFields } from '../../../lib/modelUtils/saving';
import { Currency } from '../../../components/Currency';

type Props = {
  saving: SavingType,
  refetch: () => void
};

export const Content = ({
  saving,
  className = ''
}: {
  saving: SavingType,
  className?: string
}) => {
  const excludedField = excludedRemittanceFields(saving);

  const bankCode = get(savingFields.remittance.bank, saving);
  const branchCode = get(savingFields.remittance.branch, saving);
  const collectingBankCode = get(
    savingFields.remittance.collectingBank,
    saving
  );
  return (
    <div className={className}>
      <StatisticRow widthClass="w-100" compact>
        <Statistic
          title={<Text id="SavingDetail.Remittance.method" />}
          value={
            <Text
              id={`TransactionMethod.${get(
                savingFields.remittance.method,
                saving
              )}`}
            />
          }
        />
        <Statistic
          title={<Text id="SavingDetail.Remittance.amount" />}
          value={<Currency value={saving.monthlyRemittanceAmount} />}
        />
      </StatisticRow>

      <StatisticRow widthClass="w-50" compact>
        {excludedField(savingFields.remittance.bank) ? null : (
          <Statistic
            title={<Text id="SavingDetail.Remittance.bank" />}
            value={<BankName bankCode={bankCode} />}
          />
        )}
        {excludedField(savingFields.remittance.branch) ? null : (
          <Statistic
            title={<Text id="SavingDetail.Remittance.branch" />}
            value={
              <BankBranchName bankCode={bankCode} branchCode={branchCode} />
            }
          />
        )}
        {excludedField(savingFields.remittance.account) ? null : (
          <Statistic
            title={<Text id="SavingDetail.Remittance.account" />}
            value={get(savingFields.remittance.account, saving)}
          />
        )}

        {excludedField(savingFields.remittance.collectingBank) ? null : (
          <Statistic
            title={<Text id="SavingDetail.Remittance.collectingBank" />}
            value={<BankName bankCode={collectingBankCode} />}
          />
        )}

        {/* REMITTANCE_METHOD === PAYROLL DEDUCTION */}
        {excludedField(
          'payrollDeductionDetails.payroll_deduction_employer_name'
        ) ? null : (
          <Statistic
            title={<Text id="SavingDetail.Remittance.employer" />}
            value={get(
              'payrollDeductionDetails.payroll_deduction_employer_name',
              saving
            )}
          />
        )}
        {excludedField(
          'payrollDeductionDetails.payroll_deduction_staff_id'
        ) ? null : (
          <Statistic
            title={<Text id="SavingDetail.Remittance.staffId" />}
            value={get(
              'payrollDeductionDetails.payroll_deduction_staff_id',
              saving
            )}
          />
        )}
      </StatisticRow>
    </div>
  );
};

export function Remittance({ saving, refetch }: Props) {
  return (
    <Updater value={{ onUpdate: refetch }}>
      <EditableSection
        initialData={saving}
        config={{
          Component: RemittanceForm,
          validate: RemittanceForm.validateConfig.savingEdit
        }}
      >
        <Content saving={saving} />
      </EditableSection>
    </Updater>
  );
}
