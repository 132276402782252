// @flow

import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import { UserTypes } from '@kwara/models/src/models/userTypes';

export const isDev: boolean = process.env.NODE_ENV === 'development';
export const isProd: boolean = process.env.NODE_ENV === 'production';
export const isTest: boolean = process.env.NODE_ENV === 'test';

// This is hacky and should really be an environment variable set on Netlify's process, but... YOLO
const hostname = get('window.location.hostname', global);
export const isSandbox: boolean = includes('.sandbox.', hostname);

export const appName = {
  type: '',
  set current(type) {
    this.type = type;
  },
  get isMember() {
    return this.type === UserTypes.MEMBER;
  }
};
