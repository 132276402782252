// @flow

import React from 'react';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/fp/isEmpty';
import forEach from 'lodash/fp/forEach';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import invoke from 'lodash/fp/invoke';

import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import { Checkbox as BasicCheckbox } from '@kwara/components/src/Form';
import Banner from '@kwara/components/src/Banner';

import { ProfileContext } from '../../../../../models/Profile';
import { useAppRoles } from '../../../../../models/request';
import { EditableSection } from '../../../../../components/EditableSection';
import { Grid } from '../../../../../components/Grid';

import { DetailWrapper, Title, type UserT } from '..';
import { SubscribedDatePicker } from '../../../../../components/Form';

// AppRoles object to {roleId:  boolean} shape
// [ {id: "1", name: "Maker" }, {id: "2", name: "Checker" } ]
// =>
// { 1: {val: true, name: "Maker"}, 2: {val: true, name: "Checker"} }
function getInitialState(user) {
  const { appRoles } = user;
  const state = {};
  forEach(appRole => {
    state[appRole.id] = { val: true, name: appRole.name };
  }, appRoles);
  return state;
}

const RolesForm = ({ StackChild, data }) => {
  const store = React.useContext(ProfileContext);
  const r = useAppRoles();

  const { user, formState, setFormState } = data;
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isPending, setIsPending] = React.useState(false);

  const isCurrentUser = get('profile.id', store) === user.id;

  // This function sets the boolean for the given roleId,
  // and looks up the name from the full list (so we can display)
  const setCheckbox = (roleId: string, val: boolean) => {
    const allAppRoles = r.data;
    const appRole = find(r => r.id === roleId, allAppRoles);

    return setFormState({
      ...formState,
      [roleId]: { name: get('name', appRole), val }
    });
  };

  const getChecked = (roleId: string) => {
    return !!get([roleId, 'val'], formState);
  };

  const triggerSuccess = () => {
    setSuccess(true);

    // If the user is changing his/her own permissions,
    // then we want to refetch the profile, so the UI elements
    // can show/hide according to the changes.

    // If the user changes another user's permissions, this instant
    // refetch is not necessary.
    if (isCurrentUser) {
      invoke('refetch', store);
    }

    setTimeout(() => setSuccess(false), 1000);
  };

  const triggerError = () => {
    setError(true);
    setTimeout(() => setError(false), 1000);
  };

  async function onChange(e) {
    setIsPending(true);
    const roleId = get('target.name', e);
    const isChecked = getChecked(roleId);

    if (isChecked) {
      try {
        await user.unAssignRole(roleId);
        setCheckbox(roleId, false);
        triggerSuccess();
      } catch (e) {
        setCheckbox(roleId, true);
        triggerError();
      }
    } else if (!isChecked) {
      try {
        await user.assignRole(roleId);
        setCheckbox(roleId, true);
        triggerSuccess();
      } catch (e) {
        setCheckbox(roleId, false);
        triggerError();
      }
    }

    setIsPending(false);
  }

  return (
    <StackChild>
      <div className="h3">
        {success ? (
          <Banner
            size
            text={<Text id="UserEdit.Roles.onSuccess" />}
            state="success"
          />
        ) : null}
        {error ? (
          <Banner
            size
            text={<Text id="UserEdit.Roles.onError" />}
            state="error"
          />
        ) : null}
      </div>
      <Loadable {...r}>
        {appRoles => {
          return (
            <>
              {map(appRole => {
                const isChecked = getChecked(appRole.id);

                return (
                  <BasicCheckbox
                    key={appRole.id}
                    size="regular"
                    labelText={appRole.name}
                    name={appRole.id}
                    onChange={onChange}
                    checked={isChecked}
                    disabled={isPending}
                  />
                );
              }, appRoles)}
              <div className="mt3">
                <Grid columns={2} width="w-50" border={false}>
                  <SubscribedDatePicker
                    margin={false}
                    labelId="valid from"
                    name="from"
                  />
                  <SubscribedDatePicker
                    margin={false}
                    labelId="valid to"
                    name="to"
                  />
                </Grid>
              </div>
            </>
          );
        }}
      </Loadable>
    </StackChild>
  );
};

const config = {
  Component: RolesForm,
  hideActions: true
};

type StateT = {
  [id: string]: {
    val: boolean,
    name: string
  }
};

export const Roles = ({
  user,
  isEditable
}: {
  user: UserT,
  isEditable: boolean
}) => {
  const store = React.useContext(ProfileContext);
  const isAdmin = get(get('profile.isAdmin', store));
  // We keep track of state here to avoid having to refetch any data
  // each time a user selects/deselects a role.
  // Unfortunately, this requires we pass in these functions
  // as `initialData` in a rather unconventional way.
  const [formState, setFormState] = React.useState<StateT>(
    getInitialState(user)
  );

  // If the user is refetched, we
  // should update the formState.
  React.useEffect(() => {
    setFormState(getInitialState(user));
  }, [user]);

  // When not in edit mode, only show the roles that are
  // true, based on the form activity.
  const appRoles = filter(r => r.val, Object.values(formState));

  const content = isEmpty(appRoles) ? (
    <span className="grey-400">-</span>
  ) : (
    map(
      r => (
        <div key={r.name} className="grey-400">
          {r.name}
        </div>
      ),
      appRoles
    )
  );

  return (
    <DetailWrapper>
      <Title titleId={'Settings.Personal.Roles.title'} classNames="mb1" />
      <EditableSection
        config={config}
        initialData={{ user, formState, setFormState }}
        actions={[]}
        readOnly={!isEditable || !isAdmin}
        overflowScroll={false}
        canCancel={!isAdmin}
      >
        {content}
      </EditableSection>
    </DetailWrapper>
  );
};
