//@flow
import React from 'react';
import { FormattedDate } from 'react-intl';
import cx from 'classnames';

import styles from './Date.module.css';

type Props = {
  value: Date,
  size?: 'small'
};

export default ({ value, size }: Props) => {
  const classNames = cx([
    styles.Numeric,
    { 'kw-text-small kw-weight-light': size === 'small' }
  ]);

  return (
    <span className={classNames}>
      {value == null ? '-' : <FormattedDate format="short" value={value} />}
    </span>
  );
};
