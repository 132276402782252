//@flow
import * as React from 'react';
import get from 'lodash/fp/get';

import { Collateral } from './Collateral';
import { Guarantor } from './Guarantor';
import { type LoanSubstepProps } from '../..';

const guarantorKey = index => `guarantors[${index}].amount`;

export const Security = (props: LoanSubstepProps) => {
  return (
    <>
      <Guarantor {...props} />
      <Collateral {...props} />
    </>
  );
};

Security.validate = ({ guarantors }) => {
  if (guarantors != null) {
    const validations = {};

    guarantors.forEach((_, index) => {
      validations[guarantorKey(index)] = {
        isRequired: (_, allData) =>
          !get(`guarantors.[${index}].isMarkedForDestruction`, allData),
        // We (temporarily) allow members to "overguarantee", see:
        // https://app.clubhouse.io/getkwara/story/10186/remove-restrictions-on-guarantor-addition-even-if-they-are-over-guaranteed
        // max: member.standing,
        currency: true,
        nonZero: true
      };
    });

    return validations;
  }

  return {};
};
