// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

type CreditT = {
  credit_account: string,
  credit_amount: string
};

export const JournalEntry = Base.extend({
  static: {
    jsonapiType: 'journal_entries'
  },
  attrs: {
    debitAccount: attr(),
    debitAmount: attr(),
    credits: attr(),
    date: attr()
  }
});

export interface JournalEntryT extends BaseModel<JournalEntryT> {
  debitAccount: string;
  debitAmount: string;
  credits: CreditT[];
  date: Date;
}
