//@flow

import * as React from 'react';
import size from 'lodash/fp/size';

import { Record, Section } from '../../../components/Summary';
import { type SubStepComponentProps } from '../../../components/Wizard';

export const Summary = ({ data = {}, StackChild }: SubStepComponentProps<>) => {
  return (
    <StackChild>
      <Section titleId="Onboarding.Step.Summary.Overview">
        <Record labelId="Registered name" value={data.registeredName} />
        <Record labelId="Physical address" value={data.physicalAddress} />
        <Record labelId="Postal address" value={data.postalAddress} />
        <Record labelId="Tax Pin" value={data.taxPin} />
        <Record labelId="Registered CRK" value={data.registeredCRK} />
      </Section>

      <Section titleId="Configuration">
        <Record
          labelId="Onboarding.Step.LoanProductsMain.title"
          value={size(data.loanProducts)}
        />
        <Record
          labelId="Onboarding.Step.SavingsProductsMain.title"
          value={size(data.savingsProducts)}
        />
        <Record
          labelId="Onboarding.Step.RolesMain.title"
          value={size(data.roles)}
        />
        <Record
          labelId="Onboarding.Step.UsersMain.title"
          value={size(data.users)}
        />
      </Section>
    </StackChild>
  );
};
