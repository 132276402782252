//@flow

import * as React from 'react';

import { type TopupRequestT } from '@kwara/models/src';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { DateTime, Text } from '@kwara/components/src/Intl';

import { Denominations } from '../../../components/Denominations';
import { Panel } from '../../../components/ActionModal';
import { Currency } from '../../../components/Currency';
import { type SubStepComponentProps } from '../../../components/Wizard';

export const Review = ({
  StackChild,
  data
}: SubStepComponentProps<TopupRequestT>) => {
  const { amount } = data;
  return (
    <StackChild>
      <Panel>
        <StatisticRow widthClass="w-50">
          <Statistic
            title={<Text id="TopupReview.Review.tellerName" />}
            size="large"
            value={data.till.tellerName}
          />
          <Statistic
            size="large"
            title={<Text id="TopupReview.Review.amount" />}
            value={<Currency value={amount} format="currency" />}
          />
        </StatisticRow>

        <StatisticRow widthClass="w-50">
          <Statistic
            title={<Text id="TopupReview.Review.tillId" />}
            size="large"
            value={data.till.id}
          />
          <Statistic
            title={<Text id="TopupReview.Review.date" />}
            size="large"
            value={<DateTime value={data.updatedAt} />}
          />
        </StatisticRow>
      </Panel>
      <Panel>
        <Denominations amount={amount} />
      </Panel>
    </StackChild>
  );
};
