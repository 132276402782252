//@flow
import React from 'react';
import { FormattedTime } from 'react-intl';

import styles from './Date.module.css';

type Props = {
  value: Date
};

export const Time = ({ value }: Props) => (
  <span className={styles.Numeric}>
    {value == null ? '-' : <FormattedTime value={value} />}
  </span>
);
