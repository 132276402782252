import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import logo from '@kwara/components/src/Logo/logo.svg';

import styles from './index.module.scss';

export const Footer = () => (
  <p className="grey-400">
    <Text
      id="General.PoweredBy"
      values={{
        logo: <img alt="Kwara Logo" className={styles.logo} src={logo} />
      }}
    />
  </p>
);
