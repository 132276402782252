import * as React from 'react';
import { Field as FinalFormField } from 'react-final-form';

import { type TranslationId } from '@kwara/components/src/Intl';
import type { InputProps, FieldProps } from '@kwara/components/src/Form';
import { TextField, Field } from '.';

type SubscribedTextFieldProps = InputProps &
  FieldProps & {
    leftGlyph?: string,
    rightGlyph?: string,
    placeholderId?: TranslationId
  };

const SubscribedTextField = ({
  compact = false,
  disabled,
  errorBaseId,
  errorValues = {},
  hidden,
  info,
  infoId,
  initialValue,
  labelId,
  leftGlyph,
  margin,
  max,
  min,
  name,
  inputOnBlur,
  placeholderId,
  required,
  rightGlyph,
  rightAction,
  titleId,
  type = 'text',
  values,
  ...rest
}: SubscribedTextFieldProps) => (
  <FinalFormField
    name={name}
    type={type}
    size="medium"
    placeholderId={placeholderId}
    infoId={infoId}
    initialValue={initialValue}
    render={({ input, meta }) => {
      if (!meta.visited && initialValue) {
        input.onChange(initialValue);
      }
      const showError = !!(meta.touched && meta.error);

      return (
        <Field
          compact={compact}
          margin={margin}
          disabled={disabled}
          error={showError}
          name={input.name}
          size="medium"
          titleId={titleId}
          required={required}
          hidden={hidden}
          labelId={labelId}
          errorBaseId={errorBaseId}
          errorCodes={meta.error}
          infoId={infoId}
          values={{ ...values, ...errorValues }}
          info={info}
        >
          <TextField
            disabled={disabled}
            error={showError}
            leftGlyph={leftGlyph}
            max={max}
            min={min}
            placeholderId={placeholderId}
            rightGlyph={rightGlyph}
            rightAction={rightAction}
            size="medium"
            type={type}
            values={values}
            inputOnBlur={inputOnBlur}
            {...input}
            {...rest}
          />
        </Field>
      );
    }}
  />
);

export default SubscribedTextField;
