// @flow

import * as React from 'react';
import classnames from 'classnames';

import { NotFound404 } from '@kwara/components/src/NotFound404';

import styles from './index.module.css';

type Props = {
  className?: string,
  children: React.Node,
  flexible: boolean,
  navigation: React.Node,
  notificationBanner: ?React.Node,
  zIndexClassName: string,
  fullPath: string
};

class ErrorBoundary extends React.Component<
  { children: React.Node, fullPath: string },
  {
    error: boolean
  }
> {
  state = { error: false };

  componentDidCatch() {
    // TODO: log this error to Rollbar
    this.setState({ error: true });
  }

  componentDidUpdate(prev) {
    // Make sure changing page will hide the error and retry rendering
    if (prev.fullPath !== this.props.fullPath) {
      this.setState({ error: false });
    }
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    return error ? <NotFound404 /> : children;
  }
}

const AppLayout = ({
  className,
  children,
  flexible = false,
  navigation,
  notificationBanner,
  zIndexClassName = '',
  fullPath
}: Props) => (
  <div
    className={classnames(
      styles.container,
      flexible ? styles.flexible : styles.inflexible,
      'flex flex-column',
      className
    )}
  >
    {notificationBanner ? notificationBanner : null}
    <div
      className={classnames(
        styles.navigation,
        'bb b--light-grey-500',
        zIndexClassName
      )}
    >
      <div className={styles.navigationInner}>{navigation}</div>
    </div>
    <div className={styles.content}>
      <ErrorBoundary fullPath={fullPath}>{children}</ErrorBoundary>
    </div>
  </div>
);

export default AppLayout;
