// @flow

import React from 'react';
import { observer } from 'mobx-react';
import { Text } from '@kwara/components/src/Intl';

import { ProfileContext } from '../../../models/Profile';
import { NoteContainer, NoteItem, NoteTitle } from '../../../components/Note';

export const JoiningFeePayment = observer(({ StackChild, TextField, data }) => {
  const store = React.useContext(ProfileContext);
  const { branch } = store.profile;

  // TO DO: Delete these default values (KBS's) once
  // the branch information includes the businessNumber
  const { businessNumber = '400444', joiningFeeAmount = 500 } = branch;
  const { firstName, lastName } = data;

  return (
    <StackChild>
      <h2 className="kw-text-extra-large kw-weight-bold mb0">
        <Text id="AddMember.JoiningFee.section.title" />
      </h2>
      <p className="kw-text-regular grey-400">
        <Text
          id="AddMember.JoiningFee.section.text"
          values={{ amount: joiningFeeAmount }}
        />
      </p>
      <NoteContainer>
        <NoteTitle titleId="AddMember.JoiningFee.instructions" />
        <NoteItem
          notes={<Text id="AddMember.JoiningFee.instructions.step1" />}
        />
        <NoteItem
          notes={<Text id="AddMember.JoiningFee.instructions.step2" />}
        />
        <NoteItem
          notes={
            <Text
              id="AddMember.JoiningFee.instructions.step3"
              values={{ businessNumber }}
            />
          }
        />
        <NoteItem
          notes={
            <Text
              id="AddMember.JoiningFee.instructions.step4"
              values={{ firstName, lastName }}
            />
          }
        />
        <NoteItem
          notes={
            <Text
              id="AddMember.JoiningFee.instructions.step5"
              values={{
                amount: joiningFeeAmount
              }}
            />
          }
        />
      </NoteContainer>
      <TextField
        required
        name="joiningFeeReference"
        labelId="AddMember.joiningFee.Reference.label"
      />
    </StackChild>
  );
});

JoiningFeePayment.validate = {
  joiningFeeReference: {
    isRequired: () => true
  }
};
