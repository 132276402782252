// @flow

import { ProductAdd } from './components/ProductAdd';

export const steps = {
  product: {
    titleId: 'SavingProductAdd.Product.titleId',
    Component: ProductAdd,
    validate: ProductAdd.validate,
    actions: [{ appearsAs: 'submit', behavesAs: 'complete' }]
  }
};
