import React from 'react';
import { FormSpy } from 'react-final-form';
import find from 'lodash/fp/find';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import get from 'lodash/fp/get';

const loanPurposes = [
  { title: 'AMALGAMATION', specificationRequired: false },
  { title: 'BUSINESS', specificationRequired: true },
  { title: 'CAR_PURCHASE', specificationRequired: false },
  { title: 'CONSTRUCTION', specificationRequired: false },
  { title: 'EDUCATION', specificationRequired: false },
  { title: 'HOUSE_DEVELOPMENT', specificationRequired: false },
  { title: 'HOUSE_PURCHASE', specificationRequired: false },
  { title: 'LAND_DEVELOPMENT', specificationRequired: false },
  { title: 'LAND_PURCHASE', specificationRequired: false },
  { title: 'MEDICAL', specificationRequired: false },
  { title: 'RESTRUCTURE', specificationRequired: false },
  { title: 'WEDDING', specificationRequired: false },
  { title: 'OTHER', specificationRequired: true }
];

const checkIfRequiresSpecification = (value, purposes) =>
  pipe(
    find(purpose => get('title', purpose) === value),
    get('specificationRequired')
  )(purposes);

export const LoanPurposeFields = ({ SelectField, TextField }) => {
  return (
    <div className="mv3">
      <FormSpy>
        {({ values }) => {
          const { purpose } = values;
          return (
            <>
              <SelectField
                name="purpose"
                labelId="LoanAdd.Configure.Purpose.label"
                required={true}
              >
                <SelectField.Option
                  translationId="LoanAdd.Configure.Purpose.NONE"
                  value=""
                />

                {map(purpose => {
                  return (
                    <SelectField.Option
                      translationId={`LoanAdd.Configure.Purpose.${purpose.title}`}
                      value={purpose.title}
                      key={purpose.title}
                    />
                  );
                }, loanPurposes)}
              </SelectField>

              {checkIfRequiresSpecification(purpose, loanPurposes) ? (
                <TextField
                  margin={false}
                  name="specification"
                  placeholderId="LoanAdd.Configure.Purpose.Specification.placeholder"
                  labelId="LoanAdd.Configure.Purpose.Specification.label"
                  required={true}
                />
              ) : null}
            </>
          );
        }}
      </FormSpy>
    </div>
  );
};

LoanPurposeFields.validate = {
  purpose: {
    isRequired: () => true
  },
  specification: {
    isRequired: (_, allData) => {
      const { purpose } = allData;
      return checkIfRequiresSpecification(purpose, loanPurposes);
    }
  }
};
