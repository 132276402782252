// @flow

import { attr, hasOne, hasMany } from 'spraypaint';

import Base from './Base';
import { BranchAddress, type BranchAddressT } from './BranchAddress';
import {
  TransactionChannel,
  type TransactionChannelT
} from './TransactionChannel';

export const Branch = Base.extend({
  static: {
    jsonapiType: 'branches'
  },
  attrs: {
    name: attr(),
    state: attr(),
    phoneNumber: attr(),
    email: attr(),
    address: hasOne({ type: BranchAddress }),
    transactionChannels: hasMany({ type: TransactionChannel }),
    businessNumber: attr(),
    joiningFeeAmount: attr()
  },
  methods: {}
});

export type BranchT = {
  name: string,
  state: string,
  phoneNumber: string,
  email: string,
  branchAddress: BranchAddressT,
  transactionChannels: TransactionChannelT[],
  businessNumber: string,
  joiningFeeAmount: number
};
