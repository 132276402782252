// @flow

import * as React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import includes from 'lodash/fp/includes';

import { type UserT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { type ComponentProps } from '@kwara/components/src/Wizard/SubStep';

import { ProfileContext } from '../../../../models/Profile';

import { Avatar } from './components/Avatar';
import { Email } from './components/Email';
import { Name } from './components/Name';
import { MambuRole } from './components/MambuRole';
import { Roles } from './components/Roles';
import { Password } from './components/Password';
import { PhoneNumber } from './components/PhoneNumber';
import { Deactivate } from './components/Deactivate';

import { Header } from '../Header';

export type { UserT } from '@kwara/models/src';
export type SettingProps = ComponentProps<{}>;

export const Title = ({
  titleId,
  classNames
}: {
  titleId: string,
  classNames?: string
}) => (
  <h4 className={cx('kw-text-large kw-weight-bold mt0 mb0', classNames)}>
    {titleId ? <Text id={titleId} /> : null}
  </h4>
);

export const DetailWrapper = ({ children }: { children: React.Node }) => (
  <div className="pv4 bb b--light-grey-400">{children}</div>
);

export const Detail = ({
  titleId,
  value
}: {
  titleId: string,
  value: string
}) => {
  return (
    <>
      <Title titleId={titleId} classNames="mb1" />
      <span className="grey-400">{value || '-'}</span>
    </>
  );
};

type SupportedSectionsT = 'roles';
export const ProfileContent = ({
  user,
  editableSections = []
}: {
  user: UserT,
  editableSections?: SupportedSectionsT[]
}) => {
  const canEdit = section => includes(section, editableSections);

  return (
    <>
      <Avatar />
      <Name user={user} />
      <Roles user={user} isEditable={canEdit('roles')} />
      <MambuRole user={user} />
      <PhoneNumber user={user} />
      <Email user={user} />
      <Deactivate user={user} />
    </>
  );
};

export const Personal = observer(() => {
  const store = React.useContext(ProfileContext);
  const { profile: user } = store;

  return (
    <>
      <Header
        titleId="Settings.Personal.title"
        subtitleId="Settings.Personal.subtitle"
      />
      <ProfileContent user={user} />
      <Password />
    </>
  );
});
