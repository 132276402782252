import map from 'lodash/fp/map';

import { Text, Currency } from './Intl';
import Redeemer from './InvitationRedeem/components/Redeemer';

// When we want to share between the 2 apps a component that needs access to the store
// (that is app-specific) we pass it to this function so the correct store is passed

export function configureStore(component, store) {
  component.appStore = store;
  return component;
}

// List here which component should be passed the store
const components = [Currency, Text, Redeemer];

export function configureStores(store) {
  return map(c => configureStore(c, store), components);
}
