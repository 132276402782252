//@flow

import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

type Handler = () => void;

type Props = {
  disabled: boolean,
  onAdd: Handler
};

export default ({ disabled, onAdd }: Props) => (
  <div className="pb4 flex justify-between items-center">
    <div>
      <h3 className="mb1">
        <Text id="AddMember.Kin.header" />
      </h3>
      <p className="mt1 grey-400">
        <Text id="AddMember.Kin.subheader" />
      </p>
    </div>

    <Button
      className="flex-none ml3"
      disabled={disabled}
      size="regular"
      type="secondary"
      onClick={onAdd}
    >
      <Text id="AddMember.Kin.add" />
    </Button>
  </div>
);
