//@flow
import * as React from 'react';

import { Statistic } from '@kwara/components/src/Statistic';
import { Text } from '@kwara/components/src/Intl';

import { Note } from '../../../../components/Note';
import { type SubStepComponentProps } from '../../../../components/Wizard';
import PersonalDetails, { Header } from '../PersonalDetails';

import { type WizardData } from '../..';

export const Review = ({
  data: { member },
  StackChild,
  onChange,
  Checkbox
}: SubStepComponentProps<WizardData>) => (
  <StackChild>
    {member.notes && <Note items={[{ notes: member.notes }]} />}
    <PersonalDetails member={member} onChange={onChange} />
    <Header>
      <Text id="MemberApprove.joiningFee" />
    </Header>
    <Statistic
      title={<Text id="MemberApprove.JoiningFee.reference" />}
      value={member.joiningFeeReference}
    />
    <Checkbox
      required
      name="joiningFeeReceived"
      labelId="MemberApprove.JoiningFee.labelId"
    />
  </StackChild>
);

Review.validate = {
  joiningFeeReceived: {
    isRequired: () => true
  }
};
