// @flow

export { AppRoleWizard } from './AppRoleWizard';
export { BatchTransactionImports } from './BatchTransactionImports';
export {
  UploadAdd as BatchTransactionImportsUpload
} from './BatchTransactionImports/components/UploadAdd';
export { BatchUploads } from './BatchUploads';
export { ConfirmEmail } from './SignUp/components/ConfirmEmail';
export { CreditSubmit } from './CreditSubmit';
export { default as Debug } from './Debug';
export { Finance } from './Finance';
export { default as InvitationRedeem } from './InvitationRedeem';
export { InviteUser } from './InviteUser';
export { JournalEntryAdd } from './JournalEntryAdd';
export { default as LoanAdd } from './LoanAdd';
export { default as LoanApprove } from './LoanApprove';
export { default as LoanDetail } from './LoanDetail';
export { LoanPayment } from './LoanPayment';
export { LoanPayOff } from './LoanPayOff';
export { default as LoanPenalty } from './LoanPenalty';
export { LoanProductAdd } from './LoanProductAdd';
export { LoanProductEdit } from './LoanProductEdit';
export { LoanSchedulePreview } from './LoanSchedulePreview';
export { LoanRefinance } from './LoanRefinance';
export { LoanReschedule } from './LoanReschedule';
export { LoanWriteOff } from './LoanWriteOff';
export { LoanRouteDecider } from './LoanRouteDecider';
export { default as LogIn } from '@kwara/components/src/LogIn';
export { MakeTransfer } from './MakeTransfer';
export { default as MemberApprove } from './MemberApprove';
export { MemberClose } from './MemberClose';
export { MemberDetailRoute, MemberDetail } from './MemberDetail';
export { MemberDeposit } from './MemberDeposit';
export { MemberRepayment } from './MemberRepayment';
export { MemberReportGenerate } from './MemberReportGenerate';
export { default as SavingAdd } from './SavingAdd';
export { SavingClose } from './SavingClose';
export { SavingProductAdd } from './SavingProductAdd';
export { SavingProductEdit } from './SavingProductEdit';
export { default as SavingDetail } from './SavingDetail';
export { SavingPayment } from './SavingPayment';
export { default as SelfService } from './SelfService';
export { Settings } from './Settings';
export { default as SignUp } from './SignUp';
export { LoanDisburse } from './LoanDisburse';
export { Loans } from './Loans';
export { MemberAdd } from './MemberAdd';
export { Members } from './Members';
export { MemberWithdrawal } from './MemberWithdrawal';
export { Onboarding, OnboardingWizard } from './Onboarding';
export { SavingPenalty } from './SavingPenalty';
export { Savings } from './Savings';
export { Success } from './SignUp/components/Success';
export { Tellers } from './Tellers';
export { Till } from './Till';
export { TillDetail } from './TillDetail';
export { TopupReview } from './TopupReview';
export { TopupRequest } from './TopupRequest';
export { TransactionDetail } from './TransactionDetail';
export { UserEdit } from './UserEdit';

export type LinkContext = {
  backLabel?: string,
  useBrowserBack?: boolean
};

export type PageProps<RouteParams> = {
  baseUrl?: string,
  history: {
    goBack: () => void,
    push: (path: string, ctx?: ?LinkContext) => void,
    replaceState: (data: any, title: string, url: string) => void
  },
  match: {
    params: RouteParams
  }
};

export type ListPageProps = PageProps<{}>;

export type WizardPageProps<Params> = PageProps<
  {
    step: string,
    subStep: ?string
  } & Params
>;

export type MemberPageProps = PageProps<{
  memberId: string
}>;
export type LoanPageProps = PageProps<{ loanId: string }>;
export type SavingPageProps = PageProps<{ savingId: string }>;

export type DetailPageProps<RouteParams> = PageProps<RouteParams>;
