//@flow

import * as React from 'react';
import cx from 'classnames';

import { DateTime, Text } from '@kwara/components/src/Intl';

import styles from './index.module.css';

type NoteT = {
  timestamp?: Date,
  notes: string | React.Node
};

type Props = {
  items: NoteT[],
  titleId?: string
};

export const NoteItem = ({
  notes,
  className
}: {
  notes?: string | React.Node,
  className?: string
}) =>
  notes ? (
    <blockquote
      className={cx(
        `kw-text-regular bl b--indigo-100`,
        styles.NoteItem,
        className
      )}
    >
      {notes}
    </blockquote>
  ) : (
    '-'
  );

export const NoteTitle = ({ titleId }: { titleId: string }) => (
  <h3 className="ma0 mb3">
    <Text id={titleId} />
  </h3>
);

export const NoteContainer = ({ children }: { children: React.Node }) => (
  <div className={`${styles.Note} bg-semi-white-400`}>{children}</div>
);

export const Note = ({ items, titleId = 'MemberApprove.note' }: Props) => (
  <NoteContainer>
    <NoteTitle titleId={titleId} />
    {items.map(({ notes, timestamp }) => (
      <div key={String(timestamp)} className="mt3">
        {timestamp && (
          <time
            className="kw-text-small grey-400"
            dateTime={new Date(timestamp).toISOString()}
          >
            <DateTime value={timestamp} />
          </time>
        )}
        <NoteItem notes={notes} className="mt2" />
      </div>
    ))}
  </NoteContainer>
);
