// @flow

import * as React from 'react';

import { type MemberReportT } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';

import { useMemberReports } from '../../../../models/request';
import { usePagination } from '../../../../models/pagination';

import { settingPath } from '../../../../lib/urls';

import { Header } from '../Header';
import { ReportsTable } from './ReportsTable';

const includes = [];

export const Data = () => {
  const { allData, ...r } = usePagination<MemberReportT>(
    useMemberReports,
    currentPage => [includes, currentPage]
  );

  return (
    <>
      <Header
        titleId="Settings.Data.titleId"
        subtitleId="Settings.Data.subtitleId"
        action={
          <Button
            to={settingPath({ action: 'generate', baseExtension: 'data' })}
            type="primary"
          >
            <Text id="Settings.Data.generate" />
          </Button>
        }
      />
      <ReportsTable reports={allData} {...r} />
    </>
  );
};
