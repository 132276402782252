// @flow

import React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { DetailWrapper, Detail, type UserT } from '..';

export const Name = ({ user }: { user: UserT }) => {
  const value = user.isAdmin ? (
    <Text
      id="Settings.Personal.Name.admin"
      values={{ name: user.fullName() }}
    />
  ) : (
    user.fullName()
  );

  return (
    <DetailWrapper>
      <Detail titleId="Settings.Personal.Name.title" value={value} />
    </DetailWrapper>
  );
};
