//@flow
import * as React from 'react';

import { Loadable } from '@kwara/components/src';
import { useMember } from '@kwara/models/src/models/request/hooks';

import { MemberDetail } from '../..';
import { type SubStepComponentProps } from '../../../components/Wizard';

type CustomProps = {
  memberId: string,
  type: 'APPLICANT' | 'GUARANTOR'
};

const Member = ({
  customProps,
  history,
  StackChild
}: SubStepComponentProps<CustomProps>) => {
  const result = useMember(customProps.memberId, MemberDetail.includes);
  return (
    <StackChild size="stretch">
      <Loadable {...result}>
        {member => (
          <MemberDetail
            history={history}
            member={member}
            refetch={result.refetch}
            linkContext={{
              backLabel: 'Back to loan application',
              useBrowserBack: true
            }}
            showCommentCreate={false}
            showMargins={false}
            showOverviewActions={false}
            statusOverride={customProps ? customProps.type : null}
            readOnly
          />
        )}
      </Loadable>
    </StackChild>
  );
};

Member.Type = {
  applicant: 'APPLICANT',
  guarantor: 'GUARANTOR'
};

export default Member;
