import * as React from 'react';
import get from 'lodash/fp/get';

import { type SavingType } from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Statistic, PenaltyStatistic } from '@kwara/components/src/Statistic';
import StatusTag from '@kwara/components/src/StatusTag';

import { Card } from '../../../components/Card';
import { Currency } from '../../../components/Currency';

type Props = {
  account: SavingType,
  to: string
};

export const SavingsAccountCard = ({ account, to }: Props) => {
  const fees = get('fees.total', account);
  return (
    <Card
      header={account.product.name || 'Unnamed'}
      metadata={
        <>
          <StatusTag state={account.state.current} /> &middot; {account.id}
        </>
      }
      actionLabelId="SavingsAccountCard.action"
      isHoverable
      actionTo={to}
    >
      <Statistic
        compact
        title={<Text id="SavingsAccountCard.balance" />}
        size="small"
        value={<Currency value={account.balance} />}
      />
      <Statistic
        compact
        title={<Text id="SavingsAccountCard.accruedInterest" />}
        size="small"
        border={true}
        value={<Currency value={account.accruedInterest} />}
      />
      <PenaltyStatistic
        compact
        title={<Text id="SavingsAccountCard.fees" />}
        size="small"
        border={true}
        errorValue={fees}
      />
    </Card>
  );
};
