// @flow

import { attr } from 'spraypaint';

import Base from './Base';

export const TillStatuses = Object.freeze({
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING'
});

export type TillStatusT = $Values<typeof TillStatuses>;

export const Till = Base.extend({
  static: {
    jsonapiType: 'tills'
  },
  attrs: {
    createdAt: attr(),
    updatedAt: attr(),
    tellerName: attr(),
    tellerId: attr(),
    sessionId: attr(),
    status: attr(),
    openingAmount: attr(),
    currentAmount: attr(),
    closingAmount: attr()
  },
  methods: {}
});

export type TillT = {
  id: string,
  createdAt: string,
  updatedAt: string,
  tellerName: string,
  tellerId: string,
  sessionId: string,
  status: TillStatusT,
  openingAmount: number,
  currentAmount: number,
  closingAmount: number
};

export const CurrentTill = Till.extend({
  static: {
    jsonapiType: 'tills',
    endpoint: '/current_till'
  }
});
