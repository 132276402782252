//@flow
import * as React from 'react';

import { type NotificationType } from './Provider';

type Handler = (message: React.Node) => void;

export type ContextType = {
  displayError: Handler,
  displayWarning: Handler,
  displayMessage: Handler,
  dismiss: () => void
};

export type FullContextType = ContextType & {
  current: ?NotificationType
};

/*
  The Context that passes around the current notification
*/
// $FlowFixMe: there's a syntax error using React.createContext<FullContextType>({
const NotificationContext = React.createContext({
  current: null,
  displayError: () => {},
  displayWarning: () => {},
  displayMessage: () => {},
  dismiss: () => {}
});

export default NotificationContext;
