// @flow

import { attr, hasMany } from 'spraypaint';

import Base, { type BaseModel } from './Base';
import { AppRole, type AppRoleT } from './AppRole';
import { type UserT } from './User';

type ConfigSettingsT = {
  accrued_interest_enabled: boolean
};

export const Organisation = Base.extend({
  static: {
    jsonapiType: 'organisations'
  },
  attrs: {
    name: attr(),
    currency: attr(),
    users: hasMany(),
    appRoles: hasMany({ type: AppRole }),
    configSettings: attr()
  }
});

export interface OrganisationT extends BaseModel<OrganisationT> {
  currency: string;
  name: string;
  users: UserT[];
  appRoles: AppRoleT[];
  configSettings: ConfigSettingsT;
}
