//@flow
import * as React from 'react';
import cx from 'classnames';
import { injectIntl, type IntlShape } from 'react-intl';
import last from 'lodash/fp/last';

import {
  Text,
  type TranslationId,
  hasTranslation
} from '@kwara/components/src/Intl';

import styles from './Field.module.css';

export const formatError = (errorBaseId: string, errorCode: string) =>
  `${errorBaseId}.error.${errorCode}`;

export const defaultError = (errorCode: string) =>
  formatError('FormErrors', errorCode);

export const determineError = (
  errorBaseId?: string,
  errorCodes?: string[],
  intl: IntlShape
) => {
  if (!errorCodes) {
    return null;
  }

  // For the time being,
  // we pick only one error to show
  const errorCode = last(errorCodes);

  if (!errorBaseId) {
    return defaultError(errorCode);
  }
  const maybeTranslation = formatError(errorBaseId, errorCode);

  return hasTranslation(intl, maybeTranslation)
    ? maybeTranslation
    : defaultError(errorCode);
};

export const RequiredAsterisk = (props: { hidden?: boolean }) => (
  <span hidden={props.hidden} className={cx(['red-600', styles.required])} />
);

type Props = {
  children: React.Node,
  compact?: boolean,
  disabled?: boolean,
  error?: boolean,
  errorBaseId?: string,
  errorCodes?: string[],
  flex?: boolean,
  hidden?: boolean,
  info?: React.Node,
  infoId?: TranslationId,
  showInfo?: boolean,
  intl: IntlShape,
  labelId?: TranslationId,
  margin?: boolean,
  name?: string,
  required?: boolean,
  size?: 'regular' | 'medium',
  titleId?: TranslationId,
  values?: { [string]: mixed }
};

const CompactWrapper = (props: { children: React.Node }) => {
  return <div>{props.children}</div>;
};

type InfoWrapperProps = {
  info?: React.Node,
  infoId?: TranslationId,
  errorId?: TranslationId,
  error: boolean,
  values?: { [string]: mixed }
};

export const InfoWrapper = ({
  info,
  infoId,
  errorId,
  error,
  values
}: InfoWrapperProps) => {
  // The translationId should be set to either
  // an `errorId` (if there is an error)
  // or the `infoId` (if not)
  const translationId = error && errorId ? errorId : infoId;

  // Essentially, the logic below displays one of the following
  // props, in the following priority:
  // 1. errorId
  // 2. infoId
  // 3. info
  // depending on what is defined.
  return (
    <>
      {info != null || translationId != null ? (
        <span className={cx(styles.info, error ? 'red-500' : 'grey-400')}>
          {translationId != null ? (
            <Text id={translationId} values={values} />
          ) : (
            info
          )}
        </span>
      ) : null}
    </>
  );
};

export const Field = injectIntl(
  ({
    children = null,
    compact = false,
    disabled,
    error = false,
    errorBaseId,
    errorCodes,
    flex = true,
    hidden,
    info = null,
    infoId = null,
    showInfo = true,
    intl,
    labelId,
    margin = true,
    name,
    required,
    size,
    titleId = null,
    values
  }: Props) => {
    const containerClasses = [
      disabled ? 'o-60' : null,
      flex ? 'flex flex-column' : null,
      margin ? 'pb4' : null,
      'w-100'
    ];

    const titleTextClasses = [
      `pa0 ma0 kw-text-${size === 'regular' ? 'medium' : 'regular'}`
    ];

    const labelTextClasses = [
      `ma0 grey-400 kw-text-${size === 'regular' ? 'small' : 'regular'}`
    ];

    const labelClasses = [`ma0`, compact ? 'pr3' : 'pb2 dib'];

    const Wrapper = compact ? CompactWrapper : React.Fragment;

    const titleText = titleId ? (
      <h3 className={cx(titleTextClasses)}>
        <Text id={titleId} values={values} />
        <RequiredAsterisk hidden={!required} />
      </h3>
    ) : null;

    const labelText = labelId ? (
      <p className={cx(labelTextClasses)}>
        <Text id={labelId} values={values} />
        {titleText ? null : <RequiredAsterisk hidden={!required} />}
      </p>
    ) : null;

    const label =
      titleText || labelText ? (
        <label htmlFor={name} className={cx(labelClasses)}>
          {titleText}
          {labelText}
        </label>
      ) : null;

    // Only define the errorId translation
    // if there actually is an error
    const errorId = error
      ? determineError(errorBaseId, errorCodes, intl)
      : undefined;

    return (
      <div hidden={hidden} className={cx(containerClasses)}>
        {label}
        <Wrapper>
          {children}
          {showInfo ? (
            <InfoWrapper
              info={info}
              infoId={infoId}
              errorId={errorId}
              error={error}
              values={values}
            />
          ) : null}
        </Wrapper>
      </div>
    );
  }
);
