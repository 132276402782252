// @flow
import * as React from 'react';
import getOr from 'lodash/fp/getOr';
import cx from 'classnames';
import { Field as FinalFormField } from 'react-final-form';

import { InfoPanel } from '@kwara/components/src/InfoPanel';

import { type GuaranteeType } from '@kwara/models/src';
import { Attribute } from '@kwara/components/src';
import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import { SubscribedTextField as TextField } from '@kwara/components/src/Form';
import { mapIndexed } from '@kwara/lib/src/lodash';

import { Currency } from '../../../../../components/Currency';
import { Child } from '../../../../../components/Stack';
import { LoansPanel } from '../../../../../components/LoansPanel';
import { SavingsPanel } from '../../../../../components/SavingsPanel';

import { type LoanSubstepProps } from '../../..';

type Props = {
  guarantors: GuaranteeType[],
  StackChild: typeof Child,
  formProps: $PropertyType<LoanSubstepProps, 'formProps'>
};

export const GuarantorList = ({ StackChild, guarantors, formProps }: Props) => (
  <StackChild>
    <ul className="list pa0">
      {mapIndexed((g, i) => {
        const formGuarantee = getOr(
          {},
          `values.guarantors[${i}]`,
          formProps.form && formProps.form.getState()
        );
        const { member } = g;
        return (
          <li key={member.id} className={cx('pb3')}>
            <div
              className={cx('br3 t-1a', {
                'bg-semi-white-400': !formGuarantee.isMarkedForDestruction,
                'bg-red-100 red-700': formGuarantee.isMarkedForDestruction
              })}
            >
              <div className="pa3 flex justify-between items-center">
                <Attribute
                  labelId="LoanAdd.Security.Guarantor.member"
                  size="medium"
                  values={{ id: member.id }}
                  value={member.fullName()}
                />
                <ProfilePhoto size="small" />
              </div>
              <div className="bt b--light-grey-400 flex justify-stretch items-center">
                <Attribute
                  className="pl3 flex-auto br b--light-grey-400"
                  labelContent={
                    <>
                      <Text id="LoanAdd.Security.Guarantor.deposits" />{' '}
                      <InfoPanel>
                        <SavingsPanel savings={member.savings} />
                      </InfoPanel>
                    </>
                  }
                  value={
                    member.totalSavings != null ? (
                      <Currency value={member.totalSavings} />
                    ) : null
                  }
                  valueClassName="teal-600"
                />
                <Attribute
                  className="pl3 flex-auto br b--light-grey-400"
                  labelContent={
                    <>
                      <Text id="LoanAdd.Security.Guarantor.loans" />{' '}
                      <InfoPanel>
                        <LoansPanel loans={member.loans} />
                      </InfoPanel>
                    </>
                  }
                  value={
                    member.totalLoansBalance != null ? (
                      <Currency value={member.totalLoansBalance} />
                    ) : null
                  }
                  valueClassName="red-600"
                />
                <Attribute
                  className="pl3 flex-auto"
                  labelId="LoanAdd.Security.Guarantor.standing"
                  value={
                    member.standing != null ? (
                      <Currency value={member.standing} />
                    ) : null
                  }
                />
              </div>

              <div className="pa3">
                <div className="b--white">
                  <TextField
                    labelId="LoanAdd.Security.Guarantor.amount"
                    leftGlyph="Currency.orgCurrency"
                    required
                    name={`guarantors[${i}].amount`}
                    errorBaseId="LoanAdd.SecurityAmount"
                  />
                </div>
              </div>
              <div className="pa3 bt b--light-grey-400">
                <FinalFormField
                  name={`guarantors[${i}].isMarkedForDestruction`}
                  type="checkbox"
                  render={({ input }) => {
                    if (input.checked) {
                      return (
                        <>
                          <Text id="LoanAdd.Security.Guarantor.undoWarning" />
                          <Link
                            size="regular"
                            type="primary"
                            onClick={() => input.onChange(false)}
                          >
                            <Text id="LoanAdd.Security.Guarantor.undo" />
                          </Link>
                        </>
                      );
                    }

                    return (
                      <Link
                        size="regular"
                        type="destructive"
                        onClick={() => input.onChange(true)}
                      >
                        <Text id="LoanAdd.Security.Guarantor.remove" />
                      </Link>
                    );
                  }}
                />
              </div>
            </div>
          </li>
        );
      }, guarantors)}
    </ul>
  </StackChild>
);
