// @flow
import * as React from 'react';
import get from 'lodash/fp/get';

import { LoanFields, LoanType } from '@kwara/models/src';
import { Text, Currency } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';
import {
  Bank as BankName,
  BankBranch as BankBranchName
} from '@kwara/components/src/Bank';

import { Repayment as RepaymentForm } from '../../LoanAdd/components/Repayment';
import { excludedRepaymentFields } from '../../../lib/modelUtils/loan';
import { EditableSection, Updater } from '../../../components/EditableSection';

const fields = LoanFields.repayment;

export const Content = ({
  loan,
  className = ''
}: {
  loan: LoanType,
  className?: string
}) => {
  const { member = {} } = loan;
  const excludedField = excludedRepaymentFields(loan);

  const transactionMethod = get(fields.mode, loan);
  const amount = get(fields.amount, loan);
  const bankCode = get(fields.bank, loan);
  const branchCode = get(fields.branch, loan);
  const collectingBankCode = get(fields.collectingBank, loan);

  return (
    <div className={className}>
      <StatisticRow widthClass="w-100" compact>
        <Statistic
          title={<Text id="LoanDetail.Repayment.mode" />}
          value={
            transactionMethod ? (
              <Text id={`TransactionMethod.${transactionMethod}`} />
            ) : null
          }
        />
        <Statistic
          title={<Text id="LoanDetail.Repayment.amount" />}
          value={amount ? <Currency value={amount} /> : null}
        />
      </StatisticRow>
      {excludedField('member.employer') &&
      excludedField('member.staffId') ? null : (
        <StatisticRow widthClass="w-50" compact>
          <Statistic
            title={<Text id="LoanDetail.Repayment.employerName" />}
            value={member.employer}
            hidden={excludedField('member.employer')}
          />
          <Statistic
            title={<Text id="LoanDetail.Repayment.staffId" />}
            value={member.staffId}
            hidden={excludedField('member.staffId')}
          />
        </StatisticRow>
      )}

      <StatisticRow widthClass="w-50" compact>
        <Statistic
          title={<Text id="PaymentForm.bank" />}
          value={<BankName bankCode={bankCode} />}
          hidden={excludedField(fields.bank)}
        />
        <Statistic
          title={<Text id="PaymentForm.bankBranch" />}
          value={<BankBranchName bankCode={bankCode} branchCode={branchCode} />}
          hidden={excludedField(fields.branch)}
        />
        <Statistic
          title={<Text id="PaymentForm.accountNumber" />}
          value={get(fields.account, loan)}
          hidden={excludedField(fields.account)}
        />
        <Statistic
          title={<Text id="LoanDetail.Repayment.collectingBank" />}
          value={<BankName bankCode={collectingBankCode} />}
          hidden={excludedField(fields.collectingBank)}
        />
      </StatisticRow>
    </div>
  );
};

export const Repayment = ({
  loan,
  refetch,
  readOnly
}: {
  loan: LoanType,
  refetch: () => void,
  readOnly?: boolean
}) => {
  return (
    <Updater value={{ onUpdate: refetch }}>
      <EditableSection
        readOnly={readOnly}
        initialData={loan}
        config={{
          Component: RepaymentForm,
          validate: RepaymentForm.validateConfig.loanEdit
        }}
      >
        <Content loan={loan} />
      </EditableSection>
    </Updater>
  );
};
