// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import {
  SubscribedSelectField as SelectField,
  Condition
} from '@kwara/components/src/Form';

import { Grid } from '../../Grid';

export const DAILY = 'DAILY';
export const WEEKLY = 'WEEKLY';
export const MONTHLY = 'MONTHLY';

// [1,2,3,...31]
export const daysOfMonth = [...Array(32).keys()].slice(1);
export const daysOfWeek = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
];

export const SubscribedFrequencySelectFields = ({
  addData,
  frequency,
  day
}: {
  addData: ({ [k: string]: string }) => void,
  frequency: string,
  day: string
}) => {
  return (
    <Grid columns={2} width="w-50" border={false}>
      <SelectField
        name={frequency}
        labelId="FrequencySelector.Frequency.label"
        inputOnChange={() =>
          addData({
            [day]: null
          })
        }
      >
        <SelectField.Option value=""></SelectField.Option>
        {map(
          val => (
            <SelectField.Option
              key={val}
              value={val}
              translationId={`FrequencySelector.Frequency.${val}`}
            />
          ),
          [MONTHLY, WEEKLY, DAILY]
        )}
      </SelectField>
      <Condition when={frequency} not={DAILY}>
        {({ input }) => {
          const { value } = input;

          if (value === WEEKLY) {
            return (
              <SelectField
                name={day}
                labelId="FrequencySelector.Date.Weekly.label"
              >
                <SelectField.Option value=""></SelectField.Option>
                {map(
                  d => (
                    <SelectField.Option
                      key={d}
                      value={d}
                      translationId={`FrequencySelector.Date.DayOfWeek.${d}`}
                    />
                  ),
                  daysOfWeek
                )}
              </SelectField>
            );
          }
          if (value === MONTHLY) {
            return (
              <SelectField
                name={day}
                labelId="FrequencySelector.Date.Monthly.label"
              >
                <SelectField.Option value=""></SelectField.Option>
                {map(
                  d => (
                    <SelectField.Option key={d} value={d}>
                      {d}
                    </SelectField.Option>
                  ),
                  daysOfMonth
                )}
              </SelectField>
            );
          }

          return null;
        }}
      </Condition>
    </Grid>
  );
};

SubscribedFrequencySelectFields.validate = (frequencyField, dayField) => ({
  [dayField]: {
    isRequired: (_: string, allData) => {
      const frequency = get(frequencyField, allData);
      return frequency === MONTHLY || frequency === WEEKLY;
    },
    custom: (target: string, allData) => {
      const frequency = get(frequencyField, allData);

      if (frequency === MONTHLY) {
        return daysOfMonth.includes(Number(target))
          ? null
          : 'frequencyMismatch';
      }

      if (frequency === WEEKLY) {
        return daysOfWeek.includes(target) ? null : 'frequencyMismatch';
      }

      return null;
    }
  }
});
