import React from 'react';
import getOr from 'lodash/fp/getOr';
import get from 'lodash/fp/get';

import { ERRORS as err } from '@kwara/lib/src/validator';
import { Text } from '@kwara/components/src/Intl';

export function getMinMaxLoanProductAmount(data) {
  const { maximum: principalMax, minimum: principalMin } = getOr(
    {},
    'product.amount',
    data
  );

  return { principalMax, principalMin };
}

// *** Principal Loan amount field ***
//  i.e. 50,000 KES
export const PrincipalAmountField = ({ data, TextField }) => {
  const { product } = data;
  const eligibleAmount = get('eligibility.eligibleAmount', product);
  const fullName = data.member.fullName();
  const { principalMax, principalMin } = getMinMaxLoanProductAmount(data);

  const amountInfo = (
    <ul>
      {principalMax && (
        <li>
          <Text
            id="LoanAdd.Configure.PrincipalAmount.maximumAmount"
            values={{
              principalMax,
              principalMin
            }}
          />
        </li>
      )}
      {eligibleAmount ? (
        <li>
          <Text
            id="LoanAdd.Configure.PrincipalAmount.eligibleAmount"
            values={{
              eligibleAmount
            }}
          />
        </li>
      ) : null}
    </ul>
  );

  return (
    <TextField
      margin={false}
      compact
      errorBaseId="LoanAdd.Configure.PrincipalAmount"
      errorValues={{
        principalMax,
        principalMin,
        fullName,
        eligibleAmount
      }}
      name="amount"
      required={true}
      leftGlyph="Currency.orgCurrency"
      titleId="LoanAdd.Configure.PrincipalAmount.title"
      info={amountInfo}
    />
  );
};

PrincipalAmountField.validate = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom: (target, allData) => {
      const { principalMax, principalMin } = getMinMaxLoanProductAmount(
        allData
      );

      const num = Number(target);

      // Disabled until we sort out eligibleAmount
      // if (num > Number(get('member.eligibleAmount', allData))) {
      //   return 'insufficientFunds';
      // }

      if (principalMin != null && num < principalMin) {
        return err.rangeUnderflow;
      }

      if (principalMax != null && num > principalMax) {
        return err.rangeOverflow;
      }

      return null;
    }
  }
};
