import * as React from 'react';
import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';
import map from 'lodash/fp/map';

import { isEmpty } from '@kwara/lib/src/lodash';
import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';

import Table, { Row, Cell, Heading } from '../../../../components/Table';
import { Stack, Child } from '../../../../components/Stack';
import { Currency } from '../../../../components/Currency';

export const Tiers = ({ tiers = [] }) => {
  if (!isEmpty(tiers)) {
    const sortedTiers = sortBy(t => Number(t.min), tiers);
    return (
      <Stack>
        <Child size="wide">
          <Table
            heading={
              <Row>
                <Heading
                  translationId="Settings.LoanApprove.TiersTable.Heading.minimum"
                  width="200px"
                />
                <Heading
                  translationId="Settings.LoanApprove.TiersTable.Heading.maximum"
                  width="200px"
                />
                <Heading width="50px"></Heading>
                <Heading translationId="Settings.LoanApprove.TiersTable.Heading.roles" />
                <Heading width="100px" />
              </Row>
            }
          >
            <Row>
              <Cell className="v-btm" align="center" colSpan={2}>
                <span className="kw-text-small kw-weight-light grey-400">
                  <Text id="Settings.LoanApprove.TiersTable.Helper.range" />
                </span>
              </Cell>
              <Cell />
              <Cell className="v-btm" colSpan={2}>
                <span className="kw-text-small kw-weight-light grey-400">
                  <Text id="Settings.LoanApprove.TiersTable.Helper.roles" />
                </span>
              </Cell>
            </Row>
            {map(tier => {
              return (
                <Row>
                  <Cell>
                    <Currency value={tier.min} hideDecimals={false} />
                  </Cell>
                  <Cell>
                    <Currency value={tier.max} hideDecimals={false} />
                  </Cell>
                  <Cell />
                  <Cell>{get('loanApprovalTierSet.role.name', tier)}</Cell>
                </Row>
              );
            }, sortedTiers)}
          </Table>
        </Child>
      </Stack>
    );
  }

  return (
    <Empty>
      <Text id="Settings.LoanApprove.TiersList.Empty" />
    </Empty>
  );
};
