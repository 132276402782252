// @flow

import { attr, belongsTo, hasMany } from 'spraypaint';

import Base, { BaseModel } from './Base';
import { type UserT } from './User';
import { type LoanAccountCrbSubmissionsT } from './LoanAccountCrbSubmissions';

type SubmissionStateT =
  | 'pending_approval'
  | 'approved'
  | 'uploading'
  | 'failed'
  | 'uploaded';

export const CrbDataSubmission = Base.extend({
  static: {
    jsonapiType: 'crb_data_submissions'
  },
  attrs: {
    loanIds: attr(),
    user: belongsTo('user'),
    state: attr(),
    loanAccountCrbSubmissions: hasMany('loan_account_crb_submissions')
  },
  methods: {}
});

export interface CrbDataSubmissionT extends BaseModel<CrbDataSubmissionT> {
  loanIds: string[];
  user: UserT;
  state: {
    current: SubmissionStateT,
    permitted_events: string[]
  };
  loanAccountCrbSubmissions: LoanAccountCrbSubmissionsT[];
}
