// @flow

import * as React from 'react';
import { withRouter, type Location } from 'react-router-dom';
import { injectIntl, type IntlShape } from 'react-intl';
import getOr from 'lodash/fp/getOr';

import { getTranslation } from '@kwara/components/src/Intl';

import Button from '.';
import type { LinkContext } from './linkContext';

type Props = {
  intl: IntlShape,
  location: Location & { state: { from: LinkContext } },
  to: LinkContext
};

const BackButton = ({ intl, location, to }: Props) => {
  // 1. if a to.url is passed, go there
  // 2. otherwise, try to get the destination from the location
  // 3. otherwise, fallback to going back to the root
  const toLocation = to || getOr({ url: '/' }, 'state.from', location);
  let label;

  const destinationTranslationId = `Back.${toLocation.routeName}`;
  const destinationTranslation = getTranslation(intl, destinationTranslationId);

  if (
    destinationTranslation != null &&
    destinationTranslation !== destinationTranslationId
  ) {
    label = getTranslation(intl, `Back.Location`, {
      location: destinationTranslation
    });
  } else {
    label = getTranslation(intl, 'Back.Generic');
  }

  return (
    <Button
      type="secondary"
      to={toLocation.url}
      size="regular"
      glyphLeftId={Button.Glyphs.ArrowLeft}
    >
      {label}
    </Button>
  );
};

export default withRouter(injectIntl(BackButton));
