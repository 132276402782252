import React, { Component } from 'react';
import { observer } from 'mobx-react';

import {
  WithNotification,
  type notification
} from '@kwara/components/src/Notification';
import Button from '@kwara/components/src/Button';
import PageLayout from '@kwara/components/src/PageLayout';
import { DeprecatedLoadable } from '@kwara/components/src/Loadable';
import {
  UtilityTransaction,
  TillTransaction,
  TillMetric
} from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { CurrentTill } from '@kwara/models/src/models/Till';
import { formatErrorObject } from '@kwara/models/src/models/request';
import { PermissionDenied } from '@kwara/components/src/PermissionDenied';

import { ProfileContext } from '../../models/Profile';
import { DataViewWrapper } from '../../components/DataViewWrapper';
import { TillDashboard } from './components/Dashboard';
import Head from '../../components/Head';
import { TillList } from './components/TillList';
import { TillModal } from './components/TillModal';

import type { ListPageProps } from '..';

import { store } from '../../models/Store';

export type ModalState =
  | 'success'
  | 'confirmClose'
  | 'withdrawal'
  | 'deposit'
  | 'utilities';
export type ModalAction = ({ actionModal: ModalState }) => void;

type Props = ListPageProps & notification;
type State = {
  isTillOpen: boolean,
  isModalOpen: boolean,
  activeModal: ModalState
};

type TillActionsProps = {
  isTillOpen: boolean,
  openTill: () => void,
  updateModal: ModalAction,
  tillLoading: boolean
};

const TillActions = ({
  isTillOpen,
  updateModal,
  openTill,
  tillLoading
  // giveMeCard,
  // giveMeCheque
}): TillActionsProps => {
  return [
    <Button
      onClick={() => updateModal({ activeModal: 'confirmClose' })}
      size="regular"
      className="dib"
      disabled={!isTillOpen}
      type="secondary"
      key="closeTill"
    >
      <Text id="TillList.closeTill" />
    </Button>,
    <Button
      onClick={openTill}
      size="regular"
      className="dib"
      disabled={isTillOpen || tillLoading}
      type="primary"
      key="openTill"
    >
      <Text id="TillList.openTill" />
    </Button>
  ];
};

class Content extends Component<Props, State> {
  constructor() {
    super();

    this.state = {
      tillLoading: true,
      activeModal: null
    };
  }

  updateTillInfo() {
    CurrentTill.find()
      .then(response => {
        const isTillOpen = !!response.data;

        // IMPORTANT: the till is available in the store once the user
        // visits the Till page (if they perform transactions prior to this)
        // Till will not be available.
        store.setCurrentTill(response.data);
        this.setState({ isTillOpen, tillLoading: false });
      })
      .catch(_ => {
        // Spraypaint .find() returns an error when
        // the data is `null` which it is in the case of
        // a closed Till. Hence, we ensure UI state is Closed.
        this.setState({ isTillOpen: false, tillLoading: false });
      });
  }

  updateMetrics = () => {
    TillMetric.all()
      .then(response => {
        this.setState({
          metrics: response.data
        });
      })
      .catch(async e => {
        const formattedError = await formatErrorObject(e);
        this.setState({
          error: formattedError
        });
      });
  };

  componentDidMount() {
    this.updateTillInfo();
    this.updateMetrics();
  }

  openTill = async () => {
    this.setState({ tillLoading: true });
    const { auth } = this.props;
    // TO DO: openTill call should not live in Auth
    const success = await auth.openTill();

    if (!success) {
      this.setState({ tillLoading: false });
      return;
    }

    this.setState(
      {
        tillLoading: false
      },
      () => {
        this.updateModal({ activeModal: 'success' });
        this.updateTillInfo();
        this.updateMetrics();
        this.props.resetData();
      }
    );
  };

  closeTill = async () => {
    const { auth, resetData } = this.props;
    // TO DO: closeTill call should not live in Auth
    const success = await auth.closeTill();

    if (!success) {
      return;
    }

    this.updateModal({ activeModal: 'success' });
    resetData();
    this.updateTillInfo();
    this.updateMetrics();
  };

  updateModal = ({ activeModal }: { activeModal: ModalState }) => {
    this.setState({
      activeModal
    });
  };

  submitUtilityPayment = async data => {
    const { resetData } = this.props;
    const utility = new UtilityTransaction(data);

    const didSave = await utility.save();

    if (!didSave) {
      // TODO: This should probably be handled better
      throw utility.errors;
    } else {
      this.updateMetrics();
      resetData();
      this.updateModal({ activeModal: null });
    }
  };

  giveMeCard() {
    this.updateModal({ activeModal: 'giveMeCard' });
  }

  giveMeCheque() {
    this.updateModal({ activeModal: 'giveMeCheque' });
  }

  render() {
    const { tillLoading, isTillOpen, activeModal, metrics } = this.state;

    const {
      openTill,
      closeTill,
      updateModal,
      submitUtilityPayment,
      giveMeCard,
      giveMeCheque
    } = this;

    const {
      hasMore,
      loading,
      errors,
      data,
      pristine,
      onLoadMoreData,
      totalNumberResults
    } = this.props;

    const { history } = this.props;

    return (
      <PageLayout
        overview={
          <TillDashboard
            metrics={metrics}
            isTillOpen={isTillOpen}
            actions={TillActions({
              openTill,
              closeTill,
              isTillOpen,
              updateModal,
              tillLoading,
              giveMeCard,
              giveMeCheque
            })}
          />
        }
      >
        <Head titleId="TillList.title" />
        {/* <ATMModal /> */}
        <TillModal
          openTill={openTill}
          closeTill={closeTill}
          isTillOpen={isTillOpen}
          activeModal={activeModal}
          updateModal={updateModal}
          submitUtilityPayment={submitUtilityPayment}
          history={history}
        />
        <DeprecatedLoadable
          loader={pristine ? new Promise(() => {}) : Promise.resolve(data)}
          loaded={transactions => {
            return (
              <TillList
                transactions={transactions}
                onLoadMoreData={onLoadMoreData}
                hasMore={hasMore}
                loading={loading}
                errors={errors}
                history={history}
                updateModal={updateModal}
                isTillOpen={isTillOpen}
                totalNumResults={totalNumberResults}
              />
            );
          }}
        />
      </PageLayout>
    );
  }
}

const ContentWithNotification = WithNotification(observer(Content));

export const Till = ({ location, auth, history }) => {
  const store = React.useContext(ProfileContext);
  const { profile } = store;
  const { glAccountId } = profile;

  const canUseTill = glAccountId;
  const glAccountIdMessage = (
    <Text id="TillList.PermissionDenied.noGlAccount" />
  );

  return canUseTill ? (
    <DataViewWrapper
      component={props => (
        <ContentWithNotification auth={auth} history={history} {...props} />
      )}
      scope={TillTransaction.includes(['member'])}
      pathname="/till"
      location={location}
    />
  ) : (
    <PermissionDenied message={glAccountIdMessage} />
  );
};
