// @flow

import { attr, belongsTo } from 'spraypaint';

import Transaction from './Transaction';
import createModelErrors, {
  createErrorsFromApiResponse
} from './createModelErrors';
import { SavingsTransactionReviewSet } from './TransactionReviewSet';

const SavingsTransaction = Transaction.extend({
  static: {
    jsonapiType: 'savings_transactions'
  },
  attrs: {
    chequeNumber: attr(),
    drawer: attr(),
    bankName: attr(),
    bankBranch: attr(),
    accountNumber: attr(),

    // Write
    bankGlId: attr(),
    savingsId: attr(),
    reference: attr(),

    // Write - Transfers
    linkedAccountId: attr(),
    linkedAccountType: attr(),

    memberName: attr(),
    memberId: attr(),

    savingsTransactionReviewSet: belongsTo({
      type: SavingsTransactionReviewSet
    })
  },
  methods: {}
});

export const PendingSavingsTransaction = SavingsTransaction.extend({
  static: {
    jsonapiType: 'pending_savings_transactions'
  },
  attrs: {
    checker: attr(),
    state: attr()
  },
  methods: {
    async transition(params: {
      event: 'approve' | 'reject',
      raw_savings_transaction_ids: string[],
      notes?: string
    }) {
      const url = PendingSavingsTransaction.url();
      const attributes = params;

      const options = {
        ...PendingSavingsTransaction.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({ data: { attributes } })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();

          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (errors) {
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    }
  }
});

PendingSavingsTransaction.transactionsKey = 'raw_savings_transaction_ids';

export type SavingsTransactionType = SavingsTransaction;

export default (SavingsTransaction: typeof SavingsTransaction);
