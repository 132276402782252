// @flow
import React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import Asset from '../Asset';

declare class PrintButtonT extends HTMLButtonElement {
  onClick?: () => void;
  size?: 'small' | 'regular' | 'medium';
  ariaLabel?: string;
  showPrintLabel?: boolean;
}

export function printAction() {
  global.window.print();
}

export const PrintButton = ({
  className = '',
  disabled,
  onClick = printAction,
  size = 'medium',
  showPrintLabel = false,
  ariaLabel = 'print'
}: PrintButtonT) => (
  <Button
    className={className}
    type="secondary"
    onClick={onClick}
    disabled={disabled}
    glyphLeftId={showPrintLabel ? Asset.Glyphs.Printer : null}
    size={size}
    aria-label={ariaLabel}
  >
    {showPrintLabel ? (
      <Text id="General.Print" />
    ) : (
      <Asset
        size={size}
        col={disabled ? Asset.Colours.grey300 : Asset.Colours.black}
        id={Asset.Glyphs.Printer}
      />
    )}
  </Button>
);
