//@flow
import React from 'react';

import PageLayout from '@kwara/components/src/PageLayout';
import { type Location } from '@kwara/components/src/Actionable';
import { Loan } from '@kwara/models/src';

import { DataViewWrapper } from '../../components/DataViewWrapper';
import Dashboard from './components/Dashboard';
import Head from '../../components/Head';
import LoanList from './components/LoanList';

import { type LoanPageProps } from '..';

type Props = LoanPageProps;

const Content = (props: Props) => {
  const {
    hasMore,
    loading,
    errors,
    filterBy,
    data,
    onLoadMoreData,
    totalNumberResults,
    onFilterChange
  } = props;

  return (
    <PageLayout overview={<Dashboard />}>
      <Head titleId="LoanList.title" />
      <LoanList
        filterBy={filterBy}
        hasMore={hasMore}
        loading={loading}
        errors={errors}
        loans={data}
        onFilterChange={onFilterChange}
        onLoadMoreData={onLoadMoreData}
        totalNumResults={totalNumberResults}
      />
    </PageLayout>
  );
};

export const Loans = ({ location }: { location: Location }) => (
  <DataViewWrapper
    component={Content}
    scope={Loan.includes(['member', 'product'])}
    pathname="/loans"
    location={location}
  />
);
