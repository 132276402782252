// @flow

import { attr, hasOne } from 'spraypaint';
import get from 'lodash/fp/get';

import Base, { BaseModel } from './Base';
import { ImportReviewSet, type ImportReviewSetT } from './ImportReviewSet';

type SupportedCurrencyT = 'KES';
type SupportedTransactionsT =
  | 'DEPOSIT'
  | 'WITHDRAWAL'
  | 'REPAYMENT'
  | 'TRANSFER';

type EventT = 'approve' | 'reject' | 'import';

export const BatchTransactionState = Object.freeze({
  PENDING: 'pending_approval',
  APPROVED: 'approved',
  IMPORTING: 'importing',
  IMPORTED: 'imported',
  FAILED: 'failed',
  REJECTED: 'rejected'
});

export type BatchTransactionStateT = $Values<typeof BatchTransactionState>;

type ImportErrorT = {
  code: number,
  details: string,
  meta: {
    attribute: string
  },
  source: {
    pointer: string
  }
};

export const BatchTransaction = Base.extend({
  static: {
    jsonapiType: 'batch_transactions',
    from(batchId) {
      return BatchTransaction.extend({
        static: {
          endpoint: `/batch_transaction_imports/${batchId}/batch_transactions`
        }
      });
    }
  },
  attrs: {
    name: attr(),
    memberId: attr(),
    accountId: attr(),
    amount: attr(),
    type: attr(),
    productId: attr(),
    receivingAccount: attr(),
    reference: attr(),
    glCode: attr(),
    state: attr(),
    notes: attr(),
    rowIndex: attr(),
    importResultsErrors: attr(),
    importReviewSet: hasOne({ type: ImportReviewSet })
  },
  methods: {
    formattedAmount() {
      return this.amount.cents / 100;
    },
    isPending() {
      return get('state.current', this) === BatchTransactionState.PENDING;
    }
  }
});

export interface BatchTransactionT extends BaseModel<BatchTransactionT> {
  name: string;
  memberId: string;
  accountId: string;
  amount: {
    cents: number,
    currency_iso: SupportedCurrencyT
  };
  type: SupportedTransactionsT;
  productId: string;
  receivingAccount: string;
  reference: string;
  glCode: string;
  state: {
    current: BatchTransactionStateT,
    permitted_events: EventT[]
  };
  rowIndex: number;
  importResultsErrors: ImportErrorT[];
  importReviewSet: ImportReviewSetT;
  formattedAmount: () => number;
  isPending: () => boolean;
}
