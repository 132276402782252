// @flow
import * as React from 'react';

import { ActionButton } from '@kwara/components/src/Button';
import { Text, type TranslationId } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

export type Props = {
  onCancel: () => void,
  titleId: TranslationId
};

export default ({ titleId, onCancel }: Props) => (
  <header className={`flex items-center ${styles.Header}`}>
    <h3 className="kw-weight-bold">
      <Text id={titleId} />
    </h3>
    <ActionButton className="ml-auto" onClick={onCancel} type="cancel" />
  </header>
);
