// @flow
import * as React from 'react';
import get from 'lodash/fp/get';

import { type CollateralT } from '@kwara/models/src';
import { mapIndexed } from '@kwara/lib/src/lodash';
import { Text } from '@kwara/components/src/Intl';

import { CollateralSelect } from './CollateralSelect';
import { CollateralList } from './CollateralList';
import { Header } from '../Header';
import { type LoanSubstepProps } from '../../..';
import { useListEditor } from '../../../../../lib/hooks';

function toggleItem(shouldDestroy, collaterals: CollateralT[], idx: number) {
  const clone = get(idx, collaterals).dup();
  clone.isMarkedForDestruction = shouldDestroy;
  // replace the original instance with a clone that is marked for destruction
  return {
    collaterals: mapIndexed((c, i) => {
      if (i === idx) {
        return clone;
      }
      return c;
    }, collaterals)
  };
}

export const Collateral = ({
  formProps,
  data,
  TextArea,
  StackChild,
  onChange
}: LoanSubstepProps) => {
  const { product } = data;

  const basePath = 'collaterals';
  const {
    isEditing,
    path,
    setEdit,
    addItem,
    setView,
    editItem,
    index
  } = useListEditor(data.collaterals, basePath);
  const onCancel = () => {
    if (formProps.form) {
      formProps.form.reset();
    }
    setView();
  };
  const onSubmit = (collaterals: CollateralT[]) => {
    onChange({ collaterals });
    setView();
  };

  if (!product.hasCollateralsEnabled()) {
    return (
      <StackChild>
        <div className="pb4 flex justify-between items-center">
          <p className="ma0">
            <Text id="LoanAdd.Security.Collateral.disabled" />
          </p>
        </div>
      </StackChild>
    );
  }

  return (
    <>
      <Header
        titleId="LoanAdd.Security.Collateral.title"
        subtitleId="LoanAdd.Security.Collateral.subtitle"
        buttonTextId="LoanAdd.Security.Collateral.add"
        enabled={true}
        StackChild={StackChild}
        isSearching={isEditing}
        onAdd={() => {
          addItem();
          setEdit();
        }}
        memberName={data.member.fullName()}
        data={data}
      />
      {isEditing ? (
        <CollateralSelect
          StackChild={StackChild}
          onCancel={onCancel}
          TextArea={TextArea}
          onSelect={onSubmit}
          formProps={formProps}
          index={index}
          data={data}
          path={path}
        />
      ) : (
        <CollateralList
          StackChild={StackChild}
          collaterals={data.collaterals}
          onUndo={idx => onChange(toggleItem(false, data.collaterals, idx))}
          onRemove={idx => onChange(toggleItem(true, data.collaterals, idx))}
          onEdit={editItem}
        />
      )}
    </>
  );
};
