import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import { AppPermissions } from '../../../models/Permission';
import { financePath } from '../../../lib/urls';
import { Header } from '../../../components/SideNavContainer';
import Visible from '../../../components/Visible';

export const JournalEntry = () => {
  return (
    <>
      <Header
        titleId="Finance.JournalEntries.title"
        subtitleId="Finance.JournalEntries.subtitle"
        action={
          <Visible to={AppPermissions.CreateJournalEntries}>
            <Button
              type="primary"
              to={financePath({
                baseExtension: 'journalEntries',
                action: 'new'
              })}
            >
              <Text id="Finance.JournalEntries.add" />
            </Button>
          </Visible>
        }
      />
      <div>Coming soon – Table of Journal Entries</div>
    </>
  );
};
