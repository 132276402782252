// @flow

import * as React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';

import Wizard from '../../components/Wizard';
import { savingPath } from '../../lib/urls';
import { useSaving } from '../../models/request';

import { addDeposit } from '../MemberDeposit/components/DepositForm';
import { steps } from './steps';
import { type SavingPageProps } from '..';

type Props = SavingPageProps;

export const SavingPayment = (props: Props) => {
  const r = useSaving(props.match.params.savingId);

  return (
    <Loadable {...r}>
      {saving => {
        const { member } = saving;
        return (
          <Wizard
            analyticsId="SavingPayment"
            baseUrl={savingPath({ id: saving.id })}
            history={history}
            initialData={{
              member,
              accountId: saving.id,
              saving
            }}
            cancelReturnsTo={savingPath({ id: saving.id })}
            currentStep="deposit"
            onSubmit={addDeposit}
            steps={steps}
            startId="deposit"
            titleId="SavingPayment.title"
          />
        );
      }}
    </Loadable>
  );
};
