// @flow

import * as React from 'react';
import classnames from 'classnames';

import Actionable, { type Location } from '@kwara/components/src/Actionable';
import Asset, { type Glyph } from '@kwara/components/src/Asset';

import { typeToIcon } from './ActionButton';

import styles from './index.module.css';

export { default as ActionButton } from './ActionButton';
export { default as BackButton } from './BackButton';

type ButtonProps = {
  children: React.ChildrenArray<*>,
  className?: string,
  disabled?: boolean,
  glyphLeftId?: ?Glyph | ?string,
  glyphRightId?: ?Glyph | ?string,
  isSubmit?: boolean,
  to?: Location,
  type?: 'primary' | 'secondary' | 'destructive',
  size?: 'small' | 'regular' | 'medium' | 'large',
  withLinkContext?: boolean
};

const resolveGlyph = (id: ?string): ?Glyph => {
  if (id && Asset.Glyphs[id]) {
    return Asset.Glyphs[id];
  } else if (id && typeToIcon[id]) {
    return typeToIcon[id];
  }

  return null;
};

const Button = ({
  children,
  className,
  disabled = false,
  glyphLeftId,
  glyphRightId,
  type = 'secondary',
  size = 'regular',
  ...props
}: ButtonProps) => {
  const classes = [
    `inline-flex items-center`,
    `link br-pill ba b--transparent dib v-mid`,
    disabled ? styles.disabled : styles.enabled,
    styles[size],
    styles[type]
  ];

  const isGlyphOnly = React.Children.toArray(children).length === 0;

  if (type === 'primary') {
    classes.push(
      disabled
        ? 'bg-indigo-100'
        : 'bg-indigo-500 hover-bg-indigo-600 focus-bg-indigo-600',

      'white visited-white link-white',
      styles.glyphPrimary
    );
  } else if (type === 'destructive') {
    classes.push(
      disabled ? 'bg-red-100' : 'bg-red-500',
      'white visited-white link-white'
    );
  } else {
    classes.push(
      'bg-transparent',
      disabled ? 'light-grey-500 b--light-grey-400' : 'b--light-grey-500',
      styles.glyphSecondary
    );
  }

  if (isGlyphOnly) {
    classes.push(styles.glyphOnly);
  }

  let glyphCol;

  if (type === 'primary' || type === 'destructive') {
    glyphCol = Asset.Colours.white;
  } else if (disabled) {
    glyphCol = Asset.Colours.grey300;
  } else {
    glyphCol = Asset.Colours.black;
  }

  const glyphLeftIdResolved = resolveGlyph(glyphLeftId);
  const glyphLeft =
    glyphLeftIdResolved != null ? (
      <Asset
        size={size}
        className={styles.glyphLeft}
        id={glyphLeftIdResolved}
        col={glyphCol}
      />
    ) : null;

  const glyphRightIdResolved = resolveGlyph(glyphRightId);
  const glyphRight =
    glyphRightIdResolved != null ? (
      <Asset
        size={size}
        className={styles.glyphRight}
        id={glyphRightIdResolved}
        col={glyphCol}
      />
    ) : null;

  const inner = (
    <>
      {glyphLeft}
      <span className={styles.content}>
        {children} {isGlyphOnly}
      </span>
      {glyphRight}
    </>
  );

  return (
    <Actionable
      {...props}
      className={classnames(classes, className, 'hide-on-print')}
      disabled={disabled}
    >
      {inner}
    </Actionable>
  );
};

Button.Glyphs = Asset.Glyphs;

export default Button;
