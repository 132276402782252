//@flow
import * as React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { DateTime } from 'luxon';

import styles from './DatePicker.module.css';

import { TextField } from '.';

type Props = {
  disabled?: boolean,
  disabledDays?: { before?: Date, after?: Date },
  name: string,
  value: Date,
  size?: 'regular' | 'medium',
  onChange?: (evt: { target: { value: string } }) => void,
  onBlur?: () => void
};

const FORMAT = 'dd/MM/yyyy';
const PLACEHOLDER = 'DD/MM/YYYY';

const parseDate = str => {
  const d = DateTime.fromFormat(str, FORMAT);
  if (d.isValid) {
    return d.toJSDate();
  }

  return null;
};

const formatDate = date => {
  const d = DateTime.fromJSDate(date);
  if (d.isValid) {
    return d.toFormat(FORMAT);
  }

  return null;
};

const DatePicker = ({
  onChange,
  onBlur,
  disabled,
  disabledDays,
  name,
  value
}: Props) => {
  return (
    <DayPickerInput
      classNames={{
        overlay: '',
        overlayWrapper: styles.DatePicker
      }}
      component={TextField}
      dayPickerProps={{ disabledDays }}
      formatDate={formatDate}
      inputProps={{
        onBlur,
        name,
        disabled,
        placeholder: PLACEHOLDER,
        size: 'medium'
      }}
      parseDate={parseDate}
      onDayChange={onChange}
      value={value}
    />
  );
};

export default DatePicker;
