// @flow

import * as React from 'react';

import CardContainer from '@kwara/components/src/CardContainer';

import styles from './index.module.scss';

export const Panel = ({ children }: { children: React.Node }) => (
  <CardContainer className={styles.Panel} full flex={false}>
    {children}
  </CardContainer>
);
