// @flow

import { attr, belongsTo, hasOne, hasMany } from 'spraypaint';
import get from 'lodash/fp/get';
import reduce from 'lodash/fp/reduce';
import map from 'lodash/fp/map';

import Base, { type BaseModel } from './Base';
import { Branch, type BranchT } from './Branch';
import { type OrganisationT } from './Organisation';
import { Role, type RoleT } from './Role';
import { AppRole, type AppRoleT } from './AppRole';
import { UserAppRole } from './UserAppRole';

import { fullName } from './Member';

export const User = Base.extend({
  static: {
    jsonapiType: 'users',
    endpoint: '/organisations/users'
  },
  attrs: {
    firstName: attr(),
    lastName: attr(),
    middleName: attr(),
    email: attr(),
    emailConfirmed: attr(),
    trialUser: attr(),
    trialExpiryDate: attr(),
    branch: belongsTo({ type: Branch }),
    role: hasOne({ type: Role }),
    appRoles: hasMany({ type: AppRole }),
    glAccountId: attr(),
    apiInstance: attr(),
    bridgingLoanProductId: attr(),
    bridgingDepositProductId: attr(),
    isAdmin: attr(),
    organisation: belongsTo()
  },
  methods: {
    fullName() {
      return fullName([this.firstName, this.middleName, this.lastName]);
    },
    async unAssignRole(roleId) {
      try {
        await UserAppRole.remove(this.id, roleId);
      } catch (e) {
        throw e;
      }
    },
    async assignRole(roleId) {
      try {
        await UserAppRole.add(this.id, roleId);
      } catch (e) {
        throw e;
      }
    },
    getDeprecatedPermissions() {
      return get('role.permissions', this) || [];
    },
    getAppPermissions() {
      const permissionObjects = reduce(
        (result, current) => {
          return [...result, ...current.appPermissions];
        },
        [],
        this.appRoles
      );

      return map(p => p.name, permissionObjects);
    },
    getPermissions() {
      return [...this.getDeprecatedPermissions(), ...this.getAppPermissions()];
    }
  }
});

export const Profile = User.extend({
  static: {
    endpoint: '/profile'
  }
});

export interface UserT extends BaseModel<UserT> {
  firstName: string;
  email: string;
  emailConfirmed: boolean;
  trialUser: boolean;
  trialExpiryDate: ?Date;
  branch: BranchT;
  role: RoleT;
  appRoles: AppRoleT[];
  apiInstance: string;
  bridgingLoanProductId: string;
  bridgingDepositProductId: string;
  glAccountId: string;
  isAdmin: boolean;
  fullName: () => string;
  organisation: OrganisationT;
}
