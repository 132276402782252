// @flow

import React from 'react';
import { Field as FinalFormField } from 'react-final-form';

import {
  type LoanProductType,
  type SavingProductType
} from '@kwara/models/src';

import { LoanProductCard } from './LoanProductCard';
import { SavingProductCard } from './SavingProductCard';

type SelectHandler = (product: LoanProductType | SavingProductType) => void;

type BaseProps = {
  product: LoanProductType | SavingProductType,
  onSelect: SelectHandler,
  isSelectable: boolean,
  disabled: boolean
};

type Props = BaseProps & {
  productCard: typeof LoanProductCard | typeof SavingProductCard
};

const SubscribedProductCard = ({
  product,
  onSelect,
  isSelectable = false,
  productCard: ProductCard
}: Props) => {
  return (
    <FinalFormField
      key={product.id}
      name="productId"
      type="radio"
      value={product.id}
      render={({ input }) => {
        return (
          <>
            <label htmlFor={product.id}>
              <ProductCard
                product={product}
                isSelectable={isSelectable}
                isSelected={!!input.checked}
              />
            </label>
            <input
              hidden
              {...input}
              onClick={e => {
                onSelect(product);
                input.onChange(e);
              }}
              type="radio"
              id={product.id}
              name="productId"
            />
          </>
        );
      }}
    />
  );
};

export const SubscribedLoanProductCard = (props: BaseProps) => {
  return <SubscribedProductCard {...props} productCard={LoanProductCard} />;
};

export const SubscribedSavingProductCard = (props: BaseProps) => {
  return <SubscribedProductCard {...props} productCard={SavingProductCard} />;
};
