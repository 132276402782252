import * as React from 'react';
import get from 'lodash/fp/get';
import cx from 'classnames';

import { type SavingType, type LoanType } from '@kwara/models/src';
import StatusTag from '@kwara/components/src/StatusTag';

import styles from './index.module.css';

type Props = {
  account: SavingType | LoanType,
  amount: React.Node
};

export const NoAccounts = ({ children }: { children: React.Node }) => (
  <div className="ph2 nowrap">{children}</div>
);

export const AccountThumbnail = ({ account, amount }: Props) => {
  return (
    <div
      className={cx(
        styles.AccountThumbnail,
        'pb2 bb b--light-gray kw-text-small'
      )}
    >
      <div className="nowrap tl pv2 grey-400">
        <StatusTag size="small" state={get('state.current', account)} />{' '}
        &middot; {account.id} &middot; {amount}
      </div>
      <div className="tl">{get('product.name', account)}</div>
    </div>
  );
};
