//@flow

import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';

import { AppPermissions } from '../../models/Permission';
import { ButtonMenu } from '../ButtonMenu';
import style from './index.module.scss';
import Visible from '../Visible';

const Button = ({ title, onClick }) => (
  <div className="flex items-center pointer" onClick={onClick}>
    <ProfilePhoto size="small" />
    <span className="dib ml2 mw5 truncate">{title}</span>
  </div>
);

type Props = {
  onLogOut: () => void,
  userName: string,
  saccoName?: string
};

export const UserMenu = ({ userName, onLogOut, saccoName }: Props) => (
  <ButtonMenu Button={Button} title={userName} className={style.UserMenu}>
    <ButtonMenu.Item disabled style={{ pointerEvents: 'none' }}>
      {saccoName ? saccoName : userName}
    </ButtonMenu.Item>
    <Visible to={AppPermissions.CreditSubmit}>
      <ButtonMenu.Item to="/credit">
        <Text id={`navigation.crbSubmit`} />
      </ButtonMenu.Item>
    </Visible>
    <Visible to={AppPermissions.ViewBatchTransactionImports}>
      <ButtonMenu.Item to="/batch_transaction_imports">
        <Text id={`navigation.batchTransactions`} />
      </ButtonMenu.Item>
    </Visible>
    <ButtonMenu.Item to="/settings">
      <Text id={`navigation.settings`} />
    </ButtonMenu.Item>

    <ButtonMenu.Item to="/finance">
      <Text id={`navigation.finance`} />
    </ButtonMenu.Item>

    <ButtonMenu.Item>
      <a
        rel="noopener noreferrer"
        target="_blank"
        style={{ textDecoration: 'none', color: 'inherit' }}
        href="https://help.kwara.com"
      >
        Get Help
      </a>
    </ButtonMenu.Item>

    <ButtonMenu.Item onClick={onLogOut}>
      <Text id={`navigation.logOut`} />
    </ButtonMenu.Item>

    <div className="pl3 pt2 kw-weight-light kw-text-small grey-300">
      Version 1.242.2
    </div>
  </ButtonMenu>
);
