//@flow

import * as React from 'react';

import {
  DeprecatedLoadable,
  LoadingFullScreen
} from '@kwara/components/src/Loadable';
import {
  Member as MemberModel,
  type MemberType,
  Saving,
  type SavingType,
  SavingProduct,
  type SavingProductType
} from '@kwara/models/src';

import Wizard from '../../components/Wizard';
import { type SubStepComponentProps } from '../../components/Wizard';
import { extractRemittanceOptions } from '../../lib/modelUtils/saving';
import { savingPath } from '../../lib/urls';
import { type WizardPageProps } from '..';
import { steps } from './config';
import { Logger } from '@kwara/lib/src/logger';

interface Data extends SavingType {
  depositAmount?: ?string;
  monthlyRemittanceAmount?: string;
  member: MemberType;
  savingProduct?: ?SavingProductType;
}

type Props = WizardPageProps<Data>;

export type SavingsSubStepProps = SubStepComponentProps<Data>;

export default class extends React.Component<Props, *> {
  constructor(props: Props) {
    super(props);

    const params = new URLSearchParams(props.location.search);

    const memberId = params.get('memberId');
    let memberPromise;

    if (typeof memberId === 'string') {
      memberPromise = MemberModel.full()
        .find(memberId)
        .then(response => response.data);
    } else {
      memberPromise = Promise.resolve(null);
    }

    this.state = {
      memberPromise,
      savingProductsPromise: SavingProduct.all().then(response => response.data)
    };
  }

  createSavingAccount = async (data: Data) => {
    const {
      depositAmount,
      monthlyRemittanceAmount,
      member,
      savingProduct
    } = data;
    if (member == null || savingProduct == null) {
      throw new Error('Something went wrong');
    }

    const saving = new Saving({
      account_holder_id: member.id,
      product_id: savingProduct.id,
      amount: depositAmount,
      remittanceOptions: extractRemittanceOptions(data),
      monthlyRemittanceAmount
    });

    const success = await saving.save();

    if (!success) {
      throw saving.errors;
    }

    // We also store changes to the member if for example the employer or staff id was updated
    try {
      await member.save();
      // For whatever reason the API doesn't like when { kin; [] }.
      // and returns a 500.
    } catch (e) {
      Logger.log('ERROR saving member on saving add', JSON.stringify(member));
    }
  };

  renderWizard = (member: ?MemberType) => {
    const { baseUrl, match, history } = this.props;

    const firstStep = member == null ? 'member' : 'product';
    const initialData = member == null ? {} : { member };

    return (
      <Wizard
        analyticsId="SavingAdd"
        baseUrl={baseUrl}
        history={history}
        cancelReturnsTo={savingPath()}
        currentStep={match.params.step}
        currentSubStep={
          match.params.subStep != null
            ? parseInt(match.params.subStep, 10)
            : null
        }
        initialData={initialData}
        onSubmit={this.createSavingAccount}
        steps={steps(this.state)}
        startId={firstStep}
        titleId="SavingAdd.title"
      />
    );
  };

  render() {
    return (
      <DeprecatedLoadable
        Loading={LoadingFullScreen}
        loader={this.state.memberPromise}
        loaded={this.renderWizard}
      />
    );
  }
}
