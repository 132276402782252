// @flow
import React from 'react';

import {
  Currency as CurrencyIntl,
  type CurrencyProps
} from '@kwara/components/src/Intl';

import { store } from '../../models/Store';

export function Currency(props: CurrencyProps) {
  const currencyCode = store.currency;
  return <CurrencyIntl currencyCode={currencyCode} {...props} />;
}
