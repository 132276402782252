//@flow

import * as React from 'react';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';

import { type TillT, type TillStatusT } from '@kwara/models/src';
import { DateTime, Percent } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';

import { tellerPath } from '../../../../lib/urls';
import Table, * as table from '../../../../components/Table';
import { Currency } from '../../../../components/Currency';

import { store } from '../../../../models/Store';

export const Orders = {
  updated: 'updatedAt'
};

type Order = $Values<typeof Orders>;

type FormattedTillT = {
  id: string,
  tellerName: string,
  updated: string,
  openingBalance: number,
  currentBalance: number,
  closingBalance: number,
  status: TillStatusT
};

const orderTellersByAttribute = (tellers: TillT[], attribute: Order): TillT[] =>
  orderBy<TillT>(tellers, [(teller: TillT) => [teller[attribute]]], ['desc']);

const formattedTill = (till: TillT): FormattedTillT => ({
  id: till.id,
  tellerName: till.tellerName,
  tellerId: till.tellerId,
  updated: till.updatedAt,
  openingBalance: till.openingAmount,
  currentBalance: till.currentAmount,
  closingBalance: till.closingAmount,
  status: till.status,
  percentToMaximum: till.currentAmount / store.tellerConfigs.tillMaximum
});

const TillRow = ({ till, pathTo }: { till: TillT, pathTo: string }) => {
  const t = formattedTill(till);

  const rowClass = t.percentToMaximum > 0.7 ? 'bg-red-500' : null;

  return (
    <table.Row className={rowClass} to={pathTo}>
      <table.Cell to={pathTo} className="grey-400 kw-numeric">
        {t.id}
      </table.Cell>
      <table.Cell>{t.tellerId}</table.Cell>
      <table.Cell to={pathTo} className="mw5">
        {t.tellerName}
      </table.Cell>
      <table.Cell to={pathTo}>
        <DateTime value={t.updated} />
      </table.Cell>
      <table.Cell to={pathTo} align="right">
        <Currency value={t.currentBalance} />
      </table.Cell>
      <table.Cell to={pathTo} align="right">
        <Currency value={t.closingBalance} />
      </table.Cell>
      <table.Cell to={pathTo} align="right">
        <Percent value={t.percentToMaximum} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <StatusTag state={t.status} />
      </table.Cell>
    </table.Row>
  );
};

export const TellerListTable = ({
  hasMore,
  errors,
  loading,
  tellers = [],
  onLoadMoreData
}: {
  hasMore: boolean,
  errors: Object[],
  loading: boolean,
  tellers: TillT[],
  onLoadMoreData: () => void
}) => {
  const orderTellers = orderTellersByAttribute(tellers, 'updatedAt');

  return (
    <Table
      heading={
        <table.Row>
          <table.Heading width="100px" translationId="Session id" />
          <table.Heading width="100px" translationId="Till id" />

          <table.Heading translationId="TellerListTable.name" />
          <table.Heading translationId="TellerListTable.lastUpdated" />
          <table.Heading
            align="right"
            translationId="TellerListTable.currentBalance"
          />
          <table.Heading align="right" translationId="Closing balance" />
          <table.Heading align="right" translationId="Percentage to maximum" />
          <table.Heading translationId="TellerListTable.status" />
        </table.Row>
      }
      footer={
        <table.Footer
          colSpan={8}
          isPending={loading}
          onNext={onLoadMoreData}
          hasMore={hasMore}
          errors={errors}
          items={tellers}
          translationBaseId="TellerListTable"
        />
      }
    >
      {map(orderTellers, (till: TillT) => (
        <TillRow
          key={till.id}
          till={till}
          pathTo={tellerPath({ id: till.id })}
        />
      ))}
    </Table>
  );
};
