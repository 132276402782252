// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { Loadable } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';

import Wizard from '../../components/Wizard';
import { memberPath } from '../../lib/urls';
import { steps } from './config';
import { type MemberPageProps } from '..';

import { makeRepayment } from './components/RepaymentForm';

type Props = MemberPageProps;

const includes = [{ loans: 'product' }];

export const MemberRepayment = (props: Props) => {
  const { history } = props;
  const r = useMember(props.match.params.memberId, includes);

  return (
    <Loadable {...r}>
      {member => {
        const redirectTo = memberPath({ id: member.id });
        const loansEligibleForRepayment = member.loansEligibleForRepayment();
        return loansEligibleForRepayment.length > 0 ? (
          <Wizard
            analyticsId="LoanRepayment"
            baseUrl={redirectTo}
            history={history}
            initialData={{
              member,
              accounts: loansEligibleForRepayment,
              accountId: loansEligibleForRepayment[0].id
            }}
            cancelReturnsTo={redirectTo}
            currentStep="repayment"
            onSubmit={makeRepayment}
            steps={steps}
            startId="repayment"
            titleId="LoanRepayment.title"
          />
        ) : (
          <Redirect to={redirectTo} />
        );
      }}
    </Loadable>
  );
};
