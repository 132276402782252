// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import { Text } from '@kwara/components/src/Intl';
import { ERRORS } from '@kwara/lib/src/validator';
import styles from './index.module.scss';

const ruleToError = {
  email: ERRORS.emailMismatch,
  requiresSpecialCharacter: ERRORS.mustContainSpecialCharacter,
  hasLowerCase: ERRORS.noLowerCase,
  hasUpperCase: ERRORS.noUpperCase,
  containsAlphaNum: ERRORS.notAlphaNum,
  minlength: ERRORS.tooShort,
  maxlength: ERRORS.tooLong,
  min: ERRORS.rangeUnderflow
};

export const PasswordHelper = ({
  rules,
  errors: allErrors,
  customTranslationId
}) => {
  const errors = get('password', allErrors) || [];
  const keys = Object.keys(rules);

  return (
    <ul className="pb4 pl2 list grey-400">
      {map(rule => {
        const passes =
          !errors.includes(ruleToError[rule]) &&
          !errors.includes(ERRORS.valueMissing) &&
          !(rule === 'custom' && errors.includes(customTranslationId));

        const translationId = rule === 'custom' ? customTranslationId : rule;
        const values = {
          val: rules[rule]
        };

        if (rule === 'isRequired') {
          return null;
        }

        return (
          <li key={rule} className={passes ? 'black' : ''}>
            <span className="tc dib w1 h1 black">{passes ? '✓' : ''}</span>
            {'  '}
            <div className={styles.fullText}>
              <Text id={`PasswordHelper.${translationId}`} values={values} />
            </div>
            <div className={styles.shortText}>
              <Text
                id={`PasswordHelper.${translationId}.short`}
                values={values}
              />
            </div>
          </li>
        );
      }, keys)}
    </ul>
  );
};
