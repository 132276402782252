// @flow

import fpMap from 'lodash/fp/map';

import { Member } from '@kwara/models/src';

type itemT = { [k: string]: any };
type entityT = 'members' | 'loans';

class Store {
  constructor(useLocalStorage) {
    this.store = useLocalStorage ? localStorage : {};
    this.isLocalStorage = useLocalStorage;
  }
  setItem(type: entityT, item: itemT): void {
    this.store[`${type}_${item.id}`] = item;
  }
  setItems(type: entityT, items: ReadonlyArray<itemT>) {
    fpMap(i => this.setItem(type, i), items);
  }
  getItem(type: entityT, id: string) {
    const result = this.store[`${type}_${id}`];
    if (result) {
      return result;
    }

    // if no member is cached yet we return an empty instance of Member
    // with just the ID
    if (type === 'members') {
      const m = new Member({ id });
      m.isPersisted = true;
      return m;
    }

    // only members are cached as of now, so we just return an empty object for anything else
    return {};
  }
}

function clientCache(name: string, store: Store) {
  return {
    _name: name,
    add(type, item: { [k: string]: any }): void {
      store.setItem(type, item);
    },
    bulkAdd(type, items: any): void {
      store.setItems(type, items);
      return this;
    },
    read(type: 'members', id: string) {
      return store.getItem(type, id);
    }
  };
}

export const ClientCache = clientCache('default', new Store());
