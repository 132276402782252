//@flow
import React, { Component } from 'react';

import { Text } from '@kwara/components/src/Intl';
import Dashboard from '../../../../components/Dashboard';
import DashboardMetric from '../../../../components/DashboardMetric';
import { DeprecatedLoadable } from '@kwara/components/src/Loadable';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { Metric, SavingsMetric } from '@kwara/models/src';

type Props = {};
type State = {
  metricsPromise: Promise<void>
};

export default class extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      metricsPromise: SavingsMetric.all().then(response => response.data)
    };
  }

  render() {
    return (
      <DeprecatedLoadable
        loader={this.state.metricsPromise}
        loaded={metrics => (
          <Dashboard>
            <StatisticRow
              childClasses="pa0 mr4"
              compact={true}
              widthClass="w-50"
            >
              <DashboardMetric
                title={<Text id="Savings.Dashboard.totalSavings" />}
                metric={Metric.findFromArray(metrics, 'total_savings')}
              />
              <DashboardMetric
                title={<Text id="Savings.Dashboard.accruedInterest" />}
                metric={Metric.findFromArray(metrics, 'accrued')}
              />
            </StatisticRow>
            <StatisticRow
              childClasses="pa0 mr4"
              compact={true}
              widthClass="w-50"
            >
              <DashboardMetric
                title={<Text id="Savings.Dashboard.savingFrequency" />}
                metric={Metric.findFromArray(metrics, 'saving_frequency')}
              />
              <DashboardMetric
                title={<Text id="Savings.Dashboard.averageDeposit" />}
                metric={Metric.findFromArray(metrics, 'average_deposit')}
              />
            </StatisticRow>
          </Dashboard>
        )}
      />
    );
  }
}
