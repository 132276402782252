// @flow

import { ProductEdit } from './components/ProductEdit';

export const steps = {
  product: {
    titleId: 'LoanProductEdit.Product.titleId',
    Component: ProductEdit,
    validate: ProductEdit.validate,
    actions: [{ appearsAs: 'submit', behavesAs: 'complete' }]
  }
};
