//@flow

import * as React from 'react';

import { type SubStepComponentProps } from '../../../components/Wizard';

export default ({
  StackChild,
  TextArea,
  Checkbox
}: SubStepComponentProps<>) => (
  <StackChild>
    <TextArea name="notes" placeholderId="SelfService.Notes.placeholder" />
    <Checkbox name="terms" labelId="SelfService.Notes.TermsAndConditions" />
  </StackChild>
);
