//@flow
import mapValues from 'lodash/mapValues';

import { type ModelErrors } from '..';

type Params = {
  [name: string]: string
};

/**
 * Creates an Error that is the same shape as
 * those parsed into Spraypaint's (formerly JSORM) model.errors structure
 *
 */
export default (errors: Params): ModelErrors =>
  mapValues(errors, (status, key) => ({
    attribute: key,
    code: status
  }));

type JsonApiError = {
  id: string,
  status: string,
  code: string,
  title: string,
  detail: string,
  source: {
    pointer?: string,
    parameter?: string
  },
  meta: {
    attribute: string,
    message: string,
    fullMessage: string,
    row_index: number
  }
};

type JsonApiErrorPayload = {
  data?: mixed,
  errors: JsonApiError[]
};

/**
 * See: https://github.com/jsonapi-suite/jsorm/blob/master/src/util/validation-error-builder.ts#L40
 */
export const createErrorsFromApiResponse = (
  payload: JsonApiErrorPayload = { data: null, errors: [] }
): ModelErrors => {
  const errorsAccumulator = {};

  if (payload.errors) {
    payload.errors.forEach(error => {
      const meta = error.meta || {};

      const attribute = meta.attribute || error.code;

      errorsAccumulator[attribute] = {
        title: error.title,
        code: error.code,
        attribute: meta.attribute,
        message: meta.message,
        fullMessage: attribute === 'base' ? meta.message : error.detail,
        rawPayload: error,
        rowIndex: meta.row_index
      };
    });
  }

  return errorsAccumulator;
};
