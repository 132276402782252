//@flow

import * as React from 'react';

import { type SubStepComponentProps } from '../../../../components/Wizard';

import Edit from './Edit';
import { KinFormSpy } from './FormSpy';
import Header from './Header';
import Kin from './Kin';
import { Logger } from '@kwara/lib/src/logger';

type ViewState = 'listing' | 'editing';

type State = {
  view: ViewState,
  editing: ?number
};

export default class extends React.Component<SubStepComponentProps<>, State> {
  state = {
    view: 'listing',
    editing: null
  };

  maxKin = 4;

  edit = (index: number) => {
    this.setState({
      view: 'editing',
      editing: index
    });
  };

  list = () => {
    this.setState({
      view: 'listing',
      editing: null
    });
  };

  remove = (index: number, all: string[]) => {
    this.props.addData({
      kin: [...all.slice(0, index), ...all.slice(index + 1)]
    });
  };

  renderEdit = () => (
    <Edit
      TextArea={this.props.TextArea}
      onDone={this.list}
      index={this.state.editing || 0}
      position={this.state.editing + 1}
    />
  );

  renderKin = (text: string, index: number, all: string[]) => (
    <Kin
      key={index}
      onEdit={() => this.edit(index)}
      onRemove={() => this.remove(index, all)}
      text={text}
      position={index + 1}
    />
  );

  renderKins = () => <KinFormSpy>{kin => kin.map(this.renderKin)}</KinFormSpy>;

  renderHeader = () => (
    <KinFormSpy>
      {kin => (
        <Header
          disabled={kin.length === this.maxKin || this.state.view === 'editing'}
          onAdd={() => this.edit(kin.length)}
        />
      )}
    </KinFormSpy>
  );

  renderContent = () => {
    switch (this.state.view) {
      case 'listing':
        return this.renderKins();
      case 'editing':
        return this.renderEdit();
      default:
        Logger.warn('View state is not valid');
    }
  };

  render() {
    const { StackChild } = this.props;

    return (
      <StackChild>
        {this.renderHeader()}
        {this.renderContent()}
      </StackChild>
    );
  }
}
