// @flow

// This  banks list and structure are deprecated
// in favour of @kwara/components/src/Bank/banks.js
// Keeping this file around only in case it's useful
// to resolve conflicts with the transition.

export type Shape = {
  value: string,
  branches?: { name: string, code: string }[]
}[];
export const full = [
  { value: 'abc_bank' },
  { value: 'bakki_holdco' },
  { value: 'bank_of_africa' },
  { value: 'bank_of_borada' },
  { value: 'bank_of_india' },
  { value: 'barclays_bank_of_kenya' },
  { value: 'charterhouse' },
  { value: 'chase' },
  { value: 'citibank_kenya' },
  { value: 'commercial_bank_africa' },
  { value: 'consolidated_bank_kenya' },
  { value: 'cooperative_bank_kenya' },
  { value: 'credit_bank' },
  { value: 'development_bank_kenya' },
  { value: 'diamond_trust_bank_kenya' },
  { value: 'dib_bank_kenya' },
  { value: 'ecobank_kenya' },
  { value: 'equity_bank_kenya' },
  { value: 'equity_group_holdings' },
  { value: 'family_bank' },
  { value: 'fidelity_commercial_bank' },
  { value: 'first_community_bank' },
  { value: 'guaranty_trust_bank' },
  { value: 'guardian_bank' },
  { value: 'gulf_africa_bank' },
  { value: 'habib_bank_ag_zurich' },
  { value: 'habib_bank_limited' },
  { value: 'hf_group_limited' },
  { value: 'hfc_limited' },
  { value: 'i_and_m_bank' },
  { value: 'i_and_m_holdings' },
  { value: 'imperial_bank_limited' },
  { value: 'jamii_bora_bank' },
  { value: 'kcb_bank_kenya' },
  { value: 'kcb_group' },
  { value: 'm_holdings' },
  { value: 'm_oriental_bank' },
  { value: 'middle_east_bank' },
  { value: 'national_bank_kenya' },
  { value: 'nic_bank_limitd' },
  { value: 'nic_group_plc' },
  { value: 'paramount_bank' },
  { value: 'prime_bank' },
  { value: 'sidian_bank' },
  { value: 'spire_bank' },
  { value: 'stanbic_bank_kenya' },
  { value: 'stanbic_holdings' },
  { value: 'standard_chartered_kenya' },
  { value: 'trans_national_bank' },
  { value: 'uba_kenya_bank' },
  { value: 'victoria_commercial_bank' }
];
