//@flow
import * as React from 'react';

import { NamedRoute } from '@kwara/components/src/Route';
import { isDev } from '@kwara/lib/src/utils';

type Props = {
  component: React.ComponentType<*>,
  fallback: React.ComponentType<*>,
  path?: string,
  exact?: boolean,
  strict?: boolean,
  sensitive?: boolean
};

const DevOnlyRoute = ({
  component: Component,
  fallback: Fallback,
  ...rest
}: Props) => (
  <NamedRoute
    {...rest}
    render={props =>
      isDev ? <Component {...props} /> : <Fallback {...props} />
    }
  />
);

export default DevOnlyRoute;
