//@flow
import React from 'react';
import { FormattedNumber } from '@kwara/components/src/Intl';

import styles from './Number.module.css';

type Props = {
  value: number
};

export default ({ value }: Props) => (
  <span className={styles.Numeric}>
    <FormattedNumber format="noDecimal" value={value} />
  </span>
);
