import * as React from 'react';
import noop from 'lodash/fp/noop';
import cx from 'classnames';

import { ActionButton } from '@kwara/components/src/Button';
import { type ValidationRules } from '@kwara/lib/src/validator';
import Action from '@kwara/components/src/Wizard/Action';
import {
  SubStepContainer,
  patchValues
} from '@kwara/components/src/Wizard/SubStep';
import { type StepConfig } from '../Wizard';
import { type WizardData } from '../../pages/MemberAdd';

import styles from './index.module.css';

// For some reason Flow will not type check this interface if imported in another module.
// It will only work if defined (ie copied) within the same module it's being used
export interface EditableSectionT {
  (): React.Node;
  Title: () => React.Element<Text>;
  editConfig?: { [k: 'memberDetail' | 'loanAdd']: ValidationRules };
}
export type EditableConfig<T> = {
  config?: StepConfig,
  initialData: T,
  readOnly?: boolean
};
type updateFn = (...data: any) => Promise<void>;
type UpdaterProps = {
  children: React.Node,
  value: { onUpdate: updateFn }
};

export const EditableContext = React.createContext({ onUpdate: noop });

// Wrap with this any component that needs to be re-fetch/re-render after an edit is successful
export const Updater = ({ children, value }: UpdaterProps) => (
  <EditableContext.Provider value={value}>{children}</EditableContext.Provider>
);

type EditableSectionProps<WizardDataT> = {
  onSave: WizardDataT => Promise<?boolean>,
  children: React.Node,
  initialData: WizardDataT,
  config: StepConfig,
  readOnly: boolean,
  ariaLabel?: string,
  editClassNames?: string,
  isEditing?: boolean,
  canCancel?: boolean,
  overflowScroll?: boolean
};

export const EditableSection = (props: EditableSectionProps<WizardData>) => {
  const {
    onSave = instance => instance.save(), // if you don't pass a different save function the instance will be automatically saved as is
    initialData,
    children,
    canCancel = true,
    config,
    readOnly,
    type = 'edit',
    ariaLabel = 'Edit',
    containerClassNames = '',
    editClassNames,
    actions = [
      // We have to do this as we're skipping the stepParser that does
      // new Action() for us for each step. This avoids us having to define it for each and every
      // new config we add
      new Action({
        appearsAs: 'submit',
        behavesAs: 'complete'
      })
    ],
    overflowScroll
  } = props;
  const { onUpdate } = React.useContext(EditableContext);
  const [isEditing, setIsEditing] = React.useState(!canCancel);
  const [data, setData] = React.useState(initialData);
  const [errors, setErrors] = React.useState([]);
  const [isPending, setIsPending] = React.useState(false);
  React.useEffect(() => setData(initialData), [initialData]);

  const open = () => setIsEditing(true);
  const close = () => setIsEditing(false);
  const save = edited => {
    setIsPending(true);
    return onSave(edited)
      .then(saved => {
        // this sucks a bit.
        // As you said we have to catch the error in the then because of how Spraypaint works
        if (!saved) {
          return setErrors(edited.errors);
        }

        close();
        onUpdate(edited);
      })
      .catch(setErrors)
      .finally(() => setIsPending(false));
  };

  const fullConfig = {
    ...config,
    actions
  };

  if (readOnly) {
    return children;
  }

  return (
    <div className={containerClassNames}>
      {isEditing ? (
        <SubStepContainer
          currentState={initialData}
          error={errors}
          onAction={({ action }) => {
            if (action.behavesAs === 'complete') {
              return save(data);
            }

            return close();
          }}
          onChange={updates => {
            setData(patchValues(data, updates));
            return Promise.resolve(data);
          }}
          subStep={fullConfig}
          isProcessing={isPending}
          bottomMargin={false}
          canCancel={canCancel}
          overflowScroll={overflowScroll}
        />
      ) : (
        <div className={styles.ReadMode}>
          <div className={styles.Children}>{children}</div>
          <div className={cx('flex justify-end', editClassNames)}>
            <ActionButton
              className="bg-white"
              aria-label={ariaLabel}
              hidden={isEditing}
              type={type}
              onClick={open}
            />
          </div>
        </div>
      )}
    </div>
  );
};
