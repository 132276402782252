import * as React from 'react';
import find from 'lodash/fp/find';
import get from 'lodash/fp/get';

import { Logger } from '@kwara/lib/src/logger';

import { store } from '../../../webapp-sacco/src/models/Store';

function getBank({ bankCode, banks = store.banks }) {
  return find(b => b.id === bankCode, banks);
}

export const Bank = ({ bankCode }) => {
  const bank = getBank({ bankCode });

  if (!bank) {
    Logger.warn(`Missing bank details for bankCode: ${bankCode}`);
    return null;
  }

  return <span>{bank.name}</span>;
};

export const BankBranch = ({ bankCode, branchCode, banks = store.banks }) => {
  const bank = find(b => b.id === bankCode, banks);
  const branch = find(b => b.id === branchCode, get('bankBranches', bank));

  if (!branch) {
    Logger.warn(
      `Missing branch details for bankCode: ${bankCode}, bankBranch: ${branchCode}`
    );
    return null;
  }

  return <span>{branch.name}</span>;
};
