//@flow
import Invitation from '@kwara/models/src/models/Invitation';
import { UserTypes } from '@kwara/models/src';

import config from '../config';
import IdVerification from './IdVerification';

export type { ConfigType } from '@kwara/lib/src/config';
export type { IDCheckResponse, CheckPayload } from './IdVerification';

export const invitation = new Invitation({ config, userType: UserTypes.USER });

// Permissions require access to an instance of the ./lib/auth
// so must stay in the webapp for now
export {
  default as permission,
  Permission,
  AppPermissions
} from './Permission';

export type { AppPermission, AppPermissionValue } from './Permission';

export const idVerification = new IdVerification({ config });
