//@flow
import * as React from 'react';
import classnames from 'classnames';

import { ActionButton, Attribute } from '@kwara/components/src';
import { ProfilePhoto } from '@kwara/components/src/ProfilePhoto';
import { type MemberType } from '@kwara/models/src';

import Column from './Column';

import styles from './index.module.scss';

type Props = {
  highlighted: boolean,
  member: MemberType
};

export default function NonFinancialResult({
  member,
  highlighted = false,
  ...props
}: Props) {
  return (
    <div
      className={classnames(
        styles.Result,
        'flex',
        highlighted ? styles.isHighlighted : null
      )}
      {...props}
    >
      <Column border={false}>
        <div className={classnames(styles.noFlex, 'mr3')}>
          <ProfilePhoto size="small" />
        </div>
        <div className={classnames(styles.NonFinancialResultMember, 'mr3')}>
          <Attribute
            labelId="MemberSearch.Result.member"
            labelSize="small"
            values={{ id: member.id }}
            value={member.fullName()}
            valueClassName="truncate"
          />
        </div>
      </Column>
      <Column border={false} right>
        <ActionButton />
      </Column>
    </div>
  );
}
