//@flow

import * as React from 'react';

import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import { type SubStepComponentProps } from '../../../components/Wizard';

const AddNumberPlaceholderLink = ({ onClick }: { onClick: () => void }) => (
  <Link
    onClick={evt => {
      evt.preventDefault();
      onClick();
    }}
  >
    <Text id="AddMember.Contact.Mobile.info" />
  </Link>
);

export default class Contact extends React.Component<
  SubStepComponentProps<>,
  *
> {
  constructor(props: SubStepComponentProps<>) {
    super(props);

    this.state = {
      showSecondaryNumber: props.data && props.data.secondaryPhoneNumber != null
    };
  }

  showSecondaryNumber = () => {
    this.setState({
      showSecondaryNumber: true
    });
  };

  render() {
    const { StackChild, TextField } = this.props;
    const { showSecondaryNumber } = this.state;

    return (
      <StackChild>
        <TextField
          name="phoneNumber"
          labelId="AddMember.Contact.Mobile.label"
          placeholderId="AddMember.Contact.Mobile.placeholder"
          info={
            showSecondaryNumber ? null : (
              <AddNumberPlaceholderLink onClick={this.showSecondaryNumber} />
            )
          }
        />

        {showSecondaryNumber ? (
          <TextField
            name="secondaryPhoneNumber"
            labelId="AddMember.Contact.SecondaryNumber.label"
            placeholderId="AddMember.Contact.SecondaryNumber.placeholder"
          />
        ) : null}

        <TextField
          name="email"
          errorBaseId="AddMember.Contact.Email"
          labelId="AddMember.Contact.Email.label"
          placeholderId="AddMember.Contact.Email.placeholder"
        />
      </StackChild>
    );
  }
}
