//@flow
import * as React from 'react';
import { SubscribedSelectField as SelectField } from '@kwara/components/src/Form/SubscribedSelectField';

import { type SubStepComponentProps } from '../../../components/Wizard';

export const Confirm = ({
  StackChild,
  TextArea,
  Checkbox
}: SubStepComponentProps<>) => {
  return (
    <>
      <StackChild>
        <TextArea
          name="comment"
          required
          labelId="MemberClose.Confirm.Note.label"
          infoId="MemberClose.Confirm.Note.info"
        />
      </StackChild>
      <StackChild>
        <SelectField name="reason">
          <SelectField.Option
            disabled
            key={0}
            value=""
            translationId={'Stima.Reason'}
          />
          <SelectField.Option
            key={1}
            value="Withdrawal"
            translationId={'Stima.CloseWithdrawal'}
          />
          <SelectField.Option
            key={2}
            value="Deceased"
            translationId={'Stima.Deceased'}
          />
          <SelectField.Option
            key={3}
            value="Other"
            translationId={'Stima.Other'}
          />
        </SelectField>
      </StackChild>
      <StackChild>
        <Checkbox
          name={`Stima.discount`}
          infoId={`Stima.discount.help`}
          labelId="Stima.discount"
        />
      </StackChild>
    </>
  );
};

Confirm.validate = {
  comment: {
    isRequired: () => true
  }
};
