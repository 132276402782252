//@flow
import * as React from 'react';
import classnames from 'classnames';

import { Text } from '@kwara/components/src/Intl';

import styles from './index.module.scss';

// $FlowFixMe Disjoint union
const LoadingFullScreen = ({ error }: ErrorProps | LoadingProps) => (
  <div
    className={classnames(
      'fixed w-100 h-100 top-0 right-0 bottom-0 left-0 flex justify-center items-center bg',
      styles.LoadingFullScreen
    )}
  >
    <span className="">
      <Text id={error == null ? 'Loading.loading' : 'Loading.error'} />
    </span>
  </div>
);

export default LoadingFullScreen;
