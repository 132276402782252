//@flow
import React from 'react';

import PageLayout from '@kwara/components/src/PageLayout';
import { DeprecatedLoadable } from '@kwara/components/src/Loadable';
import { Member } from '@kwara/models/src';

import { DataViewWrapper } from '../../components/DataViewWrapper';

import Dashboard from './components/Dashboard';
import Head from '../../components/Head';
import MemberList from './components/MemberList';

import type { ListPageProps } from '..';

type Props = ListPageProps;

const Content = ({
  hasMore,
  loading,
  errors,
  filterBy,
  data,
  pristine,
  onLoadMoreData,
  changeSearchValue,
  totalNumberResults,
  onFilterChange,
  searchValue
}: Props) => (
  <PageLayout overview={<Dashboard />}>
    <Head titleId="MemberList.title" />
    <DeprecatedLoadable
      loader={pristine ? new Promise(() => {}) : Promise.resolve(data)}
      loaded={members => (
        <MemberList
          members={members}
          filterBy={filterBy}
          onLoadMoreData={onLoadMoreData}
          hasMore={hasMore}
          onFilterChange={onFilterChange}
          onSearchChange={changeSearchValue}
          searchValue={searchValue}
          loading={loading}
          errors={errors}
          totalNumResults={totalNumberResults}
        />
      )}
    />
  </PageLayout>
);

export const Members = ({ location }) => (
  <DataViewWrapper
    component={Content}
    scope={Member.includes()}
    pathname="/"
    location={location}
    enableSearch={true}
  />
);
