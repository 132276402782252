//@flow
import * as React from 'react';

import { type SubStepComponentProps } from '../../../components/Wizard';

import { type WizardData } from '..';

export default ({
  StackChild,
  TextArea
}: SubStepComponentProps<WizardData>) => {
  return (
    <StackChild>
      <TextArea name="note" placeholderId="MemberApprove.Note.placeholder" />
    </StackChild>
  );
};
