// @flow
import * as React from 'react';
import pipe from 'lodash/fp/pipe';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import merge from 'lodash/merge';
import toString from 'lodash/toString';
import set from 'lodash/set';
import values from 'lodash/fp/values';
import { FormSpy } from 'react-final-form';

import {
  CollateralTypes,
  CollateralFields,
  type CollateralTypeT,
  type CollateralT,
  Collateral
} from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';
import {
  Condition,
  BankSelect,
  SubscribedSelectField as SelectField,
  SubscribedTextField as TextField
} from '@kwara/components/src/Form';

import typeof { Child } from '../../../../../components/Stack';
import { type LoanFormData } from '../../..';
import { store } from '../../../../../models/Store';

type Props = {
  onSelect: (asset: CollateralT[]) => void,
  onCancel: () => void,
  StackChild: Child,
  TextArea: React.ComponentType<any>,
  path: string,
  data: LoanFormData,
  index: number
};

const CollateralTypesValues: { value: CollateralTypeT }[] = pipe(
  values,
  map(value => ({ value }))
)(CollateralTypes);

// This is FAR from ideal but....
// Ensure that the changes as they appear in the form fields are applied to the original
// object. The full array is returned so it can be passed up to the form.
function commitChanges(
  collaterals: CollateralT[] = [],
  index: number,
  formCollaterals: CollateralT[]
) {
  // this is only need to make Flow happy that we're passing a string to Lodash methods
  const idx = toString(index);
  // Duplicate the object to avoid mutating the original instance.
  const original = getOr({}, idx, collaterals);
  // Make sure we are always dealing with a SPraypaint instance
  const asInstance = original.isSpraypaintInstance
    ? original.dup()
    : Collateral.create(original);
  // Pick the collateral that have been changed just now (this is a plain object, NOT an instance)
  const changed = get(index, formCollaterals);
  // Commit the changes
  // (merging is fixed in Lodash v.4.17.5 https://github.com/lodash/lodash/wiki/Changelog#v4175)
  /* eslint-disable-next-line prototype-pollution-security-rules/detect-merge-objects */
  const updated = merge(asInstance, changed);
  // Replace the collateral that was edited with the newly created duplicate or add it if it's a
  // new one
  set(collaterals, idx, updated);
  return collaterals;
}

export const CollateralSelect = ({
  onCancel,
  onSelect,
  StackChild,
  TextArea,
  path,
  data,
  index
}: Props) => (
  <FormSpy>
    {formSpyProps => {
      const collaterals = get('values.collaterals', formSpyProps);
      return (
        <>
          <StackChild>
            <div className="pb2 flex justify-between items-center">
              <p className="ma0 kw-text-regular grey-400">
                <Text id="LoanAdd.Security.Collateral.selectTitle" />
              </p>
            </div>
          </StackChild>
          <StackChild>
            <SelectField
              labelId="LoanAdd.Security.Collateral.Type"
              name={path + CollateralFields.type}
            >
              <SelectField.Option value="NONE" />
              {map(
                type => (
                  <SelectField.Option
                    translationId={`LoanAdd.Security.Collateral.Type.${type.value}`}
                    value={type.value}
                    key={type.value}
                  />
                ),
                CollateralTypesValues
              )}
            </SelectField>
          </StackChild>
          <StackChild>
            <TextField
              name={path + CollateralFields.amount}
              labelId="LoanAdd.Security.Collateral.Amount.label"
              placeholderId={`Currency.${store.currency}`}
              required
            />
          </StackChild>
          <StackChild>
            <Condition
              when={path + CollateralFields.type}
              is={CollateralTypes.BANK_ACCOUNT}
            >
              <BankSelect name={path + CollateralFields.bank} />
              <TextField
                name={path + CollateralFields.branch}
                labelId="Forms.TransactionForm.Branch.label"
                placeholderId="Forms.TransactionForm.Account.placeholder"
                required
              />
              <TextField
                name={path + CollateralFields.account}
                labelId="Forms.TransactionForm.Account.label"
                placeholderId="Forms.TransactionForm.Account.placeholder"
                required
              />
            </Condition>

            <Condition
              when={path + CollateralFields.type}
              is={CollateralTypes.LAND}
            >
              <TextField
                labelId={`LoanAdd.Security.Collateral.LAND.landRegistration`}
                placeholderId={`LoanAdd.Security.Collateral.LAND.landRegistration.placeholder`}
                name={path + CollateralFields.landRegistration}
              />
            </Condition>
          </StackChild>
          <StackChild>
            <Condition
              when={path + CollateralFields.type}
              is={CollateralTypes.LIFE_INSURANCE}
            >
              <TextField
                labelId={`LoanAdd.Security.Collateral.LIFE_INSURANCE.insuranceCompany`}
                name={path + CollateralFields.insuranceCompany}
              />
              <TextField
                labelId={`LoanAdd.Security.Collateral.LIFE_INSURANCE.insuranceNumber`}
                name={path + CollateralFields.insuranceNumber}
              />
            </Condition>

            <Condition
              when={path + CollateralFields.type}
              is={CollateralTypes.STOCK}
            >
              <TextField
                labelId="LoanAdd.Security.Collateral.STOCK.label"
                placeholderId="LoanAdd.Security.Collateral.STOCK.placeholder"
                name={path + CollateralFields.stockCds}
              />
            </Condition>
          </StackChild>
          <StackChild>
            <TextArea
              labelId={`LoanAdd.Security.Collateral.Narration`}
              name={path + CollateralFields.narration}
            />
          </StackChild>

          <StackChild>
            <Link className="mla" type="destructive" onClick={onCancel}>
              <Text id="LoanAdd.Security.Collateral.cancel" />
            </Link>
            <span className="ml3 dib">
              <Link
                className="mla"
                type="primary"
                onClick={() => {
                  const res = commitChanges(
                    data.collaterals,
                    index,
                    collaterals
                  );
                  onSelect(res);
                }}
              >
                <Text id="LoanAdd.Security.Collateral.confirm" />
              </Link>
            </span>
          </StackChild>
        </>
      );
    }}
  </FormSpy>
);
