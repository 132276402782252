import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import assignAll from 'lodash/fp/assignAll';

import { Loadable } from '@kwara/components/src/Loadable';
import { mapIndexed } from '@kwara/lib/src/lodash';
import { getCurrentDate } from '@kwara/lib/src/dates';
import { sumBy, equals } from '@kwara/lib/src/currency';
import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

import { useGlAccounts } from '../../../models/request';
import Table, { Heading, Row, Cell } from '../../../components/Table';
import { Grid } from '../../../components/Grid';
import { Panel } from '../../../components/ActionModal';
import {
  GeneralLedgerSelectField,
  SubscribedDatePicker,
  DatePicker,
  Field as BasicField
} from '../../../components/Form';

const useListenForChanges = callback => {
  const [changesDetected, setChangesDetected] = React.useState(false);

  if (changesDetected) {
    callback();
    setChangesDetected(false);
  }

  const triggerReset = () => setChangesDetected(true);
  return { triggerReset };
};

const heading = (
  <Row>
    <Heading translationId="JournalEntryAdd.Form.Header.amount" />
    <Heading translationId="JournalEntryAdd.Form.Header.account" />
    <Heading width="90px" />
  </Row>
);

export const JournalEntryForm = ({
  StackChild,
  SelectField,
  TextField,
  TextArea,
  resetValidation
}) => {
  const { triggerReset } = useListenForChanges(resetValidation);
  const r = useGlAccounts();

  return (
    <StackChild size="wide">
      <Panel>
        <span className="kw-text-medium kw-weight-bold">
          <Text id="JournalEntryAdd.Form.debits" />
        </span>
        <Table heading={heading}>
          <Row>
            <Cell>
              <TextField name="debitAmount" compact margin={false} />
            </Cell>
            <Cell>
              <Loadable {...r}>
                {accounts => (
                  <GeneralLedgerSelectField
                    name="debitAccount"
                    accounts={accounts}
                    compact
                    margin={false}
                  />
                )}
              </Loadable>
            </Cell>
            <Cell />
          </Row>
        </Table>

        <FieldArray name="credits">
          {({ fields }) => {
            return (
              <div key={fields.name} className="mt3">
                <span className="kw-text-medium kw-weight-bold">
                  <Text
                    id="JournalEntryAdd.Form.credits"
                    values={{ credits: fields.length }}
                  />
                </span>
                <Table heading={heading}>
                  {fields.map((name, index) => (
                    <Row key={name}>
                      <Cell>
                        <TextField
                          name={`${name}.credit_amount`}
                          compact
                          margin={false}
                          errorBaseId="JournalEntryAdd.Form.credits"
                        />
                      </Cell>
                      <Cell>
                        <Loadable {...r}>
                          {accounts => (
                            <GeneralLedgerSelectField
                              SelectField={SelectField}
                              name={`${name}.credit_account`}
                              accounts={accounts}
                              compact
                              margin={false}
                            />
                          )}
                        </Loadable>
                      </Cell>
                      <Cell>
                        {fields.length > 1 && (
                          <Link
                            type="destructive"
                            onClick={() => {
                              fields.remove(index);
                              triggerReset();
                            }}
                          >
                            <Text id="JournalEntryAdd.Form.credit.remove" />
                          </Link>
                        )}
                      </Cell>
                    </Row>
                  ))}
                </Table>
                <Link
                  type="primary"
                  onClick={() => {
                    fields.push({ credit_amount: 0 });
                    triggerReset();
                  }}
                >
                  <Text id="JournalEntryAdd.Form.credit.add" />
                </Link>
              </div>
            );
          }}
        </FieldArray>
      </Panel>

      <Panel>
        <Grid columns={2} width="w-50" border={false}>
          <SubscribedDatePicker
            name="date"
            labelId="JournalEntryAdd.Form.valueDate.label"
            disabledDays={{ after: getCurrentDate() }}
          />
          <BasicField
            required
            disabled
            name="createdAt"
            size="medium"
            labelId="JournalEntryAdd.Form.createdAtDate.label"
          >
            <DatePicker name="createdAt" disabled value={getCurrentDate()} />
          </BasicField>
        </Grid>
        <TextArea name="notes" labelId="JournalEntryAdd.Form.Notes.label" />
      </Panel>
    </StackChild>
  );
};

JournalEntryForm.validate = data => {
  return {
    debitAmount: {
      isRequired: () => true,
      currency: true,
      nonZero: true
    },
    debitAccount: {
      isRequired: () => true
    },
    date: {
      isNotFuture: true
    },
    ...assignAll(
      mapIndexed((_, i) => {
        return {
          [`credits[${i}].credit_amount`]: {
            isRequired: () => true,
            currency: true,
            nonZero: true,
            custom: (_, allData) => {
              const { credits, debitAmount } = allData;

              const totalCredits = sumBy('credit_amount', credits);

              if (!equals(totalCredits, debitAmount)) {
                return 'creditsMustEqualDebits';
              }

              return null;
            }
          },
          [`credits[${i}].credit_account`]: {
            isRequired: () => true
          }
        };
      }, data.credits)
    )
  };
};
