// @flow

import * as React from 'react';
import size from 'lodash/fp/size';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import pipe from 'lodash/fp/pipe';
import reject from 'lodash/fp/reject';
import isEqual from 'lodash/fp/isEqual';
import keyBy from 'lodash/fp/keyBy';
import { FieldArray } from 'react-final-form-arrays';

import {
  SubscribedTextField as TextField,
  SubscribedSelectField as SelectField
} from '@kwara/components/src/Form';
import { type LoanType } from '@kwara/models/src';
import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';
import { Statistic } from '@kwara/components/src/Statistic';

import { Panel } from '../../../components/ActionModal';

type Props = {
  loan: LoanType
};

export function Fees({ loan }: Props) {
  const { fees = [] } = loan.product || {};

  if (size(fees) < 1) {
    return null;
  }

  const feesMap = keyBy('id', fees);
  const isRequired = id =>
    pipe(
      get(`${id}.feeApplication`),
      isEqual('REQUIRED')
    )(feesMap);
  // a fee can be either flat, fixed fee or work as a percentage of the loan amount
  const isCalculationMethod = (type, id) =>
    pipe(
      get(`${id}.amountCalculationMethod`),
      isEqual(type)
    )(feesMap);

  return (
    <Panel>
      <FieldArray name="fees">
        {({ fields }) => {
          const optionalFees = reject(f => isRequired(get('id', f)), fees);
          return (
            <div key={fields.name}>
              {fields.map((name, index) => {
                const fieldValue = get(['value', index], fields);
                const thisFeeId = getOr('', 'id', fieldValue);
                const thisFee = get(thisFeeId, feesMap);
                const isRequiredFee = isRequired(thisFeeId);

                return (
                  <div className="bb b--light-grey-400 mb2" key={name}>
                    {isRequiredFee ? (
                      <Statistic
                        title={<Text id="PaymentForm.fee.required.labelId" />}
                        value={thisFee.name}
                        size="large"
                        padding
                      />
                    ) : (
                      <SelectField
                        required
                        name={`${name}.id`}
                        labelId="PaymentForm.fee.labelId"
                        readOnly={isRequiredFee}
                      >
                        <SelectField.Option
                          translationId="PaymentForm.fee.options.default"
                          disabled
                          value=""
                        />
                        {map(
                          fee => (
                            <SelectField.Option key={fee.id} value={fee.id}>
                              {fee.name}
                            </SelectField.Option>
                          ),
                          optionalFees
                        )}
                      </SelectField>
                    )}
                    {isCalculationMethod('FLAT', thisFeeId) ? (
                      // If fee is FLAT, one can input the amount
                      // if the amount is pre-set it will be prepopulated
                      <>
                        {get('amount', thisFee) ? (
                          <Text
                            id="PaymentForm.fee.amountHint"
                            values={{
                              amount: get('amount', thisFee)
                            }}
                          />
                        ) : (
                          <TextField
                            name={`${name}.amount`}
                            size="medium"
                            hidden={!thisFeeId}
                            leftGlyph="Currency.orgCurrency"
                            labelId="PaymentForm.fee.amount"
                            placeholderId="PaymentForm.fee.placeholder"
                          />
                        )}
                      </>
                    ) : null}
                    {isCalculationMethod(
                      'LOAN_AMOUNT_PERCENTAGE',
                      thisFeeId
                    ) ? (
                      <Text
                        id="PaymentForm.fee.percentageHint"
                        values={{
                          percentage: get('percentageAmount', thisFee)
                        }}
                      />
                    ) : null}
                    <div className="mb4 mt1">
                      <Link
                        type="destructive"
                        disabled={isRequiredFee}
                        onClick={() => fields.remove(index)}
                      >
                        <Text id="PaymentForm.fee.remove" />
                      </Link>
                    </div>
                  </div>
                );
              })}
              <Button onClick={() => fields.push({})}>
                <Text id="PaymentForm.fee.add" />
              </Button>
            </div>
          );
        }}
      </FieldArray>
    </Panel>
  );
}
