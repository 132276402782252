//@flow
import React, { Component } from 'react';

import { DeprecatedLoadable } from '@kwara/components/src/Loadable';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Text } from '@kwara/components/src/Intl';

import { Metric, MemberMetric } from '@kwara/models/src';

import Dashboard from '../../../../components/Dashboard';
import DashboardMetric from '../../../../components/DashboardMetric';

type Props = {};
type State = {
  metricsPromise: Promise<void>
};

export default class extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      metricsPromise: MemberMetric.all().then(response => response.data)
    };
  }

  render() {
    return (
      <DeprecatedLoadable
        loader={this.state.metricsPromise}
        loaded={metrics => (
          <Dashboard>
            <StatisticRow
              childClasses="pa0 mr4"
              compact={true}
              widthClass="w-50"
            >
              <DashboardMetric
                title={<Text id="Members.Dashboard.activeMembers" />}
                metric={Metric.findFromArray(metrics, 'total_members')}
              />
              <DashboardMetric
                title={<Text id="Members.Dashboard.totalLoansBalance" />}
                metric={Metric.findFromArray(metrics, 'total_loans')}
              />
            </StatisticRow>
            <StatisticRow
              childClasses="pa0 mr4"
              compact={true}
              widthClass="w-50"
            >
              <DashboardMetric
                title={<Text id="Members.Dashboard.totalSavings" />}
                metric={Metric.findFromArray(metrics, 'total_savings')}
              />
              <DashboardMetric
                title={<Text id="Members.Dashboard.shareCapital" />}
                metric={Metric.findFromArray(metrics, 'share_capital')}
              />
            </StatisticRow>
          </Dashboard>
        )}
      />
    );
  }
}
