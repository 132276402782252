//@flow

import * as React from 'react';

import { ActionButton } from '@kwara/components/src/Button';

import { Row } from '.';

type Props = {
  children: React.ChildrenArray<typeof Row>,
  initiallyOpen: boolean,
  addIcon?: boolean,
  iconColor?: string,
  collapseIcon?: string,
  expandIcon?: string,
  hideBorder?: boolean
};
type State = {
  open: boolean
};

class Collapsible extends React.Component<Props, State> {
  static defaultProps = {
    initiallyOpen: false,
    addIcon: true,
    hideBorder: true,
    iconColor: 'indigo500',
    collapseIcon: 'collapse',
    expandIcon: 'expand'
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      open: props.initiallyOpen
    };
  }

  render() {
    const { collapseIcon, expandIcon, hideBorder } = this.props;
    return (
      <>
        {React.Children.map(
          this.props.children,
          // $FlowFixMe: Complains about exact vs inexact type ¯\_(ツ)_/¯
          (element, index) => {
            if (index === 0) {
              return React.cloneElement(element, {
                ...(this.props.addIcon && {
                  icon: (
                    <ActionButton
                      type={this.state.open ? collapseIcon : expandIcon}
                      col={this.props.iconColor}
                      hideBorder={hideBorder}
                      className="ml-auto"
                    />
                  )
                }),
                onClick: () => {
                  this.setState({ open: !this.state.open });
                }
              });
            } else if (this.state.open) {
              return element;
            }

            // This is a hack. This Component makes it very hard to change the template
            // and we somehow need to display the rows on print even if the dropdown is closed
            return React.cloneElement(element, {
              className: 'show-on-print'
            });
          }
        )}
      </>
    );
  }
}

export default Collapsible;
