//@flow

import React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { Denominations } from '../../../../../components/Denominations';
import { ActionModal, Panel } from '../../../../../components/ActionModal';

import { ModalAction } from '../../..';
import { Form } from 'react-final-form';
import {
  SubscribedTextField,
  SubscribedSelectField,
  TextField
  //SubscribedSelectField
} from '@kwara/components/src/Form';

import { store } from '../../../../../models/Store';

type Props = {
  updateModal: ModalAction,
  closeTill: () => void
};

export const ConfirmationClose = ({ updateModal, closeTill }: Props) => (
  <ActionModal
    header={false}
    titleId="Till.Confirmation.Modal.title"
    onCancel={() => updateModal({ activeModal: null })}
    cancelId="Till.Confirmation.Modal.cancel"
    onConfirm={closeTill}
    confirmId="Till.Confirmation.Modal.confirm"
  >
    <Panel>
      <span className="kw-text-large kw-weight-bold ">
        <Text id="Till.Confirmation.Modal.main" />
      </span>
      {/* <br />
      <span className="kw-text-medium grey-400">
        <Text id="Till.Confirmation.Modal.subtitle" />
      </span> */}
    </Panel>
    <Panel>
      <span className="kw-text-large kw-weight-bold ">Denominations</span>
      <Denominations amount={store.currentTill.currentAmount} />
    </Panel>
  </ActionModal>
);
/*
• Member number
• Number of leaves
• Branch to pick up from
• Payment mode (account debit/ cash payment)
*/

export const ATMModal = ({ updateModal }) => (
  <ActionModal
    header={false}
    titleId="Stima.ATM.title"
    onCancel={() => updateModal({ activeModal: null })}
    cancelId="Stima.ATM.cancel"
    onConfirm={() => updateModal({ activeModal: 'success' })}
    confirmId="Stima.ATM.confirm"
  >
    <Panel>
      <span className="kw-text-large kw-weight-bold ">
        <Text id="Stima.ATM.main" />
      </span>
    </Panel>

    <Panel>
      <Form
        onSubmit={() => {}}
        initialValues={{}}
        render={({ _values }) => (
          <>
            <SubscribedTextField name="a" labelId="Stima.ATM.memberNumber" />
            <SubscribedTextField name="b" labelId="Stima.ATM.branchPickup" />

            <SubscribedSelectField name="d" labelId="Stima.ATM.paymentMethod">
              <SubscribedSelectField.Option value="1">
                Account debit
              </SubscribedSelectField.Option>
              <SubscribedSelectField.Option value="2">
                Cash
              </SubscribedSelectField.Option>
            </SubscribedSelectField>

            <SubscribedSelectField name="e" labelId="Stima.ATM.requestType">
              <SubscribedSelectField.Option value="1">
                New
              </SubscribedSelectField.Option>
              <SubscribedSelectField.Option value="2">
                Replacement
              </SubscribedSelectField.Option>
              <SubscribedSelectField.Option value="1">
                Renewal
              </SubscribedSelectField.Option>
            </SubscribedSelectField>
          </>
        )}
      />
    </Panel>
  </ActionModal>
);

export const ChequeModal = ({ updateModal }) => (
  <ActionModal
    header={false}
    titleId="Stima.cheque.title"
    onCancel={() => updateModal({ activeModal: null })}
    cancelId="Stima.ATM.cancel"
    onConfirm={() => updateModal({ activeModal: 'success' })}
    confirmId="Stima.ATM.confirm"
  >
    <Panel>
      <span className="kw-text-large kw-weight-bold ">
        <Text id="Stima.cheque.title" />
      </span>
    </Panel>

    <Panel>
      <Form
        onSubmit={() => {}}
        initialValues={{}}
        render={({ _values }) => (
          <>
            <SubscribedTextField name="a" labelId="Stima.ATM.memberNumber" />
            <SubscribedTextField name="b" labelId="Stima.ATM.branchPickup" />
            <SubscribedTextField name="c" labelId="Stima.ATM.leaves" />

            <SubscribedSelectField name="d" labelId="Stima.ATM.paymentMethod">
              <SubscribedSelectField.Option value="1">
                Account debit
              </SubscribedSelectField.Option>
              <SubscribedSelectField.Option value="2">
                Cash
              </SubscribedSelectField.Option>
            </SubscribedSelectField>
          </>
        )}
      />
    </Panel>
  </ActionModal>
);
