//@flow
import * as React from 'react';

import Asset from '@kwara/components/src/Asset';
import { TextField } from '../Form';

export type Props = {
  name: string,
  value: string,
  onChange: (evt: SyntheticInputEvent<>) => void,
  searching: boolean
};

export default ({ searching, ...props }: Props) => (
  <TextField
    className="bg-white"
    clearable
    leftGlyph={Asset.Glyphs.Search}
    rightGlyph={searching ? Asset.Glyphs.Spinner : null}
    placeholderId="MemberSearch.TextField.placeholder"
    size="regular"
    {...props}
  />
);
