// @flow

import TagManager from 'react-gtm-module';
import get from 'lodash/fp/get';

import { isDev } from '@kwara/lib/src/utils';

import config from '../config';
import { store } from '../models/Store';

const GoogleTagManagerCodes = {
  demo: 'GTM-PTTZ5WN',
  sandbox: 'GTM-T9FHFZZ',
  production: 'GTM-NKRD62T'
};

const isEnabled = !isDev;

const initializeGoogleTagManager = () => {
  if (isEnabled) {
    const tagManagerArgs = {
      gtmId: GoogleTagManagerCodes[get('env', config)],
      dataLayerName: 'PageDataLayer'
    };
    TagManager.initialize(tagManagerArgs);
  }
};

const addPageDataLayer = () => {
  if (isEnabled) {
    const dataLayerArgs = {
      dataLayer: {
        organisation: store.organisation,
        branch: store.branchName,
        event: 'userLogged'
      },
      dataLayerName: 'PageDataLayer'
    };
    TagManager.dataLayer(dataLayerArgs);
  }
};

export const GoogleTagManager = {
  initialize: initializeGoogleTagManager,
  addDataLayer: addPageDataLayer
};
