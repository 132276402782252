// @flow

import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import invoke from 'lodash/fp/invoke';

import type { TillTransactionType } from '@kwara/models/src';
import type { UserT } from '@kwara/models/src';
import {
  Section,
  Date,
  Cell,
  Row,
  Template,
  DefaultText as Text,
  SubtitleText as Subtitle,
  Currency
} from '@kwara/components/src/PDF';

const styles = StyleSheet.create({
  table: {
    flexDirection: 'column'
  },
  tableCell: { width: '16.66%', textAlign: 'center' },
  tableLabel: { textAlign: 'center', color: 'black' },
  tableDateLabel: { textAlign: 'center', color: 'lightgrey' }
});

const TransactionsTableHeader = () => (
  <Row>
    {[
      'Trans ID',
      'Name / Beneficiary',
      'Type',
      'Last updated',
      'Amount (KES)',
      'Method'
    ].map(title => (
      <Cell key={title} style={styles.tableCell}>
        <Text>{title}</Text>
      </Cell>
    ))}
  </Row>
);

const TransactionsTableRow = ({
  transaction
}: {
  transaction: TillTransactionType
}) => {
  const {
    id,
    member,
    type,
    createdAt,
    amount,
    paymentMethod,
    beneficiary
  } = transaction;

  const memberName = invoke('fullName', member);
  const memberId = get('id', member);

  return (
    <Row>
      <Cell style={styles.tableCell}>
        <Text style={styles.tableDateLabel}>{id}</Text>
      </Cell>
      <Cell
        style={{
          display: 'flex',
          flexDirection: 'column',
          ...styles.tableCell
        }}
      >
        {memberName ? (
          <Text style={styles.tableLabel}>{memberName}</Text>
        ) : null}
        {memberId ? <Text style={styles.tableLabel}>{memberId}</Text> : null}
        {beneficiary ? (
          <Text style={styles.tableLabel}>{beneficiary}</Text>
        ) : null}
      </Cell>
      <Cell style={styles.tableCell}>
        <Text style={styles.tableLabel}>{type}</Text>
      </Cell>
      <Cell style={styles.tableCell}>
        <Date style={styles.tableDateLabel}>{createdAt}</Date>
      </Cell>
      <Cell style={styles.tableCell}>
        <Currency style={styles.tableLabel}>{amount}</Currency>
      </Cell>
      <Cell style={styles.tableCell}>
        <Text style={styles.tableLabel}>{paymentMethod}</Text>
      </Cell>
    </Row>
  );
};

const TransactionsTable = ({
  transactions
}: {
  transactions: TillTransactionType[]
}) => (
  <View>
    {map(
      transaction => (
        <TransactionsTableRow key={transaction.id} transaction={transaction} />
      ),
      transactions
    )}
  </View>
);

const TellerDetails = ({ profile }: { profile: UserT }) => (
  <View>
    <Subtitle>Teller Details</Subtitle>
    <Text>Name: {profile.fullName()}</Text>
    <Text>Email: {profile.email}</Text>
  </View>
);

type Props = {
  transactions: TillTransactionType[],
  profile: UserT
};

export const TransactionsPDF = ({ transactions = [], profile }: Props) => (
  <Template
    title={'Transactions Statement for Teller'}
    headerText={'Till Transactions'}
    sacco={get('branch', profile)}
  >
    <Section fixed>
      <TellerDetails profile={profile} />
    </Section>
    <View style={styles.table}>
      <TransactionsTableHeader />
      <TransactionsTable transactions={transactions} />
    </View>
  </Template>
);
