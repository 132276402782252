//@flow
import * as React from 'react';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import invoke from 'lodash/fp/invoke';
import get from 'lodash/fp/get';

import { type TillTransactionType } from '@kwara/models/src';
import { DateTime, Text } from '@kwara/components/src/Intl';

import { transactionPath } from '../../../../lib/urls';
import { Currency } from '../../../../components/Currency';
import Table, * as table from '../../../../components/Table';

const orderTransactionsByAttribute = (
  transactions: TillTransactionType[],
  attribute: ?Order
): TillTransactionType[] =>
  orderBy<TillTransactionType>(
    transactions,
    [transaction => [transaction[attribute]]],
    ['desc']
  );

const TransactionRow = ({
  transaction,
  pathTo
}: {
  transaction: TillTransactionType,
  pathTo: string
}) => {
  return (
    <table.Row to={pathTo}>
      <table.Cell to={pathTo} className="grey-400 kw-numeric">
        {transaction.id}
      </table.Cell>
      <table.Cell to={pathTo}>
        {transaction.isUtility() ? (
          transaction.beneficiary
        ) : (
          <>
            <div>{invoke('member.fullName', transaction)}</div>
            <div className="grey-400">{get('member.id', transaction)}</div>
          </>
        )}
      </table.Cell>
      <table.Cell to={pathTo}>
        <Text id={`TillListTable.${transaction.type}`} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <DateTime value={transaction.createdAt} />
      </table.Cell>
      <table.Cell to={pathTo} align="right">
        <Currency value={transaction.amount} />
      </table.Cell>
      <table.Cell to={pathTo}>{transaction.paymentMethod}</table.Cell>
    </table.Row>
  );
};

export const TillListTable = ({
  hasMore,
  errors,
  loading,
  transactions,
  onLoadMoreData,
  isTillOpen
}: {
  hasMore: boolean,
  errors: Object[],
  loading: boolean,
  transactions: TillTransactionType[],
  onLoadMoreData: () => void,
  isTillOpen: boolean
}) => {
  const orderedTransactions = orderTransactionsByAttribute(
    transactions,
    'createdAt'
  );

  return (
    <Table
      heading={
        <table.Row>
          <table.Heading translationId="TillListTable.id" />
          <table.Heading translationId="TillListTable.name" />
          <table.Heading translationId="TillListTable.type" />
          <table.Heading translationId="TillListTable.lastUpdated" />
          <table.Heading align="right" translationId="TillListTable.amount" />
          <table.Heading translationId="TillListTable.method" />
        </table.Row>
      }
      footer={
        <table.Footer
          colSpan={6}
          isPending={loading}
          onNext={onLoadMoreData}
          hasMore={hasMore}
          errors={isTillOpen && errors}
          items={transactions}
          translationBaseId="TillListTable"
        />
      }
    >
      {map(orderedTransactions, transaction => (
        <TransactionRow
          key={transaction.id}
          transaction={transaction}
          pathTo={transactionPath({ id: transaction.id })}
        />
      ))}
    </Table>
  );
};

TillListTable.defaultProps = {
  hasMore: false
};
