// @flow

import * as React from 'react';

import { type WhereT } from '@kwara/models/src';

export function useFilter(
  getArgs: (filter: ?string) => WhereT,
  initialValue: ?string
) {
  const [currentFilter, setCurrentFilter] = React.useState<?string>(
    initialValue
  );
  const args = getArgs(currentFilter);
  const onFilterChange = React.useCallback((filter: ?string) => {
    setCurrentFilter(filter);
  }, []);

  // This sucks, but...  ¯\_(ツ)_/¯
  // https://github.com/facebook/react/issues/14476#issuecomment-471199055
  const plain = JSON.stringify(args);
  const filterValue = React.useMemo(() => JSON.parse(plain), [plain]);

  return {
    filterValue,
    onFilterChange
  };
}
