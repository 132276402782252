//@flow

import curry from 'lodash/fp/curry';
import get from 'lodash/fp/get';
import keys from 'lodash/fp/keys';
import includes from 'lodash/fp/includes';
import reject from 'lodash/fp/reject';
import pick from 'lodash/fp/pick';

import { type MemberType, type SavingType } from '@kwara/models/src';
import { TransactionChannels } from '@kwara/models/src/models/Transactions';
import { fields as savingFields } from '@kwara/models/src/models/Saving';

interface Data extends SavingType {
  member: MemberType;
}

export function extractRemittanceOptions(data: Data) {
  const { remittanceOptions = {} } = data;
  // ch10477
  // we need to return only the needed keys for the saving model, so
  // we pick them all...
  const props = keys(remittanceOptions);
  // we extract all the ones that are dependant on a particular transaction method...
  const commonKeys = reject(k => includes(k, ['direct_debit_details']), props);
  // and we create an object composed ONLY by the props that are always needed
  const baseObject = pick(commonKeys, remittanceOptions);

  if (
    remittanceOptions.remittance_method === TransactionChannels.payrollDeduction
  ) {
    return {
      ...baseObject,
      payroll_deduction_details: {
        payroll_deduction_employer_name: get('member.employer', data),
        payroll_deduction_staff_id: get('member.staffId', data)
      }
    };
  }

  // ch10478
  // This makes sure that if a user has selected direct debit and then filled some of the related
  // fields, and then picks another method (such as cash for instance) we don't send the
  // direct debit options, that are stil in the model filled by the form, but not required in the
  // backend model
  if (remittanceOptions.remittance_method === TransactionChannels.directDebit) {
    return {
      ...baseObject,
      direct_debit_details: remittanceOptions.direct_debit_details
    };
  }

  return baseObject;
}

const remittanceFields = {
  [TransactionChannels.directDebit]: [
    savingFields.remittance.bank,
    savingFields.remittance.branch,
    savingFields.remittance.account,
    savingFields.remittance.collectingBank
  ],
  [TransactionChannels.payrollDeduction]: [
    'payrollDeductionDetails.payroll_deduction_employer_name',
    'payrollDeductionDetails.payroll_deduction_staff_id'
  ]
};

export const excludedRemittanceFields = curry<SavingType, string, boolean>(
  (saving: SavingType, fieldName: string) => {
    const repaymentMode = get(savingFields.remittance.method, saving);
    return !includes(fieldName, remittanceFields[repaymentMode]);
  }
);
