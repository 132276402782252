//@flow
import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';

export default ({
  numResults,
  searchTerm
}: {
  numResults: number,
  searchTerm: string
}) => (
  <div className="mt4 pt3 pb3 grey-400 kw-text-small">
    <Text
      id="MemberSearch.ResultList.results"
      values={{ total: numResults, term: searchTerm }}
    />
  </div>
);
