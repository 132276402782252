//@flow
import * as React from 'react';

import { Logger } from '@kwara/lib/src/logger';
import { Lead, snakeCaseObjectKeys } from '@kwara/models/src';
import { Stack } from '@kwara/components/src/Stack';

import Wizard from '../../components/Wizard';

import { type WizardPageProps } from '..';
import { GetStarted } from './components/GetStarted';
import { steps, initialData } from './config';

type Props = WizardPageProps;

export function OnboardingWizard({ baseUrl, match, history }: Props) {
  return (
    <Wizard
      baseUrl={baseUrl}
      history={history}
      currentStep={match.params.step}
      currentSubStep={
        match.params.subStep != null ? parseInt(match.params.subStep, 10) : null
      }
      initialData={initialData}
      cancelReturnsTo="/welcome"
      onSubmit={async v => {
        Logger.log(v);
        const lead = new Lead(snakeCaseObjectKeys(v));
        await lead
          .save()
          .then(ok => {
            if (!ok) {
              throw lead.errors;
            }
          })
          .catch(() => {
            throw { message: 'Errors.Generic' };
          });
      }}
      steps={steps}
      startId="sacco"
      titleId="Onboarding.title"
    />
  );
}

export const Onboarding = () => (
  <div className="mt5 pt5">
    <Stack>
      <GetStarted />
    </Stack>
  </div>
);
