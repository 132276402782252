// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';

import { Form } from 'react-final-form';
import { SubscribedCheckbox } from '@kwara/components/src/Form';
import Button from '@kwara/components/src/Button';
import Banner from '@kwara/components/src/Banner';

import { Header } from '../Header';
import { Detail, DetailWrapper } from '../Personal';

import { store } from '../../../../models/Store';

const daysOfTheWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thurday',
  'Friday',
  'Saturday',
  'Sunday'
];

const SystemAccessTimesForm = () => {
  const [fromTime, setFromTime] = React.useState(
    get('accessTimes.fromTime', store)
  );
  const [toTime, setToTime] = React.useState(get('accessTimes.toTime', store));
  const [showSuccess, setShowSuccess] = React.useState(false);

  const showSuccessBanner = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 3000);
  };

  function setAccessTimes() {
    store.setAccessTimes({ toTime, fromTime });
    showSuccessBanner();
  }

  const handleChangeFromTime = e => {
    setFromTime(e.target.value);
  };

  const handleChangeToTime = e => {
    setToTime(e.target.value);
  };

  return (
    <>
      {showSuccess ? (
        <Banner
          className="mb3"
          text="Changes submitted successfully"
          state="success"
        />
      ) : null}

      <form>
        <div className="w-20 flex justify-between">
          <label htmlFor="fromTime">From</label>
          <input
            name="fromTime"
            type="time"
            value={fromTime}
            onChange={handleChangeFromTime}
          />
        </div>
        <div className="w-20 flex justify-between">
          <label htmlFor="toTime">To</label>
          <input
            name="toTime"
            type="time"
            onChange={handleChangeToTime}
            value={toTime}
          />
        </div>

        <Button
          className={'mt3'}
          disabled={!(toTime && fromTime)}
          onClick={setAccessTimes}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

const SystemAccessDaysForm = () => {
  const [showSuccess, setShowSuccess] = React.useState(false);

  const showSuccessBanner = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 3000);
  };

  function setAccessDays(data) {
    const { accessDays } = data;
    store.setAccessDays(accessDays);
    showSuccessBanner();
  }

  return (
    <>
      {showSuccess ? (
        <Banner
          className="mb3"
          text="Changes submitted successfully"
          state="success"
        />
      ) : null}
      <Form
        onSubmit={setAccessDays}
        initialValues={{ accessDays: store.accessDays }}
        render={({ form }) => {
          return (
            <div>
              {map(
                day => (
                  <SubscribedCheckbox
                    compact
                    margin={false}
                    name="accessDays"
                    value={day}
                    labelId={day}
                  />
                ),
                daysOfTheWeek
              )}
              <Button className={'mt3'} onClick={form.submit}>
                Submit
              </Button>
            </div>
          );
        }}
      />
    </>
  );
};

export const AllUsersAccessForm = () => {
  const [showSuccess, setShowSuccess] = React.useState(false);

  const showSuccessBanner = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 3000);
  };

  function setBlockAllUsersAccess(data) {
    const { blockAllUsersAccess } = data;
    store.setBlockAllUsersAccess(blockAllUsersAccess);
    showSuccessBanner();
  }

  return (
    <>
      {showSuccess ? (
        <Banner
          className="mb3"
          text="Changes submitted successfully"
          state="success"
        />
      ) : null}
      <Form
        onSubmit={setBlockAllUsersAccess}
        initialValues={{ blockAllUsersAccess: store.blockAllUsersAccess }}
        render={({ form }) => {
          return (
            <div>
              <SubscribedCheckbox
                compact
                margin={false}
                name="blockAllUsersAccess"
                labelId={'Block all users access'}
              />

              <Button className={'mt3'} onClick={form.submit}>
                Submit
              </Button>
            </div>
          );
        }}
      />
    </>
  );
};

export const Access = () => {
  return (
    <>
      <Header
        titleId="Access"
        subtitleId="Restrict access to the platform"
        action={[]}
      />
      <div>
        <DetailWrapper>
          <Detail
            titleId="Days on which system is accessible"
            value={<SystemAccessDaysForm />}
          />
        </DetailWrapper>

        <DetailWrapper>
          <Detail
            titleId="Times which system is accessible"
            value={<SystemAccessTimesForm />}
          />
        </DetailWrapper>

        <DetailWrapper>
          <Detail
            titleId="Prevent all users from accessing platform"
            value={<AllUsersAccessForm />}
          />
        </DetailWrapper>
      </div>
    </>
  );
};
