// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';
import pipe from 'lodash/fp/pipe';

import { Text } from '@kwara/components/src/Intl';
import { type LoanType } from '@kwara/models/src';

import { Currency } from '../Currency';
import { AccountThumbnail, NoAccounts } from '../AccountThumbnail';

type Props = {
  loans: LoanType[],
  noLoans?: React.Node
};

function filterAccounts(loans: LoanType[]) {
  return pipe(
    filter(l => Number(l.totalBalance)),
    sortBy(l => -Number(l.totalBalance))
  )(loans);
}

export const LoansPanel = ({
  loans,
  noLoans = <Text id="MemberDetail.Overview.loans.empty" />
}: Props) => {
  const accounts = filterAccounts(loans);

  if (isEmpty(accounts)) {
    return <NoAccounts>{noLoans}</NoAccounts>;
  }

  return (
    <div className="ph3">
      {map(
        loan => (
          <AccountThumbnail
            key={loan.id}
            account={loan}
            amount={<Currency format="currency" value={loan.totalBalance} />}
          />
        ),
        accounts
      )}
    </div>
  );
};
