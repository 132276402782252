import * as React from 'react';
import filter from 'lodash/fp/filter';

import { isEmpty } from '@kwara/lib/src/lodash';
import { Loadable } from '@kwara/components/src/Loadable';
import { LoanApprovalSetting } from '@kwara/models/src';

import {
  EditableSection,
  Updater
} from '../../../../components/EditableSection';
import { Header } from '../Header';
import { useLoanApprovalSetting } from '../../../../models/request';
import { LoanApprovalForm } from './LoanApprovalForm';
import { Tiers } from './Tiers';

const config = {
  Component: LoanApprovalForm,
  validate: LoanApprovalForm.validate
};

const removeDeleted = tiers =>
  filter(tier => !tier.isMarkedForDestruction, tiers);

function onSave(data) {
  const { tiers: raw } = data;

  const tiers = removeDeleted(raw);

  const loanApprovalSetting = new LoanApprovalSetting({
    tiers
  });
  return loanApprovalSetting.save();
}

export const LoanApproval = () => {
  const { refetch, ...r } = useLoanApprovalSetting();
  return (
    <>
      <Header
        titleId="Settings.LoanApprove.titleId"
        subtitleId="Settings.LoanApprove.subtitleId"
      />
      <Updater value={{ onUpdate: refetch }}>
        <Loadable {...r}>
          {tiers => (
            <EditableSection
              onSave={onSave}
              onUpdate
              initialData={{
                tiers: [{ min: '0' }]
              }}
              config={config}
              readOnly={!isEmpty(tiers)}
            >
              <Tiers tiers={tiers} />
            </EditableSection>
          )}
        </Loadable>
      </Updater>
    </>
  );
};
