// @flow

import * as React from 'react';
import classnames from 'classnames';

import { Text } from '@kwara/components/src/Intl';

import Column from './Column';
import styles from './index.module.scss';

export const NoResults = ({ term }: { term: string }) => (
  <div className={classnames(styles.Result, styles.NoResults, 'pa2 grey-400')}>
    <Column border={false}>
      <Text id="MemberSearch.Results.NoResults" values={{ term }} />
    </Column>
  </div>
);
