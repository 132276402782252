//@flow

import * as React from 'react';

import { ProfilePhotoUpload } from '../../../components/ProfilePhotoUpload';
import { type SubStepComponentProps } from '../../../components/Wizard';

export default ({
  StackChild,
  TextField,
  addData,
  data
}: SubStepComponentProps<>) => {
  return (
    <StackChild>
      <div className="flex">
        <ProfilePhotoUpload addData={addData} data={data} />

        <div className="flex-auto">
          <TextField
            name="firstName"
            errorBaseId="SelfService.Profile.Name"
            labelId="SelfService.Profile.FirstName.label"
          />
          <TextField
            name="middleName"
            errorBaseId="SelfService.Profile.Name"
            labelId="SelfService.Profile.MiddleName.label"
          />
          <TextField
            name="lastName"
            errorBaseId="SelfService.Profile.Name"
            labelId="SelfService.Profile.LastName.label"
          />
        </div>
      </div>
    </StackChild>
  );
};
