// @flow

import * as React from 'react';

import { Text } from '@kwara/components/src/Intl';
import { PageHeading } from '@kwara/components/src/text';

import styles from '../index.module.scss';

export const Header = ({
  titleId,
  subtitleId,
  action
}: {
  titleId: string,
  subtitleId: string,
  action?: React.Node
}) => {
  return (
    <div className={styles.Header}>
      <div>
        <PageHeading translationId={titleId} />
        <p className="grey-400 kw-text-medium mb1">
          <Text id={subtitleId} />
        </p>
      </div>
      {action ? <div className="ml-auto">{action}</div> : null}
    </div>
  );
};
