// @flow
import * as React from 'react';
import last from 'lodash/fp/last';
import groupBy from 'lodash/fp/groupBy';
import first from 'lodash/fp/first';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/fp/isEmpty';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { sumBy } from '@kwara/lib/src/currency';
import { Date } from '@kwara/components/src/Intl';
import { type RepaymentType } from '@kwara/models/src';
import StatusTag from '@kwara/components/src/StatusTag';
import { getYear, addMonths } from '@kwara/lib/src/dates';

import { Currency } from '../../../components/Currency';

import Table, {
  Cell,
  Collapsible,
  Heading,
  Row
} from '../../../components/Table';

type Props = {
  repayments: Array<RepaymentType>,
  initiallyOpen?: boolean
};

export default function RepaymentsTable({
  repayments,
  initiallyOpen = false
}: Props) {
  // ch10173 - if schedules is null it means it's still loading,
  // if it's an empty array it means we have no schedule.
  // Either way we render nothing.
  if (repayments == null || isEmpty(repayments)) {
    return null;
  }

  const lastRepayment = last(repayments);
  const lastRepaymentDate = addMonths(lastRepayment.dueAt, 1);

  const groups = groupBy(
    (repayment: RepaymentType) => getYear(repayment.dueAt),
    repayments
  );

  return (
    <Table
      heading={
        <Row>
          <Heading width="125px" translationId="RepaymentsTable.dueDate" />
          <Heading
            tooltipId="RepaymentsTable.balance.title"
            align="right"
            translationId="RepaymentsTable.balance"
          />
          <Heading
            align="right"
            tooltipId="RepaymentsTable.principal.title"
            translationId="RepaymentsTable.principal"
          />
          <Heading
            tooltipId="RepaymentsTable.interest.title"
            align="right"
            translationId="RepaymentsTable.interest"
          />
          <Heading
            tooltipId="RepaymentsTable.outstanding.title"
            align="right"
            translationId="RepaymentsTable.outstanding"
          />
          <Heading width="125px" />
        </Row>
      }
    >
      {mapIndexed(
        (items, year) => (
          <Collapsible key={year} initiallyOpen={initiallyOpen}>
            <Row>
              <Cell>
                <span className="dib kw-text-small" style={{ width: 90 }}>
                  {year}
                </span>
              </Cell>
              <Cell align="right">
                {/* Opening balance */}
                <Currency
                  value={first(items).startingBalance}
                  hideDecimals={false}
                  size="small"
                />
              </Cell>
              <Cell align="right">
                <Currency
                  value={sumBy('principal.due', items)}
                  hideDecimals={false}
                  size="small"
                />
              </Cell>
              <Cell align="right">
                <Currency
                  value={sumBy('interest.due', items)}
                  hideDecimals={false}
                  size="small"
                />
              </Cell>
              <Cell align="right">
                <Currency
                  value={last(items).outstanding()}
                  hideDecimals={false}
                  size="small"
                />
              </Cell>
            </Row>
            {map(
              repayment => (
                <Row key={repayment.dueAt}>
                  <Cell>
                    <Date value={repayment.dueAt} size="small" />
                  </Cell>
                  <Cell align="right">
                    <Currency
                      value={repayment.startingBalance}
                      hideDecimals={false}
                      size="small"
                    />
                  </Cell>
                  <Cell align="right">
                    <Currency
                      value={repayment.principal.due}
                      hideDecimals={false}
                      size="small"
                    />
                  </Cell>
                  <Cell align="right">
                    <Currency
                      value={repayment.interest.due}
                      hideDecimals={false}
                      size="small"
                    />
                  </Cell>
                  <Cell align="right">
                    <Currency
                      value={repayment.outstanding()}
                      hideDecimals={false}
                      size="small"
                    />
                  </Cell>
                  <Cell align="right">
                    <StatusTag state={repayment.state} size="small" />
                  </Cell>
                </Row>
              ),
              items
            )}
          </Collapsible>
        ),
        groups
      )}
      <Row>
        <Cell>
          <Date value={lastRepaymentDate} size="small" />
        </Cell>
        <Cell align="right">
          <Currency
            size="small"
            hideDecimals={false}
            value={lastRepayment.endingBalance}
          />
        </Cell>
        <Cell colSpan={4}></Cell>
      </Row>

      {/*repayments.map(repayment => (
        <Row key={repayment.id}>
          <Cell>
            <Date value={repayment.dueAt} />
          </Cell>
          <Cell align="right">
            <Currency value={repayment.startingBalance} />
          </Cell>
          <Cell align="right">
            <Currency value={repayment.principal.due} />
          </Cell>
          <Cell align="right">
            <Currency value={repayment.interest.due} />
          </Cell>
          <Cell align="right">
            <Currency value={repayment.outstanding()} />
          </Cell>
          <Cell align="right">
            <Currency value={repayment.endingBalance} />
          </Cell>
          <Cell>
            <Tag
              id={`RepaymentState.${repayment.state}`}
              status={statusToState[repayment.state] || Status.Neutral}
            />
          </Cell>
        </Row>
      ))*/}
    </Table>
  );
}
