//@flow
import * as React from 'react';
import map from 'lodash/map';

import { type LoanType, type LoanState, type LoanId } from '@kwara/models/src';
import {
  SubscribedCheckbox as Checkbox,
  Checkbox as BasicCheckbox
} from '@kwara/components/src/Form';
import { Duration, Percent, DateTime } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';

import { Currency } from '../../../../components/Currency';
import { loanPath } from '../../../../lib/urls';
import Table, * as table from '../../../../components/Table';

type FormattedLoan = {
  id: LoanId,
  name: string,
  loan: string,
  amount: number,
  interestRate: ?number,
  repaymentFrequency: ?string,
  duration: ?string,
  updated: ?string,
  state: LoanState
};
const formatter = (loan: LoanType): FormattedLoan => ({
  id: loan.id,
  name: loan.member.fullName(),
  loan: loan.product.name,
  amount: loan.amount,
  interestRate: loan.interestRatePercent(),
  repaymentFrequency: loan.repaymentFrequency,
  duration: loan.duration,
  updated: loan.updatedAt,
  state: loan.state.current
});

const Row = ({ item, pathTo }: { item: LoanType, pathTo: string }) => {
  const f = formatter(item);

  return (
    <table.Row to={pathTo}>
      <table.Cell onClick={e => e.stopPropagation()}>
        <Checkbox name={f.id} size="medium" />
      </table.Cell>
      <table.Cell to={pathTo} className="grey-400 kw-numeric">
        {f.id}
      </table.Cell>
      <table.Cell to={pathTo} className="mw5">
        {f.name}
      </table.Cell>
      <table.Cell to={pathTo}>{f.loan}</table.Cell>
      <table.Cell to={pathTo} align="right">
        <Currency value={f.amount} />
      </table.Cell>
      <table.Cell to={pathTo} align="right">
        <Percent value={f.interestRate} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <Duration value={f.duration} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <DateTime value={f.updated} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <StatusTag state={f.state} />
      </table.Cell>
    </table.Row>
  );
};

const createRows = (loans = []) => (
  <>
    {map(loans, loan => (
      <Row key={loan.id} item={loan} pathTo={loanPath({ id: loan.id })} />
    ))}
  </>
);

export default ({
  hasMore = false,
  loading,
  isSelectAllCheckboxActive,
  loans = [],
  toggleAllCheckboxes,
  onLoadMoreData
}: {
  hasMore?: boolean,
  loading: boolean,
  isSelectAllCheckboxActive: boolean,
  loans: LoanType[],
  toggleAllCheckboxes: () => void,
  onLoadMoreData?: () => void
}) => {
  const columns = 9;

  return (
    <Table
      heading={
        <table.Row>
          <table.Heading width="50px">
            <BasicCheckbox
              name="unsubscribed-toggle-all"
              size="medium"
              checked={isSelectAllCheckboxActive}
              onChange={toggleAllCheckboxes}
            />
          </table.Heading>
          <table.Heading
            textSize="regular"
            translationId="CreditSubmitListTable.id"
          />
          <table.Heading
            textSize="regular"
            translationId="CreditSubmitListTable.member"
          />
          <table.Heading
            textSize="regular"
            translationId="CreditSubmitListTable.loan"
          />
          <table.Heading
            textSize="regular"
            align="right"
            translationId="CreditSubmitListTable.amount"
          />
          <table.Heading
            textSize="regular"
            align="right"
            translationId="CreditSubmitListTable.interestRate"
          />
          <table.Heading
            textSize="regular"
            translationId="CreditSubmitListTable.duration"
          />
          <table.Heading
            textSize="regular"
            translationId="CreditSubmitListTable.updated"
          />
          <table.Heading
            textSize="regular"
            translationId="CreditSubmitListTable.status"
          />
        </table.Row>
      }
      footer={
        <table.Footer
          colSpan={columns}
          isPending={loading}
          onNext={onLoadMoreData}
          hasMore={hasMore}
          items={loans}
          translationBaseId="CreditSubmitListTable"
        />
      }
    >
      {createRows(loans)}
    </Table>
  );
};
