import * as React from 'react';

import Empty from '@kwara/components/src/Empty';

export const PermissionDenied = ({ message }) => (
  <div className="flex flex-column h-100 justify-center">
    <Empty>
      <div>
        <h2 className="grey-500">Permission denied</h2>
        <p>Your user account does not have permission to view this page</p>
        {message ? <p className="red-600">{message}</p> : null}
      </div>
    </Empty>
  </div>
);
