//@flow
import * as React from 'react';
import classnames from 'classnames';
import { scaleQuantize } from 'd3-scale';

import { Text, type TranslationId } from '@kwara/components/src/Intl';

import styles from './index.module.css';

export const Status = {
  Neutral: 'neutral',
  Critical: 'critical',
  Success: 'success',
  Warning: 'warning'
};

type StatusValue = $Values<typeof Status>;

const textClasses = {
  neutral: 'grey-400',
  critical: 'red-600',
  success: 'teal-600',
  warning: 'amber-500'
};

const backgroundClasses = {
  neutral: 'bg-light-grey-400',
  critical: 'bg-red-100',
  success: 'bg-teal-100',
  warning: 'bg-amber-100'
};

type Props = {
  background?: boolean,
  id: TranslationId,
  status: StatusValue,
  values?: { [string]: mixed },
  size?: 'small'
};

export const valueToStatus = (
  domain: Array<number>,
  value: number
): StatusValue => {
  return scaleQuantize()
    .domain(domain)
    .range([Status.Critical, Status.Warning, Status.Neutral, Status.Success])(
    value
  );
};

export default function Tag({
  background = true,
  id,
  status,
  values,
  size
}: Props) {
  return (
    <div
      className={classnames(
        { 'kw-text-small': size === 'small' },
        styles.container,
        background ? styles.hasBackground : styles.noBackground,
        background ? backgroundClasses[status] : null,
        textClasses[status]
      )}
    >
      <Text id={id} values={values} />
    </div>
  );
}
