//@flow
import * as React from 'react';

import { type MemberType } from '@kwara/models/src';
import { ExpanderRow } from '@kwara/components/src';
import { Loadable } from '@kwara/components/src';
import { useMember } from '@kwara/models/src/models/request/hooks';

import { Updater } from '../../../../components/EditableSection';

import {
  PersonalDetails,
  Identity,
  Documents,
  NextOfKin,
  Employment
} from '../../../MemberDetail/components';

export const Header = (props: { children: React.Node }) => (
  <header className="flex">{props.children}</header>
);

export default ({ member, onChange }: { member: MemberType }) => {
  const { isPending, data: memberRes, error } = useMember(member.id);
  const onUpdate = editedMember => onChange({ member: editedMember });
  React.useEffect(() => {
    onUpdate(memberRes);
    // eslint-disable-next-line
  }, [memberRes]);
  return (
    <Loadable isPending={isPending} error={error}>
      <Updater value={{ onUpdate }}>
        <Header>
          <PersonalDetails.Title />
        </Header>
        <ExpanderRow isExpanded>
          <PersonalDetails member={member} />
        </ExpanderRow>

        <Header>
          <Identity.Title />
        </Header>
        <ExpanderRow isExpanded>
          <Identity member={member} />
        </ExpanderRow>

        <Header>
          <NextOfKin.Title />
        </Header>
        <ExpanderRow isExpanded>
          <NextOfKin member={member} />
        </ExpanderRow>
        <Header>
          <Employment.Title />
        </Header>
        <ExpanderRow isExpanded>
          <Employment member={member} config={Employment.editConfig.extra} />
        </ExpanderRow>

        <Header>
          <Documents.Title />
        </Header>
        <ExpanderRow isExpanded>
          <Documents member={member} />
        </ExpanderRow>
      </Updater>
    </Loadable>
  );
};
