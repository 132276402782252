// @flow

import React from 'react';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import { add, minus, equals } from '@kwara/lib/src/currency';
import { ERRORS as err } from '@kwara/lib/src/validator';
import { Text } from '@kwara/components/src/Intl';

import { getMinMaxLoanProductAmount } from '../../LoanAdd/components/Configure/PrincipalAmountField';

import {
  type ComponentPropsLoanReschedule,
  type FormDataLoanReschedule
} from '..';

function getOriginalLoanAmount(data: FormDataLoanReschedule) {
  const originalLoanAmount = get('loan.principal.balance', data);
  return { originalLoanAmount };
}

type Props = {
  formProps: $PropertyType<ComponentPropsLoanReschedule, 'formProps'>
};

export const RescheduleAmountField = ({
  data,
  TextField,
  formProps
}: ComponentPropsLoanReschedule & Props) => {
  const { principalMax, principalMin } = getMinMaxLoanProductAmount(data);
  const { originalLoanAmount } = getOriginalLoanAmount(data);
  const { values } = formProps;
  const { amount: newAmount, writeOffAmount } = values;

  const targetWriteOff = Number(minus(originalLoanAmount, newAmount));
  const writeOff = Number(writeOffAmount);

  const amountInfo = (
    <ul>
      <li>
        <Text
          id="LoanReschedule.Configure.PrincipalAmount.rescheduleInfo"
          values={{
            originalLoanAmount
          }}
        />
      </li>
      {principalMax && (
        <li>
          <Text
            id="LoanReschedule.Configure.PrincipalAmount.maximumAmount"
            values={{
              principalMax,
              principalMin
            }}
          />
        </li>
      )}
    </ul>
  );

  return (
    <>
      <TextField
        margin={false}
        compact
        errorBaseId="LoanReschedule.Configure.PrincipalAmount"
        errorValues={{
          principalMax,
          principalMin,
          originalLoanAmount
        }}
        name="amount"
        required={true}
        leftGlyph="Currency.orgCurrency"
        titleId="LoanReschedule.Configure.PrincipalAmount.title"
        info={amountInfo}
      />
      <TextField
        required={Number(originalLoanAmount) !== Number(newAmount)}
        compact
        size="medium"
        titleId="LoanReschedule.Configure.WriteOffAmount.title"
        errorBaseId="LoanReschedule.Configure.WriteOffAmount"
        errorValues={{ writeOff: targetWriteOff }}
        name="writeOffAmount"
        leftGlyph="Currency.orgCurrency"
        info={
          targetWriteOff === writeOff || !targetWriteOff ? null : (
            <Text
              id="LoanReschedule.Configure.WriteOffAmount.info"
              values={{ writeOff: targetWriteOff }}
            />
          )
        }
      />
    </>
  );
};

RescheduleAmountField.validate = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom: (target, allData) => {
      const { principalMax, principalMin } = getMinMaxLoanProductAmount(
        allData
      );

      const { originalLoanAmount } = getOriginalLoanAmount(allData);

      const num = Number(target);

      if (num > originalLoanAmount) {
        return 'exceedsOriginalAmount';
      }

      if (principalMin != null && num < principalMin) {
        return err.rangeUnderflow;
      }

      if (principalMax != null && num > principalMax) {
        return err.rangeOverflow;
      }

      return null;
    }
  },
  writeOffAmount: {
    isRequired: (_, allData) => {
      const { amount } = allData;
      const { originalLoanAmount } = getOriginalLoanAmount(allData);
      const original = Number(originalLoanAmount);
      const newAmount = Number(amount);
      return newAmount < original;
    },
    custom: (writeOffAmount, allData) => {
      const { amount } = allData;
      const { originalLoanAmount } = getOriginalLoanAmount(allData);

      const original = Number(originalLoanAmount);
      const newAmount = Number(amount);
      const writeOff = Number(writeOffAmount);

      if (original === newAmount) {
        if (isEmpty(writeOffAmount)) {
          return null;
        }

        if (writeOff !== 0) {
          return 'writeOffMustBeZero';
        }
      }

      if (!equals(original, add(writeOff, newAmount))) {
        return 'invalidWriteOffAmount';
      }
      return null;
    },
    currency: true
  }
};
