import * as React from 'react';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/fp/isEmpty';

import Empty from '@kwara/components/src/Empty';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';
import { Statistic } from '@kwara/components/src/Statistic';
import { ActionButton } from '@kwara/components/src/Button';
import { mapIndexed } from '@kwara/lib/src/lodash';

import { ActionableHeading } from '../../../components/OverviewList';
import { useProduct } from './utils';
import { ActionModal, Panel } from '../../../components/ActionModal';

const UserCard = ({ user, onRemove, onEdit }) => (
  <>
    <header className="flex justify-between mt1">
      <Statistic
        containerClassName="w-50"
        title={<Text id="Onboarding.Users.FullName" />}
        value={user.name}
        size="large"
      />
      <div className="flex">
        <ActionButton
          className="mr2"
          type="delete"
          col="red500"
          onClick={onRemove}
        />
        <ActionButton type="edit" col="black" onClick={onEdit} />
      </div>
    </header>
    <div className="flex justify-between">
      <Statistic
        containerClassName="w-50"
        title={<Text id="Onboarding.Users.Email" />}
        value={user.email}
      />
      <Statistic
        containerClassName="w-50"
        title={<Text id="Onboarding.Users.Mobile" />}
        value={user.mobile}
      />
    </div>
    <div className="flex justify-between bb b--light-gray mb4">
      <Statistic
        containerClassName="w-50"
        title={<Text id="Onboarding.Users.Role" />}
        value={user.role}
      />
      <Statistic
        containerClassName="w-50"
        title={<Text id="Onboarding.Roles.SendInvite.label" />}
        value={'YES'}
      />
    </div>
  </>
);

const Modal = ({
  data = {},
  idx,
  setModalState,
  TextField,
  SelectField,
  Checkbox,
  formProps
}) => {
  const baseField = `users[${idx}]`;
  return (
    <ActionModal
      actions
      titleId="Onboarding.Users.ModalAdd.Title"
      onConfirm={() => {
        formProps.form.submit();
        formProps.form.change(formProps.form.getState());
        setModalState(false);
      }}
      onCancel={() => {
        formProps.form.reset();
        setModalState(false);
      }}
    >
      <Panel>
        <h2 className="kw-text-extra-large kw-weight-regualr mb4">
          <Text id="Onboarding.Users.PersonalInfo" />
        </h2>
        <TextField
          name={`${baseField}.name`}
          errorBaseId="Onboarding.Users.Name"
          labelId="Onboarding.Users.Name.label"
        />
      </Panel>
      <Panel>
        <h2 className="kw-text-extra-large kw-weight-regualr mb4">
          <Text id="Onboarding.Users.Contact" />
        </h2>
        <TextField
          name={`${baseField}.mobile`}
          errorBaseId="Onboarding.Users.Mobile"
          labelId="Onboarding.Users.Mobile.label"
          placeholderId="Onboarding.Users.Mobile.placeholder"
        />
        <TextField
          name={`${baseField}.email`}
          errorBaseId="Onboarding.Users.Email"
          labelId="Onboarding.Users.Email.label"
          placeholderId="Onboarding.Users.Email.placeholder"
        />
        <Checkbox
          name={`${baseField}.sendInvite`}
          labelId="Onboarding.Roles.SendInvite.label"
        />
      </Panel>
      <Panel>
        <h2 className="kw-text-extra-large kw-weight-regualr mb4">
          <Text id="Onboarding.Users.Roles" />
        </h2>
        <SelectField
          name={`${baseField}.role`}
          errorBaseId="Onboarding.Users.Roles"
          labelId="Onboarding.Users.Roles.label"
        >
          <SelectField.Option translationId="Onboarding.Users.Roles.none" />
          {map(role => {
            return (
              <SelectField.Option
                key={role.name}
                translationId={`${role.name}`}
                value={role.name}
              />
            );
          }, data.roles || [])}
        </SelectField>
        <p className="ma0 grey-400 kw-text-regular">
          <Text id="Onboarding.Users.Roles.Permissions" />
        </p>
        <div className="mb2 bb b--light-gray">
          <Statistic
            containerClassName="w-50"
            title={<Text id="Onboarding.Roles.Perm.Members" />}
            value={'can create'}
          />
        </div>
        <div className="mb2 bb b--light-gray">
          <Statistic
            containerClassName="w-50"
            title={<Text id="Onboarding.Roles.Perm.Loans" />}
            value={'can edit'}
          />
        </div>
      </Panel>
    </ActionModal>
  );
};

export function Users({
  data,
  StackChild,
  TextField,
  SelectField,
  Checkbox,
  formProps,
  onChange
}) {
  const {
    isOpen,
    setModalState,
    index,
    editProduct,
    addProduct,
    removeProduct
  } = useProduct(data.users);

  return (
    <>
      <StackChild>
        {isOpen ? (
          <Modal
            idx={index}
            {...{
              TextField,
              setModalState,
              SelectField,
              Checkbox,
              formProps
            }}
          />
        ) : null}
        {isEmpty(data.users) ? (
          <Empty img="triangle">
            <div className="flex flex-column">
              <p>
                <Text id="Onboarding.Users.NoUsersMessage" />
              </p>
              <div className="mb2">
                <Button onClick={addProduct} type="primary">
                  <Text id="Onboarding.Users.AddUserMessage" />
                </Button>
              </div>
            </div>
          </Empty>
        ) : null}
      </StackChild>
      {isEmpty(data.users) ? null : (
        <StackChild size="regular">
          <ActionableHeading
            headerId="Onboarding.Step.UsersMain.title"
            actions={[
              <Button key="add" onClick={addProduct}>
                <Text id="Onboarding.Users.AddUserMessage" />
              </Button>
            ]}
          />
          {mapIndexed(
            (user, idx) => (
              <UserCard
                key={user.name}
                idx={idx}
                user={user}
                onEdit={() => editProduct(idx)}
                onRemove={() => onChange({ users: removeProduct(idx) })}
              />
            ),
            data.users || []
          )}
        </StackChild>
      )}
    </>
  );
}
