// @flow
import * as React from 'react';
import Modal from 'react-modal';

import { isTest } from '@kwara/lib/src/utils';
import zIndices from '@kwara/lib/src/zIndices';

import styles from './index.module.scss';

// The ID we append to the top level DOM element
// that React renders into. It's used to ensure
// the Modal is accessible
const APP_ROOT_ID = 'root';

type Props = {
  className?: string,
  children: React.Node,
  isOpen: boolean
};

export default class extends React.Component<Props> {
  static defaultProps = {
    className: ''
  };

  constructor(props: Props) {
    super(props);

    // For accessibility, we must call this before we open any Modals
    // When running tests though this is throwing an error so we just avoid this as
    // it's not needed there anyway. There might be a better solution, if you find it
    // feel free to remove this conditional logic

    if (!isTest) {
      Modal.setAppElement(`#${APP_ROOT_ID}`);
    }
  }

  render() {
    return (
      <Modal
        // this is also needed to avoid errors on test renders
        ariaHideApp={!isTest}
        className={`${styles.Modal} flex flex-column items-center ${this.props
          .className || ''}`}
        overlayClassName={`${styles.ModalOverlay} ${zIndices.Modal}`}
        isOpen={this.props.isOpen}
        shouldCloseOnEsc={false}
      >
        {this.props.children}
      </Modal>
    );
  }
}
