//@flow
import * as React from 'react';

import ActivityTimeline from '@kwara/components/src/ActivityTimeline';
import { Text } from '@kwara/components/src/Intl';
import { type ActivityType } from '@kwara/models/src';
import { ModelErrorBanner } from '@kwara/components/src/ModelErrorBanner';

import { ActivityCommenter, type CommentHandler } from './Commenter';
import styles from './index.module.scss';

export { ActivityCommenter } from './Commenter';

type Props = {
  activities: ActivityType[],
  onComment: CommentHandler,
  showCommentCreate?: boolean,
  disableComments?: boolean,
  errors?: any
};

export default ({
  activities,
  onComment,
  showCommentCreate = true,
  // Can remove once all Comments are supported: Loans and Savings
  disableComments = true,
  errors
}: Props) => (
  <div>
    {showCommentCreate && (
      <div className="mb5 pb3 hide-on-print">
        {errors ? (
          <div className="mb3">
            <ModelErrorBanner errors={errors} />
          </div>
        ) : null}
        <ActivityCommenter
          errors={errors}
          disabled={disableComments}
          onComment={onComment}
        />
      </div>
    )}

    <div className={`grey-400 bt b--light-grey-300 ${styles.Title}`}>
      <h3 className="ma0 pr4 kw-text-small kw-weight-bold bg-white dib">
        <Text
          id="ActivityTimeline.title"
          values={{ numItems: activities.length }}
        />
      </h3>
    </div>

    <ActivityTimeline activities={activities} />
  </div>
);
