import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { type MemberType, type LoanType } from '@kwara/models/src';
import {
  DefaultText as Text,
  Cell,
  Row,
  Currency
} from '@kwara/components/src/PDF';

const styles = StyleSheet.create({
  table: {
    flexDirection: 'column'
  }
});

const LoanAccountsHeader = () => {
  const rows = 5;
  const columnWidth = `${100 / rows}%`;
  return (
    <Row>
      <Cell style={{ width: columnWidth, textAlign: 'left' }}>
        <Text id="MemberSummaryPDF.LoanAccounts.Header.status" />
      </Cell>
      <Cell style={{ width: columnWidth, textAlign: 'left' }}>
        <Text id="MemberSummaryPDF.LoanAccounts.Header.id" />
      </Cell>
      <Cell style={{ width: columnWidth, textAlign: 'left' }}>
        <Text id="MemberSummaryPDF.LoanAccounts.Header.name" />
      </Cell>
      <Cell style={{ width: columnWidth, textAlign: 'right' }}>
        <Text id="MemberSummaryPDF.LoanAccounts.Header.amount" />
      </Cell>
      <Cell style={{ width: columnWidth, textAlign: 'right' }}>
        <Text id="MemberSummaryPDF.LoanAccounts.Header.feesBalance" />
      </Cell>
    </Row>
  );
};

const LoanAccountRow = ({ loan }: { loan: LoanType }) => {
  const { state, id, product, amount, fees } = loan;
  const { name } = product;

  const rows = 5;
  const columnWidth = `${100 / rows}%`;

  return (
    <Row>
      <Cell style={{ width: columnWidth, textAlign: 'left' }}>
        <Text style={{ color: 'lightgrey' }}>{state.current}</Text>
      </Cell>
      <Cell style={{ width: columnWidth, textAlign: 'left' }}>
        <Text style={{ color: 'black' }}>{id}</Text>
      </Cell>
      <Cell style={{ width: columnWidth }}>
        <Text style={{ color: 'black' }}>{name}</Text>
      </Cell>
      <Cell style={{ width: columnWidth }}>
        <Currency style={{ textAlign: 'right', color: 'black' }}>
          {amount}
        </Currency>
      </Cell>

      <Cell style={{ width: columnWidth }}>
        <Currency style={{ textAlign: 'right', color: 'black' }}>
          {fees.balance}
        </Currency>
      </Cell>
    </Row>
  );
};

const LoanAccountsTable = ({ loans }: { loans: LoanType[] }) => {
  return (
    <View>
      {mapIndexed(
        (loan, i) => (
          <LoanAccountRow key={i} loan={loan} />
        ),
        loans
      )}
    </View>
  );
};

export const LoanAccounts = ({ member }: { member: MemberType }) => {
  const { loans } = member;
  return (
    <View style={styles.table}>
      <LoanAccountsHeader />
      <LoanAccountsTable loans={loans} />
    </View>
  );
};
