// @flow

import React from 'react';

import { Text } from '@kwara/components/src/Intl';

import IntlDate from './Date';
import { Time } from './Time';

type Props = {
  value: Date,
  separatorId?: string
};

export const DateTime = ({ value, separatorId = 'at' }: Props) => {
  return (
    <>
      <IntlDate value={value} />{' '}
      <Text id={`General.Separators.${separatorId}`} /> <Time value={value} />
    </>
  );
};
