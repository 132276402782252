// @flow
import React from 'react';

import PageLayout from '@kwara/components/src/PageLayout';
import { type SavingType } from '@kwara/models/src';
import Dashboard from './components/Dashboard';
import Head from '../../components/Head';
import List from './components/List';

import { useSavings } from '../../models/request';
import { useFilter } from '../../models/filter';
import { usePagination } from '../../models/pagination';

const includes = ['member', 'product'];

export const Savings = () => {
  const f = useFilter(filter => ({ state: filter }), 'ACTIVE');
  const {
    onNext,
    allData,
    hasMore,
    isPending,
    error,
    totalResults
  } = usePagination<SavingType>(
    useSavings,
    page => [includes, f.filterValue, page],
    f.filterValue
  );
  return (
    <PageLayout overview={<Dashboard />}>
      <Head titleId="SavingList.title" />

      <List
        filterBy={f.filterValue.state}
        items={allData}
        onLoadMoreData={onNext}
        hasMore={hasMore}
        onFilterChange={f.onFilterChange}
        loading={isPending}
        errors={error.messages}
        totalNumResults={totalResults}
      />
    </PageLayout>
  );
};
