import jwtDecode from 'jwt-decode';
import get from 'lodash/fp/get';

import { Logger } from '@kwara/lib/src/logger';

import typeof Api from './Api';
import { setFavicon } from './favicon';

export const TOKEN_KEY: string = 'kwara-jwt';
export const TRIAL_STATUS = 'kwara-trial';
export const TRIAL_BANNER_CLOSED = 'kwara-banner-closed';

const hasExpired = (exp: number) => exp * 1000 < Date.now();

/**
 * Auth
 *
 * This class manages authentication credentials used
 * to access the API. It exchanges a username and password
 * for a JSON Web Token (JWT) and stores this (and the provided)
 * email address in local storage.
 *
 * It provides methods to check if the JWT has expired, to extract
 * permissions from the token and to explicitly log out via the API.
 */
export default class Auth {
  constructor({ api, storage = window.localStorage }: { api: Api } = {}) {
    this.api = api;
    this.storage = storage;
    this.decoded = null;
  }

  confirmEmail = (token: string) => this.api.confirmEmail(token);

  getRawToken = () => {
    return this.storage.getItem(TOKEN_KEY);
  };

  getExpiryDate = () => {
    const token = this.getToken();

    if (token != null && token.exp) {
      return new Date(token.exp * 1000);
    }

    return null;
  };

  getToken = () => {
    // This method gets called multiple times so we cache the result
    // to avoid having to decode the same token multiple times
    if (this.decoded && !hasExpired(this.decoded.exp)) {
      return this.decoded;
    }

    const apiToken = this.getRawToken();

    if (apiToken == null) {
      return null;
    }

    try {
      const decoded = jwtDecode(apiToken);

      if (decoded && decoded.exp) {
        if (hasExpired(decoded.exp)) {
          this.clearLocalData();
        } else {
          this.decoded = decoded;
          return decoded;
        }
      }
    } catch (error) {
      Logger.error(`Cannot decode JWT`, JSON.stringify(error));
    }

    return null;
  };

  isTillOpen = () => {
    const token = this.getToken();

    if (token == null) {
      return false;
    }

    return get('sub.till.state', token) === 'OPEN';
  };

  setPermissions = (permissions = []) => {
    this.permissions = Object.freeze(permissions);
  };

  getPermissions = () => {
    return this.permissions;
  };

  openTill = async () => {
    if (this.isLoggedIn()) {
      try {
        return await this.api.tillToken(this.getRawToken());
      } catch (err) {
        Logger.error('API error opening till', err);
        return false;
      }
    }
  };

  closeTill = async () => {
    if (this.isLoggedIn()) {
      try {
        return await this.api.removeTillToken(this.getRawToken());
      } catch (err) {
        Logger.error('API error closing till', err);
        return false;
      }
    }
  };

  isLoggedIn = () => {
    return this.getToken() != null;
  };

  logIn = async ({ email, password } = {}) => {
    const { jwt } = await this.api.userToken({ email, password });
    this.storage.setItem(TOKEN_KEY, jwt);
    const { sub } = this.getToken();
    const { trial_user } = sub;
    this.storage.setItem(TRIAL_STATUS, trial_user);
  };

  logOut = async () => {
    if (this.isLoggedIn()) {
      try {
        await this.api.deleteUserToken(this.getRawToken());
      } catch (err) {
        // TODO: this isn't very clean
        Logger.error('API error logging out', err);
      } finally {
        setFavicon();
        this.clearLocalData();
      }
    }

    return;
  };

  passwordForget = data => this.api.passwordForget(data);

  passwordReset = data => this.api.passwordReset(data);

  confirmOrganisation = (token: string) => this.api.confirmOrganisation(token);

  resendInvitation = (email: string) => this.api.resendInvitation(email);

  signUpMember = payload => this.api.createNewMember(payload);

  resendUnlockLink = data => this.api.resendUnlockLink(data);

  unlockAccount = (t: string) => this.api.unlockAccount(t);

  refreshToken = async () => {
    if (this.isLoggedIn()) {
      try {
        const { jwt } = await this.api.refreshUserToken(this.getRawToken());
        this.storage.setItem(TOKEN_KEY, jwt);
        this.decoded = null;
      } catch (err) {
        Logger.error('API error refreshing token', err);
      }
    }
  };

  signUp = payload => this.api.createNewUser(payload);

  clearLocalData = () => {
    this.decoded = null;
    this.storage.removeItem(TOKEN_KEY);
    this.storage.removeItem(TRIAL_STATUS);
    this.storage.removeItem(TRIAL_BANNER_CLOSED);
  };
}
