// @flow

import { attr } from 'spraypaint';

import Base from './Base';

export const UtilityTransaction = Base.extend({
  static: {
    jsonapiType: 'utility_transactions'
  },
  attrs: {
    amount: attr(),
    beneficiary: attr(),
    type: attr(),
    paymentMethod: attr(),
    glCode: attr(),
    reference: attr(),
    invoiceNumber: attr(),
    notes: attr()
  }
});
