// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import size from 'lodash/fp/size';
import get from 'lodash/fp/get';

import { Loadable } from '@kwara/components/src/Loadable';
import { useMember } from '@kwara/models/src/models/request/hooks';
import {
  SavingsTransaction,
  PendingSavingsTransaction,
  type SavingType,
  type MemberType
} from '@kwara/models/src';
import { type TransactionChannelT } from '@kwara/models/src/models/Transactions';

import { store } from '../../models/Store';
import Wizard from '../../components/Wizard';
import { memberPath } from '../../lib/urls';
import { steps } from './config';
import { type MemberPageProps } from '..';

type Props = MemberPageProps;

type BankDataT = {
  bankName: string,
  bankBranch: string,
  accountNumber: string
};

type NotesT = {
  notes?: string
};

export type FormData = {
  member: MemberType,
  accounts: SavingType[],
  accountId: string,
  bankGlId?: string,
  saving?: SavingType,
  amount?: string,
  method?: TransactionChannelT,
  reference?: string,
  glTransferId?: string
} & BankDataT &
  NotesT;

const makeWithdrawal = async (data: FormData) => {
  const {
    amount,
    accountId: savingsId,
    method: rawMethod,
    bankName,
    bankBranch,
    accountNumber,
    bankGlId,
    notes,
    reference,
    glTransferId
  } = data;

  const Transaction = store.isMakerCheckerEnabled(amount, 'WITHDRAWAL')
    ? PendingSavingsTransaction
    : SavingsTransaction;

  const method = rawMethod === 'bankers_cheque' ? 'cheque' : rawMethod;

  // TODO: This can perhaps be improved when ch14895 is actioned
  //
  const paymentMethod = method === 'glTransfer' ? glTransferId : method;

  const transaction = Transaction.create({
    type: 'WITHDRAWAL',
    savingsId,
    amount,
    paymentMethod,
    bankName,
    bankBranch,
    accountNumber,
    notes,
    reference,
    bankGlId
  });

  const didSave = await transaction.save();

  if (!didSave) {
    throw transaction.errors;
  }
};

const includes = [
  { savings: 'product' },
  'savings.latest_pending_authorization_hold'
];

export const MemberWithdrawal = (props: Props) => {
  const { history } = props;
  const r = useMember(props.match.params.memberId, includes);

  return (
    <Loadable {...r}>
      {member => {
        const redirectTo = memberPath({ id: member.id });

        const accounts = member.savingsEligibleForTransactions();
        const initialAccountId = get('[0].id', accounts);

        return size(accounts) > 0 ? (
          <Wizard
            analyticsId="MemberWithdrawal"
            baseUrl={redirectTo}
            history={history}
            initialData={{
              member,
              accounts,
              accountId: initialAccountId
            }}
            cancelReturnsTo={redirectTo}
            currentStep="withdrawal"
            onSubmit={makeWithdrawal}
            steps={steps}
            startId="withdrawal"
            titleId="MemberWithdrawal.title"
          />
        ) : (
          <Redirect to={redirectTo} />
        );
      }}
    </Loadable>
  );
};
