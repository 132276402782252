// @flow

import * as React from 'react';

import { DeprecatedLoadable } from '@kwara/components/src/Loadable';
import { type TransactionChannelT } from '@kwara/models/src/models/Transactions';
import {
  Member,
  type MemberType,
  type SavingProductType,
  Saving
} from '@kwara/models/src';

import Wizard from '../../components/Wizard';
import { memberPath } from '../../lib/urls';
import { steps } from './steps';

import { type WizardPageProps } from '..';

type Props = WizardPageProps<{
  memberId: string
}>;

export type WizardData = {
  savingsAccountDeposit?: ?string,
  shareCapitalAmount?: ?string,
  shareCapital: boolean,
  savingsProduct?: ?SavingProductType,
  amount?: ?number,
  note?: ?string,
  rejectType?: 'SOFT' | 'HARD',
  comment?: ?string,
  method: TransactionChannelT
};

export default class MemberApprove extends React.Component<Props, *> {
  constructor(props: Props) {
    super(props);

    this.state = {
      memberPromise: Member.full()
        .find(props.match.params.memberId)
        .then(response => response.data.deserialize())
    };
  }

  approve = async (data: WizardData) => {
    const params = {};
    const shareCapitalAmount = Number(data.shareCapitalAmount);

    if (shareCapitalAmount && !isNaN(shareCapitalAmount)) {
      params.share_capital_amount = shareCapitalAmount;
    }

    if (data.note) {
      params.comment = data.note;
    }

    const member = await this.state.memberPromise;

    const didApprove = await member.approve(params);

    if (!didApprove) {
      throw member.errors;
    }

    const saving1 = new Saving({
      accountHolderId: member.id,
      productId: 'prime'
    });
    const saving1Success = await saving1.save();
    if (!saving1Success) {
      throw saving1.errors;
    }

    const saving2 = new Saving({
      accountHolderId: member.id,
      productId: 'shares'
    });
    const saving2Success = await saving2.save();
    if (!saving2Success) {
      throw saving2.errors;
    }

    const saving3 = new Saving({
      accountHolderId: member.id,
      productId: 'alpha'
    });
    const saving3Success = await saving3.save();
    if (!saving3Success) {
      throw saving3.errors;
    }
  };

  reject = async (data: {
    rejectType?: 'SOFT' | 'HARD',
    comment?: ?string
  }) => {
    const member = await this.state.memberPromise;
    const params = { comment: data.comment };
    let didReject = null;

    if (data.rejectType === 'SOFT') {
      didReject = await member.softReject(params);
    } else if (data.rejectType === 'HARD') {
      didReject = await member.reject(params);
    }

    if (!didReject) {
      throw member.errors;
    }
  };

  renderContent = (member: MemberType) => {
    const { baseUrl, match, history } = this.props;
    return (
      <Wizard
        analyticsId="MemberApprove"
        baseUrl={baseUrl}
        history={history}
        cancelReturnsTo={memberPath()}
        currentStep={match.params.step}
        currentSubStep={
          match.params.subStep != null
            ? parseInt(match.params.subStep, 10)
            : null
        }
        initialData={{
          member,
          // TODO: These are used for translations, is there a cleaner way?
          firstName: member.firstName,
          fullName: member.fullName()
        }}
        onReject={this.reject}
        onSubmit={this.approve}
        rejectSubtitleId="MemberApprove.reject"
        steps={steps()}
        startId="review"
        titleId="MemberApprove.title"
        successSubtitleId="MemberApprove.success"
        type="approval"
      />
    );
  };

  render() {
    return (
      <DeprecatedLoadable
        loader={this.state.memberPromise}
        loaded={this.renderContent}
      />
    );
  }
}
