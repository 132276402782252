// @flow

import Base from './Base';

const SavingsMetric = Base.extend({
  static: {
    jsonapiType: 'savings_metrics'
  },
  attrs: {},
  methods: {}
});

export default (SavingsMetric: typeof SavingsMetric);
