// @flow

import * as React from 'react';
import get from 'lodash/get';

import { Link } from '@kwara/components/src/Link';
import { Text } from '@kwara/components/src/Intl';
import { Loading } from '@kwara/components/src/Loadable';

import { RedemptionStates, type RedemptionState } from '../Redeemer';
import { ScrollToTop } from '../../../ScrollIntoFocus';
import RegistrationForm, {
  type FormData,
  type InitialDetails
} from '../RegistrationForm';
import { type RedemptionError } from '../Redeemer';

// import cx from 'classnames';
// import Button from '@kwara/components/src/Button';
// import styles from '../../index.module.scss';

type Props = {
  currentState: RedemptionState,
  error: RedemptionError,
  onUserDetails: (data: FormData) => any,
  userDetails: InitialDetails | FormData,
  userType: string
};

// @TODO Buttons will be added in next iteration when error messages are ready for both SACCO and Member app*/
// const Buttons = ({ errorCode }) => (
//   <div className="flex items-center justify-between">
//     <Button isSubmit type="primary" size="medium">
//       <Text id="InvitationRedeem.RedeemerUI.resend.email" />
//     </Button>
//     <Link to="/">
//       <div className={cx(styles.Link, 'kw-text-medium')}>
//         <Text id="InvitationRedeem.RedeemerUI.back.home" />
//       </div>
//     </Link>
//   </div>
// );

export default class RedeemerUI extends React.Component<Props, *> {
  renderUiForCurrentState = () => {
    const { currentState, error } = this.props;

    switch (currentState) {
      case RedemptionStates.init:
      case RedemptionStates.validatingToken:
        return <Loading />;
      case RedemptionStates.invalidToken:
        return (
          <>
            <p className="grey-400 kw-text-medium mt2 mb4">
              <Text
                values={{
                  loginHere: (
                    <Link to="/">
                      <span className="black underline">
                        <Text id="InvitationRedeem.RedeemerUI.error.loginHere" />
                      </span>
                    </Link>
                  ),
                  resetItHere: (
                    <Link to="/password/forgot">
                      <span className="black underline">
                        <Text id="InvitationRedeem.RedeemerUI.error.resetItHere" />
                      </span>
                    </Link>
                  ),
                  br: <br />
                }}
                id={`InvitationRedeem.RedeemerUI.error.${get(error, 'code') ||
                  'genericToken'}`}
              />
            </p>
            {/* @TODO Buttons will be added in next iteration when error messages are ready for both SACCO and Member app*/}
            {/* <Buttons errorCode={error.code} /> */}
          </>
        );
      case RedemptionStates.validToken:
        return (
          <RegistrationForm
            initialDetails={this.props.userDetails}
            onSubmit={this.props.onUserDetails}
            {...this.props}
          />
        );
      case RedemptionStates.submitting:
        return <Loading />;
      case RedemptionStates.submissionErrors:
        return (
          <>
            <div className="mb4">
              <Text
                id={`InvitationRedeem.RedeemerUI.error.${get(error, 'code') ||
                  'genericSubmission'}`}
              />
            </div>

            <RegistrationForm
              initialDetails={this.props.userDetails}
              onSubmit={this.props.onUserDetails}
            />
          </>
        );
      case RedemptionStates.created:
        return (
          <ScrollToTop>
            <>
              <Text
                values={{
                  loginHere: (
                    <Link to="/">
                      <span className="black underline">
                        <Text id="InvitationRedeem.RedeemerUI.error.loginHere" />
                      </span>
                    </Link>
                  )
                }}
                id="InvitationRedeem.RedeemerUI.success"
              />
            </>
          </ScrollToTop>
        );
      default:
        return 'Error';
    }
  };

  render() {
    return <div>{this.renderUiForCurrentState()}</div>;
  }
}
