// @flow

export type ModelError = {
  attribute: string,
  fullMessage: string,
  code: string,
  rowIndex?: ?number
};

export type ModelErrors = {
  [attribute: string]: ModelError
};

export { configureModels } from './models/Base';
export type { IncludesT, WhereT } from './models/Base';

export { default as Activity } from './models/Activity';
export type { ActivityType } from './models/Activity';

export const createCancellablePromise = (
  promise: Promise<*>,
  cancelSource
): CancellablePromise => {
  const cancellable: any = promise.then();

  cancellable.cancel = () => {
    cancelSource.cancel();
  };

  return (cancellable: CancellablePromise);
};
export type CancellablePromise = Promise<*> & { cancel: () => void };

export { Bank } from './models/Bank';
export type { BankT } from './models/Bank';

export { BankAccount } from './models/BankAccount';
export type { BankAccountT } from './models/BankAccount';

export { BankBranch } from './models/BankBranch';
export type { BankBranchT } from './models/BankBranch';

export { BankGlAccount, GlContexts } from './models/BankGlAccount';
export type { BankGlAccountT, GlContextsT } from './models/BankGlAccount';

export { BatchTransactionImport } from './models/BatchTransactionImport';
export type {
  BatchTransactionImportT,
  BatchTransactionPayloadT
} from './models/BatchTransactionImport';

export { BatchTransaction } from './models/BatchTransaction';
export type { BatchTransactionT } from './models/BatchTransaction';

export { Branch } from './models/Branch';
export type { BranchT } from './models/Branch';

export { BranchAddress } from './models/BranchAddress';
export type { BranchAddressT } from './models/BranchAddress';

export { CrbDataSubmission } from './models/CrbDataSubmission';
export type { CrbDataSubmissionT } from './models/CrbDataSubmission';

export { Event } from './models/Event';
export type { EventT } from './models/Event';

export { LoanAccountCrbSubmissions } from './models/LoanAccountCrbSubmissions';
export type {
  LoanAccountCrbSubmissionsT
} from './models/LoanAccountCrbSubmissions';

export { default as CustomField } from './models/CustomField';
export type { CustomFieldType } from './models/CustomField';

export { default as IdDocument } from './models/IdDocument';
export type { IdDocumentType } from './models/IdDocument';

export {
  GeneralLedgerAccount,
  GeneralLedgerAccountApi
} from './models/GeneralLedgerAccount';
export type { GeneralLedgerAccountT } from './models/GeneralLedgerAccount';

export { default as Guarantee, Guarantor } from './models/Guarantee';
export type { GuaranteeType } from './models/Guarantee';

export { JournalEntry } from './models/JournalEntry';
export type { JournalEntryT } from './models/JournalEntry';

export {
  default as Loan,
  LoanStates,
  LoanBaseStates,
  fields as LoanFields
} from './models/Loan';
export type { LoanType, LoanState, LoanBaseState, LoanId } from './models/Loan';

export { LoanApplication } from './models/LoanApplication';
export type { LoanApplicationT } from './models/LoanApplication';

export { LoanApplicationApproval } from './models/LoanApplicationApproval';
export type {
  LoanApplicationApprovalT
} from './models/LoanApplicationApproval';

export { LoanApprovalTierSet } from './models/LoanApprovalTierSet';
export type { LoanApprovalTierSetT } from './models/LoanApprovalTierSet';

export { LoanApprovalSetting } from './models/LoanApprovalSetting';
export type { LoanApprovalSettingT } from './models/LoanApprovalSetting';

export { LoanApprovalTier } from './models/LoanApprovalTier';
export type { LoanApprovalTierT } from './models/LoanApprovalTier';

export { default as LoanMetric } from './models/LoanMetric';

export { PhoneNumberVerification } from './models/PhoneNumberVerification';
export type {
  PhoneNumberVerificationT
} from './models/PhoneNumberVerification';

export { default as LoanProduct, PeriodUnits } from './models/LoanProduct';

export type { LoanProductType, PeriodUnitsT } from './models/LoanProduct';

export {
  default as LoanTransaction,
  PendingLoanTransaction
} from './models/LoanTransaction';
export type { LoanTransactionType } from './models/LoanTransaction';

export { default as MemberAddress } from './models/MemberAddress';
export type { MemberAddressType } from './models/MemberAddress';

export {
  Attachment,
  Attachments,
  allowedExtensions,
  mimeTypesMaps
} from './models/Attachment';
export type { AttachmentT } from './models/Attachment';

export { Comment } from './models/Comment';
export type { CommentT } from './models/Comment';

export {
  default as Member,
  MemberStates,
  EmploymentStatuses
} from './models/Member';
export type { MemberType, MemberState } from './models/Member';

export { MemberEligibility } from './models/MemberEligibility';
export type { MemberEligibilityT } from './models/MemberEligibility';

export { default as MemberMetric } from './models/MemberMetric';

export { default as Metric } from './models/Metric';
export type { MetricType } from './models/Metric';

export { MemberReport } from './models/MemberReport';
export type { MemberReportT } from './models/MemberReport';

export { Organisation } from './models/Organisation';
export type { OrganisationT } from './models/Organisation';

export { default as Repayment } from './models/Repayment';
export type { RepaymentType } from './models/Repayment';

export { default as Saving, SavingStates } from './models/Saving';
export type { SavingType, SavingState } from './models/Saving';

export { default as SavingsMetric } from './models/SavingsMetric';

export { default as Schedule } from './models/Schedule';
export type { ScheduleType } from './models/Schedule';

export { default as SavingProduct } from './models/SavingProduct';
export type {
  SavingProductType,
  SavingProductId
} from './models/SavingProduct';

export { NextOfKin } from './models/NextOfKin';
export type { NextOfKinT } from './models/NextOfKin';

export { Role } from './models/Role';
export type { RoleT } from './models/Role';

export { AppRole } from './models/AppRole';
export type { AppRoleT } from './models/AppRole';

export { AppPermission, KwaraPermissionsConfig } from './models/AppPermission';
export type { AppPermissionT } from './models/AppPermission';

export {
  default as SavingsTransaction,
  PendingSavingsTransaction
} from './models/SavingsTransaction';
export type { SavingsTransactionType } from './models/SavingsTransaction';

export { SupervisorMetric } from './models/SupervisorMetric';
export type { SupervisorMetricT } from './models/SupervisorMetric';

export { Till, CurrentTill } from './models/Till';
export type { TillT, TillStatusT } from './models/Till';

export { TillMetric } from './models/TillMetric';
export type { TillMetricT } from './models/TillMetric';

export { TillTransaction } from './models/TillTransaction';
export type { TillTransactionType } from './models/TillTransaction';

export { TopupRequest, TopupRequestStatus } from './models/TopupRequest';
export type { TopupRequestT, TopupRequestStatusT } from './models/TopupRequest';

export class SearchCancellation extends Error {}

export { default as Transaction } from './models/Transaction';
export type { TransactionType } from './models/Transaction';
export { TransactionChannels } from './models/Transactions';
export type { TransactionChannelT } from './models/Transactions';

export {
  TransactionChannel as MambuTransactionChannel
} from './models/TransactionChannel';
export type {
  TransactionChannelT as MambuTransactionChannelT
} from './models/TransactionChannel';

export { Lead } from './models/Lead';

export { LoginActivity } from './models/LoginActivity';
export type { LoginActivityT } from './models/LoginActivity';

export { User, Profile } from './models/User';
export type { UserT } from './models/User';

export { UserTypes } from './models/userTypes';
export type { UserType } from './models/userTypes';

export { UtilityTransaction } from './models/UtilityTransaction';

export { default as Invitation } from './models/Invitation';
export type { UserDetails } from './models/Invitation';

export { InvitationModel } from './models/InvitationModel';
export type { InvitationModelT } from './models/InvitationModel';

export {
  Collateral,
  CollateralTypes,
  CollateralFields
} from './models/Collateral';
export type { CollateralT, CollateralTypeT } from './models/Collateral';

export { BatchUpload } from './models/BatchUpload';
export type { BatchUploadT } from './models/BatchUpload';

export { default as cameliseObjectKeys } from './lib/cameliseObjectKeys';
export { snakeCaseObjectKeys } from './lib/snakeCaseObjectKeys';

export type ErrorDetails = Array<{
  detail: string,
  meta: {
    attribute: string
  },
  source: {
    pointer: string
  }
}>;

export {
  default as createModelErrors,
  createErrorsFromApiResponse
} from './models/createModelErrors';
