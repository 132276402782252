import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import '@kwara/components/src/foundations';
import AppLayout from '@kwara/components/src/AppLayout';
import { configureModels } from '@kwara/models/src';
import {
  Provider as IntlProvider,
  prepareTranslations
} from '@kwara/components/src/Intl';
import {
  Banner as NotificationBanner,
  Provider as NotificationProvider
} from '@kwara/components/src/Notification';
import DemoUserSwitcher from '@kwara/components/src/DemoUserSwitcher';
import LogIn, { Unlock } from '@kwara/components/src/LogIn';
import { ForgetPassword } from '@kwara/components/src/ForgetPassword';
import { PasswordReset } from '@kwara/components/src/PasswordReset';
import { NotFound404 } from '@kwara/components/src/NotFound404';
import { NamedRoute } from '@kwara/components/src/Route';
import { Body } from '@kwara/components/src/Body';
import { EnvironmentBanner } from '@kwara/components/src/EnvironmentBanner';
import zIndices from '@kwara/lib/src/zIndices';
import { appName } from '@kwara/lib/src/utils';
import { configureStores } from '@kwara/components/src/configureStores';
import AuthExpiryChecker from '@kwara/components/src/AuthExpiryChecker';
import DetectUserActivity from '@kwara/components/src/AuthExpiryChecker/DetectUserActivity';
import { PermissionDenied } from '@kwara/components/src/PermissionDenied';

import { AppPermissions } from './models';
import { ProfileProvider, ProfileContext } from './models/Profile';
import * as pages from './pages';
import { AppUpdateChecker } from './components/AppUpdateChecker';
import Head from './components/Head';
import AppNavigation from './components/AppNavigation';
import Visible from './components/Visible';
import PermittedRoute from './components/PermittedRoute';
import DevOnlyRoute from './components/DevOnlyRoute';
import {
  TellerNotificationBannerMaximum,
  TellerNotificationBannerMinimum
} from './components/TellerNotificationBanner';
import { TrialNotificationChecker } from './components/TrialNotificationChecker';
import config from './config';
import auth, { TOKEN_KEY } from './lib/auth';

import { GoogleTagManager } from './lib/GoogleTagManager';
import { store } from './models/Store';

import en from './translations/en.json';
import styles from './styles.module.scss';

configureModels({
  apiBaseUrl: config.API_BASE_URL,
  jwtStorageKey: TOKEN_KEY
});
configureStores(store);
appName.current = config.appName;

const KeyControl = ({ history }) => {
  document.onkeyup = function(e) {
    // M
    if (e.altKey && e.which === 77) {
      history.push('/members');
      // L
    } else if (e.altKey && e.which === 76) {
      history.push('/loans');
      // S
    } else if (e.altKey && e.which === 83) {
      history.push('/savings');
      // A
    } else if (e.altKey && e.which === 65) {
      history.push('/finance/savingTransactions');
    }
  };

  return null;
};

class App extends Component {
  constructor() {
    super();
    this.activityChecker = new DetectUserActivity();
  }

  componentDidMount() {
    GoogleTagManager.initialize();
  }

  componentWillUnmount() {
    this.activityChecker.destroy();
  }

  logOut = async () => {
    await auth.logOut();
    this.forceUpdate();
  };

  render() {
    const navigation = (
      <Route
        path="/"
        render={() => {
          if (!auth.isLoggedIn()) {
            return null;
          }

          return (
            <AppNavigation
              isLoggedIn={auth.isLoggedIn()}
              onLogOut={this.logOut}
            />
          );
        }}
      />
    );

    const demoEnvironmentUtils =
      config.env === 'demo' ? (
        <Route
          path="/"
          render={() => (
            <DemoUserSwitcher
              appName={config.appName}
              auth={auth}
              zIndexClass={zIndices.DebugSwitchUser}
            />
          )}
        />
      ) : null;

    const app = (
      <Route
        path="/"
        render={({ location, history }) => (
          <AppLayout
            notificationBanner={<NotificationBanner />}
            navigation={navigation}
            zIndexClass={zIndices.Navigation}
            flexible={!auth.isLoggedIn()}
            fullPath={`${location.pathname}${location.search}`}
          >
            <KeyControl history={history} />
            <AppUpdateChecker />
            <Head />
            <Body pathname={location.pathname} />
            {demoEnvironmentUtils}
            <Route
              render={() =>
                auth.isLoggedIn() ? (
                  <>
                    {/*
                      Checks for auth token expiry when logged in
                    */}
                    <TrialNotificationChecker />

                    <Route
                      path="/"
                      render={props => (
                        <AuthExpiryChecker
                          {...props}
                          auth={auth}
                          logOut={this.logOut}
                          getLastActivityTime={
                            this.activityChecker.getLastActivityTime
                          }
                        />
                      )}
                    />

                    <Switch>
                      <DevOnlyRoute
                        name="Debug"
                        path="/debug"
                        component={pages.Debug}
                        fallback={NotFound404}
                      />

                      <NamedRoute
                        name="Onboarding"
                        path="/welcome"
                        exact
                        render={props => <pages.Onboarding {...props} />}
                      />

                      <NamedRoute
                        name="OnboardingWizard"
                        path="/welcome/wizard/:step?/:subStep?"
                        exact
                        render={props => (
                          <pages.OnboardingWizard
                            baseUrl="/welcome/wizard"
                            {...props}
                          />
                        )}
                      />
                      <PermittedRoute
                        name="CreditSubmit"
                        permission={AppPermissions.CreditSubmit}
                        exact
                        path="/credit/:action?"
                        component={pages.CreditSubmit}
                      />

                      <NamedRoute
                        name="Loans"
                        exact
                        path="/loans"
                        component={pages.Loans}
                      />

                      {/* Either load /loans/create or /loans/:loanId but not both */}

                      <NamedRoute
                        name="LoanAdd"
                        exact
                        path="/loans/create/:step?/:subStep?"
                        render={props => (
                          <Visible
                            to={AppPermissions.AddLoans}
                            fallback={<PermissionDenied />}
                          >
                            {/* Renders loan below modal */}
                            <pages.Loans {...props} />
                            <pages.LoanAdd {...props} baseUrl="/loans/create" />
                          </Visible>
                        )}
                      />

                      {/* Decides whether to redirect to LoanApprove or LoanDetail */}
                      <NamedRoute
                        name="LoanDetail"
                        exact
                        path="/loans/:loanId"
                        component={pages.LoanRouteDecider}
                      />

                      <PermittedRoute
                        exact
                        name="BatchTransactionImportsCreate"
                        permission={
                          AppPermissions.CreateBatchTransactionImports
                        }
                        path="/batch_transaction_imports/add"
                        component={pages.BatchTransactionImportsUpload}
                      />

                      <PermittedRoute
                        exact
                        name="BatchTransactionImports"
                        permission={AppPermissions.ViewBatchTransactionImports}
                        path="/batch_transaction_imports/:id?"
                        component={pages.BatchTransactionImports}
                      />

                      <NamedRoute
                        name="LoanProductAdd"
                        exact
                        path="/settings/loans/new/:step?/:subStep?"
                        render={props => (
                          <pages.LoanProductAdd
                            baseUrl={`/settings/loans/new`}
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="AppRoleAdd"
                        exact
                        path="/settings/roles/new/:step?/:subStep?"
                        render={props => (
                          <pages.AppRoleWizard
                            baseUrl={`/settings/roles/new`}
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="AppRoleEdit"
                        exact
                        path="/settings/roles/:roleId/edit/:step?/:subStep?"
                        render={props => (
                          <pages.AppRoleWizard
                            baseUrl={`/settings/roles/${props.match.params.roleId}/edit`}
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="LoanProductEdit"
                        exact
                        path="/settings/loans/:productId/edit/:step?/:subStep?"
                        render={props => (
                          <pages.LoanProductEdit
                            baseUrl={`/settings/loans/:productId/edit`}
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="UserEdit"
                        exact
                        path="/settings/team/:userId/edit/:step?/:subStep?"
                        render={props => (
                          <pages.UserEdit
                            baseUrl={`/settings/team/:userId/edit`}
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="LoanSchedulePreview"
                        exact
                        path="/settings/loans/:productId/schedule/:step?/:subStep?"
                        render={props => (
                          <pages.LoanSchedulePreview
                            baseUrl={`/settings/loans/:productId/schedule`}
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="MemberReportGenerate"
                        exact
                        path="/settings/data/generate/:step?/:subStep?"
                        render={props => (
                          <pages.MemberReportGenerate
                            baseUrl={'/settings/data/generate'}
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="SavingProductAdd"
                        exact
                        path="/settings/saving/new/:step?/:subStep?"
                        render={props => (
                          <pages.SavingProductAdd
                            baseUrl={`/settings/saving/new`}
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="SavingProductEdit"
                        exact
                        path="/settings/saving/:productId/edit/:step?/:subStep?"
                        render={props => (
                          <pages.SavingProductEdit
                            baseUrl={`/settings/saving/:productId/edit`}
                            {...props}
                          />
                        )}
                      />

                      <PermittedRoute
                        name="InviteUser"
                        permission={AppPermissions.InviteUser}
                        exact
                        path="/settings/workspace/invite/:step?/:subStep?"
                        render={props => (
                          <pages.InviteUser
                            baseUrl="/settings/workspace/invite"
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="Settings"
                        path="/settings/:module?"
                        component={pages.Settings}
                      />

                      <NamedRoute
                        exact
                        name="Finance"
                        path="/finance/:module?"
                        component={pages.Finance}
                      />

                      <PermittedRoute
                        permission={AppPermissions.CreateJournalEntries}
                        name="JournalEntryAdd"
                        path="/finance/journalEntries/new/:step?/:subStep?"
                        render={props => (
                          <pages.JournalEntryAdd
                            baseUrl={`/finance/journalEntries/new`}
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="LoanApprove"
                        exact
                        path="/loans/:loanId/approve/:step?/:subStep?"
                        render={props => (
                          <pages.LoanApprove
                            baseUrl={`/loans/${props.match.params.loanId}/approve`}
                            {...props}
                          />
                        )}
                      />
                      <PermittedRoute
                        name="LoanReschedule"
                        permission={AppPermissions.RescheduleLoans}
                        exact
                        path="/loans/:loanId/reschedule/:step?/:subStep?"
                        render={props => (
                          <pages.LoanReschedule
                            baseUrl={`/loans/${props.match.params.loanId}/reschedule`}
                            {...props}
                          />
                        )}
                      />

                      <PermittedRoute
                        name="LoanRefinance"
                        permission={AppPermissions.RefinanceLoans}
                        exact
                        path="/loans/:loanId/refinance/:step?/:subStep?"
                        render={props => (
                          <pages.LoanRefinance
                            baseUrl={`/loans/${props.match.params.loanId}/refinance`}
                            {...props}
                          />
                        )}
                      />

                      <PermittedRoute
                        name="LoanWriteOff"
                        permission={AppPermissions.WriteOffLoans}
                        exact
                        path="/loans/:loanId/writeoff/:step?/:subStep?"
                        render={props => (
                          <pages.LoanWriteOff
                            baseUrl={`/loans/${props.match.params.loanId}/writeoff`}
                            {...props}
                          />
                        )}
                      />

                      <PermittedRoute
                        name="LoanRepayment"
                        permission={AppPermissions.AddRepayments}
                        exact
                        path="/loans/:loanId/repayment"
                        component={pages.LoanPayment}
                      />

                      <PermittedRoute
                        name="LoanPenalty"
                        permission={AppPermissions.AddPenalties}
                        exact
                        path="/loans/:loanId/penalty"
                        component={pages.LoanPenalty}
                      />

                      <PermittedRoute
                        name="LoanDisburse"
                        permission={AppPermissions.DisburseLoans}
                        exact
                        path="/loans/:loanId/disburse"
                        component={pages.LoanDisburse}
                      />

                      <PermittedRoute
                        name="LoanPayOff"
                        permission={AppPermissions.PayOffLoan}
                        exact
                        path="/loans/:loanId/payOff/:step?/:subStep?"
                        render={props => (
                          <pages.LoanPayOff
                            baseUrl={`/loans/${props.match.params.loanId}/payOff`}
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="Savings"
                        exact
                        path="/savings"
                        component={pages.Savings}
                      />

                      {/* Either load /savings/create or /savings/:savingId but not both */}

                      <NamedRoute
                        name="SavingAdd"
                        exact
                        path="/savings/create/:step?/:subStep?"
                        render={props => (
                          <Visible
                            to={AppPermissions.AddSavings}
                            fallback={<PermissionDenied />}
                          >
                            {/* Renders list below modal */}
                            <pages.Savings {...props} />
                            <pages.SavingAdd
                              {...props}
                              baseUrl="/savings/create"
                            />
                          </Visible>
                        )}
                      />

                      <PermittedRoute
                        name="SavingClose"
                        permission={AppPermissions.CloseSavings}
                        exact
                        path="/savings/:savingId/close/:step?/:subStep?"
                        render={props => (
                          <pages.SavingClose
                            baseUrl={`/savings/${props.match.params.savingId}/close`}
                            {...props}
                          />
                        )}
                      />

                      <PermittedRoute
                        name="MakeTransfer"
                        permission={AppPermissions.MakeTransfer}
                        exact
                        path="/savings/:savingId/transfer/:step?/:subStep?"
                        render={props => (
                          <pages.MakeTransfer
                            baseUrl={`/savings/${props.match.params.savingId}/transfer`}
                            {...props}
                          />
                        )}
                      />

                      <NamedRoute
                        name="SavingDetail"
                        exact
                        path="/savings/:savingId"
                        component={pages.SavingDetail}
                      />

                      <PermittedRoute
                        name="SavingDeposit"
                        exact
                        path="/savings/:savingId/deposit"
                        component={pages.SavingPayment}
                        permission={AppPermissions.AddDeposits}
                      />

                      <PermittedRoute
                        name="SavingPenalty"
                        permission={AppPermissions.AddPenalties}
                        exact
                        path="/savings/:savingId/penalty"
                        component={pages.SavingPenalty}
                      />

                      {/* Redirects from /members to / */}
                      <Route
                        exact
                        path="/members"
                        render={() => <Redirect exact to="/" />}
                      />

                      <NamedRoute
                        name="Members"
                        exact
                        path="/"
                        component={pages.Members}
                      />

                      {/* Either load /members/create or /members/:memberId but not both */}

                      <NamedRoute
                        name="MemberAdd"
                        exact
                        path="/members/create/:step?/:subStep?"
                        render={({ history, match }) => (
                          <Visible
                            to={AppPermissions.AddMembers}
                            fallback={<PermissionDenied />}
                          >
                            <Route
                              exact
                              path="/members/create.*"
                              component={pages.Members}
                            />
                            <pages.MemberAdd
                              history={history}
                              match={match}
                              baseUrl="/members/create"
                              action="add"
                            />
                          </Visible>
                        )}
                      />

                      <PermittedRoute
                        name="MemberClose"
                        permission={AppPermissions.ExitMembers}
                        exact
                        path="/members/:memberId/close/:step?/:subStep?"
                        render={props => (
                          <pages.MemberClose
                            baseUrl={`/members/${props.match.params.memberId}/close`}
                            {...props}
                          />
                        )}
                      />

                      {/* Decides whether to redirect to MemberApprove or MemberDetail */}
                      <NamedRoute
                        name="MemberDetail"
                        exact
                        path="/members/:memberId"
                        component={pages.MemberDetailRoute}
                      />

                      <NamedRoute
                        name="MemberEdit"
                        exact
                        path="/members/:memberId/edit/:step?/:subStep?"
                        render={pars => (
                          <Visible
                            to={AppPermissions.AddMembers}
                            fallback={<PermissionDenied />}
                          >
                            <pages.MemberAdd
                              history={pars.history}
                              match={pars.match}
                              baseUrl={`/members/${pars.match.params.memberId}/edit`}
                            />
                          </Visible>
                        )}
                      />

                      <PermittedRoute
                        name="MemberDeposit"
                        permission={AppPermissions.AddDeposits}
                        exact
                        path="/members/:memberId/deposit"
                        component={pages.MemberDeposit}
                      />

                      <PermittedRoute
                        name="MemberWithdrawal"
                        permission={AppPermissions.AddWithdrawal}
                        exact
                        path="/members/:memberId/withdrawal"
                        component={pages.MemberWithdrawal}
                      />

                      <PermittedRoute
                        name="MemberRepayment"
                        permission={AppPermissions.AddRepayments}
                        exact
                        path="/members/:memberId/repayment"
                        component={pages.MemberRepayment}
                      />

                      <NamedRoute
                        name="MemberApprove"
                        exact
                        path="/members/:memberId/approve/:step?/:subStep?"
                        render={props => (
                          <pages.MemberApprove
                            baseUrl={`/members/${props.match.params.memberId}/approve`}
                            {...props}
                          />
                        )}
                      />

                      <PermittedRoute
                        permission={AppPermissions.UseTill}
                        name="Till"
                        exact
                        path="/till"
                        render={props => (
                          <>
                            <TellerNotificationBannerMaximum {...props} />
                            <TellerNotificationBannerMinimum {...props} />
                            <pages.Till {...props} auth={auth} />
                          </>
                        )}
                      />

                      <PermittedRoute
                        permission={AppPermissions.UseTellerSupervisorPage}
                        name="Tellers"
                        exact
                        path="/Tellers"
                        render={props => (
                          <pages.Tellers {...props} auth={auth} />
                        )}
                      />

                      <PermittedRoute
                        name="TopupRequest"
                        permission={AppPermissions.CreateTopupRequests}
                        exact
                        path="/topup/new/:step?/:subStep?"
                        render={props => (
                          <pages.TopupRequest
                            {...props}
                            baseUrl={`/topup/new`}
                          />
                        )}
                      />

                      <PermittedRoute
                        name="TopupReview"
                        permission={AppPermissions.ReviewTopupRequest}
                        exact
                        path="/topup/:topupId/:step?/:subStep?"
                        render={props => (
                          <pages.TopupReview
                            {...props}
                            baseUrl={`/topup/${props.match.params.topupId}`}
                          />
                        )}
                      />

                      <PermittedRoute
                        permission={AppPermissions.ViewTillTransactions}
                        name="TransactionDetail"
                        exact
                        path="/transactions/:transactionId"
                        component={pages.TransactionDetail}
                      />

                      <PermittedRoute
                        permission={AppPermissions.ViewTills}
                        name="TillDetail"
                        exact
                        path="/tellers/:tillId"
                        component={pages.TillDetail}
                      />

                      <NamedRoute
                        name="NotFound404"
                        path="/"
                        component={NotFound404}
                      />
                    </Switch>
                  </>
                ) : (
                  <>
                    <Switch>
                      <NamedRoute
                        name="SelfService"
                        exact
                        path="/selfservice/members/create/:step?/:subStep?"
                        render={({ history, match }) => (
                          <pages.SelfService
                            history={history}
                            match={match}
                            baseUrl="/selfservice/members/create"
                            action="add"
                          />
                        )}
                      />
                      <NamedRoute
                        name="InvitationRedeem"
                        path="/invitation"
                        component={pages.InvitationRedeem}
                      />
                      {/* Disabled until Signup fully configured with onboarding Wizard:
                        https://kwara.slack.com/archives/C8EPTLL7K/p1573042638016500
                        <NamedRoute
                        name="SignUp"
                        path="/signup"
                        render={props => (
                          <pages.SignUp {...props} auth={auth} />
                        )}
                      /> */}
                      <NamedRoute
                        name="ConfirmEmail"
                        path="/confirm"
                        render={props => (
                          <pages.ConfirmEmail {...props} auth={auth} />
                        )}
                      />
                      <NamedRoute
                        name="Success"
                        path="/success"
                        render={props => (
                          <pages.Success {...props} auth={auth} />
                        )}
                      />
                      <NamedRoute
                        name="ForgetPassword"
                        path="/password/forgot"
                        render={props => (
                          <ForgetPassword {...props} auth={auth} />
                        )}
                      />
                      <NamedRoute
                        name="PasswordReset"
                        path="/password/reset"
                        render={props => (
                          <PasswordReset {...props} auth={auth} />
                        )}
                      />
                      <NamedRoute
                        name="Unlock"
                        path="/unlock"
                        render={props => <Unlock {...props} auth={auth} />}
                      />
                      <NamedRoute
                        name="Login"
                        path="/"
                        render={props => <LogIn {...props} auth={auth} />}
                      />
                    </Switch>
                  </>
                )
              }
            />
          </AppLayout>
        )}
      />
    );

    return (
      <div className={styles.AppWrapper}>
        <IntlProvider messages={prepareTranslations(en)}>
          <NotificationProvider>
            <BrowserRouter>
              <ProfileProvider auth={auth}>
                <EnvironmentBanner ctx={ProfileContext} />
                {app}
              </ProfileProvider>
            </BrowserRouter>
          </NotificationProvider>
        </IntlProvider>
      </div>
    );
  }
}

export default App;
