// @flow

import { attr, hasMany } from 'spraypaint';
import get from 'lodash/fp/get';

import Base, { type BaseModel } from './Base';
import { BankBranch } from './BankBranch';

export const Bank = Base.extend({
  static: {
    jsonapiType: 'banks'
  },
  attrs: {
    name: attr(),
    code: attr(),
    bankBranches: hasMany({ type: BankBranch })
  },
  methods: {
    deserialize() {
      this.bankBranchId = get('bankBranch.id', this);
      return this;
    }
  }
});

export interface BankT extends BaseModel<BankT> {
  name: string;
  code: string;
}
