// @flow

import * as React from 'react';
import { observer } from 'mobx-react';
import { injectIntl, type IntlShape } from 'react-intl';
import { PDFViewer } from '@react-pdf/renderer';

import { getTranslation } from '@kwara/components/src/Intl';
import { pdfTranslationStore as pdf } from '@kwara/components/src/PDF';
import { ActionButton, PrintButton } from '@kwara/components/src';
import { type UserT } from '@kwara/models/src';
import Modal from '@kwara/components/src/Modal';

import { ProfileContext } from '../../models/Profile';
import styles from './index.module.css';

const { useState, useContext } = React;

export const PrintPDFButton = observer(
  injectIntl(
    ({
      renderPDF,
      Button = PrintButton,
      className,
      ariaLabel,
      intl
    }: {
      renderPDF: ({ profile: UserT }) => React.Node,
      Button?: React.Node,
      className?: string,
      ariaLabel?: string,
      intl: IntlShape
    }) => {
      const [expanded, setExpanded] = useState(false);
      const store = useContext(ProfileContext);

      const translate = (translationId, values) =>
        getTranslation(intl, translationId, values);

      pdf.updateTranslateFunction(translate);
      pdf.setCurrency(store.currency);

      const { profile = {} } = store;

      return (
        <>
          <Modal isOpen={expanded} onCancel={() => setExpanded(false)}>
            <PDFViewer width="100%" height="100%">
              {renderPDF({ profile })}
            </PDFViewer>
            <ActionButton
              type="cancel"
              className={styles.CloseBtn}
              onClick={() => setExpanded(false)}
            />
          </Modal>
          <Button
            ariaLabel={ariaLabel}
            className={className}
            onClick={() => setExpanded(true)}
          />
        </>
      );
    }
  )
);
