// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import StatusTag from '@kwara/components/src/StatusTag';
import { Date, Text } from '@kwara/components/src/Intl';
import { type MemberType } from '@kwara/models/src';

type Props = {
  member: MemberType,
  statusOverride: void | 'APPLICANT' | 'GUARANTOR'
};

export const MemberOverviewStatus = ({ member, statusOverride }: Props) => (
  <div className="grey-400 flex flex-row flex-wrap items-baseline">
    <StatusTag state={statusOverride || get('state.current', member)} />
    <div className="nowrap ml1">
      <Text id="MemberDetail.Overview.joined" />
      {': '}
      <Date value={member.createdAt} />
    </div>
    <div className="nowrap ml1">
      <Text id="MemberDetail.Overview.id" values={{ id: member.id }} />
    </div>
  </div>
);
