// @flow

import * as React from 'react';

import { type SubStepProps } from '..';

export const Email = ({ StackChild, TextField }: SubStepProps) => {
  return (
    <StackChild>
      <TextField name="email" labelId="MemberReportGenerate.Email.email" />
    </StackChild>
  );
};

Email.validate = {
  email: {
    email: true
  }
};

Email.title = 'MemberReportGenerate.Email.title';
Email.subtitle = 'MemberReportGenerate.Email.subtitle';
