// @flow

import { attr, belongsTo } from 'spraypaint';

import { type TillT } from '..';

import Base from './Base';

export const TopupRequestStatus = Object.freeze({
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  DECLINED: 'DECLINED'
});

export type TopupRequestStatusT = $Values<typeof TopupRequestStatus>;

export const TopupRequest = Base.extend({
  static: {
    jsonapiType: 'topup_requests'
  },
  attrs: {
    status: attr(),
    type: attr(),
    amount: attr(),
    comment: attr(),
    tillSessionId: attr(),
    supervisor: attr(),
    till: belongsTo(),
    updatedAt: attr(),
    createdAt: attr()
  },
  methods: {}
});

export type TopupRequestT = {
  status: TopupRequestStatusT,
  type: string,
  amount: number,
  comment?: string,
  till: TillT,
  updatedAt: string,
  createdAt: string
};
