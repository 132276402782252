// @flow
import * as React from 'react';

import { Text, type TranslationId } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src';

import styles from './index.module.scss';

type Props = {
  onCancel: () => void,
  titleId: TranslationId,
  values?: { [string]: any }
};

export const WizardHeader = ({
  titleId,
  onCancel,
  values,
  currentStep,
  steps
}: Props) => (
  <header
    className={`mt3 h3 w-100 ph4 flex items-center bb b--light-grey-500 ${styles.container}`}
  >
    <div>
      <h3 data-testid="wizard-title-id" className="kw-weight-bold">
        <Text id={titleId} values={values} />
      </h3>
      <div className={styles.mobileProgress}>
        <p>
          <Text id="Wizard.steps" values={{ currentStep, steps }} />
        </p>
      </div>
    </div>
    <div className="ml-auto">
      <Link onClick={onCancel}>Cancel</Link>
    </div>
  </header>
);
