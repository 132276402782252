// @flow

import { attr, belongsTo } from 'spraypaint';

import Base, { type BaseModel } from './Base';
import {
  LoanApprovalTierSet,
  type LoanApprovalTierSetT
} from './LoanApprovalTierSet';

export const LoanApprovalTier = Base.extend({
  static: {
    jsonapiType: 'loan_approval_tiers'
  },
  attrs: {
    min: attr(),
    max: attr(),
    loanApprovalTierSet: belongsTo({ type: LoanApprovalTierSet })
  }
});

export interface LoanApprovalTierT extends BaseModel<LoanApprovalTierT> {
  min: string;
  max: string;
  loanApprovalTierSet: LoanApprovalTierSetT;
}
