//@flow
import * as React from 'react';
import { differenceInDays, distanceInWords } from '@kwara/lib/src/dates';

import { Date as IntlDate, Text } from '@kwara/components/src/Intl';

type ISODateString = string;

export default ({ value }: { value: ISODateString }) => {
  const now = new Date();
  const past = new Date(value);

  const daysAgo = differenceInDays(now, past) || 0;

  return daysAgo > 7 ? (
    <IntlDate value={past} />
  ) : (
    <Text
      id="Intl.RelativeTime.timeAgo"
      values={{
        distance: distanceInWords(past, now)
      }}
    />
  );
};
