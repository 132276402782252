//@flow

import * as React from 'react';

import { type SubStepComponentProps } from '../../../components/Wizard';

export const Address = ({ StackChild, TextField }: SubStepComponentProps<>) => (
  <StackChild>
    <TextField
      name="addresses[0].postalAddress"
      labelId="AddMember.Address.Postal.label"
      placeholderId="AddMember.Address.Postal.placeholder"
      infoId="AddMember.Address.Postal.info"
    />
    <TextField
      name="addresses[0].physicalAddress"
      labelId="AddMember.Address.Physical.label"
      placeholderId="AddMember.Address.Physical.placeholder"
      infoId="AddMember.Address.Physical.info"
    />
  </StackChild>
);

const loanAdd = {
  'addresses[0].postalAddress': {
    isRequired: () => true
  },
  'addresses[0].physicalAddress': {
    isRequired: () => true
  }
};

export const validateConfig = {
  memberAdd: {},
  memberDetail: {},
  loanAdd
};

Address.validateConfig = validateConfig;
