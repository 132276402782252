import * as React from 'react';
import { withRouter } from 'react-router-dom';
import isNull from 'lodash/isNull';

import { type TillTransactionType } from '@kwara/models/src';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import { topupRequestPath } from '../../../../lib/urls';
import { AppPermissions, permission } from '../../../../models';
import { ButtonMenu } from '../../../../components/ButtonMenu';
import OverviewList from '../../../../components/OverviewList';
import { PrintPDFButton } from '../../../../components/PrintPDFButton';
import { TransactionsPDF } from '../TillListTable/TransactionsPDF';
import { type ModalAction } from '../..';
import { TillListTable } from '../TillListTable';

type Props = {
  hasMore?: boolean,
  loading?: boolean,
  transactions: TillTransactionType[],
  errors: Object[],
  onLoadMoreData: () => void,
  totalNumResults: ?number,
  updateModal: ModalAction,
  isTillOpen: boolean
};

const AddButtonMenu = ({
  updateModal,
  isTillOpen
}): { updateModal: ModalAction, isTillOpen: boolean } => {
  const items = isTillOpen
    ? [
        permission.to(AppPermissions.AddDeposits) ? (
          <ButtonMenu.Item
            onClick={() => updateModal({ activeModal: 'deposit' })}
            key="deposit"
          >
            <Text id="TillList.addDeposit" />
          </ButtonMenu.Item>
        ) : null,
        permission.to(AppPermissions.AddWithdrawal) ? (
          <ButtonMenu.Item
            onClick={() => updateModal({ activeModal: 'withdrawal' })}
            key="withdrawal"
          >
            <Text id="TillList.addWithdrawal" />
          </ButtonMenu.Item>
        ) : null,
        permission.to(AppPermissions.AddRepayments) ? (
          <ButtonMenu.Item
            onClick={() => updateModal({ activeModal: 'repayment' })}
            key="repayment"
          >
            <Text id="TillList.addRepayment" />
          </ButtonMenu.Item>
        ) : null,
        permission.to(AppPermissions.CreateTopupRequests) ? (
          <ButtonMenu.Item to={topupRequestPath()} key="topup">
            <Text id="TillList.topup" />
          </ButtonMenu.Item>
        ) : null,
        permission.to(AppPermissions.CreateUtilityTransactions) ? (
          <ButtonMenu.Item
            onClick={() => updateModal({ activeModal: 'utilities' })}
            key="utilities"
          >
            <Text id="Teller transfer" />
          </ButtonMenu.Item>
        ) : null,
        <ButtonMenu.Item
          onClick={() => updateModal({ activeModal: 'giveMeCard' })}
          key="card"
        >
          ATM card request
        </ButtonMenu.Item>,
        <ButtonMenu.Item
          onClick={() => updateModal({ activeModal: 'giveMeCheque' })}
          key="card"
        >
          New cheque book request
        </ButtonMenu.Item>
      ]
    : [];

  return (
    <React.Fragment>
      <ButtonMenu
        disabled={items.every(isNull)}
        title={<Text id="TillList.add" />}
      >
        {items}
      </ButtonMenu>
    </React.Fragment>
  );
};

// WIP To Do: Make Button sizes consistent with other pages
// i.e. Members, Savings, Loans [ch5666]
const AddTillListButtonActions = ({
  updateModal,
  isTillOpen,
  transactions
}: {
  updateModal: ModalAction,
  isTillOpen: boolean,
  transactions: TillTransactionType
}) => {
  return [
    <PrintPDFButton
      key="pdfButton"
      renderPDF={props => (
        <TransactionsPDF transactions={transactions} {...props} />
      )}
    />,
    <Button key="csvButton" disabled size="medium">
      <Text id="TillList.csv" />
    </Button>,
    <AddButtonMenu
      key="buttonMenu"
      updateModal={updateModal}
      isTillOpen={isTillOpen}
    />
  ];
};

export const TillList = withRouter(
  ({
    hasMore = false,
    loading = false,
    transactions = [],
    errors,
    onLoadMoreData,
    totalNumResults,
    updateModal,
    isTillOpen
  }: Props) => (
    <OverviewList
      actions={AddTillListButtonActions({
        updateModal,
        isTillOpen,
        transactions
      })}
      headerId="TillList.heading"
      labelId={
        totalNumResults
          ? 'TillList.filteredCount'
          : 'TillList.filteredCountWithoutTotal'
      }
      items={transactions}
      table={
        <TillListTable
          hasMore={hasMore}
          transactions={transactions}
          onLoadMoreData={onLoadMoreData}
          loading={loading}
          errors={errors}
          isTillOpen={isTillOpen}
        />
      }
      totalNumResults={totalNumResults}
    />
  )
);
