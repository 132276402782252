// @flow

import Base from './Base';

const LoanMetric = Base.extend({
  static: {
    jsonapiType: 'loan_metrics'
  },
  attrs: {},
  methods: {}
});

export default (LoanMetric: typeof LoanMetric);
