// @flow

import React from 'react';
import cx from 'classnames';
import type { RouterHistory, LocationShape } from 'react-router-dom';

import join from 'lodash/fp/join';

import { ActionButton } from '@kwara/components/src/Button';
import { Text, FileSize } from '@kwara/components/src/Intl';
import {
  BatchUpload,
  type ModelErrors,
  createModelErrors
} from '@kwara/models/src';

import Wizard from '../../../components/Wizard';
import { type SubstepProps } from '..';
import ModelErrorBanner from '../../../components/ModelErrorBanner';

import style from '../index.module.scss';

const accept = join(', ', [
  ...BatchUpload.SupportedMimeTypes,
  ...BatchUpload.SupportedExtensions
]);
const Content = ({ StackChild, addData, data, formProps }: SubstepProps) => {
  const [errors, setErrors] = React.useState<ModelErrors>({});
  const onDownloadTemplate = () => {
    setErrors({});
    BatchUpload.getTemplate().catch(() => {
      setErrors(
        createModelErrors({
          base: 'UI_BATCH_TEMPLATE_DOWNLOAD_FAILURE'
        })
      );
    });
  };
  return (
    <StackChild>
      <ModelErrorBanner errors={errors} />
      <div className={cx('pa3 br2 mb4', style.Wrapper)}>
        <div className="black kw-weight-regular">
          <div className="grey-400 kw-text-regular">
            <FileSize value={6000} />
          </div>
          <span className="kw-text-medium">
            <Text id={`BatchUploadModal.excelTemplate`} />
          </span>
        </div>
        <div>
          <ActionButton
            onClick={onDownloadTemplate}
            type="download"
            aria-label="download template"
          />
        </div>
      </div>

      <label htmlFor="file" className={cx('grey-400 kw-text-regular')}>
        <Text id="BatchUploadModal.selectFileLabel" />
      </label>
      <div
        className={cx(style.Dropzone, 'br2 grey-400 kw-text-regular mb4', {
          [style.DropzoneDirty]: data.file
        })}
      >
        <input
          type="file"
          name="file"
          className={style.InputFile}
          accept={accept}
          onChange={async e => {
            const file = e.target.files[0];
            if (file) {
              addData({ file });
              formProps.form.submit();
            }
          }}
        />
        {data.file ? (
          <Text
            id="BatchUploadModal.uploadedFile"
            values={{ fileName: data.file.name }}
          />
        ) : (
          <Text id="BatchUploadModal.selectFileText" />
        )}
      </div>
    </StackChild>
  );
};

const config = {
  add: {
    titleId: 'BatchUploadModal.title',
    subtitleId: 'BatchUploadModal.subtitle',
    Component: Content,
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    validate: {
      file: {
        isRequired: () => true
      }
    }
  }
};

export const UploadAdd = ({
  history,
  location
}: {
  history: RouterHistory,
  location: LocationShape
}) => {
  const onComplete = ({ file }: { file: File }) =>
    BatchUpload.uploadTemplate(file);

  return (
    <Wizard
      analyticsId="UploadAdd"
      baseUrl="/uploads"
      currentStep="add"
      history={history}
      initialdata={{}}
      cancelReturnsTo="/uploads"
      onSubmit={onComplete}
      steps={config}
      startId="add"
      titleId="BatchUploadModal.headerTitle"
      location={location}
    />
  );
};
