// @flow

import { attr } from 'spraypaint';

import Base from './Base';

/*
  This is a bit of a hack in that a single Address
  record encodes both the postal and physical
  addresses so it shouldn't be used directly.

  Instead, use the helper methods on the Member
  record.
*/
const MemberAddress = Base.extend({
  static: {
    jsonapiType: 'member_addresses'
  },
  attrs: {
    physicalAddress: attr(),
    postalAddress: attr()
  }
});

export type MemberAddressType = MemberAddress;

export default (MemberAddress: typeof MemberAddress);
