//@flow
import * as React from 'react';
import classnames from 'classnames';

import Result from './Result';
import NonFinancialResult from './NonFinancialResult';
import { NoResults } from './NoResults';

import styles from './index.module.scss';

import { type MemberType } from '@kwara/models/src';

type SearchTerm = string;

export type Props = {
  collapsed: boolean,
  getItemProps: ({ item: MemberType }) => { [string]: any },
  highlightedIndex: number,
  results: MemberType[],
  searching?: boolean,
  resultType: 'financial' | 'nonFinancial',
  value: SearchTerm,
  isItemDisabled?: (item: MemberType) => boolean,
  showResultCount?: boolean
};

export default ({
  collapsed,
  getItemProps = () => ({}),
  highlightedIndex,
  results = [],
  searching,
  value,
  resultType,
  isItemDisabled = _i => false,
  showResultCount
}: Props) => {
  const Component = resultType === 'nonFinancial' ? NonFinancialResult : Result;
  const showNoMatchesFound =
    results.length === 0 && !searching && value && !showResultCount;

  return (
    <div
      className={classnames(
        styles.ResultList,
        collapsed ? styles.isCollapsed : styles.isNotCollapsed,
        results.length > 0 ? styles.hasResults : null
      )}
    >
      {showNoMatchesFound ? (
        <NoResults term={value} />
      ) : (
        <div className={styles.Results}>
          {results.map((member, index) => (
            <Component
              key={member.id}
              member={member}
              highlighted={highlightedIndex === index}
              disabled={isItemDisabled(member)}
              {...getItemProps({ item: member })}
            />
          ))}
        </div>
      )}
    </div>
  );
};
