import * as React from 'react';
import map from 'lodash/fp/map';

import { JournalEntry } from '@kwara/models/src';
import { formatIsoDate } from '@kwara/lib/src/dates';

import { financePath } from '../../lib/urls';
import Wizard from '../../components/Wizard';

import { steps } from './config';

const createJournalEntry = async ({
  date,
  credits: formCredits,
  debitAmount,
  ...rest
}) => {
  const valueDate = date ? { date: formatIsoDate(date) } : {};

  const credits = map(
    c => ({ ...c, credit_amount: Number(c.credit_amount) }),
    formCredits
  );

  const data = {
    debitAmount: Number(debitAmount),
    credits,
    ...valueDate,
    ...rest
  };

  const journalEntry = new JournalEntry(data);

  const didSave = await journalEntry.save();

  if (!didSave) {
    throw journalEntry.errors;
  }
};

export const JournalEntryAdd = ({ baseUrl, match }) => {
  return (
    <Wizard
      baseUrl={baseUrl}
      initialData={{
        credits: [{}]
      }}
      cancelReturnsTo={financePath({ baseExtension: 'journalEntries' })}
      currentStep={match.params.step}
      currentSubStep={
        match.params.subStep != null ? parseInt(match.params.subStep, 10) : null
      }
      onSubmit={createJournalEntry}
      steps={steps}
      startId="confirm"
      titleId="JournalEntryAdd.title"
    />
  );
};
