import * as React from 'react';
import getOr from 'lodash/fp/getOr';

import {
  allowedAttachments,
  UploadWidget,
  addAttachment,
  removeAttachment,
  isDirty
} from '@kwara/components/src/UploadWidget';

import { type SubStepComponentProps } from '../../../components/Wizard';

const fieldNames = allowedAttachments.typesByContext(['loans']);

const getMember = getOr({}, 'member');

export const Documents = ({
  StackChild,
  data,
  onChange
}: SubStepComponentProps<>) => (
  <StackChild>
    {fieldNames.map(f => (
      <UploadWidget
        key={f.name}
        onChange={v => onChange({ member: addAttachment(getMember(data), v) })}
        onRemove={fileName =>
          onChange({
            member: removeAttachment(getMember(data), fileName)
          })
        }
        member={getMember(data)}
        fileName={f.name}
        checked={isDirty(getMember(data).attachments, f.name)}
        textId={`DocumentUploads.${f.label}.label`}
      />
    ))}
  </StackChild>
);
