// @flow

import { attr, belongsTo } from 'spraypaint';

import Base, { type BaseModel } from './Base';
import { User, type UserT } from './User';

export const Role = Base.extend({
  static: {
    jsonapiType: 'roles'
  },
  attrs: {
    name: attr(),
    permissions: attr(),
    user: belongsTo({ type: User })
  },
  methods: {
    hasLoanApprovePermission() {
      return (
        this.permissions.includes('APPROVE_LOANS') ||
        this.permissions.includes('REJECT_LOANS')
      );
    }
  }
});

export interface RoleT extends BaseModel<RoleT> {
  name: string;
  permissions: string[];
  user: UserT;
  hasLoanApprovePermission: () => boolean;
}
