import { Configure } from './components/Configure';
import { Disbursement } from './components/Disbursement';
import { Documents } from './components/Documents';
import { Loans } from './components/Loans';
import { MemberReview } from './components/MemberReview';
import { Repayment } from './components/Repayment';
import { Security } from './components/Security';
import Member from './components/Member';
import Product from './components/Product';
import Summary from './components/Summary';

export const steps = () => ({
  member: {
    titleId: 'LoanAdd.Member.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'product'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Member.title',
        subtitleId: 'LoanAdd.Member.subtitle',
        Component: Member,
        hideActions: true
      },
      {
        titleId: 'LoanAdd.MemberReview.title',
        Component: MemberReview,
        validate: MemberReview.validate
      }
    ]
  },
  product: {
    titleId: 'LoanAdd.Product.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'configure'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Product.title',
        Component: Product,
        hideActions: { next: true }
      }
    ]
  },
  configure: {
    titleId: 'LoanAdd.Configure.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'documents'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Configure.title',
        Component: Configure,
        validate: Configure.validate
      },
      {
        titleId: 'LoanAdd.Loans.title',
        subtitleId: 'LoanAdd.Loans.subtitle',
        Component: Loans,
        validate: Loans.validate
      },
      {
        titleId: 'LoanAdd.Configure.RepaymentMode.step.title',
        Component: Repayment,
        validate: Repayment.validateConfig.loanAdd
      },
      {
        titleId: 'LoanAdd.Disbursement.title',
        subtitleId: 'LoanAdd.Disbursement.subtitle',
        Component: Disbursement,
        validate: Disbursement.validate
      },
      {
        titleId: 'LoanAdd.Security.title',
        subtitleId: 'LoanAdd.Security.subtitle',
        Component: Security,
        validate: Security.validate
      }
    ]
  },
  documents: {
    titleId: 'LoanAdd.Documents.shortTitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'next',
        destination: 'summary'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Documents.title',
        subtitleId: 'LoanAdd.Documents.subtitle',
        Component: Documents
      }
    ]
  },
  summary: {
    titleId: 'LoanAdd.Summary.shortTitle',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'complete'
      }
    ],
    children: [
      {
        titleId: 'LoanAdd.Summary.title',
        subtitleId: 'LoanAdd.Summary.subtitle',
        Component: Summary
      }
    ]
  }
});
