//@flow

import * as React from 'react';

import { type SubStepComponentProps } from '../../../components/Wizard';

const Employment = ({
  StackChild,
  SelectField,
  TextField
}: SubStepComponentProps<>) => (
  <StackChild>
    <div className="flex">
      <div className="flex-auto">
        <TextField
          name="profession"
          errorBaseId="AddMember.Employment.Profession"
          labelId="AddMember.Employment.Profession.label"
        />

        <SelectField
          name="employmentStatus"
          errorBaseId="AddMember.Employment.EmploymentStatus"
          labelId="AddMember.Employment.EmploymentStatus.label"
        >
          <SelectField.Option
            translationId="AddMember.Employment.EmploymentStatus.none"
            value=""
          />
          <SelectField.Option
            translationId="AddMember.Employment.EmploymentStatus.employed"
            value="employed"
          />
          <SelectField.Option
            translationId="AddMember.Employment.EmploymentStatus.self_employed"
            value="self_employed"
          />
        </SelectField>

        <SelectField
          name="currentlyWorking"
          errorBaseId="AddMember.Employment.CurrentlyWorking"
          labelId="AddMember.Employment.CurrentlyWorking.label"
        >
          <SelectField.Option
            translationId="AddMember.Employment.CurrentlyWorking.NONE"
            value=""
          />
          <SelectField.Option
            translationId="AddMember.Employment.CurrentlyWorking.YES"
            value="true"
          />
          <SelectField.Option
            translationId="AddMember.Employment.CurrentlyWorking.NO"
            value="false"
          />
        </SelectField>

        <TextField
          name="employer"
          errorBaseId="AddMember.Employment.Employer"
          labelId="AddMember.Employment.Employer.label"
        />
      </div>
    </div>
  </StackChild>
);

Employment.validate = {};

export default Employment;
