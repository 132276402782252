//@flow
import format from 'date-fns/format';
import differenceInDaysExternal from 'date-fns/difference_in_days';
import distanceInWordsExternal from 'date-fns/distance_in_words_strict';
import getDateExternal from 'date-fns/get_date';
import getMonthExternal from 'date-fns/get_month';
import getYearExternal from 'date-fns/get_year';
import isAfterExternal from 'date-fns/is_after';
import addMonthsExternal from 'date-fns/add_months';
import subYearsExternal from 'date-fns/sub_years';
import isFutureExternal from 'date-fns/is_future';
import subDaysExternal from 'date-fns/sub_days';
import startOfTomorrow from 'date-fns/start_of_tomorrow';
import { DateTime, Duration, type DurationObject } from 'luxon';

const HUMAN_FORMAT = 'DD/MM/YYYY';
const ISO_FORMAT = 'YYYY-MM-DD';
export const TIME = 'HH:mm:ss';

type MaybeDate = Date | string | number;

const isValidDateObject = (date: Date) => !isNaN(date.valueOf());

const isValid = (maybe: MaybeDate) => {
  let date = maybe;

  if (typeof date === 'number' || typeof date === 'string') {
    date = new Date(date);
  }

  if (date instanceof Date && isValidDateObject(date)) {
    return true;
  }

  return false;
};

export const differenceInDays = (now: MaybeDate, past: MaybeDate) =>
  differenceInDaysExternal(past, now) || null;

export const distanceInWords = (now: MaybeDate, past: MaybeDate) => {
  if (isValid(now) && isValid(past)) {
    return distanceInWordsExternal(now, past);
  }

  return null;
};

export const formatIsoDate = (date: Date): string => {
  if (date instanceof Date && isValidDateObject(date)) {
    return format(date, ISO_FORMAT);
  } else {
    throw new Error(`Cannot format date: ${String(date)}`);
  }
};

export const formatHumanDate = (
  maybeDate: MaybeDate,
  pattern: string = HUMAN_FORMAT
): ?string => {
  const date = parse(maybeDate);

  if (date != null) {
    return format(date, pattern);
  }

  return null;
};

export const formatHumanTime = (maybeDate: MaybeDate): ?string =>
  formatHumanDate(maybeDate, TIME);

/*
  Tries to parse some form of date into a JS Date, returning
  null if it's invalid

  Tries: number (assumes ms), string (assumes ISO) and also
  aacepts instance of JS Date
*/
export const parse = (maybeDate: MaybeDate): ?Date => {
  if (maybeDate == null) {
    return null;
  }

  let date = maybeDate;

  if (typeof date === 'string') {
    date = DateTime.fromISO(date);
  }

  if (typeof date === 'number') {
    date = DateTime.fromMillis(date);
  }

  if (date instanceof Date) {
    date = DateTime.fromJSDate(date);
  }

  if (date instanceof DateTime) {
    return date.isValid ? date.toJSDate() : null;
  }

  return null;
};

export const getDayOfMonth = getDateExternal;
export const getMonth = getMonthExternal;
export const getYear = (maybeDate: MaybeDate): ?number => {
  let date = maybeDate;

  if (typeof date === 'string') {
    date = new Date(date);
  }

  if (date instanceof Date && isValidDateObject(date)) {
    return getYearExternal(date);
  }

  return null;
};
export const isAfter = isAfterExternal;
export const subYears = subYearsExternal;
export const subDays = subDaysExternal;
export const isFuture = isFutureExternal;
export const tomorrow = startOfTomorrow;
export const addMonths = addMonthsExternal;

export const toDuration = (obj: DurationObject): ?string => {
  let res;
  try {
    res = Duration.fromObject(obj).toString();
  } catch (_) {
    res = undefined;
  }
  return res;
};

export const parseDuration = (duration: string) =>
  Duration.fromISO(duration).toObject();

export function getCurrentYear(): number {
  return new Date().getFullYear();
}
export function getCurrentDate() {
  return new Date();
}

export function getCurrentDateFormatted() {
  return formatHumanDate(getCurrentDate());
}
