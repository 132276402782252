// @flow

import React from 'react';
import get from 'lodash/fp/get';

import { DetailWrapper, Detail, type UserT } from '..';

export const MambuRole = ({ user }: { user: UserT }) => {
  return (
    <DetailWrapper>
      <Detail
        titleId="Settings.Personal.MambuRole.title"
        value={get('role.name', user)}
      />
    </DetailWrapper>
  );
};
