//@flow
import * as React from 'react';

import size from 'lodash/fp/size';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';
import pipe from 'lodash/fp/pipe';

import { type LoanType } from '@kwara/models/src';

import { type SubStepComponentProps } from '../../../../components/Wizard';

import { LoanBanner } from './LoanBanner';
import { LoanTermsFields } from './LoanTermsFields';
import { LoanPurposeFields } from './LoanPurposeFields';
import { PrincipalAmountField } from './PrincipalAmountField';

import styles from './index.module.css';
import { type LoanFormData } from '../..';

type ConfigureProps = SubStepComponentProps<LoanFormData>;

export function matchingLoans(data: LoanFormData = {}) {
  const { product = {}, loans = [] } = data;
  const { id } = product;

  const canRefinance = (loan: LoanType) => loan.canRefinance();
  const sameProduct = (loan: LoanType) => get('product.id', loan) === id;

  return pipe(
    filter(canRefinance),
    filter(sameProduct)
  )(loans);
}

export function hasExactLoan(data: LoanFormData) {
  return size(matchingLoans(data)) > 0;
}

export const Configure = (props: ConfigureProps) => {
  const { StackChild, data } = props;
  const { product } = data;

  return (
    <StackChild>
      <LoanBanner {...props} />

      {hasExactLoan(data) ? null : (
        <div className={`${styles.row} pb4`}>
          <h3>{product.name}</h3>
          <PrincipalAmountField {...props} />
          <LoanPurposeFields {...props} />
          <LoanTermsFields {...props} />
        </div>
      )}
    </StackChild>
  );
};

Configure.validate = {
  ...PrincipalAmountField.validate,
  ...LoanTermsFields.validate,
  ...LoanPurposeFields.validate,
  ...LoanBanner.validate
};
