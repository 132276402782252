//@flow
import * as React from 'react';

import { type SubStepComponentProps } from '../../../../components/Wizard';

import { Text } from '@kwara/components/src/Intl';
import { positiveNumber } from '../../../../lib/recordValidations';
import { type WizardData } from '../..';

const shareCapitalFieldName = 'shareCapitalAmount';

const Deposits = ({
  StackChild,
  TextField,
  data
}: SubStepComponentProps<WizardData>) => {
  return (
    <StackChild>
      {data.shareCapital !== true ? (
        <p>
          <Text id="MemberApprove.Shares.notSelected" />
        </p>
      ) : (
        <React.Fragment>
          <h3 className="kw-super-large kw-weight-bold">
            <Text id="MemberApprove.Shares.amountTitle" />
          </h3>
          <TextField
            errorBaseId="MemberApprove.Deposits.Amount"
            leftGlyph="Currency.orgCurrency"
            name={shareCapitalFieldName}
            labelId="MemberApprove.Shares.amountLabel"
          />
          <TextField
            errorBaseId="MemberApprove.Deposits.Amount"
            labelId="MemberApprove.Shares.referenceLabel"
            name="sharesReference"
          />
        </React.Fragment>
      )}
    </StackChild>
  );
};

Deposits.validate = {
  [shareCapitalFieldName]: {
    ...positiveNumber
  }
};

export default Deposits;
