// @flow

import * as React from 'react';
import toUpper from 'lodash/fp/toUpper';

import Tag, { Status } from '@kwara/components/src/Tag';
import type { LoanState, MemberState, SavingState } from '@kwara/models/src';

const InvitationStates = {
  PENDING_REDEMPTION: Status.Warning,
  REDEEMED: Status.Success,
  REVOKED: Status.Critical,
  EXPIRED: Status.Critical
};

const SavingStates = {
  ACTIVE_IN_ARREARS: Status.Warning,
  ACTIVE: Status.Success,
  APPROVED: Status.Success,
  CLOSED_REJECTED: Status.Neutral,
  CLOSED_WITHDRAWN: Status.Critical,
  CLOSED_WRITTEN_OFF: Status.Neutral,
  CLOSED: Status.Neutral,
  DORMANT: Status.Neutral,
  LOCKED: Status.Critical,
  MATURED: Status.Neutral,
  WITHDRAWN: Status.Neutral
};

const LoanStates = {
  ACTIVE_IN_ARREARS: Status.Critical,
  ACTIVE_PARTIALLY_DISBURSED: Status.Success,
  ACTIVE: Status.Success,
  APPROVED: Status.Success,
  CLOSED_REFINANCED: Status.Critical,
  CLOSED_REJECTED: Status.Critical,
  CLOSED_RESCHEDULED: Status.Critical,
  CLOSED_WITHDRAWN: Status.Critical,
  CLOSED_WRITTEN_OFF: Status.Critical,
  CLOSED_REPAID: Status.Success,
  CLOSED: Status.Neutral,
  PARTIAL_APPLICATION: Status.Neutral
};

const RepaymentStates = {
  LATE: Status.Critical,
  PENDING: Status.Neutral,
  PARTIALLY_PAID: Status.Warning,
  PAID: Status.Success,
  RESCHEDULED: Status.Neutral,
  GRACE: Status.Neutral
};

const BatchTransactionImportStates = {
  REVIEWED: Status.Success,
  IN_REVIEW: Status.Neutral
};

export const statusToState = {
  ...BatchTransactionImportStates,
  ...InvitationStates,
  ...SavingStates,
  ...LoanStates,
  ...RepaymentStates,
  APPLICANT: Status.Neutral,
  BLACKLISTED: Status.Critical,
  EXITED: Status.Neutral,
  GUARANTOR: Status.Neutral,
  INACTIVE: Status.Neutral,
  PARTIALLY_PAID: Status.Warning,
  PENDING_APPROVAL: Status.Warning,
  PENDING_REVIEW: Status.Warning,
  REJECTED: Status.Critical,
  IMPORTED: Status.Success,
  IMPORTING: Status.Neutral,
  FAILED: Status.Critical,
  UPLOADED: Status.Success,
  UPLOADING: Status.Neutral,
  SENT: Status.Success,
  SELECTED: Status.Success
};

type Props = {
  state: LoanState | MemberState | SavingState | 'GUARANTOR' | 'APPLICANT',
  size?: string
};

export const StatusText = ({ state }: Props) => (
  <Tag
    background={false}
    id={`StatusTag.${state}`}
    status={statusToState[state] || Status.Neutral}
  />
);

export default function StatusTag({ state = 'PENDING', size }: Props) {
  const normalisedState = toUpper(state);
  return (
    <Tag
      id={`StatusTag.${normalisedState}`}
      size={size}
      status={statusToState[normalisedState] || Status.Neutral}
    />
  );
}
