// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import get from 'lodash/fp/get';
import pick from 'lodash/fp/pick';
import map from 'lodash/fp/map';
import round from 'lodash/round';
import result from 'lodash/fp/result';

import {
  Loan,
  type LoanType,
  type GuaranteeType,
  type CollateralT,
  type MemberType,
  type LoanProductType,
  type PeriodUnitsT,
  type TransactionChannelT
} from '@kwara/models/src';
import { Loadable } from '@kwara/components/src/Loadable';
import { add } from '@kwara/lib/src/currency';

import { type SubStepComponentProps } from '../../components/Wizard';
import Wizard from '../../components/Wizard';
import { loanPath } from '../../lib/urls';
import { useLoan } from '../../models/request';
import { steps } from './config';
import type { WizardPageProps } from '..';

type Props = WizardPageProps<{ loanId: string }>;

export type RefinanceFormData = {
  loan: LoanType,
  guarantors: GuaranteeType[],
  collaterals: CollateralT[],
  member: MemberType,
  product: LoanProductType,
  amount: string,
  loanDuration: string,
  repaymentPeriod: string,
  repaymentPeriodUnit: PeriodUnitsT,
  purpose: string,
  specification: string,
  repaymentDetails: {
    directDebitBankBranch?: string,
    directDebitBank?: string,
    directDebitBankAccount?: string,
    repaymentMode: TransactionChannelT
  },
  disbursementMode: TransactionChannelT
};

export type ComponentPropsLoanRefinance = SubStepComponentProps<RefinanceFormData>;

const ONE_CENT = 0.01;

export function applyRefinancingFee(original: number) {
  // TO DO: This 1.05 should not be hardcoded here.
  // It should be based on the bridging loan account's
  // interest and/or any fees that are charged during refinancing.

  return round(original * 1.05, 2);
}

export function totalBalance(loan: LoanType) {
  return Number(get('totalBalance', loan));
}

const refinanceLoan = async (data): Promise<?boolean> => {
  const {
    amount,
    loanDuration,
    loanInstallments,
    repaymentPeriod,
    repaymentPeriodUnit,
    loanId,
    guarantors: guarantorsRaw,
    collaterals
  } = data;

  // TO DO: The backend does not accept
  // the amount for a guarantor as a string.
  // Ideally it should.
  const guarantors = map(g => {
    g.amount = Number(g.amount);
    return g;
  }, guarantorsRaw);

  const refinanceData = {
    id: loanId,
    amount,
    repaymentPeriod,
    repaymentPeriodUnit,
    loanDuration,
    loanInstallments,
    guarantors,
    collaterals,
    ...pick(
      [
        'purpose',
        'specification',
        'disbursementMode',
        'disbursementBankDetails',
        'repaymentDetails'
      ],
      data
    )
  };

  const RefinancedLoan = Loan.refinance(loanId);
  const loan = new RefinancedLoan(refinanceData);

  const didSave = await loan.save({ with: ['collaterals', 'guarantors'] });

  if (!didSave) {
    throw loan.errors;
  }
};

const includes = [
  'member',
  'product',
  'guarantees.member',
  // 'guarantors.member',
  'member.attachments',
  'collaterals'
];

export const LoanRefinance = (props: Props) => {
  const { baseUrl, match, history } = props;

  const response = useLoan(props.match.params.loanId, includes);

  return (
    <Loadable {...response}>
      {loan => {
        const { product } = loan;
        const {
          unit: repaymentPeriodUnit,
          value: repaymentPeriod
        } = product.toRepaymentFrequencyUI();

        return loan.canRefinance() ? (
          <Wizard
            analyticsId="LoanRefinance"
            baseUrl={baseUrl}
            history={history}
            initialData={{
              loan,
              product: loan.product,
              member: loan.member,
              firstName: get('member.firstName', loan),
              fullName: result('member.fullName', loan),
              loanId: loan.id,
              amount: add(applyRefinancingFee(totalBalance(loan)), ONE_CENT),
              repaymentPeriod,
              repaymentPeriodUnit,
              loanDuration: loan.loanDuration,
              purpose: loan.purpose,
              specification: loan.specification,
              disbursementMode: loan.disbursementMode,
              repaymentDetails: {
                repayment_mode: loan.repaymentDetails.repayment_mode
              }
            }}
            cancelReturnsTo={loanPath({ id: loan.id })}
            currentStep={match.params.step}
            currentSubStep={
              match.params.subStep != null
                ? parseInt(match.params.subStep, 10)
                : null
            }
            onSubmit={refinanceLoan}
            steps={steps}
            startId="configure"
            titleId="LoanRefinance.shortTitle"
          />
        ) : (
            <Redirect to={loanPath({ id: loan.id })} />
          )
      }}
    </Loadable>
  );
};
