// @flow

import { attr } from 'spraypaint';

import cameliseObjectKeys from '../lib/cameliseObjectKeys';
import { toDurationObjectUI, type UIDurationObjectT } from './util';
import Base, { type BaseModel } from './Base';

export const ProductTypes = Object.freeze({
  FIXED_TERM_LOAN: 'FIXED_TERM_LOAN',
  DYNAMIC_TERM_LOAN: 'DYNAMIC_TERM_LOAN',
  INTEREST_FREE_LOAN: 'INTEREST_FREE_LOAN',
  TRANCHED_LOAN: 'TRANCHED_LOAN',
  REVOLVING_CREDIT: 'REVOLVING_CREDIT'
});
type ProductTypesT = $Values<typeof ProductTypes>;

export const ChargeFrequencies = Object.freeze({
  ANNUALIZED: 'ANNUALIZED',
  EVERY_MONTH: 'EVERY_MONTH',
  EVERY_FOUR_WEEKS: 'EVERY_FOUR_WEEKS',
  EVERY_WEEK: 'EVERY_WEEK',
  EVERY_DAY: 'EVERY_DAY'
});
type ChargeFrequencyT = $Values<typeof ChargeFrequencies>;

export const InterestApplicationMethods = Object.freeze({
  AFTER_DISBURSEMENT: 'AFTER_DISBURSEMENT',
  REPAYMENT_DUE_DATE: 'REPAYMENT_DUE_DATE'
});

type InterestApplicationMethodT = $Values<typeof InterestApplicationMethods>;

export const InterestCalculationMethods = Object.freeze({
  DECLINING_BALANCE_DISCOUNTED: 'DECLINING_BALANCE_DISCOUNTED',
  DECLINING_BALANCE: 'DECLINING_BALANCE',
  FLAT: 'FLAT'
});

type InterestCalculationMethodT = $Values<typeof InterestCalculationMethods>;

export const PeriodUnits = Object.freeze({
  MONTHS: 'MONTHS',
  WEEKS: 'WEEKS',
  DAYS: 'DAYS'
});

export type PeriodUnitsT = $Values<typeof PeriodUnits>;

export const PaymentIntervalMethod = Object.freeze({
  INTERVAL: 'INTERVAL'
});

type PaymentIntervalMethodT = $Values<typeof PaymentIntervalMethod>;

type AmountSettingsT = {
  default: ?number,
  maximum: ?number,
  minimum: ?number
};

// From the API
type InterestObjectT = {
  calculation_method: InterestCalculationMethodT,
  application_method: InterestApplicationMethodT,
  interest_charge_frequency: ChargeFrequencyT,
  default_percentage: number
};

type DurationT = string;

type SecuritySettingsT = {
  collaterals_enabled: boolean,
  guarantors_enabled: boolean
};

type SecuritySettingsCamelT = {
  collateralsEnabled: boolean,
  guarantorsEnabled: boolean
};

export const States = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  EXITED: 'EXITED',
  BLACKLISTED: 'BLACKLISTED'
};

export type State = $Values<typeof States>;

const LoanProduct = Base.extend({
  static: {
    jsonapiType: 'loan_products'
  },
  attrs: {
    name: attr(),
    type: attr(),
    security: attr(),
    amount: attr(),
    repaymentFrequency: attr(),
    interestRate: attr(),
    defaultDuration: attr(),
    maxDuration: attr(),
    paymentIntervalMethod: attr(),
    fees: attr(),

    // Create
    defaultRepaymentPeriod: attr(),
    maximumRepaymentInstallments: attr(),
    repaymentPeriodUnit: attr(),
    feesSettings: attr()
  },
  methods: {
    hasFixedRepaymentFrequency() {
      return !!this.repaymentFrequency;
    },
    securityObject() {
      return cameliseObjectKeys(this.security);
    },
    hasCollateralsEnabled() {
      return !!this.security.collaterals_enabled;
    },
    hasGuarantorsEnabled() {
      return !!this.security.guarantors_enabled;
    },
    maxDurationUI() {
      return toDurationObjectUI(this.maxDuration);
    },
    toRepaymentFrequencyUI() {
      return toDurationObjectUI(this.repaymentFrequency);
    }
  }
});

export const FeeTriggersMap = {
  DISBURSEMENT: 'DISBURSEMENT',
  CAPITALIZED_DISBURSEMENT: 'CAPITALIZED_DISBURSEMENT',
  UPFRONT_DISBURSEMENT: 'UPFRONT_DISBURSEMENT',
  LATE_REPAYMENT: 'LATE_REPAYMENT',
  MONTHLY_FEE: 'MONTHLY_FEE',
  PAYMENT_DUE: 'PAYMENT_DUE',
  PAYMENT_DUE_APPLIED_ON_DUE_DATES: 'PAYMENT_DUE_APPLIED_ON_DUE_DATES',
  MANUAL: 'MANUAL'
};

export interface FeeType {
  amount: ?string;
  amountCalculationMethod: 'FLAT' | 'LOAN_AMOUNT_PERCENTAGE';
  feeApplication: 'REQUIRED' | 'OPTIONAL';
  id: string;
  name: string;
  percentageAmount: ?string;
  trigger:
    | 'DISBURSEMENT'
    | 'CAPITALIZED_DISBURSEMENT'
    | 'UPFRONT_DISBURSEMENT'
    | 'LATE_REPAYMENT'
    | 'MONTHLY_FEE'
    | 'PAYMENT_DUE'
    | 'PAYMENT_DUE_APPLIED_ON_DUE_DATES'
    | 'MANUAL';
}

export interface LoanProductType extends BaseModel<LoanProductType> {
  allowArbitraryFees: boolean;
  amount: AmountSettingsT;
  defaultDuration: ?DurationT;
  fees: FeeType[];
  hasCollateralsEnabled: () => boolean;
  hasFixedRepaymentFrequency: () => boolean;
  hasGuarantorsEnabled: () => boolean;
  id: string;
  interestRate: InterestObjectT;
  maxDuration: ?DurationT;
  name: string;
  paymentIntervalMethod: PaymentIntervalMethodT;
  repaymentFrequency: ?DurationT;
  security: SecuritySettingsT;
  securityObject: () => SecuritySettingsCamelT;
  toRepaymentFrequencyUI: () => UIDurationObjectT;
  type: ProductTypesT;
}

export default LoanProduct;
