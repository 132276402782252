// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import get from 'lodash/fp/get';
import pick from 'lodash/fp/pick';
import forEach from 'lodash/forEach';
import map from 'lodash/fp/map';
import _includes from 'lodash/fp/includes';
import filter from 'lodash/fp/filter';
import pipe from 'lodash/fp/pipe';
import isEqual from 'lodash/fp/isEqual';

import { formatIsoDate } from '@kwara/lib/src/dates';
import { type LoanType, type ModelErrors } from '@kwara/models/src';
import { fields as loanFields } from '@kwara/models/src/models/Loan';
import { FeeTriggersMap } from '@kwara/models/src/models/LoanProduct';
import createValidator from '@kwara/lib/src/validator';
import { Loadable } from '@kwara/components/src/Loadable';
import { Text } from '@kwara/components/src/Intl';

import { Panel } from '../../components/ActionModal';
import { Fees } from './components';
import LoanProductPanel from './components/LoanProductPanel';
import {
  PaymentForm,
  type PaymentData,
  validateConfig
} from './components/PaymentForm';
import Payment from '../../components/Payment';
import { useLoan } from '../../models/request';
import { loanPath } from '../../lib/urls';
import { Repayment } from '../LoanDetail/components/Repayment';
import { type LoanPageProps } from '..';

type FormData = PaymentData & { notes?: string };

const {
  DISBURSEMENT,
  CAPITALIZED_DISBURSEMENT,
  UPFRONT_DISBURSEMENT
} = FeeTriggersMap;

// We want to prefill the form with all the disbursal fees that are marked as required
const getRequiredFees = (loan: LoanType) => {
  const byTrigger = triggers => fee => _includes(get('trigger', fee), triggers);
  const onlyRequired = fee => isEqual(get('feeApplication', fee), 'REQUIRED');

  return pipe(
    get('product.fees'),
    filter(
      byTrigger([DISBURSEMENT, CAPITALIZED_DISBURSEMENT, UPFRONT_DISBURSEMENT])
    ),
    filter(onlyRequired),
    map(pick(['id']))
  )(loan);
};

// Makes sure the bank details that were saved during loan creation are the
// default ones in this form.
function prefillForm(loan: LoanType) {
  return {
    loan,
    method: get(loanFields.disbursement.mode, loan),
    bankName: get(loanFields.disbursement.bank, loan),
    bankBranch: get(loanFields.disbursement.branch, loan),
    accountNumber: get(loanFields.disbursement.account, loan),
    fees: getRequiredFees(loan)
  };
}

type States = 'inProgress' | 'success' | 'done' | 'error';
type MaybeErrors = ?ModelErrors;

const includes = [
  'guarantors',
  'member',
  'product',
  'loan_application.pay_off_loans.product'
];
export const LoanDisburse = (props: LoanPageProps) => {
  const { loanId = props.match.params.loanId } = props;
  const r = useLoan(loanId, includes);

  const { data: loan } = r;
  const [state, setState] = React.useState<States>('inProgress');
  const [isProcessing, setProcessing] = React.useState(false);
  const [errors, setErrors] = React.useState<MaybeErrors>();
  const onDisburse = async (data: FormData) => {
    setProcessing(true);

    const { firstRepaymentDate, method } = data;

    const info = pick(
      [
        'reference',
        'bankName',
        'bankBranch',
        'chequeNumber',
        'drawer',
        'accountNumber',
        'notes',
        'signedRepaymentForm',
        'signedOfferLetter',
        'accountId',
        'bankGlId',
        'fees'
      ],
      data
    );

    const didDisburse = await loan.disburse({
      ...info,
      firstRepaymentDate:
        firstRepaymentDate && formatIsoDate(firstRepaymentDate),
      paymentMethod: method
    });

    setProcessing(false);
    if (didDisburse) {
      setState('success');
    } else {
      setState('error');
      setErrors(loan.errors);
    }
  };

  const onClose = () => setState('done');

  if (state === 'done') {
    return <Redirect to={loanPath({ id: loan.id })} />;
  }

  return (
    <Loadable {...r}>
      <Form
        onSubmit={onDisburse}
        initialValues={prefillForm(loan)}
        validate={createValidator(validateConfig)}
        mutators={arrayMutators}
        render={(formProps: any) => {
          const { invalid, values, handleSubmit, form } = formProps;

          const addData = async data => {
            form.batch(function() {
              forEach(data, function(value, name) {
                form.change(name, value);
              });
            });
          };

          return (
            <Payment
              titleId="LoanDisburse.title"
              invalid={invalid}
              isProcessing={isProcessing}
              confirmSubtitle={
                <Text
                  id="LoanDisburse.confirmSubtitle"
                  values={{ firstName: get('member.firstName', loan) }}
                />
              }
              errors={errors}
              member={loan.member}
              onCancel={onClose}
              onConfirm={state !== 'success' ? handleSubmit : onClose}
              success={state === 'success'}
              type={Payment.Type.disbursement}
            >
              <LoanProductPanel product={loan.product} loan={loan} />
              <Fees loan={loan} />
              <PaymentForm formValues={values} addData={addData} />
              <Panel>
                <Repayment loan={loan} readOnly />
              </Panel>
            </Payment>
          );
        }}
      />
    </Loadable>
  );
};
