import * as React from 'react';
import cx from 'classnames';

import { Text } from '@kwara/components/src/Intl';
import { Small } from '@kwara/components/src/text';
import { ActionButton } from '@kwara/components/src/Button';
import { Link } from '@kwara/components/src/Link';

import { Stack } from '../../../components/Stack';

import StartNewImg from '../assets/StartNew.png';
import ImportImg from '../assets/Import.png';

import style from './index.module.scss';

export function GetStarted() {
  return (
    <Stack.Child>
      <Link to="/welcome/wizard" underline={false}>
        <div
          className={cx(
            style.button,
            'relative ba br3 bw1 b--light-gray h3 dim pa3 mb3 flex justify-between'
          )}
        >
          <div className="dib v-mid mt1">
            <img
              src={StartNewImg}
              className={style.GetStartedImg}
              alt="Start New"
            />
          </div>
          <div className="dib v-mid mt1">
            <Text id="Onboarding.GetStarted.StartNew.title" />
            <br />
            <Small translationId="Onboarding.GetStarted.StartNew.subtitle" />
          </div>
          <div>
            <ActionButton type="go" j onClick={() => 'Clicked'} />
          </div>
        </div>
      </Link>

      <div
        className={cx(
          style.button,
          'relative ba br3 bw1 b--light-gray h3 dim pa3 mb3 flex justify-between'
        )}
        style={{
          opacity: 0.5,
          cursor: 'not-allowed'
        }}
      >
        <div className="dib v-mid mt1">
          <img src={ImportImg} className={style.GetStartedImg} alt="Import" />
        </div>
        <div className="dib v-mid mt1">
          <Text id="Onboarding.GetStarted.Import.title" />
          <br />
          <Small translationId="Onboarding.GetStarted.Import.subtitle" />
        </div>
        <div>
          <ActionButton type="go" onClick={() => 'Clicked'} />
        </div>
      </div>
    </Stack.Child>
  );
}
