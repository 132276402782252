// @flow

import * as React from 'react';
import reject from 'lodash/fp/reject';
import split from 'lodash/fp/split';

import { isEmpty } from '@kwara/lib/src/lodash';

import { loanPath } from '../../../lib/urls';

import { type LoanTransactionType, type MemberType } from '@kwara/models/src';
import Banner from '@kwara/components/src/Banner';
import { Date, Text, Time } from '@kwara/components/src/Intl';

import { Cell, Heading, Row } from '../../../components/Table';
import TransactionsTable from '../../../components/TransactionsTable';
import { Currency } from '../../../components/Currency';
import LoanAccountCard from './LoanAccountCard';
import { AccountList } from './AccountList';
import DetailSubsection from '../../../components/DetailSubsection';

import styles from './Loans.module.css';

function by(transaction) {
  const { createdBy } = transaction;
  const left = split('(', createdBy)[1];
  return split(')', left)[0];
}

const renderRow = (transaction: LoanTransactionType) => (
  <Row key={transaction.id}>
    <Cell>
      <div className="grey-300">{transaction.id}</div>
      <div>
        <Date value={transaction.enteredAt} />
      </div>
      <div>
        <Time value={transaction.enteredAt} />
      </div>
    </Cell>
    <Cell>
      <div className="grey-300">{transaction.account.id}</div>
      <div>{transaction.account.product.name}</div>
    </Cell>
    <Cell>
      <div className="grey-300">{transaction.reference}</div>
      <span>{transaction.paymentMethod}</span>{' '}
      <Text id={`TransactionType.${transaction.type}`} />
      {transaction.notes && <div className="grey-300">{transaction.notes}</div>}
      <div className="grey-300">
        {transaction.createdBy ? ` by ${by(transaction)}` : ''}
      </div>
    </Cell>
    <Cell align="right">
      <Currency value={transaction.amount} />
    </Cell>
    <Cell align="right">
      <Currency value={transaction.balance} />
    </Cell>
    <Cell />
  </Row>
);

export const Loans = ({
  member,
  showClosedLoans
}: {
  member: MemberType,
  showClosedLoans: boolean
}) => {
  const accounts = showClosedLoans
    ? member.loans
    : reject(loan => loan.isClosed(), member.loans);

  const guaranteedAccounts = showClosedLoans
    ? member.guaranteedLoans
    : reject(loan => loan.isClosed(), member.guaranteedLoans);

  const transactions = accounts
    .map(loan =>
      loan.transactions.map(transaction => {
        transaction.account = loan;
        return transaction;
      })
    )
    .flatten();

  const heading = (
    <Row>
      <Heading translationId="TransactionsTable.date" />
      <Heading
        className={styles.AccountColumn}
        translationId="TransactionsTable.loan"
      />
      <Heading className="w-25" translationId="TransactionsTable.type" />
      <Heading align="right" translationId="TransactionsTable.amount" />
      <Heading align="right" translationId="TransactionsTable.balance" />
      <Heading iconSpacer />
    </Row>
  );

  return (
    <>
      <Banner
        text={member.firstName + ' paid off their loan on time.'}
        state={'warning'}
        hidden={isEmpty(accounts)}
      />
      <DetailSubsection title={<Text id="MemberDetail.allLoans" />}>
        <AccountList
          accounts={accounts}
          columns={3}
          width="w-33"
          renderAccount={loan => (
            <LoanAccountCard
              key={loan.id}
              account={loan}
              actionTo={loanPath({ id: loan.id })}
            />
          )}
        />
      </DetailSubsection>
      <DetailSubsection title={<Text id="MemberDetail.loansGuaranteed" />}>
        <AccountList
          accounts={guaranteedAccounts}
          columns={3}
          width="w-33"
          renderAccount={loan => (
            <LoanAccountCard
              key={loan.id}
              account={loan}
              actionTo={loanPath({ id: loan.id })}
            />
          )}
        />
      </DetailSubsection>
      <DetailSubsection title={<Text id="MemberDetail.payments" />}>
        <TransactionsTable
          heading={heading}
          renderRow={renderRow}
          transactions={transactions}
          whitelist={['REPAYMENT']}
          maximumEntries={100}
        />
      </DetailSubsection>
    </>
  );
};
