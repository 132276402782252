// @flow

import * as React from 'react';
import find from 'lodash/fp/find';
import includes from 'lodash/fp/includes';
import toLower from 'lodash/fp/toLower';

import {
  Attachments,
  type AttachmentT
} from '@kwara/models/src/models/Attachment';
import type { MemberType } from '@kwara/models/src/';

import {
  ActionModal,
  Panel
} from '../../../webapp-sacco/src/components/ActionModal';
import { toBase64Img } from '.';
import { Text } from '../Intl';

const { useState, useEffect } = React;

export const useAttachment = (
  id: ?string,
  memberId: string,
  attachments: AttachmentT[]
) => {
  const [attachment, setAttachment] = useState({});
  const persisted = find(att => att.id === id, attachments);

  useEffect(() => {
    // if the attachment is already there just display it.
    if (persisted && persisted.content) {
      setAttachment(persisted);
      return;
    }

    // otherwise just make a new request and retrieve it server side
    if (id) {
      const att = new Attachments({ memberId });
      att.findOne({ id }).then(setAttachment);
      return;
    }

    setAttachment({});
  }, [id, memberId, persisted]);

  return [attachment];
};

type VProps = {
  member?: MemberType,
  id: string,
  onCancel: () => void,
  disabled?: boolean,
  isClosed: boolean,
  details: {
    name: string,
    filesize: number,
    type: string
  }
};

// Given a member and an attachment ID this will fetch the attachment content
// If the member already contains the image content it just diplays it,
// if the content is yet to be fetched it will query the db to retrieve it.
export const Viewer = ({
  member = {},
  id,
  onCancel,
  isClosed,
  details = {}
}: VProps) => {
  // TODO: handle error here
  const [attachment = {}] = useAttachment(id, member.id, member.attachments);

  if (isClosed) {
    return null;
  }

  const isImg = includes(details.type, ['PNG', 'JPG', 'JPEG']);

  return (
    <ActionModal
      header
      titleId={`DocumentUploads.${details.name}.label`}
      onCancel={onCancel}
      actions={false}
      childSize="widest"
    >
      <Panel>
        <div className="tc pt5">
          {isImg ? (
            attachment.content ? (
              <img
                alt="attachment"
                src={`${toBase64Img(attachment.content, details.type)}`}
              />
            ) : (
              <Text id="DocumentUploads.Loading.label" />
            )
          ) : (
            <>
              <Text id="DocumentUploads.DownloadWarning" />{' '}
              <a
                href={`data:application/octet-stream;base64,${attachment.content}`}
                download={`${details.name}.${toLower(details.type)}`}
              >
                <Text id="DocumentUploads.DownloadText" />{' '}
              </a>
            </>
          )}
        </div>
      </Panel>
    </ActionModal>
  );
};

type WVProps = {
  member: ?MemberType,
  children: ({
    setShownAttachment: (attachment?: AttachmentT) => void
  }) => React.Node
};
// Wrap any component with this and pass down a member to make is possible to
// view documents. This will expose a setShownAttachment function that accepts an instance of
// Document (or any object with that shape) and will take care of retieving and displaying the
// image. Note: we need to pass a member because the attachment is ALWAYS linked to a member,
// so we need at least his ID to retrieve the document

// <WithViewer member={MemberType} />
//   {({ setShownAttachment }) => (
//      <div>
//         <button onClick={() => setShownAttachment(AttachmentT)}>View attachment</button>
//      </div>
//   )}
// </WithViewer>
//
// If member is not passed the children will be displayed and a noop will be passed
export const WithViewer = ({ member, children }: WVProps) => {
  const [shownAttachment, setShownAttachment] = useState({});

  if (!member) {
    return children({ setShownAttachment: () => {} });
  }

  return (
    <>
      <Viewer
        isClosed={!shownAttachment.name}
        id={shownAttachment.id}
        details={shownAttachment}
        member={member}
        onCancel={() => setShownAttachment({})}
      />
      {children({ setShownAttachment })}
    </>
  );
};
