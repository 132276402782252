//@flow

import { type IntlShape } from 'react-intl';

import { type TranslationId } from '.';

export const hasTranslation = (intl: IntlShape, id: TranslationId) => {
  const { messages } = intl;
  return !!messages[id];
};
