// @flow
import React from 'react';

import { Text } from '@kwara/components/src/Intl';

import { Panel } from '../../../components/ActionModal';

import { store } from '../../../models/Store';

export const Request = ({ TextField }) => {
  return (
    <Panel>
      <div className="mb4">
        <span className="kw-text-large kw-weight-bold ">
          <Text id="TopupRequest.main" />
        </span>
        <br />
        <span className="kw-text-medium grey-400">
          <Text id="TopupRequest.subtitle" />
        </span>
      </div>
      <TextField
        name="amount"
        labelId="TopupRequest.CashRequest.label"
        errorBaseId="TopupRequest.CashRequest"
        errorValues={{ max: store.maxAllowedDeposit }}
        leftGlyph="Currency.orgCurrency"
        required
      />
    </Panel>
  );
};

Request.validate = ({ maxTopup = store.maxAllowedDeposit }) => ({
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    max: maxTopup
  }
});
