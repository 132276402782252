//@flow

import React from 'react';

import { Loadable } from '@kwara/components/src/Loadable';
import StatisticRow from '@kwara/components/src/StatisticRow';
import { Currency } from '@kwara/components/src/Intl';
import { Text } from '@kwara/components/src/Intl';
import { Metric } from '@kwara/models/src';
import { Statistic } from '@kwara/components/src/Statistic';

import { useSupervisorMetrics, useGlAccount } from '../../../../models/request';
import Dashboard from '../../../../components/Dashboard';
import DashboardMetric from '../../../../components/DashboardMetric';

import { store } from '../../../../models/Store';

export const TellerDashboard = ({ messages = [], inboxAction }) => {
  const result = useSupervisorMetrics();
  const r2 = useGlAccount(store.vaultId);
  return (
    <Loadable {...result}>
      {metrics => {
        return (
          <Dashboard messages={messages} inboxAction={inboxAction}>
            <StatisticRow
              childClasses="pa0 mr4"
              compact={true}
              widthClass="w-50"
            >
              <DashboardMetric
                title={<Text id="Tellers.Dashboard.currentBalance" />}
                metric={Metric.findFromArray(metrics, 'current_balance')}
              />
              <Loadable {...r2}>
                {vault => (
                  <Statistic
                    title="Vault"
                    value={<Currency value={vault.balance} />}
                    size="extraBig"
                  />
                )}
              </Loadable>
            </StatisticRow>
            <StatisticRow
              childClasses="pa0 mr4"
              compact={true}
              widthClass="w-33"
            >
              <DashboardMetric
                title={<Text id="Tellers.Dashboard.deposits" />}
                metric={Metric.findFromArray(metrics, 'deposits')}
                size="large"
              />

              <DashboardMetric
                title={<Text id="Tellers.Dashboard.withdrawals" />}
                metric={Metric.findFromArray(metrics, 'cash_withdrawals')}
                size="large"
              />
              <DashboardMetric
                title={<Text id="Tellers.Dashboard.utilities" />}
                metric={Metric.findFromArray(metrics, 'utility_payments')}
                size="large"
              />
            </StatisticRow>
            <StatisticRow
              childClasses="pa0 mr4"
              compact={true}
              widthClass="w-33"
            >
              <Statistic
                title={'Minimum till value'}
                value={<Currency value={store.tellerConfigs.tillMinimum} />}
                size="large"
              />
              <Statistic
                title={'Maximum till value'}
                value={<Currency value={store.tellerConfigs.tillMaximum} />}
                size="large"
              />
              <Statistic
                title={'Maximum vault value'}
                value={<Currency value={store.vaultConfigs.reorderMaximum} />}
                size="large"
              />
            </StatisticRow>
          </Dashboard>
        );
      }}
    </Loadable>
  );
};
