// @flow

import { attr, belongsTo } from 'spraypaint';

import { isAfter, getCurrentDate } from '@kwara/lib/src/dates';
import Base, { type BaseModel } from './Base';
import { Role, type RoleT } from './Role';

const InvitationStatuses = Object.freeze({
  REDEEMED: 'REDEEMED',
  PENDING_REDEMPTION: 'PENDING_REDEMPTION',
  REVOKED: 'REVOKED',
  EXPIRED: 'EXPIRED'
});

type InvitationStatus = $Values<typeof InvitationStatuses>;

export const InvitationModel = Base.extend({
  static: {
    jsonapiType: 'invitations'
  },
  attrs: {
    email: attr(),
    redeemed: attr(),
    revoked: attr(),
    expiresAt: attr(),
    role: belongsTo({ type: Role }),
    roleId: attr()
  },
  methods: {
    isExpired() {
      return isAfter(getCurrentDate(), this.expiresAt);
    },
    getStatus() {
      if (this.redeemed) {
        return InvitationStatuses.REDEEMED;
      } else if (this.revoked) {
        return InvitationStatuses.REVOKED;
      } else if (this.isExpired()) {
        return InvitationStatuses.EXPIRED;
      } else {
        return InvitationStatuses.PENDING_REDEMPTION;
      }
    }
  }
});

export interface InvitationModelT extends BaseModel<InvitationModelT> {
  email: string;
  role: RoleT;
  roleId: string;
  redeemed: boolean;
  revoked: boolean;
  expiresAt: string;
  isExpired: () => boolean;
  getStatus: () => InvitationStatus;
}
