//@flow
import * as React from 'react';
import cx from 'classnames';

import { Text, type TranslationId } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

import styles from './index.module.scss';
import Progress from './Progress';

import { type StepState } from '.';

type Props = {
  number: number,
  progress: number,
  titleId: TranslationId,
  stepState: StepState,
  editUrl: string
};

const Number = ({ number }: { number: number }) => (
  <div
    className={`m0 pt1 lh-solid flex justify-center items-center ${
      styles.Number
    }`}
  >
    {number}
  </div>
);

const Title = ({ titleId }: { titleId: TranslationId }) => (
  <div className={`${styles.Text}`}>
    <Text id={titleId} />
  </div>
);

const Item = ({ number, progress, titleId, stepState, editUrl }: Props) => (
  <li
    className={[
      'flex-auto flex items-center',
      'h3 m0 ph4 pv0',
      'bb br',
      styles.Item,
      styles[stepState]
    ].join(' ')}
  >
    <div data-current-progress={progress} className={styles.StepCounter}>
      <Progress percent={progress} />
      <Number number={number} />
    </div>
    <Title titleId={titleId} />
    <span
      className={cx(styles.Link, {
        [styles.Link__hidden]: stepState !== 'past'
      })}
    >
      <span className={styles.Dot}>•</span>
      <Link to={editUrl} type="primary" underline active>
        <Text id="General.Edit" />
      </Link>
    </span>
  </li>
);

export default Item;
