import * as React from 'react';
import cx from 'classnames';

import styles from '../index.module.css';

type Props = {
  children: React.Node,
  metadata?: React.Node,
  secondary?: React.Node,
  title: React.Node,
  headerRight?: React.Node
};

export const DetailOverviewSection = ({
  children,
  metadata,
  secondary,
  title,
  headerRight
}: Props) => (
  <section className={styles.Section}>
    <div>
      {metadata ? <div>{metadata}</div> : null}
      <header className={styles.Header}>
        <div className="mt0 mb1">
          <div>
            <h2 className="mb1 kw-text-big kw-weight-bold">
              <span className={'dib'}>{title}</span>
            </h2>
          </div>
        </div>
        <div className={styles.HeaderRight}>{headerRight}</div>
      </header>
    </div>
    <div className="flex items-start">
      <div className={cx(styles.Secondary, 'flex-none')}>{secondary}</div>
      <div className="flex-auto">{children}</div>
    </div>
  </section>
);
