// @flow

import { attr } from 'spraypaint';

import Base from './Base';

export const [NATIONAL, PASSPORT] = ['NATIONAL', 'PASSPORT'];

const IdDocument = Base.extend({
  static: {
    jsonapiType: 'members_id_documents'
  },
  attrs: {
    documentId: attr(),
    type: attr()
  }
});

export type IdDocumentType = {
  documentId: string,
  type: 'NATIONAL' | 'PASSPORT'
};

export default (IdDocument: typeof IdDocument);
