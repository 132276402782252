//@flow

import * as React from 'react';

import { Select } from '../Form';
import { Text, type TranslationId } from '@kwara/components/src/Intl';

export type FilterValue = string;

export type FilterType = {
  value: FilterValue,
  translationId: TranslationId
};

export type FilterChangeHandler = (filter: FilterValue) => void;

export type Filters = FilterType[];

const Filter = ({
  filterLabelId = 'OverviewList.filterByStatusLabel',
  filterBy,
  filters,
  onChange,
  disabled
}: {
  filterLabelId: string,
  filterBy: ?FilterValue,
  filters: Filters,
  onChange: FilterChangeHandler,
  disabled: boolean
}) => (
  <div className="flex-none">
    <p className="dib kw-text-regular grey-400 ma0 pa0 mr2">
      <Text id={filterLabelId} />
    </p>
    <Select
      inline
      name="filter"
      value={filterBy || ''}
      onChange={evt => onChange(evt.target.value)}
      onBlur={() => {}}
      size="regular"
      disabled={disabled}
    >
      {filters.map(({ value, translationId }: FilterType) => (
        <Select.Option
          key={value}
          value={value}
          translationId={translationId}
        />
      ))}
    </Select>
  </div>
);

Filter.defaultProps = {
  filterLabel: 'status',
  filterBy: null,
  filters: [],
  onChange: () => {},
  disabled: false
};

export default Filter;
