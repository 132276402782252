import * as React from 'react';
import { Form } from 'react-final-form';
import map from 'lodash/fp/map';
import round from 'lodash/fp/round';

import Asset from '@kwara/components/src/Asset';
import Button from '@kwara/components/src/Button';
import { Loadable } from '@kwara/components/src/Loadable';
import { Currency } from '@kwara/components/src/Intl';
import {
  SubscribedTextField,
  SubscribedSelectField,
  SubscribedCheckbox
} from '@kwara/components/src/Form';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { Header } from '../../../../components/SideNavContainer';
import { useSavingProducts } from '../../../../models/request';
import { Grid } from '../../../../components/Grid';

const fakeData = {
  flat: { base: 14667872.5, modifierExited: 0.96, modiferDefaulted: 0.78 },
  weighted: { base: 16134659.75, modifierExited: 0.97, modiferDefaulted: 0.83 },
  products: {
    alpha: 5.6,
    fixed: 15,
    prime: 2.7,
    children: -10.5,
    msingi: -20.5,
    sc: -5.6,
    shares: 10,
    twiga: 2
  }
};

const later = value => new Promise(resolve => setTimeout(resolve, 450, value));

export const Dividends = () => {
  const [isPending, setIsPending] = React.useState(false);
  const [total, setTotal] = React.useState(null);

  const fetchData = async ({
    type,
    product,
    excludeExitedMembers,
    excludeDefaulters
  }) => {
    let base = fakeData[type].base * (1 + fakeData.products[product] / 100);

    if (excludeExitedMembers) {
      base = base * fakeData[type].modifierExited;
    }

    if (excludeDefaulters) {
      base = base * fakeData[type].modiferDefaulted;
    }

    setIsPending(true);
    const val = await later(base);
    setTotal(val);
    setIsPending(false);
  };

  const r = useSavingProducts();
  return (
    <>
      <Header
        titleId="Dividends"
        subtitleId="Calculate interest and dividends of accounts on the platform"
      />
      <Form
        onSubmit={fetchData}
        initialValues={{ type: 'flat', product: 'alpha' }}
        render={({ form, values }) => {
          const { interestRate } = values;
          const rate = Number(interestRate) / 100;
          return (
            <>
              <form>
                <Grid columns={2} width="w-50" border={false}>
                  <Loadable {...r}>
                    {products => (
                      <SubscribedSelectField name="product" labelId="Product">
                        {map(product => {
                          return (
                            <SubscribedSelectField.Option
                              key={product.id}
                              value={product.id}
                            >
                              {product.name}
                            </SubscribedSelectField.Option>
                          );
                        }, products)}
                      </SubscribedSelectField>
                    )}
                  </Loadable>
                  <SubscribedSelectField name="type" labelId="Type">
                    <SubscribedSelectField.Option value="flat">
                      Flat rate
                    </SubscribedSelectField.Option>
                    <SubscribedSelectField.Option value="weighted">
                      Weighted average
                    </SubscribedSelectField.Option>
                  </SubscribedSelectField>
                </Grid>
                <SubscribedCheckbox
                  labelId="Exclude exited members?"
                  name="excludeExitedMembers"
                />
                <SubscribedCheckbox
                  labelId="Exclude members with defaulted loans?"
                  name="excludeDefaulters"
                />

                <Button onClick={form.submit}>Calculate</Button>
              </form>

              <StatisticRow className="mt3" widthClass="w-50">
                {isPending ? (
                  <div className="h-100 flex items-center justify-center">
                    <Asset id="Spinner" col="indigo500" />
                  </div>
                ) : (
                  <Statistic
                    size="large"
                    title="Sum of balances"
                    value={total ? <Currency value={round(total, 2)} /> : null}
                  />
                )}
                <div className="pt3">
                  <SubscribedTextField
                    compact
                    //   margin={false}
                    size="regular"
                    disabled={!total}
                    name="interestRate"
                    //   titleId="Rate"
                    placeholderId="Rate"
                    rightGlyph="%"
                  />
                </div>
                <Statistic
                  size="large"
                  title="Calculated interest"
                  value={
                    total && rate ? <Currency value={total * rate} /> : null
                  }
                />
              </StatisticRow>
            </>
          );
        }}
      />
    </>
  );
};
