// @flow

import * as React from 'react';

import { PermissionDenied } from '@kwara/components/src/PermissionDenied';

import { financePath } from '../../lib/urls';
import { JournalEntry } from './components/JournalEntry';
import permission, { AppPermissions } from '../../models/Permission';
import { SideNavContainer } from '../../components/SideNavContainer';
import { SideNav } from '../../components/SideNav';

import { PendingSavingTransactions } from './components/PendingSavingTransactions';
import { PendingLoanTransactions } from './components/PendingLoanTransactions';
import { Dividends } from './components/Dividends';

type Props = {
  match: {
    params: {
      module: string
    }
  }
};

function getModules() {
  return [
    ...(permission.to(AppPermissions.ViewJournalEntries)
      ? ['journalEntries']
      : []),
    ...(permission.to(AppPermissions.UseMakerCheckerPage)
      ? ['savingTransactions', 'loanTransactions']
      : []),
    'dividends'
  ];
}

function componentSwitch(activeModule: string) {
  switch (activeModule) {
    case 'journalEntries':
      return permission.to(AppPermissions.ViewJournalEntries) ? (
        <JournalEntry />
      ) : (
        <PermissionDenied />
      );
    case 'savingTransactions':
      return permission.to(AppPermissions.UseMakerCheckerPage) ? (
        <PendingSavingTransactions />
      ) : (
        <PermissionDenied />
      );
    case 'loanTransactions':
      return permission.to(AppPermissions.UseMakerCheckerPage) ? (
        <PendingLoanTransactions />
      ) : (
        <PermissionDenied />
      );
    case 'dividends':
      return <Dividends />;
  }
}

export const Finance = (props: Props) => {
  const active = props.match.params.module || 'journalEntries';
  const activeModule = componentSwitch(active);

  return (
    <SideNavContainer
      sideMenu={
        <SideNav
          active={active}
          modules={getModules()}
          pathFn={financePath}
          translationBase="Finance"
        />
      }
      activeModule={activeModule}
    />
  );
};
