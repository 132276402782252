// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import { type SavingType, type LoanType } from '@kwara/models/src';
import { Currency, Text } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';
import { Statistic } from '@kwara/components/src/Statistic';

import { Card } from '../Card';
import styles from './index.module.css';

export const SavingAccountCard = ({
  account,
  isSelected
}: {
  account: SavingType,
  isSelected: boolean
}) => {
  return (
    <Card
      header={<div>{account.name}</div>}
      className={styles.Card}
      metadata={
        <>
          <StatusTag state={account.state.current} /> &middot; {account.id}
        </>
      }
      highlighted={isSelected}
      isHoverable
    >
      <Statistic
        title={<Text id="AccountCard.balance" />}
        value={<Currency value={account.balance} />}
        size="small"
      />
    </Card>
  );
};

export const LoanAccountCard = ({
  account,
  isSelected
}: {
  account: LoanType,
  isSelected: boolean
}) => {
  return (
    <Card
      header={<div>{get('product.name', account)}</div>}
      className={styles.Card}
      metadata={
        <>
          <StatusTag state={account.state.current} /> &middot; {account.id}
        </>
      }
      highlighted={isSelected}
      isHoverable
    >
      <Statistic
        title={<Text id="AccountCard.balance" />}
        value={<Currency value={account.totalBalance} />}
        size="small"
        color="red-500"
      />
    </Card>
  );
};
