// @flow

import React from 'react';
import getOr from 'lodash/fp/getOr';
import sortBy from 'lodash/fp/sortBy';
import first from 'lodash/fp/first';
import last from 'lodash/fp/last';
import { View, StyleSheet } from '@react-pdf/renderer';

import { minus } from '@kwara/lib/src/currency';
import { getCurrentDateFormatted, formatHumanDate } from '@kwara/lib/src/dates';
import {
  type SavingType,
  type TransactionT,
  type MemberType,
  type UserT
} from '@kwara/models/src';
import {
  Currency,
  Date,
  Time,
  pdfMemberDetails as MemberDetails,
  DefaultText as Text,
  SubtitleText as Subtitle,
  Cell,
  Row,
  Section,
  Template,
  EndOfStatement,
  pdfTranslationStore as pdf
} from '@kwara/components/src/PDF';

const styles = StyleSheet.create({
  table: {
    flexDirection: 'column'
  },
  summary: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

const SavingAccountSummary = ({ account }: { account: SavingType }) => {
  const { id, balance, accruedInterest, name } = account;
  return (
    <View style={{ width: '200px' }}>
      <Subtitle
        id="SavingTransactionsPDF.AccountSummary.title"
        style={{ textAlign: 'right' }}
      />
      <View style={styles.summary}>
        <Text>
          {name ? (
            `${name}:`
          ) : (
            <Text id="SavingTransactionsPDF.AccountSummary.account" />
          )}
        </Text>
        <Text>{id}</Text>
      </View>
      <View style={styles.summary}>
        <Text id="SavingTransactionsPDF.AccountSummary.balance" />
        <Text>
          <Text id="SavingTransactionsPDF.Currency" />{' '}
          <Currency>{balance}</Currency>
        </Text>
      </View>
      <View style={styles.summary}>
        <Text id="SavingTransactionsPDF.AccountSummary.interest" />
        <Text>
          <Text id="SavingTransactionsPDF.Currency" />{' '}
          <Currency>{accruedInterest}</Currency>
        </Text>
      </View>
    </View>
  );
};

const DateRange = ({ saving }: { saving: SavingType }) => {
  const { createdAt } = saving;
  return (
    <Subtitle style={{ textAlign: 'center' }}>
      <Text
        id="SavingTransactionsPDF.DateRange"
        values={{
          start: formatHumanDate(createdAt),
          finish: getCurrentDateFormatted()
        }}
      />
    </Subtitle>
  );
};

const SavingTransactionTableHeader = () => {
  return (
    <Row>
      <Cell style={{ width: '25%', textAlign: 'left' }}>
        <Text id="SavingTransactionsPDF.Table.Header.date" />
      </Cell>
      <Cell style={{ width: '35%', textAlign: 'left' }}>
        <Text id="SavingTransactionsPDF.Table.Header.description" />
      </Cell>
      <Cell style={{ width: '20%', textAlign: 'right' }}>
        <Text id="SavingTransactionsPDF.Table.Header.amount" />
      </Cell>
      <Cell style={{ width: '20%', textAlign: 'right' }}>
        <Text id="SavingTransactionsPDF.Table.Header.balance" />
      </Cell>
    </Row>
  );
};

const OpeningBalanceRow = ({ openingTransaction }) => {
  const { enteredAt, amount, balance } = openingTransaction;
  const openingBalance = minus(balance, amount);
  return (
    <Row style={{ backgroundColor: '#E9E9E9' }}>
      <Cell style={{ width: '25%', textAlign: 'left' }}>
        <Date style={{ color: 'black' }}>{enteredAt}</Date>
        <Time style={{ color: 'black' }}>{enteredAt}</Time>
      </Cell>
      <Cell style={{ width: '35%' }}>
        <Text
          id="SavingTransactionsPDF.Table.openingBalance"
          style={{ color: 'black' }}
        />
      </Cell>
      <Cell style={{ width: '20%' }} />
      <Cell style={{ width: '20%' }}>
        <Currency style={{ textAlign: 'right', color: 'black' }}>
          {openingBalance}
        </Currency>
      </Cell>
    </Row>
  );
};

const ClosingBalanceRow = ({ closingTransaction }) => {
  const { enteredAt, balance } = closingTransaction;
  return (
    <Row style={{ backgroundColor: '#E9E9E9' }}>
      <Cell style={{ width: '25%', textAlign: 'left' }}>
        <Date style={{ color: 'black' }}>{enteredAt}</Date>
        <Time style={{ color: 'black' }}>{enteredAt}</Time>
      </Cell>
      <Cell style={{ width: '35%' }}>
        <Text
          id="SavingTransactionsPDF.Table.closingBalance"
          style={{ color: 'black' }}
        />
      </Cell>
      <Cell style={{ width: '20%' }} />
      <Cell style={{ width: '20%' }}>
        <Currency style={{ textAlign: 'right', color: 'black' }}>
          {balance}
        </Currency>
      </Cell>
    </Row>
  );
};

const SavingTableRow = ({ transaction }: { transaction: TransactionT }) => {
  const {
    id,
    enteredAt,
    type,
    amount,
    balance,
    notes,
    reference
  } = transaction;
  return (
    <Row>
      <Cell style={{ width: '25%', textAlign: 'left' }}>
        <Text style={{ color: 'grey' }}>{id}</Text>
        <Date style={{ color: 'black' }}>{enteredAt}</Date>
        <Time style={{ color: 'black' }}>{enteredAt}</Time>
      </Cell>
      <Cell style={{ width: '35%' }}>
        <Text style={{ color: 'grey' }}>{reference}</Text>
        <Text style={{ color: 'black' }} id={`TransactionType.${type}`} />
        <Text style={{ color: 'grey' }}>{notes}</Text>
      </Cell>
      <Cell style={{ width: '20%' }}>
        <Currency style={{ textAlign: 'right', color: 'black' }}>
          {amount}
        </Currency>
      </Cell>
      <Cell style={{ width: '20%' }}>
        <Currency style={{ textAlign: 'right', color: 'black' }}>
          {balance}
        </Currency>
      </Cell>
    </Row>
  );
};

const SavingTransactionsTable = ({
  transactions
}: {
  transactions: TransactionT[]
}) => {
  return (
    <View>
      <OpeningBalanceRow openingTransaction={first(transactions)} />
      {transactions.map(transaction => (
        <SavingTableRow key={transaction.id} transaction={transaction} />
      ))}
      <ClosingBalanceRow closingTransaction={last(transactions)} />
      <EndOfStatement />
    </View>
  );
};

type Props = {
  saving: SavingType,
  member: MemberType,
  profile: UserT,
  transactions: TransactionT[]
};
// Create Document Component
export const SavingTransactionsPDF = ({
  saving,
  member,
  transactions = [],
  profile
}: Props) => {
  const sortedTransactions = sortBy(t => Number(t.id), transactions);

  return (
    <Template
      title={pdf.translate('SavingTransactionsPDF.title', { id: saving.id })}
      sacco={getOr('', 'branch', profile)}
    >
      <Section fixed>
        <MemberDetails member={member} />
        <SavingAccountSummary account={saving} />
      </Section>
      <Section style={{ borderBottomColor: 'black' }}>
        <DateRange saving={saving} />
      </Section>
      <View style={styles.table}>
        <SavingTransactionTableHeader />
        <SavingTransactionsTable transactions={sortedTransactions} />
      </View>
    </Template>
  );
};
