//@flow
import React from 'react';
import reduce from 'lodash/fp/reduce';
import map from 'lodash/map';

import { Logger } from '@kwara/lib/src/logger';
import { parseDuration } from '@kwara/lib/src/dates';
import Text from './Text';

type Props = {
  value: string
};

export default ({ value }: Props) => {
  if (!value) {
    Logger.error('[ERROR] Duration is receiving falsy values');
  }
  const elements = map(parseDuration(value), function(val, key) {
    return <Text key={key} id={`Duration.${key}`} values={{ value: val }} />;
  });

  return <span>{(reduce((prev, curr) => [prev, ', ', curr]), elements)}</span>;
};
