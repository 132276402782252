// @flow

import * as React from 'react';
import { Form } from 'react-final-form';
import queryString from 'query-string';

import Auth from '@kwara/models/src/lib/Auth';
import Banner from '@kwara/components/src/Banner';
import Button, { BackButton } from '@kwara/components/src/Button';
import createValidator from '@kwara/lib/src/validator';
import Empty from '@kwara/components/src/Empty';
import { snakeCaseObjectKeys } from '@kwara/models/src';
import { SubscribedPasswordField } from '@kwara/components/src/Form';
import { Text } from '@kwara/components/src/Intl';
import { Stack, Child } from '@kwara/components/src/Stack';
import { PasswordHelper } from '@kwara/components/src/PasswordHelper';
import {
  passwordBaseRules,
  passwordConfirmBaseRules
} from '@kwara/lib/src/validator/ruleSets';

type Props = {
  auth: Auth,
  location: {
    search: string
  }
};

type State = {
  success: boolean,
  error: boolean
};

type FormData = {
  password: string,
  passwordConfirmation: string
};

export class PasswordReset extends React.Component<Props, State> {
  static validator = createValidator({
    password: passwordBaseRules,
    passwordConfirmation: passwordConfirmBaseRules
  });

  static validate(values: FormData) {
    return PasswordReset.validator(values);
  }

  state = {
    success: false,
    error: false
  };

  handleSubmit = (data: FormData) => {
    this.setState({ error: false, success: false });

    const { password, passwordConfirmation } = data;
    const { location, auth } = this.props;
    const { t } = queryString.parse(location.search);

    auth
      .passwordReset(
        snakeCaseObjectKeys({
          password,
          passwordConfirmation,
          token: t
        })
      )
      .then(() => {
        this.setState({
          success: true
        });
      })
      .catch(() => {
        this.setState({
          error: true
        });
      });
  };

  render() {
    const { success, error } = this.state;
    return (
      <div className="flex flex-column h-100 justify-center">
        <Stack>
          <Child size="wide">
            {!success ? (
              <BackButton
                to={{ url: '/', routeName: 'Login' }}
                type="secondary"
              />
            ) : null}
            <Empty>
              <div className="pv3">
                {!success ? (
                  <>
                    <h2 className="grey-500">
                      <Text id="Password.Reset.title" />
                    </h2>
                    <Form
                      onSubmit={this.handleSubmit}
                      validate={PasswordReset.validate}
                      render={({ handleSubmit, invalid, errors }) => {
                        return (
                          <form onSubmit={handleSubmit}>
                            <SubscribedPasswordField
                              margin={false}
                              name="password"
                              labelId="Password.Reset.password"
                              errorBaseId="Password.Reset"
                            />

                            <PasswordHelper
                              rules={passwordBaseRules}
                              errors={errors}
                            />

                            <SubscribedPasswordField
                              name="passwordConfirmation"
                              labelId="Password.Reset.passwordConfirm"
                            />

                            <Button
                              isSubmit
                              disabled={invalid}
                              type="primary"
                              size="medium"
                            >
                              <Text id="Password.Reset.submit" />
                            </Button>
                          </form>
                        );
                      }}
                    />
                  </>
                ) : null}

                {success ? (
                  <>
                    <h2 className="grey-500">
                      <Text id="Password.Reset.submit.success" />
                    </h2>

                    <Button
                      className="mt2"
                      to="/"
                      type="primary"
                      glyphRightId="go"
                    >
                      <Text id="SignUp.Success.button" />
                    </Button>
                  </>
                ) : null}

                {error ? (
                  <Banner
                    className="mt3"
                    state="error"
                    text={<Text id="Password.Reset.submit.error" />}
                  />
                ) : null}
              </div>
            </Empty>
          </Child>
        </Stack>
      </div>
    );
  }
}
