// @flow

import React from 'react';

import { DetailWrapper, Detail } from '..';

export const Password = () => {
  return (
    <DetailWrapper>
      <Detail titleId="Settings.Personal.Password.title" value="****" />
    </DetailWrapper>
  );
};
