import * as React from 'react';
import classnames from 'classnames';
import min from 'lodash/min';
import max from 'lodash/max';

import Button, { ActionButton } from '@kwara/components/src/Button';
import { Text, DateTime } from '@kwara/components/src/Intl';

import styles from './index.module.css';

export const Message = ({ message }) => {
  const { type, ...values } = message;
  return (
    <h3 className="kw-text-large kw-weight-bold">
      <Text id={`Inbox.${type}`} values={values} />
    </h3>
  );
};

export const Inbox = ({ messages = [], action }) => {
  const [index, setIndex] = React.useState(0);
  const back = () => setIndex(max([index - 1, 0]));
  const next = () => setIndex(min([index + 1, messages.length - 1]));

  // This is a ****temporary**** HACK.
  // See https://kwara.slack.com/archives/CA6NU0RF1/p1570018012015000?thread_ts=1570005621.007200&cid=CA6NU0RF1
  if (!['/tellers', '/till'].includes(window.location.pathname)) {
    return null;
  }

  if (messages.length === 0) {
    return (
      <div className={classnames(styles.Inbox, 'pv5 fl br b--light-grey-300')}>
        <div className="flex items-center">
          <h2 className="mt0 dib kw-text-large kw-weight-bold">
            <Text id="Dashboard.inbox" />
          </h2>
        </div>
        <p className="mb0 grey-400">
          <Text id="Inbox.noMessages" />
        </p>
      </div>
    );
  }

  const message = messages[index];

  return (
    <div className={classnames(styles.Inbox, 'pv5 fl br b--light-grey-300')}>
      <div className="flex ">
        <h2 className="mt0 tc kw-text-large kw-weight-bold">
          <Text id="Dashboard.inbox" />
        </h2>
        <div className="flex ml-auto">
          <ActionButton onClick={back} size="tiny" type="back" />
          <ActionButton onClick={next} size="tiny" type="go" />
        </div>
      </div>

      <div className="mt4 flex items-center">
        <span className="kw-text-regular grey-400 dib">
          <Text
            id="Inbox.messages"
            values={{ current: index + 1, total: messages.length }}
          />
        </span>
        <span className="kw-text-regular grey-400 dib flex ml-auto">
          <DateTime value={message.updated} />
        </span>
      </div>

      <span className="mb0">
        <Message message={message} />
      </span>

      {action ? (
        <Button type="primary" onClick={() => action(message)}>
          <Text id={`Inbox.action.${message.type}`} />
        </Button>
      ) : null}
    </div>
  );
};
