// @flow

import * as React from 'react';
import invoke from 'lodash/fp/invoke';
import size from 'lodash/fp/size';

import { type BatchTransactionImportT } from '@kwara/models/src';
import { Text, FileSize } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import StatisticRow from '@kwara/components/src/StatisticRow';

import { DetailOverviewSection } from '../../../components/Detail/DetailOverviewSection';
import { OverviewMetadata } from './OverviewMetadata';

type Props = {
  batchImport: BatchTransactionImportT
};

export const Overview = ({ batchImport }: Props) => {
  const { user, batchTransactions } = batchImport;

  return (
    <DetailOverviewSection
      metadata={<OverviewMetadata batchImport={batchImport} />}
      title={
        <Text
          id="BatchImportDetails.Overview.title"
          values={{ name: batchImport.name }}
        />
      }
    >
      <section className="mb5">
        <StatisticRow widthClass="w-50">
          <Statistic
            title={<Text id="BatchImportDetails.Overview.totalRows" />}
            value={size(batchTransactions)}
            size={'huge'}
          />
        </StatisticRow>
        <StatisticRow widthClass="w-33">
          <Statistic
            title={<Text id="BatchImportDetails.Overview.uploader" />}
            value={invoke('fullName', user)}
            size={'medium'}
          />
          <Statistic
            title={<Text id="BatchImportDetails.Overview.uploaderId" />}
            value={user.id}
            size={'medium'}
          />
          <Statistic
            title={<Text id="BatchImportDetails.Overview.fileSize" />}
            value={<FileSize value={batchImport.byteSize} />}
            size={'medium'}
          />
        </StatisticRow>
      </section>
    </DetailOverviewSection>
  );
};
