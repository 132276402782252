import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import OverviewList from '../../../../components/OverviewList';
import { TransactionsTable } from './TransactionTable';

const filters = [
  { translationId: 'Finance.Transactions.List.filter.none', value: '' },
  {
    translationId: 'Finance.Transactions.List.filter.PENDING',
    value: 'pending_approval'
  },
  {
    translationId: 'Finance.Transactions.List.filter.REJECTED',
    value: 'rejected'
  },
  {
    translationId: 'Finance.Transactions.List.filter.IMPORTING',
    value: 'importing'
  },
  {
    translationId: 'Finance.Transactions.List.filter.IMPORTED',
    value: 'imported'
  },
  { translationId: 'Finance.Transactions.List.filter.FAILED', value: 'failed' },
  {
    translationId: 'Finance.Transactions.List.filter.APPROVED',
    value: 'approved'
  }
];

export const TransactionsList = ({
  transactions,
  valid,
  onFilterChange,
  filterBy,
  onApprove,
  onReject,
  ...rest
}) => {
  const confirm = (
    <Button disabled={!valid} type="primary" onClick={onApprove}>
      <Text id="Finance.Transactions.List.Confirm" />
    </Button>
  );
  const reject = (
    <Button disabled={!valid} type="destructive" onClick={onReject}>
      <Text id="Finance.Transactions.List.Reject" />
    </Button>
  );

  return (
    <OverviewList
      labelId="Finance.Transactions.List.label"
      totalNumResults={transactions.length}
      onFilterChange={onFilterChange}
      filterBy={filterBy}
      filters={filters}
      actions={[confirm, reject]}
      items={transactions}
      table={<TransactionsTable transactions={transactions} {...rest} />}
    />
  );
};
