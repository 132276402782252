import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import get from 'lodash/fp/get';

import { mapIndexed } from '@kwara/lib/src/lodash';
import { type MemberType, type SavingType } from '@kwara/models/src';
import {
  DefaultText as Text,
  Cell,
  Row,
  Currency
} from '@kwara/components/src/PDF';

const styles = StyleSheet.create({
  table: {
    flexDirection: 'column'
  }
});

const SavingsAccountsHeader = () => {
  const rows = 6;
  const columnWidth = `${100 / rows}%`;
  return (
    <Row>
      <Cell style={{ width: columnWidth, textAlign: 'left' }}>
        <Text id="MemberSummaryPDF.SavingAccounts.Header.status" />
      </Cell>
      <Cell style={{ width: columnWidth, textAlign: 'left' }}>
        <Text id="MemberSummaryPDF.SavingAccounts.Header.id" />
      </Cell>
      <Cell style={{ width: columnWidth, textAlign: 'left' }}>
        <Text id="MemberSummaryPDF.SavingAccounts.Header.name" />
      </Cell>
      <Cell style={{ width: columnWidth, textAlign: 'right' }}>
        <Text id="MemberSummaryPDF.SavingAccounts.Header.balance" />
      </Cell>
      <Cell style={{ width: columnWidth, textAlign: 'right' }}>
        <Text id="MemberSummaryPDF.SavingAccounts.Header.interest" />
      </Cell>
      <Cell style={{ width: columnWidth, textAlign: 'right' }}>
        <Text id="MemberSummaryPDF.SavingAccounts.Header.feesBalance" />
      </Cell>
    </Row>
  );
};

const SavingsAccountRow = ({ saving }: { saving: SavingType }) => {
  const { state, id, name, balance, accruedInterest, fees } = saving;

  const rows = 6;
  const columnWidth = `${100 / rows}%`;

  return (
    <Row>
      <Cell style={{ width: columnWidth, textAlign: 'left' }}>
        <Text style={{ color: 'lightgrey' }}>{state.current}</Text>
      </Cell>
      <Cell style={{ width: columnWidth, textAlign: 'left' }}>
        <Text style={{ color: 'black' }}>{id}</Text>
      </Cell>
      <Cell style={{ width: columnWidth }}>
        <Text style={{ color: 'black' }}>{name}</Text>
      </Cell>
      <Cell style={{ width: columnWidth }}>
        <Currency style={{ textAlign: 'right', color: 'black' }}>
          {balance}
        </Currency>
      </Cell>
      <Cell style={{ width: columnWidth }}>
        <Currency style={{ textAlign: 'right', color: 'black' }}>
          {accruedInterest}
        </Currency>
      </Cell>
      <Cell style={{ width: columnWidth }}>
        <Currency style={{ textAlign: 'right', color: 'black' }}>
          {get('total', fees)}
        </Currency>
      </Cell>
    </Row>
  );
};

const SavingsAccountTable = ({ savings }: { savings: SavingType[] }) => {
  return (
    <View>
      {mapIndexed(
        (saving, i) => (
          <SavingsAccountRow key={i} saving={saving} />
        ),
        savings
      )}
    </View>
  );
};

export const SavingsAccounts = ({ member }: { member: MemberType }) => {
  const { savings } = member;
  return (
    <View style={styles.table}>
      <SavingsAccountsHeader />
      <SavingsAccountTable savings={savings} />
    </View>
  );
};
