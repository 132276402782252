// @flow

import * as React from 'react';
import get from 'lodash/fp/get';

import {
  type TillT,
  Metric,
  type TillTransactionType
} from '@kwara/models/src';
import { Text } from '@kwara/components/src/Intl';
import { Loadable } from '@kwara/components/src/Loadable';
import PageLayout from '@kwara/components/src/PageLayout';
import { PrintPDFButton } from '../../components/PrintPDFButton';
import { TransactionsPDF } from '../Till/components/TillListTable/TransactionsPDF';

import {
  useTill,
  useTillMetrics,
  useTillTransactions
} from '../../models/request';

import {
  AsNavigation,
  BackToTopNavigation
} from '../../components/DetailNavigation';

import { DetailSection } from '../../components/Detail/DetailSection';
import Head from '../../components/Head';
import { Overview } from './components/Overview';
import { Transactions } from './components/Transactions';

type Props = {
  showMargins: boolean,
  showOverviewActions: boolean,
  match: {
    params: {
      tillId: string
    }
  }
};

// Currently the full TillDetail data must come from two endpoints
// This function is responsible for merging the data into one object
// The TD story to clean this up is here [ch7258]:
// https://app.clubhouse.io/getkwara/story/7258/metrics-on-individual-tills-page
function useMergedTillData(tillId) {
  const till = useTill(tillId);
  const metrics = useTillMetrics(tillId);

  const data = {
    id: get('data.id', till),
    updatedAt: get('data.updatedAt', till),
    status: get('data.status', till),
    closingAmount: get('data.closingAmount', till),
    tellerId: get('data.tellerId', till),
    currentAmount: get('data.currentAmount', till),
    tellerName: get('data.tellerName', till),
    deposits: get(
      'value.value',
      Metric.findFromArray(metrics.data, 'cash_deposits')
    ),
    withdrawals: get(
      'value.value',
      Metric.findFromArray(metrics.data, 'cash_withdrawals')
    )
  };

  return {
    data,
    isPending: till.isPending || metrics.isPending,
    error: till.error || metrics.error
  };
}

const renderBody = ({
  till,
  transactions
}: {
  till: TillT,
  transactions: TillTransactionType[]
}) => {
  return (
    <>
      <Head titleId="TillDetail.pageTitle" values={{ id: till.tellerName }} />
      <DetailSection
        id="transactions"
        title={<Text id="TillDetail.transactions" />}
        subtitle={<BackToTopNavigation />}
        headerRight={
          <PrintPDFButton
            renderPDF={props => (
              <TransactionsPDF transactions={transactions} {...props} />
            )}
          />
        }
      >
        <Transactions transactions={transactions} />
      </DetailSection>
      {/* <DetailSection
      id="activity"
      title={<Text id="TillDetail.activity" />}
      subtitle={<BackToTopNavigation />}
    >
      <h1>Placeholder</h1>
    </DetailSection> */}
    </>
  );
};

export const TillDetail = ({
  showMargins = true,
  showOverviewActions = true,
  ...props
}: Props) => {
  const results = useMergedTillData(props.match.params.tillId);
  const r2 = useTillTransactions(props.match.params.tillId);

  return (
    <Loadable {...results}>
      {till => {
        return (
          <Loadable {...r2}>
            {transactions => {
              const body = renderBody({ till, transactions });
              const Navigation = AsNavigation(body);
              return (
                <PageLayout
                  className={showMargins ? 'pt5' : ''}
                  overview={
                    <Overview
                      navigation={<Navigation />}
                      till={till}
                      showBack={showOverviewActions}
                      actions={[]}
                    />
                  }
                >
                  {body}
                </PageLayout>
              );
            }}
          </Loadable>
        );
      }}
    </Loadable>
  );
};
