// @flow
import * as React from 'react';

import styles from './index.module.scss';
import image from './image.png';
import triangle from './triangle.png';

type Props = {
  children: React.Node,
  className?: string,
  img?: 'default' | 'triangle'
};

function src(img) {
  if (img === 'triangle') {
    return triangle;
  }

  return image;
}

export default ({ children, img, className = '' }: Props) => (
  <div className={`${styles.Box} flex justify-stretch ${className}`}>
    <div
      className={`${styles.Message} flex-auto flex items-center kw-text-regular grey-400`}
    >
      {children}
    </div>
    <div className={`${styles.Image} ml-auto`}>
      <img className={styles.Png} src={src(img)} alt="" />
    </div>
  </div>
);
