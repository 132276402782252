// @flow

import { attr, hasMany } from 'spraypaint';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';

import Base from './Base';
import { type RepaymentType } from './Repayment';

const Schedule = Base.extend({
  static: {
    jsonapiType: 'schedules'
  },
  attrs: {
    totalDue: attr(),
    repayments: hasMany()
  },
  methods: {
    firstInstallmentAmount() {
      return size(this.repayments) > 0
        ? get('repayments.[0].totalDue', this)
        : null;
    }
  }
});

export type ScheduleType = {
  totalDue: string,
  repayments: $ReadOnlyArray<RepaymentType>
};

export default (Schedule: typeof Schedule);
