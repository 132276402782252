// @flow

import forEach from 'lodash/fp/forEach';

import { KwaraPermissionsConfig } from '@kwara/models/src';

export function keysOnly(permissionsObject) {
  const obj = {};

  forEach(p => {
    obj[p] = p;
  }, Object.keys(permissionsObject));

  return obj;
}

export const KwaraPermissions = keysOnly(KwaraPermissionsConfig);
export type KwaraPermission = $Values<typeof KwaraPermissions>;
