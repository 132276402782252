//@flow
import * as React from 'react';
import lodashGroupBy from 'lodash/groupBy';
import lodashMap from 'lodash/map';
import lodashOrderBy from 'lodash/orderBy';

import StatusTag from '@kwara/components/src/StatusTag';
import { type LoanType, type LoanState } from '@kwara/models/src';
import { Duration, Percent, DateTime } from '@kwara/components/src/Intl';

import { Currency } from '../../../../components/Currency';
import { loanPath } from '../../../../lib/urls';
import Table, * as table from '../../../../components/Table';

export const Groups = {
  state: 'state'
};

export const Orders = {
  updated: 'updated_at'
};

type Group = $Values<typeof Groups>;
type Order = $Values<typeof Orders>;

type FormattedLoan = {
  id: string,
  name: string,
  loan: string,
  amount: string,
  interestRate: ?number,
  repaymentFrequency: ?string,
  duration: ?string,
  state: LoanState,
  updated: string
};
const formatter = (model: LoanType): FormattedLoan => ({
  id: model.id,
  name: model.member.fullName(),
  loan: model.product.name,
  amount: model.amount,
  interestRate: model.interestRatePercent(),
  repaymentFrequency: model.repaymentFrequency,
  duration: model.duration,
  updated: model.updatedAt,
  state: model.state.current
});

const Row = ({ item, pathTo }: { item: LoanType, pathTo: string }) => {
  const f = formatter(item);

  return (
    <table.Row to={pathTo}>
      <table.Cell to={pathTo} className="grey-400 kw-numeric">
        {f.id}
      </table.Cell>
      <table.Cell to={pathTo} className="mw5">
        {f.name}
      </table.Cell>
      <table.Cell to={pathTo}>{f.loan}</table.Cell>
      <table.Cell to={pathTo} align="right">
        <Currency value={f.amount} />
      </table.Cell>
      <table.Cell to={pathTo} align="right">
        <Percent value={f.interestRate} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <Duration value={f.duration} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <DateTime value={f.updated} />
      </table.Cell>
      <table.Cell to={pathTo}>
        <StatusTag size="small" state={f.state} />
      </table.Cell>
    </table.Row>
  );
};

const createGroupWithLoans = ({
  key,
  group,
  columns,
  loans
}: {
  key: string,
  group: Group,
  columns: number,
  loans: LoanType[]
}) => (
  <React.Fragment key={key}>
    {group !== 'none' && (
      <table.GroupingRow
        cols={columns}
        translationId={`LoanListTableGroup.${group}`}
      />
    )}
    {loans.map(item => (
      <Row key={item.id} item={item} pathTo={loanPath({ id: item.id })} />
    ))}
  </React.Fragment>
);

type GroupedItems = { [id: Group]: LoanType[] };

const itemsGroupedByAttribute = (
  loans: LoanType[],
  attribute: Group
): GroupedItems =>
  lodashGroupBy(loans, ({ attributes }) => attributes[attribute]);

const orderByAttribute = (loans: LoanType[], attribute: Order): LoanType[] =>
  // $FlowFixMe should be lodashOrderBy<LoanType>(...)
  lodashOrderBy(loans, [({ attributes }) => attributes[attribute]], ['desc']);

export default ({
  hasMore = false,
  groupBy = Groups.state,
  loading,
  loans,
  errors,
  orderBy = Orders.updated,
  onLoadMoreData
}: {
  hasMore?: boolean,
  groupBy: Group,
  loading: boolean,
  loans: LoanType[],
  errors: Object[],
  orderBy: Order,
  onLoadMoreData: () => void
}) => {
  const groupedLoans = groupBy
    ? itemsGroupedByAttribute(loans, groupBy)
    : {
        none: loans
      };

  const columns = 8;

  return (
    <Table
      heading={
        <table.Row>
          <table.Heading translationId="LoanListTable.id" />
          <table.Heading translationId="LoanListTable.member" />
          <table.Heading translationId="LoanListTable.loan" />
          <table.Heading align="right" translationId="LoanListTable.amount" />
          <table.Heading
            align="right"
            translationId="LoanListTable.interestRate"
          />
          <table.Heading translationId="LoanListTable.duration" />
          <table.Heading translationId="LoanListTable.updated" />
          <table.Heading translationId="LoanListTable.status" />
        </table.Row>
      }
      footer={
        <table.Footer
          colSpan={columns}
          isPending={loading}
          onNext={onLoadMoreData}
          hasMore={hasMore}
          errors={errors}
          items={loans}
          translationBaseId="LoanListTable"
        />
      }
    >
      {lodashMap(groupedLoans, (loans, group) =>
        createGroupWithLoans({
          key: group,
          columns: columns,
          group: group,
          loans: orderByAttribute(loans, orderBy)
        })
      )}
    </Table>
  );
};
