// @flow

import React from 'react';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';
import map from 'lodash/fp/map';

import StatusTag from '@kwara/components/src/StatusTag';
import { DateTime } from '@kwara/components/src/Intl';
import { BackButton } from '@kwara/components/src/Button';
import { PageHeading } from '@kwara/components/src/text';

import { useHistoricalCreditSubmission } from '../../../../models/request';
import Table, * as table from '../../../../components/Table';
import OverviewList from '../../../../components/OverviewList';

const includes = ['user', 'loan_account_crb_submissions'];
export const CreditSubmitDetails = ({ id }: { id: string }) => {
  const { data, isPending, error } = useHistoricalCreditSubmission(
    id,
    includes
  );

  const { loanAccountCrbSubmissions = [] } = data;

  return (
    <>
      <div className="flex items-center flex-column mt2 mb5">
        <PageHeading translationId="CrbDetails.pageHeading" values={{ id }} />
      </div>
      <OverviewList
        backButton={<BackButton to={{ url: '/credit', routeName: 'Credit' }} />}
        labelId="CrbDetails.entity"
        totalNumResults={size(loanAccountCrbSubmissions)}
        items={loanAccountCrbSubmissions}
        table={
          <Table
            heading={
              <table.Row>
                <table.Heading width="50px" />
                <table.Heading
                  textSize="regular"
                  translationId="CrbDetails.heading.loanId"
                />
                <table.Heading
                  textSize="regular"
                  translationId="CrbDetails.heading.date"
                />
                <table.Heading
                  textSize="regular"
                  translationId="CrbDetails.heading.loanStatus"
                  values={{ br: <br /> }}
                />
                <table.Heading
                  textSize="regular"
                  translationId="CrbDetails.heading.submissionStatus"
                />
                <table.Heading iconSpacer />
              </table.Row>
            }
            footer={
              <table.Footer
                colSpan={5}
                isPending={isPending}
                hasMore={false}
                errors={error.messages}
                items={loanAccountCrbSubmissions}
                translationBaseId="LoanListTable"
              />
            }
          >
            {map(
              entry => (
                <table.Row key={entry.id}>
                  <table.Cell className="grey-400 kw-numeric" />
                  <table.Cell className="grey-400 kw-numeric">
                    {entry.loanId}
                  </table.Cell>
                  <table.Cell>
                    <DateTime value={entry.createdAt} />
                  </table.Cell>
                  <table.Cell>
                    <StatusTag state={get('state.current', entry)} />
                  </table.Cell>
                  <table.Cell>
                    <StatusTag state={get('state.current', data)} />
                  </table.Cell>
                </table.Row>
              ),
              loanAccountCrbSubmissions
            )}
          </Table>
        }
      />
    </>
  );
};
