// @flow

import * as React from 'react';

import { ERRORS } from '@kwara/lib/src/validator';
import { Text } from '@kwara/components/src/Intl';

import { getMinMaxLoanProductAmount } from '../../LoanAdd/components/Configure/PrincipalAmountField';

import {
  type ComponentPropsLoanRefinance,
  type RefinanceFormData,
  totalBalance,
  applyRefinancingFee
} from '..';

function getOriginalLoanAmount(data: RefinanceFormData): number {
  const { loan } = data;
  return totalBalance(loan);
}

function getMinimumRefinanceAmount(data: RefinanceFormData) {
  const original = getOriginalLoanAmount(data);
  return applyRefinancingFee(original);
}

export const AmountField = ({
  data,
  TextField
}: ComponentPropsLoanRefinance) => {
  const { principalMax, principalMin } = getMinMaxLoanProductAmount(data);
  const minimumRefinancingAmount = getMinimumRefinanceAmount(data);

  const amountInfo = (
    <ul>
      <li>
        <Text
          id="LoanRefinance.Configure.PrincipalAmount.refinanceInfo"
          values={{
            minimumRefinancingAmount
          }}
        />
      </li>
      {principalMax && (
        <li>
          <Text
            id="LoanRefinance.Configure.PrincipalAmount.maximumAmount"
            values={{
              principalMax,
              principalMin
            }}
          />
        </li>
      )}
    </ul>
  );

  return (
    <>
      <TextField
        required
        margin={false}
        compact
        errorBaseId="LoanRefinance.Configure.PrincipalAmount"
        errorValues={{
          principalMax,
          principalMin,
          minimumRefinancingAmount
        }}
        name="amount"
        leftGlyph="Currency.orgCurrency"
        titleId="LoanRefinance.Configure.PrincipalAmount.title"
        info={amountInfo}
      />
    </>
  );
};

AmountField.validate = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true,
    custom: (target, allData) => {
      const { principalMax, principalMin } = getMinMaxLoanProductAmount(
        allData
      );

      const minimumRefinancingAmount = getMinimumRefinanceAmount(allData);

      const num = Number(target);

      if (num <= minimumRefinancingAmount) {
        return 'mustExceedOriginalAmount';
      }

      if (principalMin != null && num < principalMin) {
        return ERRORS.rangeUnderflow;
      }

      if (principalMax != null && num > principalMax) {
        return ERRORS.rangeOverflow;
      }

      return null;
    }
  }
};
