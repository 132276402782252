// @flow

import * as React from 'react';
import cx from 'classnames';

import { appName } from '@kwara/lib/src/utils';
import { Text, type TranslationId } from '@kwara/components/src/Intl';
import { Stack } from '@kwara/components/src/Stack';

type Props = {
  titleId: TranslationId,
  subtitleId?: ?TranslationId,
  values?: ?{ [string]: string },
  children?: React.Node
};

export default ({ titleId, subtitleId, values, children }: Props) => (
  <Stack.Child>
    <div className="mb5">
      {titleId ? (
        <h1
          data-testid="wizard-title-visible"
          className={cx('mt4 mb0  kw-weight-bold', {
            ['kw-text-extra-big']: !appName.isMember,
            ['kw-text-super-large']: appName.isMember
          })}
        >
          <Text id={titleId} values={values} />
        </h1>
      ) : null}
      {subtitleId && (
        <p className="mt1 mb0 kw-text-large grey-400">
          <Text id={subtitleId} values={values} />
        </p>
      )}
      {children}
    </div>
  </Stack.Child>
);
