// @flow

// WARNING
// Editing this file?
// Consider refactoring the functionality of the "type" you are working on
// ('repayment') to its own form, by extracting the pieces of
// functionality you need from here.
// When you have finished remember to delete the "type" from the below Flow Type defs of Props
// type: 'repayment'

import * as React from 'react';
import get from 'lodash/fp/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/fp/isEmpty';

import { getCurrentDate } from '@kwara/lib/src/dates';
import { type LoanType, type SavingType } from '@kwara/models/src';
import { Statistic } from '@kwara/components/src/Statistic';
import { Text, type TranslationId } from '@kwara/components/src/Intl';
import {
  SubscribedTextField as TextField,
  SubscribedSelectField as SelectField,
  SubscribedPaymentSelectField
} from '@kwara/components/src/Form';
import {
  getTransactionTypes,
  contexts,
  TransactionChannels,
  type TransactionChannelT
} from '@kwara/models/src/models/Transactions';

import { DatePicker, Field, Option } from '../Form';
import { Grid } from '../Grid';
import { Currency } from '../Currency';

import styles from './index.module.css';

export const requiredIfBankSelected = {
  isRequired: (target: string, allData: any) =>
    allData.method === TransactionChannels.bankTransfer && isEmpty(target)
};

export const defaultConfig = {
  amount: {
    isRequired: () => true,
    currency: true,
    nonZero: true
  },
  method: { isRequired: () => true },
  bankName: requiredIfBankSelected,
  bankBranch: requiredIfBankSelected,
  accountNumber: requiredIfBankSelected
};

export type PaymentData = {
  amount?: ?number,
  date?: ?Date,
  method?: ?TransactionChannelT,
  bankName?: string,
  bankBranch?: string,
  accountNumber?: string,
  reference?: ?string,
  accountId?: string
};

type Props = {
  showAmount?: boolean, // To Do: I'm not sure about this prop
  accounts?: Array<LoanType | SavingType>,
  selectedAccount: LoanType | SavingType,
  amountInfo?: React.Node,
  formValues: PaymentData,
  dateLabelId?: TranslationId,
  disabledDays?: { before?: Date, after?: Date },
  showMethod?: boolean,
  handleAccountChange: (event: SyntheticEvent<HTMLSelectElement>) => void,
  outstanding?: number,
  type: 'repayment'
};

export function getTransactionMethodConfig(type: string) {
  const formTypeToTransactionType = {
    deposit: contexts.Deposit,
    withdrawal: contexts.Withdrawal,
    repayment: contexts.LoanRepayment
  };
  const toContext = get(type, formTypeToTransactionType);
  return {
    bank: 'bankName',
    methods: getTransactionTypes(toContext).values
  };
}

export default class PaymentForm extends React.Component<Props, {}> {
  static defaultProps = {
    showMethod: true
  };

  renderAccount = () => {
    const hasAccountsSelection = isArray(this.props.accounts);
    const hasSelectedAccount = this.props.selectedAccount != null;

    if (hasAccountsSelection) {
      return this.renderAccountsSelect();
    } else if (hasSelectedAccount) {
      return this.renderAccountName();
    }

    return null;
  };

  renderAccountName = () => (
    <Statistic
      size="medium"
      title={<Text id="PaymentForm.account" />}
      value={`${this.props.selectedAccount.id} — ${this.props.selectedAccount.product.name}`}
    />
  );

  renderAccountsSelect = () => {
    const { accounts, handleAccountChange } = this.props;
    let hasAccounts = false;
    let options = (
      <Option value="">
        <Text id="PaymentForm.noAccounts" />
      </Option>
    );

    if (accounts != null && isArray(accounts) && accounts.length > 0) {
      hasAccounts = true;

      options = accounts.map(account => (
        <SelectField.Option key={account.id} value={account.id}>
          {account.id} &mdash; {account.product.name}
        </SelectField.Option>
      ));
    }

    return (
      <SelectField
        name="accountId"
        size="medium"
        disabled={!hasAccounts}
        labelId="PaymentForm.account"
        inputOnChange={handleAccountChange}
      >
        {options}
      </SelectField>
    );
  };

  renderAmountField = () => {
    return (
      <TextField
        required
        name="amount"
        size="medium"
        leftGlyph="Currency.orgCurrency"
        labelId="PaymentForm.amount"
        info={this.props.amountInfo}
      />
    );
  };

  renderDateField = () => (
    <Field
      required
      disabled
      name="date"
      size="medium"
      labelId={this.props.dateLabelId || 'PaymentForm.date'}
    >
      <DatePicker
        name="date"
        disabled
        value={getCurrentDate()}
        disabledDays={this.props.disabledDays}
      />
    </Field>
  );

  renderReference = (formValues: PaymentData) =>
    formValues.method != null && (
      <TextField
        name="reference"
        size="medium"
        labelId="PaymentForm.reference"
      />
    );

  render() {
    const { formValues, showAmount, type } = this.props;
    return (
      <div className={styles.PaymentForm}>
        <div className="bb b--light-grey-400 mb4">
          {this.renderAccount()}
          {this.props.outstanding ? (
            <p className="grey-400 mb2  pt0 mt0 kw-text-regular">
              <Text id="PaymentForm.oustandingAmount" />
              {': '}
              <Currency format="currency" value={this.props.outstanding} />
            </p>
          ) : null}
        </div>
        {showAmount ? (
          <Grid columns={2} width="w-50" border={false}>
            {this.renderAmountField()}
            {this.renderDateField()}
          </Grid>
        ) : (
          this.renderDateField()
        )}
        {this.props.showMethod ? (
          <SubscribedPaymentSelectField
            name="method"
            config={getTransactionMethodConfig(type)}
            required
          />
        ) : null}
        {this.props.showMethod ? this.renderReference(formValues) : null}
      </div>
    );
  }
}
