// @flow
import * as React from 'react';
import cx from 'classnames';
import posed from 'react-pose';
import noop from 'lodash/fp/noop';

import { ActionButton } from '..';

import styles from './index.module.scss';

type ExpanderStateT = 'expanded' | 'collapsed';

export const Expander = posed.div({
  collapsed: {
    height: 0,
    opacity: 0,
    flip: true
  },
  expanded: {
    height: '100%',
    opacity: 1,
    flip: true
  }
});

const Control = posed.div({
  collapsed: {
    opacity: 1
  },
  expanded: {
    opacity: 0
  }
});

export const ExpanderBar = ({
  isExpanded,
  onClick = noop
}: {
  isExpanded: boolean,
  onClick: (p: boolean) => void
}) => (
  <Control
    className={`bt b--light-grey-400 w-100 flex justify-center ${styles.ExpandBar}`}
  >
    <ActionButton
      className={cx(styles.ExpandBtn, isExpanded && styles.ExpandBtn__open)}
      type="expand"
      onClick={() => onClick(!isExpanded)}
    />
  </Control>
);

export const ExpanderRow = ({
  children,
  isExpanded,
  isActive = true
}: {
  children: React.Node,
  isExpanded?: boolean,
  isActive?: boolean
}) => {
  const [toggle, state] = useExpander(isExpanded ? 'expanded' : 'collapsed');

  if (!isActive) {
    return children;
  }

  return (
    // the wrapping div is necessary to avoid an overflow bug in FF, see ch6922
    <div>
      <ExpanderBar isExpanded={state === 'expanded'} onClick={toggle} />
      <Expander className={styles.Expander} pose={state}>
        <div className={styles.Row}>
          <div className={styles.RowContent}>{children}</div>
        </div>
      </Expander>
    </div>
  );
};

export function useExpander(initialState: ExpanderStateT = 'collapsed') {
  const [state, setState] = React.useState<ExpanderStateT>(initialState);
  const toggle = () =>
    setState(state === 'expanded' ? 'collapsed' : 'expanded');
  return [toggle, state, setState];
}
