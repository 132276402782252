// @flow

import throttle from 'lodash/throttle';

export default class DetectUserActivity {
  static eventNames = [
    'keypress',
    'keydown',
    'click',
    'contextmenu',
    'dblclick',
    // 'mousemove',
    'scroll',
    // 'touchmove',
    'touchstart'
  ];

  constructor(root: ?HTMLElement = document.body) {
    if (root == null || !(root instanceof EventTarget)) {
      throw new Error('body element is required');
    }

    this.root = root;

    DetectUserActivity.eventNames.forEach(eventName => {
      this.root.addEventListener(eventName, this.updateUserActivity);
    });

    this.lastUserActivityTime = Date.now();
  }

  lastUserActivityTime: number;
  root: EventTarget;

  destroy = () => {
    DetectUserActivity.eventNames.forEach(eventName => {
      this.root.removeEventListener(eventName, this.updateUserActivity);
    });
  };

  updateUserActivity = throttle(() => {
    this.lastUserActivityTime = Date.now();
  }, 500);

  getLastActivityTime = () => {
    return this.lastUserActivityTime;
  };
}
