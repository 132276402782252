// @flow

import * as React from 'react';
import { Form } from 'react-final-form';
import size from 'lodash/fp/size';
import pipe from 'lodash/fp/pipe';
import keys from 'lodash/fp/keys';
import get from 'lodash/fp/get';

import { onlyTruthyValues } from '@kwara/lib/src/lodash';
import { type BatchTransactionImportT } from '@kwara/models/src';

import { BatchTransactionsList } from './BatchTransactionsList';
import { Confirm } from './Confirm';

function truthyKeysToArray(transactions: { [id: string]: boolean }) {
  return pipe(
    onlyTruthyValues,
    keys
  )(transactions);
}

type Props = {
  batchImport: BatchTransactionImportT,
  refetch: () => void
};

const formEvents = Object.freeze({
  CLOSE: 'ON_CLOSE',
  APPROVE: 'ON_APPROVE',
  APPROVE_ALL: 'ON_APPROVE_ALL',
  REJECT: 'ON_REJECT',
  REJECT_ALL: 'ON_REJECT_ALL',
  ERROR: 'ON_ERROR',
  SUCCESS: 'ON_SUCCESS'
});

const initialState = {
  confirm: false,
  error: {},
  event: null,
  applyToAll: false,
  totalResults: 0
};

interface StateShape {
  applyToAll?: boolean;
  confirm: boolean;
  error: {};
  event: $Values<typeof formEvents>;
  totalResults?: number;
}

function reducer(state: StateShape, evt) {
  const { type, error, totalResults } = evt;

  if (type === formEvents.APPROVE) {
    return {
      ...state,
      confirm: true,
      event: 'approve',
      applyToAll: false
    };
  }

  if (type === formEvents.APPROVE_ALL) {
    return {
      ...state,
      confirm: true,
      event: 'approve',
      applyToAll: true,
      totalResults
    };
  }

  if (type === formEvents.REJECT) {
    return {
      ...state,
      confirm: true,
      event: 'reject'
    };
  }

  if (type === formEvents.REJECT_ALL) {
    return {
      ...state,
      confirm: true,
      event: 'reject',
      applyToAll: true,
      totalResults
    };
  }

  if (type === formEvents.CLOSE) {
    return {
      confirm: false,
      event: null,
      error: {}
    };
  }

  if (type === formEvents.SUCCESS) {
    return {
      confirm: false,
      event: null,
      error: {}
    };
  }

  if (type === formEvents.ERROR) {
    return {
      ...state,
      error
    };
  }

  throw `Undefined event type ${type}`;
}

export const BatchTransactionsForm = ({ batchImport, refetch }: Props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const { event, error, totalResults }: StateShape = state;

  async function submitSelected(formData) {
    const { notes, transactions } = formData;

    const data = {
      // This doesn't read very nice but Flow can't handle conditional spreading with && correctly
      // and always reports an error.
      // see: https://github.com/facebook/flow/issues/5946
      // namely https://github.com/facebook/flow/issues/5946#issuecomment-408721639
      // All we're doing here is adding `batch_transactions` as a key only if the user is
      // approving by selecting individual transactions. If selecting in bulk the BE
      // validations moans if the attribute is present.
      ...(state.applyToAll
        ? {}
        : {
            batch_transactions_ids: truthyKeysToArray(transactions)
          }),
      apply_to_all: state.applyToAll,
      notes,
      event
    };

    try {
      await batchImport.review(data);
      refetch();
      dispatch({ type: 'ON_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'ON_ERROR', error });
    }
  }

  return (
    <Form
      onSubmit={submitSelected}
      render={formProps => {
        const { values, form } = formProps;

        const numberSelected = state.applyToAll
          ? totalResults
          : size(truthyKeysToArray(values.transactions));

        return (
          <>
            <Confirm
              isOpen={state.confirm}
              onClose={() => dispatch({ type: 'ON_CLOSE' })}
              onSubmit={form.submit}
              numberSelected={numberSelected}
              event={event}
              error={error}
              applyToAll={state.applyToAll}
            />
            <BatchTransactionsList
              batchId={batchImport.id}
              status={get('state.current', batchImport)}
              numberSelected={numberSelected}
              onApprove={() => dispatch({ type: 'ON_APPROVE' })}
              onApproveAll={totalResults =>
                dispatch({ type: 'ON_APPROVE_ALL', totalResults })
              }
              onReject={() => dispatch({ type: 'ON_REJECT' })}
              onRejectAll={totalResults =>
                dispatch({ type: 'ON_REJECT_ALL', totalResults })
              }
              {...formProps}
            />
          </>
        );
      }}
    />
  );
};
