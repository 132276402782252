// @flow
import * as React from 'react';

import { Member, type MemberType } from '@kwara/models/src';
import { DeprecatedLoadable } from '@kwara/components/src/Loadable';

import Wizard from '../../components/Wizard';
import { memberPath } from '../../lib/urls';
import { steps } from './config';
import { type WizardPageProps } from '..';

type Props = WizardPageProps;

export type WizardData = {
  firstName?: ?string,
  middleName?: ?string,
  lastName?: ?string,

  gender?: ?('MALE' | 'FEMALE'),
  dateOfBirth?: ?string,
  maritalStatus?: ?'SINGLE' | 'MARRIED' | 'OTHER',
  maritalStatusOther?: ?string,

  postalAddress?: ?string,
  physicalAddress?: ?string,

  currentlyWorking?: ?'true' | 'false',

  phoneNumber?: ?string,
  secondaryPhoneNumber?: ?string,
  email?: ?string,

  nextOfKin?: ?Array<string>,

  identityType?: 'NATIONAL' | 'PASSPORT',
  identityValue?: string,

  notes?: ?string
};

export default class extends React.Component<Props, *> {
  static memberId(props: Props) {
    return props.match.params.memberId;
  }

  constructor(props: Props) {
    super(props);

    // TODO: Move this request to componentDidMount, where it should be.
    // Also, do that for pretty much all compenents that use <DeprecatedLoadable />
    // as they all fire the request inside the constructor, which is not ideal.
    this.state = {
      memberPromise: props.match.params.memberId
        ? Member.full()
            .find(props.match.params.memberId)
            .then(response => response.data.deserialize())
        : Promise.resolve(Member.new())
    };
  }

  renderContent = (member: MemberType) => {
    const { baseUrl, match, history } = this.props;

    return (
      <Wizard
        baseUrl={baseUrl}
        history={history}
        currentStep={match.params.step}
        initialData={member}
        currentSubStep={
          match.params.subStep != null
            ? parseInt(match.params.subStep, 10)
            : null
        }
        cancelReturnsTo={memberPath(member.id)}
        onSubmit={() => {}}
        steps={steps}
        startId="about"
        titleId="SelfService.title"
      />
    );
  };

  render() {
    return (
      <DeprecatedLoadable
        loader={this.state.memberPromise}
        loaded={this.renderContent}
      />
    );
  }
}
