// @flow
import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';

import typeof { Child } from '../../../../components/Stack';

type Props = {
  titleId: string,
  subtitleId: string,
  buttonTextId: string,
  enabled: boolean,
  isSearching: boolean,
  onAdd: () => void,
  memberName: string,
  StackChild: Child
};

export const Header = ({
  titleId,
  subtitleId,
  buttonTextId,
  StackChild,
  enabled = false,
  isSearching,
  onAdd,
  memberName
}: Props) => (
  <StackChild>
    <div className="pb4 flex justify-between items-center">
      <div>
        <h3 className="ma0">
          <Text id={titleId} values={{ memberName }} />
        </h3>
        <p className="ma0 grey-400">
          <Text id={subtitleId} />
        </p>
      </div>
      {enabled && (
        <Button
          className="flex-none ml3"
          disabled={isSearching}
          size="regular"
          type="secondary"
          onClick={onAdd}
        >
          <Text id={buttonTextId} />
        </Button>
      )}
    </div>
  </StackChild>
);
