// @flow

import React from 'react';

import { Text } from '@kwara/components/src/Intl';
import Modal from '@kwara/components/src/Modal';
import DelayedAnimation from '@kwara/components/src/Wizard/DelayedAnimation';

import { AutoDismissableCompletion } from '../../../../../components/Completion';

const unanimatedSuccess = ({
  onCancel,
  isTillOpen
}: {
  onCancel: () => void,
  isTillOpen: boolean
}) => {
  return (
    <AutoDismissableCompletion
      analyticsId={isTillOpen ? 'Till-Open' : 'Till-Close'}
      autoconfirm
      autoconfirmTimeoutSecs={3}
      type="good"
      onConfirm={onCancel}
      subtitle={isTillOpen ? 'All good' : 'All good'}
    />
  );
};

export const Success = ({
  onCancel,
  isTillOpen
}: {
  onCancel: () => void,
  isTillOpen: boolean
}) => {
  return (
    <Modal isOpen>
      <DelayedAnimation onDone={onCancel}>
        {({ hide }) => unanimatedSuccess({ onCancel: hide, isTillOpen })}
      </DelayedAnimation>
    </Modal>
  );
};
