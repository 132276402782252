// @flow

import { attr } from 'spraypaint';

import Base, { type BaseModel } from './Base';

export const TransactionChannel = Base.extend({
  static: {
    jsonapiType: 'transaction_channels'
  },
  attrs: {
    name: attr(),
    isBaseChannel: attr(),
    isGlChannel: attr(),
    glCode: attr(),
    glName: attr(),
    allowsManualJournalEntries: attr()
  }
});

export interface TransactionChannelT extends BaseModel<TransactionChannelT> {
  name: string;
  isBaseChannel: boolean;
  isGlChannel: boolean;
  glCode: string;
  glName: string;
  allowsManualJournalEntries: boolean;
}
