// @flow

import * as React from 'react';
import map from 'lodash/fp/map';
import compact from 'lodash/fp/compact';

import {
  BatchTransactionState,
  type BatchTransactionT
} from '@kwara/models/src/models/BatchTransaction';
import { Text } from '@kwara/components/src/Intl';
import Button from '@kwara/components/src/Button';

import { useBatchTransactions } from '../../../models/request';
import { usePagination } from '../../../models/pagination';
import { useFilter } from '../../../models/filter';

import OverviewList from '../../../components/OverviewList';
import { BatchTransactionsTable } from './BatchTransactionsTable';

type Props = {
  onApprove: () => void,
  onApproveAll: (n: number) => void,
  onReject: () => void,
  onRejectAll: (n: number) => void,
  numberSelected: number,
  batchId: string,
  status: string
};

const batchTransactionFilters = [
  { value: '', translationId: '' },
  ...map(
    value => ({
      value,
      translationId: `BatchTransactionsList.filter.${value}`
    }),
    BatchTransactionState
  )
];

const includes = ['import_review_set.user'];
export const BatchTransactionsList = ({
  onApprove,
  onApproveAll,
  onReject,
  onRejectAll,
  numberSelected,
  batchId,
  status,
  ...rest
}: Props) => {
  const { filterValue, onFilterChange } = useFilter(filter => ({
    state: filter
  }));
  const {
    onNext,
    allData,
    hasMore,
    totalResults,
    isPending,
    ...r
  } = usePagination<BatchTransactionT>(
    useBatchTransactions,
    page => [batchId, includes, filterValue, page],
    filterValue
  );

  const hasPendingTransactions =
    status === 'in_review' || status === 'pending_review';

  const approve = (
    <Button key="approve" onClick={onApprove} type="primary">
      <Text
        id="BatchTransactionsApproval.action.approve"
        values={{ val: numberSelected }}
      />
    </Button>
  );
  const reject = (
    <Button key="reject" onClick={onReject} type="destructive">
      <Text
        id="BatchTransactionsApproval.action.reject"
        values={{ val: numberSelected }}
      />
    </Button>
  );
  const approveAll =
    // only show this button when the batch still has pending approvals
    hasPendingTransactions ? (
      <Button
        key="approveAll"
        onClick={() => onApproveAll(totalResults)}
        type="primary"
      >
        <Text
          id="BatchTransactionsApproval.action.approveAll"
          values={{ totalResults }}
        />
      </Button>
    ) : null;

  const rejectAll =
    // only show this button when the batch still has pending approvals
    hasPendingTransactions ? (
      <Button
        key="rejectAll"
        onClick={() => onRejectAll(totalResults)}
        type="destructive"
      >
        <Text
          id="BatchTransactionsApproval.action.rejectAll"
          values={{ totalResults }}
        />
      </Button>
    ) : null;

  const actions = numberSelected ? [approve, reject] : [approveAll, rejectAll];

  return (
    <OverviewList
      headerId="BatchTransactionsList.header"
      labelId="BatchTransactionsList.label"
      totalNumResults={totalResults}
      items={allData}
      actions={compact(actions)}
      filterBy={filterValue.state}
      filters={batchTransactionFilters}
      filterDisabled={isPending}
      onFilterChange={onFilterChange}
      table={
        <BatchTransactionsTable
          transactions={allData}
          hasMore={hasMore}
          onNext={onNext}
          isPending={isPending}
          {...r}
        />
      }
      {...rest}
    />
  );
};
