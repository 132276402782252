// @flow

import * as React from 'react';

import { getCurrentDate } from '@kwara/lib/src/dates';
import { SubscribedTextField as TextField } from '@kwara/components/src/Form';

import { Panel } from '../../../components/ActionModal';
import { DatePicker, Field } from '../../../components/Form';
import { Grid } from '../../../components/Grid';

export const validateConfig = {
  amount: { isRequired: () => true, currency: true, nonZero: true }
};

export type PaymentData = {
  amount?: ?number,
  date?: ?Date
};

export const PaymentForm = () => {
  return (
    <Panel>
      <Grid columns={2} width="w-50" border={false}>
        <TextField
          required
          name="amount"
          size="medium"
          leftGlyph="Currency.orgCurrency"
          labelId="PaymentForm.amount"
        />
        <Field
          required
          name="date"
          size="medium"
          labelId="SavingPenalty.dateLabel"
        >
          <DatePicker name="date" disabled value={getCurrentDate()} />
        </Field>
      </Grid>
    </Panel>
  );
};
