// @flow

import React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import pipe from 'lodash/fp/pipe';
import mapValues from 'lodash/fp/mapValues';
import toNumber from 'lodash/fp/toNumber';

import { LoanProduct } from '@kwara/models/src';
import {
  ProductTypes,
  InterestCalculationMethods,
  InterestApplicationMethods,
  ChargeFrequencies,
  PaymentIntervalMethod
} from '@kwara/models/src/models/LoanProduct';
import { onlyTruthyValues } from '@kwara/lib/src/lodash';

import Wizard from '../../components/Wizard';
import { settingPath } from '../../lib/urls';
import { steps } from './config';

type Props = {
  baseUrl: string,
  match: {
    params: {
      step: string,
      subStep: string
    }
  }
};

export function valuesAsNumbers(collection: ?{ [string]: any } = {}) {
  return pipe(
    onlyTruthyValues,
    mapValues(toNumber)
  )(collection);
}

export function formatAmountInfo(amountObject) {
  return isEmpty(valuesAsNumbers(amountObject))
    ? {}
    : { amount: valuesAsNumbers(amountObject) };
}

export const LoanProductAdd = (props: Props) => {
  const createLoanProduct = async data => {
    const amountInfo = formatAmountInfo(data.amount);

    const product = new LoanProduct({
      ...data,
      ...amountInfo
    });

    const didSave = await product.save();

    if (!didSave) {
      throw product.errors;
    }
  };

  return (
    <Wizard
      baseUrl={props.baseUrl}
      history={history}
      initialData={{
        paymentIntervalMethod: PaymentIntervalMethod.INTERVAL,
        type: ProductTypes.FIXED_TERM_LOAN,
        interestRate: {
          calculation_method:
            InterestCalculationMethods.DECLINING_BALANCE_DISCOUNTED,
          application_method: InterestApplicationMethods.AFTER_DISBURSEMENT,
          interest_charge_frequency: ChargeFrequencies.ANNUALIZED
        },
        security: {
          guarantors_enabled: false,
          collaterals_enabled: false
        }
      }}
      cancelReturnsTo={settingPath({ action: 'loans' })}
      currentStep={props.match.params.step}
      currentSubStep={
        props.match.params.subStep != null
          ? parseInt(props.match.params.subStep, 10)
          : null
      }
      onSubmit={createLoanProduct}
      steps={steps}
      startId="product"
      titleId="LoanProductAdd.titleId"
      type="approval"
    />
  );
};
