// @flow

import React from 'react';

import { LoanSummary } from './LoanSummary';

import { type ComponentPropsLoanReschedule } from '..';

export const ConfirmReschedule = ({
  data,
  StackChild
}: ComponentPropsLoanReschedule) => {
  return (
    <StackChild>
      <LoanSummary data={data} isRescheduledInfo />
    </StackChild>
  );
};
