import React from 'react';
import get from 'lodash/fp/get';
import { View } from '@react-pdf/renderer';

import {
  pdfMemberDetails as MemberDetails,
  DefaultText as Text,
  SubtitleText as Subtitle,
  Section,
  Template,
  Currency
} from '@kwara/components/src/PDF';
import { formatHumanDate } from '@kwara/lib/src/dates';

const TransactionSummary = ({ transaction }) => {
  const {
    id,
    amount,
    reference,
    bankName,
    bankBranch,
    accountNumber,
    paymentMethod,
    createdAt,
    type
  } = transaction;

  return (
    <View>
      <Subtitle>Transaction Details</Subtitle>
      <Text>ID: {id}</Text>
      <Text>Date: {formatHumanDate(createdAt)}</Text>
      <Text>Type: {type}</Text>
      <Text>
        Amount: KES <Currency>{amount}</Currency>
      </Text>
      <Text>Reference: {reference || '--'}</Text>
      <Text>Method: {paymentMethod}</Text>
      {bankName ? <Text>Bank name: {bankName}</Text> : null}
      {bankBranch ? <Text>Bank branch: {bankBranch}</Text> : null}
      {accountNumber ? <Text>Account number: {accountNumber}</Text> : null}
    </View>
  );
};

const EndOfDocument = () => (
  <Text style={{ textAlign: 'center', paddingTop: '5px', color: 'black' }}>
    -----End of Document-----
  </Text>
);

export const TransactionPDF = ({ transaction, member, profile }) => {
  return (
    <Template
      title={`Transaction ${transaction.id}`}
      headerText="Transaction Receipt"
      sacco={get('branch', profile)}
    >
      <Section fixed>
        <TransactionSummary transaction={transaction} />
        {member ? <MemberDetails member={member} /> : null}
      </Section>
      <EndOfDocument />
    </Template>
  );
};
