import React from 'react';
import size from 'lodash/fp/size';

import { removeAt } from '@kwara/lib/src/lodash';

const { useState } = React;

// DEPRECATED: use useListEditor to achieve this functionality
export function useProduct(products) {
  const productsLen = size(products);
  const [isOpen, setModalState] = useState(false);
  const [index, setIndex] = useState(productsLen);
  const editProduct = idx => {
    setIndex(idx);
    setModalState(true);
  };
  const removeProduct = idx => removeAt(idx, products);
  const addProduct = () => editProduct(productsLen);
  return {
    isOpen,
    setModalState,
    index,
    setIndex,
    addProduct,
    editProduct,
    productsLen,
    removeProduct
  };
}
