// @flow

import * as React from 'react';
import { invitation } from '../../models';
import { UserTypes } from '@kwara/models/src';
import { InvitationRedeem as SharedRedeem } from '@kwara/components/src/InvitationRedeem';

const InvitationRedeem = props => (
  <SharedRedeem userType={UserTypes.USER} invitation={invitation} {...props} />
);

export default InvitationRedeem;
