// @flow

import * as React from 'react';

import Button from '@kwara/components/src/Button';
import { Text } from '@kwara/components/src/Intl';
import { ButtonBar } from '@kwara/components/src/ButtonBar';
import Modal from '@kwara/components/src/Modal';
import { Panel } from '@kwara/components/src/Panel';

type Props = {
  hide: boolean,
  onCancel: () => void,
  onRemain: () => void
};

export const ConfirmCancel = ({ hide, onCancel, onRemain }: Props) => {
  return (
    <Modal isOpen={!hide}>
      <div className="w-100 h-100 flex justify-center items-center">
        <Panel>
          <h2 className="mt1 mb3 kw-text-large kw-weight-bold">
            <Text id="WizardConfirmCancel.title" />
          </h2>
          <p className="kw-weight-light">
            <Text id="WizardConfirmCancel.subtitle" />
          </p>
          <ButtonBar
            className="mt4"
            left={
              <Button onClick={onRemain}>
                <Text id="WizardConfirmCancel.remain" />
              </Button>
            }
            right={
              <Button classNames="mr3" type="destructive" onClick={onCancel}>
                <Text id="WizardConfirmCancel.exit" />
              </Button>
            }
          />
        </Panel>
      </div>
    </Modal>
  );
};
