import React from 'react';

import { PageHeading } from '@kwara/components/src/text';
import { Statistic } from '@kwara/components/src/Statistic';
import StatusTag from '@kwara/components/src/StatusTag';
import { Text } from '@kwara/components/src/Intl';
import { Grid } from '../../../../components/Grid';
// import { DateTime } from '@kwara/components/src/Intl';
import { tomorrow } from '@kwara/lib/src/dates';
import { FormattedDate } from 'react-intl';

import { Card } from '../../../../components/Card';
import { TrialNotificationChecker } from '../../../../components/TrialNotificationChecker';

const Tiers = [
  {
    name: 'Basic',
    selected: false
  },
  {
    name: 'Advanced',
    selected: true
  }
];

export const Billing = () => {
  return (
    <>
      <TrialNotificationChecker force />
      <PageHeading translationId="Stima.Billing.title" />
      <p className="grey-400 kw-text-medium mb4">
        <Text id="Stima.billingText" />
      </p>
      <Grid columns={4} width="w-25">
        {Tiers.map(t => (
          <Card
            key={t.name}
            header={<div>{t.name}</div>}
            // className={styles.Card}
            metadata={
              <>
                {t.selected ? <StatusTag state={'selected'} /> : null} &middot;
              </>
            }
            highlighted={t.selected}
            isHoverable
          >
            <Statistic
              title={t.selected ? <Text id="Stima.expirydate" /> : 'Pricing'}
              value={
                // /  tomorrow
                t.selected ? (
                  <FormattedDate format="short" value={tomorrow()} />
                ) : (
                  'Contact sales'
                )
              }
              size="small"
              color={t.selected ? 'red-500' : 'black-500'}
            />
          </Card>
        ))}
      </Grid>
    </>
  );
};
