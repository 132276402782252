//@flow

import * as React from 'react';
import cx from 'classnames';

import size from 'lodash/size';
import get from 'lodash/get';

import { Text, type TranslationId } from '@kwara/components/src/Intl';
import { Link } from '@kwara/components/src/Link';

import styles from './Summary.module.scss';

//Returns true if value of the record is the DocumentList
// && it has no (non-profile-picture) attachments
const isEmptyDocumentList = element => {
  const attachments = get(element, 'props.value.props.attachments');
  return !!attachments && size(attachments) === 0;
};

const isValueNull = element =>
  element.props.value == null && element.props.valueId == null;

const isEmptyRecord = element =>
  isEmptyDocumentList(element) || isValueNull(element);

type NoRecordsMessageProps = {
  children: React.Node
};

const NoRecordsMessage = ({ children }: NoRecordsMessageProps) => {
  return React.Children.toArray(children).every(isEmptyRecord) ? '-' : children;
};

type SectionProps = {
  titleId: TranslationId,
  children?: React.Node,
  editUrl?: string
};

export const Section = ({ titleId, children, editUrl }: SectionProps) => (
  <section
    className={`bb b--light-grey-400 flex items-flex-start ${styles.Section}`}
  >
    <h3 className={`ma0 kw-text-regular kw-weight-bold mr2 ${styles.Heading}`}>
      <Text id={titleId} />
    </h3>
    <div className={cx('kw-text-regular grey-400', styles.RecordContent)}>
      <NoRecordsMessage>{children}</NoRecordsMessage>
    </div>
    <div className={styles.EditColumn}>
      {editUrl ? (
        <Link to={editUrl} type="primary" underline active>
          <Text id="General.Edit" />
        </Link>
      ) : null}
    </div>
  </section>
);

type RecordProps = {
  labelId?: TranslationId,
  value?: ?React.Node,
  valueId?: TranslationId,
  values?: ?{ [string]: mixed }
};

export const Record = ({ labelId, value, valueId, values }: RecordProps) => {
  const label = labelId ? <Text id={labelId} values={values} /> : null;
  const join = label ? ': ' : null;
  const data = valueId != null ? <Text id={valueId} values={values} /> : value;

  if (data == null) {
    return null;
  }

  return (
    <div className="ma0 mb1">
      {label}
      {join}
      {data}
    </div>
  );
};
